import React, { memo, useCallback } from 'react'
import { View, Text } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { Dropdown, PickerItem } from '../feed/Dropdown'
import { Space } from '../shared/Space'
import { useSelect } from 'downshift'
import { Tooltip } from '../shared/Tooltip'
import { ThreeDotsIcon } from '../feed/HeaderIcons'
import { useAppContext } from '@there/components/shared/AppContext'
import { useDeleteDialog } from '@there/components/main/useDeleteDialog'
import { currentDialogIdAtom } from '@there/components/feed/useLobbyMan'
import { useAtom } from 'jotai'
import { useEditDialog } from '@there/components/main/useEditDialog'
import { DialogInfo } from '@there/sun/utils/node-types'
import { GoodButton } from '@there/components/shared/GoodButton'
import { DeleteIcon, RemoveIcon, RenameIcon, RoomBgIcon } from './RoomMenuIcons'
import { useRemoveDialogBackground } from './useRemoveDialogBackground'
import { useUiContext } from '../shared/UiContext'
import Sentry from '@there/app/utils/sentry'
import {
  currentDialogLockStatusAtom,
  spaceContextRefetchAtom,
} from '../shared/spaceContext/context'
import { useModals } from '../feed/ModalsContext'
import { useSoundEffect } from '../feed/useSoundEffect'
import { UnlockIcon } from '../room/UnlockIcon'
import { LockIcon } from '../room/LockIcon'
import { MicrophoneIcon } from '@there/components/shared/MicrophoneIcon'
import { useLockDialog } from './useLockDialog'

export const RoomMenu = memo(
  ({ currentDialog }: { currentDialog: DialogInfo | null }) => {
    const t = useTheme()
    let { activeSpaceId } = useAppContext()

    let [, deleteDialog] = useDeleteDialog()
    let [, editDialog] = useEditDialog()
    let [currentDialogId] = useAtom(currentDialogIdAtom)

    let [, removeDialogBackground] = useRemoveDialogBackground()
    let { hasCustomBackground } = useUiContext()
    let [, modalDispatch] = useModals()

    let isLobby = currentDialog?.specialType === 'lobby'

    let { toggleLockDialog, currentDialogLockStatus } = useLockDialog()
    const customRoomItems: PickerItem[] = [
      {
        label: 'Set Room Background',
        onClick: () => {
          if (!currentDialogId) return
          modalDispatch({
            type: 'modal opened',
            modalName: 'setBackground',
            modalData: {
              dialogId: currentDialogId,
              dialogBgObject: currentDialog?.bgObjectId,
            },
          })
        },
        icon: (
          <RoomBgIcon color={t.colors.tertiaryText} width={20} height={20} />
        ),
      },

      {
        label: currentDialog?.silent
          ? 'Disable Silent Mode'
          : 'Enable Silent Mode',
        onClick: () => {
          if (!activeSpaceId) return
          if (!currentDialogId) return

          editDialog({
            id: currentDialogId,
            silent: !currentDialog?.silent,
            __extra: { spaceId: activeSpaceId },
          })
        },
        icon: (
          <MicrophoneIcon
            width={18}
            height={18}
            color={t.colors.tertiaryLight}
            muted={!currentDialog?.silent}
          />
        ),
      },
    ]

    let lobbyItems = [
      {
        label: currentDialogLockStatus ? 'Unlock Room' : 'Lock Room',
        onClick: () => toggleLockDialog(!currentDialogLockStatus),
        icon: !currentDialogLockStatus ? (
          <LockIcon width={20} color={t.colors.lockRoomIcon} />
        ) : (
          <UnlockIcon color={t.colors.tertiaryLight} width={20} />
        ),
      },
      {
        label: 'Rename',
        onClick: () => {
          if (currentDialog) {
            modalDispatch({
              modalName: 'editRoom',
              type: 'modal opened',
              modalData: {
                dialogId: currentDialog.id,
                dialog: { ...currentDialog, avatars: undefined },
              },
            })
          }
        },
        icon: (
          <RenameIcon color={t.colors.tertiaryText} width={20} height={20} />
        ),
      },
      {
        label: 'Delete Room',
        onClick: () => {
          if (!activeSpaceId) return
          if (!currentDialogId) return
          deleteDialog({ dialogId: currentDialogId, spaceId: activeSpaceId })

          Sentry.withScope((scope) => {
            scope.setExtras({ 'room name': currentDialog?.title })
            Sentry.captureMessage('Room deleted')
          })
        },
        icon: (
          <DeleteIcon color={t.colors.tertiaryText} width={20} height={20} />
        ),
      },
    ]

    if (!isLobby) {
      customRoomItems.unshift(...lobbyItems)
    }

    // Do not show clear dialog background item if we have not bg
    if (hasCustomBackground) {
      customRoomItems.push({
        label: 'Clear Background',
        onClick: () => {
          if (!currentDialogId) return
          if (!activeSpaceId) return
          removeDialogBackground({
            id: currentDialogId,
            __extra: { spaceId: activeSpaceId },
          })
        },
        icon: (
          <RemoveIcon color={t.colors.tertiaryText} width={20} height={20} />
        ),
      })
    }

    const items: PickerItem[] = customRoomItems

    const {
      getToggleButtonProps,
      getMenuProps,
      getItemProps,
      isOpen,
      highlightedIndex,
      toggleMenu,
      closeMenu,
    } = useSelect({
      items,
      onSelectedItemChange: ({ selectedItem }) => {
        if (!selectedItem) return
        if (selectedItem.onClick) {
          selectedItem.onClick()
        }
      },
    })

    return (
      <Dropdown.Root>
        <Dropdown.Toggle onPress={() => toggleMenu()}>
          {({ onPress }) => (
            <Tooltip label="Open menu" placement="bottomCenter">
              <GoodButton
                variant="transparent"
                active={isOpen}
                size="small"
                onPress={onPress}
                icon={<ThreeDotsIcon color={t.colors.quaternaryLight} />}
                {...getToggleButtonProps(
                  { refKey: 'nokey' },
                  { suppressRefError: true },
                )}
              />
            </Tooltip>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu
          {...getMenuProps({ refKey: 'nokey' }, { suppressRefError: true })}
          isOpen={isOpen}
          onClose={closeMenu}
          width={185}
        >
          <Dropdown.Section>
            <Space vertical={4} />
            {items.map((item, index) => {
              return (
                <View
                  key={item.label}
                  {...getItemProps({
                    item,
                    index,
                  })}
                >
                  <Dropdown.ItemWithIcon onPress={item.onClick}>
                    {item.icon}
                    <Text
                      style={{
                        fontSize: t.fontSizes.normal,
                        color: t.colors.tertiaryText,
                        flex: 1,
                        marginLeft: 5,
                      }}
                    >
                      {item.label}
                    </Text>
                  </Dropdown.ItemWithIcon>
                </View>
              )
            })}
            <Space vertical={4} />
          </Dropdown.Section>
        </Dropdown.Menu>
      </Dropdown.Root>
    )
  },
)
