import { useLatest } from '@there/components/shared/use-latest'
import { MouseEventHandler, useMemo } from 'react'
import { useState, useEffect, useCallback } from 'react'
import { ViewProps } from 'react-native'

export function useHover(options?: {
  stopPropagation?: boolean
  onMouseEnter?: MouseEventHandler
}): [boolean, Record<string, undefined | ((event: any) => void)>] {
  const [hovered, setHovered] = useState(false)
  let hoveredRef = useLatest(hovered)
  let callbacks = useMemo(
    () => ({
      onMouseEnter: (event: any) => {
        if (options?.stopPropagation) {
          event.stopPropagation()
        }
        setHovered(true)
        if (options?.onMouseEnter) {
          options.onMouseEnter(event)
        }
      },
      onMouseMove: (event: any) => {
        if (hoveredRef.current) return
        setHovered(true)
      },
      onMouseLeave: (event: any) => {
        setHovered(false)
      },
    }),
    [hoveredRef, options],
  )

  return [hovered, callbacks]
}
