// ☢️ MUST update app on desktop update to have change logs updated ☢️

export const changeLog: {
  app: Record<string, string>
  desktop: Record<string, string>
} = {
  app: {
    // '2.1.120': 'Video over screen-share!',
    '1.2.6': 'Faster app & bug fixes',
    '1.2.5': 'Faster app & bug fixes',
    '1.1.0': 'All-new UI & work-time',
    '1.0.0': 'All-new UI and more!',
    '0.17.33-nightly.14': 'All-new UI and more',
    '0.17.33-nightly.13': 'Room notifications',
    '0.17.33-nightly.9': 'More stable updating',
  },
  desktop: {
    '2.0.24': 'Video over screen-share!',
    '1.0.0': 'All-new UI and more!',
    '0.17.32-nightly.3': 'All-new UI',
  },
}
