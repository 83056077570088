import { useAtomValue } from 'jotai/utils'
import { spaceTypeAtom } from '../atoms/spaceAtom'

const proSpaceIds = new Set([
  'Space:ckkyg8tdc5136esfliyjzq9e1',
  'Space:ckrjpdn2638152eyfjsgmghd55',
])

export const useProSpace = (
  spaceId?: string,
): {
  isProSpace: boolean
} => {
  let isProSpace =
    useAtomValue(spaceTypeAtom) === 'pro' ||
    Boolean(spaceId && proSpaceIds.has(spaceId))
  return { isProSpace }
}
