import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { EditTopicModalData, useModals } from '../feed/ModalsContext'
import { StandardButton } from '@there/components/main/StandardButton'
import { StandardInput } from '@there/components/main/StandardInput'
import { Space } from '../shared/Space'
import { ModalTitle } from './ModalTitle'
import { ModalWrapper } from './ModalWrapper'
import { useAppContext } from '@there/components/shared/AppContext'
import { useEditTopic } from '../main/useEditTopic'

const modalName = 'editTopic'

export const EditTopicModal = () => {
  let { activeSpaceId } = useAppContext()
  let [{ modals, modalsData }, modalsDispatch] = useModals()
  let [, editTopic] = useEditTopic()
  let [name, setName] = useState('')

  const onClose = () => {
    modalsDispatch({ type: 'modal closed', modalName })
    setName('')
  }

  let modalData = modalsData[modalName] as EditTopicModalData | undefined

  useEffect(() => {
    if (!modals.includes(modalName)) return
    if (!modalData || !modalData.topic.title) return

    setName(modalData.topic.title)
  }, [modalData, modals])

  function submitted() {
    if (!modalData) return
    if (!activeSpaceId) return

    // submit
    editTopic({
      id: modalData.topicId,
      title: name,
    })

    onClose()
  }

  return (
    <ModalWrapper isOpen={modals.includes(modalName)} onClose={onClose}>
      <View
        style={{
          width: 215,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalTitle align="center">Rename Topic</ModalTitle>
        <Space vertical={10} />
        <View>
          <StandardInput
            autoFocus={true}
            autoSelect={true}
            isMultiline={false}
            placeholder="eg. Let's play"
            value={name}
            setValue={setName}
            background="transparentInputBackground"
            bordered={true}
            align="center"
            onSubmit={submitted}
            onCancel={onClose}
          />
          <Space vertical={10} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <StandardButton
              text="Close"
              backgroundColor="buttonBackground"
              textColor="secondaryText"
              size="normal"
              onPress={onClose}
            />
            <Space horizontal={10} />
            <StandardButton
              text="Done"
              backgroundColor="primaryButtonBackground"
              textColor="secondaryText"
              size="normal"
              onPress={submitted}
            />
          </View>
        </View>
      </View>
    </ModalWrapper>
  )
}
