import { useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { Text, View } from 'react-native'
import { activeNewChatPeerAtom } from '../atoms/chatAtom'
import { contentViewAtom } from '../atoms/contentViewAtom'
import { useTheme } from '../feed/ThemeContext'
import { StatusIcon } from '../nooray/FeedContent'
import { useStatusesContext } from '../nooray/useStatusesContext'
import { Pressable } from '../shared/Pressable'
import { logEvent } from '../shared/use-analytics'
import { useMainWindowContext } from '../shared/use-main-window'
import { sidebarHorizontalPadding, sidebarRightPadding } from '../v2/Sidebar'

export const StatusFeedButton = () => {
  let theme = useTheme()

  let { dispatch, mode, isFeedOpen } = useMainWindowContext()
  let [, setActiveChatPeer] = useAtom(activeNewChatPeerAtom)
  let { hasUnreadStatus } = useStatusesContext()

  let isActive = mode === 'room' && isFeedOpen
  return (
    <Pressable
      style={({ pressed }) => [
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: sidebarHorizontalPadding - 10,
          paddingTop: 5,
          paddingBottom: 5,
          marginHorizontal: 10,
          borderRadius: 6,
        },
        isActive && {
          backgroundColor: '#219ebc',
        },
        !isActive && pressed && { opacity: 0.85 },
      ]}
      onPress={() => {
        dispatch({ type: 'change mode', mode: 'room', isFeedOpen: true })
        setActiveChatPeer({
          peerTopicId: null,
          peerUserId: null,
        })
        logEvent('User Opened Statuses')
      }}
    >
      <View
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <View
          style={{
            width: 25,
            height: 25,
            borderRadius: 23,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.colors.buttonBackground,
          }}
        >
          <StatusIcon
            width={12}
            height={12}
            color={theme.colors.tertiaryLight}
          />
        </View>
        <Text
          style={{
            fontSize: theme.fontSizes.normal,
            color: theme.colors.secondaryText,
            marginLeft: 8,
          }}
        >
          Feed
        </Text>
      </View>
      {hasUnreadStatus && !isActive && (
        <View
          style={{
            width: 10,
            height: 10,
            borderRadius: 10,
            backgroundColor: '#3484CE',
            marginRight: 8,
          }}
        />
      )}
    </Pressable>
  )
}
