import gql from 'graphql-tag'
import { useMutation } from 'urql'
import { userInfoFragment, UserInfo } from './fragments/userInfo'

export const uploadAvatar = gql`
  mutation uploadAvatar($file: Upload!) {
    user: uploadAvatar(file: $file) {
      ...userInfo
    }
  }
  ${userInfoFragment}
`

export type Variables = { file: File }
export type Data = {
  user?: UserInfo
}

export const useUploadAvatar = () => useMutation<Data, Variables>(uploadAvatar)
