import * as React from 'react'
import { isElectron } from '@there/desktop/utils/electron-api'
import { feedSize } from '@there/shared/desktop/windows-config'

export const Devtools = () => {
  return <></>
}

const browserFeedClipStyles: React.CSSProperties = {
  height: feedSize.height,
  top: '50%',
  left: '50%',
  overflow: 'scroll',
  position: 'absolute',
  transform: `translateX(-50%) translateY(-50%)`,
  boxShadow: `0 10px 18px rgba(0,0,0,0.5)`,
}

export const DevFeedWrapper = ({
  children,
  width = feedSize.width,
}: {
  children: React.ReactNode
  width?: number
}) => {
  return (
    <>
      <div
        style={
          isElectron
            ? {}
            : typeof window !== 'undefined' &&
              window.matchMedia(`(min-width: ${width}px)`).matches
            ? {} || { ...browserFeedClipStyles, width }
            : {}
        }
      >
        {children}
      </div>

      {/* Todo: position correctly */}
      <Devtools />
    </>
  )
}
