import { CallSection } from '@there/components/settings/CallSection'
import { WindowSection } from '@there/components/settings/WindowSection'
import { StatusSection } from '@there/components/settings/StatusSection'
import React from 'react'
import { Space } from '../shared/Space'
import { WindowHeader } from './SettingsUI'
import { ShortcutsSection } from './NoorayShortcutsManager'
import { ChatSection } from '@there/components/settings/ChatSection'

export const NoorayPreferencesWindow = () => {
  return (
    <React.Fragment>
      <WindowHeader>Preferences</WindowHeader>
      <Space vertical={12} />
      <ShortcutsSection />
    </React.Fragment>
  )
}
