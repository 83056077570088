import { useSpring, a } from '@react-spring/native'
import { roomSelectedAtom } from '@there/components/main/DialogItem'
import { easeOutCubic, easeOutExpo } from '@there/components/utils/easings'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { Text, View } from 'react-native'
import { Door } from '../main/RoomMenuIcons'

let width = 246

export const DoubleClickPopup = () => {
  let [roomSelected, setRoomSelected] = useAtom(roomSelectedAtom)
  useEffect(() => {
    if (!roomSelected) return
    let id = setTimeout(() => {
      setRoomSelected(false)
    }, 3000)

    return () => {
      clearTimeout(id)
    }
  }, [roomSelected, setRoomSelected])
  let styles = useSpring({
    y: roomSelected ? 0 : 20,
    opacity: roomSelected ? 1 : 0,

    config: {
      duration: 800,
      easing: easeOutExpo,
    },
  })

  return (
    <>
      <a.View
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.10)',
          width: width,
          height: 46,
          position: 'absolute',
          bottom: 20,
          left: '50%',
          opacity: styles.opacity,
          transform: [{ translateY: styles.y }, { translateX: -(width / 2) }],
          //@ts-ignore
          backdropFilter: 'blur(19px)',
          borderRadius: 10,
          alignItems: 'center',
          justifyContent: 'center',
          //@ts-ignore
          pointerEvents: 'none',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Door />
          <Text
            style={{
              fontSize: 15,
              color: '#fff',
              fontWeight: '900',
              marginLeft: 12,
            }}
          >
            Double click to join room
          </Text>
        </View>
      </a.View>
    </>
  )
}
