import { ResolveInfo, Cache } from '@urql/exchange-graphcache'

type OptimisticMutationResolver = (
  vars: any,
  cache: Cache,
  info: ResolveInfo,
) => null | any

interface CustomOptimisticConfig {
  [mutationName: string]: OptimisticMutationResolver
}

export const optimisticUpdates: CustomOptimisticConfig = {}
