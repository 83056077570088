import { useCallback, useEffect, useState } from 'react'
import { View, Image, Text } from 'react-native'
import { useTheme } from 'styled-components'
import { PreviewPhotoModalData, useModals } from '../feed/ModalsContext'
import { StandardButton } from '../main/StandardButton'
import { Space } from '../shared/Space'
import { ModalWrapper } from './ModalWrapper'
import { useSpring } from '@react-spring/native'
import { useUiContext } from '@there/components/shared/UiContext'
import { calculateImageDimensions } from '../v2/ChatUploadButton'
import { useKeyContext } from '@there/components/feed/KeyContext'

const lineHeight = 16
const maxLineLimit = 7
const textInputPaddingVertical = 7
const textInputMinHeight = 3

export const PreviewPhotoModal = () => {
  let theme = useTheme()
  let [modalsState, modalsDispatch] = useModals()
  let { dispatch } = useUiContext()
  let [darkenFilter, setDarkenFilter] = useState(0)
  let [shouldHide, setHide] = useState(false)

  let [message, setMessage] = useState('')
  let [numberOfLines, setNumberOfLines] = useState(1)
  let currentLineHeight =
    Math.min(numberOfLines, maxLineLimit) * lineHeight +
    textInputPaddingVertical * 2
  let textInputHeight = Math.max(currentLineHeight, textInputMinHeight)

  let wrapperStyleProps = useSpring({
    height: textInputHeight,
    config: {
      tension: 500,
      friction: 15,
      mass: 0.1,
    },
  })

  const onClose = useCallback(() => {
    modalsDispatch({ type: 'modal closed', modalName: 'previewPhoto' })
    dispatch({ type: 'preview background removed' })
    setHide(false)
    setDarkenFilter(0)
  }, [dispatch, modalsDispatch])

  let { registerKeyEvent, unregisterKeyEvent } = useKeyContext()

  let modalData = modalsState.modalsData[
    'previewPhoto'
  ] as PreviewPhotoModalData

  let doUpload = modalData?.doUpload
  useEffect(() => {
    if (!modalData) return
    registerKeyEvent({
      id: 'confirm-photo-upload',
      action: () => {
        doUpload?.()
        onClose()
      },
      key: 'Enter',
    })

    return () => {
      unregisterKeyEvent({ id: 'confirm-photo-upload', key: 'Enter' })
    }
  }, [doUpload, modalData, onClose, registerKeyEvent, unregisterKeyEvent])
  if (!modalData) return null

  // const imageWidth = 220 * (modalData.dimensions.w / modalData.dimensions.h)
  const imageDims = calculateImageDimensions(modalData.dimensions, 220)
  return (
    <ModalWrapper
      isOpen={modalsState.modals.includes('previewPhoto')}
      onClose={onClose}
      hide={shouldHide}
    >
      <View
        style={{
          width: 215,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View
          style={[
            {
              width: imageDims.w,
              height: imageDims.h,
              borderRadius: 8,

              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',

              borderWidth: 1,
              borderColor: theme.colors.tertiaryLight,
              borderStyle: 'dashed',
            },
            shouldHide && {
              opacity: 0,
            },
          ]}
        >
          {modalData.photoUrl && (
            // React native <Image> tag perf is bad
            <img
              src={modalData.photoUrl}
              width={imageDims.w}
              height={imageDims.h}
              style={{
                width: imageDims.w,
                height: imageDims.h,
                borderRadius: 8,
              }}
            />
          )}
        </View>
        {modalData.textMessage && <Text>{modalData.textMessage}</Text>}
        <Space vertical={10} />
        <View
          style={[
            {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            },
            shouldHide && {
              opacity: 0,
            },
          ]}
        >
          <StandardButton
            text="Cancel"
            backgroundColor="buttonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={() => {
              onClose()
            }}
          />
          <Space horizontal={10} />
          <StandardButton
            text={'Upload'}
            backgroundColor="primaryButtonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={() => {
              modalData.doUpload()
              onClose()
            }}
          />
        </View>
      </View>
    </ModalWrapper>
  )
}
