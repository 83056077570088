import { ChatMessageInfo } from '@there/components/types/chat'
import { BucketEndpoint } from '@there/shared/api/config'
import { DocumentInfo } from '@there/sun/utils/node-types'

/**
 * Used for sidebar and notification subtitle
 */
export const getMessageOneLiner = (message: ChatMessageInfo) => {
  let messageContent = message.text
    ? message.text.slice(0, 124)
    : message.mediaObjectId?.endsWith('.gif') ||
      message.document?.type === 'Gif'
    ? 'GIF'
    : message.mediaObjectId || message.document?.type === 'Photo'
    ? 'Photo'
    : message.document?.type === 'File'
    ? 'File'
    : ''

  return messageContent
}

export const getMessageMediaFullPath = (message: ChatMessageInfo) => {
  if (message.document?.type == 'Photo') {
    return `${BucketEndpoint}/${message.document.objectId}`
  } else {
    return `${BucketEndpoint}/${message.mediaObjectId}`
  }
}

export const getMessageMediaThumbDataUri = (message: ChatMessageInfo) => {
  if (message.document?.type == 'Photo') {
    return message.thumbObjectId
      ? `${BucketEndpoint}/${message.document.thumbObjectId}`
      : undefined
  } else {
    return message.thumbObjectId
      ? `${BucketEndpoint}/${message.thumbObjectId}`
      : undefined
  }
}

export const getMessageFileFullPath = (message: ChatMessageInfo) => {
  if (message.document) {
    return `${BucketEndpoint}/${message.document.objectId}`
  } else {
    return `${BucketEndpoint}/${message.mediaObjectId}`
  }
}
export const getFileFullPath = (document: DocumentInfo) => {
  return `${BucketEndpoint}/${document.objectId}`
}
export const getMediaThumbDataUri = (document: DocumentInfo) => {
  return `${BucketEndpoint}/${document.thumbObjectId}`
}
