import React from 'react'
import { useVolumeContext } from '../shared/VolumeContext'
import { VolumeIcon } from './VolumeManager'
import { Slider } from '../shared/Slider'
import { useTheme } from './ThemeContext'

const handleWidth = 18

export const UserVolumeManager = ({ userId }: { userId: string }) => {
  const { usersVolume, dispatch } = useVolumeContext()
  let theme = useTheme()

  return (
    <Slider
      onValueChange={(value) => {
        dispatch({
          type: 'user volume changed',
          volume: +value.toFixed(0),
          userId,
        })
      }}
      value={Number(
        usersVolume && usersVolume[userId] ? usersVolume[userId] : 100,
      )}
      icon={<VolumeIcon color={theme.colors.popoverBg} />}
    />
  )
}
