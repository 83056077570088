import * as React from 'react'
import Svg, { SvgProps, Mask, Path, G } from 'react-native-svg'

export function LockIcon(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 10a2 2 0 012 2v6a2 2 0 01-2 2H7a2 2 0 01-2-2v-6c0-1.1.9-2 2-2V9a5 5 0 0110 0v1zm-5-4a3 3 0 00-3 3v1h6V9a3 3 0 00-3-3z"
        fill={props.color ? props.color : '#000'}
      />
    </Svg>
  )
}
