import { useEffect } from 'react'

export const usePaste = (onPaste: (file: File) => void) => {
  useEffect(() => {
    let handler = (event: ClipboardEvent) => {
      const data = event.clipboardData
      if (!data) return
      const file: File | undefined = data.files[0]
      if (!file) return

      if (
        !['image/jpeg', 'image/png', 'image/gif', 'image/webp'].includes(
          file.type,
        )
      ) {
        return
      }
      event.stopPropagation()
      event.preventDefault()

      onPaste(file)
    }
    document.addEventListener('paste', handler)

    return () => {
      document.removeEventListener('paste', handler)
    }
  }, [onPaste])
}
