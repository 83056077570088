import { useState } from 'react'
import { View, Text } from 'react-native'
import { SetFromTwitterModalData, useModals } from '../feed/ModalsContext'
import { StandardButton } from '../main/StandardButton'
import { StandardInput } from '../main/StandardInput'
import { Space } from '../shared/Space'
import { ModalDescription } from './ModalDescription'
import { ModalTitle } from './ModalTitle'
import { ModalWrapper } from './ModalWrapper'

export const SetFromTwitterModal = () => {
  let [modalsState, modalsDispatch] = useModals()
  const onClose = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'setFromTwitter' })
  }
  let modalData = modalsState.modalsData[
    'setFromTwitter'
  ] as SetFromTwitterModalData

  let [value, setValue] = useState('')

  return (
    <ModalWrapper
      isOpen={modalsState.modals.includes('setFromTwitter')}
      onClose={onClose}
    >
      <View
        style={{
          width: 215,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalTitle align="center">Set from twitter</ModalTitle>
        <Space vertical={10} />
        <View>
          <StandardInput
            autoFocus={true}
            placeholder="@Mostafa"
            value={value}
            setValue={setValue}
            background="transparentInputBackground"
            bordered={true}
            align="center"
            onSubmit={() => {
              if (modalData) {
                modalData.setTwitter(value)
              }
              onClose()
            }}
          />
          <Space vertical={10} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <StandardButton
              text="Cancel"
              backgroundColor="buttonBackground"
              textColor="secondaryText"
              size="normal"
              onPress={onClose}
            />
            <Space horizontal={10} />
            <StandardButton
              text="Submit"
              backgroundColor="primaryButtonBackground"
              textColor="secondaryText"
              size="normal"
              onPress={() => {
                if (modalData) {
                  modalData.setTwitter(value)
                }
                onClose()
              }}
            />
          </View>
        </View>
      </View>
    </ModalWrapper>
  )
}
