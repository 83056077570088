import Sentry from '@there/app/utils/sentry'
import { useNotificationSubscription } from '@there/components/main/useNotificationSubscription'
import { useLatest } from '@there/components/shared/use-latest'
import {
  initialNotificationsState,
  JoinRoomNotificationType,
  NotificationsDispatch,
  NotificationsState,
  NotificationType,
  useNotificationsState,
} from '@there/components/shared/use-notification-state'
import { ipc, useListenToIpc } from '@there/desktop/utils/electron-api'
import { UserInfo } from '@there/sun/utils/node-types'
import { useAtom } from 'jotai'
import ms from 'ms'
import { nanoid as shortid } from 'nanoid'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useUser } from '../atoms/userAtom'
import { currentDialogIdAtom } from '../feed/useLobbyMan'
import { useSoundEffect } from '../feed/useSoundEffect'
import { useDismissNotification } from '../main/useDismissNotification'
import { useNurQuery } from '../sun/use-query'
import { useAppContext } from './AppContext'
import { RtcManager } from './use-rtc'
import { useWindowContext } from './WindowContext'

/**
 * CONTEXT
 */

export interface NotificationHistory {
  batchNotificationIds: string[]
  userId: string
  time: number
  type: 'nudge' | 'clear all'
  user?: {
    name?: string
    profilePhoto?: string
  }
  spaceId?: string
}
export interface NotificationsContextValue {
  state: NotificationsState
  dispatch: NotificationsDispatch
  notifications: NotificationType[]

  haveActiveNotification: boolean

  notificationHistory: NotificationHistory[]
}

let batchNotificationTime = ms('2min')

export type NotificationsContextManager = NotificationsContextValue
export type NotificationsManager = NotificationsContextValue

let voidFunction = () => {}

const initialContext: NotificationsContextManager = {
  state: initialNotificationsState,
  dispatch: voidFunction,
  notifications: [],

  haveActiveNotification: false,
  notificationHistory: [],
}

export const NotificationsContext = createContext<NotificationsContextManager>(
  initialContext,
)
export const useNotificationsContext = () => {
  return useContext(NotificationsContext)
}

/**
 * MANAGEMENT HOOK
 */

type Props = {
  enabled: boolean
  activeSpaceId?: string | undefined
  avatarId?: string | undefined
  dialogId?: string | undefined

  rtcManager?: RtcManager

  joinUserDialog?: (variables: {
    spaceId: string
    dialogId: string
    userId: string
  }) => void
  getUser?: (id: string) => UserInfo | undefined
  joinDialog?: (dialogId: string) => void
}

export const useNotificationsManager = ({
  enabled,
  activeSpaceId,
  joinUserDialog,
  getUser,
  avatarId,
  dialogId,
  joinDialog,
  rtcManager,
}: Props): NotificationsContextManager => {
  let [state, dispatch] = useNotificationsState()
  let [currentDialogId] = useAtom(currentDialogIdAtom)
  let prepareToObserve = rtcManager?.prepareToObserve
  let callId = rtcManager?.walkieState.callId
  let [joinRoomSound] = useSoundEffect('/tones/join-room.mp3')
  let { currentUserId } = useAppContext()
  let getUserFunction = useLatest(getUser)
  let currentUser = useUser(currentUserId || '')

  // Get notification history list
  const [
    { data: notificationList, error, fetching, stale },
    refetch,
  ] = useNurQuery({
    method: 'notificationList',
    pause: !currentUserId,
  })

  // Batch NotificationList data
  let notificationHistory: NotificationHistory[] = useMemo(() => {
    if (!notificationList || !notificationList.notifications) return []

    let notificationHistory: NotificationHistory[] = []
    for (let notification of notificationList.notifications) {
      if (notification.senderId === currentUserId) continue

      // if notification should be in a existing item, get batch item
      let properNotificationIndex = notificationHistory.findIndex(
        (n) =>
          n.userId === notification.senderId &&
          Math.abs(n.time - notification.sentAt) < batchNotificationTime,
      )

      // push notification to existing batch item
      if (properNotificationIndex !== -1) {
        let properNotification = notificationHistory[properNotificationIndex]
        notificationHistory[properNotificationIndex] = {
          ...properNotification,
          batchNotificationIds: [
            ...properNotification.batchNotificationIds,
            notification.id,
          ],
        }
        continue
      }

      // if there is no created item for notification, create new batch
      notificationHistory.push({
        batchNotificationIds: [notification.id],
        userId: notification.senderId,
        time: notification.sentAt,
        type: 'nudge',
        user: {
          name: notification.user?.name,
          profilePhoto: notification.user?.profilePhoto,
        },
        spaceId: notification.spaceId,
      })
    }

    return notificationHistory
  }, [currentUserId, notificationList])

  let [haveActiveNotification, setActiveNotificationStatus] = useState<boolean>(
    false,
  )

  // Set incoming Notification
  const onNotification = useCallback(
    ({ data, error }) => {
      if (!enabled) return

      if (error) {
        console.warn('ignored notification')
        return
      }

      let notification = data as NotificationType
      if (!notification) {
        return
      }

      // Don't show nudge when user is in focus mode
      if (currentUser?.focused) {
        console.warn('ignored nudge notification, because we are focused')
        return
      }

      let senderUserData =
        getUserFunction.current &&
        getUserFunction.current(notification.senderId)

      let profilePhoto =
        notification.user?.profilePhoto ||
        senderUserData?.profilePhoto ||
        undefined
      let name =
        notification.user?.name ||
        senderUserData?.nickname ||
        senderUserData?.name ||
        undefined

      if (!profilePhoto && !name) {
        Sentry.captureMessage(
          'Notification received but senderUser is not recognized!',
        )
        console.warn('Notification received but senderUser is not recognized!')
        return
      }

      // Don't show [ join ] button when nudge is from the current room
      if (
        notification.type === 'nudge' &&
        notification.dialogId === currentDialogId
      ) {
        delete notification.dialogId
      }

      // Trigger a web notification for nudges if not in Electron
      if (
        notification.type === 'nudge' &&
        typeof window !== 'undefined' &&
        !isElectron
      ) {
        // trigger web notification for nudge
        if ('Notification' in window) {
          if (Notification.permission === 'granted') {
            new Notification(`👋 ${name} nudged`, {
              body: 'Open Noor to respond',
              icon: profilePhoto,
            })
          } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                new Notification(`👋 ${name} nudged`, {
                  body: 'Open Noor to respond',
                  icon: profilePhoto,
                })
              }
            })
          }
        }
      }

      dispatch({
        type: 'add incoming',
        notification: {
          ...notification,
          user: {
            name,
            profilePhoto,
          },
        },
      })
    },
    [currentDialogId, currentUser, dispatch, enabled, getUserFunction],
  )

  useNotificationSubscription({
    onData: onNotification,
  })

  // Handle responded notifications
  useEffect(() => {
    if (!enabled) return
    if (state.respondedNotifications.length === 0) return

    for (let notification of state.respondedNotifications) {
      switch (notification.type) {
        // when screen Invite responded
        case 'screenShareInvite':
          if (!activeSpaceId) return
          if (prepareToObserve) {
            prepareToObserve({
              callId: notification.callId,
              hostUserId: notification.senderId,
            })
          }
          if (callId !== notification.callId && joinUserDialog) {
            /**
             *  when we accepted screenInvite
             *  if we are not in the screenShare's call, join it
             * */
            joinUserDialog({
              userId: notification.senderId,
              dialogId: notification.callId,
              spaceId: activeSpaceId,
            })
          }
          break
        case 'nudge':
          if (!notification.dialogId) return
          if (!avatarId) return
          if (!activeSpaceId) return
          if (joinDialog) {
            joinDialog(notification.dialogId)
            ipc?.invoke('feed:focus')
          }
        default:
          break
      }
      dispatch({ type: 'response processed', notification })
    }
  }, [
    activeSpaceId,
    avatarId,
    callId,
    dispatch,
    enabled,
    joinDialog,
    joinUserDialog,
    prepareToObserve,
    state.respondedNotifications,
  ])

  // Check if we have active Notification
  useEffect(() => {
    for (let notificationArray of Object.values(state.incomingNotifications)) {
      if (notificationArray?.length > 0) {
        setActiveNotificationStatus(true)
        return
      }
    }
    setActiveNotificationStatus(false)
    return
  }, [state.incomingNotifications])

  // FIX ME
  let notifications = useMemo(() => {
    let notifications = new Array<NotificationType>()
    if (!state.incomingNotifications) return []

    Object.values(state.incomingNotifications).map((notificationsArray) => {
      // to check if its iterable
      if (!notificationsArray || notificationsArray.length === 0) return

      for (let notification of notificationsArray) {
        notifications.push(notification)
      }
    })
    return notifications.sort((a, b) => {
      if (a.type === b.type) {
        return b.sentAt - a.sentAt
      }
      if (a.type === 'nudge') return 1
      return -1
    })
  }, [state])

  let evilParticipantIdsRef = useRef<string[]>([])
  let participantIds = useMemo(() => {
    if (!rtcManager) return
    if (!rtcManager.participants) return
    let participants = rtcManager.participants
    return participants.map((participant) => participant.user.id)
  }, [rtcManager])

  let confirmedCallIdRef = useRef<string | undefined>(undefined)
  let callIdRef = useLatest(dialogId)

  let currentUserIdRef = useLatest(currentUserId)

  let { windowFocused, isElectron } = useWindowContext()
  let isElectronRef = useLatest(isElectron)
  let windowFocusedRef = useLatest(windowFocused)

  // Show join notification when participant joins
  useEffect(() => {
    if (!enabled) return
    if (!getUserFunction.current) return
    if (!participantIds || participantIds.length === 0) return

    // initiate join privilege
    // do not show joined participant notif when we join a room
    if (!callIdRef.current) return
    if (
      !confirmedCallIdRef.current ||
      confirmedCallIdRef.current !== callIdRef.current
    ) {
      confirmedCallIdRef.current = callIdRef.current
      evilParticipantIdsRef.current = participantIds
    }

    // if participants increased, show join notification
    if (participantIds.length > evilParticipantIdsRef.current.length) {
      for (let participantId of participantIds) {
        // prevent to show ourself notification
        if (participantId === currentUserIdRef.current) continue

        // prevent to show notification multiple times
        if (evilParticipantIdsRef.current.includes(participantId)) continue

        // get participant user data
        let user = getUserFunction.current(participantId)
        if (!user) {
          console.warn('[notification] [joinRoom] user is not recognized')
          continue
        }

        // if window focused, do not show join notification
        if (windowFocusedRef.current && isElectronRef.current) {
          continue
        }
        dispatch({
          type: 'add incoming',
          notification: {
            id: shortid(),
            senderId: participantId,
            user: {
              name: user.name || undefined,
              profilePhoto: user.profilePhoto || undefined,
            },
            sentAt: Date.now(),
            type: 'joinRoom',
          } as JoinRoomNotificationType,
        })
        joinRoomSound()
      }
    }

    evilParticipantIdsRef.current = participantIds
  }, [
    callIdRef,
    currentUserIdRef,
    dispatch,
    enabled,
    getUserFunction,
    isElectronRef,
    joinRoomSound,
    participantIds,
    windowFocusedRef,
  ])

  // on windowFocused dismiss all notification in mini
  useEffect(() => {
    if (!enabled) return
    if (!windowFocused) return
    if (!isElectron) return
    dispatch({ type: 'all notifications dismissed' })
  }, [dispatch, enabled, isElectron, windowFocused])

  let [, dismissNotification] = useDismissNotification()

  // Get Notification Actions (Dismiss)
  useListenToIpc(
    'notification:onAction',
    useCallback(
      (_, data) => {
        if (!enabled) return
        if (!data || !data.notificationId) return
        dismissNotification({
          clearAll: false,
          senderId: data.notificationId,
        })
      },
      [dismissNotification, enabled],
    ),
  )

  const value: NotificationsContextManager = useMemo(() => {
    return {
      state,
      dispatch,
      notifications,
      haveActiveNotification,
      notificationHistory,
    }
  }, [
    state,
    dispatch,
    notifications,
    haveActiveNotification,
    notificationHistory,
  ])
  return value
}
