import { memo, useMemo } from 'react'
import { Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { EmojiView } from '../main/EmojiView'
import { Pressable } from '../shared/Pressable'
import { Tooltip } from '../shared/Tooltip'
import { useUsersContext } from '../shared/UsersContext'
import { getShortName } from '../utils/get-short-name'
import { useTransition, a } from '@react-spring/native'
import { useAppContext } from '../shared/AppContext'
import { rgba } from 'polished'

type Props = {
  reactions: Record<string, string[]>
  toggleReaction: (emoji: string) => void
}
export const Reactions = memo(({ reactions, toggleReaction }: Props) => {
  let { getUser } = useUsersContext()
  let { currentUserId } = useAppContext()

  let transition = useTransition(Object.keys(reactions), {
    key: (item: any) => item,
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: { opacity: 0 },
    config: { tension: 400, friction: 18, mass: 0.5 },
  })

  return (
    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
      {transition(({ opacity }, emoji) => {
        let userIds = reactions[emoji]
        if (!userIds || userIds.length === 0) return null

        let userNames = (userIds || []).map((id) => {
          let user = getUser(id)
          if (!user) return
          return getShortName(user)
        })
        let index = Object.keys(reactions).indexOf(emoji)
        let weReacted = currentUserId ? userIds.includes(currentUserId) : false
        return (
          <a.View style={{ opacity }}>
            <ReactionItem
              emoji={emoji}
              userIds={userIds}
              userNames={userNames.join(', ')}
              key={emoji}
              index={index}
              toggleReaction={toggleReaction}
              weReacted={weReacted}
            />
          </a.View>
        )
      })}
    </View>
  )
})

const ReactionItem = memo(
  ({
    emoji,
    userIds,
    index,
    toggleReaction,
    userNames,
    weReacted,
  }: {
    emoji: string
    userIds: string[]
    index: number
    toggleReaction: (emoji: string) => void
    userNames: string
    weReacted: boolean
  }) => {
    let theme = useTheme()
    return (
      <Tooltip label={userNames}>
        <Pressable
          onPress={() => {
            toggleReaction(emoji)
          }}
          style={({ hovered, pressed }) => [
            {
              width: 39,
              paddingVertical: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
              borderRadius: 100,
              marginLeft: index > 0 ? 5 : 0,
              height: 24,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              paddingHorizontal: 3,
              borderWidth: 1,
              borderColor: 'rgba(0, 0, 0, 0)',
            },
            weReacted && {
              borderColor: 'rgba(255, 255, 255, 0.25)',
            },
            hovered && {
              backgroundColor: 'rgba(255, 255, 255, 0.20)',
            },
            pressed && {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          ]}
        >
          <EmojiView size={20}>{emoji}</EmojiView>
          {userIds.length > 0 && (
            <Text
              style={{
                width: 16,
                color: theme.colors.tertiaryText,
                fontSize: theme.fontSizes.small,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {userIds.length}
            </Text>
          )}
        </Pressable>
      </Tooltip>
    )
  },
)
