import React, { useCallback, useEffect } from 'react'
import { Section, SectionHeader } from './SettingsUI'
import { Space } from '../shared/Space'
import { ToggleRow } from '@there/components/settings/ToggleRow'
import { useAppContext } from '@there/components/shared/AppContext'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { electronSupports, ipc } from '@there/desktop/utils/electron-api'
import { useWindowContext } from '@there/components/shared/WindowContext'
import { logEvent } from '@there/components/shared/use-analytics'
import { useNurMutation } from '../sun/use-mutation'
import {
  changeFeaturePreviewsMessage,
  ChangeFeaturePreviewsResult,
} from '@there/sun/modules/changeFeaturePreviews'

export const StatusSection = () => {
  let { typingStatus, dispatch, statusFeed, activeSpaceId } = useAppContext()
  let { isElectron, isMacOs } = useWindowContext()

  let [, changeFeaturePreviews] = useNurMutation<
    ChangeFeaturePreviewsResult,
    changeFeaturePreviewsMessage['payload']
  >({
    method: 'changeFeaturePreviews',
  })

  const updateFeedFeature = useCallback(
    (value: boolean) => {
      if (!activeSpaceId) return

      changeFeaturePreviews({
        featureName: 'feed',
        value: value,
        spaceId: activeSpaceId,
      })
    },
    [activeSpaceId, changeFeaturePreviews],
  )

  // Disable this section for now
  return null
  return (
    <Section>
      <SectionHeader>Status Settings</SectionHeader>
      <Space vertical={8} />

      <ToggleRow
        title="Show When I'm Typing (Mac Only)"
        description="Shows a small typing icon when you're active on your computer."
        disabledReason={!isElectron ? `Requires desktop app` : undefined}
        disabled={!isElectron || !isMacOs}
        value={!!typingStatus}
        onValueChange={(value) => {
          dispatch({ type: 'typing status changed', value })
          if (value) {
            logEvent('Global Typing Enabled')
          } else {
            logEvent('Global Typing Disabled')
          }
        }}
      />
      {/* <ToggleRow
        title="Enable Status Feed"
        description="Show statuses section in the space"
        value={!!statusFeed}
        onValueChange={(value) => {
          const newValue = !statusFeed
          dispatch({
            type: 'status feed changed',
            value: newValue,
          })
          updateFeedFeature(newValue)
        }}
      /> */}
    </Section>
  )
}
