import { View } from 'react-native'
import { PopoversPortal } from '../shared/ClientOnlyPortal'
import { useTransition, a, useSpring } from '@react-spring/native'
import { useTheme } from '../feed/ThemeContext'

export const ModalWrapper = ({
  children,
  isOpen,
  onClose,
  hide = false,
}: {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  hide?: boolean
}) => {
  let theme = useTheme()

  let openConfig = {
    tension: 300,
    friction: 24,
    mass: 0.4,
  }

  let closeConfig = {
    tension: 300,
    friction: 20,
    mass: 0.4,
  }

  const transitions = useTransition(isOpen, {
    from: {
      opacity: 0.7,
      y: 400,
    },
    enter: {
      opacity: 1,
      y: 0,
      config: openConfig,
    },
    leave: {
      opacity: 0.5,
      y: 1000,
      config: closeConfig,
    },
  })

  return (
    <PopoversPortal
      isOpen={isOpen}
      onClose={() => onClose()}
      backMode={hide ? 'transparent' : 'normal'}
      inside={
        <>
          {transitions(({ opacity, y }, item) => {
            return item ? (
              <a.View
                style={[
                  {
                    //@ts-ignore
                    cursor: 'default',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: [{ translateY: '-50%' }, { translateX: '-50%' }],
                    zIndex: 20,
                    height: '100vh',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',

                    // Workaround for animation issue in Settings
                    // @ts-ignore
                    pointerEvents: 'none',
                  },
                ]}
              >
                <a.View
                  style={[
                    {
                      position: 'relative',
                      paddingHorizontal: 12,
                      paddingVertical: 12,
                      borderRadius: theme.borderRadius.large,
                      borderWidth: 1,
                      borderColor: theme.colors.modalBorder,
                      backgroundColor: theme.colors.modalBackground,
                      overflow: 'hidden',

                      //@ts-ignore
                      backdropFilter: `blur(36px)`,

                      opacity,
                      transform: [{ translateY: y }],

                      // @ts-ignore
                      pointerEvents: 'all',
                    },
                    hide && {
                      backgroundColor: undefined,
                      //@ts-ignore
                      backdropFilter: undefined,
                      borderColor: 'rgba(0, 0, 0, 0)',
                    },
                  ]}
                >
                  {children}
                </a.View>
              </a.View>
            ) : null
          })}
        </>
      }
    />
  )
}
