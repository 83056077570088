import { throttle } from '@there/components/utils/schedulers'
import { useEffect, useState } from 'react'

interface WindowSize {
  width: number
  height: number
}

function useWindowSize({
  throttleMs = 200,
}: {
  throttleMs: number
}): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const handler = throttle(
      () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      },
      throttleMs,
      true,
    )

    // Set size at the first client-side load
    handler()

    window.addEventListener('resize', handler)

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [throttleMs])

  return windowSize
}

export default useWindowSize
