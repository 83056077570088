import { useTheme } from '@there/components/feed/ThemeContext'
import { Initials } from '@there/components/shared/Initials'
import { useLatest } from '@there/components/shared/use-latest'
import { UserInfo } from '@there/sun/utils/node-types'
import React, { memo, useCallback, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { TouchableOpacity, TouchableOpacityProps, View } from 'react-native'
import { DefaultTheme } from 'styled-components'
import { getProfileBackground } from '../utils/get-profile-background'

interface PartialUser {
  profilePhoto?: string | undefined | null
  name?: string | undefined | null
  email?: string | undefined | null
  nickname?: string | undefined | null
}

export type MinUserProfile = PartialUser

export const radiusConstant = 0.42

export type Props = {
  user: PartialUser
  size?: number
  borderWidth?: number
  borderColor?: keyof DefaultTheme['colors']
  innerBorderWidth?: number
  innerBorderColor?: keyof DefaultTheme['colors']
  style?: TouchableOpacityProps['style']
  onPress?: () => void
  onDoubleClick?: () => void
  icon?: React.ReactNode
}

export const ProfilePhoto = memo((props: Props) => {
  let {
    user,
    size = 36,
    borderWidth,
    borderColor,
    style,
    onPress,
    onDoubleClick,
    innerBorderWidth,
    innerBorderColor,
    icon,
  } = props

  let profilePhoto = user ? user.profilePhoto : undefined
  let t = useTheme()
  // let [imageLoading, setImageLoading] = useState(false)
  // let [loading, setLoading] = useState(false)
  let [failedToLoad, setFailedToLoad] = useState(false)

  // let stillImageLoading = useLatest(imageLoading)
  // useEffect(() => {
  //   if (!profilePhoto) return

  //   let timeout = setTimeout(() => {
  //     if (stillImageLoading.current) {
  //       setLoading(true)
  //     }
  //   }, 100)

  //   return () => {
  //     clearTimeout(timeout)
  //   }
  // }, [profilePhoto, stillImageLoading])

  // let { opacity } = useSpring({
  //   opacity: loading ? 0 : 1,
  //   config: config.wobbly,
  // })

  let weHaveBorder = Boolean(borderWidth && borderWidth > 0)
  let transparentSpaceWidth = size < 30 ? 0 : 1
  let wrapperSize = weHaveBorder
    ? size + Number(borderWidth) * 2 + transparentSpaceWidth * 2
    : size

  let innerWrapperSize = weHaveBorder ? size + transparentSpaceWidth * 2 : size

  let innerBorderRadius =
    wrapperSize < 30
      ? wrapperSize
      : weHaveBorder
      ? (size + transparentSpaceWidth * 2) * radiusConstant
      : size * radiusConstant

  let outerBorderRadius =
    wrapperSize < 30
      ? wrapperSize
      : weHaveBorder
      ? wrapperSize * radiusConstant
      : size * radiusConstant

  const onError = useCallback(() => {
    setFailedToLoad(true)
  }, [])

  let failedToLoadRef = useLatest(failedToLoad)
  useEffect(() => {
    if (failedToLoadRef.current === false) return
    setFailedToLoad(false)
  }, [failedToLoadRef, profilePhoto])

  return (
    <View
      style={{
        width: wrapperSize,
        height: wrapperSize,
        borderColor: borderColor ? t.colors[borderColor] : undefined,
        borderRadius: outerBorderRadius,
        borderWidth: borderWidth,
        overflow: 'hidden',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TouchableOpacity
        activeOpacity={0.85}
        disabled={!onPress && !onDoubleClick}
        onPress={onPress}
        //@ts-ignore
        onDoubleClick={onDoubleClick}
        style={[
          {
            position: 'relative',
            width: innerWrapperSize,
            height: innerWrapperSize,
            overflow: 'hidden',
            borderWidth: weHaveBorder ? transparentSpaceWidth : 0,
            borderColor: t.colors.transparent,
            borderRadius: innerBorderRadius,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          //@ts-ignore
          // { opacity: opacity },
          style,
        ]}
      >
        {profilePhoto && !failedToLoad && !icon ? (
          <Photo uri={profilePhoto} size={size} onError={onError} />
        ) : (
          <View
            style={{
              width: size,
              height: size,
              backgroundColor: getProfileBackground(
                user.name || user.email || 'user',
              ),

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon ? (
              icon
            ) : (
              <Initials name={user.name || user.email || ''} size={size} />
            )}
          </View>
        )}

        {innerBorderWidth ? (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: size,
              height: size,
              borderRadius: size * radiusConstant,
              borderWidth: innerBorderWidth,
              borderColor: innerBorderColor
                ? t.colors[innerBorderColor]
                : 'transparent',
            }}
          />
        ) : null}
      </TouchableOpacity>
    </View>
  )
}, isEqual)

const Photo = memo(
  ({
    uri,
    size,
    onError,
  }: {
    uri: string
    size: number
    onError?: () => void
  }) => {
    return (
      // FIXME: until we fix the flash of loading
      // <Image
      //   source={{ uri }}
      //   width={size}
      //   height={size}
      //   style={{ width: size, height: size }}
      //   onError={onError}
      // />
      <img
        src={uri}
        width={size}
        height={size}
        style={{ width: size, height: size }}
        onError={onError}
      />
    )
  },
)

export function minifyUserForProfilePhoto(user: UserInfo): PartialUser {
  return {
    profilePhoto: user.profilePhoto,
    name: user.name,
    nickname: user.nickname,
    email: user.email,
  }
}
