interface Nominal<T> {
  'nominal structural brand': T
}

// export type GlobalId = Nominal<string>
/** To prevent some type errors, for now it's just a normal string */
export type GlobalId = string

export type ResolvedGlobalId = {
  type: string
  id: string
}

/**
 * Takes a type name and an ID specific to that type name, and returns a
 * "global ID" that is unique among all types.
 */
export function toGlobalId(type: string, id: GlobalId | string): GlobalId {
  if (((id as unknown) as string).includes(':')) {
    // already global
    return (id as unknown) as GlobalId
  }

  return (`${type}:${id}` as unknown) as GlobalId
}

/**
 * Takes the "global ID" created by toGlobalID, and returns the type name and ID
 * used to create it.
 */
export function fromGlobalId(globalId: string): ResolvedGlobalId {
  const delimiterPos = globalId.indexOf(':')
  return {
    type: globalId.slice(0, delimiterPos),
    id: globalId.slice(delimiterPos + 1),
  }
}
/**
 * Takes the "global ID" created by client, and checks it.
 */
export function validateInputGlobalId(
  inputGlobalId: string,
  type: string,
): string | undefined {
  if (!inputGlobalId.includes(':')) {
    return
  }

  let resolved = fromGlobalId(inputGlobalId)
  if (resolved.type !== type) {
    return
  }

  return resolved.id
}
