import { useLatest } from '@there/components/shared/use-latest'
import { ChatMessageInfo } from '@there/components/types/chat'
import { NewMessageInfo } from '@there/sun/utils/node-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSeenMessage } from '../main/useSeenMessage'
import { NewChatWithPeer } from '../shared/SpaceChatsContext'
import { useDebounceCallback } from '../shared/use-debounce-callback'
import { useNurMutation } from '../sun/use-mutation'

// Refactor
export const useSeenMessageManager = (
  activeChat: NewChatWithPeer | undefined,
  activeSpaceId: string | undefined,
  messages: ChatMessageInfo[],
) => {
  // should run mutation every 100ms when we have messages to seen
  let [seenMessagesNum, setSeenMessagesNum] = useState(0)
  let [lastSeenMessage, setLastSeenMessage] = useState<
    undefined | NewMessageInfo
  >(undefined)
  let [lastSeenMessageId, setLastSeenMessageId] = useState<string | undefined>(
    undefined,
  )

  useEffect(() => {
    if (!activeChat?.lastSeenMessageId) return

    setLastSeenMessageId(activeChat.lastSeenMessageId)
  }, [activeChat?.lastSeenMessageId])

  let lastSeenMessageSentAt = useMemo(() => {
    if (!messages || !lastSeenMessageId) {
      return
    }

    let lastSeenMessage = messages.find(
      (message) => message.id === lastSeenMessageId,
    )

    return lastSeenMessage?.sentAt || 0
  }, [lastSeenMessageId, messages])
  let lastSeenMessageSentAtRef = useLatest(lastSeenMessageSentAt)

  let lastSeenMessageRef = useLatest(lastSeenMessage)

  const seenMessage = useCallback(
    (message: NewMessageInfo) => {
      // if seen newer message before
      if (
        lastSeenMessageRef.current &&
        lastSeenMessageRef.current?.sentAt > message.sentAt
      ) {
        return
      }

      setLastSeenMessage(message)
      setLastSeenMessageId(message.id)
      setSeenMessagesNum((num) => num + 1)
    },
    [lastSeenMessageRef],
  )

  let [, doSeenMessage] = useSeenMessage()

  const syncSeenMessage = useCallback(() => {
    if (!activeSpaceId) return
    if (!activeChat) return
    if (!lastSeenMessageId) return
    if (seenMessagesNum === 0) return

    let topMessageId = activeChat.topMessageId
    let seenNum = seenMessagesNum

    if (lastSeenMessageId === topMessageId) {
      // Prevent minus
      seenNum = Math.max(0, activeChat.unreadCount)
    }
    doSeenMessage({
      chatId: activeChat.id,
      lastSeenMessageId: lastSeenMessageId,
      seenMessagesNum: seenNum,
      peerUserId: activeChat.peerUserId ? activeChat.peerUserId : undefined,
      __extra: {
        spaceId: activeSpaceId,
        type: activeChat.peerUserId ? 'userChat' : 'topicChat',
      },
    })

    // reset seen messages after run
    setLastSeenMessage(undefined)
    setSeenMessagesNum(0)
  }, [
    activeChat,
    activeSpaceId,
    doSeenMessage,
    lastSeenMessageId,
    seenMessagesNum,
  ])

  let { debouncedCallback } = useDebounceCallback(() => {
    syncSeenMessage()
  }, 100)

  useEffect(() => {
    if (!lastSeenMessageId) return
    if (seenMessagesNum === 0) return
    debouncedCallback()
  }, [
    debouncedCallback,
    // lastSeenMessage,
    lastSeenMessageId,
    seenMessagesNum,
    syncSeenMessage,
  ])

  let topMessageId = activeChat?.topMessageId

  const seenMultiple = useCallback(
    (messages: NewMessageInfo[]) => {
      let newestMessage = messages[0]
      let seenCount = 0

      // Find the newest in batch
      for (let message of messages) {
        if (message.sentAt > newestMessage.sentAt) {
          newestMessage = message
        }

        // See if we haven't seen this message also, increase count
        if (
          typeof lastSeenMessageSentAt === 'number' &&
          lastSeenMessageSentAt <= message.sentAt &&
          lastSeenMessageId !== message.id
        ) {
          seenCount++
        }
      }

      // Reset issue
      // Workaround for when last seen message id is undefined
      if (messages.length > 0 && !lastSeenMessageId && topMessageId) {
        console.info('resetting seen issue')
        setLastSeenMessageId(topMessageId)
        setSeenMessagesNum(
          (num) =>
            num +
            // Fake, just so the sync updates
            1,
        )
        return
      }

      // if seen newer message before
      if (seenCount === 0) {
        console.info('No need to seen.')
        return
      }

      console.info('Seen', seenCount, 'messages')

      setLastSeenMessage(newestMessage)
      setLastSeenMessageId(newestMessage.id)
      setSeenMessagesNum((num) => num + seenCount)
    },
    [lastSeenMessageId, lastSeenMessageSentAt, topMessageId],
  )

  return { seenMessage, lastSeenMessageId, seenMultiple, lastSeenMessageSentAt }
}
