import { a, useSpring } from '@react-spring/native'
import { useKeyContext } from '@there/components/feed/KeyContext'
import { SmallCrossIcon } from '@there/components/main/mainIcons'
import { Pressable } from '@there/components/shared/Pressable'
import { useWidth } from '@there/components/shared/use-width'
import { useEffect, useRef, useState } from 'react'
import { TextInput, View } from 'react-native'
import Svg, { Path, SvgProps } from 'react-native-svg'
import { useTheme } from '../feed/ThemeContext'
import { Space } from '../shared/Space'

const INPUT_HEIGHT = 28

export const SideSearch = ({
  onValueChange,
}: {
  onValueChange: (value: string) => void
}) => {
  let theme = useTheme()
  let [value, setValue] = useState('')
  let [isFocus, setFocus] = useState(false)
  let inputRef = useRef<null | TextInput>(null)
  let [width, widthHandlers] = useWidth()

  let { registerKeyEvent, unregisterKeyEvent } = useKeyContext()

  useEffect(() => {
    registerKeyEvent({
      id: 'side-search',
      action: () => {
        inputRef.current?.focus()
      },
      key: 'f',
      ctrl: true,
    })

    return () => {
      unregisterKeyEvent({ id: 'side-search', key: 'f' })
    }
  }, [registerKeyEvent, unregisterKeyEvent])

  useEffect(() => {
    if (!inputRef || !inputRef.current) return
    if (!isFocus) return
    inputRef.current.focus()
  }, [isFocus])

  let offCenter = 28
  let styleProps = useSpring({
    x: width ? (isFocus || value ? 10 : width / 2 - offCenter) : undefined,
    opacity: width ? 1 : 0,
    config: {
      mass: 0.3,
      tension: 300,
      friction: 20,
    },
  })

  let crossProps = useSpring({
    scale: isFocus || value ? 1 : 0,
    delay: 200,
    config: {
      mass: 0.3,
      tension: 300,
      friction: 20,
    },
  })

  return (
    <View
      style={{
        height: INPUT_HEIGHT,
        flex: 1,
      }}
      {...widthHandlers}
    >
      <Pressable
        style={{
          //@ts-ignore
          cursor: 'text',
        }}
        onPress={() => {
          if (isFocus) return
          inputRef.current?.focus()
        }}
      >
        <a.View
          style={{
            // backgroundColor: 'rgba(255, 255, 255, 0.05)',
            backgroundColor: 'rgba(199, 219, 237, 0.09)',
            opacity: width ? 1 : 0,
            height: '100%',
            width: '100%',
            borderRadius: 8,
            paddingLeft: styleProps.x,
            paddingRight: 8,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <View>
            <SearchIcon
              color={theme.colors.quinaryText}
              width={14}
              style={{ flexShrink: 0 }}
            />
          </View>
          <Space horizontal={5} />
          <TextInput
            ref={inputRef}
            style={{
              lineHeight: INPUT_HEIGHT,
              color: theme.colors.secondaryText,
              width: '100%',
            }}
            placeholder="Find"
            placeholderTextColor={theme.colors.quinaryText}
            value={value}
            onKeyPress={(event) => {
              //@ts-ignore
              if (event.key == 'Escape') {
                setFocus(false)
                inputRef.current?.blur()
              }
            }}
            onFocus={() => {
              setFocus(true)
            }}
            onChangeText={(text) => {
              setValue(text)
              onValueChange(text)
            }}
          />
          <a.View
            style={{
              transform: [{ scale: crossProps.scale }],
              opacity: isFocus || value ? 1 : 0,
            }}
          >
            <Pressable
              style={{
                width: 14,
                height: 14,
                borderRadius: 16,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() => {
                setFocus(false)
                setValue('')
                onValueChange('')
              }}
            >
              <SmallCrossIcon color={theme.colors.tertiaryText} />
            </Pressable>
          </a.View>
        </a.View>
      </Pressable>
    </View>
  )
}

export function SearchIcon(props: SvgProps) {
  return (
    <Svg width={19} height={19} viewBox="0 0 19 19" fill="none" {...props}>
      <Path
        d="M13.82 7.29a6.52 6.52 0 01-6.53 6.53 6.54 6.54 0 116.53-6.53zm-2.15 5.04l6 6"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
      />
    </Svg>
  )
}
