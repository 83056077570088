import { DefaultStorage } from '@urql/exchange-graphcache/dist/types/default-storage'

export const makeLocalStorage = (): DefaultStorage => {
  const cache = {}

  return {
    async writeData(delta: any) {
      return Promise.resolve().then(() => {
        Object.assign(cache, delta)
        localStorage.setItem('urql', JSON.stringify(cache))
      })
    },

    async readData() {
      return Promise.resolve().then(() => {
        const local = localStorage.getItem('urql') || '{}'
        Object.assign(cache, JSON.parse(local))
        return cache
      })
    },

    async writeMetadata(data: any) {
      localStorage.setItem('urqlMetadata', JSON.stringify(data))
    },

    async readMetadata() {
      return Promise.resolve().then(() => {
        const metadataJson = localStorage.getItem('urqlMetadata') || '{}'
        return JSON.parse(metadataJson)
      })
    },

    async clear() {
      return Promise.resolve().then(() => {
        localStorage.removeItem('urql')
        localStorage.removeItem('urqlMetadata')
      })
    },

    onOnline(cb: () => void) {
      window.addEventListener('online', () => {
        cb()
      })
    },
  }
}
