import { useWindowContext } from '@there/components/shared/WindowContext'
import { electronSupports, isMacOS } from '@there/desktop/utils/electron-api'
import { useEffect, useState } from 'react'

let SUPPORTS_CONTROL_OVERLAY =
  typeof navigator !== 'undefined' && 'windowControlsOverlay' in navigator

export const useSafeTopBarStyles = () => {
  let { isElectron, isMacOs, isWindows } = useWindowContext()
  let showCustomMacClose = isElectron && !electronSupports?.nativeTitleBar

  let [controlsBounds, setControlsBounds] = useState({
    x: 0,
    y: 0,
    height: 0,
    width: '100%',
    left: 0,
    right: 0,
  })

  useEffect(() => {
    if (
      // @ts-ignore
      !navigator.windowControlsOverlay ||
      // @ts-ignore
      (!('getBoundingClientRect' in navigator.windowControlsOverlay) &&
        // @ts-ignore
        !('getTitlebarAreaRect' in navigator.windowControlsOverlay))
    ) {
      return
    }

    let setBounds = () => {
      let bounds
      // @ts-ignore
      if ('getBoundingClientRect' in navigator.windowControlsOverlay) {
        // @ts-ignore
        bounds = navigator.windowControlsOverlay.getBoundingClientRect()
        // @ts-ignore
      } else if ('getTitlebarAreaRect' in navigator.windowControlsOverlay) {
        // @ts-ignore
        bounds = navigator.windowControlsOverlay.getTitlebarAreaRect()
      }

      setControlsBounds(bounds)
    }

    setBounds()

    // @ts-ignore
    navigator.windowControlsOverlay.addEventListener(
      'geometrychange',
      setBounds,
    )

    return () => {
      // @ts-ignore
      navigator.windowControlsOverlay.removeEventListener(
        'geometrychange',
        setBounds,
      )
    }
  }, [])

  return {
    paddingLeft: controlsBounds.left ? controlsBounds.left : undefined,

    width:
      typeof controlsBounds.width === 'string'
        ? controlsBounds.width
        : typeof controlsBounds.width === 'undefined'
        ? '100%'
        : controlsBounds.width + controlsBounds.left,

    // FALLBACK
    ...(!SUPPORTS_CONTROL_OVERLAY &&
    isElectron &&
    isWindows &&
    // if custom, we show a mac close button
    !showCustomMacClose
      ? {
          paddingRight: 100,
        }
      : {}),

    ...(!SUPPORTS_CONTROL_OVERLAY &&
    isElectron &&
    (isMacOS || showCustomMacClose)
      ? {
          paddingLeft: 74,
        }
      : {}),

    ...(!isElectron ? { width: '100%' } : {}),
  }
}
