import { View, Text } from 'react-native'
import {
  AlertModalData,
  ConfirmModalData,
  useModals,
} from '../feed/ModalsContext'
import { StandardButton } from '../main/StandardButton'
import { Space } from '../shared/Space'
import { ModalDescription } from './ModalDescription'
import { ModalTitle } from './ModalTitle'
import { ModalWrapper } from './ModalWrapper'

export const AlertModal = () => {
  let [modalsState, modalsDispatch] = useModals()
  const onSubmit = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'alert' })
    modalData.onSubmit()
  }
  const onDismiss = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'alert' })
    modalData.onDismiss()
  }
  let modalData = modalsState.modalsData['alert'] as AlertModalData

  if (!modalData) return null
  return (
    <ModalWrapper
      isOpen={modalsState.modals.includes('alert')}
      onClose={onDismiss}
    >
      <View
        style={{
          width: 230,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {modalData.alertIcon ? (
          <View style={{ marginBottom: 5 }}>{modalData.alertIcon}</View>
        ) : null}
        <ModalTitle align="center">{modalData.alertText}</ModalTitle>
        {modalData.alertDescription ? (
          <>
            <Space vertical={5} />
            <ModalDescription
              align={modalData.textAlign === 'normal' ? 'normal' : 'center'}
            >
              {modalData.alertDescription}
            </ModalDescription>
          </>
        ) : null}
        <Space vertical={10} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <StandardButton
            text={modalData.submitLabel ? modalData.submitLabel : 'Close'}
            backgroundColor="buttonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={() => {
              onSubmit()
            }}
          />
        </View>
      </View>
    </ModalWrapper>
  )
}
