import { useCallback } from 'react'
import { useModals } from '../feed/ModalsContext'

export const useToast = (props: {
  icon?: React.ReactNode
  title?: string | undefined
  description?: string | undefined
  submitLabel?: string | undefined
  dismissLabel?: string | undefined
}) => {
  let { title, icon, description, dismissLabel, submitLabel } = props
  let [modalsState, modalsDispatch] = useModals()

  let openModal = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      modalsDispatch({
        type: 'modal opened',
        modalName: 'toast',
        modalData: {
          title,
          icon,
          description,
          dismissLabel,
          submitLabel,

          onSubmit: () => resolve(),
          onDismiss: () => reject(),
        },
      })
    })
  }, [description, dismissLabel, icon, modalsDispatch, submitLabel, title])

  return { openModal }
}
