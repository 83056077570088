import { useNurClient } from '@there/components/sun/context'
import { SubscriptionResult } from '@there/components/sun/utils/types'
import { useLatest } from '@there/components/shared/use-latest'
import { useCallback, useEffect, useRef, useState } from 'react'

export interface SubscriptionInput<Data = any> {
  method: string
  pause?: boolean
  onData?: (result: SubscriptionResult<Data>) => void
  /** Optimize perf for got signal */
  callbackOnly?: boolean
}

export const useNurSubscription = <Data = any>(
  input: SubscriptionInput<Data>,
): [SubscriptionResult<Data>, () => void] => {
  let client = useNurClient()
  let [result, setResult] = useState<SubscriptionResult<Data>>({
    data: null,
    error: null,
  })

  let callbackRef = useLatest(input.onData)
  let { pause, callbackOnly } = input

  const execute = useCallback(() => {
    if (!client || pause) {
      return { unsubscribe: () => {} }
    }

    let op = client.subscribe(
      { method: input.method },
      {
        next: (result) => {
          if (!callbackOnly) {
            setResult(result)
          }
          callbackRef.current?.(result)
        },
        complete: () => {},
      },
    )

    return op
  }, [callbackOnly, callbackRef, client, input.method, pause])

  useEffect(() => {
    let { unsubscribe } = execute()

    return () => {
      unsubscribe()
    }
  }, [execute])

  return [result, execute]
}
