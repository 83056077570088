import { useNurClient } from '@there/components/sun/context'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ObjectNode } from '@there/components/sun/cache'
import fastCompare from 'react-fast-compare'
import { useLatest } from '@there/components/shared/use-latest'
import useIsMounted from '@there/components/hooks/useIsMounted'

interface NodeInput {
  id: string | null | undefined
}

export const useNurNode = <NodeType extends ObjectNode>(
  node: NodeInput,
): [
  node: NodeType | null,
  tools: { getNode: (input: NodeInput) => NodeType | null },
] => {
  let client = useNurClient()
  let [result, setResult] = useState<ObjectNode | null>(null)
  let resultRef = useLatest(result)
  let isMounted = useIsMounted()

  let nodeId = node.id

  let getNode: (input: NodeInput) => NodeType | null = useCallback(
    (input) => {
      if (!client || !input.id) {
        // Reset
        return null
      }

      return client.cache.readNode({ id: input.id }) as NodeType | null
    },
    [client],
  )

  useEffect(() => {
    if (!client) {
      return
    }

    if (!nodeId) {
      // Reset
      setResult(null)
      return
    }

    let mounted = true

    let op = client.node({ id: nodeId }, (newResult) => {
      if (fastCompare(newResult, resultRef.current)) {
        // TODO: move this into the Sun layer like urql
        // Equal, skip render
        return
      }
      if (!mounted) return
      if (!isMounted.current) return
      setResult(newResult)
    })

    return () => {
      mounted = false
      op.unsubscribe()
    }
  }, [client, isMounted, nodeId, resultRef])

  return [result as NodeType, useMemo(() => ({ getNode }), [getNode])]
}
