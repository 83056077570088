import gql from 'graphql-tag'
import { useMutation } from 'urql'

const ChangeSpaceMutation = gql`
  mutation ChangeSpaceMutation($spaceId: GlobalID!) {
    done: changeSpace(spaceId: $spaceId)
  }
`

export type Variables = {
  spaceId: string
}

export type ChangeSpaceData = {
  done: boolean
}

export function useChangeSpace() {
  return useMutation<ChangeSpaceData, Variables>(ChangeSpaceMutation)
}
