import { memo } from 'react'
import { View } from 'react-native'
import { CircleButton } from '../main/CircleButton'
import { useTheme } from '@there/components/feed/ThemeContext'
import { MicrophonePicker } from '@there/components/feed/MicrophonePicker'
import Svg, { SvgProps, Path } from 'react-native-svg'
import { GoodButton } from '@there/components/shared/GoodButton'

export const PickerButton = memo(() => {
  let theme = useTheme()

  return (
    <>
      <MicrophonePicker
        includeCameras={true}
        toggleComponent={({ active }) => (
          <GoodButton
            onPressHandled={true}
            active={active}
            icon={<ThreeDots color={theme.colors.quaternaryLight} />}
          />
        )}
      />
    </>
  )
})

function ThreeDots(props: SvgProps) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M3.333 9.333a1.333 1.333 0 100-2.666 1.333 1.333 0 000 2.666zM8 9.333a1.333 1.333 0 100-2.666 1.333 1.333 0 000 2.666zM12.667 9.333a1.333 1.333 0 100-2.666 1.333 1.333 0 000 2.666z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}
