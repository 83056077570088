import { useNurMutation } from '@there/components/sun/use-mutation'
import { DeleteStatusReactionMessage } from '@there/sun/modules/deleteStatusReaction'
interface Data {
  done: boolean
}

type RemoteVariables = DeleteStatusReactionMessage['payload']
export interface LocalDeleteStatusReactionMessageVariables
  extends RemoteVariables {
  __extra: {
    userId: string
  }
}

export const useDeleteStatusReaction = () => {
  return useNurMutation<Data, LocalDeleteStatusReactionMessageVariables>({
    method: 'deleteStatusReaction',
  })
}
