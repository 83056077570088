import React, { memo } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  TouchableOpacityProps,
} from 'react-native'
import { ProfilePhoto } from '@there/components/feed/ProfilePhoto'
import { useAppContext } from '../shared/AppContext'
import { useRtcContext } from '../shared/use-rtc'
import { UserInfo } from '@there/sun/utils/node-types'
import { PuffSpinner } from '@there/components/shared/PuffSpinner'
import { Tooltip } from '@there/components/shared/Tooltip'
import { AvatarLeaveButton } from '@there/components/room/AvatarLeaveButton'
import { useHover } from '@there/components/shared/use-hover'

type Props = {
  user: UserInfo
  disconnectedBadge?: boolean
  onBadgePress?: () => void
  connecting?: boolean
}
export const Avatar = memo(
  ({ user, disconnectedBadge, onBadgePress, connecting }: Props) => {
    const { currentUserId } = useAppContext()
    const { walkieState, toggleTalk } = useRtcContext()
    let [hovered, hoverListeners] = useHover()

    let isUs = currentUserId === user.id
    let isTalking = isUs
      ? walkieState.weTalking
      : walkieState.participants[user.id]?.isTalking

    return (
      <View
        style={[
          {
            position: 'relative',

            // Add border
            width: 42,
            height: 42,
          },
          !user.online && { opacity: 0.6 },
        ]}
        {...hoverListeners}
      >
        <ProfilePhoto
          user={user}
          size={38}
          borderWidth={2}
          borderColor={isTalking ? 'avatarBorderTalking' : 'avatarBorder'}
          onPress={isUs ? toggleTalk : undefined}
        />

        {hovered && isUs && (
          <AvatarLeaveButton
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        )}

        {connecting ? (
          <ConnectingBadge />
        ) : (
          disconnectedBadge && <DisconnectedBadge onPress={onBadgePress} />
        )}
      </View>
    )
  },
)

export const DisconnectedBadge = memo((props: TouchableOpacityProps) => {
  return (
    <BadgeButton {...props}>
      <Image
        source={{
          height: 12,
          width: 12,
          uri: '/th-icons/disconnected@2x.png',
        }}
      />
    </BadgeButton>
  )
})

export const ConnectingBadge = memo((props: TouchableOpacityProps) => {
  return (
    <BadgeButton {...props}>
      <Tooltip
        label={
          <>
            Connecting...
            <br />
            Click to restart
          </>
        }
      >
        <PuffSpinner size={14} />
      </Tooltip>
    </BadgeButton>
  )
})

const BadgeButton = (
  props: TouchableOpacityProps & { children: React.ReactNode },
) => {
  return (
    <TouchableOpacity
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        width: 16,
        height: 16,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: `rgba(255,255,255,0.2)`,
        backgroundColor: `#4D4E53`,
      }}
    />
  )
}
