import { useRouter } from 'next/router'

export const useIsFeed = () => {
  let { pathname } = useRouter()

  const isFeed =
    pathname.startsWith('/feed') ||
    pathname.startsWith('/login') ||
    pathname.startsWith('/join-space') ||
    pathname.startsWith('/maintenance')

  return isFeed
}
