globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),

  get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }

};
import { createStitches } from '@stitches/react';
export var {
  getCssText,
  globalCss,
  styled,
  createTheme
} = createStitches({
  theme: {
    colors: {
      button: 'pink',
      text: 'black',
      appBg: '#f2f2f2'
    },
    fontSizes: {
      small: '11px',
      normal: '13px',
      large: '15px'
    },
    transitions: {
      colors: 'background-color 200ms, color 200ms'
    }
  },
  media: {
    mobile: "(max-width: 600px)"
  },
  utils: {
    paddingVertical: value => ({
      paddingTop: value,
      paddingBottom: value
    }),
    size: value => ({
      width: value,
      height: value
    }),
    dFlex: value => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    })
  }
});
export var darkTheme = createTheme({
  colors: {
    button: 'pink',
    text: 'white',
    appBg: 'black'
  }
});
export var globalStyles = globalCss({
  '*': {
    margin: 0,
    padding: 0
  },
  button: {
    border: 'none'
  }
});