import gql from 'graphql-tag'
import { useMutation } from 'urql'
import { userInfoFragment } from './fragments/userInfo'

export const SendEmailCodeMutation = gql`
  mutation sendEmailCode($email: String!) {
    sendEmailCode(email: $email) {
      ...userInfo
    }
  }
  ${userInfoFragment}
`

export type Variables = {
  email: string
}
export type Data = {
  sendEmailCode: boolean
}

export const useSendEmailCodeMutation = () =>
  useMutation<Data, Variables>(SendEmailCodeMutation)
