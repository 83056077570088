import { useNurMutation } from '@there/components/sun/use-mutation'
import type { SendNudgeMessage } from '@there/sun/modules/sendNudge'
interface Data {
  done: boolean
}

export const useSendNudge = () => {
  return useNurMutation<Data, SendNudgeMessage['payload']>({
    method: 'sendNudge',
  })
}
