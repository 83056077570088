import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg'

export function CheckingIcon(props: SvgProps) {
  return (
    <Svg width={50} height={50} viewBox="0 0 50 50" fill="none" {...props}>
      <G clipPath="url(#checkingIcon)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.8 27.32l-.71 6.87-.8 6.94 6.9-.8 6.82-.8-3.17-3.2a24.63 24.63 0 002.77-11.26C49.6 11.27 38.43 0 24.88 0 11.18 0 0 11.26 0 25.08A25 25 0 0024.88 50c4.68 0 9.2-1.44 13-3.84l-5.95-5.83a16.67 16.67 0 01-7.05 1.6C15.53 41.93 8 34.34 8 25.08A16.9 16.9 0 0124.88 8.07c9.2 0 16.71 7.58 16.71 17 0 1.69-.31 3.6-.8 5.12l-2.99-2.87z"
          fill={props.color ? props.color : '#727270'}
        />
      </G>
      <Defs>
        <ClipPath id="checkingIcon">
          <Path fill="#fff" d="M0 0h50v50H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
