import { crispUrl, legalDocument } from '@there/components/main/HelpMenu'
import { Pressable } from '@there/components/shared/Pressable'
import { Space } from '@there/components/shared/Space'
import { electronApi, ipc } from '@there/desktop/utils/electron-api'
import { Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'

export const Footer = () => {
  let theme = useTheme()

  let openLink = (link: string) => {
    if (electronApi) {
      electronApi?.openExternalUrl(link)
    } else {
      window.open(link, '__blank')
    }
  }

  let textSize = theme.fontSizes.small
  let textColor = theme.colors.quaternaryText
  let textColorHover = theme.colors.tertiaryText
  let textColorActive = theme.colors.quaternaryLight

  return (
    <View
      style={[
        {
          position: 'absolute',
          bottom: 12,
          left: 0,
          right: 0,
          width: '100%',
          paddingHorizontal: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexWrap: 'wrap',
          height: 'auto',
        },
      ]}
    >
      <Space vertical={20} />
      <Text
        style={{
          fontSize: textSize,
          color: textColor,
          letterSpacing: -0.01,
        }}
        numberOfLines={1}
      >
        Built from 2019-2024 © Noor
      </Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
        }}
      >
        <Pressable
          onPress={() => {
            openLink(`https://usenoor.com`)
          }}
        >
          {({ hovered, pressed }) => (
            <Text
              style={[
                {
                  fontSize: textSize,
                  color: textColor,
                  letterSpacing: -0.01,
                },
                hovered && {
                  color: textColorHover,
                },
                pressed && {
                  color: textColorActive,
                },
              ]}
            >
              usenoor.com
            </Text>
          )}
        </Pressable>
        <Divider />
        <Pressable
          onPress={() => {
            openLink(legalDocument)
          }}
        >
          {({ hovered, pressed }) => (
            <Text
              style={[
                {
                  fontSize: textSize,
                  color: textColor,
                  letterSpacing: -0.01,
                },
                hovered && {
                  color: textColorHover,
                },
                pressed && {
                  color: textColorActive,
                },
              ]}
            >
              Privacy & terms
            </Text>
          )}
        </Pressable>
        <Divider />
        <Pressable
          onPress={() => {
            if (ipc) {
              ipc?.invoke('open-help-window', undefined)
            } else {
              window.open(crispUrl, '__blank')
            }
          }}
        >
          {({ hovered, pressed }) => (
            <Text
              style={[
                {
                  fontSize: textSize,
                  color: textColor,
                  letterSpacing: -0.01,
                },
                hovered && {
                  color: textColorHover,
                },
                pressed && {
                  color: textColorActive,
                },
              ]}
            >
              Get help
            </Text>
          )}
        </Pressable>
      </View>
    </View>
  )
}

const Divider = () => {
  let theme = useTheme()
  return (
    <Text
      style={[
        {
          fontSize: theme.fontSizes.large,
          color: theme.colors.quaternaryText,
          lineHeight: 13.3,
          marginHorizontal: 5,
        },
      ]}
    >
      •
    </Text>
  )
}
