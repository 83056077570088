import React, {
  ForwardedRef,
  forwardRef,
  Fragment,
  useEffect,
  useState,
} from 'react'
import { TextInput, TextInputProps, View, Text } from 'react-native'
import { a, useSpring } from '@react-spring/native'

export const AutoHeightTextInput = forwardRef(
  (
    {
      // numberOfLines,
      // setNumberOfLines,
      lineHeight,
      maxLines = 4,
      style,
      paddingVertical = 8,
      minHeight = 0,
      ...props
    }: TextInputProps & {
      // numberOfLines: number
      // setNumberOfLines: (lines: number) => void
      lineHeight: number
      maxLines?: number
      paddingVertical?: number
      minHeight?: number
    },
    ref: ForwardedRef<TextInput>,
  ) => {
    const [numberOfLines, setNumberOfLines] = useState(1)

    let textInputHeight = Math.max(
      minHeight,
      numberOfLines * lineHeight + paddingVertical,
    )
    let wrapperStyleProps = useSpring({
      height: textInputHeight,
      config: {
        tension: 600,
        friction: 15,
        mass: 0.1,
      },
    })

    let commonStyle: TextInputProps['style'] = [
      style,
      {
        width: '100%',
        textAlignVertical: 'center',
        overflow: numberOfLines > maxLines ? 'visible' : 'hidden',
        lineHeight,
      },
    ]

    return (
      <a.View
        style={{
          flexGrow: 1,
          justifyContent: 'flex-start',
          height: wrapperStyleProps.height,
          flexDirection: 'row',
          overflow: 'hidden',
        }}
      >
        <TextInput
          ref={ref}
          multiline={true}
          style={[
            commonStyle,
            {
              height: textInputHeight,
              // backgroundColor: 'blue',
              // opacity: 0.8,
              color: 'white',
              flexShrink: 0,
            },
          ]}
          {...props}
        />
        <Text
          onLayout={(event) => {
            setNumberOfLines(
              Math.floor(
                (event.nativeEvent.layout.height - paddingVertical) /
                  lineHeight,
              ),
            )
          }}
          // Copy styles for both
          style={[
            commonStyle,
            {
              // left: 0,
              // right: 0,
              position: 'absolute',
              opacity: 0,
              flexShrink: 0,
              // opacity: 0.3,
              // backgroundColor: '#eee',

              //@ts-ignore
              pointerEvents: 'none',
            },
          ]}
        >
          {(props.value || '').split('\n').map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </Text>
      </a.View>
    )
  },
)
