import {
  SubscriptionInput,
  useNurSubscription,
} from '@there/components/sun/use-subscription'
import { GotSignalMessage } from '@there/sun/modules/gotSignal'

export type GotSignalData = GotSignalMessage['payload']

export const useGotSignal = (
  args: Omit<SubscriptionInput<GotSignalData>, 'method'>,
) => {
  return useNurSubscription<GotSignalData>({
    method: 'gotSignal',
    ...args,
  })
}
