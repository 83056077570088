import { electronApi } from '@there/desktop/utils/electron-api'
import { useEffect } from 'react'

/**
 * When feed gets hidden or closed
 * Useful for doing clean-ups, resetting states, unsubscribe from certain events, etc.
 *
 * @param callback
 */
export const useWindowFocused = (callback: (_: any) => void) => {
  useEffect(() => {
    if (!electronApi) return

    electronApi.windowFocused.addListener(callback)

    return () => {
      if (electronApi) {
        electronApi.windowFocused.removeListener(callback)
      }
    }
  }, [callback])
}
