import React, { RefObject } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { usePresentedConnectivity } from '../shared/use-connectivity'
import { WiFiOut } from './WifiOut'
import { SpaceManager } from './SpaceManager'
import { useAppContext } from '../shared/AppContext'
import { Tooltip } from '../shared/Tooltip'
import { isMacOS } from '@there/desktop/utils/electron-api'
import { Space } from '../shared/Space'
import { WebStyled, WebkitAppRegion } from '@there/components/shared/WebStyled'
import { useTheme } from './ThemeContext'
import { SettingsDropdown } from '@there/components/settings/SettingsDropdown'
import { useMemberships } from '@there/components/feed/useMemberships'
import { StandardButton } from '../main/StandardButton'
import { useModals } from './ModalsContext'
import isEqual from 'react-fast-compare'
import { NativeWindowClose } from '@there/components/shared/NativeWindowClose'
import { CircleButton } from '@there/components/main/CircleButton'
import { RefreshIcon } from '@there/components/feed/HeaderIcons'
import { HelpMenu } from '../main/HelpMenu'
import { logEvent } from '@there/components/shared/use-analytics'
import { useWindowContext } from '@there/components/shared/WindowContext'
import { MicrophoneButton } from '@there/components/feed/MicrophoneButton'
import { ScreenShareButton } from '@there/components/CallControl/ScreenShareButton'
import { CameraButton } from '@there/components/CallControl/CameraButton'
import { PickerButton } from '@there/components/CallControl/PickerButton'
import { NotificationDropdown } from '../dropdown/NotificationDropdown'
import { GoodButton } from '../shared/GoodButton'
import { LockIcon } from '../room/LockIcon'
import { LockRoomButton } from '../room/LockRoomButton'

type Props = {
  isSpaceNameTooLong?: boolean
  noApollo?: boolean
  pagesRef?: RefObject<HTMLDivElement | null>
  shown?: boolean
}

export const Header = React.memo(
  ({ isSpaceNameTooLong, noApollo, shown }: Props) => {
    const t = useTheme()
    const { activeSpaceName } = useAppContext()

    let { currentMember, memberships } = useMemberships()

    if (shown === false) {
      return null
    }

    return (
      <HeaderWrapper>
        <View style={[styles.section]}>
          {!noApollo ? (
            <WebkitAppRegion mode="no-drag">
              <SpaceManager
                memberships={memberships}
                currentMember={currentMember}
                smaller={isSpaceNameTooLong}
              />
            </WebkitAppRegion>
          ) : (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1,
                marginLeft: 10,
              }}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: t.fontSizes.large,
                  color: t.colors.spaceName,
                  lineHeight: 20,
                }}
              >
                {activeSpaceName}
              </Text>
            </View>
          )}
        </View>
        <HeaderControls />
      </HeaderWrapper>
    )
  },
  isEqual,
)

Header.displayName = 'Header'

export const HeaderWrapper = ({
  children,
  styles: customStyles,
}: {
  children: React.ReactNode
  styles?: React.CSSProperties
}) => {
  const t = useTheme()

  return (
    <WebStyled
      style={{
        WebkitAppRegion: 'drag',
        width: '100%',
        height: headerHeight,
        paddingLeft: t.spaces.sidePaddingNarrow,
        paddingRight: t.spaces.sidePaddingNarrow,
        paddingTop,
        position: 'relative',
        zIndex: 10,
        flexShrink: 0,

        ...customStyles,
      }}
    >
      <View style={styles.innerHeader}>{children}</View>
    </WebStyled>
  )
}

export const SideHeader = () => {
  let t = useTheme()

  return (
    <WebkitAppRegion mode="drag">
      <View
        style={{
          paddingTop,
          height: headerHeight,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          paddingLeft: t.spaces.sidePaddingNarrow + 1,
          paddingRight: t.spaces.sidePaddingNarrow - 2,
          paddingBottom: 4,
        }}
      >
        <Text
          style={{
            fontSize: t.fontSizes.large,
            color: t.colors.tertiaryText,
            fontWeight: 'normal',
            flexGrow: 1,
          }}
        >
          Members
        </Text>

        <NotificationDropdown />

        <Tooltip
          label={
            <>
              Reload <span className="light">• CMD + R</span>
              <br />{' '}
              <span className="light">
                While in beta, hit Reload
                <br /> to fix any issue.
              </span>
            </>
          }
          placement="bottomLeft"
        >
          <CircleButton
            backgroundMode="transparent"
            onPress={() => {
              logEvent('User Clicked Reload')

              window.location.reload()
            }}
          >
            <RefreshIcon
              color={t.colors.tertiaryLight}
              width={16}
              height={16}
            />
          </CircleButton>
        </Tooltip>

        <HelpMenu />

        <SettingsDropdown />
      </View>
    </WebkitAppRegion>
  )
}

export const HeaderControls = ({ showLock }: { showLock?: boolean }) => {
  const presentedIsOnline = usePresentedConnectivity()
  let theme = useTheme()

  return (
    <View style={[styles.section, { justifyContent: 'flex-end' }]}>
      {!presentedIsOnline && (
        <>
          <Tooltip
            placement="bottomLeft"
            label={
              <>
                <span className="light">Offline</span> <br />
                Click <span className="light">to retry</span>
                <br />
                {isMacOS ? '⌘' : '^'} + R{' '}
                <span className="light">to reload</span>
              </>
            }
          >
            <CircleButton
              backgroundMode="transparent"
              onPress={() => {
                window.location.reload()
              }}
            >
              <WiFiOut color={theme.colors.tertiaryLight} />
            </CircleButton>
          </Tooltip>
        </>
      )}

      {showLock && <LockRoomButton />}

      <Space horizontal={6} />

      <PickerButton />

      <Space horizontal={6} />

      <CameraButton />

      <Space horizontal={6} />

      <ScreenShareButton />

      <Space horizontal={6} />

      <MicrophoneButton />
    </View>
  )
}

// For adding extra space in feed
export const headerHeight = 44

const paddingTop = 6

const styles = StyleSheet.create({
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 34,
  },
  section: {
    position: 'relative',
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
})
