import { useState, useCallback, useMemo } from 'react'

type Point = {
  x: number
  y: number
}

type CursorState = {
  isScrolling?: boolean
  isDragging?: boolean
  isAwake?: boolean
  isOverScreen?: boolean
  /** Possibly can go to "participants" object? */
  isInCall?: boolean

  // Internal
  _scrollingRevertTimeout: number | undefined
}

type PointAndDate = Point & { lastMoveAt: number }
export type CursorsState = {
  positions: Record<string, PointAndDate>
  states: Record<string, CursorState>
}
export type UpdatePosition = (userId: string, newPosition: Point) => void
export type CursorsManager = {
  updatePosition: UpdatePosition
  movedIn(id: string): void
  movedOut(id: string): void
  setDragging(id: string, isDragging: boolean): void
  scrolled(id: string): void
}

// This hook is for when we have private room for call
export function useCursors(): [CursorsState, CursorsManager] {
  const [positions, setPositions] = useState<Record<string, PointAndDate>>({})
  const [cursorStates, setCursorStates] = useState<Record<string, CursorState>>(
    {},
  )

  const updatePosition = useCallback((id: string, newPosition: Point) => {
    setPositions((state) => ({
      ...state,
      [id]: { ...newPosition, lastMoveAt: performance.now() },
    }))
  }, [])

  const manager = useMemo(
    () => ({
      updatePosition,
      movedIn(id: string) {
        setCursorStates((state) => ({
          ...state,
          [id]: {
            ...(state[id] || {}),
            isOverScreen: true,
          },
        }))
      },
      movedOut(id: string) {
        setCursorStates((state) => ({
          ...state,
          [id]: {
            ...(state[id] || {}),
            isOverScreen: false,
          },
        }))
      },
      setDragging(id: string, isDragging: boolean) {
        setCursorStates((state) => ({
          ...state,
          [id]: {
            ...(state[id] || {}),
            isDragging,
          },
        }))
      },
      scrolled(id: string) {
        // Set to scrolling for a little while (to throttle)
        setCursorStates((state) => {
          const _scrollingRevertTimeout =
            state[id] && state[id]._scrollingRevertTimeout
          _scrollingRevertTimeout && clearTimeout(_scrollingRevertTimeout)

          return {
            ...state,
            [id]: {
              ...(state[id] || {}),
              isScrolling: true,
              // Revert back state with a timeout (to throttle)
              _scrollingRevertTimeout: setTimeout(() => {
                setCursorStates((state) => ({
                  ...state,
                  [id]: {
                    ...state[id],
                    isScrolling: false,
                    _scrollingRevertTimeout: undefined,
                  },
                }))
              }, 50),
            },
          }
        })
      },
    }),
    [updatePosition],
  )

  return [
    {
      positions,
      states: cursorStates,
    },
    manager,
  ]
}
