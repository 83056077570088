import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

type Placement =
  | 'bottomLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomRight'
  | 'bottomCenter'
  | 'topLeft'
  | 'leftCenter'
  | 'rightCenter'

const horizontalAnimateNumber = 30
const verticalAnimatedNumber = 20

const animate = (placement: Placement) => {
  let animate = { x: 0, y: 0, scale: 1, opacity: 1, display: 'block' }
  switch (placement) {
    case 'topCenter':
      return { ...animate, y: -verticalAnimatedNumber, x: '-50%' }

    case 'topRight':
      return { ...animate, y: -verticalAnimatedNumber }

    case 'rightCenter':
      return { ...animate, x: horizontalAnimateNumber, y: '-50%' }
    default:
      return animate
  }
}

const initial = (placement: Placement) => {
  let initial = { x: 0, y: 0, scale: 0.9, opacity: 0 }
  switch (placement) {
    case 'topCenter':
      return { ...initial, x: '-50%' }

    case 'topRight':
      return { ...initial }

    case 'rightCenter':
      return { ...initial, y: '-50%' }

    default:
      return initial
  }
}

type Props = {
  children: React.ReactNode
  trigger: boolean
  value: string
  placement?: Placement
  offset?: number
  duration?: number | 'unlimit'
}
export const TinyNotification = ({
  children,
  trigger,
  value,
  duration = 2000,
  placement = 'topCenter',
  offset = 0,
}: Props) => {
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    if (trigger) {
      setVisible(true)
    } else {
      if (duration === 'unlimit') {
        setVisible(false)
      }
    }
  }, [duration, trigger])

  useEffect(() => {
    if (!isVisible) return
    if (duration === 'unlimit') return
    let timer = setTimeout(() => {
      setVisible(false)
    }, duration)
    return () => clearTimeout(timer)
  }, [duration, isVisible])

  return (
    <RelativeWrapper>
      {isVisible && (
        <Notification placement={placement} offset={offset}>
          {value}
        </Notification>
      )}
      {children}
    </RelativeWrapper>
  )
}

const RelativeWrapper = styled.div`
  position: relative;
`

export const Notification = styled.div.attrs({})<{
  placement: Placement
  offset: number
}>`
  position: absolute;
  z-index: 20;

  padding: 4px 8px;

  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #454545;
  text-align: center;

  color: rgba(255, 255, 255, 0.8);
  line-height: 1.375;
  font-size: ${(p) => p.theme.fontSizes[11]}px;
  font-weight: ${(p) => p.theme.fontWeights.medium};
  white-space: nowrap;
  pointer-events: none;

  opacity: 0;
  display: none;

  ${(p) => {
    let offset = p.offset

    switch (p.placement) {
      case 'bottomLeft':
        return css`
          top: calc(100% - ${verticalAnimatedNumber}px);
          left: ${horizontalAnimateNumber}px;
        `

      case 'bottomRight':
        return css`
          top: calc(100% + 4px);
          left: ${offset}px;
        `

      case 'topCenter':
        return css`
          bottom: calc(100% + ${offset - verticalAnimatedNumber}px);
          left: 50%;
          text-align: center;
        `

      case 'bottomCenter':
        return css`
          top: calc(100% + 4px);
          left: 50%;
          text-align: center;
        `

      case 'topLeft':
        return css`
          bottom: calc(100%);
          left: 0px;
        `

      case 'topRight':
        return css`
          bottom: calc(100% - ${verticalAnimatedNumber}px);
          right: 0;
        `

      case 'leftCenter':
        return css`
          top: 50%;
          transform: translateY(-50%);
          right: calc(100% + ${4 + offset}px);
          text-align: center;
        `
      case 'rightCenter':
        return css`
          top: 50%;
          transform: translateY(-50%);
          left: calc(100% + ${offset - horizontalAnimateNumber}px);
          text-align: center;
        `

      default:
        return ``
    }
  }}
`
