import { useTransition, a, useSpring } from '@react-spring/native'
import { useEffect, useState, useRef } from 'react'
import { Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { UploadIcon } from '../modal/UploadIcon'
import { useDraftMessage, useSendChatMessage } from './ChatCompose'
import { useDrop } from './useDrop'
import { useChatUploader, useFileUploadManager } from './useFileUploadManager'

export const FileDropRegion = (props: {
  peerTopicId?: string | null
  peerUserId?: string | null
  children: React.ReactNode
}) => {
  const { peerTopicId, peerUserId } = props

  const dragRegionRef = useRef<HTMLDivElement | null>(null)
  let sendChatMessageObject = useSendChatMessage()
  let draftMessage = useDraftMessage()
  let { uploadFile } = useChatUploader()
  let theme = useTheme()

  let { aboutToDrop, registerDropAction } = useDrop({
    dragRegionRef,
    onDrop: (file) =>
      uploadFile({ file, draftText: draftMessage?.message }).then(
        (document) => {
          sendChatMessageObject.sendChatMessage({
            peerTopicId: peerTopicId || null,
            peerUserId: peerUserId || null,
            text: draftMessage?.message || '',
            documentId: document.id,
            document,
          })
        },
      ),
  })

  let crossStyleProps = useSpring({
    opacity: aboutToDrop ? 1 : 0,
    config: {
      mass: 0.1,
      tension: 300,
      friction: 20,
    },
  })

  return (
    <div
      ref={(ref) => {
        registerDropAction(ref)
      }}
      style={{
        flex: 1,
        // Disallow shrinking for scroll
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {props.children}

      <a.View
        //@ts-ignore
        style={[
          crossStyleProps,
          {
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.1)',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 999,
            //@ts-ignore
            pointerEvents: 'none',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}
      >
        <View
          style={[
            {
              width: 200,
              height: 100,
              borderRadius: 8,

              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          <UploadIcon color={theme.colors.tertiaryLight} />
          <Text
            style={{
              fontSize: theme.fontSizes.large,
              color: theme.colors.tertiaryLight,
            }}
          >
            Drop file here
          </Text>
        </View>
      </a.View>
    </div>
  )
}

const canUploadFile = (file: File | undefined) => {
  if (!file) return false
  // if (
  //   !['image/jpeg', 'image/png', 'image/gif', 'image/webp'].includes(file.type)
  // ) {
  //   return false
  // }
  return true
}
