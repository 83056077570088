import { ScaledSize, View } from 'react-native'

type Props = {
  offset: number
  window: ScaledSize
  toggleBounds: {
    x: number
    y: number
    width: number
    height: number
  }
  popoverDimensions: {
    width: number
    height: number
  }
}

type Position = {
  top: number | undefined
  left: number | undefined
  right: number | undefined
  bottom: number | undefined
}

const defaultVerticalPositionPadding = 2

const initialPosition: Position = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}

export const calculatePopoverPosition = ({
  offset,
  window,
  toggleBounds,
  popoverDimensions,
}: Props) => {
  let position = initialPosition

  let isBottomCriticalArea =
    toggleBounds.y > window.height - window.height / 3 ||
    // toggleBounds.y + popoverDimensions.height > window.height ||
    window.height < 60
  let isRightCriticalArea =
    toggleBounds.x + toggleBounds.width + popoverDimensions.width > window.width

  let topSpaceNormal =
    toggleBounds.y +
    toggleBounds.height +
    offset +
    defaultVerticalPositionPadding

  let topNegativeOffset =
    topSpaceNormal + popoverDimensions.height > window.height
      ? topSpaceNormal + popoverDimensions.height - window.height
      : 0

  let topSpaceCritical =
    window.height -
    popoverDimensions.height -
    offset -
    (window.height - toggleBounds.y)

  let leftSpaceCritical =
    window.width -
    popoverDimensions.width -
    offset -
    (window.width - toggleBounds.x - toggleBounds.width)

  let leftSpaceNormal = toggleBounds.x + offset + toggleBounds.width

  let leftNegativeOffset =
    leftSpaceNormal + popoverDimensions.width > window.width
      ? leftSpaceNormal + popoverDimensions.width - window.width
      : 0

  position = {
    top: isBottomCriticalArea
      ? topSpaceCritical
      : topSpaceNormal - topNegativeOffset,
    bottom: undefined,
    left: isRightCriticalArea
      ? leftSpaceCritical
      : leftSpaceNormal - leftNegativeOffset,
    right: undefined,
  }
  return position
}
