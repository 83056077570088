import gql from 'graphql-tag'
import { useMutation } from 'urql'
import { userInfoFragment } from './fragments/userInfo'
import { userSpacesInfoFragment } from './fragments/userSpacesInfo'

export const EmailValidationMutation = gql`
  mutation emailValidation($email: String!, $code: String!, $userId: String!) {
    done: emailValidation(email: $email, code: $code, userId: $userId)
  }
`

export type Variables = {
  email: string
  code: string
  userId: string
}
export type Data = {
  done: boolean
}

export const useEmailValidationMutation = () =>
  useMutation<Data, Variables>(EmailValidationMutation)
