import { ipc } from '@there/desktop/utils/electron-api'
import { NewMessageInfo } from '@there/sun/utils/node-types'
import { useEffect, useRef, useState } from 'react'
import { Pressable, ActivityIndicator } from 'react-native'
import useCanvasBlur from '../lib/useCanvasBlur'
import { calculateImageDimensions } from '../v2/ChatUploadButton'
import { getMessageMediaFullPath, getMessageMediaThumbDataUri } from './helpers'
import { useSpring, a } from '@react-spring/native'
import useIsMounted from '@there/components/hooks/useIsMounted'
import { Gif } from '../emoji-picker/GifPickerContainer'

export const MAX_MEDIA_WIDTH = 220
type props = {
  message: NewMessageInfo
  onPress: () => void
}

export const GifMessage = ({ message, onPress }: props) => {
  const messageMedia = message.document

  const thumbnailUrl = getMessageMediaThumbDataUri(message)
  const thumbRef = useCanvasBlur(thumbnailUrl, !Boolean(thumbnailUrl), true)
  let isMounted = useIsMounted()

  const imageDims =
    messageMedia?.mediaW && messageMedia.mediaH
      ? calculateImageDimensions(
          {
            w: messageMedia.mediaW,
            h: messageMedia.mediaH,
          },
          MAX_MEDIA_WIDTH,
        )
      : { w: MAX_MEDIA_WIDTH, h: MAX_MEDIA_WIDTH }

  const hasLoaded = useRef(false)
  const [showMainSize, setShwMainSize] = useState(
    // Show immediately, unless after 40ms it didn't load, start the loading
    true,
  )

  useEffect(() => {
    let t = setTimeout(() => {
      if (!hasLoaded.current) {
        setShwMainSize(false)
      }
    }, 40)

    return () => {
      t && clearTimeout(t)
    }
  }, [])

  const thumbnailStyles = useSpring({
    opacity: showMainSize ? 0 : 1,

    config: {
      duration: 100,
    },
  })

  useEffect(() => {
    if (!thumbnailUrl) return

    let img = new Image()
    img.src = thumbnailUrl
  }, [thumbnailUrl])

  if (!messageMedia || !messageMedia.remoteUrl || !messageMedia.mediaW)
    return null
  return (
    <Gif
      gif={{
        dims: [messageMedia.mediaW || 0, messageMedia.mediaH || 0],
        id: messageMedia.id,
        name: messageMedia.fileName,
        preview: messageMedia.remotePreview || undefined,
        size: messageMedia.size,
        url: messageMedia.remoteUrl,
      }}
      width={Math.min(messageMedia.mediaW, MAX_MEDIA_WIDTH)}
      style={{
        borderRadius: 14,
      }}
    />
  )
}
