import { useNurMutation } from '@there/components/sun/use-mutation'
import type { LogPeerConnectionMessage } from '@there/sun/modules/logPeerConnection'
interface Data {
  done: boolean
}

export const useLogPeerConnection = () => {
  return useNurMutation<Data, LogPeerConnectionMessage['payload']>({
    method: 'logPeerConnection',
  })
}
