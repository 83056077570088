import { Text, TextStyle } from 'react-native'
import { useTheme } from '../feed/ThemeContext'

export const ModalLabel = ({
  text,
  tiny,
  style,
  numberOfLines,
}: {
  text: string
  tiny?: boolean
  style?: TextStyle
  numberOfLines?: number
}) => {
  let theme = useTheme()
  return (
    <Text
      style={[
        {
          fontSize: tiny ? theme.fontSizes.tiny : theme.fontSizes.normal,
          color: theme.colors.secondaryText,
        },
        style,
      ]}
      numberOfLines={numberOfLines}
    >
      {text}
    </Text>
  )
}
