import { View, Text } from 'react-native'
import { useTheme } from '../feed/ThemeContext'

export const HelpText = ({ text }: { text: string }) => {
  let theme = useTheme()
  return (
    <View
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
      }}
    >
      <Text
        style={{
          fontSize: theme.fontSizes.small,
          color: theme.colors.quaternaryText,
        }}
      >
        (?)
      </Text>
      <Text
        style={{
          marginLeft: 4,

          fontSize: theme.fontSizes.small,
          color: theme.colors.quaternaryText,
        }}
      >
        {text}
      </Text>
    </View>
  )
}
