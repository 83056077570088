import { useCallback, useMemo } from 'react'
import {
  ipc,
  RtcDataChannelDataOverIpc,
  useListenToIpc,
} from '@there/desktop/utils/electron-api'
import {
  DataType,
  SendToAll,
  SendToUser,
} from '@there/components/shared/use-rtc-peers'

export type UniversalDataChannel = {
  sendToAll: SendToAll
  sendToUser: SendToUser
}

type Input = {
  enabled: boolean
  sendToAll: SendToAll
  sendToUser: SendToUser
  /** We call it if we get universal event */
  onData: (data: DataType, userId: string) => void
}

const IPC_CHANNEL_LISTEN_NAME = 'rtc-data-channel-data'
const IPC_CHANNEL_SEND_NAME = 'rtc-data-send'

export function useRtcUniversalData({
  enabled,
  sendToAll: sendToAllLocal,
  sendToUser: sendToUserLocal,
  onData,
}: Input): UniversalDataChannel {
  const sendToUser: SendToUser = useCallback(
    (userId, data) => {
      if (enabled) {
        return sendToUserLocal(userId, data)
      } else {
        ipc
          ?.invoke(IPC_CHANNEL_SEND_NAME, {
            userId: userId,
            data,
          } as RtcDataChannelDataOverIpc)
          .catch(() => {})
        return true
      }
    },
    [enabled, sendToUserLocal],
  )

  const sendToAll: SendToAll = useCallback(
    (data) => {
      if (enabled) {
        return sendToAllLocal(data)
      } else {
        ipc
          ?.invoke(IPC_CHANNEL_SEND_NAME, {
            userId: '__all',
            data,
          } as RtcDataChannelDataOverIpc)
          .catch(() => {})
        return 1
      }
    },
    [enabled, sendToAllLocal],
  )

  // Send universally
  useListenToIpc(IPC_CHANNEL_SEND_NAME, (_, data) => {
    if (!enabled) return
    if (data.userId === '__all') {
      sendToAllLocal(data.data)
    } else {
      sendToUserLocal(data.userId, data.data)
    }
  })

  // Handle incoming
  useListenToIpc(IPC_CHANNEL_LISTEN_NAME, (_, data) => {
    if (!enabled) return
    onData(data.data, data.userId)
  })

  return useMemo(
    () => ({
      sendToUser,
      sendToAll,
    }),
    [sendToUser, sendToAll],
  )
}
