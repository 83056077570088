import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export const ArrowDownIcon = (props: SvgProps & { rotate: boolean }) => {
  const { rotate, ...restProps } = props
  return (
    <Svg
      width={7}
      height={4}
      fill="none"
      transform={rotate ? 'rotate(180)' : ''}
      {...restProps}
    >
      <Path
        d="m1 1 2.5 2.5L6 1"
        stroke="#8A95A2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
