import { DeafenIconMuted } from '@there/components/feed/HeaderIcons'
import { useTheme } from '@there/components/feed/ThemeContext'
import { ThemedView } from '@there/components/shared/ThemedView'
import { Tooltip } from '@there/components/shared/Tooltip'
import { memo, useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { DefaultTheme } from 'styled-components'
import { MicrophoneIcon } from '@there/components/shared/MicrophoneIcon'
import { SystemAudioIcon } from '../v2/SystemAudioIcon'
import { Pressable } from '../shared/Pressable'
import { useVolumeContext } from '../shared/VolumeContext'

interface PositionProps {
  right?: number
  bottom?: number
}

export const AvatarIndicator = memo(
  ({
    right,
    bottom,
    type,
  }: PositionProps & {
    type: 'online' | 'idle' | 'working' | 'offline' | 'none'
  }) => {
    let label = ''
    let backgroundColor: keyof DefaultTheme['colors']
    let borderColor: keyof DefaultTheme['colors'] = 'transparent'

    switch (type) {
      case 'online':
        backgroundColor = 'avatarOnlineIndicator'
        label = 'Online'
        break
      case 'working':
        backgroundColor = 'avatarWorkingIndicator'
        label = 'Work-time'
        break
      case 'idle':
        backgroundColor = 'avatarIdleIndicator'
        label = 'Probably Away'
        break
      case 'offline':
        backgroundColor = 'transparent'
        borderColor = 'secondaryLight'
        label = 'Offline'
        break
      default:
        backgroundColor = 'transparent'
    }

    return (
      <Tooltip label={label}>
        <View
          style={{
            position: 'absolute',
            right: right ?? 4,
            bottom: bottom ?? 1,
          }}
        >
          <ThemedView
            borderColor={borderColor}
            backgroundColor={backgroundColor}
            style={[
              {
                width: 7,
                height: 7,
                borderRadius: 10,
                borderWidth: 2,

                shadowColor: 'rgba(0, 0, 0, 0.4)',
                shadowOffset: { height: 0, width: 0 },
                shadowRadius: 4,
              },
            ]}
          />
        </View>
      </Tooltip>
    )
  },
)

export const MuteIndicator = memo(() => {
  return (
    <ThemedView
      backgroundColor="background"
      style={[
        {
          position: 'absolute',
          left: 0,
          bottom: -2,
          width: 22,
          height: 22,
          borderRadius: 22,
          justifyContent: 'center',
          alignItems: 'center',
        },
      ]}
    >
      <DeafenIconMuted color="rgba(255,255,255,0.6)" width={18} height={18} />
    </ThemedView>
  )
})
export const SystemAudioIndicator = memo(
  ({
    userId,
    userName,
    isUs,
  }: {
    userId?: string
    userName: string
    isUs: boolean
  }) => {
    const { dispatch, usersSystemVolume } = useVolumeContext()

    let userSystemVolume =
      userId && usersSystemVolume ? usersSystemVolume[userId] : 100

    let toggleUserSystemVolume = useCallback(() => {
      if (!userId) return
      if (isUs) return
      dispatch({
        type: 'user system volume changed',
        userId,
        volume: userSystemVolume === 0 ? 100 : 0,
      })
    }, [dispatch, isUs, userId, userSystemVolume])
    return (
      <Pressable
        disabled={isUs}
        style={{ position: 'absolute', right: 0, bottom: 0, zIndex: 2 }}
        onPress={toggleUserSystemVolume}
      >
        <Tooltip
          placement="bottomCenter"
          label={
            <>
              {!isUs && (
                <>
                  {userSystemVolume === 0 ? 'click to play' : 'click to mute'}
                  <br />
                </>
              )}
              <span className="light">{userName} Computer audio shared</span>
            </>
          }
        >
          <ThemedView
            backgroundColor={
              userSystemVolume === 0 ? 'background' : 'systemAudioButtonIcon'
            }
            style={[
              {
                width: 20,
                height: 20,
                borderRadius: 20,
                justifyContent: 'center',
                alignItems: 'center',
              },
            ]}
          >
            <SystemAudioIcon
              color={
                userSystemVolume === 0
                  ? 'rgba(255, 255, 255, 0.35)'
                  : 'rgba(255, 255, 255, 0.85)'
              }
              width={16}
              height={16}
            />
          </ThemedView>
        </Tooltip>
      </Pressable>
    )
  },
)

export const CrossedMicIndicator = memo(() => {
  let theme = useTheme()

  return (
    <ThemedView
      backgroundColor="background"
      style={[
        {
          width: 20,
          height: 20,
          borderRadius: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.colors.background,
        },
      ]}
    >
      <MicrophoneIcon
        width={16}
        height={16}
        color={theme.colors.tertiaryLight}
        // To fix SSR warning
        muted={true}
      />
    </ThemedView>
  )
})
