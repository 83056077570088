import { useNurMutation } from '@there/components/sun/use-mutation'
import {
  DeleteChatMessage,
  DeleteChatResult,
} from '@there/sun/modules/deleteChat'

type Variables = DeleteChatMessage['payload']

export const useDeleteChat = () => {
  return useNurMutation<DeleteChatResult, Variables>({
    method: 'deleteChat',
  })
}
