import { isMacOS } from '@there/desktop/utils/electron-api'
import { GoodButton } from '../shared/GoodButton'
import { Tooltip } from '../shared/Tooltip'
import { usePresentedConnectivity } from '../shared/use-connectivity'
import { useTheme } from './ThemeContext'
import { WiFiOut } from './WifiOut'
import { ActivityIndicator } from 'react-native'

export const NotConnected = () => {
  let presentedIsOnline = usePresentedConnectivity()
  let theme = useTheme()
  return (
    <>
      {!presentedIsOnline && (
        <>
          <Tooltip
            placement="bottomLeft"
            label={
              <>
                <span className="light">Offline</span> <br />
                Click <span className="light">to retry</span>
                <br />
                {isMacOS ? '⌘' : '^'} + R{' '}
                <span className="light">to reload</span>
              </>
            }
          >
            <GoodButton
              variant="transparent"
              onPress={() => {
                window.location.reload()
              }}
              icon={
                // <WiFiOut
                //   color={theme.colors.tertiaryText}
                //   style={{ marginTop: -2 }}
                // />
                <ActivityIndicator
                  size={16}
                  color={theme.colors.tertiaryText}
                />
              }
            />
          </Tooltip>
        </>
      )}
    </>
  )
}
