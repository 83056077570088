import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { MinUserInfo } from '../urql/fragments/userInfo'
import PlusIcon from '@there/components/feed/PlusIcon'
import { Pressable } from '../shared/Pressable'
import { Dropdown, PickerItem } from '../feed/Dropdown'
import { Space } from '../shared/Space'
import {
  PhotoIcon,
  TwitterIcon,
} from '@there/components/login/ProfilePickerIcons'
import { useSelect } from 'downshift'
import { useHover } from '../shared/use-hover'
import { useAvatarUploader } from './use-avatar-uploader'
import { ProfilePhoto } from '../feed/ProfilePhoto'
import { useModals } from '../feed/ModalsContext'
import { Tooltip } from '../shared/Tooltip'
import { useSpaceContext } from '../shared/spaceContext'
import { PuffSpinner } from '../shared/PuffSpinner'
import { useAtom } from 'jotai'
import { spaceContextRefetchAtom } from '../shared/spaceContext/context'
import { TWIVATAR_BASE_URL } from '@there/components/config'

export const ProfilePicker = ({
  profile,
  setProfile,
  setProfileUploading,
}: {
  setProfile: Dispatch<SetStateAction<string | undefined>>
  profile?: string
  setProfileUploading?: Dispatch<SetStateAction<boolean>>
}) => {
  let [{ refetchSpaceContext }] = useAtom(spaceContextRefetchAtom)
  let theme = useTheme()
  let [, modalsDispatch] = useModals()
  let [fakeUser, setFakeUser] = useState<MinUserInfo>({
    id: 'random',
    email: '',
    name: '',
    nickname: '',
    profilePhoto: profile || '',
    pendingSetup: false,
    userId: '',
  })

  useEffect(() => {
    if (!profile) return
    setFakeUser((user) => ({
      ...user,
      profilePhoto: profile,
    }))
  }, [profile])

  let hasProfile =
    (fakeUser.profilePhoto && fakeUser.profilePhoto.length > 0) || profile

  let [getAvatar, uploadingAvatar, optimisticAvatar] = useAvatarUploader()

  useEffect(() => {
    if (!setProfileUploading) return
    setProfileUploading(uploadingAvatar)
  }, [uploadingAvatar, setProfileUploading])

  useEffect(() => {
    if (!optimisticAvatar) return
    setProfile(optimisticAvatar)
    setFakeUser((user) => {
      if (!optimisticAvatar) return user
      return { ...user, profilePhoto: optimisticAvatar }
    })
    setTwitter('')
  }, [optimisticAvatar, refetchSpaceContext, setProfile])

  useEffect(() => {
    if (uploadingAvatar) return
    if (!optimisticAvatar) return
    refetchSpaceContext()
  }, [optimisticAvatar, refetchSpaceContext, uploadingAvatar])

  const [twitter, setTwitter] = useState('')

  useEffect(() => {
    if (twitter.length > 3) {
      setProfile(`${TWIVATAR_BASE_URL}/${twitter.replace('@', '')}`)
      setFakeUser((user) => ({
        ...user,
        profilePhoto: `${TWIVATAR_BASE_URL}/${twitter.replace('@', '')}`,
      }))
    }
  }, [refetchSpaceContext, setProfile, twitter])

  let pickerItems: PickerItem[] = [
    {
      label: 'Upload',
      onClick: () => {
        getAvatar()
      },
      icon: <PhotoIcon />,
    },
    {
      label: 'Set from Twitter',
      onClick: () => {
        modalsDispatch({
          type: 'modal opened',
          modalName: 'setFromTwitter',
          modalData: {
            setTwitter,
          },
        })
      },
      icon: <TwitterIcon />,
    },
  ]

  const {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    openMenu,
    closeMenu,
  } = useSelect({
    items: pickerItems,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return
      if (selectedItem.onClick) {
        selectedItem.onClick()
      }
    },
  })

  let [toggleHovered, hoverListener] = useHover()

  return (
    <View
      style={{
        width: 100,

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Dropdown.Root>
        <Dropdown.Toggle onPress={openMenu}>
          <View
            style={[
              {
                width: 46,
                height: 46,
                borderRadius: 20,
                borderWidth: 2,
                borderColor: theme.colors.avatarBorder,
                overflow: 'hidden',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            ]}
          >
            <Pressable
              disabled
              style={({ pressed }) => [
                {
                  width: 42,
                  height: 42,
                  backgroundColor: theme.colors.profilePickerBackground,
                  borderRadius: theme.borderRadius.avatar,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                toggleHovered && {
                  backgroundColor: theme.colors.profilePickerBackgroundHover,
                },
                pressed && {
                  backgroundColor: theme.colors.profilePickerBackgroundActive,
                },
                hasProfile && {
                  backgroundColor: theme.colors.transparent,
                },

                uploadingAvatar && {
                  opacity: 0.5,
                },
              ]}
              {...hoverListener}
              {...getToggleButtonProps(
                { refKey: 'noref' },
                { suppressRefError: true },
              )}
            >
              {({ pressed }) =>
                hasProfile ? (
                  <ProfilePhoto user={fakeUser} size={42} />
                ) : (
                  <PlusIcon
                    width={24}
                    height={24}
                    color={theme.colors.profilePickerPlus}
                  />
                )
              }
            </Pressable>
            {uploadingAvatar ? (
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                }}
              >
                <PuffSpinner size={42} />
              </View>
            ) : null}
          </View>
        </Dropdown.Toggle>
        <Dropdown.Menu
          {...getMenuProps(undefined, { suppressRefError: true })}
          isOpen={isOpen}
          onClose={closeMenu}
          width={140}
        >
          <Dropdown.Section>
            <Space vertical={4} />
            {pickerItems.map((item, index) => {
              return (
                <View
                  key={item.label}
                  {...getItemProps({
                    item,
                    index,
                  })}
                >
                  <Dropdown.ItemWithIcon onPress={item.onClick}>
                    {item.icon}
                    <Text
                      style={{
                        fontSize: theme.fontSizes.normal,
                        color: theme.colors.tertiaryText,
                        flex: 1,
                        marginLeft: 5,
                      }}
                    >
                      {item.label}
                    </Text>
                  </Dropdown.ItemWithIcon>
                </View>
              )
            })}

            <Space vertical={4} />
          </Dropdown.Section>
        </Dropdown.Menu>
      </Dropdown.Root>
    </View>
  )
}
