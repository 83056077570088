import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const usePersistedState = <State = any>(
  key: string,
  initialState: State,
): [State, Dispatch<SetStateAction<State>>] => {
  let [hydrated, setHydrated] = useState(false)
  let [state, setState] = useState<State>(initialState)

  // Load state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }
    const stateJson = localStorage.getItem(key)

    if (!stateJson || typeof stateJson !== 'string') {
      setHydrated(true)
      return
    }
    // Hydrate
    let cachedState = JSON.parse(stateJson) as State
    setState(cachedState)
    setHydrated(true)
  }, [key])

  // Persist state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }
    if (!hydrated) {
      return
    }
    localStorage.setItem(key, JSON.stringify(state))
  }, [state, hydrated, key])

  return [state, setState]
}
