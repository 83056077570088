import React, { memo } from 'react'
import { useSpring } from '@react-spring/native'
import { AnimatedView } from '@there/components/shared/AnimatedView'

export const ScaleLoading = memo(
  ({ size = 50, color }: { size: number; color?: string }) => {
    // @ts-ignore
    let styleProps: { opacity: number; diameter: number } = useSpring({
      from: { diameter: 0 },
      to: [{ diameter: size }, { diameter: 0 }],
      config: { duration: 600 },
      loop: true,
    })

    return (
      <>
        <AnimatedView
          style={[
            {
              borderWidth: size / 5,
              borderColor: color ? color : 'rgba(255,255,255,0.4)',
              backgroundColor: color ? color : `rgba(255,255,255,0.1)`,
              borderRadius: size,
              height: styleProps.diameter,
              width: styleProps.diameter,
              zIndex: 1,
            },
          ]}
        >
          <></>
        </AnimatedView>
      </>
    )
  },
)
