import { UserInfo } from '@there/sun/utils/node-types'
import { Text } from 'react-native'
import { getShortName } from '@there/shared/utilities/get-short-name'
import { getProfileBackground } from '@there/components/utils/get-profile-background'
import { useTheme } from '@there/components/feed/ThemeContext'
import { useUser } from '@there/components/atoms/userAtom'

type Props = {
  // user: Partial<UserInfo>
  userId: string
  embedded?: boolean
  normalWeight?: boolean
}

type State = {
  senderName: string
}

const SenderNameNode = ({
  senderName,
  embedded,
  normalWeight,
}: Props & State) => {
  const theme = useTheme()
  return (
    <Text
      style={{
        fontSize: theme.fontSizes.normal,
        fontWeight: normalWeight ? 'normal' : 'bold',
        color: getProfileBackground(senderName || 'user'),
        marginBottom: embedded ? 0 : 2,
        lineHeight: embedded ? 10 : undefined,
      }}
      numberOfLines={1}
    >
      {senderName}
    </Text>
  )
}

export const SenderName = (props: Props) => {
  let user = useUser(props.userId)
  let senderName = user ? getShortName(user) || '' : ''
  let state: State = {
    senderName,
  }

  return <SenderNameNode {...props} {...state} />
}
