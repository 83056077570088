import { a, useTransition } from '@react-spring/native'
import { useUser } from '@there/components/atoms/userAtom'
import { joinDialogAtom } from '@there/components/feed/useLobbyMan'
import {
  ConnectingBadge,
  DisconnectedBadge,
} from '@there/components/main/Avatar'
import { PersonSharingIndicator } from '@there/components/main/PersonSharingIndicator'
import {
  itemHeight,
  itemWidth,
  profileWidth,
} from '@there/components/main/Rooms'
import {
  CrossedMicIndicator,
  MuteIndicator,
  SystemAudioIndicator,
} from '@there/components/person/Indicators'
import { NudgeButton } from '@there/components/person/NudgeButton'
import { useAppContext } from '@there/components/shared/AppContext'
import { ThemedView } from '@there/components/shared/ThemedView'
import { useHover } from '@there/components/shared/use-hover'
import { useLatest } from '@there/components/shared/use-latest'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { getShortName } from '@there/components/utils/get-short-name'
import { FocusIcon } from '@there/components/v2/FocusIcon'
import {
  AvatarInfo,
  UserFocusInfo,
  UserInfo,
} from '@there/sun/utils/node-types'
import { useAtom } from 'jotai'
import React, { memo, useCallback, useEffect, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { Text, View } from 'react-native'
import { minifyUserForProfilePhoto, ProfilePhoto } from '../feed/ProfilePhoto'
import { useTheme } from '../feed/ThemeContext'
import { useNudge } from '../feed/use-nudge'
import { useSoundEffect } from '../feed/useSoundEffect'
import { Tooltip } from '../shared/Tooltip'
import { useUiContext } from '../shared/UiContext'
import { convertMsToTime } from '../utils/date'
import { useRerenderEvery } from '../v2/useRerenderEvery'

export let profileBorderWidth = 2

// user: UserInfo
export const Person = memo(
  ({
    user: givenUser,
    avatar,
    userId,
    isUs,
    inCallView,
  }: {
    avatar: AvatarInfo | undefined
    userId: string
    user?: UserInfo | undefined
    isUs?: boolean
    inCallView: boolean
    borderBottom?: boolean /** override text under name */
    subText?: string /** still fetching, don't show online status */
    showPresence?: boolean
    avatarId?: string
    inviteAccepted?: boolean | null
  }) => {
    const t = useTheme()
    const [hovered, hoverListeners] = useHover()
    const [avatarHovered, avatarHoverListeners] = useHover()
    let [{ joinDialog }] = useAtom(joinDialogAtom)
    let [screenShareSound] = useSoundEffect('/tones/share-ding.mp3')

    const [, sendNudge] = useNudge({ recipientId: userId })

    const {
      walkieState,
      isInitiallyConnecting,
      toggleTalk,
      triggerConnectionRecheck,
      joinParticipantScreen,
      triggerParticipantReset,
    } = useRtcContext()
    let { awayDot } = useAppContext()

    let user = useUser(userId)

    let toggleTalkRef = useLatest(toggleTalk)
    const stableToggleTalk = useCallback(() => {
      toggleTalkRef.current?.()
    }, [toggleTalkRef])

    let isOnline = user?.online

    const participant = walkieState.participants?.[userId]

    let isTalking = isUs
      ? walkieState.weTalking
      : participant?.isTalking || !!user?.talking

    let isActive = inCallView && !!avatar?.dialogId

    let isConnecting =
      participant?.peerState === 'connecting' && !isInitiallyConnecting

    let disconnectedBadge =
      participant?.peerState === 'closed' && !isInitiallyConnecting
    let onBadgePress = () => {
      triggerParticipantReset(userId)
    }

    const onSharingPress = useCallback(() => {
      //..

      if (avatar?.dialogId && walkieState.callId !== avatar?.dialogId) {
        // alert('First join their room by clicking on their avatar')
        // first join dialog Id
        joinDialog(avatar.dialogId)
        console.info('Joining user screen by delay')
        setTimeout(() => {
          joinParticipantScreen(userId)

          // Experimental
        }, 140)
      } else {
        if (!walkieState.callId) {
          alert('First open into voice chat using the blue button.')
          return
        }

        console.info('Joining user screen')
        joinParticipantScreen(userId)
      }
    }, [walkieState.callId, avatar, joinParticipantScreen, userId, joinDialog])

    let isUserSharing = isUs
      ? walkieState.weSharingScreen
      : participant?.isSharingScreen
    let isUserSharingIndicator = isUs
      ? walkieState.weSharingScreen
      : participant?.isSharingScreen || !!user?.sharing

    let isSharingSystemAudio = isUs
      ? walkieState.weSharingSystemAudio
      : participant?.isSharingSystemAudio

    let focused = !!user?.focused
    let userFocus = user?.UserFocus && user?.UserFocus[0]

    let renderIndicatorTransition = useTransition(isUserSharingIndicator, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { tension: 200, friction: 20 },
    })

    let callId = walkieState.callId
    let shouldPlay = useRef(false)
    useEffect(() => {
      // when you left the person in header will ding again
      if (isUs) return
      if (!callId) {
        shouldPlay.current = false
      }

      if (isUserSharing && inCallView && shouldPlay.current) {
        screenShareSound()
      }

      // Pre-Condition
      shouldPlay.current = callId ? true : false
    }, [
      isUserSharing,
      screenShareSound,
      inCallView,
      callId,
      isInitiallyConnecting,
      isUs,
    ])
    let { hasCustomBackground } = useUiContext()

    let stableUser = givenUser || user
    if (!stableUser) return null

    let shortName = getShortName(stableUser)

    // for users who does not set it, make it enable by default
    let probablyAway =
      typeof awayDot !== 'boolean' || awayDot
        ? participant?.idleState === 'idle' ||
          (isUs && walkieState.idleState === 'idle')
        : false

    return (
      <Tooltip
        enabled={probablyAway}
        label={
          <>
            Probably away
            <br />
            from computer
          </>
        }
      >
        <View
          style={[
            {
              width: 'auto',
              maxWidth: itemWidth,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              borderRadius: t.borderRadius.larger,
            },
          ]}
          {...hoverListeners}
        >
          {/* Person */}
          <View
            style={[
              {
                width: itemWidth,
                height: itemHeight,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingVertical: 2,
              },
            ]}
          >
            <View
              style={[
                {
                  position: 'relative',
                  height: profileWidth + 2,
                  // zIndex: 4,
                },
                !stableUser.online && {
                  opacity: 0.6,
                },
              ]}
              {...avatarHoverListeners}
            >
              {focused && (
                <View
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    shadowColor: t.colors.yellow,
                    shadowRadius: 10,
                    shadowOpacity: 0.72,
                    borderRadius: itemHeight,
                  }}
                />
              )}
              {stableUser && (
                <ProfilePhoto
                  onPress={
                    isUs
                      ? stableToggleTalk
                      : isUserSharing
                      ? onSharingPress
                      : undefined
                  }
                  user={minifyUserForProfilePhoto(stableUser)}
                  size={profileWidth}
                  borderWidth={profileBorderWidth}
                  borderColor={
                    isOnline
                      ? isTalking
                        ? 'green'
                        : focused
                        ? 'yellow'
                        : isActive
                        ? 'transparent'
                        : 'transparent'
                      : 'transparent'
                  }
                  innerBorderWidth={isUserSharing ? 2 : 0}
                  innerBorderColor="screenShareButtonIcon"
                />
              )}

              {isConnecting ? (
                <ConnectingBadge onPress={onBadgePress} />
              ) : disconnectedBadge ? (
                <DisconnectedBadge onPress={onBadgePress} />
              ) : null}

              {/** actions */}
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  flexGrow: 1,
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  left: -5,
                  bottom: -5,
                  zIndex: 2,
                }}
              >
                {isOnline &&
                // Temporarily disable nudge when this guy is sharing to save space
                !isUs &&
                !isUserSharing ? (
                  <NudgeButton
                    show={avatarHovered}
                    sendNudge={sendNudge}
                    isUs={isUs}
                  />
                ) : null}
              </View>
              {/* 
              {isOnline && !isUserSharing && !isTalking ? (
                participant?.idleState === 'idle' ? (
                  <AvatarIndicator type="idle" />
                ) : null
              ) : // <OnlineIndicator />
              null} */}

              {isActive &&
              (participant?.systemMuted ||
                (isUs &&
                  (walkieState.systemMuted || walkieState.volumeMuted))) ? (
                <MuteIndicator />
              ) : null}
              {isSharingSystemAudio ? (
                <SystemAudioIndicator
                  userId={user?.id}
                  userName={isUs ? 'Ours' : `${shortName || 'User'}'s`}
                  isUs={!!isUs}
                />
              ) : null}

              {!isTalking ? (
                <View
                  style={{
                    width: 20,
                    height: 20,
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    zIndex: 1,
                    //@ts-ignore
                    pointerEvents: 'none',
                  }}
                >
                  <Tooltip label="Muted" placement="bottomCenter">
                    <CrossedMicIndicator />
                  </Tooltip>
                </View>
              ) : null}

              {/* Screen sharing indicator */}
              {renderIndicatorTransition(({ opacity }, showBadge) =>
                showBadge && stableUser ? (
                  <a.View
                    style={{
                      width: 23,
                      height: 23,
                      opacity,
                      position: 'absolute',
                      bottom: -1,
                      right: -1,
                      zIndex: 2,
                    }}
                  >
                    <Tooltip
                      enabled={!!isUserSharing}
                      label={isUs ? 'Sharing' : 'View'}
                      placement="bottomCenter"
                    >
                      <PersonSharingIndicator
                        user={stableUser}
                        onPress={isUserSharing ? onSharingPress : undefined}
                        isUs={isUs}
                      />
                    </Tooltip>
                  </a.View>
                ) : null,
              )}
            </View>

            {/* name */}
            <ThemedView
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'center',
                  maxWidth: '100%',
                },
              ]}
            >
              {probablyAway ? <AwayDot /> : null}
              <View
                style={{
                  maxWidth: itemWidth,

                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 4,
                }}
              >
                {focused && <FocusDot userFocus={userFocus} />}
                <Text
                  numberOfLines={1}
                  style={[
                    {
                      maxWidth: itemWidth,
                      fontSize:
                        shortName && (shortName.length > 7 || focused)
                          ? t.fontSizes.small
                          : t.fontSizes.normal,
                      fontWeight: 'normal',
                      lineHeight: 16,
                      textAlignVertical: 'center',
                      color:
                        t.colors[
                          focused
                            ? 'yellow'
                            : isOnline
                            ? 'tertiaryText'
                            : 'quaternaryText'
                        ],
                    },
                    hasCustomBackground && {
                      textShadowOffset: { width: 0, height: 1 },
                      textShadowRadius: 2,
                      textShadowColor: 'rgba(0, 0, 0, 0.25)',

                      color:
                        t.colors[isOnline ? 'secondaryText' : 'tertiaryText'],
                    },
                  ]}
                >
                  {shortName}
                </Text>
              </View>
            </ThemedView>
          </View>
        </View>
      </Tooltip>
    )
  },
  isEqual,
)

function AwayDot() {
  return (
    <ThemedView
      backgroundColor="avatarIdleIndicator"
      style={[
        {
          width: 5,
          height: 5,
          marginRight: 4,
          borderRadius: 10,
          marginTop: 2,

          shadowColor: 'rgba(0, 0, 0, 0.4)',
          shadowOffset: { height: 0, width: 0 },
          shadowRadius: 4,
        },
      ]}
    />
  )
}

const FocusDot = ({ userFocus }: { userFocus: UserFocusInfo | undefined }) => {
  let t = useTheme()

  useRerenderEvery('1s')

  let startTime = userFocus?.startedAt || 0
  let now = new Date()
  let diff = now.getTime() - startTime
  return (
    <Tooltip
      placement="bottomRight"
      label={
        <>
          <span className="light">Focused </span>
          {convertMsToTime(diff)}
        </>
      }
      enabled={Boolean(userFocus && userFocus.startedAt)}
    >
      <FocusIcon color={t.colors.yellow} />
    </Tooltip>
  )
}
