import { TouchableOpacity, View } from 'react-native'
import { DefaultTheme } from 'styled-components'
import { CircleButton } from '../main/CircleButton'

type Props = {
  onPress: () => void
  children: React.ReactNode
  style?: TouchableOpacity['props']['style']
  isActive?: boolean
  activeColor?: keyof DefaultTheme['colors']
  size?: 'md' | 'sm'
}

export const PinControlButton = ({
  onPress,
  children,
  isActive,
  activeColor,
  style,
  size = 'md',
}: Props) => {
  return (
    <CircleButton
      size={size == 'md' ? 28 : 24}
      backgroundColor="pinButtonBackground"
      activeColor={activeColor || 'screenShareButtonIcon'}
      onPress={onPress}
      isActive={isActive}
      style={{ marginLeft: 3 }}
    >
      {children}
    </CircleButton>
  )
}
