import { memo, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { EmojiView } from '../main/EmojiView'
import { Pressable } from '../shared/Pressable'
import { CustomReactionIcon } from './CustomReactionIcon'

type Props = {
  toggleReaction: (emoji: string) => void
  openPicker: () => void
}
export const ReactionBox = memo(({ toggleReaction, openPicker }: Props) => {
  let theme = useTheme()

  return (
    <View
      style={[
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 10,
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          height: 28,
          width: 125,
          borderColor: 'rgba(255, 255, 255, 0.03)',
          borderWidth: 1.5,
        },
      ]}
    >
      <EmojiButton children="👍" toggleReaction={toggleReaction} />
      <EmojiButton children="😍" toggleReaction={toggleReaction} />
      <EmojiButton children="👀" toggleReaction={toggleReaction} />
      <EmojiButton children="🎉" toggleReaction={toggleReaction} />
      <Pressable
        onPress={openPicker}
        style={({ hovered, pressed }) => [
          {
            marginHorizontal: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 100,
            padding: 2,
          },
          hovered && {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
          pressed && {
            backgroundColor: 'rgba(255, 255, 255, 0.02)',
          },
        ]}
      >
        <CustomReactionIcon
          width={16}
          height={16}
          color={theme.colors.quaternaryText}
          style={{ flexShrink: 0 }}
        />
      </Pressable>
    </View>
  )
})

const EmojiButton = memo(
  ({
    children,
    toggleReaction,
  }: {
    children: string
    toggleReaction: (emoji: string) => void
  }) => {
    return (
      <Pressable
        onPress={() => {
          toggleReaction(children)
        }}
        style={({ hovered, pressed }) => [
          {
            marginHorizontal: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 100,
            padding: 1,
          },
          hovered && {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
          pressed && {
            backgroundColor: 'rgba(255, 255, 255, 0.02)',
          },
        ]}
      >
        <EmojiView size={20}>{children}</EmojiView>
      </Pressable>
    )
  },
)
