import { createGlobalStyle, css } from 'styled-components'
import { Dropdown } from '../feed/Dropdown'
import { Notification as TinyNotificationWrapper } from './TinyNotification'

const fonts = css`
  @font-face {
    font-family: 'RM Neue';
    src: url('/fonts/RMNeue-Regular.woff2') format('woff2');
    font-weight: normal;
  }

  @font-face {
    font-family: 'RM Neue';
    src: url('/fonts/RMNeue-Bold.woff2') format('woff2');
    font-weight: bold;
  }
  @font-face {
    font-family: Dana;
    src: url('/fonts/dana-noen-regular.woff2') format('woff2');
    font-weight: normal;
  }

  /* 
  @font-face {
    font-family: 'RM Neue';
    src: url('/fonts/Inter-Medium.woff2') format('woff2');
    font-weight: 500;
  } */
`

const reset = css`
  html {
    box-sizing: border-box;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }

  /* RN focus state */
  svg,
  svg *,
  button {
    outline: 0;
  }

  html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }

  body {
    padding: 0;
    margin: 0;
    font-family: 'RM Neue', 'Dana', -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol' !important;

    user-select: none;
    direction: auto;
  }

  textarea,
  input,
  div,
  span,
  a {
    font-family: 'RM Neue', 'Dana', -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    outline: none;
  }

  img {
    pointer-events: none;
  }

  a {
    text-decoration: none;
  }

  body::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  #__next-prerender-indicator {
    display: none;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .light {
    color: rgba(255, 255, 255, 0.6);
  }
`

const startZIndexAt = 6
const elementsIndexes = [
  // - Less important elements
  // PersonProfileWrapper,

  // - static elements
  // RoomNode,
  // HeaderWrapper,
  // ToolbarWrapper,
  // LaptopWrapper,

  // - button overlays, etc
  // ConnectionSign,

  // - popovers
  Dropdown.WrapperNode,
  TinyNotificationWrapper,
]
export const zIndexPreventOverlay = 50
export const zIndexWindowControl = 60

const zIndexes = css`
  ${elementsIndexes.map(
    (elementClass, i) => css`
      ${elementClass}${elementClass} {
        z-index: ${i + startZIndexAt};
      }
    `,
  )}
`

export const GlobalStyle = createGlobalStyle`
  ${fonts}
  ${reset}
  ${zIndexes}
`
