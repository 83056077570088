import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export function DownloadingIcon(props: SvgProps) {
  return (
    <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        d="M2 13c0-.5.5-1 1-1s1 .5 1 1v5a2 2 0 002 2h12a2 2 0 002-2v-5a1 1 0 112 0v5a4 4 0 01-4 4H6a4 4 0 01-4-4v-5z"
        fill={props.color ? props.color : '#727270'}
      />
      <Path
        d="M11 14a1 1 0 102 0V2a1 1 0 10-2 0v12z"
        fill={props.color ? props.color : '#727270'}
      />
      <Path
        d="M16.293 9.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.376.036l-5-4.5a1 1 0 011.338-1.486l4.295 3.865 4.329-4.33z"
        fill={props.color ? props.color : '#727270'}
      />
    </Svg>
  )
}
