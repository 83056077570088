import * as Sentry from '@sentry/browser'
import {
  EnsureHasAccessFunc,
  useMediaPermissions,
} from '@there/components/shared/use-media-permissions'
import { electronApi, isElectron } from '@there/desktop/utils/electron-api'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  SafeDisplay,
  SafeSource,
  useCurrentMediaDevicesContext,
} from './CurrentMediaDevicesContext'
import { useLatest } from './use-latest'

const debug = require('debug')('desktop:use-stream')

export type MediaType = 'mic' | 'screen' | 'camera'

export type StreamsObject = {
  mic: {
    stream: MediaStream | undefined
    // v2
    // track: MediaStreamTrack | undefined
    getMutedTrack: () => MediaStreamTrack | undefined
    isMuted: boolean
    mute(): void
    toggle(isMuted?: boolean): void
    setMuted(isMuted?: boolean): void
    stop(): void
    switchDevice(deviceId: string): Promise<void>
  }
  screen: {
    stream: MediaStream | undefined
    display: SafeDisplay | undefined
    stop(): void
    setEnabled(isEnabled: boolean): void
  }
  systemAudio: {
    stream: MediaStream | undefined
    start(deviceId: string): void
    stop(): void
  }
  camera: {
    stream: MediaStream | undefined
    stop(): void
    switchDevice(deviceId: string): Promise<void>
    changeQuality(quality: 'high' | 'low'): Promise<void>
  }
  ensureHasAccess: EnsureHasAccessFunc
  /** Coming soon */
  allStreams: MediaStream[]
  stopAll: () => void
  ready(mediaTypes: MediaType[]): boolean
}

const isBrowser = !Boolean(electronApi)

// This hook is for when we have private room for call
export function useStream(input: {
  moduleEnabled: boolean
}): [
  (which: MediaType[], options?: { enabled?: boolean }) => Promise<void>,
  StreamsObject,
] {
  const {
    currentMic,
    currentCamera,
    // Used for bounds
    preferredDisplay,
    // Used for getUserMedia
    preferredSource,
    isMicMuted,
    dispatch,
  } = useCurrentMediaDevicesContext()
  const [screenStream, setScreenStream] = useState<MediaStream | undefined>(
    undefined,
  )
  const [microphoneStream, setMicrophoneStream] = useState<
    MediaStream | undefined
  >(undefined)
  const [systemAudioStream, setSystemAudioStream] = useState<
    MediaStream | undefined
  >(undefined)
  const [cameraStream, setCameraStream] = useState<MediaStream | undefined>(
    undefined,
  )
  // const [microphoneMutedTrack, setMicrophoneMutedTrack] = useState<
  //   MediaStreamTrack | undefined
  // >(undefined)
  const microphoneMutedTrack = useRef<MediaStreamTrack | undefined>()

  const [fetchingStream, setFetchingStream] = useState(false)
  const [display, setDisplay] = useState<SafeDisplay | undefined>(undefined)

  const allStreams = useMemo(() => {
    const streamsArray: MediaStream[] = []

    if (screenStream) streamsArray.push(screenStream)
    if (microphoneStream) streamsArray.push(microphoneStream)
    if (cameraStream) streamsArray.push(cameraStream)

    return streamsArray
  }, [screenStream, microphoneStream, cameraStream])

  const microphoneIsMuted = useRef<boolean | undefined>()

  const muteMicrophone = useCallback(() => {
    if (!microphoneStream) {
      return
    }

    for (const track of microphoneStream.getAudioTracks()) {
      track.enabled = false
      dispatch({ type: 'microphone state changed', isMuted: true })
      microphoneIsMuted.current = true
    }
  }, [microphoneStream, dispatch])

  const toggleMicrophone = useCallback(
    (isMuted?: boolean) => {
      if (!microphoneStream) {
        return
      }

      for (const track of microphoneStream.getAudioTracks()) {
        const nextIsMuted =
          typeof isMuted === 'undefined' ? !!track.enabled : isMuted
        track.enabled = !nextIsMuted
        dispatch({ type: 'microphone state changed', isMuted: nextIsMuted })
        microphoneIsMuted.current = nextIsMuted
      }
    },
    [microphoneStream, dispatch],
  )

  let currentMicId = currentMic?.id
  let currentCameraId = currentCamera?.id

  let permissions = useMediaPermissions({
    enabled: input.moduleEnabled,
  })

  // Don't use permissions object as deps
  let ensureHasAccess = permissions.ensureHasAccess

  let streamsSoFar = useRef<any[]>([])
  let cameraStreamsSoFar = useRef<any[]>([])
  let screenStreamsSoFar = useRef<any[]>([])

  useEffect(() => {
    //@ts-ignore
    window.streamsSoFar = streamsSoFar.current
  }, [])

  // let [processedScreen] = useProcessVideo(screenStream)

  const getStream = useCallback(
    async function getStream(
      mediaTypes: MediaType[],
      options: { enabled?: boolean } | undefined = {},
    ) {
      if (fetchingStream) {
        return
      }

      ensureHasAccess(mediaTypes)

      const haveDisplayStream = screenStream && screenStream.active
      const haveAudioStream = microphoneStream && microphoneStream.active
      const haveCameraStream = cameraStream && cameraStream.active

      const wantsDisplay = mediaTypes.includes('screen')
      const wantsAudio = mediaTypes.includes('mic')
      const wantsCamera = mediaTypes.includes('camera')

      setFetchingStream(true)
      debug('Started getting local stream...')

      // Display ------------------------------
      if (wantsDisplay && !haveDisplayStream) {
        debug('preferredDisplay=', preferredDisplay)
        debug('preferredSource=', preferredSource)
        // Removed fallback to not mess up window sharing
        // const pickedDisplay = preferredDisplay || (await getPrimaryDisplay())
        const pickedDisplay = preferredDisplay
        const pickedSource = preferredSource
        try {
          const displayStream = await getDisplayStream({
            display: pickedDisplay ? pickedDisplay : true,
            source: pickedSource,
            browser: isBrowser,
          })

          if (!displayStream) {
            throw new Error('Cannot get screen media capture, please retry.')
          }

          // Turn it into black
          if (options.enabled === false && displayStream) {
            for (const t of displayStream.getTracks()) {
              t.enabled = false
            }
          }

          screenStreamsSoFar.current.push(displayStream)
          setScreenStream(displayStream)
          setDisplay(preferredDisplay)
        } catch (error) {
          alert(
            'Cannot get screen media capture, please retry or check the permissions.',
          )
          Sentry.withScope((scope) => {
            scope.setExtra('message', 'Display stream failed')
            Sentry.captureException(error)
          })
          console.error(error)
        }
      }

      // Audio --------------------------------
      if (!haveAudioStream && wantsAudio) {
        // Stop mic stream if it's already running - safety net
        if (microphoneStream && !microphoneStream.active) {
          debug('stopping mic stream before getting new one')
          for (const track of microphoneStream.getTracks()) {
            track.stop()
          }
        }

        debug('Getting new microphone stream')
        const audio = await getAudioStream({ deviceId: currentMicId })

        streamsSoFar.current.push(audio)

        // Turn it into muted until accept
        if (options.enabled === false && audio) {
          for (const t of audio.getTracks()) {
            t.enabled = false
          }
        }

        // Save bandwidth
        try {
          if (audio) {
            for (const t of audio.getTracks()) {
              t.applyConstraints({
                channelCount: 1,
              })
            }
          }
        } catch (error) {
          console.error(error)
        }

        setMicrophoneStream(audio)

        // Note(@mo): Once we store "preferred device" in localStorage, we'll need to set this
        // dispatch({ type: 'microphone device changed', deviceId })

        const isMuted = options.enabled === false ? true : false
        dispatch({
          type: 'microphone state changed',
          isMuted,
        })
        microphoneIsMuted.current = isMuted
      }

      if (!haveCameraStream && wantsCamera) {
        debug('Getting new camera stream', currentCameraId)
        const camera = await getCameraStream({ deviceId: currentCameraId })

        //Turn it into black
        if (options.enabled === false && camera) {
          for (const track of camera.getTracks()) {
            track.enabled = false
          }
        }

        setCameraStream(camera)
        cameraStreamsSoFar.current.push(camera)
      }

      debug('Fetching streams finished')
      setFetchingStream(false)
    },
    [
      fetchingStream,
      ensureHasAccess,
      screenStream,
      microphoneStream,
      cameraStream,
      preferredDisplay,
      preferredSource,
      currentMicId,
      currentCameraId,
      dispatch,
    ],
  )

  /**
   * System audio
   * for now get deviceId from user, but we can get it from the system
   */
  const startSystemAudio = useCallback(
    async (deviceId) => {
      // do not get system audio
      if (!deviceId) return
      if (deviceId === 'default') return

      const audio = await getAudioStream({
        deviceId: deviceId,
      })

      if (!audio) return

      // Set content hint to avoid noise cancellation
      let track = audio.getAudioTracks()[0]

      if (track.label === microphoneStream?.getAudioTracks()[0].label) {
        // do not allow duplicate sound (inarix issue)
        return
      }

      try {
        if ('contentHint' in track) {
          //@ts-ignore
          track.contentHint = 'music'
          //@ts-ignore
          if (track.contentHint !== hint) {
            console.info("Invalid audio track contentHint: 'music'")
          }
        } else {
          track.applyConstraints({ noiseSuppression: false })
          console.info('MediaStreamTrack contentHint attribute not supported')
        }
      } catch (_error) {}

      setSystemAudioStream(audio)
    },
    [microphoneStream],
  )
  const stopSystemAudio = useCallback(() => {
    if (!systemAudioStream) return
    for (const track of systemAudioStream.getTracks()) track.stop()
    setSystemAudioStream(undefined)
    debug('system audio stopped')
  }, [systemAudioStream])

  const stopScreen = useCallback(() => {
    if (!screenStream) {
      return undefined
    }

    for (const track of screenStream.getTracks()) track.stop()
    setScreenStream(undefined)

    // HACK: to fix stream being in use (and preventing notifications)
    try {
      for (const stream of screenStreamsSoFar.current) {
        for (const track of stream.getTracks()) track.stop()
      }
    } catch (error) {}
  }, [setScreenStream, screenStream])

  const stopMicrophone = useCallback(() => {
    if (!microphoneStream) {
      return undefined
    }

    for (const track of microphoneStream.getTracks()) track.stop()

    setMicrophoneStream(undefined)
    microphoneMutedTrack.current?.stop()
    microphoneMutedTrack.current = undefined
    debug('mic stopped')

    // HACK: to fix stream being in use
    try {
      for (const stream of streamsSoFar.current) {
        for (const track of stream.getTracks()) track.stop()
      }
    } catch (error) {}
  }, [setMicrophoneStream, microphoneStream])

  const stopCamera = useCallback(() => {
    let stream = cameraStream

    if (!stream) {
      return undefined
    }

    for (const track of stream.getTracks()) track.stop()
    setCameraStream(undefined)

    // HACK: to fix stream being in use
    try {
      for (const stream of cameraStreamsSoFar.current) {
        for (const track of stream.getTracks()) track.stop()
      }
    } catch (error) {}
  }, [cameraStream])

  const stopAll = useCallback(() => {
    stopMicrophone()
    stopScreen()
    stopCamera()
    stopSystemAudio()

    debug(`Stopped all media streams.`)
  }, [stopMicrophone, stopScreen, stopCamera, stopSystemAudio])

  const microphoneDeviceId = useRef<string | undefined>()
  const gettingDeviceId = useRef<string | undefined>()
  const latestMicrophoneStream = useLatest(microphoneStream)

  const changeMicrophoneDevice = useCallback(
    async (deviceId) => {
      const prevStream = latestMicrophoneStream.current
      const isCurrentStreamSame =
        latestMicrophoneStream.current &&
        latestMicrophoneStream.current.getAudioTracks()[0]?.getCapabilities()
          ?.deviceId === deviceId

      if (isCurrentStreamSame) {
        debug('mic device correct')
        return
      }

      if (gettingDeviceId.current === deviceId) {
        debug('change device in progress')
        return
      }

      gettingDeviceId.current = deviceId

      const audio = await getAudioStream({
        deviceId: deviceId,
      })

      if (gettingDeviceId.current !== deviceId) {
        debug('old change device cancelled $$')
        audio?.getAudioTracks()[0]?.stop()
        return
      }

      // When user is already muted, device change must adhere to that
      if (isMicMuted && audio) {
        for (const track of audio.getTracks()) {
          track.enabled = false
        }
      }

      setMicrophoneStream(audio)

      if (prevStream) {
        // stop afterwards so we keep the old stream until it's loaded
        for (const track of prevStream.getTracks()) track.stop()
      }

      // Add timeout so it's not stopped by above function call
      setTimeout(() => {
        streamsSoFar.current?.push(audio)
      }, 0)
    },
    [isMicMuted, latestMicrophoneStream],
  )

  const microphoneDeviceChanged = useCallback(
    async (deviceId) => {
      changeMicrophoneDevice(deviceId)

      // ??????
      // ??????
      // ??????
      // ??????
      // This is our way to detect if it was changed here, or we're reacting to a change from feed
      // if (microphoneDeviceId.current !== deviceId) {
      //   dispatch({
      //     type: 'update current mic',
      //     micId: deviceId,
      //     name: 'Auto',
      //   })
      //   microphoneDeviceId.current = deviceId
      // }
    },
    [changeMicrophoneDevice],
  )

  /**
   * Camera Change
   */

  const cameraDeviceId = useRef<string | undefined>()
  const gettingCameraDeviceId = useRef<string | undefined>()
  const latestCameraStream = useLatest(cameraStream)

  const changeCameraDevice = useCallback(
    async (deviceId) => {
      let prevStream = latestCameraStream.current
      const isCurrentStreamSame =
        latestCameraStream.current &&
        latestCameraStream.current.getVideoTracks()[0]?.getCapabilities()
          ?.deviceId === deviceId

      if (isCurrentStreamSame) {
        debug('mic device correct')
        return
      }

      if (gettingCameraDeviceId.current === deviceId) {
        debug('change device in progress')
        return
      }

      gettingCameraDeviceId.current = deviceId

      const newCameraStream = await getCameraStream({
        deviceId: deviceId,
      })

      if (gettingCameraDeviceId.current !== deviceId) {
        debug('old change device cancelled $$')
        newCameraStream?.getTracks()[0]?.stop()
        return
      }

      setCameraStream(newCameraStream)

      if (prevStream) {
        // stop afterwards so we keep the old stream until it's loaded
        for (const track of prevStream.getTracks()) track.stop()
      }

      // Add timeout so it's not stopped by above function call
      setTimeout(() => {
        cameraStreamsSoFar.current?.push(newCameraStream)
      }, 0)
    },
    [latestCameraStream],
  )

  const cameraDeviceChanged = useCallback(
    async (deviceId) => {
      changeCameraDevice(deviceId)

      // ???????
      // ???????
      // ???????
      // ???????
      // This is our way to detect if it was changed here, or we're reacting to a change from feed
      // if (cameraDeviceId.current !== deviceId) {
      //   dispatch({
      //     type: 'current camera changed',
      //     deviceId: deviceId,
      //     name: 'Auto',
      //   })
      //   cameraDeviceId.current = deviceId
      // }
    },
    [changeCameraDevice],
  )

  const changeCameraQuality = useCallback(
    async (quality: 'high' | 'low') => {
      let constraints: MediaTrackConstraints = {}
      switch (quality) {
        case 'high':
          constraints = {
            width: { min: 480, ideal: 720, max: 1080 },
            height: { min: 480, ideal: 720, max: 1080 },
          }

        case 'low':
          constraints = {
            // setting max to 720, breaks changing constraints as "high" already meets it
            width: { min: 120, ideal: 240, max: 480 },
            height: { min: 120, ideal: 240, max: 480 },
          }
      }

      cameraStream
        ?.getVideoTracks()[0]
        .applyConstraints(constraints)
        .then(() => {})
    },
    [cameraStream],
  )

  // React to muted from CallControl in feed
  useEffect(() => {
    if (
      typeof isMicMuted !== 'undefined' &&
      // Loop prevention
      (isMicMuted !== microphoneIsMuted.current ||
        typeof microphoneIsMuted.current === 'undefined')
    ) {
      // User has changed audio from feed, now we need to react to the change
      const isMuted = isMicMuted

      // By setting this, we'll not re-dispatch in the event handler
      microphoneIsMuted.current = isMuted
      toggleMicrophone(isMuted)
    }
  }, [isMicMuted, toggleMicrophone])

  const setScreenEnabled = useCallback(
    (isEnabled) => {
      if (!screenStream) return

      for (const track of screenStream.getTracks()) {
        track.enabled = isEnabled
      }
    },
    [screenStream],
  )

  ////////////////// Muted Microphone track /////////////////
  const getMutedTrack = useCallback(() => {
    if (!microphoneStream) return
    if (microphoneMutedTrack.current) {
      // make sure muted
      microphoneMutedTrack.current.enabled = false
      return microphoneMutedTrack.current
    }

    let clonedTrack = microphoneStream.getAudioTracks()[0].clone()
    clonedTrack.enabled = false
    microphoneMutedTrack.current = clonedTrack
    return clonedTrack
  }, [microphoneMutedTrack, microphoneStream])
  //\\\\\\\\\\\\\\\ Muted Microphone track \\\\\\\\\\\\\\\\\

  const streams = useMemo(
    () => ({
      mic: {
        stream: microphoneStream,
        getMutedTrack,
        isMuted: isMicMuted,
        mute: muteMicrophone,
        switchDevice: microphoneDeviceChanged,
        stop: stopMicrophone,
        toggle: toggleMicrophone,
        setMuted: toggleMicrophone,
      },
      systemAudio: {
        stream: systemAudioStream,
        stop: stopSystemAudio,
        start: startSystemAudio,
      },
      screen: {
        stream: screenStream,
        display: display,
        stop: stopScreen,
        setEnabled: setScreenEnabled,
      },
      camera: {
        stream: cameraStream,
        stop: stopCamera,
        switchDevice: cameraDeviceChanged,
        changeQuality: changeCameraQuality,
      },
      ensureHasAccess,
      allStreams,
      stopAll,
      ready: (mediaTypes: MediaType[]) => {
        for (let mediaType of mediaTypes) {
          switch (mediaType) {
            case 'mic': {
              if (!microphoneStream) {
                return false
              }
              break
            }

            case 'screen': {
              if (!screenStream) {
                return false
              }
              break
            }

            case 'camera': {
              return false
            }
          }
        }

        return true
      },
    }),
    [
      microphoneStream,
      getMutedTrack,
      isMicMuted,
      muteMicrophone,
      microphoneDeviceChanged,
      stopMicrophone,
      toggleMicrophone,
      systemAudioStream,
      stopSystemAudio,
      startSystemAudio,
      screenStream,
      display,
      stopScreen,
      setScreenEnabled,
      cameraStream,
      stopCamera,
      cameraDeviceChanged,
      changeCameraQuality,
      ensureHasAccess,
      allStreams,
      stopAll,
    ],
  )

  useEffect(() => {
    //@ts-ignore
    window.getStreams = () => {
      return {
        getStream,
        streams,
      }
    }
  })

  return [getStream, streams]
}

interface PartialDisplay {
  id: string | number
  /** Optional */
  name?: string
  bounds?: {
    width: number
    height: number
  }
}

type GetStreamOptions = {
  display: boolean | PartialDisplay
  source: SafeSource | undefined
  // display: boolean | DisplaySource
  browser: boolean
  constraints?: MediaTrackConstraints
}

// 👨‍🔬 For in browser
async function getDisplayStream({
  display,
  source,
  browser,
  constraints,
}: GetStreamOptions): Promise<MediaStream | undefined> {
  let screenStream

  try {
    if (!browser && (display || source)) {
      // Electron
      const width =
        typeof display === 'object' ? display.bounds?.width : undefined
      const height =
        typeof display === 'object' ? display.bounds?.height : undefined

      debug('Source picked:', source?.id, source?.name)

      let boundsConstraints = {}
      if (!width || !height) {
        debug('(Display did not have bounds)')
      } else {
        boundsConstraints = {
          // X2 for retina please
          // minWidth: width ? width / 2 : 0,
          minWidth: 0,
          maxWidth: width ? width : undefined,
          minHeight: 0,
          maxHeight: height ? height : undefined,
        }
      }

      screenStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          // @ts-ignore
          mandatory: {
            chromeMediaSource: 'desktop',
            // chromeMediaSourceId: displayId,
            chromeMediaSourceId: source?.id,
            ...boundsConstraints,
            ...constraints,
            minFrameRate: 5,
            maxFrameRate: 24,
          },
        },
      })
    } else {
      debug('Getting stream from browser.')
      // Browser
      // @ts-ignore
      screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: !!display,
        audio: false,
      })
    }

    debug('Screen stream received.')

    return screenStream
  } catch (error) {
    console.error('Failed screen media', error)
    Sentry.captureException(error)
  }
}

async function getAudioStream({ deviceId }: { deviceId?: string } = {}) {
  try {
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: deviceId
        ? {
            deviceId: deviceId,
          }
        : true,
      video: false,
    })

    debug('Got audio.')

    return audioStream
  } catch (error) {
    console.error('Failed to get audio stream:', error)
    Sentry.captureException(error)
  }
}

async function getCameraStream({ deviceId }: { deviceId?: string } = {}) {
  async function getStream() {
    let deviceIdObject = deviceId ? { deviceId } : {}
    const cameraStream = await navigator.mediaDevices.getUserMedia({
      video: {
        ...deviceIdObject,

        width: { min: 480, ideal: 720, max: 1080 },
        height: { min: 480, ideal: 720, max: 1080 },
        // @ts-ignore
        resizeMode: 'crop-and-scale',
      },
    })

    debug('Got camera.')

    return cameraStream
  }

  try {
    // await here to handle errors
    let stream = await getStream()
    return stream
  } catch (error) {
    // we handle this in use-permissions
    if (!isElectron) {
      alert(
        'Cannot obtain camera video stream, please retry or check the permissions.',
      )
    }

    Sentry.withScope((scope) => {
      scope.setExtra('message', 'Camera stream failed')
      scope.setExtra('id', deviceId)
      Sentry.captureException(error)
    })
    console.error('Failed to get camera stream:', error)

    // Try to fix opal issue
    if (String(error).includes('Could not start video source')) {
      // Try again
      await new Promise((resolve) => setTimeout(resolve, 1500))
      try {
        let stream = await getStream()
        return stream
      } catch (error) {
        console.warn('Failed again to get camera ')
        // Failed again
        Sentry.withScope((scope) => {
          scope.setExtra('message', 'Camera stream failed')
          scope.setExtra('id', deviceId)
          Sentry.captureException(error)
        })
      }
    }
  }
}

export const replaceTrack = ({
  oldTrack,
  newTrack,
  stream,
}: {
  oldTrack: MediaStreamTrack | undefined
  newTrack: MediaStreamTrack | undefined
  stream: MediaStream
}) => {
  if (oldTrack) {
    stream.removeTrack(oldTrack)
  }

  if (newTrack) {
    stream.addTrack(newTrack)
  }

  return stream
}
