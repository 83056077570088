import { Pressable } from '../shared/Pressable'
import { Text } from 'react-native'
import { useTheme } from '../feed/ThemeContext'

export const TextButton = ({
  children,
  onPress,
}: {
  children: string
  onPress?: () => void
}) => {
  let theme = useTheme()
  return (
    <Pressable
      onPress={onPress}
      style={[
        {
          paddingHorizontal: 10,
          height: '100%',

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      ]}
    >
      {({ hovered, pressed }) => (
        <Text
          style={[
            {
              color: theme.colors.tertiaryText,
              fontSize: theme.fontSizes.normal,
            },
            hovered && {
              color: theme.colors.secondaryText,
            },
            pressed && { opacity: 0.9, transform: [{ scale: 0.95 }] },
          ]}
        >
          {children}
        </Text>
      )}
    </Pressable>
  )
}
