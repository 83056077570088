import { memo } from 'react'
import {
  currentDialogIdAtom,
  leaveDialogAtom,
} from '@there/components/feed/useLobbyMan'
import { useAtom } from 'jotai'
import { GoodButton } from '@there/components/shared/GoodButton'
import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
import { ClosedDoorIcon } from '@there/components/main/mainIcons'
import { Tooltip } from '@there/components/shared/Tooltip'

export const LeaveButton = memo(() => {
  let [{ leaveDialog }] = useAtom(leaveDialogAtom)

  return (
    <Tooltip label="Leave video chat">
      <GoodButton
        variant="transparent"
        // title="Leave"
        icon={
          // <LeaveIcon />
          <ClosedDoorIcon color="#FF1384" />
        }
        onPress={() => {
          leaveDialog()
        }}
      />
    </Tooltip>
  )
})

function LeaveIcon(props: SvgProps) {
  return (
    <Svg width={11} height={11} fill="none" {...props}>
      <Path
        d="M10 1L5.5 5.5M1 10l4.5-4.5m0 0L10 10M5.5 5.5L1 1"
        stroke="#FF11A0"
        strokeOpacity={0.7}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Svg>
  )
}
