import { useLatest } from '@there/components/shared/use-latest'
import {
  electronSupports,
  ipc,
  useListenToIpc,
} from '@there/desktop/utils/electron-api'
import { useCallback, useState } from 'react'

export type NativeDropdownItem =
  | {
      type: string
      label: string
      disabled?: boolean
      payload?: any
    }
  | {
      type: '__separator'
    }

type Props = {
  items: NativeDropdownItem[]
  key: string
  onSelectItem: (selectedItemType: string, id: string) => void
}

export const useNativeDropdown = ({ items, onSelectItem, key }: Props) => {
  let [id, setId] = useState<string | undefined>(undefined)
  const openMenu = useCallback(
    (id: string, overrideItems?: NativeDropdownItem[]) => {
      setId(id)

      let finalItems = (overrideItems || items).filter((item) => {
        // Filter separator for version that don't support it
        if (
          !electronSupports?.dropdownSeparator &&
          item.type === '__separator'
        ) {
          return false
        }
        return true
      })

      ipc?.invoke('dropdown:open', { items: finalItems, key })
    },
    [items, key],
  )

  let onSelectItemFunction = useLatest(onSelectItem)

  useListenToIpc(
    'dropdown:click-action',
    useCallback(
      (event, data) => {
        if (!data || !data.type || !data.key) return
        if (!id) return
        if (data.key !== key) return
        onSelectItemFunction.current?.(data.type, id)
      },
      [id, key, onSelectItemFunction],
    ),
  )

  return { openMenu }
}
