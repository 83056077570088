import { View, Text, Image } from 'react-native'
import { useAppContext } from '@there/components/shared/AppContext'
import { getProfileBackground } from '@there/components/utils/get-profile-background'
import { Initials } from '@there/components/shared/Initials'
import { useTheme } from '@there/components/feed/ThemeContext'
import { Pressable } from '@there/components/shared/Pressable'
import { activeNewChatPeerAtom } from '@there/components/atoms/chatAtom'
import { contentViewAtom } from '@there/components/atoms/contentViewAtom'
import { useUpdateAtom, useAtomValue } from 'jotai/utils'
import { useAtom } from 'jotai'
import { PeopleIcon } from '@there/components/main/mainIcons'
import { spaceLogosComponents } from '@there/components/pro/SpaceLogos'
import { useSpaceManager } from '../shared/spaceContext'
import { spaceLogoDocumentAtom } from '../atoms/spaceAtom'
import { useMainWindowContext } from '../shared/use-main-window'

export const ActiveSpaceName = ({ isHome }: { isHome: boolean }) => {
  let setActiveChatPeer = useUpdateAtom(activeNewChatPeerAtom)
  let { activeSpaceName, activeSpaceId } = useAppContext()
  let { dispatch } = useMainWindowContext()
  let theme = useTheme()
  return (
    <Pressable
      style={({ pressed }) => [
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          // Push the icon to side
          marginRight: 'auto',
          overflow: 'hidden',
          flexShrink: 1,
          zIndex: 1,
        },
        pressed && { opacity: 0.85 },
      ]}
      onPress={() => {
        if (isHome) {
          dispatch({ type: 'change mode', mode: 'home' })
        } else {
          dispatch({ type: 'change mode', mode: 'room' })
        }

        setActiveChatPeer({})
      }}
    >
      {isHome ? (
        <View style={{ width: SPACE_AVATAR_SIZE }}>
          <PeopleIcon color={theme.colors.primary} width={24} height={24} />
        </View>
      ) : (
        activeSpaceName &&
        activeSpaceId && (
          <>
            <SpaceAvatar
              space={{ id: activeSpaceId, name: activeSpaceName }}
              size={SPACE_AVATAR_SIZE}
            />
          </>
        )
      )}
      <Text
        style={{
          color: theme.colors.secondaryText,
          fontSize: theme.fontSizes[15],
          marginLeft: 10,
          // fontWeight: 'bold',
          maxWidth: '100%',
        }}
        numberOfLines={1}
      >
        {isHome ? 'Home' : activeSpaceName ? activeSpaceName : ''}
      </Text>
    </Pressable>
  )
}

const SPACE_AVATAR_SIZE = 23

export const SpaceAvatar = ({
  space,
  size,
  unreadNum,
}: {
  // Minimum space info
  space: { id: string; name: string }
  size: number
  unreadNum?: number
}) => {
  let Logo = spaceLogosComponents[space.id]
  // let spaceLogo = useAtomValue(spaceLogoDocumentAtom)
  let theme = useTheme()

  return (
    <>
      <View
        style={{
          width: size,
          height: size,
          borderRadius: size,
          // overflow: 'hidden',

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          backgroundColor: Logo
            ? 'transparent'
            : getProfileBackground(space.name || 'space'),
        }}
      >
        {Logo ? (
          <Logo width={size} />
        ) : (
          <Initials name={space.name} size={size} />
        )}
        {!!unreadNum && unreadNum > 0 ? (
          <View
            style={{
              minWidth: size / 3,
              height: size / 3,
              borderRadius: size / 3,
              backgroundColor: theme.colors.primary,

              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
          >
            {/* <Text>{unreadNum}</Text> */}
          </View>
        ) : (
          <></>
        )}
      </View>
    </>
  )
}
