import { DocumentInfo, NewMessageInfo } from '@there/sun/utils/node-types'
import { memo } from 'react'
import { Text, View, Image } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import deepEqual from 'react-fast-compare'
import { BucketEndpoint } from '@there/shared/api/config'
import { SenderName } from '@there/components/message/SenderName'
import { getFileFullPath } from '@there/components/message/helpers'
import { useNurNode } from '@there/components/sun/use-node'
import { toGlobalId } from '@there/tower/utils/global-id'
import { AttachFileMessage } from '@there/components/main/mainIcons'

type Props = {
  message: NewMessageInfo | null
  withSenderName: boolean
}

export const EmbeddedMessage = memo(({ message, withSenderName }: Props) => {
  const theme = useTheme()

  let [document] = useNurNode<DocumentInfo>({
    id:
      message?.documentId ||
      toGlobalId('Document', message?.documentId || '') ||
      null,
  })

  return (
    <View
      style={{
        width: '100%',
        height: 30,
        borderLeftWidth: 2,
        borderLeftColor: theme.colors.tertiaryText,
        paddingRight: 4,
        paddingTop: 1,
        flexDirection: 'row',

        // Adopt to bottom
        flexShrink: 1,
        minWidth: 0,
        overflow: 'hidden',
      }}
    >
      {document && (
        <>
          {document.type === 'File' ? (
            <View
              style={{
                width: 28,
                height: 28,
                marginLeft: 4,
                marginRight: 2,
                borderRadius: 2,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.colors.quaternaryLight,
              }}
            >
              <AttachFileMessage />
            </View>
          ) : (
            <Image
              style={{
                width: 28,
                height: 28,
                marginLeft: 4,
                marginRight: 2,
                borderRadius: 2,
              }}
              source={{
                uri:
                  document.type === 'Gif'
                    ? document.remotePreview || ''
                    : getFileFullPath(document),
              }}
            />
          )}
        </>
      )}
      {message && (
        <View
          style={{
            paddingLeft: 4,
            paddingVertical: 1,
            justifyContent: 'space-between',
          }}
        >
          <SenderName userId={message.senderId} embedded={true} />
          <Text
            style={{
              fontSize: theme.fontSizes.normal,
              color: theme.colors.tertiaryLight,
              textAlign: 'left',
              maxWidth: '100%',
            }}
            numberOfLines={1}
          >
            {message.text
              ? message.text?.replace(/(\r\n|\n|\r)/gm, '')
              : document?.type === 'Photo'
              ? 'Photo'
              : document?.type === 'Gif'
              ? 'Gif'
              : document?.type === 'File'
              ? 'File'
              : ''}
          </Text>
        </View>
      )}
    </View>
  )
}, deepEqual)
