import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export function MuteIcon(props: SvgProps) {
  return (
    <Svg width={16} height={16} fill="none" viewBox="0 0 16 16" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.06 1.646a.5.5 0 10-.706.708L5 5l-.333.333-2.262.453a1.333 1.333 0 00-1.072 1.307v1.814c0 .636.449 1.183 1.072 1.307l2.262.453 2.195 2.195A.666.666 0 008 12.39V8l2.162 2.162a.499.499 0 00.638.638l.935.935a4.84 4.84 0 01-.976.623.5.5 0 00.434.901 5.819 5.819 0 001.252-.814l1.201 1.201a.5.5 0 00.708-.707l-1.205-1.204a5.833 5.833 0 00-1.902-8.967.5.5 0 10-.443.896 4.833 4.833 0 011.634 7.36l-.825-.825a3.165 3.165 0 00-.715-4.952.5.5 0 10-.495.868 2.165 2.165 0 01.502 3.376L8 6.586V3.609a.667.667 0 00-1.138-.47L5.707 4.292 3.061 1.646z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}
