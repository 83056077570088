import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useImmerReducer } from 'use-immer'
import { Draft } from 'immer'

import { FeedWindowMode, FeedWindowPages } from '@there/shared/desktop/types'
import { ReactNode } from 'react'
type MainWindowMode = 'room' | 'chat' | 'home'
type State = {
  mode: MainWindowMode
  isFeedOpen: boolean
  isGameRoom: boolean
  isSettingsOpen: boolean
  page: FeedWindowPages
  sidebarOpen: boolean
}

const initialState: State = {
  mode: 'room',
  isFeedOpen: false,
  isGameRoom: false,
  isSettingsOpen: false,
  sidebarOpen: false,
  page: FeedWindowPages.Space,
}

type FeedWindowAction =
  | {
      type: 'change mode'
      mode: MainWindowMode
      isFeedOpen?: boolean
      isGameRoom?: boolean
    }
  | { type: 'change feed mode'; isFeedOpen: boolean }
  | { type: 'change gameRoom mode'; isGameRoom: boolean }
  | { type: 'settings state changed'; state: boolean }
  | { type: 'change page'; page: FeedWindowPages }
  | { type: 'sidebar toggled' }
  | { type: 'load state'; state: State }

type FeedWindowDispatch = React.Dispatch<FeedWindowAction>

function reducer(draft: Draft<State>, action: FeedWindowAction): void | State {
  switch (action.type) {
    case 'change mode':
      draft.mode = action.mode
      if (typeof action.isFeedOpen === 'boolean') {
        draft.isFeedOpen = action.isFeedOpen
      }

      if (typeof action.isGameRoom === 'boolean') {
        draft.isGameRoom = action.isGameRoom
      }

      break

    case 'change feed mode':
      draft.isFeedOpen = action.isFeedOpen
      break

    case 'change gameRoom mode':
      draft.isGameRoom = action.isGameRoom
      break

    case 'settings state changed':
      draft.isSettingsOpen = action.state
      break

    case 'change page':
      draft.page = action.page
      break

    case 'sidebar toggled':
      draft.sidebarOpen = !draft.sidebarOpen
      break
    case 'load state':
      return action.state

    default:
      return draft
  }
}

const useFeedWindowState = (): [State, FeedWindowDispatch] => {
  return useImmerReducer<State, FeedWindowAction>(reducer, initialState)
}

export type FeedWindowManager = State & {
  dispatch: FeedWindowDispatch
}

export function useFeedWindowManager(): FeedWindowManager {
  const [state, dispatch] = useFeedWindowState()

  const [hydrated, setHydrated] = useState(false)

  // Load state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    const feedWindowStateJson = localStorage.getItem('feedWindowState')

    if (
      !feedWindowStateJson ||
      typeof feedWindowStateJson !== 'string' ||
      !feedWindowStateJson.startsWith('{')
    ) {
      setHydrated(true)
      return
    }

    // Hydrate
    dispatch({ type: 'load state', state: JSON.parse(feedWindowStateJson) })
    setHydrated(true)
  }, [dispatch])

  // Persist state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    // Don't replace our cache with empty state
    if (!hydrated) {
      return
    }

    localStorage.setItem('feedWindowState', JSON.stringify(state))
  }, [hydrated, state])

  let value: FeedWindowManager = useMemo(() => {
    return {
      ...state,
      dispatch,
    }
  }, [dispatch, state])

  return value
}

const initialValue: FeedWindowManager = {
  ...initialState,
  dispatch: () => {},
}

export const FeedWindowContext = createContext<FeedWindowManager>(initialValue)

export const useMainWindowContext = () => {
  return useContext(FeedWindowContext)
}
