import { ScrollView, Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { Preview } from './Preview'
import { NCompose } from './NCompose'
import { feedBackgroundDark } from '@there/shared/desktop/windows-config'
import { WithNoorayUploadManager } from '../v2/WithNoorayUploadManager'
import { Pressable } from '../shared/Pressable'
import { CrossIcon } from '../main/mainIcons'
import { useUiContext } from '@there/components/shared/UiContext'

export const FeedContent = () => {
  let theme = useTheme()
  let { dispatch } = useMainWindowContext()
  let { fetchMoreStatuses } = useStatusesContext()
  let { hasCustomBackground } = useUiContext()

  return (
    <WithNoorayUploadManager>
      <View
        //@ts-ignore
        style={{
          padding: theme.spaces.sidePaddingNarrow,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          zIndex: 2,
        }}
      >
        <Pressable
          style={{
            width: 16,
            height: 16,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            dispatch({ type: 'change mode', mode: 'room', isFeedOpen: false })
          }}
        >
          <CrossIcon
            width={10}
            height={10}
            color={theme.colors.quaternaryText}
          />
        </Pressable>
        <Space horizontal={8} />
        <StatusIcon color={theme.colors.quaternaryText} />
        <Space horizontal={6} />
        <Text
          style={{
            fontSize: theme.fontSizes.normal,
            color: theme.colors.quaternaryText,
          }}
        >
          Feed
        </Text>
        <FillHorizontal />
        <StatusStreak />
      </View>
      <ScrollView
        style={{
          borderRightWidth: 1,
          borderColor: 'rgba(255, 255, 255, 0.04)',
        }}
        onScroll={(event) => {
          let layoutHeight = event.nativeEvent.layoutMeasurement.height
          let contentSizeHeight = event.nativeEvent.contentSize.height
          let endY = contentSizeHeight - layoutHeight
          let y = event.nativeEvent.contentOffset?.y
          // console.log(y, endY, contentSizeHeight, layoutHeight)
          // If got to the top, fetch more once
          if (
            endY - y < 40 &&
            // If it could scroll
            contentSizeHeight > layoutHeight
          ) {
            fetchMoreStatuses()
          }
        }}
        scrollEventThrottle={200}
      >
        <NCompose />
        <Preview />
      </ScrollView>
      {!hasCustomBackground && (
        <div
          style={{
            height: 30,
            width: 4,
            position: 'absolute',
            top: 0,
            right: -2,
            zIndex: 3,
            // Fade the edge of side separator
            background: `linear-gradient(180deg, ${feedBackgroundDark} 0%, rgba(31, 40, 51, 0) 100%)`,
          }}
        />
      )}
    </WithNoorayUploadManager>
  )
}

import Svg, { SvgProps, Path } from 'react-native-svg'
import { Space } from '../shared/Space'
import { useStatusesContext } from './useStatusesContext'
import { useMainWindowContext } from '../shared/use-main-window'
import { StatusStreak } from '@there/components/nooray/StatusStreak'
import { FillHorizontal } from '@there/components/shared/Flex'

export const StatusIcon = (props: SvgProps) => (
  <Svg width={15} height={15} fill="none" viewBox="0 0 15 15" {...props}>
    <Path
      d="M15 7.47c0 4.124-3.151 7.468-7.039 7.468a6.7 6.7 0 0 1-3.52-.999c-.477-.293-4 1.4-4.398 1-.412-.415 2.302-2.926 1.994-3.435A7.768 7.768 0 0 1 .923 7.469C.923 3.344 4.073 0 7.96 0 11.85 0 15 3.344 15 7.47Z"
      fill={props.color ? props.color : '#fff'}
    />
  </Svg>
)
