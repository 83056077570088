import { useImmerReducer } from 'use-immer'
import { Draft } from 'immer'
import { createContext, useContext, useMemo } from 'react'

export type SettingsWindowsType = 'preferences' | 'account' | 'members'

export type ShortcutForType = 'knock' | 'talk' | 'deepWork'
export const shortcutForArray = ['knock', 'talk', 'deepWork']

export type ShortcutTypes = 1 | 2 | 3 | 4

export type AvailableShortcutsType = {
  talk: 1 | 2 | 3 | 4
  knock: 2 | 3
  deepWork: 1 | 4 | 3
}

export const availableShortcuts = {
  talk: [1, 2, 3, 4],
  knock: [2, 3],
  deepWork: [1, 3, 4],
}

export const settingsWindows = [
  {
    id: 'preferences',
    label: 'Preferences',
    active: true,
  },
  {
    id: 'account',
    label: 'Account',
    active: true,
  },
  {
    id: 'members',
    label: 'Members',
    active: false,
  },
]

type PreferencesType = {
  shortcuts: AvailableShortcutsType
  launchOnStartUp: boolean
  typingStatus: boolean
}

type AccountType = Record<string, never>
type MembersType = Record<string, never>

type State = {
  isWindowVisible: boolean
  window: SettingsWindowsType

  preferences: PreferencesType
  account: AccountType
  members: MembersType
}

export type SettingsPageState = State

const initialState: State = {
  isWindowVisible: false,
  window: 'preferences',
  preferences: {
    shortcuts: {
      talk: 1,
      knock: 2,
      deepWork: 3,
    },
    launchOnStartUp: true,
    typingStatus: true,
  },
  account: {},
  members: {},
}

export type SettingsPageDispatch = React.Dispatch<SettingsPageAction>

type SettingsPageAction =
  | { type: 'open window'; window?: SettingsWindowsType }
  | { type: 'close window' }
  | { type: 'change window'; window: SettingsWindowsType }
  | { type: 'change talk shortcut'; shortcut: number }
  | { type: 'change knock shortcut'; shortcut: number }
  | { type: 'change deepWork shortcut'; shortcut: number }

export function reducer(
  draft: Draft<State>,
  action: SettingsPageAction,
): void | State {
  switch (action.type) {
    case 'open window':
      draft.isWindowVisible = true
      if (action.window) {
        draft.window = action.window
      } else {
        draft.window = 'preferences'
      }
      return
    case 'close window':
      draft.isWindowVisible = false
      return
    case 'change window':
      draft.window = action.window
      return
  }
}

export const useSettingsPageState = (): [
  SettingsPageState,
  SettingsPageDispatch,
] => {
  return useImmerReducer<State, SettingsPageAction>(reducer, initialState)
}

export type SettingsWindowManager = {
  settingsWindowState: State
  settingsWindowDispatch: SettingsPageDispatch
}

export function useSettingsWindow(): SettingsWindowManager {
  const [state, dispatch] = useSettingsPageState()

  return useMemo(
    () => ({
      settingsWindowState: state,
      settingsWindowDispatch: dispatch,
    }),
    [dispatch, state],
  )
}

const initialValue: SettingsWindowManager = {
  settingsWindowState: initialState,
  settingsWindowDispatch: () => {},
}

export const SettingsWindowContext = createContext<SettingsWindowManager>(
  initialValue,
)

export const useSettingsWindowContext = () => {
  return useContext(SettingsWindowContext)
}
