import ms from 'ms'
import { UserInfo } from '../urql/fragments/userInfo'

const oneDay = 1000 * 60 * 60 * 24

export const getLastSeen = (
  user: UserInfo,
  inviteAccepted?: boolean | null,
) => {
  let isOnline = Boolean(user.online)

  if (inviteAccepted === false) {
    return '✉️ Invited'
  }

  if (isOnline) {
    return 'Online'
  }

  if (!user.lastOnline) {
    return 'Offline'
  }

  let lastOnline = new Date(user.lastOnline)

  // If not Online👇
  return getRelativeDate(lastOnline)
}

export function getRelativeDate(
  dateInput: Date | number | undefined,
  options?: { date: Date },
) {
  if (!dateInput) return
  let date = typeof dateInput === 'number' ? new Date(dateInput) : dateInput
  let now = options?.date ? options.date : new Date()

  let isSameYear = now.getFullYear() - date.getFullYear() === 0
  let dayDifference = getDayOfYear(now) - getDayOfYear(date)

  let diffInMs = now.getTime() - date.getTime()
  if (diffInMs < ms('10h')) {
    if (diffInMs < ms('1m')) {
      return `just now`
    } else if (diffInMs < ms('1m')) {
      return `${msToSeconds(diffInMs)}s ago`
    } else if (diffInMs < ms('1h')) {
      return `${msToMinutes(diffInMs)}m ago`
    } else {
      return `${msToHours(diffInMs)}h ago`
    }
  }

  let shouldShowByDate = !isSameYear || dayDifference > 1

  let day = date.getDate()
  let month = date.getMonth()
  let year = date.getFullYear().toString().slice(2, 4)
  let hour = date
    .getHours()
    .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
  let minutes = date
    .getMinutes()
    .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

  let formattedDate = `${month}/${day}/${year}`
  let formattedTime = `${hour}:${minutes}`

  if (shouldShowByDate) {
    return `${formattedDate}`
  }

  if (dayDifference === 1) {
    return `Yesterday`
    // return `Yesterday at ${formattedTime}`
  }

  if (dayDifference === 0) {
    return `Today at ${formattedTime}`
  }

  return `${formattedDate}`
}

const getDayOfYear = (date: Date) => {
  let start = new Date(date.getFullYear(), 0, 0)
  let diff = date.valueOf() - start.valueOf()

  return Math.floor(diff / oneDay)
}

function msToMinutes(millis: number) {
  return Math.floor(millis / ms('1m'))
}
function msToSeconds(millis: number) {
  return Math.floor(millis / 1000)
}

function msToHours(millis: number) {
  return Math.floor(millis / ms('1h'))
}

export function getRelativeTime(now: Date, date: Date) {
  let diffInMs = now.getTime() - date.getTime()
  if (diffInMs < ms('24h')) {
    if (diffInMs < ms('1m')) {
      //don't show just now
      return ``
    } else if (diffInMs < ms('1m')) {
      return `${msToSeconds(diffInMs)}s`
    } else if (diffInMs < ms('1h')) {
      return `${msToMinutes(diffInMs)}m`
    } else {
      return `${msToHours(diffInMs)}h`
    }
  }

  return ''
}
