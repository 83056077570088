import { css } from 'styled-components'

export const resetForm = css`
  background: none;
  padding: 0;
  border: none;
  margin: 0;
`

export const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
