import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export const GameViewIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      d="m12.95 9.32-1.9-.64c.77-2.29 2-3.72 3.7-4.15C15.66 4.31 17 2.87 17 2h2c0 1.8-1.98 4.03-3.76 4.47-.95.24-1.72 1.14-2.3 2.85Z"
      fill={props.color ? props.color : '#000'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.06 8.03C7.2 8 7.35 8 7.5 8h9c.15 0 .3 0 .44.03A5.5 5.5 0 1 1 13.25 17h-2.5A5.49 5.49 0 0 1 1 13.5a5.5 5.5 0 0 1 6.06-5.47ZM5.5 10a.5.5 0 0 0-.5.5v1c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2 2a.5.5 0 0 0-.5.5v1c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM19 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-1 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5.5 14a.5.5 0 0 0-.5.5v1c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-2-2a.5.5 0 0 0-.5.5v1c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"
      fill={props.color ? props.color : '#000'}
    />
  </Svg>
)
