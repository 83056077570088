export enum SunErrorCodes {
  UNKNOWN = 'UNKNOWN',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  FLOOD = 'FLOOD',
  INVALID_ACTION = 'INVALID_ACTION',
  INVALID_SPACE = 'INVALID_SPACE',
  SERVER_ERROR = 'SERVER_ERROR',
  INVALID_ARGS = 'INVALID_ARGS',
  ROOM_FULL = 'ROOM_FULL',
  DATE_TOO_PAST = 'DATE_TOO_PAST',
}

export class SunError extends Error {
  public name: string
  public message: string
  public code: SunErrorCodes

  constructor(message: string, code: SunErrorCodes) {
    super(message)

    this.name = 'SunError'
    this.message = message
    this.code = code
  }

  toString() {
    return `${this.code} - ${this.message}`
  }
}
