import { electronApi } from '@there/desktop/utils/electron-api'
import { useCallback, useMemo } from 'react'
import { useEditDialog } from '../main/useEditDialog'
import { useConfirm } from '../modal/use-confirm'
import { useAppContext } from '../shared/AppContext'
import { useSpaceContext } from '../shared/spaceContext'
import { useWindowContext } from '../shared/WindowContext'

export type GameInfo = {
  id: string
  title: string
  logo: string
  time: string
  players: string
  gameLink?: string
  helpLink?: string
  serverType?: 'link' | 'joinCode'
}

export const gamesArray: GameInfo[] = [
  {
    id: 'krunker',
    title: 'Krunker',
    logo: 'https://cdn.usenoor.com/assets/games/krunker.png',
    time: '+10 min',
    players: '2-15',
    gameLink: 'https://krunker.io',
    helpLink:
      'https://www.notion.so/there/Krunker-Help-dda5f904052946f2a73103ff770f19c2',
    serverType: 'link',
  },
  {
    id: 'brawl',
    title: 'Brawl Stars',
    logo: 'https://cdn.usenoor.com/assets/games/brawl.png',
    time: '+4 min',
    players: '1-3',
    gameLink: 'https://supercell.com/en/games/brawlstars/',
    serverType: 'joinCode',
  },
  {
    id: 'shellshock',
    title: 'Shell Shocker',
    logo: 'https://cdn.usenoor.com/assets/games/shellshock.png',
    time: '+10 min',
    players: '2-17',
    gameLink: 'https://shellshock.io',
    serverType: 'link',
  },
  {
    id: 'tankpvp',
    title: 'Tanks PVP',
    logo: 'https://cdn.usenoor.com/assets/games/Tanks.png',
    time: '+10 min',
    players: '1-2',
    gameLink:
      'https://html5.gamedistribution.com/17046ff8409849599b235afe908b4e58/',
    serverType: 'link',
  },
]
const searchGame = (gameId: string) => {
  return gamesArray.find((gameItem) => gameItem.id === gameId)
}

export const useGameRoom = () => {
  let [{ currentDialog: dialog, getDialogAvatars }] = useSpaceContext()
  let { isElectron } = useWindowContext()
  let { activeSpaceId } = useAppContext()
  let [, editDialog] = useEditDialog()

  let [selectedGame, gameServer] = useMemo(() => {
    if (!dialog) return []
    if (!dialog.gameId || !dialog.gameServer) return []
    const game = searchGame(dialog?.gameId)
    if (!game) return []
    return [game, dialog.gameServer]
  }, [dialog])

  const openGame = useCallback(() => {
    if (!gameServer) {
      alert('game server is not defined')
      return
    }

    if (isElectron) {
      electronApi?.openExternalUrl(gameServer)
    } else if (typeof window !== 'undefined') {
      window.open(gameServer, '_blank')
    }

    // TODO: set pattern for game link type
    // if (!gameLink.startsWith('https://krunker.io')) {
    //       alert('game server is not valid')
    //       return
    //     }
    // window.open(
    //   gameLink,
    //   '_blank',
    //   'top=500,left=400,frame=true,nodeIntegration=no',
    // )
  }, [gameServer, isElectron])

  let { openModal } = useConfirm({
    alertText: 'End Game',
    alertDescription: 'Do you want to end game on room?',
    alertIcon: '🎉',
    submitLabel: 'End Game',
    cancelLabel: 'Cancel',
  })

  const endGame = useCallback(() => {
    openModal().then(() => {
      if (!dialog) return
      if (!activeSpaceId) return

      editDialog({
        id: dialog?.id,
        gameId: '',
        gameServer: '',
        __extra: {
          spaceId: activeSpaceId,
        },
      })
    })
  }, [activeSpaceId, dialog, editDialog, openModal])

  return { selectedGameInfo: selectedGame, gameServer, endGame, openGame }
}
