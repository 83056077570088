import Sentry from '@there/app/utils/sentry'
import { useCallback, useState } from 'react'
import { useUploadBackground } from '../urql/uploadBackground'

const pickerOpts = {
  types: [
    {
      description: 'Images',
      accept: {
        'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
      },
    },
  ],
  excludeAcceptAllOption: true,
  multiple: false,
}

export const useBackgroundUploader = (): [
  () => Promise<void>,
  boolean,
  string | undefined,
  ({
    dialogId,
  }: {
    dialogId: string | undefined | null
    bgDarkenPercent: number
  }) => Promise<void>,
  () => void,
  (file: File) => Promise<void>,
] => {
  const [
    { fetching: uploadBackgroundLoading },
    uploadBackground,
  ] = useUploadBackground()
  let [uploading, setUploading] = useState<boolean>(false)
  let [optimisticResult, setOptimisticResult] = useState<string | undefined>(
    undefined,
  )
  let [file, setFile] = useState<File | undefined>(undefined)

  const clearStates = useCallback(() => {
    setOptimisticResult(undefined)
    setFile(undefined)
  }, [])

  const getBackground = useCallback(async () => {
    try {
      if (typeof window === 'undefined') return
      //@ts-ignore
      const fileHandle = await window.showOpenFilePicker(pickerOpts)
      if (fileHandle[0]) {
        setOptimisticResult(undefined)
        const file = await fileHandle[0].getFile()
        setFile(file)
        const objectUrl = URL.createObjectURL(file)
        setOptimisticResult(objectUrl)
      }
    } catch (error) {
      console.warn('Picking image canceled by user.')
    }
  }, [])
  const getFile = useCallback(async (file: File) => {
    if (typeof window === 'undefined') return
    setOptimisticResult(undefined)
    setFile(file)
    const objectUrl = URL.createObjectURL(file)
    setOptimisticResult(objectUrl)
  }, [])

  const revoke = useCallback(() => {
    optimisticResult && URL.revokeObjectURL(optimisticResult)
    setOptimisticResult(undefined)
  }, [optimisticResult])

  const upload = useCallback(
    async ({
      dialogId,
      bgDarkenPercent = 0,
    }: {
      dialogId: string | undefined | null
      bgDarkenPercent: number
    }) => {
      try {
        if (!dialogId) return
        if (typeof window === 'undefined') return
        if (!file) return
        setUploading(true)
        const objectUrl = URL.createObjectURL(file)
        setOptimisticResult(objectUrl)

        const dialog = await uploadBackground({
          file: file,
          dialogId,
          bgDarkenPercent: bgDarkenPercent,
        })
        setUploading(false)
        revoke()
      } catch (error) {
        alert('Failed to upload background, please try again')
        Sentry.captureException(error)
      }
    },
    [file, revoke, uploadBackground],
  )

  return [
    getBackground,
    uploading,
    optimisticResult,
    upload,
    clearStates,
    getFile,
  ]
}

function _arrayBufferToBase64(buffer: Buffer | ArrayBuffer) {
  let binary = ''
  let bytes = new Uint8Array(buffer)
  let len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}
