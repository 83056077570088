import { useCallback, useEffect } from 'react'
import { View, Text } from 'react-native'
import {
  AlertModalData,
  ConfirmModalData,
  ToastModalData,
  useModals,
} from '../feed/ModalsContext'
import { StandardButton } from '../main/StandardButton'
import { Space } from '../shared/Space'
import { ModalDescription } from './ModalDescription'
import { ModalTitle } from './ModalTitle'
import { ModalWrapper } from './ModalWrapper'
import { a, useTransition } from '@react-spring/native'
import { Pressable } from '../shared/Pressable'
import { useTheme } from '../feed/ThemeContext'

export const ToastModal = () => {
  let [modalsState, modalsDispatch] = useModals()
  let theme = useTheme()
  const onSubmit = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'toast' })
    modalData.onSubmit()
  }
  let toastState = modalsState.modals.includes('toast')
  let modalData = modalsState.modalsData['toast'] as ToastModalData

  let dismiss = useCallback(() => {
    modalsDispatch({ type: 'modal closed', modalName: 'toast' })
    if (modalData.onDismiss) {
      modalData.onDismiss()
    }
  }, [modalData, modalsDispatch])

  useEffect(() => {
    if (!toastState) return
    let t = setTimeout(() => {
      dismiss()
    }, 10_000)
    return () => clearTimeout(t)
  }, [dismiss, toastState])

  let transition = useTransition(toastState, {
    from: {
      y: 100,
      opacity: 1,
    },
    enter: {
      y: 0,
      opacity: 1,
    },
    leave: {
      y: 100,
      opacity: 0,
    },
    config: {
      tension: 400,
      friction: 20,
      mass: 0.1,
    },
  })

  if (!modalData) return null
  return (
    // <ModalWrapper
    //   isOpen={modalsState.modals.includes('toast')}
    //   onClose={onSubmit}
    // >
    //   <View
    //     style={{
    //       width: 215,
    //       display: 'flex',
    //       flexDirection: 'column',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //     }}
    //   >
    //     {modalData.icon ? (
    //       <View style={{ marginBottom: 5 }}>{modalData.icon}</View>
    //     ) : null}
    //     <ModalTitle align="center">{modalData.title}</ModalTitle>
    //     {modalData.description ? (
    //       <>
    //         <Space vertical={5} />
    //         <ModalDescription align="center">
    //           {modalData.description}
    //         </ModalDescription>
    //       </>
    //     ) : null}
    //     <Space vertical={10} />
    //     <View
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'row',
    //         justifyContent: 'center',
    //       }}
    //     >
    //       <StandardButton
    //         text={modalData.submitLabel ? modalData.submitLabel : 'Close'}
    //         backgroundColor="buttonBackground"
    //         textColor="secondaryText"
    //         size="normal"
    //         onPress={() => {
    //           onSubmit()
    //         }}
    //       />
    //     </View>
    //   </View>
    // </ModalWrapper>
    <>
      {transition(({ y, opacity }, item) => {
        return item ? (
          <a.View
            style={{
              width: '100%',
              paddingHorizontal: 12,
              position: 'absolute',
              bottom: 12,
              transform: [{ translateY: y }],
              opacity,
            }}
          >
            <Pressable
              style={{
                // height: 48,
                display: 'flex',
                flexGrow: 1,
                borderRadius: 8,
                backgroundColor: '#293544',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingHorizontal: 8,
                paddingVertical: 8,

                shadowOpacity: 0.1,
                shadowColor: '#000',
                shadowRadius: 15,
                shadowOffset: { width: 0, height: 2 },
              }}
              onPress={dismiss}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {!!modalData.title && (
                  <Text
                    style={{
                      fontSize: theme.fontSizes.normal,
                      color: theme.colors.secondaryText,
                    }}
                  >
                    {modalData.title}
                  </Text>
                )}
                {!!modalData.description && (
                  <Text
                    style={{
                      fontSize: theme.fontSizes.small,
                      color: theme.colors.tertiaryText,
                    }}
                  >
                    {modalData.description}
                  </Text>
                )}
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: 10,
                }}
              >
                {!!modalData.onDismiss && (
                  <StandardButton
                    text={modalData.dismissLabel || `Dismiss`}
                    backgroundColor="buttonBackground"
                    textColor="secondaryText"
                    size="normal"
                    onPress={dismiss}
                    style={{ marginRight: 10 }}
                  />
                )}
                {!!modalData.submitLabel && (
                  <StandardButton
                    text={modalData.submitLabel || `Submit`}
                    backgroundColor="primaryButtonBackground"
                    textColor="secondaryText"
                    size="normal"
                    onPress={onSubmit}
                  />
                )}
              </View>
            </Pressable>
          </a.View>
        ) : null
      })}
    </>
  )
}
