// # Atoms

import { activeNewChatPeerAtom } from '@there/components/atoms/chatAtom'
import { contentViewAtom } from '@there/components/atoms/contentViewAtom'
import {
  NativeDropdownItem,
  useNativeDropdown,
} from '@there/components/dropdown/useNativeDropdown'
import { useModals } from '@there/components/feed/ModalsContext'
import { useDeleteChatMessage } from '@there/components/main/useDeleteChatMessage'
import { useEditChatMessageSimple } from '@there/components/main/useEditChatMessageSimple'
import { getMessageMediaFullPath } from '@there/components/message/helpers'
import { useAppContext } from '@there/components/shared/AppContext'
import { useLatest } from '@there/components/shared/use-latest'
import { useNurQuery } from '@there/components/sun/use-query'
import { ChatMessageInfo } from '@there/components/types/chat'
import { writeImageToClipboard } from '@there/components/utils/copyImage'
import { ChatsStateDispatch } from '@there/components/v2/useChatsState'
import { ChatPeerId } from '@there/components/v2/useRenderingPhase'
import { Rectangle } from '@there/desktop/shared/types'
import { electronSupports, ipc } from '@there/desktop/utils/electron-api'
import { filterFalsy } from '@there/shared/utilities/filter-falsy'
import { NewChatMessage, NewChatQueryReply } from '@there/sun/modules/newChat'
import { MessageInfo, NewMessageInfo } from '@there/sun/utils/node-types'
import { copyToClipboard } from 'copy-lite'
import { atom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useCallback, useEffect } from 'react'
import { useMainWindowContext } from '../shared/use-main-window'

// Used for selective broadcasting of most widely used state properties
export const messageActionsAtom = atom<{
  onEditMessage: (input: { messageId: string; text: string }) => void
  onDeleteMessage: (msgId: string) => void
  onArrowUp: () => void
  setReplyMsgId: (msgId: string | null) => void
  setEditingMsgId: (msgId: string | null) => void
  openMessageMenu: (message: ChatMessageInfo) => void
  goToUserChat: (userId: string) => void
  viewPhoto: (message: ChatMessageInfo, position?: Rectangle) => void
}>({
  // Handlers
  onEditMessage: () => {},
  onDeleteMessage: () => {},
  onArrowUp: () => {},
  // Action
  setReplyMsgId: () => {},
  setEditingMsgId: () => {},
  openMessageMenu: () => {},
  goToUserChat: () => {},
  viewPhoto: () => {},
})

export const useMessageActions = () => {
  return useAtomValue(messageActionsAtom)
}

export const useMessageActionsProvider = ({
  peer,
  chatsStateDispatch,
  messages,
}: {
  peer: ChatPeerId | null
  chatsStateDispatch: ChatsStateDispatch
  messages: NewMessageInfo[]
}) => {
  let setActions = useUpdateAtom(messageActionsAtom)

  let { currentUserId } = useAppContext()
  let [, doEditMessage] = useEditChatMessageSimple()
  let [, doDeleteMessage] = useDeleteChatMessage()

  let peerTopicId = peer?.type === 'Topic' ? peer.id : undefined
  let peerUserId = peer?.type === 'User' ? peer.id : undefined

  let setEditingMsgId = (msgId: string | null) => {
    if (!peer) return
    chatsStateDispatch({
      type: 'editing msgId updated',
      peer: peer,
      msgId: msgId,
    })
  }

  let setReplyMsgId = (msgId: string | null) => {
    if (!peer) return
    chatsStateDispatch({
      type: 'replying msgId updated',
      peer: peer,
      msgId: msgId,
    })
  }

  // edit last message on arrow up
  let editLastMessage = () => {
    if (!currentUserId) return
    let messagesCopy = [...messages]
    let lastMsgIdOfSelf = messagesCopy
      .reverse()
      .find((message) => message.senderId === currentUserId)?.id

    // No message
    if (!lastMsgIdOfSelf) return

    setEditingMsgId(lastMsgIdOfSelf)
  }

  let editMessage = ({
    messageId,
    text,
  }: {
    messageId: string
    text: string
  }) => {
    doEditMessage({
      id: messageId,
      text,
      peerTopicId,
      peerUserId,
    })

    setEditingMsgId(null)
  }

  let deleteMessage = (messageId: string) => {
    doDeleteMessage({
      id: messageId,
      peerTopicId,
      peerUserId,
    })
  }

  let setActiveChatPeer = useUpdateAtom(activeNewChatPeerAtom)
  let { dispatch: windowDispatch } = useMainWindowContext()

  const goToUserChat = (userId: string) => {
    setActiveChatPeer({
      peerTopicId: null,
      peerUserId: userId,
    })
    windowDispatch({ type: 'change mode', mode: 'chat' })
  }

  let copyMessageById = (id: string) => {
    let text = messages.find((message) => message.id === id)?.text
    if (!text) return
    copyToClipboard(text)
  }

  let [, dispatch] = useModals()

  let viewPhoto = (message: ChatMessageInfo, position?: Rectangle) => {
    dispatch({
      type: 'modal opened',
      modalData: { message, position, type: 'message photo' },
      modalName: 'viewPhoto',
    })
  }

  // MENUS

  // self messages dropdown
  // let { openMenu: openSelfMessageMenu } = useNativeDropdown({
  //   items: selfItems,
  //   key: 'self-message-chat-2',
  //   onSelectItem: (selectedItemType: string, id: string) => {
  //     switch (selectedItemType) {
  //       case 'edit-chat-message':
  //         setEditingMsgId(id)
  //         break
  //       case 'delete-chat-message':
  //         deleteMessage(id)
  //         break
  //       case 'copy-message-text':
  //         copyMessageById(id)
  //         break
  //       case 'reply-message':
  //         setReplyMsgId(id)
  //         break
  //     }
  //   },
  // })

  let { openMenu } = useNativeDropdown({
    // init later
    items: [],
    key: 'message-chat-2',
    onSelectItem: (selectedItemType: string, id: string) => {
      switch (selectedItemType) {
        case 'edit-chat-message':
          setEditingMsgId(id)
          break
        case 'delete-chat-message':
          deleteMessage(id)
          break
        case 'copy-message-text':
          copyMessageById(id)
          break
        case 'reply-message':
          setReplyMsgId(id)
          break
        case 'save-chat-media': {
          let message = messages.find((m) => m.id === id)
          if (!message) return

          if (electronSupports?.fileDownload) {
            ipc?.invoke('file:download', message, {
              saveAs: true,
              // set to false when added a notification
              openFolderWhenDone: true,
            })
          } else {
            // Does not support progress update
            ipc?.invoke('image:download', getMessageMediaFullPath(message), {
              saveAs: true,
              // set to false when added a notification
              openFolderWhenDone: true,
            })
          }

          break
        }
        case 'copy-chat-media': {
          let message = messages.find((m) => m.id === id)
          if (!message?.mediaObjectId) return
          writeImageToClipboard(getMessageMediaFullPath(message))
          break
        }
      }
    },
  })

  const openMessageMenu = (message: ChatMessageInfo) => {
    let items = getMessageMenuItems(message, message.senderId === currentUserId)
    openMenu(message.id, items)
  }
  // MENUS END

  // Set in atom
  let editLastMessageFunction = useLatest(editLastMessage)
  let editMessageFunction = useLatest(editMessage)
  let deleteMessageFunction = useLatest(deleteMessage)
  let setReplyMsgIdFunction = useLatest(setReplyMsgId)
  let setEditingMsgIdFunction = useLatest(setEditingMsgId)
  let openMessageMenuFunction = useLatest(openMessageMenu)
  let goToUserChatFunction = useLatest(goToUserChat)
  let viewPhotoFunction = useLatest(viewPhoto)

  useEffect(() => {
    setActions({
      onDeleteMessage: (msgId) => deleteMessageFunction.current?.(msgId),
      onEditMessage: (input) => editMessageFunction.current?.(input),
      onArrowUp: () => editLastMessageFunction.current?.(),
      setReplyMsgId: (msgId) => setReplyMsgIdFunction.current?.(msgId),
      setEditingMsgId: (msgId) => setEditingMsgIdFunction.current?.(msgId),
      openMessageMenu: (msgId) => openMessageMenuFunction.current?.(msgId),
      goToUserChat: (...args) => goToUserChatFunction.current?.(...args),
      viewPhoto: (...args) => viewPhotoFunction.current?.(...args),
    })
  }, [
    deleteMessageFunction,
    editLastMessageFunction,
    editMessageFunction,
    openMessageMenuFunction,
    setActions,
    setEditingMsgIdFunction,
    setReplyMsgIdFunction,
    goToUserChatFunction,
    viewPhotoFunction,
  ])
}

// Message context menu items
let selfItems: NativeDropdownItem[] = [
  {
    type: 'reply-message',
    label: 'Reply',
  },
  {
    type: 'copy-message-text',
    label: 'Copy Text',
  },
  {
    type: 'edit-chat-message',
    label: 'Edit',
  },
  {
    type: 'delete-chat-message',
    label: 'Delete',
  },
]

const getMessageMenuItems = (
  message: ChatMessageInfo,
  out: boolean,
): NativeDropdownItem[] => {
  let hasPhoto =
    message.mediaType == 'Photo' || message.document?.type == 'Photo'
      ? true
      : false

  let hasFile = message.document?.type == 'File' ? true : false
  let hasText = !!message.text

  const reply = {
    type: 'reply-message',
    label: 'Reply',
  }
  const edit = {
    type: 'edit-chat-message',
    label: 'Edit',
  }
  const copyText = {
    type: 'copy-message-text',
    label: 'Copy Text',
  }
  const deleteMsg = {
    type: 'delete-chat-message',
    label: 'Delete',
  }
  const saveAs = {
    type: 'save-chat-media',
    label: 'Save as...',
  }
  const copyMedia = {
    type: 'copy-chat-media',
    label: 'Copy Media',
  }
  const separator = {
    type: '__separator',
  }

  let items = [
    reply,
    separator,

    ...(out ? [edit, deleteMsg, separator] : [deleteMsg]),

    hasText && copyText,
    hasPhoto && copyMedia,
    (hasPhoto || hasFile) && saveAs,
  ]

  return items.filter(filterFalsy) as NativeDropdownItem[]
}
