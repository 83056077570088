import gql from 'graphql-tag'
import { useQuery, UseQueryArgs } from 'urql'
import { MinUserInfo } from '@there/components/urql/fragments/userInfo'
import { Omit } from '@there/shared/types'

export const PublicUserQuery = gql`
  query publicUser($email: String!) {
    user: publicUser(email: $email) {
      id
      userId
      name
      profilePhoto
      email
      pendingSetup
    }
  }
`

export type Variables = { email: string }
export type Data = {
  user: MinUserInfo | undefined
}

export const usePublicUserQuery = (
  args: Omit<UseQueryArgs<Variables, Data>, 'query'>,
) => useQuery<Data, Variables>({ query: PublicUserQuery, ...args })
