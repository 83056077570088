import { StatusesQueryReply } from '@there/sun/modules/statuses'
import { StatusInfo } from '@there/sun/utils/node-types'
import { useCallback, useMemo, useRef } from 'react'
import { statusSortFunction } from '../feed/sort-statuses'
import { useMemberships } from '../feed/useMemberships'
import { useAppContext } from '../shared/AppContext'
import { useSpaceContext } from '../shared/spaceContext'
import { useNurQuery } from '../sun/use-query'
import { useUserSpaces } from '../urql/userSpaces'

export interface StatusInfoWithSpaceIds extends StatusInfo {
  spaces: string[]
}

export const useStatuses = () => {
  let { activeSpaceId } = useAppContext()
  let { joinedSpacesData } = useMemberships()
  const [{ data, fetching, error, stale }, refetch, fetchMore] = useNurQuery<
    StatusesQueryReply
  >({
    method: 'statuses',
    variables: {
      spaceIds: [activeSpaceId],
      addOffset: 30,
    },
  })

  const getStatusSpace = useCallback(
    (status: StatusInfoWithSpaceIds) => {
      let spaces = status.spaces
      return spaces.map((spaceId) => joinedSpacesData[spaceId])
    },
    [joinedSpacesData],
  )

  let userSpaceStatuses = data?.userSpaceStatuses
  let [statuses, statusToReplies] = useMemo(() => {
    let statusToReplies: Record<string, string[]> = {}
    let allStatuses: Record<string, StatusInfoWithSpaceIds> = {}
    if (userSpaceStatuses) {
      for (let [spaceId, spaceStatuses] of Object.entries(userSpaceStatuses)) {
        for (let status of spaceStatuses) {
          let spaces: string[] = []
          if (allStatuses[status.id]) {
            spaces = allStatuses[status.id].spaces
          }
          allStatuses[status.id] = { ...status, spaces: [...spaces, spaceId] }

          if (status.replyToStatusId) {
            let replyIds = new Set(
              statusToReplies[status.replyToStatusId] || [],
            )
            replyIds.add(status.id)
            statusToReplies[status.replyToStatusId] = Array.from(replyIds)
          }
        }
      }
    }
    return [
      Object.values(allStatuses).sort(statusSortFunction),
      statusToReplies,
    ]
  }, [userSpaceStatuses])

  let lastFetchMore = useRef(0)
  const fetchMoreStatuses = useCallback(() => {
    if (fetching) return
    if (error) return
    if (statuses.length === 0) return

    if (lastFetchMore.current + 1000 > performance.now()) return
    fetchMore({
      offsetId: statuses[statuses.length - 1].id,
    })
    lastFetchMore.current = performance.now()
  }, [error, fetchMore, fetching, statuses])

  return {
    statuses,
    statusToReplies,
    getStatusSpace,
    fetchMoreStatuses,
    refetch,
  }
}
