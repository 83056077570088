import React from 'react'
import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'

export const WindowHeader = styled.h3`
  margin: 0px;
  padding: 0px;

  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: ${(p) => p.theme.fontSizes['21']}px;
  color: ${(p) => p.theme.colors.secondaryText};

  -webkit-app-region: drag;
`

export const Section = styled.div`
  width: 100%;
`

export const SectionColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const SectionColumn = styled.div``

export const SectionHeader = styled.h4`
  margin: 0px;
  padding: 0px;

  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: 17px;
  color: ${(p) => p.theme.colors.text};
`

export const Separator = styled.div`
  width: 100%;
  height: 0px;
  margin: 4px 0px;
  border-top: 1px solid ${(p) => p.theme.colors.separatorLine};
`

export const Row = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ScrollRow = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

export const FooterButton = styled.div<{ active: boolean }>`
  padding: 4px 8px;
  border-radius: 6px;
  -webkit-app-region: no-drag;

  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes['15']}px;
  color: ${(p) => p.theme.colors.tertiaryText};

  transition: ${(p) => p.theme.transition.background};

  :hover {
    background: ${(p) => p.theme.colors.hoverItemBackground};
  }

  :active {
    background: ${(p) => p.theme.colors.activeItemBackground};
  }

  ${(p) =>
    p.active &&
    css`
      background: #42a4ff;
      color: #fff;

      :hover {
        background: ${lighten(0.05, '#42a4ff')};
      }
      :active {
        background: ${darken(0.03, '#42a4ff')};
      }
    `}
`

export const Label = styled.span`
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes['13']}px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.text};
`

const dropdownButtonHeight = 24

export const DropdownButtonWrapper = styled.div<{ width: number }>`
  width: ${(p) => p.width}px;
  height: ${dropdownButtonHeight}px;
  border-radius: 6px;
  background: ${(p) => p.theme.colors.hoverItemBackground};

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DropdownLabel = styled.span`
  padding-left: 10px;
  text-align: center;
  max-width: 100%;

  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes['13']}px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.tertiaryText};

  flex: 1;
`

export const DropdownIcon = styled.div`
  width: 8px;
  height: ${dropdownButtonHeight}px;
  margin: 0px 6px 0px 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;
`

export const TitleText = styled.div`
  display: flex;
  align-items: center;

  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes[13]}px;

  color: ${(p) => p.theme.colors.tertiaryText};

  svg {
    margin-right: 5px;
  }
`

export const DropdownButton = (props: { label: string; width: number }) => {
  return (
    <DropdownButtonWrapper width={props.width}>
      <DropdownLabel>{props.label}</DropdownLabel>
      <DropdownIcon>
        <svg width={8} height={4} fill="none" viewBox="0 0 8 4">
          <path d="M4 4L0 0h8L4 4z" fill="#B3B3B3" />
        </svg>
      </DropdownIcon>
    </DropdownButtonWrapper>
  )
}

export const Check = (props: { color?: string }) => {
  return (
    <svg width={15} height={11} fill="none" viewBox="0 0 15 11">
      <path
        d="M1 5.5L5.5 10l9-9"
        stroke={props.color ? props.color : '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
