import React from 'react'
import { useTheme } from '@there/components/feed/ThemeContext'
import { useAppContext } from '@there/components/shared/AppContext'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { WebkitAppRegion } from 'components/shared/WebStyled'
import { useSpring, a } from '@react-spring/native'
import { controlsWidth } from '@there/components/rtc/CameraView'
import { PinMicrophoneButton } from '@there/components/rtc/PinMicrophoneButton'
import { PinCameraButton } from '@there/components/rtc/PinCameraButton'
import { ScreenSharePinButton } from '@there/components/CallControl/ScreenSharePinButton'
import { Space } from '@there/components/shared/Space'
import { PinLeaveButton } from '@there/components/rtc/PinLeaveButton'

type Props = {
  show: boolean
}

export const VerticalControls = ({ show }: Props) => {
  let { walkieState } = useRtcContext()
  let animatedStyleProps = useSpring({
    opacity: show ? 1 : 0,
    config: {
      tension: 400,
    },
  })

  return (
    <>
      <a.View
        style={[
          animatedStyleProps,
          {
            height: '100vh',
            width: controlsWidth,

            alignItems: 'flex-end',
          },
        ]}
      >
        <WebkitAppRegion
          mode={'no-drag'}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <>
            <PinMicrophoneButton />
            <Space vertical={4} />
            <PinCameraButton />
            <Space vertical={4} />
            <ScreenSharePinButton active={walkieState.weSharingScreen} />
            <Space vertical={4} />
            <PinLeaveButton />
          </>
        </WebkitAppRegion>
      </a.View>
    </>
  )
}
