import { useNurMutation } from '@there/components/sun/use-mutation'
import { SendMessageActionMessage } from '@there/sun/modules/sendMessageAction'
interface Data {
  done: boolean
}

type RemoteVariables = SendMessageActionMessage['payload']

export const useSendMessageAction = () => {
  return useNurMutation<Data, RemoteVariables>({
    method: 'sendMessageAction',
  })
}
