import React, { useEffect, useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { useHover } from '@there/components/shared/use-hover'
import { ThemedView } from '@there/components/shared/ThemedView'
import { AddPeopleIcon, PlusIcon } from '@there/components/main/mainIcons'
import { useModals } from '@there/components/feed/ModalsContext'
import { logEvent } from '@there/components/shared/use-analytics'
import { useAppContext } from '@there/components/shared/AppContext'
import { profileWidth, styles } from '@there/components/main/MemberRow'
import { Pressable } from '@there/components/shared/Pressable'
import { lighten } from 'polished'
import { useSpaceContext } from '@there/components/shared/spaceContext'
import { a, useSpring } from '@react-spring/native'
import { useMemberships } from '@there/components/feed/useMemberships'
import ms from 'ms'

export const InviteRow = () => {
  const theme = useTheme()
  const [hovered, hoverListeners] = useHover()
  let [, modalsDispatch] = useModals()
  let {
    activeSpaceId,
    activeSpaceName,
    invitedOnce,
    dispatch: appDispatch,
  } = useAppContext()
  let [{ space }] = useSpaceContext()
  let { currentMember } = useMemberships()
  let [recommending, setRecommending] = useState(false)

  function clicked() {
    setRecommending(false)
    modalsDispatch({ type: 'modal opened', modalName: 'invite' })

    logEvent('User Opened Invite Modal', {
      space: {
        id: activeSpaceId,
        name: activeSpaceName,
      },
    }).catch(() => {})

    if (!invitedOnce) {
      appDispatch({ type: 'invited once' })
    }
  }

  let membersLength = space?.members.length
  let currentMemberCreatedAt = currentMember?.createdAt
  useEffect(() => {
    // new space
    if (membersLength === 1) {
      setRecommending(true)
      return
    }

    // recently joined
    if (currentMemberCreatedAt > Date.now() - ms('5h')) {
      setRecommending(true)
      return
    }

    // Never invited
    if (!invitedOnce) {
      setRecommending(true)
      return
    }

    setRecommending(false)
  }, [membersLength, currentMemberCreatedAt, invitedOnce])

  let subStyles = useSpring({
    height: recommending ? 13 : 0,
    opacity: recommending ? 1 : 0,
    config: {
      tension: 200,
      friction: 20,
      mass: 0.1,
    },
  })

  return (
    <Pressable
      onPress={clicked}
      style={({ pressed }) => [
        styles.rowWrapper,
        {
          // paddingLeft: theme.spaces.sidePaddingNarrow,
          // paddingRight: theme.spaces.sidePaddingNarrow,
          borderRadius: theme.borderRadius.larger,
        },
        pressed && { opacity: 0.9, transform: [{ scale: 0.95 }] },
      ]}
      {...hoverListeners}
    >
      <View style={[styles.avatarWrapper]}>
        <View
          style={[
            {
              height: profileWidth,
              width: profileWidth,
              borderRadius: 14,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: recommending
                ? hovered
                  ? lighten(0.05, theme.colors.blue)
                  : theme.colors.blue
                : hovered
                ? `rgba(255, 255, 255, 0.1)`
                : `rgba(255, 255, 255, 0.06)`,
              borderWidth: 1,
              borderColor: theme.colors.separatorLine,
            },
          ]}
        >
          {/* <AddPeopleIcon
            color={
              recommending ? theme.colors.contrast : theme.colors.tertiaryLight
            }
          /> */}
          <PlusIcon
            width={12}
            color={
              recommending ? theme.colors.contrast : theme.colors.tertiaryLight
            }
          />
        </View>
      </View>

      <ThemedView
        style={[
          {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            height: '100%',
            // side padding
            paddingRight: theme.spaces.sidePaddingNarrow,
          },
        ]}
      >
        <Text
          style={[
            styles.name,
            {
              fontSize: theme.fontSizes.normal,
              fontWeight: recommending ? 'bold' : 'normal',
              lineHeight: 16,
              color:
                theme.colors[recommending ? 'secondaryText' : 'tertiaryText'],
              marginTop: 0,
            },
          ]}
        >
          Add teammates
        </Text>

        <a.Text
          numberOfLines={1}
          style={[
            styles.statusText,
            {
              fontSize: theme.fontSizes.small,
              color: theme.colors['quaternaryText'],
            },
            subStyles,
          ]}
        >
          Share the join link!
        </a.Text>
      </ThemedView>
    </Pressable>
  )
}

export const InviteButton = () => {
  const theme = useTheme()
  const [hovered, hoverListeners] = useHover()
  let [, modalsDispatch] = useModals()
  let {
    activeSpaceId,
    activeSpaceName,
    invitedOnce,
    dispatch: appDispatch,
  } = useAppContext()
  let [recommending, setRecommending] = useState(false)

  function clicked() {
    modalsDispatch({ type: 'modal opened', modalName: 'invite' })

    logEvent('User Opened Invite Modal', {
      space: {
        id: activeSpaceId,
        name: activeSpaceName,
      },
    }).catch(() => {})

    if (!invitedOnce) {
      appDispatch({ type: 'invited once' })
    }
  }

  let subStyles = useSpring({
    height: recommending ? 13 : 0,
    opacity: recommending ? 1 : 0,
    config: {
      tension: 200,
      friction: 20,
      mass: 0.1,
    },
  })

  return (
    <Pressable
      onPress={clicked}
      style={({ pressed }) => [
        styles.rowWrapper,
        {
          // paddingLeft: theme.spaces.sidePaddingNarrow,
          // paddingRight: theme.spaces.sidePaddingNarrow,
          borderRadius: theme.borderRadius.larger,
        },
        pressed && { opacity: 0.9, transform: [{ scale: 0.95 }] },
      ]}
      {...hoverListeners}
    >
      <View
        style={[
          {
            height: 32,
            width: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}
      >
        <AddPeopleIcon
          color={
            recommending
              ? hovered
                ? lighten(0.05, theme.colors.blue)
                : theme.colors.blue
              : hovered
              ? theme.colors.tertiaryLight
              : theme.colors.quaternaryText
          }
          width={20}
          height={20}
        />
      </View>
    </Pressable>
  )
}
