import { Tooltip } from '../shared/Tooltip'
import { GoodButton } from '@there/components/shared/GoodButton'
import { DialogInfo } from '@there/sun/utils/node-types'
import { EmojiView } from '@there/components/main/EmojiView'
import { useMainWindowContext } from '../shared/use-main-window'
import { GameViewIcon } from './GameViewIcon'
import { useTheme } from '../feed/ThemeContext'

export const OpenGameView = ({
  currentDialog,
}: {
  currentDialog: DialogInfo | null
}) => {
  let { dispatch, isGameRoom: isGameView } = useMainWindowContext()
  let theme = useTheme()

  if (isGameView) return null
  return (
    <Tooltip label="Open game view" placement="bottomCenter">
      <GoodButton
        variant="transparent"
        size="small"
        onPress={() => {
          dispatch({ type: 'change gameRoom mode', isGameRoom: true })
        }}
        icon={<GameViewIcon width={18} color={theme.colors.palePink} />}
      />
    </Tooltip>
  )
}
