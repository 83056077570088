import { ProfilePhoto } from '@there/components/feed/ProfilePhoto'
import { useTheme } from '@there/components/feed/ThemeContext'
import { FeedList } from '@there/components/nooray/FeedList'
import { useReadStatus } from '@there/components/feedBox/useReadStatus'
import { useAppContext } from '@there/components/shared/AppContext'
import { Pressable } from '@there/components/shared/Pressable'
import { useNurNode } from '@there/components/sun/use-node'
import { useNurQuery } from '@there/components/sun/use-query'
import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Api } from '@there/sun/utils/node-types'
import { atom, useAtom } from 'jotai'
import { StatusInfoWithSpaceIds, useStatuses } from './useStatuses'
import { useNativeTheme } from '../shared/use-native-theme'
import { createTrayNewStatusBadge } from '../utils/create-tray-icon'
import { ipc } from '@there/desktop/utils/electron-api'
import { feedOpenAtom } from '../atoms/feedAtoms'
import { useStatusesContext } from './useStatusesContext'

export type StatusesType = Api['Status'][]

export const unreadStatusAtom = atom<StatusInfoWithSpaceIds[]>([])

export const Preview = () => {
  let theme = useTheme()
  let {
    getStatusSpace,
    statuses,
    maxReadAt,
    markRead,
    statusToReplies,
  } = useStatusesContext()
  let [isFeedOpen] = useAtom(feedOpenAtom)

  useEffect(() => {
    if (!isFeedOpen) {
      markRead()
    }
    return () => {
      markRead()
    }
  }, [isFeedOpen, markRead])

  // new status blue badge
  // const { shouldUseDarkColors } = useNativeTheme()
  // useEffect(() => {
  //   let hasUnread = statuses[0] && statuses[0].createdAt > maxReadAt
  //   async function switchTrayIcon() {
  //     try {
  //       const result = await createTrayNewStatusBadge(
  //         hasUnread ? 2 : 0,
  //         shouldUseDarkColors,
  //       )
  //       if (!result?.dataUrl) {
  //         console.warn('failed to create users canvas')
  //         return
  //       }

  //       return ipc?.invoke('update-tray-status', {
  //         ...result,
  //       })
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }
  //   switchTrayIcon()
  // }, [maxReadAt, shouldUseDarkColors, statuses])

  return (
    <>
      {statuses.length > 0 ? (
        <FeedList
          statuses={statuses}
          maxReadAt={maxReadAt}
          getStatusSpace={getStatusSpace}
          statusToReplies={statusToReplies}
        />
      ) : (
        <View
          style={{
            width: '100%',
            height: '100%',
            paddingVertical: 150,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              fontSize: theme.fontSizes.normal,
              paddingVertical: 2,
              paddingHorizontal: 8,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              color: theme.colors.tertiaryText,
              borderRadius: 100,
            }}
          >
            No statuses here yet
          </Text>
        </View>
      )}
    </>
  )
}
