import { useCallback, useEffect, useState } from 'react'

const storageKey = 'statusesLastSeen'

export const useReadStatus = () => {
  const [maxReadAt, setMaxReadAt] = useState<number>(0)

  useEffect(() => {
    if (maxReadAt !== 0) return
    let value = localStorage.getItem(storageKey)
    if (!value) return

    setMaxReadAt(Number(value))
  }, [maxReadAt])

  return {
    markRead: useCallback(() => {
      let time = Date.now()
      setMaxReadAt(time)
      localStorage.setItem(storageKey, String(time))
    }, []),
    maxReadAt,
  }
}
