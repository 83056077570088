import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export function UploadIcon(props: SvgProps) {
  return (
    <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        d="M13 12a1 1 0 10-2 0v10a1 1 0 102 0V12z"
        fill={props.color ? props.color : '#000'}
      />
      <Path
        d="M7 16a1 1 0 110 2H6a4 4 0 01-4-4V6a4 4 0 014-4h12a4 4 0 014 4v8.007a4 4 0 01-4 4h-1a1 1 0 010-2h1a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v8a2 2 0 002 2h1z"
        fill={props.color ? props.color : '#000'}
      />
      <Path
        d="M9.707 13.707a1 1 0 01-1.414-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L12 11.414l-2.293 2.293z"
        fill={props.color ? props.color : '#000'}
      />
    </Svg>
  )
}
