import * as React from 'react'
import Svg, { SvgProps } from 'react-native-svg'
import { useSpring } from '@react-spring/native'
import { a } from '@react-spring/web'

export function CheckIcon({
  animate,
  ...props
}: SvgProps & { color?: string; animate: boolean }) {
  let length = 30
  const spring = useSpring(() => ({
    config: { duration: 320 },
    from: { value: length },
    to: { value: 0 },
  }))

  React.useEffect(() => {
    if (animate) {
      spring[1].update({ value: length })
    } else {
      spring[1].set({ value: 0 })
    }
  }, [spring, animate, length])

  return (
    <Svg width={12} height={12} fill="none" viewBox="0 0 12 12" {...props}>
      <a.path
        d="M0.5 6.5L3 9L9 3"
        stroke={props.color ? props.color : '#000'}
        strokeDashoffset={spring[0].value}
        strokeDasharray={length}
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{
          willChange: 'stroke-dashoffset',
          transform: 'translate3d(0, 0, 0)',
        }}
      />
    </Svg>
  )
}

export function DoubleCheckIcon({
  animate,
  ...props
}: SvgProps & { color?: string; animate: boolean }) {
  let length = 30
  const spring = useSpring(() => ({
    config: { duration: 320 },
    from: { value: length },
    to: { value: 0 },
  }))

  React.useEffect(() => {
    if (animate) {
      spring[1].update({ value: length })
    } else {
      spring[1].set({ value: 0 })
    }
  }, [spring, animate, length])

  return (
    <Svg width={13} height={8} fill="none" {...props}>
      <a.path
        d="M1 4.5 3.5 7l6-6"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={props.color ? props.color : '#642bf3'}
        strokeDashoffset={0}
        strokeDasharray={length}
        style={{
          willChange: 'stroke-dashoffset',
          transform: 'translate3d(0, 0, 0)',
        }}
      />
      <a.path
        d="M6 6.5L6.5 7L12.5 1"
        stroke={props.color ? props.color : '#642bf3'}
        strokeDashoffset={spring[0].value}
        strokeDasharray={length}
        style={{
          willChange: 'stroke-dashoffset',
          transform: 'translate3d(0, 0, 0)',
        }}
      />
    </Svg>
  )
}
