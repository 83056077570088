import { ToggleRow } from '@there/components/settings/ToggleRow'
import { ThemedText } from '@there/components/shared/ThemedText'
import { useMultipleSelection, useSelect } from 'downshift'
import React, { memo, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import { TouchableOpacity } from 'react-native'
import { CircleButton } from '../main/CircleButton'
import { useCurrentMediaDevicesContext } from '../shared/CurrentMediaDevicesContext'
import { Space } from '../shared/Space'
import { Tooltip } from '../shared/Tooltip'
import { logEvent } from '../shared/use-analytics'
import { useForceSpeaker, useMediaDevices } from '../shared/use-media-devices'
import { Dropdown } from './Dropdown'
import { ArrowDownIcon } from './HeaderIcons'
import { useTheme } from './ThemeContext'
import { VolumeManager } from './VolumeManager'

type Props = {
  disabled?: boolean
  toggleComponent?: ({ active }: { active: boolean }) => React.ReactNode
  includeCameras?: boolean
}
export const microphonePickerWidth = 210
export const volumeSliderMargins = Dropdown.sidePadding * 2

let latestAltClickedDevice: string | undefined = undefined

export const MicrophonePicker = memo((props: Props) => {
  const t = useTheme()
  const {
    preferredMic,
    dispatch,
    currentMic,
    systemAudioDevice,
    forceSpeaker,
  } = useCurrentMediaDevicesContext()

  const {
    microphones,
    speakers,
    cameras,
    selectedMicrophoneId,
    selectedSpeakerId,
    selectedCameraId,
    updateMediaList,
  } = useMediaDevices()

  const items = props.includeCameras
    ? [...microphones, ...speakers, ...cameras]
    : [...microphones, ...speakers]

  const {
    getDropdownProps,
    removeSelectedItem,
    selectedItems,
    setSelectedItems,
  } = useMultipleSelection({})

  const {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    openMenu,
    toggleMenu,
    closeMenu,
  } = useSelect({
    items,
    // selectedItem: preferredAsItem,
    // itemToString: device => device && device.shortName,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.MenuBlur:
          return {
            ...changes,
            isOpen: true, // don't keep the menu open after selection.
          }
      }
      return changes
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (selectedItems.includes(selectedItem)) {
              break
            } else {
              if (microphones.includes(selectedItem)) {
                for (const item of microphones) {
                  if (item !== selectedItem) {
                    removeSelectedItem(item)
                  } else if (
                    // check if the selected item is not the the alt clicked for system audio
                    latestAltClickedDevice !== selectedItem.id
                  ) {
                    // addSelectedItem(selectedItem)
                    dispatch({
                      type: 'preferred mic changed',
                      micId: selectedItem.id,
                      name: selectedItem.name,
                    })
                    logEvent('User Changed Mic', {
                      micName: selectedItem.name,
                    })
                  }
                }
              } else if (speakers.includes(selectedItem)) {
                for (const item of speakers) {
                  if (item !== selectedItem) {
                    removeSelectedItem(item)
                  } else {
                    // addSelectedItem(item)
                    dispatch({
                      type: 'preferred speaker changed',
                      speakerId: selectedItem.id,
                      name: selectedItem.name,
                    })
                    logEvent('User Changed Speaker', {
                      speakerName: selectedItem.name,
                    })
                  }
                }
              } else if (cameras.includes(selectedItem)) {
                for (const item of cameras) {
                  if (item !== selectedItem) {
                    removeSelectedItem(item)
                  } else {
                    dispatch({
                      type: 'preferred camera changed',
                      deviceId: selectedItem.id,
                      name: selectedItem.name,
                    })
                    try {
                      logEvent('User Changed Camera', {
                        name: selectedItem.name,
                        deviceId: selectedItem.id,
                        allDevices: JSON.stringify(
                          cameras?.map((c) => ({
                            name: c.name,
                            id: c.id,
                            d: c.isDefault,
                          })),
                        ),
                      })
                    } catch (error) {}
                  }
                }
              }
              // Close
            }
          }
          break
        default:
          break
      }
    },
  })

  function buttonClicked(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    updateMediaList()
  }

  // When use connects a microphone, they expect it to show up
  // Update list every 1.3s as long as dropdown is open!

  //resync selected items

  let selectedSpeakerFromList = speakers.find(
    ({ id }) => id === selectedSpeakerId,
  )

  useEffect(() => {
    let selected = []
    let selectedMicFromList = microphones.find(
      ({ id }) => id === selectedMicrophoneId,
    )

    let selectedSpeakerFromList = speakers.find(
      ({ id }) => id === selectedSpeakerId,
    )

    let selectedCameraFromList = cameras.find(
      ({ id }) => id === selectedCameraId,
    )

    if (selectedMicFromList) {
      selected.push(selectedMicFromList)
    }

    if (selectedSpeakerFromList) {
      selected.push(selectedSpeakerFromList)
    }

    if (selectedCameraFromList) {
      selected.push(selectedCameraFromList)
    }

    setSelectedItems(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    microphones,
    selectedMicrophoneId,
    selectedSpeakerId,
    selectedCameraId,
    speakers,
  ])

  let { notPlayingBecauseForced, preferredSpeaker } = useForceSpeaker()

  return (
    <Dropdown.Root>
      <Dropdown.Toggle onPress={() => toggleMenu()}>
        <Tooltip
          label={
            <>
              <span className="light">Manage Mic & Devices</span>
              <br />
              {currentMic?.name}
            </>
          }
          placement="topLeft"
        >
          {props.toggleComponent ? (
            <TouchableOpacity
              disabled={true}
              {...getToggleButtonProps(
                getDropdownProps(
                  {
                    refKey: 'noref',
                    onClick: buttonClicked,
                    preventKeyAction: isOpen,
                  },
                  { suppressRefError: true },
                ),
                { suppressRefError: true },
              )}
            >
              {props.toggleComponent({ active: isOpen })}
            </TouchableOpacity>
          ) : (
            <CircleButton
              options={() =>
                getToggleButtonProps(
                  {
                    refKey: 'noref',
                    ...getDropdownProps(
                      {
                        refKey: 'noref',
                        onClick: buttonClicked,
                        preventKeyAction: isOpen,
                      },
                      { suppressRefError: true },
                    ),
                  },
                  { suppressRefError: true },
                )
              }
              isActive={isOpen}
              size={16}
            >
              <ArrowDownIcon color={t.colors.quaternaryLight} />
            </CircleButton>
          )}
        </Tooltip>
      </Dropdown.Toggle>

      <Dropdown.Menu
        {...getMenuProps({}, { suppressRefError: true })}
        isOpen={isOpen}
        onClose={closeMenu}
        width={microphonePickerWidth}
      >
        <Space vertical={10} key="space1" />
        <Dropdown.Inner>
          <VolumeManager key="slider" />
        </Dropdown.Inner>

        {/* If AirPods selected as speaker, show option to force it */}
        {((selectedSpeakerFromList?.name === 'AirPods' &&
          !selectedSpeakerFromList.isDefault) ||
          forceSpeaker) && (
          <Dropdown.Inner style={{ marginTop: 8 }}>
            <ToggleRow
              title="Force AirPods"
              description="Won't play in speakers"
              value={!!forceSpeaker}
              onValueChange={(value) => {
                dispatch({ type: 'forceSpeaker changed', state: value })
              }}
            />
          </Dropdown.Inner>
        )}

        {notPlayingBecauseForced && (
          <Dropdown.Inner style={{}}>
            <ThemedText fontSize="small" color="tertiaryText">
              ⚠️ Not playing until {'"'}
              {preferredSpeaker?.name}
              {'"'} is connected again.
            </ThemedText>
          </Dropdown.Inner>
        )}

        <Space vertical={10} key="space2" />
        <Dropdown.Head key="microphone">microphone</Dropdown.Head>
        <Space vertical={3} />
        <Dropdown.Section>
          {items.map((device, index) => {
            let selected = selectedItems.includes(device)
            let systemAudioSelected =
              systemAudioDevice &&
              systemAudioDevice?.id === device.id &&
              device.type === 'mic'

            return (
              <React.Fragment key={device.type + device.id + device.name}>
                {index === microphones.length && (
                  <React.Fragment key="speaker">
                    <Space vertical={8} />
                    <Dropdown.Head>speaker</Dropdown.Head>
                    <Space vertical={3} />
                  </React.Fragment>
                )}
                {index === microphones.length + speakers.length && (
                  <React.Fragment key="camera">
                    <Space vertical={8} />
                    <Dropdown.Head>camera</Dropdown.Head>
                    <Space vertical={3} />
                  </React.Fragment>
                )}
                <Dropdown.Item
                  key={device.id}
                  selected={selected}
                  highlighted={highlightedIndex === index}
                  {...getItemProps({
                    item: device,
                    index,
                    title: device.name,

                    onClick: (event) => {
                      // Use as secondary mic
                      if (event.altKey && microphones.includes(device)) {
                        event.preventDefault()
                        event.stopPropagation()

                        latestAltClickedDevice = device.id

                        // Toggle
                        if (systemAudioSelected) {
                          dispatch({
                            type: 'system audio device changed',
                            deviceId: undefined,
                          })
                          // Make it available for selecting as a normal mic
                          setTimeout(() => {
                            latestAltClickedDevice = undefined
                            removeSelectedItem(device)
                          }, 100)
                        } else {
                          dispatch({
                            type: 'system audio device changed',
                            deviceId: device.id,
                            name: device.name,
                          })
                        }
                      }
                    },
                  })}
                >
                  <span>{device.shortName}</span>
                  {selected ? <Check /> : null}
                  {systemAudioSelected ? <Check color="orange" /> : null}
                </Dropdown.Item>
              </React.Fragment>
            )
          })}
        </Dropdown.Section>
        <Space vertical={Dropdown.sideItemPadding} />
      </Dropdown.Menu>
    </Dropdown.Root>
  )
}, isEqual)

export function Check(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="17"
      height="12"
      fill="none"
      viewBox="0 0 17 12"
      style={{ flexShrink: 0 }}
    >
      <path
        d="M1 6.5l5 5L16.5 1"
        stroke={props.color ? props.color : '#3D73FF'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
