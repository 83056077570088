import React, { useState } from 'react'
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import { StandardInput } from '../main/StandardInput'
import { StandardButton } from '../main/StandardButton'
import { ReplyIcon } from '../nooray/ReplyIcon'
import { useTheme } from '@there/components/feed/ThemeContext'
import { electronApi, isElectron } from '@there/desktop/utils/electron-api'
import { LeftArrowIcon } from '@there/components/main/mainIcons'
import { GameInfo } from '@there/components/gameRoom/useGame'

type Props = {
  game: GameInfo
  setGame: ({
    gameServer,
    game,
  }: {
    game: GameInfo
    gameServer: string
  }) => void
  backToGameList: () => void
  isMinimalView: boolean
}
export const GameSetBox = ({
  game,
  setGame,
  backToGameList,
  isMinimalView,
}: Props) => {
  let theme = useTheme()

  const [gameServer, setGameServer] = useState<string>('')

  const openLink = (link: string) => {
    if (isElectron) {
      electronApi?.openExternalUrl(link)
    } else if (typeof window !== 'undefined') {
      window.open(gameServer, '_blank')
    }
  }

  return (
    <View
      style={[
        {
          minHeight: 130,
          margin: 10,
          borderWidth: 1,
          borderColor: '#787878',
          borderRadius: 10,
          backgroundColor: 'rgba(36, 45, 57, 0.4)',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          padding: 10,
          marginTop: 10,
          marginLeft: 20,
          marginRight: 20,
        },
        isMinimalView && {
          flexDirection: 'column',
          alignItems: 'center',
        },
      ]}
    >
      <Pressable
        style={{
          position: 'absolute',
          left: 5,
          top: 5,
          zIndex: 100,
          opacity: 0.7,

          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        onPress={backToGameList}
      >
        <LeftArrowIcon height={10} />
        <Text
          style={[
            {
              color: '#fff',
              marginLeft: 4,
              fontSize: theme.fontSizes.small,
              fontWeight: 'bold',
            },
          ]}
        >
          back to game list
        </Text>
      </Pressable>
      <View
        style={[
          { width: 110, justifyContent: 'center', alignItems: 'center' },
          isMinimalView && {
            marginTop: 10,
          },
        ]}
      >
        <Pressable
          onPress={() => {
            if (game?.gameLink) openLink(game.gameLink)
          }}
        >
          {game.logo && (
            <Image
              style={{ width: 120, height: 55 }}
              source={{ uri: game.logo }}
              resizeMode={'contain'}
            />
          )}
        </Pressable>
        {game.title && <Text style={{ color: '#fff' }}>{game.title}</Text>}
      </View>
      <View
        style={[
          {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            flexGrow: 1,
            paddingLeft: 10,
          },
          isMinimalView && {
            marginTop: 10,
          },
        ]}
      >
        <StandardInput
          placeholder={game.serverType == 'link' ? 'server url' : 'join code'}
          value={gameServer}
          setValue={setGameServer}
          background="transparentInputBackground"
          bordered={true}
          align="center"
          onSubmit={() => {}}
        />
        <View
          style={[
            {
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
            },
            isMinimalView && {
              flexDirection: 'column-reverse',
            },
          ]}
        >
          {game.helpLink && (
            <StandardButton
              text={'how to create server'}
              backgroundColor="modalSecondaryButtonBackground"
              textColor="secondaryText"
              size="normal"
              onPress={() => {
                if (!game.helpLink) return
                openLink(game.helpLink)
              }}
              style={[
                isMinimalView && {
                  marginTop: 10,
                },
              ]}
            />
          )}
          {!game.helpLink && game.gameLink && (
            <StandardButton
              text={'game website'}
              backgroundColor="modalSecondaryButtonBackground"
              textColor="secondaryText"
              size="normal"
              onPress={() => {
                if (!game.gameLink) return
                openLink(game.gameLink)
              }}
              style={[
                isMinimalView && {
                  marginTop: 10,
                },
              ]}
            />
          )}
          <StandardButton
            style={[
              {
                marginLeft: 10,
              },
              isMinimalView && {
                marginLeft: 0,
              },
            ]}
            text={'Set this game'}
            backgroundColor="primaryButtonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={() => {
              setGame({
                gameServer,
                game,
              })
              backToGameList()
            }}
          />
        </View>
      </View>
    </View>
  )
}
