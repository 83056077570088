import { useRouter } from 'next/router'

export const useIsAuthenticatedPage = () => {
  let { pathname } = useRouter()

  const isAuthenticated =
    pathname.startsWith('/feed') || pathname.startsWith('/join-space')

  return isAuthenticated
}
