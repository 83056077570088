// export const feedSize = { width: 519, height: 350 }
export const feedSize = { width: 670, height: 450 }
// export const feedBackgroundDark = '#1E2128'
// export const feedBackgroundDark = '#1F2833'
export const feedBackgroundDark = '#24303F'
export const feedBackgroundLight = '#F2F2F0'
export const pinHeight = 58
// export const cameraDefaultHeight = 140
export const cameraDefaultHeight = 170
// Add room for leave icon
// export const topBarDarkBg = `#161F27`
export const topBarDarkBg = `#1B2430`
