import {
  addEmojiCacheListener,
  removeEmojiCacheListener,
} from '@there/components/emoji-picker/use-emoji-cache'
import useForceUpdate from '@there/components/hooks/useForceUpdate'
import { useCallback, useEffect, useMemo } from 'react'

export type Emoji = {
  id: string
  names: string[]
  native: string
  image: string
  skin?: number
}

export type EmojiCategory = {
  id: string
  name: string
  emojis: string[]
}

export type EmojiRawData = ((string | string[])[] | (string | string[])[][])[]
export type EmojiModule = { default: EmojiRawData }

export type EmojiData = {
  categories: Array<EmojiCategory>
  emojis: Record<string, Emoji>
}

type EmojiWithSkins = Record<number, Emoji>

export type AllEmojis = Record<string, Emoji | EmojiWithSkins>

export type ConfigType = {
  emojiPerLine: number
  emojiSize: number
  emojiMargin: number
  containerWidth: number
  containerHeight: number
  sheetColumns: number
  sheetRows: number
}

export const usePicker = () => {
  // picker config
  let sheetColumns = 58
  let sheetRows = 58

  let emojiPerLine = 7
  let emojiSize = 38
  let emojiMargin = 2
  let containerWidth =
    emojiPerLine * emojiSize + (emojiPerLine - 1) * emojiMargin * 2
  let containerHeight = containerWidth * 1.3

  let forceUpdate = useForceUpdate()

  useEffect(() => {
    addEmojiCacheListener(forceUpdate)

    return () => {
      removeEmojiCacheListener(forceUpdate)
    }
  }, [forceUpdate])

  let emojiRawData: EmojiRawData | undefined

  if (typeof window !== 'undefined') {
    emojiRawData = window.__EMOJI_CACHE
  }

  let value = useMemo(() => {
    return {
      config: {
        emojiPerLine,
        emojiSize,
        emojiMargin,
        containerWidth,
        containerHeight,
        sheetColumns,
        sheetRows,
      },
      emojiRawData,
    }
  }, [
    containerHeight,
    containerWidth,
    emojiMargin,
    emojiPerLine,
    emojiRawData,
    emojiSize,
    sheetColumns,
    sheetRows,
  ])

  return value
}
