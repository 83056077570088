import gql from 'graphql-tag'
import { useMutation } from 'urql'
import { spaceMembersInfoFragment } from './fragments/spaceMembersInfo'
import { SpaceInfo, spaceInfoFragment } from './fragments/spaceInfo'

export const UpdateSpaceMutation = gql`
  mutation updateSpace($spaceId: String!, $name: String) {
    space: updateSpace(spaceId: $spaceId, name: $name) {
      ...spaceInfo
    }
  }
  ${spaceInfoFragment}
`

export type Variables = { spaceId: string; name: string }
export type Data = {
  space?: SpaceInfo
}

export const useUpdateSpaceMutation = () =>
  useMutation<Data, Variables>(UpdateSpaceMutation)
