import React from 'react'
import { StyleSheet, View, Image, Text, Pressable } from 'react-native'
import { useHover } from '../shared/use-hover'
import { MultiPlayerIcon, TimeSvgIcon } from './Icons'
import { useTheme } from '@there/components/feed/ThemeContext'

// import DefaultImage from '@there/app/public/game-room/krunker.png'

type Props = {
  logo?: string
  title?: string
  time?: string
  players?: string
  onClick?: () => void
}
export const GameItem = (props: Props) => {
  const { logo, title, time, players, onClick } = props
  let [hovered, listeners] = useHover()
  let theme = useTheme()
  return (
    <Pressable
      style={[
        {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',

          backgroundColor: '#1F2833',

          minWidth: 130,
          height: 110,
          borderRadius: theme.borderRadius.larger,
          marginTop: 10,
          marginBottom: 0,
          marginHorizontal: 5,
          flexGrow: 1,
        },
      ]}
      onPress={onClick}
      {...listeners}
    >
      <View style={styles.content}>
        {logo && (
          <Image
            style={[
              styles.iconStyle,
              {
                width: 82,
                height: 44,
              },
            ]}
            source={{ uri: logo }}
            resizeMode={'contain'}
          />
        )}

        {title && <Text style={styles.gameTitle}>{title}</Text>}
      </View>
      <View
        style={[
          styles.side,
          hovered && {
            backgroundColor: 'rgba(255, 255, 255, 0.075)',
          },
        ]}
      >
        {players && (
          <View style={styles.descItem}>
            <MultiPlayerIcon />
            <Text style={styles.desc}>{players}</Text>
          </View>
        )}

        {time && (
          <View style={styles.descItem}>
            <TimeSvgIcon />
            <Text style={styles.desc}>{time}</Text>
          </View>
        )}
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  content: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  gameTitle: {
    color: '#fff',
  },
  side: {
    // justifyContent: 'space-between',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    paddingBottom: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.17)',
    opacity: 0.5,
    width: 38,
  },
  iconStyle: {
    width: 78,
    height: 28,
  },
  descItem: {
    // justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 10,
  },
  desc: {
    fontSize: 9,
    color: '#fff',
    width: '100%',
    textAlign: 'center',
    marginTop: 2,
  },
})
