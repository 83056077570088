import { useLatest } from '@there/components/shared/use-latest'
import { useVolumeContext } from '@there/components/shared/VolumeContext'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useModals } from '../feed/ModalsContext'
import { useToast } from '../modal/use-toast'
import {
  MediaDeviceType,
  useCurrentMediaDevicesContext,
} from './CurrentMediaDevicesContext'
import { useDevices } from './use-input-list'

export const useMediaDevices = () => {
  const [{ cameras, microphones, speakers }, updateDevices] = useDevices()
  const {
    preferredMic,
    preferredSpeaker,
    preferredCamera,
    currentMic,
    currentSpeaker,
    currentCamera,
    isInternalRecommended,
    dispatch,
  } = useCurrentMediaDevicesContext()

  let [, modalsDispatch] = useModals()
  let { openModal: openToastModal } = useToast({
    title: 'AirPods detected',
    description:
      'We recommend you to change your mic to internal for better quality.',
    submitLabel: 'Switch Internal',
    dismissLabel: 'Dismiss',
  })

  let presentationalMicList = useMemo(() => microphones.map(formatMicrophone), [
    microphones,
  ])

  let presentationalSpeakerList = useMemo(() => speakers.map(formatSpeaker), [
    speakers,
  ])

  let presentationalCameraList = useMemo(() => cameras.map(formatCamera), [
    cameras,
  ])

  useEffect(() => {
    if (typeof navigator === 'undefined') return
    const handler = () => {
      updateDevices()
    }
    navigator.mediaDevices.addEventListener('devicechange', handler)
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', handler)
    }
  }, [updateDevices])

  let selectedMicrophoneId = currentMic
    ? currentMic.id
    : microphones && microphones[0] && microphones[0].deviceId

  let selectedSpeakerId = currentSpeaker
    ? currentSpeaker.id
    : speakers && speakers[0] && speakers[0].deviceId

  let selectedCameraId = currentCamera ? currentCamera.id : null

  const setCurrentMicToDefault = useCallback(() => {
    if (presentationalMicList && presentationalMicList.length > 0) {
      let defaultMic =
        presentationalMicList.find((mic) => mic.isDefault) ||
        presentationalMicList[0]

      dispatch({
        type: 'update current mic',
        micId: defaultMic.id,
        name: defaultMic.name,
      })
    }
  }, [dispatch, presentationalMicList])

  const setCurrentSpeakerToDefault = useCallback(() => {
    if (presentationalSpeakerList && presentationalSpeakerList.length > 0) {
      let defaultSpeaker =
        presentationalSpeakerList.find((speaker) => speaker.isDefault) ||
        presentationalSpeakerList[0]

      dispatch({
        type: 'update current speaker',
        speakerId: defaultSpeaker.id,
        name: defaultSpeaker.name,
      })
    }
  }, [dispatch, presentationalSpeakerList])

  const setCurrentCameraToDefault = useCallback(() => {
    if (presentationalCameraList && presentationalCameraList.length > 0) {
      let defaultCamera =
        presentationalCameraList.find((camera) => camera.isDefault) ||
        presentationalCameraList[0]

      dispatch({
        type: 'current camera changed',
        deviceId: defaultCamera.id,
        name: defaultCamera.name,
      })
    }
  }, [dispatch, presentationalCameraList])

  //check if preferred media devices disconnected, set current to default
  let isPreferredMicDisconnected =
    preferredMic &&
    presentationalMicList &&
    presentationalMicList.length > 0 &&
    !presentationalMicList.some(
      (mic) => mic.id === preferredMic.id || mic.name === preferredMic.name,
    )

  let isPreferredSpeakerDisconnected =
    preferredSpeaker &&
    presentationalSpeakerList &&
    presentationalSpeakerList.length > 0 &&
    !presentationalSpeakerList.some(
      (speaker) =>
        speaker.id === preferredSpeaker.id ||
        speaker.name === preferredSpeaker.name,
    )

  let isPreferredCameraDisconnected =
    preferredCamera &&
    presentationalCameraList &&
    presentationalCameraList.length > 0 &&
    !presentationalCameraList.some(
      (camera) =>
        camera.id === preferredCamera.id ||
        // It was commented, but it'll make bugs with Opal camera
        camera.name === preferredCamera.name,
    )

  let isDefaultMicSelected = preferredMic?.id === 'default'

  // we should detect other brand AirPods too
  let isAirPods = currentMic?.name.includes('AirPods')
  let isAirPodsPreferred = useLatest(preferredMic?.name.includes('AirPods'))

  let isAirPodsConnected = useMemo(() => {
    return presentationalMicList.find((mic) => mic.name.includes('AirPods'))
  }, [presentationalMicList])

  // Get internal Mic
  let internalMic = useMemo(() => {
    return presentationalMicList.find((mic) => mic.isInternal)
  }, [presentationalMicList])

  // show modal to switch mic to internal (just one time)
  let currentMicRef = useRef<MediaDeviceType | undefined>(undefined)
  useEffect(() => {
    if (!currentMicRef.current) {
      currentMicRef.current = currentMic
      return
    }
    if (currentMic?.name === currentMicRef.current.name) return
    currentMicRef.current = currentMic
    // Check there is no preferred option available
    if (!isPreferredMicDisconnected && preferredMic?.id !== 'default') return

    // Check internal mic is detected
    if (!internalMic) return
    let internalMicrophone = internalMic

    // Check its on 'auto'
    if (!isDefaultMicSelected) return

    // Check it is on AirPods
    if (!(isAirPods && isAirPodsPreferred.current) || !isAirPodsConnected)
      return
    // Do not show modal when we show it before
    if (isInternalRecommended) {
      openToastModal()
        .then(() => {
          dispatch({
            type: 'update current mic',
            micId: internalMicrophone.id,
            name: internalMicrophone.name,
          })
        })
        .catch(() => {})
    } else if (typeof isInternalRecommended === 'undefined') {
      // show modal to recommend switch to internal mic
      modalsDispatch({
        type: 'modal opened',
        modalName: 'recommendedMic',
        modalData: {
          onSubmit: () => {
            dispatch({
              type: 'update current mic',
              micId: internalMicrophone.id,
              name: internalMicrophone.name,
            })
            dispatch({
              type: 'internal recommended state changed',
              state: true,
            })
          },
          onCancel: () => {
            dispatch({
              type: 'internal recommended state changed',
              state: false,
            })
          },
        },
      })
    }
  }, [
    currentMic,
    dispatch,
    internalMic,
    isAirPods,
    isAirPodsConnected,
    isAirPodsPreferred,
    isDefaultMicSelected,
    isInternalRecommended,
    isPreferredMicDisconnected,
    modalsDispatch,
    openToastModal,
    preferredMic,
  ])

  //switch to preferred when disconnected device connects
  useEffect(() => {
    if (isPreferredMicDisconnected) {
      setCurrentMicToDefault()
    }
  }, [isPreferredMicDisconnected, setCurrentMicToDefault])

  useEffect(() => {
    if (isPreferredSpeakerDisconnected) {
      setCurrentSpeakerToDefault()
    }
  }, [isPreferredSpeakerDisconnected, setCurrentSpeakerToDefault])

  useEffect(() => {
    if (isPreferredCameraDisconnected) {
      setCurrentCameraToDefault()
    }
  }, [isPreferredCameraDisconnected, setCurrentCameraToDefault])

  // Connect back to preferred as soon as available
  useEffect(() => {
    if (!preferredMic || !currentMic) return
    if (
      currentMic.id === preferredMic.id &&
      currentMic.name === preferredMic.name
    )
      return
    if (isPreferredMicDisconnected) return

    //???????
    //???????
    //???????
    //???????
    //???????
    // if preferred is default and user selected to use recommended mic -> do not switch to preferred
    // if (preferredMic.id === 'default' && isInternalRecommended) return

    dispatch({
      type: 'update current mic',
      micId: preferredMic.id,
      name: preferredMic.name,
    })
  }, [
    currentMic,
    dispatch,
    isInternalRecommended,
    isPreferredMicDisconnected,
    preferredMic,
  ])

  useEffect(() => {
    if (!preferredSpeaker || !currentSpeaker) return
    if (
      currentSpeaker.id === preferredSpeaker.id ||
      currentSpeaker.name === preferredSpeaker.name
    )
      return
    if (isPreferredSpeakerDisconnected) return

    dispatch({
      type: 'update current speaker',
      speakerId: preferredSpeaker.id,
      name: preferredSpeaker.name,
    })
  }, [
    currentSpeaker,
    dispatch,
    isPreferredSpeakerDisconnected,
    preferredSpeaker,
  ])

  // Set current camera id
  useEffect(() => {
    if (!preferredCamera) return
    if (isPreferredCameraDisconnected) return

    // Opal fix
    // so if id changed but name is in there
    let newDeviceWithNameOrId = presentationalCameraList.find(
      (camera) =>
        camera.id === preferredCamera.id ||
        camera.name === preferredCamera.name,
    )

    if (!newDeviceWithNameOrId) return
    dispatch({
      type: 'current camera changed',
      deviceId: newDeviceWithNameOrId.id,
      name: newDeviceWithNameOrId.name,
    })
  }, [
    // currentCamera,
    dispatch,
    isPreferredCameraDisconnected,
    preferredCamera,
    presentationalCameraList,
  ])

  return {
    microphones: presentationalMicList,
    speakers: presentationalSpeakerList,
    cameras: presentationalCameraList,
    selectedMicrophoneId,
    selectedSpeakerId,
    selectedCameraId,
    updateMediaList: updateDevices,

    currentMic,
    currentSpeaker,
    currentCamera,
  }
}

export function formatMicrophone(device: MediaDeviceInfo) {
  // output
  let fullName = device.label || ''
  let shortName = ''
  let description = ''
  let isDefault = false
  let isInternal = false
  let icon = null
  let type = 'mic'

  if (fullName.startsWith('Default') || device.deviceId === 'default') {
    isDefault = true
  }

  let hasBuiltIn = fullName.includes('Built-in')

  if (fullName.includes('Internal') && hasBuiltIn) {
    shortName = 'Internal'
    icon = 'laptop'
    isInternal = true
  } else if (fullName.includes('MacBook') && hasBuiltIn) {
    shortName = 'Internal'
    icon = 'laptop'
    isInternal = true
  } else if (fullName.includes('External') && hasBuiltIn) {
    shortName = 'External Mic'
    icon = 'laptop'
  } else if (fullName.includes('Internal')) {
    shortName = 'Internal Mic'
    icon = 'laptop'
    isInternal = true
  } else if (fullName.includes('AirPods')) {
    shortName = 'AirPods'
    icon = 'airpods'
  } else {
    shortName = device.label
    icon = 'laptop'
  }

  if (isDefault) {
    // Doing it here, so filtering in downshift works
    description = `Now ${shortName}'s`
    shortName = `Auto (${shortName})`
  }

  return {
    id: device.deviceId,
    isDefault,
    shortName,
    description,
    icon,
    name: fullName,
    isInternal,
    type,
  }
}

export function formatSpeaker(device: MediaDeviceInfo) {
  let fullName = device.label || ''
  let shortName = ''
  let description = ''
  let isDefault = false
  let isInternal = false
  let icon = null
  let type = 'speaker'

  if (fullName.startsWith('Default') || device.deviceId === 'default') {
    isDefault = true
  }

  let hasBuiltIn = fullName.includes('Built-in')

  if (fullName.includes('Internal') && hasBuiltIn) {
    shortName = 'Built-in Speaker'
    icon = 'laptop'
    isInternal = true
  } else if (fullName.includes('MacBook') && hasBuiltIn) {
    shortName = 'Built-in Speaker'
    icon = 'laptop'
    isInternal = true
  } else if (fullName.includes('DisplayPort')) {
    shortName = "Display's Speaker"
    icon = 'laptop'
  } else if (fullName.includes('Internal')) {
    shortName = 'Internal Speaker'
    icon = 'laptop'
    isInternal = true
  } else if (fullName.includes('AirPods')) {
    shortName = 'AirPods'
    icon = 'airpods'
  } else if (fullName.includes('Bluetooth')) {
    shortName = 'Bluetooth Speaker'
    icon = 'laptop'
  } else {
    shortName = device.label
    icon = 'laptop'
  }

  if (isDefault) {
    // Doing it here, so filtering in downshift works
    description = `Now ${shortName}'s`
    shortName = `Auto (${shortName})`
  }

  return {
    id: device.deviceId,
    isDefault,
    shortName,
    description,
    icon,
    name: fullName,
    type,
    isInternal,
  }
}

export function formatCamera(device: MediaDeviceInfo) {
  let fullName = device.label || ''
  let shortName = ''
  let description = ''
  let isDefault = false
  let isInternal = false
  let icon = ''
  let type = 'camera'

  if (fullName.startsWith('Default')) {
    isDefault = true
  }

  shortName = device.label
  icon = 'camera'

  if (isDefault) {
    // Doing it here, so filtering in downshift works
    description = `Now ${shortName}'s`
    shortName = `Auto`
  }

  return {
    id: device.deviceId,
    isDefault,
    shortName,
    description,
    icon,
    name: fullName,
    type,
    isInternal,
  }
}

/**
 * Showing if we're setting volume to 0 when device is not connected
 */
export function useForceSpeaker() {
  const {
    currentSpeaker,
    preferredSpeaker,
    forceSpeaker,
  } = useCurrentMediaDevicesContext()

  const isSpeakerNotPreferred = currentSpeaker?.id !== preferredSpeaker?.id

  return {
    forceSpeaker,
    notPlayingBecauseForced: forceSpeaker && isSpeakerNotPreferred,
    preferredSpeaker,
  }
}

/**
 * To show muted indicator when volume / device is muted
 */
export function useIsCurrentlyMuted() {
  let { notPlayingBecauseForced } = useForceSpeaker()
  let { muted, volume } = useVolumeContext()

  let noSound = notPlayingBecauseForced || muted || volume === 0

  return noSound
}
