import { MemberWithUserInfo, StatusInfo } from '@there/sun/utils/node-types'

const A_FIRST = -1
const B_FIRST = 1

export const statusSortFunction = (a: StatusInfo, b: StatusInfo) => {
  let statusA = a
  let statusB = b

  return a.updatedAt > b.updatedAt ? -1 : 1
}
