import { useModals } from '@there/components/feed/ModalsContext'
import { copyToClipboard } from 'copy-lite'
import { useEffect, useState } from 'react'
import { getJoinLink } from '../feed/SpaceManager'
import { StandardButton } from '../main/StandardButton'
import { useSpaceContext } from '../shared/spaceContext'
import { logEvent } from '../shared/use-analytics'

export const AddPeopleCopyButton = () => {
  const [{ space }] = useSpaceContext()
  let [{ modals }, modalsDispatch] = useModals()

  const inviteLink = space?.joinSecretCode
    ? getJoinLink({ joinSecretCode: space.joinSecretCode })
    : 'Invite Link unavailable'

  const [isCopied, setCopied] = useState(false)
  const copyAction = ({
    value,
    type,
  }: {
    value: string
    type: 'guest' | 'invite'
  }) => {
    copyToClipboard(value)
    setCopied(true)

    logEvent('User Copied Invite Link', {
      from: 'header - invite modal',
      spaceId: space?.id,
    })
  }

  useEffect(() => {
    if (!isCopied) return
    let t = setTimeout(() => {
      setCopied(false)
      // auto-close
      modalsDispatch({ type: 'modal closed', modalName: 'invite' })
    }, 600)
    return () => clearTimeout(t)
  }, [isCopied, modalsDispatch])

  return (
    <StandardButton
      text={isCopied ? 'Copied' : 'Copy invite link'}
      backgroundColor="primaryButtonBackground"
      textColor="secondaryText"
      stretch={true}
      onPress={() => copyAction({ value: inviteLink, type: 'invite' })}
      textStyle={{
        width: '100%',
        textAlign: 'center',
      }}
    />
  )
}
