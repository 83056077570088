import { useCallback, useState } from 'react'

export type DownloadData = {
  documentId: string
  type: 'started' | 'completed' | 'percent'
  progress: number
  downloadedBytes: number
}
export type DownloadManagerMap = Map<string, DownloadData>

export const useDownloadManager = () => {
  const [inProgressDownloads, setInprogressDownloads] = useState(
    new Map<string, DownloadData>([]),
  )
  // Add download inprogress
  const addDownload = (documentId: string, value: DownloadData) => {
    setInprogressDownloads((prev) => new Map([...prev, [documentId, value]]))
  }

  // Update Percent of inprogress
  const updateDownload = (documentId: string, value: DownloadData) => {
    // setInprogressDownloads((prev) =>{
    //   const nextRows = new Map(prevState.rows),
    // })
  }
  // Remove download inprogress
  const removeDownload = (documentId: string) => {
    setInprogressDownloads((prev) => {
      const newState = new Map(prev)
      newState.delete(documentId)
      return newState
    })
  }

  return {
    inProgressDownloads,
    addDownload,
    updateDownload,
    removeDownload,
  }
}
