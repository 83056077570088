import { usePicker } from '@there/components/emoji-picker/use-picker'
import { View, Text, PixelRatio } from 'react-native'
import { EMOJI_FILE_LINK } from '../emoji-picker/EmojiButton'
import { useWindowContext } from '../shared/WindowContext'

/**
 * emojiUnicode
 * Get the unicode code of an emoji in base 16.
 *
 * @name emojiUnicode
 * @function
 * @param {String} input The emoji character.
 * @returns {String} The base 16 unicode code.
 */

function emojiUnicode(input: string): string {
  return emojiUnicode
    .raw(input)
    .split(' ')
    .map(function (val: string) {
      return parseInt(val).toString(16)
    })
    .join('-')
    .toUpperCase()
}

/**
 * emojiunicode.raw
 * Get the unicode code points of an emoji in base 16.
 *
 * @name emojiunicode.raw
 * @function
 * @param {String} input The emoji character.
 * @returns {String} The unicode code points.
 */
emojiUnicode.raw = function (input: string): string {
  if (input.length === 1) {
    return input.charCodeAt(0).toString()
  } else if (input.length > 1) {
    let pairs = []
    for (let i = 0; i < input.length; i++) {
      if (
        // high surrogate
        input.charCodeAt(i) >= 0xd8_00 &&
        input.charCodeAt(i) <= 0xdb_ff
      ) {
        if (
          input.charCodeAt(i + 1) >= 0xdc_00 &&
          input.charCodeAt(i + 1) <= 0xdf_ff
        ) {
          // low surrogate
          pairs.push(
            (input.charCodeAt(i) - 0xd8_00) * 0x4_00 +
              (input.charCodeAt(i + 1) - 0xdc_00) +
              0x1_00_00,
          )
        }
      } else if (
        input.charCodeAt(i) < 0xd8_00 ||
        input.charCodeAt(i) > 0xdf_ff
      ) {
        // modifiers and joiners
        pairs.push(input.charCodeAt(i))
      }
    }
    return pairs.join(' ')
  }

  return ''
}

/**
 * Renders emoji in a normalized box used for chat icons
 * @param props
 */
export const EmojiView = (props: {
  children: string
  size?: number
  marginRight?: number
}) => {
  let { config } = usePicker()
  let { isMacOs } = useWindowContext()
  let size = props.size ?? 16
  let unicode = emojiUnicode(props.children)
  let sizeToEmojiRatio = size / config.emojiSize
  return (
    <View
      style={{
        width: size,
        height: size,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: props.marginRight,
        // backgroundColor: 'red',
      }}
    >
      {
        <img
          style={{
            width: config.emojiSize * sizeToEmojiRatio - 3,
            display: 'inline-block',
          }}
          src={`${EMOJI_FILE_LINK}/img-apple-64/${unicode.toLowerCase()}.png`}
          alt=""
          // loading="lazy"
        />
      }
    </View>
  )
}
