import { WebStyled } from '@there/components/shared/WebStyled'
import { Reorder } from 'framer-motion'
import { useWindowContext } from '@there/components/shared/WindowContext'
import { StyleSheet, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { electronSupports } from '@there/desktop/utils/electron-api'
import React, { Fragment, useEffect, useState } from 'react'
import { HomeFillIcon } from '../feed/HomeIcon'
import { useAppContext } from '../shared/AppContext'
import { Space } from '@there/components/shared/Space'
import { NewCallBar } from '../feed/NewCallBar'
import { useAtom } from 'jotai'
import {
  activeTabAtom,
  sideViewAtom,
  SpaceTabType,
  useSpaceTabs,
} from '../atoms/contentViewAtom'
import { activeSpaceIdTabAtom } from '../atoms/spaceAtom'
import { activeNewChatPeerAtom } from '../atoms/chatAtom'
import { NotConnected } from '../feed/NotConnected'
import { useRtcContext } from '../shared/use-rtc'
import { topBarDarkBg } from '@there/shared/desktop/windows-config'
import { NativeWindowClose } from '@there/components/shared/NativeWindowClose'
import { useUpdateAtom } from 'jotai/utils'
import { useSafeTopBarStyles } from '@there/components/feed/useSafeTopBarStyles'
import { NavTab } from '@there/components/topbar/NavTab'
import { SpaceAvatar } from '@there/components/sidebar/ActiveSpace'
import { saveSpaceDate } from '../v2/HomeTab'
import { useRouter } from 'next/router'
import { useMemberships } from '../feed/useMemberships'
import { fromGlobalId } from '@there/tower/utils/global-id'
import { useMainWindowContext } from '../shared/use-main-window'
import { useUnread } from '@there/components/shared/UnreadContext'

const topBarHeight = 40

export const TopBar = (props: { skeleton?: boolean }) => {
  let { dispatch: windowDispatch, mode } = useMainWindowContext()

  let [sideView, setSideView] = useAtom(sideViewAtom)
  let [activeSpaceIdTab, setActiveSpaceIdTab] = useAtom(activeSpaceIdTabAtom)

  let setActiveChatPeer = useUpdateAtom(activeNewChatPeerAtom)
  let { isElectron } = useWindowContext()
  let showCustomMacClose = isElectron && !electronSupports?.nativeTitleBar

  let theme = useTheme()

  let { isInCall } = useRtcContext()
  let safeTopBarStyles = useSafeTopBarStyles()
  let { activeSpaceId, dispatch } = useAppContext()

  // temporary
  useEffect(() => {
    if (mode === 'home') {
      setActiveSpaceIdTab(undefined)
    } else {
      setActiveSpaceIdTab(activeSpaceId)
    }
  }, [activeSpaceId, mode, setActiveSpaceIdTab])

  let isHome = sideView === 'home'

  // New tabs state
  let [tabs, tabsDispatch] = useSpaceTabs()
  let [activeTab] = useAtom(activeTabAtom)
  let [hydrated, setTabsHydrated] = useState(false)

  useEffect(() => {
    // When there's no space open, keep the last one as active space to avoid issues
    if (!activeTab || activeTab.type === SpaceTabType.Home) {
      windowDispatch({ type: 'change mode', mode: 'home' })
      setSideView('home')
      return
    }

    // .. Switch active space .. //
    windowDispatch({ type: 'change mode', mode: 'room' })
    setSideView('space')
    dispatch({
      type: 'active space changed',
      spaceId: activeTab.id,
      spaceName: activeTab.name,
      memberId: activeTab.memberId,
    })
  }, [activeTab, dispatch, setSideView, windowDispatch])

  // Navigate to space when join
  let router = useRouter()
  let { memberships } = useMemberships()

  useEffect(() => {
    if (!router.query || !router.query.navToSpace) return
    let membership = memberships?.find(
      (membership) =>
        fromGlobalId(membership.space.id).id ===
        fromGlobalId(String(router.query.navToSpace)).id,
    )
    if (!membership) return

    tabsDispatch({
      type: 'space opened',
      id: membership.space.id,
      memberId: membership.id,
      name: membership.space.name || '',
    })
    saveSpaceDate(membership.space.id)
    router.replace('/feed')
  }, [memberships, router, tabsDispatch])

  let { spaceIdToUnread } = useUnread()

  return (
    <WebStyled style={divStyles as any}>
      <View style={[styles.wrapper, safeTopBarStyles]}>
        {props.skeleton ? null : (
          <>
            <Reorder.Group
              as="div"
              axis="x"
              values={tabs.openTabs}
              onReorder={(newTabs) => {
                if (newTabs[0].type != SpaceTabType.Home) {
                  return
                }

                // find new active tab index
                let activeTab = tabs.openTabs[tabs.activeTabIndex || 0]
                let indexInNewTabs = newTabs.indexOf(activeTab)

                tabsDispatch({
                  type: 'tabs reordered',
                  newTabs,
                  activeTabIndex: indexInNewTabs,
                })
              }}
              style={{
                height: '100%',
                flexShrink: 1,
                flexGrow: 1,
                width: '100%',
                minWidth: 0,
                overflow: 'hidden',
                display: 'flex',
              }}
            >
              <View style={styles.tabs}>
                {tabs.openTabs.map((tab, index) => {
                  let isHome = tab.type == SpaceTabType.Home
                  let key =
                    tab.type + (tab.type === SpaceTabType.Space ? tab.id : '')
                  let unreadNum =
                    tab.type === SpaceTabType.Space
                      ? spaceIdToUnread[tab.id]
                      : 0
                  let inner = (
                    <NavTab
                      icon={
                        tab.type === SpaceTabType.Home ? (
                          <HomeFillIcon
                            style={{ marginTop: -2 }}
                            color={
                              isHome
                                ? theme.colors.tertiaryText
                                : theme.colors.quaternaryText
                            }
                          />
                        ) : (
                          <SpaceAvatar
                            space={{ id: tab.id, name: tab.name }}
                            size={18}
                            unreadNum={unreadNum}
                          />
                        )
                      }
                      text={
                        tab.type === SpaceTabType.Space ? tab.name : undefined
                      }
                      tabType={
                        tab.type === SpaceTabType.Home ? 'home' : 'normal'
                      }
                      isActive={index === tabs.activeTabIndex}
                      onClose={() => {
                        tabsDispatch({ type: 'tab closed', index })
                      }}
                      onPress={() => {
                        tabsDispatch({ type: 'tab switched', index })
                        if (tab.type === SpaceTabType.Home) {
                          setSideView('home')
                          windowDispatch({
                            type: 'change mode',
                            mode: 'home',
                          })
                          setActiveSpaceIdTab(undefined)
                          setActiveChatPeer({})
                        } else {
                          setSideView('space')
                          windowDispatch({
                            type: 'change mode',
                            mode: 'room',
                          })
                          setActiveSpaceIdTab(activeSpaceId)
                          setActiveChatPeer({})
                        }
                      }}
                    />
                  )
                  return (
                    <Reorder.Item
                      key={key}
                      value={tab}
                      dragListener={isHome ? false : true}
                      style={{
                        flexShrink: isHome ? 0 : 1,
                        overflow: 'hidden',
                      }}
                    >
                      {inner}
                    </Reorder.Item>
                  )
                })}
              </View>
            </Reorder.Group>

            <View style={styles.callBar}>
              <NotConnected />
              <Space horizontal={isInCall ? 4 : 7} />
              <NewCallBar />
            </View>
          </>
        )}
      </View>

      {!props.skeleton && showCustomMacClose ? (
        <View style={styles.closeWrapper}>
          <NativeWindowClose />
        </View>
      ) : null}
    </WebStyled>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    height: topBarHeight,
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // flexWrap: 'nowrap',
    flexShrink: 0,
    overflow: 'hidden',
  },
  tabs: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    height: '100%',
    position: 'relative',
    flexShrink: 1,
    overflow: 'hidden',
    paddingLeft: 16,
    width: '100%',
    // flexGrow: 1,
    // width: '100%',
  },
  callBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    flexGrow: 0,
    flexShrink: 0,
  },
  closeWrapper: {
    position: 'absolute',
    top: 14,
    left: 14,
    zIndex: 4,
  },
})

const divStyles = {
  WebkitAppRegion: 'drag',
  height: topBarHeight,
  flexShrink: 0,
  backgroundColor: topBarDarkBg,
  position: 'relative',
}
