import { useCallback } from 'react'
import { copyToClipboard } from 'copy-lite'
import {
  ipc,
  isElectron,
  DisplaySource,
  electronApi,
  Display,
  RtcDataChannelDataOverIpc,
} from '@there/desktop/utils/electron-api'
import { useCursors } from '../shared/use-cursors'
import { PeerEventContext, PeerOnJsonData } from '../shared/use-rtc-peers'

export const useScreenShareMouseEvents = () => {
  const [cursorsState, cursorsManager] = useCursors()
  const handleScreenShareMouseEvents = useCallback(
    (
      { userId, data }: RtcDataChannelDataOverIpc,
      remoteControlPrivilege: boolean,
    ) => {
      switch (data.type) {
        // case 'switched-host': {
        //   streams.screen.stop()

        //   ipc?.invoke('call:switch-mode', {
        //     callId: activeRequest?.id,
        //     mode: 'observer',
        //   })

        //   break
        // }

        /**
         * host events
         */
        case 'mv': {
          const { x, y } = data
          cursorsManager.updatePosition(userId, { x, y })
          break
        }

        /** Pre-0.11 event - @deprecated */
        case 'mouse-moved': {
          const { xPercent: x, yPercent: y, uId: userId } = data.payload
          cursorsManager.updatePosition(userId, { x, y })
          break
        }

        case 'mouse-clicked': {
          if (!remoteControlPrivilege) break
          const {
            xPercent,
            yPercent,
            uId: userId,
            doubleClick,
            button,
            modifiers,
          } = data.payload

          // move (in case cursor has fallen out of sync)
          cursorsManager.updatePosition(userId, {
            x: xPercent,
            y: yPercent,
          })

          cursorsManager.scrolled(userId)

          // click
          electronApi?.participantCursorClicked({
            xPercent,
            yPercent,
            // ....
            doubleClick,
            button,
            modifiers,
            // screenId: activeRequest?.id,
            userId,
            // Probably should remove
            // displayScaleFactor: callerDisplay?.scaleFactor,
          })
          break
        }

        case 'mouse-scrolled': {
          if (!remoteControlPrivilege) break
          const {
            xPercent,
            yPercent,
            deltaX,
            deltaY,
            uId: userId,
          } = data.payload

          // move (in case cursor has fallen out of sync)
          cursorsManager.updatePosition(userId, {
            x: xPercent,
            y: yPercent,
          })

          cursorsManager.scrolled(userId)

          electronApi?.participantCursorScrolled({
            xPercent,
            yPercent,
            deltaX,
            deltaY,
            userId,
          })
          break
        }
      }
    },
    [cursorsManager],
  )

  return {
    handleScreenShareMouseEvents,
    cursorsState,
  }
}

/**
 *  used in use rtc
 */
export const useScreenShareKeyboardEvents = ({
  currentUserId,
}: {
  currentUserId: string | undefined
}) => {
  const handleScreenShareKeyboardEvents: PeerOnJsonData = useCallback(
    (context, data) => {
      switch (data.type) {
        /**
         * observer events
         */
        case 'write-clipboard': {
          // Copy string from caller screen to receiver clipboard
          if (data.payload.userId === currentUserId) {
            const { text } = data.payload
            copyToClipboard(text)
          }
          break
        }

        // case 'switched-host': {
        //   streams.screen.stop()

        //   ipc?.invoke('call:switch-mode', {
        //     callId: activeRequest?.id,
        //     mode: 'observer',
        //   })

        //   break
        // }

        /**
         * host events
         */

        case 'key-press': {
          ipc?.invoke('key-press-request', data.payload).then((result) => {
            // TODO: re-implement using ipc
            //It should be in another file & can handle copy file
            if (
              result?.action &&
              result.action === 'copy-clipboard-requested'
            ) {
              context.sendToUser(context.userId, {
                type: 'write-clipboard',
                payload: { text: result.text, userId: data.payload.userId },
              })
            }
          })
          break
        }
      }
    },
    [currentUserId],
  )

  return handleScreenShareKeyboardEvents
}
