import { useEffect, useRef, useState } from 'react'
import { useSoundEffect } from '../feed/useSoundEffect'
import { NotificationsState } from '../shared/use-notification-state'

type Props = {
  notificationState: NotificationsState
}

export const NotificationAudio = (props: Props) => {
  let state = props.notificationState
  let volume = 0.1
  let vibrateAudioRef = useRef<HTMLAudioElement | null>(null)
  let beepAudioRef = useRef<HTMLAudioElement | null>(null)
  let [vibrateTriggered, setVibrateTrigger] = useState(false)
  let [beepTriggered, setBeepTrigger] = useState(false)
  let [playNudgeReceive] = useSoundEffect('/tones/nudge-receive.mp3', {
    // increased
    volumeMultiplier: 1.5,
  })

  useEffect(() => {
    let nudgeNotifications = state.incomingNotifications['nudge']
    if (
      nudgeNotifications &&
      nudgeNotifications.length > 0 &&
      !vibrateTriggered
    ) {
      playNudgeReceive()
      setVibrateTrigger(true)
      return
    }
    if (!nudgeNotifications || nudgeNotifications.length === 0) {
      setVibrateTrigger(false)
    }
  }, [playNudgeReceive, state.incomingNotifications, vibrateTriggered])

  useEffect(() => {
    if (!beepAudioRef || !beepAudioRef.current) return
    let screenInviteNotifications =
      state.incomingNotifications['screenShareInvite']
    if (
      screenInviteNotifications &&
      screenInviteNotifications.length > 0 &&
      !beepTriggered
    ) {
      beepAudioRef.current.play()
      setBeepTrigger(true)
      return
    }
    if (!screenInviteNotifications || screenInviteNotifications.length === 0) {
      setBeepTrigger(false)
    }
  }, [state.incomingNotifications, beepTriggered])

  return (
    <>
      <audio
        ref={beepAudioRef}
        onLoad={(event) => {
          const beep = event.currentTarget
          if (!beep) {
            return
          }
          beep.volume = volume
        }}
        src="/tones/beep_beep.mp3"
        autoPlay={false}
        preload="auto"
        style={{ display: 'none' }}
      />
    </>
  )
}
