import { useUniversalReducer } from '@there/components/shared/use-universal-reducer'
import { PartialUser } from '@there/components/urql/fragments/userInfo'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import isEqual from 'react-fast-compare'
import { useLatest } from './use-latest'

type AppContextType = State & {
  dispatch: React.Dispatch<Action>
  dangourselyDispatchLocally: React.Dispatch<Action>
  purgeState: () => void
  appState: State
  hydrated: boolean
}

type State = {
  joinedSpaceIds: string[] | undefined
  activeSpaceId: string | undefined
  activeSpaceName: string | undefined
  activeMemberId: string | undefined
  activeTopicId: string | undefined
  currentUserId: string | undefined
  currentUser: PartialUser | undefined
  avatarId: string | null
  token: string | undefined
  lastSeenNotifications: number | undefined
  recentUsedEmojis: string[]

  // flags
  nudgeLearned: boolean | undefined
  invitedOnce: boolean | undefined
  desktopVersion: string | undefined
  appVersion: string | undefined
  downloadDesktopBannerDismissed: boolean | undefined

  // preferences
  resilientVoice?: boolean
  camDefaultOn?: boolean
  alwaysOnTop?: boolean
  typingStatus?: boolean
  autoEnableMic?: boolean
  awayDot?: boolean
  /** to migrate old ppl */
  autoSetAutoEnableMic?: boolean
  lowCpuShare?: boolean
  lowCpu?: boolean
  chatNoSound?: boolean
  chatNoNotification?: boolean
  hidePin?: boolean
  doubleClick?: boolean
  statusFeed?: boolean
  hideDock?: boolean
  hideTrayUsers?: boolean
  onlineFirstSide?: boolean
}

export type AppState = State

type Action =
  | {
      type: 'active space changed'
      spaceId: string
      memberId: string
      spaceName?: string
    }
  | {
      type: 'active space name changed'
      spaceId: string
      spaceName: string
    }
  | { type: 'active space invalid'; spaceId: string }
  | { type: 'joined spaces fetched'; spaceIds: string[] }
  | { type: 'logged in'; token: string; userId?: string }
  | {
      type: 'current user fetched'
      user: PartialUser
      avatarId: string | null
    }
  | { type: 'load state'; state: State }
  | { type: 'purge' }
  | { type: 'active topic changed'; topicId: string }
  | { type: 'notifications were seen' }
  | { type: 'nudge learned' }
  | { type: 'invited once' }
  | { type: 'desktop version updated'; newVersion: string }
  | { type: 'app version updated'; newVersion: string }
  | { type: 'resilient voice changed'; value: boolean }
  | { type: 'cam default changed'; value: boolean }
  | { type: 'always on top changed'; value: boolean }
  | { type: 'typing status changed'; value: boolean }
  | { type: 'auto enable mic changed'; value: boolean; auto: boolean }
  | { type: 'low cpu sharing changed'; value: boolean }
  | { type: 'low cpu changed'; value: boolean }
  | {
      type: 'chat notif preference changed'
      chatNoSound: boolean
      chatNoNotification: boolean
    }
  | { type: 'hide pin changed'; value: boolean }
  | { type: 'recent emojis updated'; value: string[] }
  | { type: 'double click changed'; value: boolean }
  | { type: 'status feed changed'; value: boolean }
  | { type: 'dismiss desktop banner'; value: boolean }
  | { type: 'hide dock changed'; value: boolean }
  | { type: 'hide tray users changed'; value: boolean }
  | { type: 'away dot changed'; value: boolean }
  | { type: 'online first changed'; value: boolean }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'active space changed':
      return {
        ...state,
        activeSpaceId: action.spaceId,
        activeMemberId: action.memberId,
        activeSpaceName: action.spaceName,
      }
    case 'active space name changed':
      if (action.spaceId !== state.activeSpaceId) return state
      return {
        ...state,
        activeSpaceName: action.spaceName,
      }

    case 'active space invalid': {
      if (state.activeSpaceId === action.spaceId) {
        return {
          ...state,
          activeSpaceId: undefined,
          activeMemberId: undefined,
        }
      }
      return state
    }

    case 'joined spaces fetched':
      return { ...state, joinedSpaceIds: action.spaceIds }

    case 'logged in':
      return { ...state, token: action.token, currentUserId: action.userId }

    case 'current user fetched':
      let avatarId = action.avatarId
      return {
        ...state,
        currentUser: action.user,
        avatarId,
      }

    case 'active topic changed':
      return {
        ...state,
        activeTopicId: action.topicId,
      }

    case 'notifications were seen':
      return {
        ...state,
        lastSeenNotifications: Date.now(),
      }

    case 'nudge learned':
      return {
        ...state,
        nudgeLearned: true,
      }

    case 'invited once':
      return {
        ...state,
        invitedOnce: true,
      }

    case 'desktop version updated':
      return {
        ...state,
        desktopVersion: action.newVersion,
      }

    case 'app version updated':
      return {
        ...state,
        appVersion: action.newVersion,
      }

    case 'resilient voice changed':
      return {
        ...state,
        resilientVoice: action.value,
      }

    case 'cam default changed':
      return {
        ...state,
        camDefaultOn: action.value,
      }

    case 'always on top changed':
      return {
        ...state,
        alwaysOnTop: action.value,
      }

    case 'typing status changed':
      return {
        ...state,
        typingStatus: action.value,
      }

    case 'low cpu sharing changed':
      return {
        ...state,
        lowCpuShare: action.value,
      }

    case 'low cpu changed':
      return {
        ...state,
        lowCpu: action.value,
      }

    case 'chat notif preference changed':
      return {
        ...state,
        chatNoSound: action.chatNoSound,
        chatNoNotification: action.chatNoNotification,
      }

    case 'auto enable mic changed':
      return {
        ...state,
        autoEnableMic: action.value,
        autoSetAutoEnableMic: action.auto ? true : state.autoSetAutoEnableMic,
      }

    case 'hide pin changed':
      return {
        ...state,
        hidePin: action.value,
      }

    case 'hide dock changed':
      return {
        ...state,
        hideDock: action.value,
      }

    case 'hide tray users changed':
      return {
        ...state,
        hideTrayUsers: action.value,
      }

    case 'recent emojis updated':
      let recentEmojis = state.recentUsedEmojis
        ? [...state.recentUsedEmojis]
        : []
      return {
        ...state,
        recentUsedEmojis: Array.from(
          new Set([...action.value, ...recentEmojis]),
        ).splice(0, 28),
      }

    case 'dismiss desktop banner':
      return {
        ...state,
        downloadDesktopBannerDismissed: action.value,
      }

    case 'load state':
      return action.state

    case 'purge':
      return { ...initialState }

    case 'double click changed':
      return {
        ...state,
        doubleClick: action.value,
      }

    case 'status feed changed':
      return {
        ...state,
        statusFeed: action.value,
      }

    case 'away dot changed':
      return {
        ...state,
        awayDot: action.value,
      }

    case 'online first changed':
      return {
        ...state,
        onlineFirstSide: action.value,
      }

    // if unknown action, don't clear the state
    default:
      return state
  }
}

const initialState: State = {
  joinedSpaceIds: undefined,
  activeSpaceId: undefined,
  activeMemberId: undefined,
  activeSpaceName: undefined,
  activeTopicId: undefined,
  currentUserId: undefined,
  currentUser: undefined,
  avatarId: null,
  token: undefined,
  lastSeenNotifications: undefined,
  nudgeLearned: undefined,
  invitedOnce: undefined,
  desktopVersion: undefined,
  appVersion: undefined,
  resilientVoice: undefined,
  camDefaultOn: undefined,
  alwaysOnTop: undefined,
  typingStatus: undefined,
  autoEnableMic: undefined,
  lowCpuShare: undefined,
  lowCpu: undefined,
  chatNoSound: undefined,
  chatNoNotification: undefined,
  hidePin: undefined,
  recentUsedEmojis: [],
  doubleClick: undefined,
  statusFeed: false,
  downloadDesktopBannerDismissed: false,
  hideDock: false,
  hideTrayUsers: false,
  awayDot: true,
  onlineFirstSide: false,
}

function noop() {}

const initialContext = {
  ...initialState,
  appState: initialState,
  dispatch: noop,
  purgeState: noop,
  dangourselyDispatchLocally: noop,
  hydrated: false,
}

const AppContext = createContext<AppContextType>(initialContext)
AppContext.displayName = 'AppContext'
export const useAppContext = () => useContext<AppContextType>(AppContext)
export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [[appState, dispatch], { localDispatch }] = useUniversalReducer(
    useReducer(reducer, initialState),
    {
      key: 'app-context',
    },
  )
  let appStateRef = useLatest(appState)

  const [hydrated, setHydrated] = useState(false)

  // Load state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    const appStateJson = localStorage.getItem('appState')

    if (
      !appStateJson ||
      typeof appStateJson !== 'string' ||
      !appStateJson.startsWith('{')
    ) {
      setHydrated(true)

      return
    }

    // Hydrate
    if (!isEqual(appStateRef.current, initialState)) {
      console.warn('☢️ app state has been changed before hydrated ☢️')
    }
    localDispatch({ type: 'load state', state: JSON.parse(appStateJson) })
    setHydrated(true)
  }, [appStateRef, localDispatch])

  // Persist state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    // Don't replace our cache with empty state
    if (!hydrated) {
      return
    }

    // to prevent set appState when you are not login
    if (!appState.token) return

    localStorage.setItem('appState', JSON.stringify(appState))
  }, [hydrated, appState])

  // Persist token separately for the graphql client
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    if (appState.token) {
      localStorage.setItem('token', appState.token)
    } else {
      localStorage.removeItem('token')
    }
  }, [appState.token])

  useEffect(() => {
    if (!hydrated) return

    // for users with no preferences, set it as on default
    if (!appState.autoSetAutoEnableMic && !appState.autoEnableMic) {
      dispatch({ type: 'auto enable mic changed', auto: true, value: true })
    }
  }, [
    appState.autoEnableMic,
    appState.autoSetAutoEnableMic,
    dispatch,
    hydrated,
  ])

  const purgeState = useCallback(() => {
    dispatch({ type: 'purge' })
    localStorage.removeItem('appState')
  }, [dispatch])

  const value = useMemo(() => {
    return {
      ...appState,
      appState,
      purgeState,
      dispatch,
      dangourselyDispatchLocally: localDispatch,
      hydrated,
    }
  }, [appState, hydrated, purgeState, localDispatch, dispatch])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
