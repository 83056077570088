import { useState } from 'react'
import { Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { EmojiView } from '../main/EmojiView'
import { Pressable } from '../shared/Pressable'
import { a, useTransition } from '@react-spring/native'
import { useCallback } from 'react'
import { useModals, UserRateModalData } from '../feed/ModalsContext'
import { useUserRate } from '../main/useUserRate'
import { useEffect } from 'react'

export const RateCall = () => {
  let theme = useTheme()
  let [modalsState, modalsDispatch] = useModals()
  let [, doRate] = useUserRate()

  let rateModalState = modalsState.modals.includes('userRate')

  let dismiss = useCallback(() => {
    modalsDispatch({ type: 'modal closed', modalName: 'userRate' })
  }, [modalsDispatch])

  useEffect(() => {
    if (!rateModalState) return
    let t = setTimeout(() => {
      dismiss()
    }, 20_000)
    return () => clearTimeout(t)
  }, [dismiss, rateModalState])

  let modalData = modalsState.modalsData['userRate'] as
    | UserRateModalData
    | undefined

  let rateCall = useCallback(
    (rate: number) => {
      if (!modalData) return
      doRate({
        maximumParticipants: modalData.callDetails.maximumParticipants,
        rate,
        dialogId: modalData.dialogId,
        hasCamera: modalData.callDetails.hasCamera,
        hasScreenShare: modalData.callDetails.hasScreenShare,
      })
      dismiss()
    },
    [dismiss, doRate, modalData],
  )

  let transition = useTransition(rateModalState, {
    from: {
      y: 100,
      opacity: 1,
    },
    enter: {
      y: 0,
      opacity: 1,
    },
    leave: {
      y: 100,
      opacity: 0,
    },
    config: {
      tension: 400,
      friction: 20,
      mass: 0.1,
    },
  })

  return (
    <>
      {transition(({ y, opacity }, item) => {
        return item ? (
          <a.View
            style={{
              width: '100%',
              paddingHorizontal: 12,
              position: 'absolute',
              bottom: 12,
              transform: [{ translateY: y }],
              opacity,
            }}
          >
            <Pressable
              style={{
                height: 48,
                display: 'flex',
                flexGrow: 1,
                borderRadius: 48,
                backgroundColor: '#293544',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 16,
                paddingRight: 8,
              }}
              onPress={dismiss}
            >
              <Text
                style={{
                  fontSize: theme.fontSizes.normal,
                  color: theme.colors.secondaryText,
                }}
              >
                Please rate the quality of your noor call
              </Text>
              <View
                style={{
                  height: 32,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: 100,
                  marginLeft: 10,
                }}
              >
                <EmojiButton
                  emoji="😍"
                  onPress={() => {
                    rateCall(5)
                  }}
                />
                <EmojiButton
                  emoji="😐"
                  onPress={() => {
                    rateCall(3)
                  }}
                />
                <EmojiButton
                  emoji="😒"
                  onPress={() => {
                    rateCall(1)
                  }}
                />
                <Pressable
                  onPress={dismiss}
                  style={({ hovered, pressed }) => [
                    {
                      height: '100%',
                      padding: 5,
                      paddingHorizontal: 10,
                      borderRadius: 100,
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                    hovered && {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: theme.fontSizes.normal,
                      color: theme.colors.tertiaryText,
                    }}
                  >
                    Dismiss
                  </Text>
                </Pressable>
              </View>
            </Pressable>
          </a.View>
        ) : null
      })}
    </>
  )
}

const EmojiButton = ({
  emoji,
  onPress,
}: {
  emoji: string
  onPress: () => void
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={({ hovered, pressed }) => [
        {
          padding: 5,
          borderRadius: 100,
        },
        hovered && {
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
        },
      ]}
    >
      <EmojiView size={21}>{emoji}</EmojiView>
    </Pressable>
  )
}
