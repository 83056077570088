/**
 * ☢️ Universal
 *
 */
import { formatUpdates } from '@there/sun/utils/formatters/formatUpdates'
import { DialogInfo, NodeUpdateInfo } from '@there/sun/utils/node-types'
import type { Input } from './editDialog'

interface CacheData {
  spaceId: string
  dialogId: string
}

const editDialog = (
  input: Omit<Input, 'currentUserId'>,
  cacheData: CacheData,
): NodeUpdateInfo[] => {
  // Create optimistic
  let {
    dialogId,
    iconEmoji,
    doorClosed,
    silent,
    title,
    backgroundUrl,
    gameId,
    gameServer,
  } = input

  let patch: Partial<DialogInfo> = {}

  if (typeof title !== 'undefined') patch.title = title
  if (typeof iconEmoji !== 'undefined') patch.iconEmoji = iconEmoji
  if (typeof doorClosed !== 'undefined') patch.doorClosed = doorClosed
  if (typeof silent !== 'undefined') patch.silent = silent
  if (typeof backgroundUrl !== 'undefined') patch.bgObjectId = backgroundUrl
  if (typeof backgroundUrl !== 'undefined') patch.bgDarkenPercent = 0
  if (typeof gameId !== 'undefined') patch.gameId = gameId
  if (typeof gameServer !== 'undefined') patch.gameServer = gameServer

  let updatedDialog: NodeUpdateInfo = {
    mutation: 'Update',
    type: 'Dialog',
    node: {
      id: cacheData.dialogId,
      ...patch,
    },
  }

  let updates = [updatedDialog]

  return formatUpdates(updates)
}

export default editDialog
