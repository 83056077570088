import { useImmerReducer } from 'use-immer'
import { Draft } from 'immer'
import { useUniversalReducer } from '@there/components/shared/use-universal-reducer'

export enum NotificationResponse {
  Accepted,
  Rejected,
  Dismissed,
  Timeout,
}
interface UntypedNotification {
  id: string
  sentAt: number
  senderId: string
  user: {
    name?: string
    profilePhoto?: string
  }
  responded?: NotificationResponse | undefined
}

interface Nudge extends UntypedNotification {
  type: 'nudge'
  dialogId?: string
  spaceId?: string
}

export type NudgeNotificationType = Nudge

interface ScreenShareInvite extends UntypedNotification {
  type: 'screenShareInvite'
  callId: string
}

interface JoinRoom extends UntypedNotification {
  type: 'joinRoom'
}

export type JoinRoomNotificationType = JoinRoom

export type NotificationType =
  | Nudge
  | ScreenShareInvite
  | JoinRoomNotificationType

let typeArraySizes: Record<string, number> = {
  nudge: 3,
  screenShareInvite: 1,
  joinRoom: 3,
}

export type State = {
  incomingNotifications: Record<string, NotificationType[]>
  respondedNotifications: NotificationType[]
  recentIds: string[]
}

export type NotificationsDispatch = React.Dispatch<NotificationAction>

const initialState: State = {
  incomingNotifications: {},
  recentIds: new Array<string>(),
  respondedNotifications: [],
}

export const initialNotificationsState = initialState

export type NotificationsState = State

type NotificationAction =
  | {
      type: 'add incoming'
      notification: NotificationType
    }
  | { type: 'incoming dismissed'; notification: NotificationType }
  | {
      type: 'incoming responded'
      notification: NotificationType
      response: NotificationResponse
    }
  | {
      type: 'response processed'
      notification: NotificationType
    }
  | {
      type: 'all notifications dismissed'
    }

export function reducer(
  draft: Draft<State>,
  action: NotificationAction,
): void | State {
  switch (action.type) {
    case 'add incoming':
      let notification = action.notification
      // check if it is received recently -> do not add it again
      if (draft.recentIds.includes(notification.id)) {
        return
      }

      // time validation (30s)

      // commented because Mo said //

      // let timestampDifferenceInSeconds =
      //   (Date.now() - notification.sentAt) / 1000
      // if (timestampDifferenceInSeconds > 30) {
      //   console.warn(
      //     'ignore notif because time',
      //     Date.now(),
      //     notification.sentAt,
      //   )
      //   return
      // }

      let notificationTypeArray =
        draft.incomingNotifications[notification.type] || []

      // check if we accept this type of notification and space has allocated
      if (!typeArraySizes[notification.type]) {
        console.warn('ignore notif because type size limit')
        return
      }

      // check size of notificationTypeArray
      if (notificationTypeArray.length >= typeArraySizes[notification.type]) {
        let numberOfItemsToRemove =
          notificationTypeArray.length - typeArraySizes[notification.type] + 1
        notificationTypeArray.splice(0, numberOfItemsToRemove)
      }

      // add notification to incoming array
      notificationTypeArray.push(notification)
      draft.incomingNotifications[notification.type] = notificationTypeArray

      //add to recentIds array
      if (draft.recentIds.length === 50) {
        draft.recentIds.shift()
      }
      draft.recentIds.push(notification.id)
      return

    case 'incoming dismissed':
      // check if we have notification from dismissed type
      if (!draft.incomingNotifications[action.notification.type]) return

      // remove dismissed
      draft.incomingNotifications[
        action.notification.type
      ] = draft.incomingNotifications[action.notification.type].filter(
        (notification) => notification.id !== action.notification.id,
      )
      return

    case 'incoming responded':
      // check if we have notification from responded type
      if (!draft.incomingNotifications[action.notification.type]) return
      // remove from incoming
      draft.incomingNotifications[
        action.notification.type
      ] = draft.incomingNotifications[action.notification.type].filter(
        (notification) => notification.id !== action.notification.id,
      )

      // add to responded
      draft.respondedNotifications = [
        ...draft.respondedNotifications,
        { ...action.notification, responded: action.response },
      ]

      return

    case 'all notifications dismissed':
      draft.incomingNotifications = {}
      return

    case 'response processed':
      if (!draft.respondedNotifications) return
      draft.respondedNotifications = draft.respondedNotifications.filter(
        (notification) => notification.id !== action.notification.id,
      )
      return

    default:
      return draft
  }
}

export const useNotificationsState = (): [
  NotificationsState,
  React.Dispatch<NotificationAction>,
] => {
  let [reducerTuple] = useUniversalReducer(
    useImmerReducer<State, NotificationAction>(reducer, initialState),
    { key: 'notification-reducer' },
  )

  return reducerTuple
}
