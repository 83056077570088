import React, { useCallback } from 'react'
import { View, Text } from 'react-native'
import { Dropdown } from '@there/components/feed/Dropdown'
import { BellIcon } from '@there/components/main/BellIcon'
import {
  NotificationHistory,
  useNotificationsContext,
} from '@there/components/shared/NotificationsManager'
import { Tooltip } from '@there/components/shared/Tooltip'
import { useSelect } from 'downshift'
import { useTheme } from '@there/components/feed/ThemeContext'
import { CircleButton } from '@there/components/main/CircleButton'
import { Space } from '@there/components/shared/Space'
import { useUsersContext } from '@there/components/shared/UsersContext'
import { ProfilePhoto } from '@there/components/feed/ProfilePhoto'
import { getShortName } from '../utils/get-short-name'
import { getRelativeDate } from '../main/get-last-seen'
import { useDismissNotification } from '../main/useDismissNotification'
import { Pressable } from '../shared/Pressable'
import { useMemo } from 'react'
import { useAppContext } from '../shared/AppContext'
import { useMemberships } from '../feed/useMemberships'
import { GoodButton } from '@there/components/shared/GoodButton'

const notificationDropdownWidth = 190
const horizontalSpace = 4
const profileSize = 28

export const NotificationDropdown = ({ v2 }: { v2?: boolean }) => {
  let { dispatch, lastSeenNotifications, activeSpaceId } = useAppContext()
  let { joinedSpacesData } = useMemberships()
  let { notificationHistory } = useNotificationsContext()
  let { getUser } = useUsersContext()
  let theme = useTheme()

  // This is a fake downshift item to add at the end of items
  let clearAllItem: NotificationHistory = useMemo(() => {
    let notificationIds: string[] = []
    for (let notification of notificationHistory) {
      notificationIds = [
        ...notificationIds,
        ...notification.batchNotificationIds,
      ]
    }

    return {
      batchNotificationIds: notificationIds,
      time: 0,
      type: 'clear all',
      userId: '',
    }
  }, [notificationHistory])

  let items = [...notificationHistory, clearAllItem]

  let {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    closeMenu,
    openMenu,
  } = useSelect({
    items,
  })

  let [, dismissNotification] = useDismissNotification()

  const openNotificationMenu = useCallback(() => {
    openMenu()
    dispatch({ type: 'notifications were seen' })
  }, [dispatch, openMenu])

  let unSeenNotifications = useMemo(() => {
    return notificationHistory.filter(
      (notification) => notification.time > (lastSeenNotifications || 0),
    ).length
  }, [lastSeenNotifications, notificationHistory])

  return (
    <Dropdown.Root>
      <Dropdown.Toggle onPress={openNotificationMenu}>
        <Tooltip label="Notifications">
          {v2 ? (
            <GoodButton
              icon={
                <>
                  <BellIcon color="#757D86" width={19} height={19} />
                  <NotificationIndicator number={unSeenNotifications} />
                </>
              }
              onPressHandled={true}
              variant="transparent"
              active={isOpen}
              {...getToggleButtonProps(
                { refKey: 'notification-dropdown-noref' },
                { suppressRefError: true },
              )}
            />
          ) : (
            <CircleButton
              backgroundMode="transparent"
              isActive={isOpen}
              {...getToggleButtonProps(
                { refKey: 'notification-dropdown-noref' },
                { suppressRefError: true },
              )}
            >
              <BellIcon color={theme.colors.tertiaryLight} />
              <NotificationIndicator number={unSeenNotifications} />
            </CircleButton>
          )}
        </Tooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu
        {...getMenuProps(undefined, { suppressRefError: true })}
        isOpen={isOpen}
        onClose={closeMenu}
        width={notificationDropdownWidth}
      >
        <Dropdown.Section>
          <Space vertical={4} />
          {items.length > 1 &&
            items.map((item, index) => {
              let highlighted = highlightedIndex === index
              if (item.type === 'nudge') {
                let userId = item.userId
                if (!userId) {
                  console.warn('[notification] senderUser is undefined')
                  return null
                }

                let user = getUser(userId)
                let userName = item.user?.name || user?.nickname || user?.name
                let userProfile = item.user?.profilePhoto || user?.profilePhoto
                if (!userName && !userProfile) {
                  console.warn('[notification] user is undefined')
                  return null
                }

                let count = item.batchNotificationIds.length

                let notificationSpaceId = String(item.spaceId)
                let receivedFromAnotherSpace =
                  notificationSpaceId && notificationSpaceId !== activeSpaceId
                let spaceData =
                  joinedSpacesData && joinedSpacesData[notificationSpaceId]

                return (
                  <Pressable
                    key={item.batchNotificationIds[0]}
                    {...getItemProps({
                      item,
                      index,
                    })}
                    style={[
                      {
                        maxWidth:
                          notificationDropdownWidth - horizontalSpace * 2,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingVertical: 2,
                        paddingHorizontal: horizontalSpace,
                        marginHorizontal: horizontalSpace,
                        borderRadius: theme.borderRadius.small,
                      },
                      highlighted &&
                        receivedFromAnotherSpace &&
                        spaceData && {
                          backgroundColor: theme.colors.hoverItemBackground,
                        },
                    ]}
                    disabled={!receivedFromAnotherSpace || !spaceData}
                    onPress={() => {
                      if (!receivedFromAnotherSpace || !spaceData) return
                      dispatch({
                        type: 'active space changed',
                        spaceId: notificationSpaceId,
                        spaceName: spaceData.name || '',
                        memberId: spaceData.memberId,
                      })
                    }}
                  >
                    <View
                      style={{
                        width: profileSize,
                        height: profileSize,
                        borderRadius: profileSize,
                        borderWidth: 1,
                        borderColor: theme.colors.quinaryLight,
                        position: 'relative',
                      }}
                    >
                      <ProfilePhoto
                        user={{
                          profilePhoto: userProfile || user?.profilePhoto,
                          name: userName || user?.name,
                        }}
                        size={26}
                      />
                      <Text
                        style={{
                          width: 14,
                          height: 14,
                          fontSize: 14,
                          position: 'absolute',
                          bottom: 0,
                          left: -3,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        👋
                      </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: horizontalSpace,
                      }}
                    >
                      <Text
                        style={{
                          maxWidth:
                            notificationDropdownWidth -
                            horizontalSpace * 5 -
                            profileSize,
                          fontSize: 12,
                          color: theme.colors.tertiaryText,
                        }}
                      >
                        <Text style={{ color: theme.colors.secondaryText }}>
                          {getShortName({ nickname: userName })}
                        </Text>{' '}
                        nudged you{count === 1 ? `` : ` ${count} times`}{' '}
                        <Text style={{ color: theme.colors.quaternaryText }}>
                          {getRelativeDate(new Date(item.time))}
                        </Text>
                        {receivedFromAnotherSpace && spaceData ? (
                          <Text style={{ color: theme.colors.quaternaryText }}>
                            {' '}
                            from {spaceData.name}&apos;s space.
                          </Text>
                        ) : null}
                      </Text>
                    </View>
                  </Pressable>
                )
              } else {
                return (
                  <View
                    key="clear-all-notification"
                    {...getItemProps({
                      item,
                      index,
                    })}
                  >
                    <View
                      style={{
                        width: notificationDropdownWidth - horizontalSpace * 4,
                        height: 0,
                        borderBottomWidth: 1,
                        borderBottomColor: theme.colors.separatorLine,
                        marginHorizontal: horizontalSpace * 2,
                        marginVertical: horizontalSpace,
                      }}
                    />
                    <Pressable
                      onPress={() => {
                        dismissNotification({ clearAll: true })
                      }}
                      style={({ pressed }) => [
                        {
                          maxWidth:
                            notificationDropdownWidth - horizontalSpace * 2,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingVertical: 2,
                          paddingHorizontal: horizontalSpace,
                          marginHorizontal: horizontalSpace,
                          borderRadius: theme.borderRadius.small,
                        },
                        highlighted && {
                          backgroundColor: theme.colors.hoverItemBackground,
                        },
                        pressed && {
                          backgroundColor: theme.colors.activeItemBackground,
                        },
                      ]}
                    >
                      <Text
                        style={{
                          textAlign: 'center',
                          color: theme.colors.tertiaryText,
                        }}
                      >
                        Clear all
                      </Text>
                    </Pressable>
                  </View>
                )
              }
            })}

          {/**
           * when there is no notification
           */}
          {items.length <= 1 && (
            <View
              style={{
                width: notificationDropdownWidth,
                paddingVertical: 4,
                paddingHorizontal: 8,

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  color: theme.colors.tertiaryText,
                  fontSize: theme.fontSizes.large,
                }}
              >
                🎉 Congratulations
              </Text>
              <Text
                style={{
                  color: theme.colors.tertiaryText,
                  textAlign: 'center',
                  fontSize: theme.fontSizes.normal,
                  marginTop: 2,
                }}
              >
                You{"'"}ve cleared your notifications
              </Text>
            </View>
          )}

          <Space vertical={4} />
        </Dropdown.Section>
      </Dropdown.Menu>
    </Dropdown.Root>
  )
}

const NotificationIndicator = ({ number }: { number: number }) => {
  let theme = useTheme()

  if (!number || number <= 0) return null
  return (
    <View
      style={{
        position: 'absolute',
        width: 10,
        height: 10,
        borderRadius: 12,
        backgroundColor: theme.colors.primary,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',

        bottom: 3,
        right: 3,
      }}
    >
      <Text
        style={{
          fontSize: 8,
          color: theme.colors.text,
          textAlign: 'center',
        }}
      >
        {number}
      </Text>
    </View>
  )
}
