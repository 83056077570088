import { useEffect, useMemo, useRef, useState } from 'react'
import { rtcCoreAtom, useRtcContext } from '@there/components/shared/use-rtc'
import { Cursor, CursorProps } from '@there/components/screen-share/Overlay'
import {
  usePhotoPointer,
  usePhotoViewers,
} from '@there/components/photoViewer/state'
import { useUsersContext } from '@there/components/shared/UsersContext'
import { getShortName } from '@there/shared/utilities/get-short-name'
import { getProfileBackground } from '@there/components/utils/get-profile-background'
import { useAppContext } from '@there/components/shared/AppContext'
import { useAtomValue } from 'jotai/utils'
import { a, useSpring } from '@react-spring/web'

type PointersProps = {
  messageId: string
  imageWidth: number
  imageHeight: number
  scale: number
}

export const Pointers = ({
  messageId,
  imageWidth,
  imageHeight,
  scale,
}: PointersProps) => {
  let { participants } = useRtcContext()
  let { getUser } = useUsersContext()
  let { opened, closed, usersByMessageId } = usePhotoViewers()
  let { pointersById } = usePhotoPointer()

  // Register
  useEffect(() => {
    opened(messageId)

    return () => {
      closed(messageId)
    }
  }, [closed, opened, messageId])

  let viewingParticipants = useMemo(() => {
    return participants.filter((avatar) =>
      usersByMessageId[messageId]?.includes(avatar.userId),
    )
  }, [messageId, participants, usersByMessageId])

  /**
   * 1. + add photo open / close events for chats
   * 2. send mouse moves (to people with open photos)
   * 3. create pointer components
   * 4. show pointers of active people on the photo
   * 5. show avatars of active person on the photo preview
   */

  return (
    <>
      {viewingParticipants.map((avatar) => {
        let user = getUser(avatar.userId)
        let item = pointersById[avatar.userId]

        if (!user) return null
        if (!item) return null

        return (
          <div
            key={user.id}
            style={{
              width: 150,
              height: 40,
              position: 'absolute',
              zIndex: 10,
              top: 0,
              left: 0,
              transform: `translate3d(${
                (imageWidth * scale * item.x) / 100
              }px, ${(imageHeight * scale * item.y) / 100}px, 0)`,
              opacity: item.active ? 1 : 0.6,
              transition: `transform 18ms`,
            }}
          >
            <Pointer
              name={getShortName(user) || ''}
              color={getProfileBackground(user.name || user.email || '')}
              active={item.active}
            />
          </div>
        )
      })}
    </>
  )
}

/**
 * Because it's dependant on page x and y instead of image percent, we should put it outside the pointers relative boundary
 * We had to use page x and y so pinch doesn't mess up it's position
 */
export const SelfPointer = ({ isOpen }: { isOpen: boolean }) => {
  let { currentUser } = useAppContext()
  let { isInCall } = useAtomValue(rtcCoreAtom)
  let [mousePos, setMousePos] = useState<[number, number]>([0, 0])
  // let [mousePos, setMousePos] = useAtom(photoSelfMousePositionAtom)

  useEffect(() => {
    // Reset
    setMousePos([0, 0])
  }, [isOpen, setMousePos])

  let [showSelfPointer, setShowSelfPointer] = useState(true)

  useEffect(() => {
    const listener = () => {
      setShowSelfPointer(false)
    }
    const handleEnter = () => {
      setShowSelfPointer(true)
    }

    let nextFrame: number | undefined
    const handleMove = (event: MouseEvent) => {
      if (nextFrame) cancelAnimationFrame(nextFrame)
      nextFrame = requestAnimationFrame(() => {
        setMousePos([event.pageX, event.pageY])
      })
    }

    document.addEventListener('mousemove', handleMove)
    document.addEventListener('mouseleave', listener)
    document.addEventListener('mouseenter', handleEnter)

    return () => {
      document.removeEventListener('mousemove', handleMove)
      document.removeEventListener('mouseleave', listener)
      document.removeEventListener('mouseenter', handleEnter)
    }
  }, [])

  let styles = useSpring({
    opacity: Number(showSelfPointer && mousePos[0] > 0 && mousePos[1] > 0),
  })

  if (!isInCall || !currentUser) return null

  return (
    <a.div
      key={currentUser.id}
      style={{
        width: 150,
        height: 40,
        position: 'absolute',
        zIndex: 10,
        top: 0,
        left: 0,
        transform: `translate3d(${mousePos[0]}px, ${mousePos[1]}px, 0)`,
        transition: `transform 18ms`,
        opacity: styles.opacity,
        display: isOpen ? undefined : 'none',
      }}
    >
      <Pointer
        name="You"
        color={getProfileBackground(
          currentUser.name || currentUser.email || '',
        )}
        active={true}
        onlyName={true}
      />
    </a.div>
  )
}

const Pointer = (props: CursorProps) => {
  return (
    <>
      <Cursor {...props} />
    </>
  )
}
