import { NewMessageInfo } from '@there/sun/utils/node-types'
import { memo, useRef, useState } from 'react'
import { ActivityIndicator, Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { usePrevious } from '../shared/use-previous'
import { WebStyled } from '../shared/WebStyled'
import { checkOnlyEmoji } from '../utils/check-only-emoji'
import { useOnScreenOptimized } from './useOnScreen'
import {
  electronApi,
  electronSupports,
  ipc,
  isElectron,
} from '@there/desktop/utils/electron-api'
import { CheckIcon } from '../chat/CheckIcon'
import deepEqual from 'react-fast-compare'
import {
  minifyUserForProfilePhoto,
  MinUserProfile,
  ProfilePhoto,
} from '@there/components/feed/ProfilePhoto'
import { getAmPmTime } from '../utils/ampm'
import { Pressable } from '@there/components/shared/Pressable'
import { useUser } from '@there/components/atoms/userAtom'
import { useNurNode } from '@there/components/sun/use-node'
import { useMessageActions } from '@there/components/v2/useMessageActions'
import { EmbeddedMessage } from '@there/components/v2/EmbededMessage'
import { SenderName } from '@there/components/message/SenderName'
import Linkify from 'react-linkify'
import { MAX_MEDIA_WIDTH, PhotoMessage } from '../message/PhotoMessage'
import useIsMounted from '@there/components/hooks/useIsMounted'
import { FileMessage } from '../message/FileMessage'
import {
  getMetaWidth,
  MessageMeta,
} from '@there/components/message/MessageMeta'
import { useInViewportTarget } from '@there/components/hooks/useInViewport'
import { DownloadData } from './useDownloadManager'
import { getMessageMediaFullPath } from '@there/components/message/helpers'
import { GifMessage } from '../message/GifMessage'
import { OgComponent } from './OgComponent'

type Props = {
  isUs: boolean
  sent?: boolean
  hasRead: boolean
  parentWidth: number

  onCalculate?: () => void

  message: NewMessageInfo

  // New props
  noAvatars: boolean
  withAvatar: boolean
  withSenderName: boolean
  lastMessageInBatch: boolean
  downloadStatusData?: DownloadData
}

type State = {
  sender: MinUserProfile | undefined
  replyMessage: NewMessageInfo | undefined
}

const MessageNode = memo(
  ({
    message,
    isUs,
    sent,
    parentWidth,
    onCalculate,
    noAvatars,
    withAvatar,
    withSenderName,
    sender,
    replyMessage,
    downloadStatusData,
    hasRead,
  }: Props & State) => {
    let {
      openMessageMenu: openMenu,
      goToUserChat,
      viewPhoto,
    } = useMessageActions()
    let isMounted = useIsMounted()

    // Message Data
    let time = message.sentAt
    let children = message.text || ' '

    let theme = useTheme()
    let sendTime = new Date(time)
    let [lineNumbers, setLineNumbers] = useState(1)
    let isOnlyEmoji = checkOnlyEmoji(children)

    let renderEmojiImproved = isOnlyEmoji && children.length < 8

    let ref = useRef<View | null>(null)

    // seen message (now managed in chat view)
    useInViewportTarget(message, ref)

    let user = sender

    let metaWidth = isUs ? 70 : 62
    if (message.edited) {
      // metaWidth += 26
      metaWidth += 30
    }

    let hasPhoto =
      message.mediaType === 'Photo' || message.document?.type === 'Photo'

    let hasGif = message.document && message.document.type === 'Gif'

    let hasFile = message.document?.type === 'File'
    let hasText = !!message.text
    let messageMaxWidth = parentWidth * 0.6

    const BIGGEST_LEFT_PADDING = 12
    const BIGGEST_RIGHT_PADDING = 10

    if (hasPhoto || hasGif) {
      const PADDINGS_AROUND_TEXT = BIGGEST_LEFT_PADDING + BIGGEST_RIGHT_PADDING
      // Limit max width here
      messageMaxWidth = Math.min(
        messageMaxWidth,
        MAX_MEDIA_WIDTH - PADDINGS_AROUND_TEXT,
      )
    }

    // let forceMultiLine = hasPhoto
    let forceMultiLine = false
    let isTextMultiLine = lineNumbers > 1 || forceMultiLine

    let reverse = isUs

    let onlyMediaMessage = (hasPhoto || hasGif) && !hasText

    // let [metaWidth, setMetaWidth] = useState<number | null>(null)

    const contentPaddings = {
      paddingLeft: renderEmojiImproved && !reverse ? 4 : BIGGEST_LEFT_PADDING,
      paddingRight: renderEmojiImproved && reverse ? 4 : BIGGEST_RIGHT_PADDING,
    }

    const downloadFile = (message: NewMessageInfo) => {
      if (electronSupports?.fileDownload) {
        ipc?.invoke('file:download', message, {
          saveAs: false,
          openFolderWhenDone: true,
        })
      } else {
        // Does not support progress update
        ipc?.invoke('image:download', getMessageMediaFullPath(message), {
          saveAs: false,
          // set to false when added a notification
          openFolderWhenDone: true,
        })
      }
    }

    return (
      <WebStyled
        onContextMenu={() => {
          openMenu(message)
        }}
        style={{
          width: '100%',
          position: 'relative',

          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: isUs ? 'flex-end' : 'flex-start',
          paddingLeft: noAvatars ? 0 : 28,
          marginBottom: renderEmojiImproved ? 2 : 0,
        }}
      >
        {withAvatar && user && (
          <Pressable
            style={{ position: 'absolute', bottom: 0, left: 0 }}
            onPress={() => {
              goToUserChat(message.senderId)
            }}
          >
            <ProfilePhoto
              size={22}
              user={user}
              borderWidth={1}
              borderColor="avatarBorder"
            />
          </Pressable>
        )}

        {/* Bubble */}
        <View
          ref={ref}
          style={[
            {
              position: 'relative',
              paddingTop: 5,

              // Moved to the text box
              // paddingBottom: 5,

              // Moved it to message text
              // paddingLeft:
              //   renderEmojiImproved && !reverse ? 4 : BIGGEST_LEFT_PADDING,
              // paddingRight:
              //   renderEmojiImproved && reverse ? 4 : BIGGEST_RIGHT_PADDING,

              marginTop: 4,
              borderRadius: 14,

              backgroundColor:
                renderEmojiImproved || onlyMediaMessage
                  ? undefined
                  : isUs
                  ? theme.colors.bubbleMessageSelfBackground
                  : theme.colors.bubbleMessageBackground,

              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
            (hasPhoto || hasFile || hasGif) && {
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
              overflow: 'hidden',
            },
          ]}
        >
          <View style={[contentPaddings, { maxWidth: messageMaxWidth }]}>
            {
              // Emoji only messages don't need
              !renderEmojiImproved &&
              withSenderName &&
              user &&
              !hasPhoto &&
              !hasGif ? (
                <SenderName userId={message.senderId} />
              ) : null
            }

            {
              // Put the if on the id so until it's loaded the space is preserved for proper scroll to end
              message.replyToMessageId && (
                <EmbeddedMessage
                  message={replyMessage || null}
                  withSenderName={withSenderName}
                />
              )
            }
          </View>
          {/* Wrapper of Image and message meta */}
          {hasPhoto && (
            <PhotoMessage
              message={message}
              onPress={() => {
                ref.current?.measureInWindow((x, y, width, height) => {
                  viewPhoto(message, { x, y, width, height })
                })
              }}
            />
          )}
          {hasGif && (
            <GifMessage
              message={message}
              onPress={() => {
                ref.current?.measureInWindow((x, y, width, height) => {
                  // viewPhoto(message, { x, y, width, height })
                })
              }}
            />
          )}
          {hasFile && (
            <FileMessage
              message={message}
              onPress={() => {
                downloadFile(message)
              }}
              downloadStatusData={downloadStatusData}
            />
          )}
          {/* Wrapper of text and message meta */}
          <View
            style={[
              {
                display: 'flex',
                flexDirection: isTextMultiLine ? 'column' : 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
                // },
                // hasPhoto && {
              },

              contentPaddings,

              // Gap to the top
              (hasPhoto || hasGif) &&
                hasText && {
                  marginTop: 4,
                },

              hasText && {
                paddingBottom: 5,
              },

              // // Overlay over photo mode
              // // In photos put it over
              // !hasText &&
              //   hasPhoto && {
              //     paddingLeft: 0,
              //     paddingRight: 2,
              //     position: 'absolute',
              //     bottom: 6,
              //     right: 7,
              //     zIndex: 3,
              //     backgroundColor: `rgba(0,0,0,0.6)`,
              //     borderRadius: 8,
              //     paddingVertical: 2,
              //   },
            ]}
          >
            <Text
              onLayout={(event) => {
                if (!isMounted.current) return
                setLineNumbers(Math.floor(event.nativeEvent.layout.height / 19))

                onCalculate?.()
              }}
              style={[
                {
                  maxWidth: messageMaxWidth,
                  fontSize: theme.fontSizes.normal,
                  color: theme.colors.text,
                  flex: 1,
                  lineHeight: 19,
                  // lineHeight: lineNumbers > 1 ? undefined : 19,
                  textAlignVertical: 'center',
                  overflow: 'hidden',
                  // @ts-ignore
                  cursor: 'text',
                  alignSelf: isTextMultiLine ? 'flex-start' : 'unset',
                },
                renderEmojiImproved && {
                  fontSize: 37,
                  lineHeight: 38,
                  letterSpacing: 4,
                },
                // Prevent it from making meta wrapper box bigger when we have it as overlay
                !hasText && { lineHeight: 0.1 },
              ]}
              selectable={true}
            >
              {/* {children} */}
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) =>
                  linkDecorator(decoratedHref, decoratedText, key)
                }
              >
                {children}
              </Linkify>
            </Text>

            {/* Meta info */}
            <MessageMeta
              isUs={isUs}
              largeEmojiMode={renderEmojiImproved}
              sendDate={sendTime}
              message={message}
              sent={sent}
              hasRead={hasRead}
            />
          </View>
        </View>
      </WebStyled>
    )
  },
  deepEqual,
)

export const Message = (props: Props) => {
  let { senderId, replyToMessageId } = props.message

  let user = useUser(senderId)
  let [replyMessage] = useNurNode<NewMessageInfo>({
    id: replyToMessageId || null,
  })

  let state: State = {
    replyMessage: replyMessage || undefined,
    sender: user ? minifyUserForProfilePhoto(user) : undefined,
  }

  return <MessageNode {...props} {...state} />
}

const linkDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number,
  // viewPhoto: () => void,
) => {
  return (
    <>
      <Text
        accessibilityRole="link"
        onPress={(event) => {
          if (isElectron) {
            event.preventDefault()
            electronApi?.openExternalUrl(decoratedHref)
          } else if (typeof window !== 'undefined') {
            window.open(decoratedHref, '_blank')
          }
        }}
        //@ts-ignore
        href={decoratedHref}
        style={{
          textDecorationLine: 'underline',
          textDecorationStyle: 'solid',
        }}
        key={key}
      >
        {decoratedText}
      </Text>
      {/* <OgComponent href={decoratedHref} /> */}
    </>
  )
}
