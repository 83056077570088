import { useCallback } from 'react'
import { useModals } from '../feed/ModalsContext'

export const useConfirm = (props: {
  alertIcon?: React.ReactNode
  alertText: string
  alertDescription?: string
  submitLabel?: string
  cancelLabel?: string
}) => {
  let {
    alertText,
    cancelLabel,
    submitLabel,
    alertDescription,
    alertIcon,
  } = props
  let [modalsState, modalsDispatch] = useModals()

  let openModal = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      modalsDispatch({
        type: 'modal opened',
        modalName: 'confirm',
        modalData: {
          alertIcon,
          alertText,
          cancelLabel,
          submitLabel,
          alertDescription,

          onSubmit: () => resolve(),
          onCancel: () => reject(),
        },
      })
    })
  }, [
    alertDescription,
    alertIcon,
    alertText,
    cancelLabel,
    modalsDispatch,
    submitLabel,
  ])

  return { openModal }
}
