import { a, TransitionFn, useTransition } from '@react-spring/native'
import { sideViewAtom } from '@there/components/atoms/contentViewAtom'
import { InviteButton } from '@there/components/main/InviteRow'
import { useAppContext } from '@there/components/shared/AppContext'
import { usePrevious } from '@there/components/shared/use-previous'
import { useInitialRender } from '@there/components/shared/useInitialRender'
import { ActiveSpaceName } from '@there/components/sidebar/ActiveSpace'
import { Banners } from '@there/components/sidebar/Banners'
import { SelfManager } from '@there/components/sidebar/SelfManager'
import {
  CHAT_ITEM_HEIGHT,
  SideChatItem,
} from '@there/components/sidebar/SideChatItem'
import {
  sidebarHorizontalPadding,
  sidebarRightPadding,
} from '@there/components/v2/Sidebar'
import { useRerenderEvery } from '@there/components/v2/useRerenderEvery'
import {
  DialogInfo,
  MemberWithUserInfo,
  UserInfo,
} from '@there/sun/utils/node-types'
import { useAtom } from 'jotai'
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import deepEqual from 'react-fast-compare'
import { ScrollView, Text, View } from 'react-native'
import { activeNewChatPeerAtom } from '../atoms/chatAtom'
import {
  onlineFirstUsersSortFunction,
  usersWithChatSortFunction,
} from '../feed/sort-users'
import { UpdateView } from '../main/UpdateView'
import { Space } from '../shared/Space'
import {
  NewChatWithPeer,
  useSpaceChatsContext,
} from '../shared/SpaceChatsContext'
import { useSpaceContext } from '../shared/spaceContext'
import { useUsersContext } from '../shared/UsersContext'
import { ChatPeer as ChatPeerOg } from '../types/chat'
import { AddPeople } from './AddPeople'
import { showMoreHeight, SideMoreButton } from './SideMoreButton'
import { SideSearchButton } from './SideSearchButton'
import { SideUserItem } from './SideUserItem'
import { StatusFeedButton } from './StatusFeedButton'
import { useSideMenu } from './use-side-menu'
type SideChatInput = {
  chats: NewChatWithPeer[]
  users: UserInfo[]
  setFilter: (value: string) => void
  isHome: boolean
  currentUserId: string | undefined
  currentUserTimezone: string | undefined | null
  getMemberByUserId: (userId: string) => MemberWithUserInfo | undefined
  isAdmin: boolean
  openMenu: (type: 'member' | 'group', id: string) => void
  activeSpaceId: string | null | undefined
  getChatByPeerId: (peerId: string) => NewChatWithPeer | undefined
  getDialogByUserId: (userId: string) => DialogInfo | null
}

const SideChatsContainer = memo(
  ({ children }: { children: (input: SideChatInput) => React.ReactNode }) => {
    let { chats, getChatByPeerId, allUsers } = useSpaceChatsContext()
    let {
      currentUserId,
      currentUser,
      activeMemberId,
      activeSpaceId,
      onlineFirstSide,
    } = useAppContext()
    let [filter, setFilter] = useState('')
    let { getMemberByUserId, members } = useUsersContext()
    let [{ space, getAvatarByUserId, getDialog }] = useSpaceContext()
    let isAdmin = currentUserId
      ? getMemberByUserId(currentUserId)?.role == 'Admin'
      : false
    let [sideView, setSideView] = useAtom(sideViewAtom)

    let isHome = sideView == 'home'

    let filteredAllUsers = useMemo(() => {
      let lowercaseFilter = filter?.toLowerCase()
      let usersArray = allUsers || []
      return usersArray
        .map((user) => {
          let chat = getChatByPeerId(user.id)
          return {
            ...user,
            chat,
          }
        })
        .filter((user) => {
          let filterBySearch =
            user.name?.toLowerCase().includes(lowercaseFilter) ||
            user.nickname?.toLowerCase().includes(lowercaseFilter)

          return filter ? filterBySearch : true
        })
        .sort(usersWithChatSortFunction({ currentUserId }))
    }, [allUsers, currentUserId, filter, getChatByPeerId])

    // users in sidebar in spaces
    let filteredUsers = useMemo(() => {
      let lowercaseFilter = filter?.toLowerCase()
      let membersArray = members || []
      return membersArray
        .map((member) => {
          let chat = getChatByPeerId(member.user.id)
          return {
            ...member.user,
            chat,
          }
        })
        .filter((user) => {
          let filterBySearch =
            user.name?.toLowerCase().includes(lowercaseFilter) ||
            user.nickname?.toLowerCase().includes(lowercaseFilter)

          return (
            // Check falsy
            filter ? filterBySearch : true
          )
        })
        .sort(
          onlineFirstSide
            ? onlineFirstUsersSortFunction({ currentUserId })
            : // sort by chats
              usersWithChatSortFunction({ currentUserId }),
        )
    }, [currentUserId, filter, getChatByPeerId, members, onlineFirstSide])

    // search in side chats
    let filteredChats = useMemo(() => {
      let lowercaseFilter = filter?.toLowerCase()

      return chats.filter((chat) => {
        let filterBySearch = chat.peerTopic?.title
          ?.toLowerCase()
          .includes(lowercaseFilter)

        return (
          // Check falsy
          !chat.hide &&
          chat.peerTopic &&
          (chat.peerTopic ? chat.peerTopic.spaceId === activeSpaceId : true) &&
          // Check input search
          (filter ? filterBySearch : true)
        )
      })
    }, [activeSpaceId, chats, filter])

    let [activePeer, setActiveChatPeer] = useAtom(activeNewChatPeerAtom)

    // ---------------
    // AUTO SWITCHER
    // -> If active chat is not in current space, switch to home

    useEffect(() => {
      // only check for DMs until we support multi space tabs
      if (!activePeer.peerUserId) return

      let userId = activePeer.peerUserId
      let isPartOfActiveSpace = Boolean(getMemberByUserId(userId))

      if (!isPartOfActiveSpace) {
        // Switch to home
        setSideView('home')
      }
    }, [activePeer.peerUserId, getMemberByUserId, setSideView])
    // --------------- /end

    // self messages dropdown
    let { openAdminMenu, openGroupMenu, openMemberMenu } = useSideMenu({
      activeSpaceId,
      getMemberByUserId,
    })

    const openMenu = useCallback(
      (type: 'member' | 'group', id: string) => {
        if (type === 'member') {
          // Don't show on self
          if (activeMemberId === id) return
          if (isAdmin) {
            openAdminMenu(id)
          } else {
            openMemberMenu(id)
          }
        } else {
          openGroupMenu(id)
        }
      },
      [activeMemberId, isAdmin, openAdminMenu, openGroupMenu, openMemberMenu],
    )

    const getDialogByUserId = useCallback(
      (userId: string) => {
        let avatar = getAvatarByUserId(userId)
        let dialog = avatar?.dialogId ? getDialog(avatar?.dialogId) : null
        return dialog || null
      },
      [getAvatarByUserId, getDialog],
    )

    return (
      <Fragment>
        {children({
          chats: filteredChats,
          users: isHome ? filteredAllUsers : filteredUsers,
          setFilter,
          currentUserId,
          currentUserTimezone: currentUser?.timezone,
          getMemberByUserId,
          isAdmin,
          isHome,
          openMenu,
          activeSpaceId,
          getChatByPeerId,
          getDialogByUserId,
        })}
      </Fragment>
    )
  },
)

const SideChatsUi = memo(
  ({
    chats,
    users,
    setFilter,
    currentUserId,
    currentUserTimezone,
    isAdmin,
    isHome,
    openMenu,
    activeSpaceId,
    getChatByPeerId,
    getMemberByUserId,
    getDialogByUserId,
  }: SideChatInput) => {
    useRerenderEvery('1m')
    let [activePeer, setActiveChatPeer] = useAtom(activeNewChatPeerAtom)
    let initialRender = useInitialRender()

    const [isMembersExpanded, setIsMembersExpanded] = useState<boolean>(false)
    const limitedUsers = isMembersExpanded ? users : users.slice(0, 7)
    const canExpand = users.length > 7

    let viewState = {
      spaceId: activeSpaceId,
      isHome,
      count: chats.length + limitedUsers.length,
    }
    let prevState = usePrevious(viewState)

    let hasDrasticallyChanged =
      Math.abs(viewState.count - (prevState?.count || 0)) > 1
    let hasViewChanged =
      viewState.isHome !== prevState?.isHome ||
      viewState.spaceId !== prevState?.spaceId
    let shouldAnimate =
      !hasDrasticallyChanged && !hasViewChanged && !initialRender

    // Animate side re-order
    let membersContainerHeight =
      limitedUsers.length * CHAT_ITEM_HEIGHT +
      (canExpand ? showMoreHeight + 10 : 0)
    let chatsContainerHeight = chats.length * CHAT_ITEM_HEIGHT
    let listContainerHeight =
      membersContainerHeight + (isHome ? 0 : chatsContainerHeight)

    const memberTransitions = useTransition(limitedUsers, {
      key: (item: UserInfo) => item.id,
      from: shouldAnimate ? { y: 0, opacity: 1 } : null,
      leave: shouldAnimate ? { y: 0, opacity: 0 } : null,
      enter: (_, index) => ({ y: index * CHAT_ITEM_HEIGHT, opacity: 1 }),
      update: (_, index) => ({ y: index * CHAT_ITEM_HEIGHT }),
      config: shouldAnimate
        ? {
            tension: 380,
            mass: 0.1,
            friction: 27,
          }
        : { duration: 0 },
    })

    let chatsTopOffset = membersContainerHeight + 26
    const transitions = useTransition(chats, {
      key: (item: NewChatWithPeer) => item.id,
      from: shouldAnimate ? { y: 0, opacity: 0 } : null,
      leave: shouldAnimate ? { y: 0, opacity: 0 } : null,
      enter: (_, index) => ({
        y: index * CHAT_ITEM_HEIGHT + chatsTopOffset,
        opacity: 1,
      }),
      update: (_, index) => ({
        y: index * CHAT_ITEM_HEIGHT + chatsTopOffset,
      }),
      config: shouldAnimate
        ? {
            tension: 380,
            mass: 0.1,
            friction: 27,
          }
        : { duration: 0 },
    })

    let scrollViewRef = useRef<ScrollView | null>(null)

    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            height: 42,
            paddingLeft: sidebarHorizontalPadding,
            paddingRight: sidebarRightPadding - 6, // 10 is invite button padding: ;
            marginTop: 4,
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <ActiveSpaceName isHome={isHome} />
          <Space horizontal={4} />
          {!isHome && (
            <SideSearchButton
              onValueChange={(value) => {
                setFilter(value)
              }}
            />
          )}
          {!isHome && <InviteButton />}
        </View>
        <StatusFeedButton />
        <Space vertical={4} />

        <ScrollView
          ref={scrollViewRef}
          style={{ paddingBottom: 20 }}
          showsVerticalScrollIndicator={false}
          scrollEventThrottle={200}
          contentContainerStyle={{
            height: listContainerHeight,
            position: 'relative',
          }}
          key={activeSpaceId}
        >
          {renderMembersWithTransition({
            activePeer,
            currentUserId,
            currentUserTimezone,
            isAdmin,
            openMenu,
            transition: memberTransitions,
            getChatByPeerId,
            getMemberByUserId,
            getDialogByUserId,
            isMembersExpanded,
            membersCounter: limitedUsers.length,
            expandMembers: () => {
              setIsMembersExpanded((prev) => !prev)
            },
            canExpand,
          })}
          {!isHome && (
            <>
              {renderChatsWithTransition({
                activePeer,
                currentUserId,
                currentUserTimezone,
                isAdmin,
                openMenu,
                transition: transitions,
              })}
            </>
          )}
        </ScrollView>
        <AddPeople />

        <Banners />
        <SelfManager />
        <UpdateView />
      </View>
    )
  },
  deepEqual,
)

export const SideChats = () => {
  return (
    <SideChatsContainer>
      {(props) => <SideChatsUi {...props} />}
    </SideChatsContainer>
  )
}

// Alias legacy
export type ChatPeer = ChatPeerOg

type ChatsTransition = TransitionFn<
  NewChatWithPeer,
  {
    y: number
    opacity: number
  }
>
type MemberTransition = TransitionFn<
  UserInfo,
  {
    y: number
    opacity: number
  }
>

type ActivePeer = {
  peerTopicId?: string | null
  peerUserId?: string | null
}

const renderMembersWithTransition = ({
  transition,
  activePeer,
  isMembersExpanded,
  membersCounter,
  isAdmin,
  currentUserId,
  currentUserTimezone,
  openMenu,
  getChatByPeerId,
  getMemberByUserId,
  getDialogByUserId,
  expandMembers,
  canExpand,
}: {
  transition: MemberTransition
  membersCounter: number
  isMembersExpanded: boolean
  isAdmin: boolean
  activePeer: ActivePeer
  currentUserId: string | undefined
  currentUserTimezone: string | undefined | null
  openMenu: (type: 'member' | 'group', id: string) => void
  getChatByPeerId: (peerId: string) => NewChatWithPeer | undefined
  getMemberByUserId: (userId: string) => MemberWithUserInfo | undefined
  getDialogByUserId: (userId: string) => DialogInfo | null
  expandMembers: () => void
  canExpand: boolean
}) => {
  return (
    <>
      {transition((style, item, t, index) => {
        let isActive = activePeer.peerUserId
          ? item.id === activePeer.peerUserId
          : false

        let chat = getChatByPeerId(item.id)

        return (
          <a.View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              // @ts-ignore
              willChange: 'transform',
              transform: [{ translateY: style.y }],
              opacity: style.opacity,
            }}
            key={item.id}
          >
            <Fragment>
              <SideUserItem
                key={item.id}
                isAdmin={isAdmin}
                chat={chat}
                isActive={isActive}
                topMessageId={chat?.topMessageId}
                isSelf={item.id === currentUserId}
                unreadCount={chat?.unreadCount || 0}
                currentUserTimezone={currentUserTimezone}
                user={item}
                openMenu={openMenu}
                getMemberByUserId={getMemberByUserId}
                dialog={getDialogByUserId(item.id) || null}
              />
              {canExpand && membersCounter === index + 1 && (
                <SideMoreButton
                  isActive={false}
                  isOpen={isMembersExpanded}
                  onClick={expandMembers}
                ></SideMoreButton>
              )}
            </Fragment>
          </a.View>
        )
      })}
    </>
  )
}

const renderChatsWithTransition = ({
  transition,
  activePeer,
  isAdmin,
  currentUserId,
  currentUserTimezone,
  openMenu,
}: {
  transition: ChatsTransition
  isAdmin: boolean
  activePeer: ActivePeer
  currentUserId: string | undefined
  currentUserTimezone: string | undefined | null
  openMenu: (type: 'member' | 'group', id: string) => void
}) => {
  return (
    <>
      {transition((style, item, t, index) => {
        let isActive = activePeer.peerUserId
          ? item.peerUserId === activePeer.peerUserId
          : activePeer.peerTopicId
          ? item.peerTopicId === activePeer.peerTopicId
          : false

        return (
          <a.View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              // @ts-ignore
              willChange: 'transform',
              transform: [{ translateY: style.y }],
              opacity: style.opacity,
            }}
            key={item.id}
          >
            <Fragment>
              {index === 0 && (
                <Text
                  style={{
                    fontSize: 13,
                    color: 'rgba(255, 255, 255, 0.37)',
                    fontWeight: 'bold',
                    paddingLeft: 16,
                    lineHeight: 16,
                    marginTop: -18,
                    paddingBottom: 2,
                  }}
                >
                  Chats
                </Text>
              )}
              <SideChatItem
                key={item.id}
                isAdmin={isAdmin}
                isActive={isActive}
                chat={item}
                topMessageId={item.topMessageId}
                isSelf={item.peerUser?.id === currentUserId}
                unreadCount={item.unreadCount}
                currentUserTimezone={currentUserTimezone}
                openMenu={openMenu}
              />
            </Fragment>
          </a.View>
        )
      })}
    </>
  )
}
