import {
  View,
  TouchableOpacity,
  StyleSheet,
  Text,
  Pressable,
} from 'react-native'
import { memo } from 'react'
import deepEqual from 'react-fast-compare'

import { useHover } from '@there/components/shared/use-hover'
import { sidebarHorizontalPadding } from '../v2/Sidebar'
import { useTheme } from '../feed/ThemeContext'
import { NewChatWithPeer } from '../shared/SpaceChatsContext'
import { activeNewChatPeerAtom } from '../atoms/chatAtom'
import {
  DialogInfo,
  MemberWithUserInfo,
  UserInfo,
} from '@there/sun/utils/node-types'
import { WebStyled } from '../shared/WebStyled'
import { useUpdateAtom } from 'jotai/utils'
import { useMainWindowContext } from '../shared/use-main-window'
import { ArrowDownIcon } from './ArrowDownIcon'

export const CHAT_ITEM_HEIGHT = 46
const INSET_PADDING = 6
export const showMoreHeight = 20

export const SideMoreButton = ({
  isActive,
  isOpen,
  onClick,
}: {
  isActive: boolean
  isOpen: boolean
  onClick?: () => void
}) => {
  let theme = useTheme()
  let [hovered, hoverListener] = useHover()

  let backgroundColor = hovered ? `rgba(255, 255, 255, 0.1)` : ''
  // : `rgba(58, 76, 98, 0.37)`

  return (
    <div
      style={{
        height: showMoreHeight,
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        alignItems: 'center',
        marginLeft: sidebarHorizontalPadding - INSET_PADDING,
        // marginRight: 6,
        paddingLeft: INSET_PADDING,
        paddingRight: INSET_PADDING,
        minWidth: 0,
        flexShrink: 1,

        borderRadius: 5,
        //@ts-ignore
        cursor: 'pointer',
        marginTop: 4,
        alignSelf: 'flex-start',

        backgroundColor: backgroundColor,
      }}
      onClick={() => {
        if (onClick) onClick()
      }}
      {...hoverListener}
    >
      <Text
        style={[
          {
            fontSize: theme.fontSizes.small,
            color: '#8A95A1',
            paddingRight: 5,
          },
        ]}
      >
        show {isOpen ? 'less' : 'more'}
      </Text>
      <ArrowDownIcon rotate={isOpen} style={{}} />
    </div>
  )
}

const styles = StyleSheet.create({
  secondRow: {
    flexDirection: 'row',
  },
})
