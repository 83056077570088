import { useNurMutation } from '@there/components/sun/use-mutation'
import { DeleteMemberMessage } from '@there/sun/modules/deleteMember'

interface Data {
  done: boolean
}

export const useDeleteMember = () => {
  return useNurMutation<Data, DeleteMemberMessage['payload']>({
    method: 'deleteMember',
  })
}
