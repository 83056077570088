import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg'

export const TimeSvgIcon = (props: SvgProps) => (
  <Svg width={14} height={14} fill="none" {...props}>
    <G clipPath="url(#a)" fill="#fff">
      <Path d="M7.583 1.196V.583a.583.583 0 0 0-1.166 0v.613a6.405 6.405 0 0 0-4.27 10.573 1.765 1.765 0 0 0-.98 1.648.583.583 0 1 0 1.166 0 .7.7 0 0 1 .648-.717c.04-.008.08-.02.117-.036a6.373 6.373 0 0 0 7.81 0c.035.015.07.028.107.037a.7.7 0 0 1 .652.716.583.583 0 1 0 1.166 0 1.764 1.764 0 0 0-.98-1.648 6.405 6.405 0 0 0-4.27-10.573ZM1.75 7.583A5.25 5.25 0 1 1 7 12.833a5.256 5.256 0 0 1-5.25-5.25ZM11.209 0a.583.583 0 1 0 0 1.167 1.51 1.51 0 0 1 1.624 1.458.583.583 0 0 0 1.167 0A2.684 2.684 0 0 0 11.209 0ZM1.167 2.625A1.51 1.51 0 0 1 2.79 1.167a.583.583 0 1 0 0-1.167A2.683 2.683 0 0 0 0 2.625a.583.583 0 0 0 1.167 0Z" />
      <Path d="M7.583 6.758V4.083a.583.583 0 1 0-1.166 0V7c0 .155.061.303.17.412l1.75 1.75a.583.583 0 0 0 .825-.824l-1.579-1.58Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h14v14H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export const MultiPlayerIcon = (props: SvgProps) => (
  <Svg width={13} height={14} fill="none" {...props}>
    <Path
      d="M4.5 5.571c1.657 0 3-1.247 3-2.785C7.5 1.247 6.157 0 4.5 0s-3 1.247-3 2.786c0 1.538 1.343 2.785 3 2.785ZM4.5 6.5C2.016 6.503.003 8.372 0 10.679c0 .256.224.464.5.464h8c.276 0 .5-.208.5-.464C8.997 8.372 6.984 6.503 4.5 6.5Z"
      fill="#fff"
    />
    <Path
      d="M7.5 7.929c1.897 0 3.5-1.437 3.5-3.286S9.397 1.357 7.5 1.357 4 2.794 4 4.643s1.603 3.286 3.5 3.286Z"
      fill="#fff"
      stroke="#242D39"
    />
    <Path
      d="M7.5 7.857c-2.725.003-4.997 2.062-5 4.678 0 .568.483.965 1 .965h8c.517 0 1-.397 1-.964-.003-2.617-2.275-4.676-5-4.679Z"
      fill="#fff"
      stroke="#242D39"
    />
  </Svg>
)
