import { a, useSpring } from '@react-spring/native'
import { useKeyContext } from '@there/components/feed/KeyContext'
import { SmallCrossIcon } from '@there/components/main/mainIcons'
import { Pressable } from '@there/components/shared/Pressable'
import { useWidth } from '@there/components/shared/use-width'
import { useEffect, useRef, useState } from 'react'
import { TextInput, View } from 'react-native'
import Svg, { Path, SvgProps } from 'react-native-svg'
import { useTheme } from '../feed/ThemeContext'
import { sidebarHorizontalPadding } from '../v2/Sidebar'

const INPUT_HEIGHT = 28

export const SideSearchButton = ({
  onValueChange,
}: {
  onValueChange: (value: string) => void
}) => {
  let theme = useTheme()
  let [value, setValue] = useState('')
  let [isFocus, setFocus] = useState(false)
  let inputRef = useRef<null | TextInput>(null)
  let [width, widthHandlers] = useWidth()

  let { registerKeyEvent, unregisterKeyEvent } = useKeyContext()

  useEffect(() => {
    registerKeyEvent({
      id: 'side-search',
      action: () => {
        inputRef.current?.focus()
      },
      key: 'f',
      ctrl: true,
    })

    return () => {
      unregisterKeyEvent({ id: 'side-search', key: 'f' })
    }
  }, [registerKeyEvent, unregisterKeyEvent])

  useEffect(() => {
    if (!inputRef || !inputRef.current) return
    if (!isFocus) return
    inputRef.current.focus()
  }, [isFocus])

  let offCenter = 28

  let isActive = isFocus || value.length > 0
  let styleProps = useSpring({
    opacity: width ? 1 : 0,
    config: {
      mass: 0.3,
      tension: 300,
      friction: 20,
    },
  })

  let crossProps = useSpring({
    scale: isFocus || value ? 1 : 0,
    delay: 200,
    config: {
      mass: 0.3,
      tension: 300,
      friction: 20,
    },
  })

  return (
    <View
      style={{
        height: INPUT_HEIGHT,
        // flex: 1,
        flexShrink: 0,
        position: isActive ? 'absolute' : 'relative',
        right: isActive ? 10 : undefined, // invite button + right padding
        left: isActive ? sidebarHorizontalPadding : undefined,
        zIndex: 3,
      }}
      {...widthHandlers}
    >
      <Pressable
        style={{
          //@ts-ignore
          cursor: isActive ? 'text' : 'pointer',
        }}
        onPress={() => {
          inputRef.current?.focus()
        }}
      >
        {({ pressed, hovered }) => (
          <a.View
            style={{
              backgroundColor: isActive ? '#38495e' : undefined,
              opacity: width ? 1 : 0,
              height: '100%',
              width: '100%',
              borderRadius: 8,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <View
              style={[
                { paddingLeft: isActive ? 8 : 0 },
                pressed && { opacity: 0.9, transform: [{ scale: 0.95 }] },
              ]}
            >
              <SearchIcon
                color={
                  hovered
                    ? theme.colors.tertiaryLight
                    : theme.colors.quaternaryText
                }
                width={18}
                style={{ flexShrink: 0 }}
              />
            </View>
            <TextInput
              ref={inputRef}
              style={{
                lineHeight: INPUT_HEIGHT,
                color: theme.colors.secondaryText,
                width: isActive ? '100%' : 0,
                paddingLeft: 6,
              }}
              placeholder="Find"
              placeholderTextColor={theme.colors.quinaryText}
              value={value}
              onKeyPress={(event) => {
                //@ts-ignore
                if (event.key == 'Escape') {
                  setFocus(false)
                  inputRef.current?.blur()
                }
              }}
              onFocus={() => {
                setFocus(true)
              }}
              onChangeText={(text) => {
                setValue(text)
                onValueChange(text)
              }}
            />
            <a.View
              style={{
                transform: [{ scale: crossProps.scale }],
                opacity: isFocus || value ? 1 : 0,
                display: isActive ? 'flex' : 'none',
                paddingRight: isActive ? 5 : 0,
              }}
            >
              <Pressable
                style={{
                  width: 14,
                  height: 14,
                  borderRadius: 16,
                  backgroundColor: 'rgba(236, 195, 195, 0.1)',

                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={() => {
                  setFocus(false)
                  setValue('')
                  onValueChange('')
                }}
              >
                <SmallCrossIcon color={theme.colors.tertiaryText} />
              </Pressable>
            </a.View>
          </a.View>
        )}
      </Pressable>
    </View>
  )
}

export function SearchIcon(props: SvgProps) {
  return (
    <Svg width={19} height={19} viewBox="0 0 19 19" fill="none" {...props}>
      <Path
        d="M13.82 7.29a6.52 6.52 0 01-6.53 6.53 6.54 6.54 0 116.53-6.53zm-2.15 5.04l6 6"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
      />
    </Svg>
  )
}
