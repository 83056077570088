import { useNurMutation } from '@there/components/sun/use-mutation'
import type { JoinDialogMessage } from '@there/sun/modules/joinDialog'
import { NodeUpdateInfo } from '@there/sun/utils/node-types'
import { SunErrorCodes } from '@there/sun/ws/errors'
import { useEffect } from 'react'
interface Data {
  updates: NodeUpdateInfo[]
}

type RemoteVariables = JoinDialogMessage['payload']

export interface LocalJoinDialogVariables extends RemoteVariables {
  __extra: {
    oldDialogId: string | undefined | null
    avatarId: string
    spaceId: string
  }
}

export const useJoinDialog = () => {
  let result = useNurMutation<Data, LocalJoinDialogVariables>({
    method: 'joinDialog',
  })

  let errorCode = result[0].error?.SunError?.code

  useEffect(() => {
    if (errorCode === SunErrorCodes.ROOM_FULL) {
      alert('Cannot join room with more than 6 people.')
      console.info('Cannot join room with more than 6 people.')
    }
  }, [errorCode])

  return result
}
