import { StyleSheet, TextInput, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { Space } from '../shared/Space'
import { ConfigType } from './use-picker'
import { useEffect, useRef, useState } from 'react'
import { SearchIcon } from '../sidebar/SideSearch'
import { a, useSpring } from '@react-spring/native'
import { useWidth } from '../shared/use-width'
import { Pressable } from '../shared/Pressable'
import { SmallCrossIcon } from '../main/mainIcons'

export const SearchInput = ({
  config,
  searchInput,
  onSearchChanges,
}: {
  config: ConfigType
  searchInput: string
  onSearchChanges: (input: string) => void
}) => {
  const t = useTheme()
  let searchInputRef = useRef<TextInput | null>(null)
  let [isFocus, setFocus] = useState(false)
  let width = config.containerWidth + config.emojiMargin * 2
  useEffect(() => {
    if (!searchInputRef || !searchInputRef.current) return
    if (!isFocus) return
    searchInputRef.current.focus()
  }, [isFocus])

  let offCenter = 40
  let styleProps = useSpring({
    x: width
      ? isFocus || searchInput
        ? 10
        : width / 2 - offCenter
      : undefined,
    config: {
      mass: 0.3,
      tension: 300,
      friction: 20,
    },
  })

  let crossProps = useSpring({
    scale: isFocus || searchInput ? 1 : 0,
    delay: 200,
    config: {
      mass: 0.3,
      tension: 300,
      friction: 20,
    },
  })

  return (
    <View
      style={{
        height: styles.searchBox.height,
        flex: 1,
      }}
    >
      <Pressable
        style={{
          //@ts-ignore
          cursor: 'text',
        }}
        onPress={() => {
          searchInputRef.current?.focus()
        }}
        disabled={isFocus}
      >
        <a.View
          style={[
            styles.searchBox,
            {
              width: config.containerWidth - config.emojiMargin,
              marginHorizontal: t.spaces.sidePaddingNarrow,
              borderRadius: t.borderRadius.searchInput,
              backgroundColor: t.colors.emojiPickerSearchBackground,
              paddingLeft: styleProps.x,
              paddingRight: 8,
            },
          ]}
        >
          <View style={[styles.searchIcon]}>
            <SearchIcon
              width={14}
              color={t.colors.emojiPickerSearchIcon}
              style={{ flexShrink: 0 }}
            />
          </View>
          <Space horizontal={5} />
          <TextInput
            ref={searchInputRef}
            onKeyPress={(event) => {
              //@ts-ignore
              if (event.code === 'Escape') {
                event.preventDefault()
                searchInputRef.current?.blur()
                setFocus(false)
                onSearchChanges('')
              }
            }}
            onFocus={() => {
              setFocus(true)
            }}
            style={[
              styles.searchInput,
              {
                color: t.colors.secondaryText,
                fontSize: t.fontSizes.normal,
                lineHeight: styles.searchBox.height,
                width: '100%',
              },
            ]}
            placeholderTextColor={t.colors.emojiPickerSearchText}
            placeholder="Search"
            numberOfLines={1}
            value={searchInput}
            onChangeText={(value: string) => onSearchChanges(value)}
          />
          <a.View
            style={{
              transform: [{ scale: crossProps.scale }],
              opacity: isFocus || searchInput ? 1 : 0,
            }}
          >
            <Pressable
              style={{
                width: 14,
                height: 14,
                borderRadius: 16,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() => {
                setFocus(false)
                onSearchChanges('')
              }}
            >
              <SmallCrossIcon color={t.colors.tertiaryText} />
            </Pressable>
          </a.View>
        </a.View>
      </Pressable>
    </View>
  )
}
const styles = StyleSheet.create({
  searchBox: {
    height: 31,
    padding: 5,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchIcon: {
    width: 16,
    height: 31,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchInput: {},
})
