import { View } from 'react-native'
import { useModals } from '../feed/ModalsContext'
import { Space } from '../shared/Space'
import { AddPeopleCopyButton } from './AddPeopleCopyButton'
import { AddPeopleEmailInput } from './AddPeopleEmailInput'
import { Header } from './Header'
import { HelpText } from './HelpText'
import { ModalLabel } from './ModalLabel'
import { ModalWrapper } from './ModalWrapper'

export const AddPeopleModal = () => {
  let [{ modals }, modalsDispatch] = useModals()

  const onClose = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'invite' })
  }
  return (
    <ModalWrapper isOpen={modals.includes('invite')} onClose={onClose}>
      <View style={{ width: 220 }}>
        <Header label="Invite teammates" onClose={onClose} />
        <Space vertical={15} />
        <AddPeopleCopyButton />
        <Space vertical={4} />
        <HelpText text="Anyone with the link can join" />
        <Space vertical={10} />
        <ModalLabel text="Invite by email" />
        <Space vertical={5} />
        <AddPeopleEmailInput onClose={onClose} />
      </View>
    </ModalWrapper>
  )
}
