import { PresenceInfo } from '@there/sun/utils/node-types'
import ms from 'ms'
import { useMemo } from 'react'
import { getRelativeDate, getRelativeTime } from '../main/get-last-seen'

export function useUserStatus({
  presence,
  isOnline,
  lastOnline,
  status,
  refreshKey,
}: {
  presence?: PresenceInfo | null
  isOnline: boolean
  lastOnline: number | null | undefined
  status?: string | undefined | null
  refreshKey?: string | number | undefined | null | boolean
}) {
  return useMemo(() => {
    let isWorking = presence?.working

    let long = ''
    let short = ''

    if (status) {
      long = status
    }

    if (isWorking && isOnline) {
      short = ''
    }

    if (!isOnline && lastOnline) {
      let now = new Date()
      let lastOnlineDate = new Date(lastOnline)
      let ago = getRelativeTime(now, new Date(lastOnline))
      if (!ago || now.getTime() - lastOnlineDate.getTime() >= ms('24h')) {
        short = ''
      } else {
        short = `(${ago})`
      }
    }

    if (refreshKey) {
    }

    return [short, long]
  }, [
    isOnline,
    lastOnline,
    presence?.working,
    status,
    // used for refreshing
    refreshKey,
  ])
}
