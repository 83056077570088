import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

function SvgComponent(props: SvgProps) {
  return (
    <Svg width={7} height={12} viewBox="0 0 7 12" fill="none" {...props}>
      <Path
        d="M5.5 1.5L1 6l4.5 4.5"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SvgComponent
