import { NexusGenFieldTypes } from '@there/tower/generated/graphql'
import gql from 'graphql-tag'
import { useMutation } from 'urql'

export const uploadDocument = gql`
  mutation uploadDocument($file: Upload!) {
    documentInfo: uploadDocument(file: $file) {
      id
      type
      fileExt
      fileName
      size
      mediaW
      mediaH
      objectId
      thumbObjectId
      compressed
      createdAt
    }
  }
`

export type Variables = {
  file: File
}

type Source = NexusGenFieldTypes['UploadDocument']

export interface ChatUploadDocumentInfo {
  __typename?: 'ChatUploadFile'
  id: Source['id']
  type: Source['type']
  fileExt: Source['fileExt']
  fileName: Source['fileName']
  size: Source['size']
  mediaW: Source['mediaW']
  mediaH: Source['mediaH']
  objectId: Source['objectId']
  thumbObjectId: Source['thumbObjectId']
  compressed: Source['compressed']
  createdAt: Source['createdAt']
  remoteUrl: Source['remoteUrl']
  remotePreview: Source['remotePreview']
}

export type Data = {
  documentInfo?: ChatUploadDocumentInfo
}

export const useChatUploadDocument = () =>
  useMutation<Data, Variables>(uploadDocument)
