import {
  currentPlatform,
  electronSupports,
  ipc,
  isMac,
} from '@there/desktop/utils/electron-api'
import { getDefaultKeymap } from '@there/shared/desktop/default-shortcuts'
import { Draft } from 'immer'
import { atom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useImmerReducer } from 'use-immer'

type Keymap = Record<ShortcutActions, string | null | undefined>

// /////// Reducer ///////// //
type State = {
  keymap: Keymap
}
type Action =
  | {
      type: 'shortcut picked'
      actionKey: ShortcutActions
      selectedOption: string | null | undefined
    }
  | { type: 'keymap loaded'; keymap: Keymap }
const initialState: State = {
  keymap: getDefaultKeymap({ platform: currentPlatform }),
}

function reducer(draft: Draft<State>, action: Action): void | State {
  switch (action.type) {
    case 'shortcut picked': {
      //check for duplicates
      let duplicateResolver = {}
      for (let [actionKey, shortcut] of Object.entries(draft.keymap)) {
        if (shortcut === action.selectedOption) {
          duplicateResolver = { [actionKey]: null }
        }
      }

      return {
        ...draft,
        keymap: {
          ...draft.keymap,
          ...duplicateResolver,
          [action.actionKey]: action.selectedOption,
        },
      }
    }

    case 'keymap loaded': {
      draft.keymap = action.keymap
      break
    }

    default:
      return draft
  }
}

type ContextType = {
  state: State
  defaultComboOptions: typeof options
  toReadableShortcut: (option: string) => string
  getShortcutForAction: (action: ShortcutActions) => string
  shortcutPicked: (
    actionKey: ShortcutActions,
    selectedOption: string | null | undefined,
  ) => void
  subscribeToEscape: (handler: () => void) => void
  unsubscribeFromEscape: (handler: () => void) => void
  getEscapeSubscribersCount: () => number
}
const initialValue: ContextType = {
  state: initialState,
  defaultComboOptions: [],
  shortcutPicked: () => {},
  getShortcutForAction: () => '',
  toReadableShortcut: (e) => e,
  subscribeToEscape: () => {},
  unsubscribeFromEscape: () => {},
  getEscapeSubscribersCount: () => 0,
}
export const Context = createContext<ContextType>(initialValue)

export const useShortcutManager = () => {
  return useContext(Context)
}

export type ShortcutItemType = string | null
let capsLockForMac = electronSupports?.capsLockShortcut ? ['f13'] : []
let commonOptions = ['alt+s', 'alt+shift+s', 'alt+a', 'alt+l']
let macOptions = [
  ...commonOptions,
  'cmd+j',
  'cmd+k',
  'cmd+l',
  ...capsLockForMac,
]
let linuxOptions = [...commonOptions, 'ctrl+j', 'ctrl+k', 'ctrl+l']
let windowsOptions = ['ctrl+j', 'ctrl+k', 'ctrl+a', 'ctrl+l', ...commonOptions]
let options = [
  null,
  ...(currentPlatform === 'windows'
    ? windowsOptions
    : currentPlatform === 'mac'
    ? macOptions
    : currentPlatform === 'linux'
    ? linuxOptions
    : commonOptions),
]

let keyToReadableName: Record<string, string> = {
  ctrl: 'Control',
  capslock: 'Caps Lock',
  f13: 'Caps Lock',
  cmd: '⌘',
  shift: 'Shift',
  alt: isMac ? 'Option' : 'Alt',
  option: 'Option',
  trayClick: 'Icon Click',
}

export function toReadableShortcut(option: string) {
  let keys = option.split('+')
  return keys
    .map((key) => keyToReadableName[key] || key.toUpperCase())
    .join(' + ')
}

export type ShortcutActions =
  | 'toggleMicrophone'
  | 'toggleCamera'
  | 'toggleVoice'
  | 'toggleWindowVisible'

export const ShortcutManagerProvider = memo(
  ({
    children,
    keyHandlersFallback,
  }: {
    children: React.ReactNode
    keyHandlersFallback: Record<KeyboardEvent['key'], () => void>
  }) => {
    const [state, dispatch] = useImmerReducer<State, Action>(
      reducer,
      initialState,
    )

    const shortcutPicked = useCallback(
      (
        actionKey: ShortcutActions,
        selectedOption: string | null | undefined,
      ) => {
        dispatch({ type: 'shortcut picked', actionKey, selectedOption })
      },
      [dispatch],
    )

    // capture/release capslock
    useEffect(() => {
      let hasCapslock = false
      for (let key of Object.values(state.keymap)) {
        if (key === 'f13') {
          hasCapslock = true
          break
        }
      }
      if (hasCapslock) {
        ipc?.invoke('capture-capslock')
      } else {
        ipc?.invoke('release-capslock')
      }
    }, [state.keymap])

    let hasLoaded = useRef(false)

    // Save
    useEffect(() => {
      if (!hasLoaded.current) return
      ipc?.invoke('there:keymap-updated', state.keymap)
      localStorage.setItem('keyMap', JSON.stringify(state.keymap))
    }, [state.keymap])

    // Load
    useEffect(() => {
      let raw = localStorage.getItem('keyMap')
      if (hasLoaded.current) return
      if (raw) {
        try {
          dispatch({ type: 'keymap loaded', keymap: JSON.parse(raw) })
        } catch (error) {}
      }
      hasLoaded.current = true
    }, [dispatch])

    // Handle Escape
    let escapeSubscribers = useRef<(() => void)[]>([])
    useEffect(() => {
      if (typeof window === 'undefined') return

      const keyPressed = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          if (
            !escapeSubscribers.current ||
            escapeSubscribers.current.length === 0
          ) {
            keyHandlersFallback['Escape'] && keyHandlersFallback['Escape']()
            return
          }
          let subscriber = escapeSubscribers.current.pop()
          try {
            if (typeof subscriber === 'function') {
              subscriber()
            }
          } catch (_) {}
        }
      }
      window.addEventListener('keydown', keyPressed)

      return () => {
        window.removeEventListener('keydown', keyPressed)
      }
    }, [keyHandlersFallback])

    const { subscribeToEscape, unsubscribeFromEscape } = useMemo(() => {
      let unsubscribeFromEscape = (handler: () => void) => {
        if (!escapeSubscribers.current) return
        escapeSubscribers.current = escapeSubscribers.current.filter(
          (existingHandler) => existingHandler !== handler,
        )
      }
      let subscribeToEscape = (handler: () => void) => {
        unsubscribeFromEscape(handler)
        escapeSubscribers.current.push(handler)
      }
      return { unsubscribeFromEscape, subscribeToEscape }
    }, [])

    let setShortcutManagerAtom = useUpdateAtom(shortcutManagerAtom)
    useEffect(() => {
      setShortcutManagerAtom({
        escapeSubscribersRef: escapeSubscribers,
      })
    }, [setShortcutManagerAtom])

    let value = useMemo(
      () => ({
        state,
        defaultComboOptions: options,
        getShortcutForAction: (action: ShortcutActions) => {
          let option = state.keymap[action]
          return option ? toReadableShortcut(option) : ''
        },
        toReadableShortcut,
        shortcutPicked,
        subscribeToEscape,
        unsubscribeFromEscape,
        getEscapeSubscribersCount: () => escapeSubscribers.current.length,
      }),
      [shortcutPicked, state, subscribeToEscape, unsubscribeFromEscape],
    )

    return <Context.Provider value={value}>{children}</Context.Provider>
  },
)

const shortcutManagerAtom = atom<{
  escapeSubscribersRef: { current: any[] }
}>({ escapeSubscribersRef: { current: [] } })

export const useShortcutManagerAtom = () => {
  return useAtomValue(shortcutManagerAtom)
}
