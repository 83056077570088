import { NexusGenFieldTypes } from '@there/tower/generated/graphql'
import gql from 'graphql-tag'
import { AvatarInfo, AvatarWithUserInfo } from './avatarInfo'

export const dialogInfoFragment = gql`
  fragment dialogInfo on Dialog {
    id
    doorClosed
    avatarCount
    spaceId
    direct
    bgObjectId
  }
`

type Source = NexusGenFieldTypes['Dialog']

export interface DialogInfo {
  __typename?: 'Dialog'
  id: Source['id']
  doorClosed: Source['doorClosed']
  avatarCount: Source['avatarCount']
  spaceId: Source['spaceId']
  direct: Source['direct']
  bgObjectId: Source['bgObjectId']
}

export interface DialogWithAvatarWithUserInfo extends DialogInfo {
  avatars: AvatarWithUserInfo[]
}
export interface DialogWithAvatarInfo extends DialogInfo {
  avatars: AvatarInfo[]
}

export const partialDialogInfoFragment = gql`
  fragment partialDialogInfo on PartialDialog {
    id
    doorClosed
    avatarCount
    spaceId
    direct
    bgObjectId
  }
`

export interface PartialDialogInfo {
  __typename?: 'Dialog'
  id: Source['id']
  doorClosed?: Source['doorClosed']
  avatarCount?: Source['avatarCount']
  spaceId?: Source['spaceId']
  direct?: Source['direct']
  bgObjectId?: Source['bgObjectId']
}
