import { Dispatch, useEffect, useMemo, useState } from 'react'

export const usePersistedReducerState = <State = any, Action = any>(
  args: [State, Dispatch<Action>],
  options: { key: string },
): [State, React.Dispatch<Action>] => {
  let [state, dispatch] = args
  let { key } = options
  const [hydrated, setHydrated] = useState(false)

  // Load state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    const stateJson = localStorage.getItem(key)

    if (
      !stateJson ||
      typeof stateJson !== 'string' ||
      !stateJson.startsWith('{')
    ) {
      setHydrated(true)
      return
    }

    // Hydrate
    // @ts-ignore
    dispatch({ type: 'load state', state: JSON.parse(stateJson) })
    setHydrated(true)
  }, [dispatch, key])

  // Persist state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    // Don't replace our cache with empty state
    if (!hydrated) {
      return
    }

    localStorage.setItem(key, JSON.stringify(state))
  }, [hydrated, key, state])

  return useMemo(() => [state, dispatch], [dispatch, state])
}
