import { View, Text } from 'react-native'
import { RecommendedMicModalData, useModals } from '../feed/ModalsContext'
import { StandardButton } from '../main/StandardButton'
import { Space } from '../shared/Space'
import { ModalDescription } from './ModalDescription'
import { ModalTitle } from './ModalTitle'
import { ModalWrapper } from './ModalWrapper'

export const RecommendedMicModal = () => {
  let [modalsState, modalsDispatch] = useModals()
  const onClose = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'recommendedMic' })
  }
  let modalData = modalsState.modalsData[
    'recommendedMic'
  ] as RecommendedMicModalData

  return (
    <ModalWrapper
      isOpen={modalsState.modals.includes('recommendedMic')}
      // isOpen={true}
      onClose={onClose}
    >
      <View
        style={{
          width: 215,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalTitle align="center">AirPods detected</ModalTitle>
        <Space vertical={5} />
        <ModalDescription align="center">
          We recommend you to change your mic to internal for better quality.
        </ModalDescription>
        <Space vertical={10} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <StandardButton
            text="Cancel"
            backgroundColor="buttonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={() => {
              if (modalData) {
                modalData.onCancel()
              }
              onClose()
            }}
          />
          <Space horizontal={10} />
          <StandardButton
            text="Switch Internal"
            backgroundColor="primaryButtonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={() => {
              if (modalData) {
                modalData.onSubmit()
              }
              onClose()
            }}
          />
        </View>
      </View>
    </ModalWrapper>
  )
}
