import { StatusesQueryReply } from '@there/sun/modules/statuses'
import { useEffect } from 'react'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { SpaceData, useMemberships } from '../feed/useMemberships'
import { useReadStatus } from '../feedBox/useReadStatus'
import { useAppContext } from '../shared/AppContext'
import { StatusInfoWithSpaceIds, useStatuses } from './useStatuses'

type StatusesContextType = {
  statuses: StatusInfoWithSpaceIds[]
  getStatusSpace: (status: StatusInfoWithSpaceIds) => SpaceData[]
  markRead: () => void
  hasUnreadStatus: boolean
  maxReadAt: number
  fetchMoreStatuses: () => void
  statusToReplies: Record<string, string[]>
  getStatusReplies: (statusId: string) => StatusInfoWithSpaceIds[]
  refetch: () => void
}

const initialContext: StatusesContextType = {
  statuses: [],
  getStatusSpace: () => [],
  hasUnreadStatus: false,
  markRead: () => {},
  maxReadAt: 0,
  fetchMoreStatuses: () => {},
  statusToReplies: {},
  getStatusReplies: () => [],
  refetch: () => {},
}

export const StatusesContext = createContext<StatusesContextType>(
  initialContext,
)

export const useStatusesContext = () =>
  useContext<StatusesContextType>(StatusesContext)

export const useStatusManager = () => {
  let {
    statuses,
    getStatusSpace,
    fetchMoreStatuses,
    statusToReplies,
    refetch,
  } = useStatuses()

  const getStatusReplies = useCallback(
    (statusId) => {
      return statuses
        .filter((s) => s.replyToStatusId === statusId)
        .sort((a, b) => a.createdAt - b.createdAt)
    },
    [statuses],
  )

  let { markRead, maxReadAt } = useReadStatus()
  let { currentUserId } = useAppContext()

  let firstStatus = statuses[0]
  let hasUnreadStatus = useMemo(() => {
    if (
      firstStatus &&
      firstStatus.userId === currentUserId &&
      firstStatus.createdAt > maxReadAt
    ) {
      markRead()
      return false
    }
    if (firstStatus && firstStatus.createdAt > maxReadAt) {
      return true
    }

    return false
  }, [currentUserId, firstStatus, markRead, maxReadAt])

  let value = useMemo(() => {
    return {
      statuses,
      getStatusSpace,
      markRead,
      hasUnreadStatus,
      maxReadAt,
      fetchMoreStatuses,
      statusToReplies,
      getStatusReplies,
      refetch,
    }
  }, [
    statuses,
    getStatusSpace,
    markRead,
    hasUnreadStatus,
    maxReadAt,
    fetchMoreStatuses,
    statusToReplies,
    getStatusReplies,
    refetch,
  ])

  return value
}
