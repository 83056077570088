import { NotificationDropdown } from '@there/components/dropdown/NotificationDropdown'
import { RefreshIcon } from '@there/components/feed/HeaderIcons'
import { CircleButton } from '@there/components/main/CircleButton'
import { SettingsDropdown } from '@there/components/settings/SettingsDropdown'
import { Tooltip } from '@there/components/shared/Tooltip'
import { HelpMenu } from '@there/components/main/HelpMenu'
import { logEvent } from '@there/components/shared/use-analytics'
import React from 'react'
import { View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { Space } from '@there/components/shared/Space'

let gap = 2

export const HomeHeader = () => {
  let theme = useTheme()

  return (
    <View style={{ flexDirection: 'row' }}>
      <NotificationDropdown />
      <Space horizontal={gap} />
      <Tooltip
        label={
          <>
            Reload <span className="light">• CMD + R</span>
            <br />{' '}
            <span className="light">
              While in beta, hit Reload
              <br /> to fix any issue.
            </span>
          </>
        }
        placement="bottomLeft"
      >
        <CircleButton
          backgroundMode="transparent"
          onPress={() => {
            logEvent('User Clicked Reload')
            window.location.reload()
          }}
        >
          <RefreshIcon
            color={theme.colors.tertiaryLight}
            width={16}
            height={16}
          />
        </CircleButton>
      </Tooltip>
      <Space horizontal={gap} />
      <HelpMenu />
      <Space horizontal={gap} />
      <SettingsDropdown />
    </View>
  )
}
