import { electronApi, isElectron } from '@there/desktop/utils/electron-api'
import { useCallback, useMemo } from 'react'
import { usePersistedState } from '../hooks/usePersistState'
import { EmojiView } from '../main/EmojiView'
import { useAlert } from '../modal/use-alert'
import { useCurrentMediaDevicesContext } from '../shared/CurrentMediaDevicesContext'
import { useLatest } from '../shared/use-latest'
import { useMediaDevices } from '../shared/use-media-devices'
import { useRtcContext } from '../shared/use-rtc'
import { useWindowContext } from '../shared/WindowContext'

const BlackHoleGuide =
  'https://there.notion.site/Share-System-Audio-46d81e70b6f14602bf540d7a27d7e05e'

export const useSystemAudio = () => {
  const {
    systemAudioDevice,
    dispatch,
    currentSpeaker,
  } = useCurrentMediaDevicesContext()
  const { microphones, speakers } = useMediaDevices()
  const { isMacOs, isWindows } = useWindowContext()
  let [
    autoSelectedSystemAudioDevice,
    setAutoSelectedSystemAudioDevice,
  ] = usePersistedState<boolean>('systemAudio-autoSelected', false)

  let { openModal: openBlackHoleModal } = useAlert({
    alertText: 'Install an extension',
    alertDescription: `To share system audio, you need to do this manual step only once. Follow this short guide and you'll be good to go in 5 minutes.`,
    alertIcon: <EmojiView size={34} children="🎵" />,
    submitLabel: 'Open Guide',
  })

  let { blackHoleMic, loopBackMic, soundFlowerMic } = useMemo(() => {
    let blackHoleMic, soundFlowerMic, loopBackMic

    for (let microphone of microphones) {
      let name = microphone.name.toLowerCase()
      if (name.includes('blackhole')) {
        blackHoleMic = microphone
        continue
      }
      if (name.includes('soundflower')) {
        soundFlowerMic = microphone
        continue
      }
      if (name.includes('loopback')) {
        loopBackMic = microphone
        continue
      }
    }
    return { blackHoleMic, soundFlowerMic, loopBackMic }
  }, [microphones])

  let {
    isBlackHoleSpeakerSelected,
    isLoopBackSpeakerSelected,
    isMultiOutputSpeakerSelected,
    isSoundFlowerSpeakerSelected,
  } = useMemo(() => {
    let isBlackHoleSpeakerSelected,
      isMultiOutputSpeakerSelected,
      isSoundFlowerSpeakerSelected,
      isLoopBackSpeakerSelected

    for (let speaker of speakers) {
      if (speaker.id !== 'default') continue
      let name = speaker.name.toLowerCase()
      if (name.includes('multi-output')) {
        isMultiOutputSpeakerSelected = true
        continue
      }
      if (name.includes('blackhole')) {
        isBlackHoleSpeakerSelected = true
        continue
      }
      if (name.includes('soundflower')) {
        isSoundFlowerSpeakerSelected = true
        continue
      }
      if (name.includes('loopback')) {
        isLoopBackSpeakerSelected = true
        continue
      }
    }
    return {
      isBlackHoleSpeakerSelected,
      isMultiOutputSpeakerSelected,
      isSoundFlowerSpeakerSelected,
      isLoopBackSpeakerSelected,
    }
  }, [speakers])

  // MacOs sharing system audio virtual speakers
  let isMacOsSharingSpeakerSelected =
    isBlackHoleSpeakerSelected ||
    isSoundFlowerSpeakerSelected ||
    isLoopBackSpeakerSelected

  // Windows sharing system audio virtual speakers
  let isWindowsSharingSpeakerSelected = isLoopBackSpeakerSelected

  let isSharingSpeakerSelected = isMacOs
    ? isMacOsSharingSpeakerSelected
    : isWindows
    ? isWindowsSharingSpeakerSelected
    : false

  // To check if selected Noor's output device is a virtual speaker
  let isNoorSpeakerSetToSharingSpeaker = useMemo(() => {
    return (
      currentSpeaker?.name.toLowerCase().includes('blackhole') ||
      currentSpeaker?.name.toLowerCase().includes('soundflower') ||
      currentSpeaker?.name.toLowerCase().includes('loopback')
    )
  }, [currentSpeaker])

  // toggleSystemAudioSharingState: only changes weSharingSystemAudio state
  const { toggleSystemAudioSharingState, walkieState } = useRtcContext()
  const weSharingSystemAudio = walkieState.weSharingSystemAudio

  let autoSelectedSystemAudioDeviceRef = useLatest(
    autoSelectedSystemAudioDevice,
  )

  // when sharing audio started but the sharing audio speaker did not selected
  // we use this to select it automatically and deselect it when sharing stops
  const autoSelectSystemAudioDevice = useCallback(
    (micDevice) => {
      dispatch({
        type: 'system audio device changed',
        deviceId: micDevice.id,
        name: micDevice.name,
      })
      toggleSystemAudioSharingState()
      // use this to detect if its selected automatically
      setAutoSelectedSystemAudioDevice(true)
    },
    [dispatch, setAutoSelectedSystemAudioDevice, toggleSystemAudioSharingState],
  )

  // this is a middleware to check mic conditions before toggle weSharingState using toggleSystemAudioSharingState
  const toggleSystemAudio = useCallback(() => {
    // 1. disable sharing if we are sharing system audio
    if (weSharingSystemAudio) {
      toggleSystemAudioSharingState()
      // if we selected system audio device automatically -> deselect it
      if (autoSelectedSystemAudioDeviceRef.current) {
        dispatch({
          type: 'system audio device changed',
          deviceId: undefined,
          name: undefined,
        })
      }
      return
    }

    // 2. we selected a virtual device as a system audio mic manually -> start sharing
    if (systemAudioDevice) {
      toggleSystemAudioSharingState()
      return
    }

    // 3. we are not selected a system audio device manually

    // 3.1 we detected a suitable virtual device to share system audio
    // select it automatically and start sharing
    if (blackHoleMic) {
      autoSelectSystemAudioDevice(blackHoleMic)
      return
    } else if (soundFlowerMic) {
      autoSelectSystemAudioDevice(soundFlowerMic)
      return
    } else if (loopBackMic) {
      autoSelectSystemAudioDevice(loopBackMic)
      return
    }

    // 3.2 there is no known virtual device to share system audio
    // show a modal and help user to setup a virtual device
    openBlackHoleModal()
      .then(() => {
        if (isElectron) {
          electronApi?.openExternalUrl(BlackHoleGuide)
        }
      })
      .finally(() => {})
  }, [
    autoSelectSystemAudioDevice,
    autoSelectedSystemAudioDeviceRef,
    blackHoleMic,
    dispatch,
    loopBackMic,
    openBlackHoleModal,
    soundFlowerMic,
    systemAudioDevice,
    toggleSystemAudioSharingState,
    weSharingSystemAudio,
  ])

  return {
    toggleSystemAudio,
    isMultiOutputSpeakerSelected,
    isSharingSpeakerSelected,
    isNoorSpeakerSetToSharingSpeaker,
  }
}
