import React, { memo, useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import styled from 'styled-components'
import {
  SettingsPageState,
  SettingsPageDispatch,
  settingsWindows,
  SettingsWindowsType,
} from './use-settings-window'
import { PreferencesWindow } from './PreferencesWindow'
import { AccountWindow } from './AccountWindow'
import { FooterButton } from './SettingsUI'
import { Space } from '../shared/Space'
import { useMainWindowContext } from '../shared/use-main-window'
import { useTheme } from '../feed/ThemeContext'
import { GoodButton } from '../shared/GoodButton'
import { CrossIcon } from '../main/mainIcons'
import { useSafeTopBarStyles } from '@there/components/feed/useSafeTopBarStyles'
import { NoorayPreferencesWindow } from './NoorayPreferences'

let isNooray = process.env.APP_ENV === 'nooray'

export const SettingsWindows = memo(
  (props: { state: SettingsPageState; dispatch: SettingsPageDispatch }) => {
    const t = useTheme()
    const { state, dispatch } = props
    const { dispatch: windowDispatch } = useMainWindowContext()

    const closeSettingsWindow = () => {
      dispatch({ type: 'close window' })
      windowDispatch({ type: 'settings state changed', state: false })
    }

    let safeTopBarStyles = useSafeTopBarStyles()

    return (
      <Wrapper>
        <View
          style={[
            {
              position: 'absolute',
              width: '100%',
              top: 0,
              height: footerHeight,
              backgroundColor: t.colors.footerBackground,

              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            },

            // safeTopBarStyles,
          ]}
        >
          <View
            style={[
              {
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
              !isNooray && safeTopBarStyles,
            ]}
          >
            <View
              style={[
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '100%',
                  flexGrow: 1,
                },
              ]}
            >
              {!isNooray && <Space horizontal={8} />}
              {settingsWindows.map((item) => {
                if (item.active) {
                  return (
                    <React.Fragment key={item.id}>
                      <Space horizontal={4} />
                      <FooterButton
                        onClick={() => {
                          dispatch({
                            type: 'change window',
                            window: item.id as SettingsWindowsType,
                          })
                        }}
                        active={state.window === item.id}
                      >
                        {item.label}
                      </FooterButton>
                    </React.Fragment>
                  )
                }
              })}
            </View>
            <GoodButton
              onPress={closeSettingsWindow}
              icon={<CrossIcon color={t.colors.tertiaryText} />}
              variant="transparent"
            />
            <Space horizontal={10} />
          </View>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            padding: 18,
            flex: 1,
            marginTop: footerHeight,
          }}
          contentContainerStyle={{
            width: '100%',
            position: 'relative',
            paddingBottom: footerHeight,
          }}
        >
          {state.window === 'preferences' &&
            (isNooray ? <NoorayPreferencesWindow /> : <PreferencesWindow />)}
          {state.window === 'account' && <AccountWindow />}
        </ScrollView>
      </Wrapper>
    )
  },
)

const footerHeight = 40

export const SettingsWrapper = styled.div`
  position: absolute;
  bottom: 11px;
  right: 19px;
  z-index: 40;
`

const Wrapper = styled.div`
  position: absolute;
  z-index: 20;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  box-shadow: 0px 11px 16px 4px rgba(0, 0, 0, 0.15),
    0px 3px 4px rgba(0, 0, 0, 0.15);
  background: ${(p) => p.theme.colors.background};
  backdrop-filter: blur(26px);

  display: flex;
  justify-content: center;
`
