import { useTheme } from 'components/feed/ThemeContext'
import { logEvent } from '../shared/use-analytics'
import { PinControlButton } from './PinControlButton'
import { MicrophoneIcon } from '@there/components/shared/MicrophoneIcon'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { CameraIcon } from '@there/components/shared/CameraIcon'

export const PinCameraButton = () => {
  let { walkieState, toggleCamera } = useRtcContext()
  const theme = useTheme()

  return (
    <PinControlButton
      onPress={() => {
        toggleCamera()
        logEvent('User Toggled Camera', {
          from: 'overlay',
        })
      }}
      activeColor="cameraButtonIcon"
      isActive={walkieState.weSharingCamera}
    >
      <CameraIcon
        color={theme.colors.white}
        opacity={walkieState.weSharingCamera ? 1 : 0.6}
        width={20}
      />
    </PinControlButton>
  )
}
