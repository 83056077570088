import React, { useCallback, useEffect, useRef, useState } from 'react'
import { View, Text } from 'react-native'
import { Space } from '../shared/Space'
import { ProfilePicker } from '@there/components/login/ProfilePicker'
import { InputLabel } from '@there/components/login/InputLabel'
import { useDebounce } from '../shared/use-debounce'
import { useTheme } from '../feed/ThemeContext'
import { getShortName } from '@there/shared/utilities/get-short-name'
import { usePublicUserQuery } from '../urql/publicUser'
import { useSpring, a } from '@react-spring/native'
import { HelpText } from '@there/components/login/HelpText'
import { StandardButton } from '../main/StandardButton'
import { StandardInput } from '../main/StandardInput'
import { useSendEmailCodeMutation } from '../urql/sendEmailCode'
import { Pressable } from '@there/components/shared/Pressable'
import { useAppContext } from '../shared/AppContext'
import { useEmailValidationMutation } from '../urql/emailValidation'
import { useSpaceContext } from '../shared/spaceContext'
import { useUser } from '../atoms/userAtom'
import { useUpdateProfile } from '@there/components/main/useUpdateProfile'
import { useAtom } from 'jotai'
import { spaceContextRefetchAtom } from '../shared/spaceContext/context'

const isNooray = process.env.APP_ENV === 'nooray'

export const AvatarSection = () => {
  let theme = useTheme()
  let { currentUser, currentUserId } = useAppContext()
  let user = useUser(currentUser?.id || '')

  let [{ refetchSpaceContext }] = useAtom(spaceContextRefetchAtom)
  let [fullName, setFullName] = useState('')
  let [nickname, setNickname] = useState('')
  let [profile, setProfile] = useState<undefined | string>(undefined)

  let [isFullNameEmpty, setFullNameEmpty] = useState(false)
  let [isManualNickname, setManualNickname] = useState(false)
  let fullNameRef = useRef<string | null>(null)

  let [email, setEmail] = useState(
    typeof currentUser?.email === 'string' ? currentUser.email : '',
  )
  let [verificationCode, setVerificationCode] = useState('')
  let [isCodeWrong, setCodeWrong] = useState(false)
  let [isInVerification, setVerification] = useState(false)
  let [emailExists, setEmailExists] = useState(false)
  let [profileUploading, setProfileUploading] = useState(false)

  let [isEmailVerified, setEmailVerified] = useState(
    Boolean(currentUser?.emailVerified),
  )

  let [emailVerificationLoading, setEmailVerificationLoading] = useState(false)
  let [updateProfileLoading, setUpdateProfileLoading] = useState(false)

  useEffect(() => {
    if (!user) return
    setFullName(typeof user.name === 'string' ? user.name : '')
    fullNameRef.current = user.name || null
    setNickname(typeof user?.nickname === 'string' ? user.nickname : '')
    setEmail(typeof user?.email === 'string' ? user.email : '')
  }, [user])

  // set nickname when fullName changed and nickname does not set
  useEffect(() => {
    if (isManualNickname) return
    if (fullName.length === 0) return
    if (fullNameRef.current === fullName) return
    let shortName = getShortName({ name: fullName }) || ''
    setFullNameEmpty(false)
    setNickname(shortName)
    fullNameRef.current = fullName
  }, [fullName, isManualNickname])

  // check if nickname set manually, make its flag true to prevent change nickname on name change
  useEffect(() => {
    if (nickname.length === 0) {
      setManualNickname(false)
    } else {
      if (getShortName({ name: fullNameRef.current }) !== nickname) {
        setManualNickname(true)
      }
    }
  }, [nickname])

  // check if email exists
  const debouncedEmailHandle = useDebounce(email, 200)
  const [{ data }, refetch] = usePublicUserQuery({
    variables: { email: String(debouncedEmailHandle) },
    pause: String(debouncedEmailHandle).length < 4,
  })

  useEffect(() => {
    if (!debouncedEmailHandle) return
    if (debouncedEmailHandle < 4) return

    try {
      if (typeof refetch !== 'undefined') {
        refetch({ email: String(debouncedEmailHandle) })
      }
    } catch (error) {
      console.info('email check failed:', error)
    }
  }, [debouncedEmailHandle, refetch])

  useEffect(() => {
    if (isEmailVerified) return
    if (!data || !data.user) {
      setEmailExists(false)
      return
    }
    setEmailExists(
      Boolean(data.user.email) &&
        data.user.pendingSetup !== true &&
        data.user.id !== currentUserId,
    )
  }, [currentUserId, data, isEmailVerified])

  // email exists warning animation manager
  let emailExistsStyleProps = useSpring({
    opacity: emailExists ? 1 : 0,
    config: {
      mass: 0.3,
      tension: 400,
      friction: 20,
    },
  })

  // full name empty warning animation manager
  let fullNameEmptyStyleProps = useSpring({
    opacity: isFullNameEmpty ? 1 : 0,
    config: {
      mass: 0.3,
      tension: 400,
      friction: 20,
    },
  })

  // verification form animation manager
  let styleProps = useSpring({
    height: isInVerification ? 100 : 0,
    config: {
      mass: 0.3,
      tension: 400,
      friction: 20,
    },
  })

  // verification code wrong warning animation manager
  let codeWrongStyleProps = useSpring({
    opacity: isCodeWrong ? 1 : 0,
    config: {
      mass: 0.3,
      tension: 400,
      friction: 20,
    },
  })

  // to update userProfile
  let [updateProfileResult, updateProfile] = useUpdateProfile()
  async function onSubmit() {
    if (fullName.length === 0) {
      setFullNameEmpty(true)
      return
    }

    try {
      setUpdateProfileLoading(true)
      const profilePhoto = profile?.includes('https://') ? profile : undefined

      updateProfile({
        name: fullName,
        nickname,
        profilePhoto,
      })
    } catch (error) {
      alert(`Failed with error: ${error}`)
      setUpdateProfileLoading(false)
    }
  }

  useEffect(() => {
    if (!updateProfileResult || !updateProfileResult.data) return
    setUpdateProfileLoading(false)
  }, [updateProfileResult])

  // to send email verification code
  const [
    { fetching: emailRequestLoading },
    sendEmailCode,
  ] = useSendEmailCodeMutation()
  const onSubmitEmail = useCallback(
    (email: string) => {
      if (!email || email.length < 5) {
        return
      }
      setEmailVerificationLoading(true)
      sendEmailCode({
        email,
      })
        .then(() => {
          setVerification(true)
        })
        .catch((error) => {})
        .finally(() => {
          setEmailVerificationLoading(false)
        })
    },
    [sendEmailCode],
  )

  // to check verification code then verify email
  const [, emailValidationCheck] = useEmailValidationMutation()
  const onSubmitVerification = useCallback(
    ({
      email,
      verificationCode,
    }: {
      email: string
      verificationCode: string
    }) => {
      if (!currentUser) return
      if (email.length === 0 || verificationCode.length === 0) {
        return
      }
      setEmailVerificationLoading(true)
      emailValidationCheck({
        email,
        code: verificationCode,
        userId: currentUser.id,
      })
        .then((result) => {
          if (!result || !result.data) return
          if (result.data.done) {
            setEmailVerified(true)
            setVerification(false)
          }
        })
        .catch(() => {
          setCodeWrong(true)
        })
        .finally(() => {
          setEmailVerificationLoading(false)
          refetchSpaceContext()
        })
    },
    [currentUser, emailValidationCheck, refetchSpaceContext],
  )

  let profileName = nickname || getShortName({ name: fullName })

  let verificationSectionJsx = (
    <a.View
      style={{
        height: styleProps.height,
        overflow: 'hidden',
      }}
    >
      <Space vertical={10} />
      <Text
        style={{
          width: '100%',
          fontSize: theme.fontSizes.normal,
          color: theme.colors.tertiaryText,
        }}
      >
        We’ve sent a code to your email. Enter the code below to verify:
        <Pressable
          onPress={() => {
            setVerification(false)
            setVerificationCode('')
          }}
        >
          <Text style={{ color: theme.colors.quaternaryText }}>
            {' '}
            (edit email)
          </Text>
        </Pressable>
      </Text>
      <Space vertical={10} />
      <View style={{ position: 'relative' }}>
        <StandardInput
          placeholder="code"
          disabled={!isInVerification || emailVerificationLoading}
          value={verificationCode}
          setValue={setVerificationCode}
          background="transparentInputBackground"
          bordered={true}
          onSubmit={() => {
            onSubmitVerification({ email, verificationCode })
          }}
        />
      </View>
    </a.View>
  )

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: isNooray ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: isNooray ? 'center' : undefined,
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 200,
        }}
      >
        <ProfilePicker
          setProfile={setProfile}
          profile={user?.profilePhoto || undefined}
          setProfileUploading={setProfileUploading}
        />
        {profileName && profileName.length > 0 && (
          <Text
            numberOfLines={1}
            style={{
              fontSize: theme.fontSizes.normal,
              color: theme.colors.secondaryText,
              maxWidth: 90,
            }}
          >
            {profileName}
          </Text>
        )}
        <Space vertical={10} />
        <View
          style={{
            maxWidth: 150,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <InputLabel>Full name</InputLabel>
          <Space vertical={5} />
          <View style={{ position: 'relative' }}>
            <StandardInput
              placeholder="Mostafa Ahmadi"
              disabled={updateProfileLoading}
              value={fullName}
              setValue={setFullName}
              background="transparentInputBackground"
              bordered={true}
            />
            <a.View
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: '100%',
                width: 100,
                marginLeft: 5,
                opacity: fullNameEmptyStyleProps.opacity,

                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <HelpText>Enter your name</HelpText>
            </a.View>
          </View>
          <Space vertical={10} />
          <InputLabel>Nickname</InputLabel>
          <Space vertical={5} />
          <StandardInput
            placeholder="Mosi"
            disabled={updateProfileLoading}
            value={nickname}
            setValue={setNickname}
            background="transparentInputBackground"
            bordered={true}
          />
          <Space vertical={10} />
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <StandardButton
              disabled={updateProfileLoading || profileUploading}
              text={updateProfileLoading ? 'Updating...' : 'Update'}
              backgroundColor="primaryButtonBackground"
              textColor="secondaryText"
              size="normal"
              onPress={onSubmit}
            />
          </View>
        </View>
      </View>
      <View
        style={{
          width: 200,
          paddingLeft: 25,
          borderLeftWidth: 1,
          borderLeftColor: theme.colors.separatorLine,

          display: isNooray ? 'none' : 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <InputLabel subLabel={isEmailVerified ? '(verified🎉)' : '(optional)'}>
          Email
        </InputLabel>
        <Space vertical={5} />
        <View style={{ position: 'relative' }}>
          <StandardInput
            placeholder="mostafa@gmail.com"
            disabled={emailVerificationLoading || isEmailVerified}
            value={email}
            setValue={setEmail}
            background="transparentInputBackground"
            bordered={true}
            textColor={isEmailVerified ? 'tertiaryText' : 'text'}
            onSubmit={() => {
              if (isEmailVerified) return
              if (emailVerificationLoading) return
              if (isInVerification) return
              onSubmitEmail(email)
            }}
          />
          <a.View
            style={{
              position: 'absolute',
              bottom: -15,
              right: 0,
              width: '100%',
              marginLeft: 5,
              opacity: emailExistsStyleProps.opacity,

              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <HelpText alignment="right">Email exists</HelpText>
          </a.View>
          <View style={{ position: 'relative' }}>
            {verificationSectionJsx}
            <a.View
              style={{
                position: 'absolute',
                bottom: -10,
                right: 0,
                width: '100%',
                marginLeft: 5,
                opacity: codeWrongStyleProps.opacity,

                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <HelpText alignment="right">Code is wrong</HelpText>
            </a.View>
          </View>
        </View>
        {isEmailVerified ? null : (
          <>
            <Space vertical={10} />
            <StandardButton
              text={
                isInVerification
                  ? emailVerificationLoading
                    ? 'Verifying...'
                    : 'Verify'
                  : emailVerificationLoading
                  ? 'Sending email...'
                  : 'Continue'
              }
              backgroundColor="primaryButtonBackground"
              textColor="secondaryText"
              size="normal"
              onPress={() => {
                isInVerification
                  ? onSubmitVerification({ email, verificationCode })
                  : onSubmitEmail(email)
              }}
            />
          </>
        )}
        <Space vertical={10} />
        <HelpText alignment="justify">
          A verified email saves your data and helps you to use your account on
          multiple devices
        </HelpText>
      </View>
    </View>
  )
}
