import * as React from 'react'
import { useImmerReducer } from 'use-immer'

export const useRenderingPhase = () => {
  return useImmerReducer(reducer, initialState)
}

function reducer(draft: RenderingPhaseState, action: Action) {
  switch (action.type) {
    case 'new chat requested': {
      // filter not rendered pages, keep only the rendered page
      draft.pages = draft.pages.filter((page) => page.rendering)

      if (draft.pages.some((page) => page.peer.id === action.peer.id)) {
        // Same page
        return
      }

      draft.pages.push({
        peer: action.peer,
        rendering: false,
        state: {
          fetched: false,
          scrolled: false,
          calculated: false,
          calculatedEmptySpace: false,
        },
      })
      break
    }

    case 'state changed': {
      let id = action.peer.id
      let page = draft.pages.find((page) => page.peer.id === id)
      if (!page) return
      if (page.rendering) return

      page.state.fetched = action.state.fetched ?? page.state.fetched
      page.state.calculated = action.state.calculated ?? page.state.calculated
      page.state.scrolled = action.state.scrolled ?? page.state.scrolled
      page.state.calculatedEmptySpace =
        action.state.calculatedEmptySpace ?? page.state.calculatedEmptySpace

      if (isPageReady(page.state)) {
        // Clear old rendering page
        draft.pages = draft.pages.filter(
          (page) => !page.rendering,
          // || page.peer.id === id,
        )

        // Mark as new rendering
        page.rendering = true
      }
      break
    }

    // Render empty chat right away
    case 'chat is empty': {
      let id = action.peer.id
      let page = draft.pages.find((page) => page.peer.id === id)
      if (!page) return
      if (page.rendering) return

      // Clear old rendering page
      draft.pages = draft.pages.filter((page) => !page.rendering)

      // Mark as new rendering
      page.rendering = true

      break
    }
  }
}

interface PageRenderingState {
  fetched: boolean
  scrolled: boolean
  calculated: boolean
  calculatedEmptySpace: boolean
}

type RenderedPage = {
  peer: ChatPeerId
  state: PageRenderingState
  rendering: boolean
}

type RenderingPhaseState = {
  pages: RenderedPage[]
}

const initialState: RenderingPhaseState = {
  pages: [],
}

type Action =
  | { type: 'new chat requested'; peer: ChatPeerId }
  | {
      type: 'state changed'
      peer: ChatPeerId
      state: Partial<PageRenderingState>
    }
  | {
      type: 'chat is empty'
      peer: ChatPeerId
    }

export type ChatPeerId =
  | {
      type: 'Topic'
      id: string
    }
  | { type: 'User'; id: string }
export type ChatPeerType = ChatPeerId['type']

export type RenderingPhaseDispatch = React.Dispatch<Action>

function isPageReady(state: PageRenderingState) {
  return (
    state.calculated &&
    state.fetched &&
    state.scrolled &&
    state.calculatedEmptySpace
  )
}
