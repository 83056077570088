import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import type { Workbox } from 'workbox-window'

interface ContextType {
  registration: ServiceWorkerRegistration | undefined
  showSkipWaiting: boolean
  accepted: () => void
  dismissed: () => void
  updateServiceWorker: () => void
}

const initialValue: ContextType = {
  registration: undefined,
  showSkipWaiting: false,
  accepted: () => {},
  dismissed: () => {},
  updateServiceWorker: () => {},
}

export const ServiceWorkerContext = createContext<ContextType>(initialValue)

export const ServiceWorker = ({ children }: { children: ReactNode }) => {
  let [showSkipWaiting, setShowSkipWaiting] = useState(false)
  let [registration, setRegistration] = useState<
    ServiceWorkerRegistration | undefined
  >()

  const wb = useRef<Workbox | undefined>()
  const fallbackTimeout = useRef<number | undefined>()

  // useEffect(() => {
  //   if (!('serviceWorker' in navigator)) return
  //   if (process.env.isDev && !appConfig.DEBUG_SERVICE_WORKER) return
  //   // @ts-ignore
  //   if (window.workbox === undefined) return

  //   console.info('sw')

  //   try {
  //     // Register
  //     // wb.current = new Workbox(
  //     //   // process.env.NEXT_EXPORT
  //     //   //   ? '/service-worker.js'
  //     //   //   : '/static/service-worker.js',

  //     //   '/service-worker.js',
  //     // )
  //     // @ts-ignore
  //     wb.current = window.workbox
  //   } catch (error) {
  //     console.error('Failed to load service worker:', error)
  //   }

  //   let wbInstance = wb.current

  //   const showSkipWaitingPrompt = () => {
  //     setShowSkipWaiting(true)
  //   }
  //   const generalEventHandlers = (event: any) => {
  //     console.info(`SW: ${event.type}`)
  //   }

  //   if (wbInstance) {
  //     // add event listeners to handle any of PWA lifecycle event
  //     // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
  //     wbInstance.addEventListener('installed', generalEventHandlers)
  //     wbInstance.addEventListener('controlling', generalEventHandlers)
  //     wbInstance.addEventListener('activated', generalEventHandlers)

  //     // Listening for externalwaiting is no longer needed.
  //     wbInstance.addEventListener('waiting', showSkipWaitingPrompt)
  //     let registrationPromise = wbInstance.register()

  //     registrationPromise
  //       .then((registration) => {
  //         console.info('ws registered')
  //         // save in state
  //         setRegistration(registration)

  //         setShowSkipWaiting(false)

  //         // Fallback
  //         if (fallbackTimeout.current) {
  //           clearTimeout(fallbackTimeout.current)
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Failed to register service worker:', error)
  //       })
  //   }

  //   return () => {
  //     // Unregister?
  //     wbInstance?.removeEventListener('waiting', showSkipWaitingPrompt)
  //     wbInstance?.removeEventListener('installed', generalEventHandlers)
  //     wbInstance?.removeEventListener('controlling', generalEventHandlers)
  //     wbInstance?.removeEventListener('activated', generalEventHandlers)
  //   }
  // }, [])

  const accepted = useCallback(() => {
    // wb.current?.addEventListener('controlling', () => {
    //   window.location.reload()
    // })

    // // This will postMessage() to the waiting service worker.
    // wb.current?.messageSkipWaiting()
    // setShowSkipWaiting(false)

    // // Fallback
    // if (fallbackTimeout.current) {
    //   clearTimeout(fallbackTimeout.current)
    // }
    // fallbackTimeout.current = setTimeout(() => {
    //   window.location.reload()
    // }, 1000)

    window.location.reload()
  }, [])

  const dismissed = useCallback(() => {
    // setShowSkipWaiting(false)
  }, [])

  const updateServiceWorker = useCallback(() => {
    // if (!wb.current) return
    // let updater = wb.current.update()
    // if (updater) {
    //   updater
    //     .then(() => {
    //       console.info('checked for new sw')
    //     })
    //     .catch((error) => {
    //       console.error('failed to update sw:', error)
    //     })
    // }
  }, [])

  // put in context

  const value = useMemo(
    () => ({
      registration,
      showSkipWaiting,
      accepted,
      dismissed,
      updateServiceWorker,
    }),
    [accepted, dismissed, registration, showSkipWaiting, updateServiceWorker],
  )

  return (
    <ServiceWorkerContext.Provider value={value}>
      <>{children}</>
    </ServiceWorkerContext.Provider>
  )
}

export const useServiceWorker = () => {
  return useContext(ServiceWorkerContext)
}
