import { useAppContext } from '@there/components/shared/AppContext'
import { useMemo } from 'react'
import { useForceSpeaker } from '../shared/use-media-devices'
import { useRtcContext } from '../shared/use-rtc'
import { useVolumeContext } from '../shared/VolumeContext'

const focusedVolume = 10

export const useOutputVolume = () => {
  let { currentUserId } = useAppContext()
  const { volume, muted, usersSystemVolume, usersVolume } = useVolumeContext()
  let { walkieState, participants } = useRtcContext()

  let userIdsArray = Object.values(participants).map(
    (participant) => participant.userId,
  )
  let { notPlayingBecauseForced } = useForceSpeaker()

  let weTalking = walkieState.weTalking

  // we does not have access to useUser in rtc
  // so we use participant's user data to detect focus state of us
  let weFocused = !!participants.find(
    (participant) => participant.userId === currentUserId,
  )?.user.focused

  let usersOutputVolume = useMemo(() => {
    let output: Record<
      string,
      { systemOutput: number; userOutput: number }
    > = {}
    for (let userId of userIdsArray) {
      let systemAudio = usersSystemVolume && usersSystemVolume[userId]
      let userVolume = usersVolume && usersVolume[userId]
      let systemOutput = 100
      let userOutput = 100

      if (muted || notPlayingBecauseForced) {
        output[userId] = {
          systemOutput: 0,
          userOutput: 0,
        }
        continue
      }

      if (weFocused && !weTalking) {
        output[userId] = {
          systemOutput: focusedVolume / 100,
          userOutput: focusedVolume / 100,
        }
        continue
      }

      if (typeof systemAudio === 'number') {
        systemOutput = systemAudio
      }

      if (typeof userVolume === 'number') {
        userOutput = userVolume
      }

      output[userId] = {
        systemOutput: (systemOutput * volume) / 10_000,
        userOutput: (userOutput * volume) / 10_000,
      }
    }
    return output
  }, [
    muted,
    notPlayingBecauseForced,
    userIdsArray,
    usersSystemVolume,
    usersVolume,
    volume,
    weFocused,
    weTalking,
  ])

  let outputVolume = useMemo(() => {
    if (muted || notPlayingBecauseForced) {
      return 0
    }
    if (weFocused) {
      return focusedVolume / 100
    }
    return volume / 100
  }, [muted, notPlayingBecauseForced, volume, weFocused])

  return { usersOutputVolume, outputVolume }
}
