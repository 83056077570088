import { electronData, ipc } from '@there/desktop/utils/electron-api'

export function getTokenFromAppState(appState?: string) {
  if (!appState || !appState.startsWith('{')) {
    return undefined
  }

  const token = JSON.parse(appState).token

  return token
}

export async function getToken() {
  if (typeof localStorage === 'undefined') return

  const token =
    // @mo: For some reason, in join-space, `token` wasn't set _-\^*^ /-_
    localStorage.getItem('token') ||
    // For changing app url
    (await ipc?.invoke('get-token').catch(() => {})) ||
    getTokenFromAppState(localStorage.appState)

  return token
}

export function getActiveSpaceId() {
  if (typeof localStorage === 'undefined') return

  if (!localStorage.appState || !localStorage.appState.startsWith('{')) {
    return undefined
  }

  const activeSpaceId = JSON.parse(localStorage.appState).activeSpaceId

  return activeSpaceId
}
