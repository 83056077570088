import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { View } from 'react-native'
import { useLatest } from '../shared/use-latest'
import { SearchInput } from './SearchInput'
import { SearchResult } from './SearchResult'
import { AllEmojis, ConfigType, Emoji } from './use-picker'

type Props = {
  config: ConfigType
  setIsSearching: Dispatch<SetStateAction<boolean>>
  emojis: AllEmojis
  onEmojiClick: (emoji: Emoji) => void
  isOpen: boolean
}

export const SearchEmoji = memo(
  ({ emojis, config, setIsSearching, onEmojiClick, isOpen }: Props) => {
    const [searchInput, setSearchInput] = useState('')
    let isSearching = searchInput.length > 0

    let isOpenRef = useLatest(isOpen)
    useEffect(() => {
      if (isOpen) return
      let timeout = setTimeout(() => {
        if (isOpenRef.current) return
        setSearchInput('')
      }, 10_000)

      return () => clearTimeout(timeout)
    }, [isOpen, isOpenRef])

    useEffect(() => {
      setIsSearching(searchInput.length > 0)
    }, [searchInput.length, setIsSearching])

    const onSearchChanges = useCallback((input: string) => {
      setSearchInput(input)
    }, [])

    const searchEmoji = useCallback(
      (value: string) => {
        let result: Emoji[] = []
        if (!emojis) return result
        for (let id in emojis) {
          let { names } = emojis[id] as Emoji
          if (names.join('-').includes(value)) {
            result.push(emojis[id] as Emoji)
          }
        }

        return result
      },
      [emojis],
    )

    const searchResultEmojis = useMemo(() => {
      return searchEmoji(searchInput.toLowerCase())
    }, [searchEmoji, searchInput])

    return (
      <>
        <SearchInput
          config={config}
          onSearchChanges={onSearchChanges}
          searchInput={searchInput}
        />
        {isSearching && (
          <View
            style={[
              {
                display: 'flex',
                width: config.containerWidth + config.emojiMargin * 2,
                marginTop: 10,
              },
            ]}
          >
            <SearchResult
              emojis={searchResultEmojis}
              key="SearchResultCategory"
              onEmojiClick={onEmojiClick}
              config={config}
            />
          </View>
        )}
      </>
    )
  },
)
