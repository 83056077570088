import styled, { css } from 'styled-components'
import { lighten, darken, opacify } from 'polished'

const sizeToHeight = {
  standard: 24,
  large: 30,
}

const sizeToPadding = {
  standard: 12,
  large: 22,
}

export type ActionButtonProps = {
  variant?: 'primary' | 'danger'
  width?: '100%' | number
  size?: 'standard' | 'large'
  align?: 'center'
  round?: boolean
  padding?: boolean
}

export const ActionButton = styled.button<ActionButtonProps>`
  border: none;
  outline: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${(p) =>
    sizeToHeight[typeof p.size !== 'undefined' ? p.size : 'standard']}px;
  width: ${({ width }) =>
    typeof width === 'number' ? `${width}px` : width || 'auto'};
  padding: 0
    ${(p) =>
      p.padding === false
        ? 0
        : sizeToPadding[typeof p.size !== 'undefined' ? p.size : 'standard']}px;
  font-size: ${(p) => p.theme.fontSizes[15]}px;
  line-height: 1;
  font-weight: ${(p) => p.theme.fontWeights.button};
  color: ${(p) => p.theme.colors.secondaryText};
  background: ${(p) => p.theme.colors.transparentButtonBackground};
  border-radius: ${(p) => (p.round ? `28` : p.theme.borderRadius.large)}px;
  transition: ${(p) => p.theme.transition.colors};
  cursor: default;
  box-shadow: inset 0 1px 0
    ${(p) => (p.round ? 'transparent' : p.theme.colors.transparentOverlay)};

  &:active {
    background: ${(p) => opacify(0.05, p.theme.colors.transparentOverlay)};
    transition: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  ${(p) =>
    p.variant === 'primary' &&
    css`
      color: ${p.theme.colors.secondaryText};
      background: ${p.theme.colors.primary};

      &:hover {
        background: ${lighten(0.08, p.theme.colors.primary)};
      }

      &:active {
        background: ${darken(0.08, p.theme.colors.primary)};
      }
    `}

  ${(p) =>
    p.variant === 'danger' &&
    css`
      color: ${p.theme.colors.secondaryText};
      background: '#E81C41';

      &:hover {
        background: ${lighten(0.08, '#E81C41')};
      }
    `};

  svg {
    height: 16px;
    width: auto;
  }
`
