interface PartialUserName {
  nickname?: string | undefined | null
  name?: string | undefined | null
  email?: string | undefined | null
}

export function getShortName(user: PartialUserName) {
  if (user.nickname) {
    return user.nickname
  }

  if (user.name) {
    return user.name.trim().split(' ')[0]
  }

  if (user.email) {
    return String(user.email.split('@')[0] || '')
  }

  return undefined
}
