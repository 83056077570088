import { useNurMutation } from '@there/components/sun/use-mutation'
import { UserRateMessage } from '@there/sun/modules/userRate'
interface Data {
  done: boolean
}

export const useUserRate = () => {
  return useNurMutation<Data, UserRateMessage['payload']>({
    method: 'userRate',
  })
}
