import { useSelect } from 'downshift'
import { Dropdown, DropdownItemType } from '@there/components/feed/Dropdown'
import { Space } from '@there/components/shared/Space'
import { WebStyled } from '@there/components/shared/WebStyled'
import { useDeleteMember } from '../main/useDeleteMember'
import { useAppContext } from '../shared/AppContext'

export const memberDropdownWidth = 140
export const volumeSliderMargins = Dropdown.sidePadding * 2

type Props = {
  isAdmin: boolean
  memberId?: string
  children: React.ReactNode
}

export const MemberDropdown = (props: Props) => {
  const { activeSpaceId, activeMemberId } = useAppContext()
  let [, doDeleteMember] = useDeleteMember()

  let { children, isAdmin, memberId } = props

  let items: DropdownItemType[] = [
    {
      label: 'Remove Member',
      onClick: () => {
        if (!activeSpaceId) return
        if (!memberId) return

        let confirmed = confirm(
          'Are you sure you want to DELETE this teammate? This cannot be undone.',
        )

        if (!confirmed) {
          return
        }

        // Remove member
        doDeleteMember({
          memberId: memberId,
          spaceId: activeSpaceId,
        })
      },
    },
  ]

  let {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    closeMenu,
    openMenu,
  } = useSelect({
    items: items,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          }
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.MenuBlur:
          return {
            ...changes,
            isOpen: true,
          }
      }
      return changes
    },
  })

  const onContextMenu = () => {
    if (!isAdmin) return
    // Don't show on self
    if (activeMemberId === memberId) return
    openMenu()
  }

  return (
    <Dropdown.Root>
      <WebStyled>
        <Dropdown.Toggle
          toggleProps={getToggleButtonProps}
          onContextMenu={onContextMenu}
        >
          {children}
        </Dropdown.Toggle>
      </WebStyled>
      <Dropdown.Menu
        {...getMenuProps(undefined, { suppressRefError: true })}
        isOpen={isOpen}
        onClose={closeMenu}
        width={memberDropdownWidth}
      >
        <Dropdown.Section>
          <Space vertical={4} />
          {items.map((item, index) => {
            let highlighted =
              item.onClick && !item.disabled && highlightedIndex === index
            return (
              <Dropdown.Item
                key={item.label}
                isDim={!item.onClick || item.disabled}
                highlighted={highlighted}
                {...getItemProps({
                  item,
                  index,
                })}
                onClick={(event) => {
                  if (item.onClick) {
                    event.stopPropagation()
                    item.onClick()
                    closeMenu()
                  }
                }}
              >
                {item.label}
              </Dropdown.Item>
            )
          })}
          <Space vertical={4} />
        </Dropdown.Section>
      </Dropdown.Menu>
    </Dropdown.Root>
  )
}
