import { useAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { useTheme } from '../feed/ThemeContext'
import { currentDialogIdAtom } from '../feed/useLobbyMan'
import { useSoundEffect } from '../feed/useSoundEffect'
import { useEditDialog } from '../main/useEditDialog'
import { useAppContext } from '../shared/AppContext'
import { GoodButton } from '../shared/GoodButton'
import { useSpaceContext } from '../shared/spaceContext'
import { currentDialogLockStatusAtom } from '../shared/spaceContext/context'
import { Tooltip } from '../shared/Tooltip'
import { LockIcon } from './LockIcon'
import { UnlockIcon } from './UnlockIcon'

export const LockRoomButton = () => {
  let theme = useTheme()
  let { activeSpaceId } = useAppContext()
  let [currentDialogId] = useAtom(currentDialogIdAtom)
  let [currentDialogLockStatus] = useAtom(currentDialogLockStatusAtom)
  let [playLock] = useSoundEffect('/tones/lock.mp3')
  let [playUnlock] = useSoundEffect('/tones/unlock.mp3')

  let [, editDialog] = useEditDialog()

  let toggleLockDialog = useCallback(
    (lock) => {
      if (!activeSpaceId) return
      if (!currentDialogId) return
      editDialog({
        id: currentDialogId,
        doorClosed: lock,
        __extra: { spaceId: activeSpaceId },
      })
      if (lock) {
        playLock()
      } else {
        playUnlock()
      }
    },
    [activeSpaceId, currentDialogId, editDialog, playLock, playUnlock],
  )

  if (!currentDialogId) return null

  // need to play a sound on lock
  return (
    <Tooltip
      label={
        currentDialogLockStatus ? (
          <>
            Room is locked
            <br />
            Click to unlock
          </>
        ) : (
          <>
            Room is unlock
            <br />
            Click to lock
          </>
        )
      }
    >
      <GoodButton
        icon={
          currentDialogLockStatus ? (
            <LockIcon width={20} color={theme.colors.lockRoomIcon} />
          ) : (
            <UnlockIcon color={theme.colors.tertiaryLight} width={20} />
          )
        }
        onPress={() => toggleLockDialog(!currentDialogLockStatus)}
      />
    </Tooltip>
  )
}
