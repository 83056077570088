import React from 'react'
import { Text, TextProps } from 'react-native'
import { useTheme } from '@there/components/feed/ThemeContext'
import { ThemeType } from '@there/components/utils/theme'

export type ThemedTextProps = TextProps & {
  children?: React.ReactNode
  color?: keyof ThemeType['colors']
  fontSize?: keyof ThemeType['fontSizes']
  onContextMenu?: () => void
}

export const ThemedText = (props: ThemedTextProps) => {
  let { color, fontSize, ...rawProps } = props
  let t = useTheme()

  return (
    <Text
      {...rawProps}
      style={[
        {
          color: color ? t.colors[color] : undefined,
          fontSize: fontSize ? t.fontSizes[fontSize] : undefined,
        },
        props.style,
      ]}
    />
  )
}
