import { useHover } from '@there/components/shared/use-hover'
import { atom, useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { ScrollView, ScrollViewProps } from 'react-native'

export let roomsViewHovered = atom(false)

// Used in DialogItem to calculate dialogs maxWidth
export const peopleScrollViewPaddingHorizontal = 5

export const PeopleScrollView = React.forwardRef<
  ScrollView,
  ScrollViewProps & { children: React.ReactNode }
>((props, ref) => {
  let [hovered, listeners] = useHover()

  let [, setHoveredAtom] = useAtom(roomsViewHovered)

  useEffect(() => {
    setHoveredAtom(hovered)
  }, [hovered, setHoveredAtom])

  return (
    <ScrollView
      horizontal={false}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={{
        width: '100%',
      }}
      contentContainerStyle={{
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flex: 1,
        // So dialog title doesn't stick to top
        paddingTop: 10,
      }}
      {...listeners}
      {...props}
      ref={ref}
    />
  )
})
