import { useLatest } from '@there/components/shared/use-latest'
import { MouseEventHandler, useMemo, useRef } from 'react'
import { useState, useEffect, useCallback } from 'react'
import { ViewProps } from 'react-native'

export function useInitialRender(): boolean {
  let [initial, setInitial] = useState(true)

  useEffect(() => {
    setInitial(false)
  }, [])

  return initial
}

export function useInitialRenderRef(): { current: boolean } {
  let initial = useRef(true)

  useEffect(() => {
    initial.current = false
  }, [])

  return initial
}
