import { memo, MutableRefObject, useCallback } from 'react'
import { ScrollView, Text } from 'react-native'
import { TopArrowIcon } from '../room/TopArrowIcon'
import { useTransition, a } from '@react-spring/native'
import { Pressable } from '../shared/Pressable'
import { useTheme } from '../feed/ThemeContext'

type Props = {
  isScrolledManually: boolean
  scrollViewRef: MutableRefObject<ScrollView | null>
  unreadCount?: number
}

export const ScrollToButton = memo(
  ({ scrollViewRef, isScrolledManually, unreadCount }: Props) => {
    let theme = useTheme()

    let scrollToBottom = useCallback(() => {
      if (!scrollViewRef || !scrollViewRef.current) return
      scrollViewRef.current.scrollToEnd({ animated: true })
    }, [scrollViewRef])

    let buttonTransition = useTransition(isScrolledManually, {
      from: {
        opacity: 0,
        scale: 0.5,
        y: 0,
      },
      enter: {
        opacity: 1,
        scale: 1,
        y: 0,
      },
      leave: {
        opacity: 0,
        scale: 0.5,
        y: 20,
      },
      config: {
        tension: 400,
        friction: 10,
        mass: 0.1,
      },
    })

    return (
      <>
        {buttonTransition(({ opacity, scale, y }, item) => {
          return item ? (
            <a.View
              style={[
                {
                  position: 'absolute',
                  right: 32,
                  bottom: 18,
                  transform: [{ translateY: y }, { scale }],
                  opacity,
                  zIndex: 1,
                },
              ]}
            >
              <Pressable
                style={({ hovered, pressed }) => [
                  {
                    width: 28,
                    height: 28,
                    backgroundColor: theme.colors.circleButtonBackground,
                    borderRadius: 28,
                    // borderColor: theme.colors.quinaryLight,
                    // borderWidth: 1,

                    shadowRadius: 3,
                    shadowOffset: {
                      height: 2,
                      width: 0,
                    },
                    shadowColor: theme.colors.black,
                    shadowOpacity: 0.25,

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  hovered && {
                    backgroundColor: theme.colors.circleButtonBackgroundHover,
                  },
                  pressed && {
                    backgroundColor: theme.colors.circleButtonBackgroundActive,
                  },
                ]}
                onPress={scrollToBottom}
              >
                {({ pressed, hovered }) => (
                  <>
                    <TopArrowIcon
                      width={16}
                      style={{ transform: [{ rotate: '180deg' }] }}
                      color={theme.colors.tertiaryText}
                    />
                    {unreadCount && unreadCount > 0 ? (
                      <Text
                        style={[
                          {
                            width: 14,
                            height: 14,
                            backgroundColor: theme.colors.primary,
                            borderRadius: 14,
                            position: 'absolute',
                            top: -4,
                            right: -4,

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            fontSize: theme.fontSizes.tiny,
                            color: theme.colors.text,
                          },
                        ]}
                      >
                        {unreadCount}
                      </Text>
                    ) : null}
                  </>
                )}
              </Pressable>
            </a.View>
          ) : null
        })}
      </>
    )
  },
)
