import { doLogout } from '@there/components/feed/BackgroundHooks/use-logout'
import { useAppContext } from '@there/components/shared/AppContext'
import { MemberWithSpaceInfo } from '@there/components/urql/fragments/memberInfo'
import { useUserSpaces } from '@there/components/urql/userSpaces'
import { getToken } from '@there/components/utils/get-token'
import { ipc } from '@there/desktop/utils/electron-api'
import { useEffect, useMemo, useRef } from 'react'

interface Output {
  memberships?: Array<MemberWithSpaceInfo> | undefined
  currentMember?: MemberWithSpaceInfo
  refetch: () => any
  joinedSpacesData: Record<string, SpaceData>
}

export type SpaceData = {
  name: string
  memberId: string
}

export const useMemberships = (): Output => {
  const {
    activeSpaceId,
    activeMemberId,
    dispatch,
    token,
    hydrated,
    currentUserId,
  } = useAppContext()

  const [
    { data: userData, error: userSpacesError, fetching },
    refetch,
  ] = useUserSpaces({
    requestPolicy:
      // for login
      !activeSpaceId || !activeMemberId || !currentUserId
        ? 'network-only'
        : // for normal times
          'cache-and-network',
  })

  let memberships = userData?.user?.memberships

  const currentMember =
    memberships && memberships.find((member) => member.id === activeMemberId)

  let countOfFudges = userData?.user?.memberships?.length

  // Check if we're logged in
  useEffect(() => {
    if (
      userSpacesError &&
      userSpacesError.graphQLErrors.some(
        (graphError) => graphError.extensions?.code === 'NOT_AUTHORIZED',
      )
    ) {
      console.info('NOT_AUTHORIZED, userSpacesError')
      // this was driving people crazy
      // alert('NOT_AUTHORIZED, userSpacesError')
      // Note(mo): DO NOT logout based on tower, use SUN events for this
      // doLogout()
    }

    if (
      !activeSpaceId &&
      !fetching &&
      !userSpacesError &&
      countOfFudges === 0
    ) {
      // Go to create space

      // it was annoying
      // alert('Please create a space first. Click OK to continue.')
      // doLogout()
      location.assign('/login/space')
      return
    }
  }, [userSpacesError, activeSpaceId, countOfFudges, fetching])

  const joinedSpaceIds = useMemo(
    () => memberships && memberships.map((member) => member.space.id),
    [memberships],
  )

  useEffect(() => {
    if (!memberships) {
      return
    }

    if (joinedSpaceIds) {
      dispatch({
        type: 'joined spaces fetched',
        spaceIds: joinedSpaceIds,
      })
    }

    // if (memberships.length === 0) {
    //   // Go back to login
    //   console.warn('No memberships')
    //   doLogout()
    //   return
    // }

    // if (!activeSpaceId || spaceError) {
    if (!activeSpaceId) {
      // If selected space doesn't exist or error in loading
      // Set one space as active
      const onlySpaceId = memberships[0]?.space.id
      if (!onlySpaceId) {
        console.error('No space id found')
        return
      }
      dispatch({
        type: 'active space changed',
        spaceId: onlySpaceId,
        spaceName: memberships[0].space.name,
        memberId: memberships[0].id,
      })
    }
  }, [memberships, activeSpaceId, joinedSpaceIds, dispatch])

  const joinedSpacesData = useMemo(() => {
    if (!memberships) return {}
    let joinedSpaceNames: Record<string, SpaceData> = {}
    for (let membership of memberships) {
      if (!membership.space.id || !membership.space.name) continue
      joinedSpaceNames[membership.space.id] = {
        name: membership.space.name,
        memberId: membership.id,
      }
    }

    return joinedSpaceNames
  }, [memberships])

  /**
   * Nut sure if this should be here. But this is important.
   */
  useEffect(() => {
    // Save or update current user object (used for endedBy event)
    if (userData?.user && userData?.user.id === currentUserId) {
      dispatch({
        type: 'current user fetched',
        user: userData.user,
        avatarId: userData.user.avatar?.id || null,
      })
    }
  }, [currentUserId, dispatch, userData])

  let ranReloginCheck = useRef(false)

  // Re-login
  useEffect(() => {
    if (!hydrated) return
    if (ranReloginCheck.current) return
    async function reLogin() {
      let userInfo = (await ipc?.invoke('get-user-info')) || null

      if (!userInfo || !userInfo.token) return

      if (!token && userInfo.token) {
        console.info('re-logged in based on main info')
        dispatch({
          type: 'logged in',
          token: userInfo.token,
          userId: userInfo.user.id,
        })
      }
      ranReloginCheck.current = true
    }

    reLogin()
  }, [dispatch, token, hydrated])

  return { memberships, currentMember, refetch, joinedSpacesData }
}
