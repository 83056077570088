import { useNurMutation } from '@there/components/sun/use-mutation'
import { LeaveSpaceMessage } from '@there/sun/modules/leaveSpace'
interface Data {
  done: boolean
}

export const useLeaveSpace = () => {
  return useNurMutation<Data, LeaveSpaceMessage['payload']>({
    method: 'leaveSpace',
  })
}
