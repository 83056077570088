import { useTheme } from '@there/components/feed/ThemeContext'
import { SmallCrossIcon } from '@there/components/main/mainIcons'
import { useLeaveDialog } from '@there/components/main/useLeaveDialog'
import { Pressable } from '@there/components/shared/Pressable'
import { logEvent } from '@there/components/shared/use-analytics'
import React, { memo } from 'react'
import { ViewStyle } from 'react-native'

export const AvatarLeaveButton = memo(({ style }: { style?: ViewStyle }) => {
  let theme = useTheme()
  let [, leaveDialog] = useLeaveDialog()

  return (
    <Pressable
      style={({ hovered }) => [
        {
          width: 14,
          height: 14,
          borderRadius: 12,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.colors.circleButtonBackground,
          borderWidth: 1,
          borderColor: theme.colors.separatorLine,
        },
        hovered && {
          backgroundColor: theme.colors.circleButtonBackgroundHover,
        },
        style,
      ]}
      onPress={() => {
        // leave
        alert('Leave disabled')
        // leaveDialog({__extra: }})
        // logEvent('User Left a Room', { from: 'CurrentRoom - leave' })
      }}
    >
      <SmallCrossIcon color={theme.colors.quaternaryText} />
    </Pressable>
  )
})
