import { useCallback, useEffect } from 'react'
import localforage from 'localforage'
import { EmojiRawData } from './use-picker'
const debug = require('debug')('emoji-cache')

let productionPath = ''

export const THERE_EMOJI_VERSION = 4
const EMOJI_DATA_LINK =
  process.env.NODE_ENV === 'production'
    ? `${productionPath}/emoji-set-${THERE_EMOJI_VERSION}.json`
    : process.env.APP_ENV === 'nooray'
    ? `http://localhost:7777/emoji-set-${THERE_EMOJI_VERSION}.json`
    : `http://localhost:7000/emoji-set-${THERE_EMOJI_VERSION}.json`

localforage.config({
  driver: typeof window !== 'undefined' ? localforage.INDEXEDDB : undefined,
  name: 'thereEmoji',
  storeName: 'emoji_data',
  version: THERE_EMOJI_VERSION,
  description: 'there emoji storage',
})

declare global {
  interface Window {
    __EMOJI_CACHE: EmojiRawData | undefined
  }
}

type Listener = () => void
let emojiCacheListeners = new Set<Listener>([])
export let addEmojiCacheListener = (callback: Listener) => {
  emojiCacheListeners.add(callback)
}
export let removeEmojiCacheListener = (callback: Listener) => {
  emojiCacheListeners.delete(callback)
}

export const useEmojiCache = () => {
  const fetchAndStoreEmojis = useCallback(() => {
    fetch(EMOJI_DATA_LINK, { mode: 'cors', cache: 'default' })
      .then((response) => {
        return response.json()
      })
      .then((data: EmojiRawData) => {
        localforage.setItem('thereEmojiVersion', THERE_EMOJI_VERSION)
        localforage.setItem('thereEmojiJson', data)
        window.__EMOJI_CACHE = data

        try {
          for (let listener of emojiCacheListeners) {
            listener()
          }
        } catch {}
      })
  }, [])

  const getLocalEmojiData = useCallback(async () => {
    try {
      const version: number | null = await localforage.getItem(
        'thereEmojiVersion',
      )
      const localEmojiJson: EmojiRawData | null = await localforage.getItem(
        'thereEmojiJson',
      )
      if ((version && version < THERE_EMOJI_VERSION) || !localEmojiJson) {
        return false
      } else {
        window.__EMOJI_CACHE = localEmojiJson
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const preloadEmojiSheet = async () => {
    debug('preloading emoji sheet...')
    try {
      await fetch('/emojis/64.png')
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (window.__EMOJI_CACHE) return

    preloadEmojiSheet()
    getLocalEmojiData().then((isEmojiDataAvailable) => {
      if (!isEmojiDataAvailable) {
        fetchAndStoreEmojis()
      }
    })
  }, [fetchAndStoreEmojis, getLocalEmojiData])
}
