import { Text } from 'react-native'
import { useTheme } from '../feed/ThemeContext'

export const ModalDescription = ({
  children,
  align,
}: {
  children: React.ReactNode
  align?: 'center' | 'normal'
}) => {
  let theme = useTheme()
  return (
    <Text
      style={[
        {
          fontSize: theme.fontSizes.normal,
          color: theme.colors.tertiaryText,
          lineHeight: 16,
          width: '100%',
        },
        align === 'center' && {
          textAlign: 'center',
        },
      ]}
    >
      {children}
    </Text>
  )
}
