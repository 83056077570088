import { MemberWithUserInfo } from '@there/sun/utils/node-types'
import { useCallback } from 'react'
import {
  NativeDropdownItem,
  useNativeDropdown,
} from '../dropdown/useNativeDropdown'
import { useDeleteChat } from '../main/useDeleteChat'
import { useDeleteMember } from '../main/useDeleteMember'
import { useEditChat } from '../main/useEditChat'
import { useLatest } from '../shared/use-latest'

const adminMenuItems: NativeDropdownItem[] = [
  {
    type: 'mark-as-read',
    label: 'Mark as Read',
  },
  {
    type: 'delete-member',
    label: 'Delete Member',
  },
]

let memberMenuItems: NativeDropdownItem[] = [
  {
    type: 'mark-as-read',
    label: 'Mark as Read',
  },
]
let groupMenuItems: NativeDropdownItem[] = [
  {
    type: 'mark-as-read',
    label: 'Mark as Read',
  },
  {
    type: 'delete-group',
    label: 'Delete Group',
  },
]

export const useSideMenu = ({
  activeSpaceId,
  getMemberByUserId,
}: {
  activeSpaceId: string | undefined
  getMemberByUserId: (userId: string) => MemberWithUserInfo | undefined
}) => {
  let [, doDeleteMember] = useDeleteMember()
  let [, doEditChat] = useEditChat()
  let [, doDeleteChat] = useDeleteChat()

  let getMemberByUserIdRef = useLatest(getMemberByUserId)

  // admins menu
  let { openMenu: openAdminMenu } = useNativeDropdown({
    items: adminMenuItems,
    key: 'side-member-admin-menu',
    onSelectItem: useCallback(
      (selectedItemType: string, userId: string) => {
        switch (selectedItemType) {
          case 'mark-as-read':
            if (!activeSpaceId) return
            if (!userId) return
            doEditChat({ unreadCount: 0, activeSpaceId, peerUserId: userId })
            break
          case 'delete-member':
            if (!activeSpaceId) return
            let member = getMemberByUserIdRef.current(userId)
            if (!member) return

            let confirmed = confirm(
              'Are you sure you want to DELETE this teammate? This cannot be undone.',
            )

            if (!confirmed) {
              return
            }

            // Remove member
            doDeleteMember({
              memberId: member.id,
              spaceId: activeSpaceId,
            })
            break
        }
      },
      [activeSpaceId, doDeleteMember, doEditChat, getMemberByUserIdRef],
    ),
  })

  // members menu
  let { openMenu: openMemberMenu } = useNativeDropdown({
    items: memberMenuItems,
    key: 'side-member-member-menu',
    onSelectItem: useCallback(
      (selectedItemType: string, userId: string) => {
        switch (selectedItemType) {
          case 'mark-as-read':
            if (!activeSpaceId) return
            if (!userId) return
            doEditChat({ unreadCount: 0, activeSpaceId, peerUserId: userId })
            break
        }
      },
      [activeSpaceId, doEditChat],
    ),
  })
  let { openMenu: openGroupMenu } = useNativeDropdown({
    items: groupMenuItems,
    key: 'side-member-group-menu',
    onSelectItem: useCallback(
      (selectedItemType: string, topicId: string) => {
        switch (selectedItemType) {
          case 'mark-as-read':
            if (!activeSpaceId) return
            if (!topicId) return

            doEditChat({ unreadCount: 0, activeSpaceId, peerTopicId: topicId })
            break
          case 'delete-group':
            if (!activeSpaceId) return
            if (!topicId) return

            let confirmed = confirm(
              'Are you sure you want to DELETE this group? This cannot be undone.',
            )

            if (!confirmed) {
              return
            }

            doDeleteChat({
              peerTopicId: topicId,
              activeSpaceId,
            })
            break
        }
      },
      [activeSpaceId, doDeleteChat, doEditChat],
    ),
  })

  return {
    openAdminMenu,
    openMemberMenu,
    openGroupMenu,
  }
}
