// Jibreel QL

import { SunErrorCodes } from './errors'

export enum JQLClientMessageKind {
  Query = 'query',
  Mutation = 'mutation',
  Ack = 'ack',
  Ping = 'pi',
  ConnectionInit = 'connection_init',
}

type JQLMethodNames = string

export interface JQLClientMessage {
  id: string
  kind: JQLClientMessageKind
  time?: number
  method?: JQLMethodNames
  payload?: any
}

export interface JQLClientPingMessage {
  id: string
  kind: JQLClientMessageKind.Ping
}

export enum JQLServerMessageKind {
  Message = 'msg',
  Error = 'error',
  Acknowledgment = 'ack',
  ConnectionAcknowledgment = 'connection_ack',
  Reply = 'reply',
  Pong = 'po',
}

export type JQLServerMethodNames = string
export interface JQLServerAckMessage {
  id: string
  kind: JQLServerMessageKind.Acknowledgment
}
export interface JQLServerConnectionAckMessage {
  id: string
  kind: JQLServerMessageKind.ConnectionAcknowledgment
  server: string
}
export interface JQLServerErrorMessage {
  id: string
  kind: JQLServerMessageKind.Error
  code: SunErrorCodes
  message?: string
}
export interface JQLServerMessage {
  id: string
  time: number
  kind: JQLServerMessageKind.Message
  method: JQLServerMethodNames
  payload: any
}
export interface JQLServerReply {
  id: string
  time: number
  kind: JQLServerMessageKind.Reply
  payload: any
}
export interface JQLServerPong {
  id: string
  kind: JQLServerMessageKind.Pong
}

export type JQLAnyServerMessage =
  | JQLServerAckMessage
  | JQLServerConnectionAckMessage
  | JQLServerErrorMessage
  | JQLServerMessage
  | JQLServerPong
  | JQLServerReply
