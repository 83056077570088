import { useNurMutation } from '@there/components/sun/use-mutation'
import { SendNewMessageMessage } from '@there/sun/modules/sendNewMessage'
interface Data {
  done: boolean
}

type RemoteVariables = SendNewMessageMessage['payload']

export interface LocalSendNewMessageVariables extends RemoteVariables {
  __extra: {
    senderId: string
  }
}

export type SendChatNewMessageType = ({
  text,
  mediaObjectId,
  mediaType,
  thumbObjectId,
  mediaW,
  mediaH,
  documentId,
  document,
}: RemoteVariables) => void

export const useSendNewMessage = () => {
  return useNurMutation<Data, LocalSendNewMessageVariables>({
    method: 'sendNewMessage',
  })
}
