import * as React from 'react'

export const FlexRow = ({
  justifyContent,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { justifyContent?: 'center' }) => (
  <div style={{ display: 'flex', justifyContent }} {...props} />
)

export const FillHorizontal = ({
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    style={{
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
    {...props}
  />
)
