interface Json {
  [key: string]: any
}

export interface Store {
  writeData: (data: Json) => Promise<void>
  readData: () => Promise<Json>
  writeMetadata: (data: Json) => Promise<void>
  readMetadata: () => Promise<Json>
  clear: () => Promise<void>
  onOnlineChange: (cb: (isOnline: boolean) => void) => void
}

export const makeLocalStorageStore = (): Store => {
  const dataKey = 'nur_v1'
  const metaDataKey = 'nur_meta_v1'

  return {
    async writeData(data: Record<string, any>) {
      if (typeof window === 'undefined') return
      localStorage.setItem(dataKey, JSON.stringify(data))
    },

    async readData() {
      if (typeof window === 'undefined') return {}

      const json = localStorage.getItem(dataKey) || '{}'
      return JSON.parse(json)
    },

    async writeMetadata(data: any) {
      if (typeof window === 'undefined') return

      localStorage.setItem(metaDataKey, JSON.stringify(data))
    },

    async readMetadata() {
      if (typeof window === 'undefined') return {}

      const metadataJson = localStorage.getItem(metaDataKey) || '{}'
      return JSON.parse(metadataJson)
    },

    async clear() {
      if (typeof window === 'undefined') return

      localStorage.removeItem(dataKey)
      localStorage.removeItem(metaDataKey)
    },

    onOnlineChange(cb: (isOnline: boolean) => void) {
      if (typeof window === 'undefined') return

      window.addEventListener('online', () => {
        cb(true)
      })
      window.addEventListener('offline', () => {
        cb(false)
      })
    },
  }
}
