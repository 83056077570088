import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { View } from 'react-native'
import { useDebounceCallback } from '../shared/use-debounce-callback'
import { useLatest } from '../shared/use-latest'
import { SearchInput } from './SearchInput'
import { ConfigType } from './use-picker'

type Props = {
  config: ConfigType
  setSearching: Dispatch<SetStateAction<boolean>>
  search: (query: string) => void
  isOpen: boolean
}

export const SearchGif = ({ config, setSearching, search, isOpen }: Props) => {
  let [searchQuery, setSearchQuery] = useState<string>('')

  let isOpenRef = useLatest(isOpen)
  useEffect(() => {
    if (isOpen) return
    let timeout = setTimeout(() => {
      if (isOpenRef.current) return
      setSearchQuery('')
      setSearching(false)
    }, 10_000)

    return () => clearTimeout(timeout)
  }, [isOpen, isOpenRef, setSearching])

  let searchGif = useCallback(
    (query: string) => {
      search(query)
    },
    [search],
  )

  let searchQueryRef = useLatest(searchQuery)
  const { debouncedCallback } = useDebounceCallback(() => {
    searchGif(searchQueryRef.current)
  }, 200)

  useEffect(() => {
    if (searchQuery.length === 0) {
      setSearching(false)
      return
    }
    setSearching(true)
    debouncedCallback()
  }, [debouncedCallback, searchQuery.length, setSearching])

  return (
    <>
      <SearchInput
        config={config}
        onSearchChanges={setSearchQuery}
        searchInput={searchQuery}
      />
    </>
  )
}
