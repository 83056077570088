import { RtcPeer } from '@there/components/shared/rtc-peer'
import { useLatest } from '@there/components/shared/use-latest'
import { RtcAvatar } from '@there/components/shared/use-rtc-avatars'
import { ipc, isElectron } from '@there/desktop/utils/electron-api'
import useInterval from '@use-it/interval'
import { useCallback, useEffect, useRef } from 'react'
import StatsGatherer from './statsGatherer'
import type { PeerStatsRecord } from '@there/sun/model/rtcStats'
import Sentry from '@there/app/utils/sentry'
import { useSendRtcStats } from '@there/components/main/useSendRtcStats'

const debug = require('debug')('desktop:use-stats-gatherer')

const TRACKING_INTERVAL_SECONDS = 40

interface Input {
  enabled: boolean
  dialogId: string | undefined
  sessionId: string | undefined | null
  participants: RtcAvatar[] | undefined
  sharing: ('camera' | 'mic' | 'screen')[]
}

/** To be enabled only in "RtcWindow", if done elsewhere, make sure to check its db sync calls */
export function useStatsGatherer({
  enabled,
  dialogId,
  sessionId,
  participants,
  sharing,
}: Input): (peer: RtcPeer<any>, peerUserId: string) => void {
  let pendingStatsRecords = useRef<PeerStatsRecord[]>([])

  let dialogIdRef = useLatest(dialogId)
  let sessionIdRef = useLatest(sessionId)
  let roomSizeRef = useLatest(participants?.length)
  let sharingRef = useLatest(sharing)
  let enabledRef = useLatest(enabled)

  const trackPeer = useCallback(
    (peer: RtcPeer<any>, peerUserId: string) => {
      function start() {
        if (!enabledRef.current) {
          debug('Called trackPeer when not enabled. It will not work.')
          return
        }

        if (!peer.peer) {
          console.warn('[gatherer] Failed to get peer handle to track')
          return
        }
        // Gatherer
        let gatherer: StatsGatherer | null = new StatsGatherer(peer.peer, {
          interval: TRACKING_INTERVAL_SECONDS,
        })

        debug('tracking peer', peer.id)

        let oldPeerId = peer.id

        gatherer.on('stats', (statsEvent) => {
          if (!dialogIdRef.current) return
          if (!sessionIdRef.current) return

          debug('stats event', statsEvent, sharingRef.current || [])

          // Park for sending
          pendingStatsRecords.current?.push({
            dialogId: dialogIdRef.current,
            peerId: peer.id,
            roomSize: roomSizeRef.current || 0,
            sessionId: sessionIdRef.current,
            sharing: sharingRef.current || [],
            stats: statsEvent,
            peerUserId,
          })

          // update
          oldPeerId = peer.id
        })

        peer.addListener('peerDestroy', () => {
          // Stop it with a timeout to give room for logging error msg probably figure out a better solution
          setTimeout(() => {
            debug('stopped tracking peer', oldPeerId, peerUserId)
            gatherer?.removeAllListeners()
            gatherer = null
          }, 100)
        })
      }

      if (peer.peer) {
        start()
      }

      // Slight timeout makes sure peer.peer is created
      peer.addListener('peerInitialize', () => {
        start()
      })
    },
    [dialogIdRef, enabledRef, roomSizeRef, sessionIdRef, sharingRef],
  )
  let [, sendRtcStats] = useSendRtcStats()

  // sync array with db
  useInterval(
    () => {
      if (!pendingStatsRecords.current) {
        pendingStatsRecords.current = []
        return
      }
      if (pendingStatsRecords.current?.length > 12) {
        Sentry.captureMessage(
          'pending stats logs are more than 12... Not sending.',
        )
        pendingStatsRecords.current = []
        return
      }

      if (pendingStatsRecords.current.length === 0) {
        return
      }

      // send
      if (isElectron) {
        ipc
          ?.invoke('feed:general-ipc', {
            event: 'nur-send-rtc-stats',
            payload: pendingStatsRecords.current,
          })
          .catch(() => {})
      } else {
        // Browser
        sendRtcStats({
          peerStats: pendingStatsRecords.current,
        })
      }

      // flush
      pendingStatsRecords.current = []
    },
    enabled ? TRACKING_INTERVAL_SECONDS * 1000 : null,
  )

  useEffect(() => {
    console.info('trackPeer')
  }, [trackPeer])

  // let trackPeerFunction = useLatest(trackPeer)

  // This way it's more stable
  // return trackPeerFunction.current || (() => {})
  return trackPeer
}
