import { useNurMutation } from '@there/components/sun/use-mutation'
import type { RtcStatsMessage } from '@there/sun/modules/rtcStats'
interface Data {
  done: boolean
}

export const useSendRtcStats = () => {
  return useNurMutation<Data, RtcStatsMessage['payload']>({
    method: 'rtcStats',
  })
}
