import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export function NoorLogo(props: SvgProps) {
  return (
    <Svg width={30} height={31} fill="none" viewBox="0 0 30 31" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 .13a15.1 15.1 0 0115 13.62H0A15.1 15.1 0 0115 .13zm15 16.3c-.8 7.7-7.21 13.7-15 13.7-7.78 0-14.2-6-15-13.69h5.7c.72 4.72 4.53 8.32 9.11 8.32 4.59 0 8.4-3.6 9.12-8.33H30z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}
