import { RoomsTab } from '@there/components/v2/Home'
import { ChatsTab } from '@there/components/v2/ChatsTab'
import { useAtom } from 'jotai'
import React from 'react'
import { contentViewAtom } from '../atoms/contentViewAtom'
import { ChatView } from './ChatView'
import { HomeTab } from './HomeTab'
import { GameRoomView } from '../gameRoom/GameRoomView'
import { useMainWindowContext } from '../shared/use-main-window'
import { useEffect } from 'react'

export const ContentView = () => {
  let { mode } = useMainWindowContext()

  return (
    <>
      {/* <Tabs /> */}
      {mode === 'home' && <HomeTab />}
      {mode === 'room' && <RoomsTab />}
      {/* {contentView === 'chats' && <ChatsTab />} */}

      {mode === 'chat' && <ChatView />}
    </>
  )
}
