import { GoodButton } from '@there/components/shared/GoodButton'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { View, Pressable } from 'react-native'
import { useModals } from '../feed/ModalsContext'
import { AttachFileIcon, Image } from '../main/mainIcons'
import { MessageTypeInput } from './ChatCompose'
import { useChatUploader } from './useFileUploadManager'

type Props = {
  sendChatMessage: ({}: MessageTypeInput) => void
  textMessage: string
  peerTopicId: string | null
  peerUserId: string | null
}

export const ChatUploadButton = ({
  sendChatMessage,
  textMessage,
  peerTopicId,
  peerUserId,
}: Props) => {
  let { uploadFile } = useChatUploader()

  const onPress = useCallback(async () => {
    try {
      if (typeof window !== 'undefined') {
        const fileHandles = await window.showOpenFilePicker(pickerOpts)
        // TODO: handle multiple files
        let fileHandle = fileHandles[0]
        // console.log('filehandle', fileHandle)

        if (!fileHandle) return

        const file = await fileHandle.getFile()

        if (file.size > 25_000_000) {
          alert('Too big!')
          return
        }

        uploadFile({ file }).then((document) => {
          sendChatMessage({
            peerTopicId: peerTopicId,
            peerUserId: peerUserId,
            text: textMessage,
            document,
            documentId: document.id,
          })
        })
      }
    } catch (error) {
      if (!(error instanceof Error)) return
      if (error.message.toLowerCase().includes('abort')) {
        return
      }
      // Failed to uploadThe user aborted a request.
      alert('Failed to upload' + error.message)
    }
  }, [peerTopicId, peerUserId, sendChatMessage, textMessage, uploadFile])

  return (
    <View
      style={[
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 30,
          paddingRight: 8,
          paddingLeft: 4,
        },
      ]}
    >
      <GoodButton
        onPress={onPress}
        icon={<AttachFileIcon opacity={0.6} />}
        variant="transparent"
      />
    </View>
  )
}

const pickerOpts = {
  types: [
    {
      description: 'File',
      accept: {
        // 'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
      },
    },
  ],
  excludeAcceptAllOption: true,
  multiple: false,
}

//https://usenoor.fra1.digitaloceanspaces.com/

function _arrayBufferToBase64(buffer: ArrayBuffer | Buffer) {
  let binary = ''
  let bytes = new Uint8Array(buffer)
  let len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export type PhotoDimensions = { w: number; h: number }

export const calculateImageDimensions = (
  dimensions: PhotoDimensions,
  maxDim: number,
): {
  w: number
  h: number
} => {
  let imageHeight: number = dimensions.h
  let imageWidth: number = dimensions.w
  if (dimensions.h >= dimensions.w) {
    imageHeight = maxDim
    imageWidth = (dimensions.w * maxDim) / dimensions.h
  } else {
    imageHeight = (dimensions.h * maxDim) / dimensions.w
    imageWidth = maxDim
  }

  return {
    w: imageWidth,
    h: imageHeight,
  }
}
