import { useAppContext } from '@there/components/shared/AppContext'
import { useCallback, useEffect, useRef } from 'react'
import { useSystemAudio } from '../feed/useSystemAudio'
import { useCurrentMediaDevicesContext } from '../shared/CurrentMediaDevicesContext'
import { useLatest } from '../shared/use-latest'
import { useRtcContext } from '../shared/use-rtc'
const debug = require('debug')('desktop:system-audio')

export function SystemAudio() {
  let rtcManager = useRtcContext()
  let { currentUserId } = useAppContext()
  let { isSharingSpeakerSelected } = useSystemAudio()
  const enabled = rtcManager.enabled

  const audioRef = useRef<HTMLAudioElement | undefined>(undefined)

  const { currentSpeaker } = useCurrentMediaDevicesContext()

  const playSystemAudio = useCallback(
    async (stream: MediaStream) => {
      let audioElement = audioRef.current

      if (!audioElement) {
        audioRef.current = audioElement = new Audio()
        debug('audioTag created', audioElement)
      }

      let isPlaying = !audioElement.paused
      debug('isPlaying ', isPlaying)

      audioElement.volume = 0
      if (currentSpeaker && 'setSinkId' in audioElement) {
        try {
          //@ts-ignore
          await audioElement?.setSinkId(currentSpeaker.id)
        } catch (error) {
          let deviceNotFound =
            error instanceof Error &&
            error.message?.includes('Requested device not found')

          console.warn(error)
          // Ignore requested not found errors polling the console
          if (!deviceNotFound) {
            console.error(error)
          }
        }
        debug('setSpeakerToAudio', currentSpeaker, audioElement)
      }
      audioElement.volume = 1
      // Fix for AirPods pausing audio when you take one AirPod off
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      audioElement.onpause = () => {
        audioElement?.play().catch(() => {
          debug('[onpause] failed to play audio')
        })
      }

      if (stream?.getAudioTracks()[0]) {
        stream.getAudioTracks()[0].onunmute = () => {
          debug('on-unmute track')
          if (audioElement) {
            console.info('[onunmute] handler called')
            audioElement.srcObject = stream || null
            debug('[onunmute] play audio')
            audioElement?.play().catch(() => {
              debug('[onunmute] failed to play audio')
              console.warn('failed to play')
            })
          }
        }
      }

      if (
        stream &&
        (!isPlaying ||
          !audioElement.srcObject ||
          (audioElement.srcObject instanceof MediaStream &&
            // or tracks not match (important)
            (audioElement.srcObject?.id !== stream?.id ||
              audioElement.srcObject?.getAudioTracks()[0].id !==
                stream.getAudioTracks()[0].id)) ||
          audioElement.srcObject !== stream)
      ) {
        audioElement.srcObject = stream
        debug('play audio')
        // play
        audioElement?.play().catch(() => {
          debug('failed to play audio')
          console.warn('failed to play')
        })
      }
      debug('----END----', 'playSystemAudio')
    },
    [currentSpeaker],
  )

  // Add audio tags for system audio
  let systemStream = rtcManager._streams?.systemAudio
  let currentUserIdRef = useLatest(currentUserId)
  useEffect(() => {
    if (!enabled) return
    if (!isSharingSpeakerSelected) return

    let audioElement: HTMLAudioElement
    if (systemStream?.stream && currentUserIdRef.current) {
      debug('should play system audio for self, ', {
        audioRef: audioRef.current,
        currentSpeaker,
        systemStream: systemStream.stream,
      })
      playSystemAudio(systemStream.stream).then(() => {
        console.info('play system audio')
      })
    }

    return () => {
      // clean-up created audio tag
      audioRef.current?.remove()
      audioRef.current = undefined
      debug('clean-up system audio tag, audioRef:', audioRef.current)
    }
  }, [
    currentSpeaker,
    currentUserIdRef,
    enabled,
    isSharingSpeakerSelected,
    playSystemAudio,
    systemStream,
  ])

  return null
}
