import React, { memo, useCallback, useEffect, useState } from 'react'
import { useTheme } from '../feed/ThemeContext'
import styled from 'styled-components'
import { Dropdown } from '../feed/Dropdown'
import { Space } from '../shared/Space'
import {
  electronApi,
  electronVersion,
  ipc,
  useListenToIpc,
} from '@there/desktop/utils/electron-api'
import { View } from 'react-native'
import { useSelect } from 'downshift'
import { useSettingsWindowContext } from './use-settings-window'
import { Tooltip } from '../shared/Tooltip'
import { CircleButton } from '@there/components/main/CircleButton'
import { useServiceWorker } from '../shared/ServiceWorker'
import { getRelativeDate } from '@there/components/main/get-last-seen'
import { SettingsIcon } from '../feed/HeaderIcons'
import { useLogOutAction } from '@there/components/feed/BackgroundHooks/use-logout'
import { useLatest } from '@there/components/shared/use-latest'
import { useSpaceContext } from '../shared/spaceContext'
import { fromGlobalId } from '@there/tower/utils/global-id'
import { useAppContext } from '../shared/AppContext'
import { CheckingIcon } from './CheckingIcon'
import { DownloadingIcon } from './DownloadingIcon'
import { useProSpace } from '../pro/useProSpace'
import { GoodButton } from '@there/components/shared/GoodButton'
import { useWindowContext } from '../shared/WindowContext'
import { logEvent } from '@there/components/shared/use-analytics'

export type SettingItem = {
  label: string
  onClick?: () => void
  size: 'normal' | 'small'
  disabled?: boolean
}

const versionDate = process.env.versionTime
  ? getRelativeDate(new Date(parseInt(process.env.versionTime)))
  : ''

// Links
export const crispUrl = `https://go.crisp.chat/chat/embed/?website_id=cbfa35e7-76b1-45ac-9d9b-6aa76541cba5`
export const SettingsDropdown = memo(({ v2 }: { v2?: boolean }) => {
  const { settingsWindowDispatch: dispatch } = useSettingsWindowContext()
  const { activeSpaceId } = useAppContext()

  const t = useTheme()
  const [showBadge, setShowBadge] = useState(false)
  const [downloadedPercent, setDownloadProgress] = useState(0)
  const [updateState, setUpdateState] = useState('none')
  let { accepted, showSkipWaiting, updateServiceWorker } = useServiceWorker()
  const logOut = useLogOutAction()
  let { isElectron, hasDesktopVersion } = useWindowContext()
  const { currentUser } = useAppContext()

  let isUpdateAvailable = showBadge || showSkipWaiting

  let openLink = (link: string) => {
    if (electronApi) {
      electronApi?.openExternalUrl(link)
    } else {
      window.open(link, '__blank')
    }
  }

  let { isProSpace } = useProSpace(activeSpaceId)

  let desktopVersion: SettingItem[] = isElectron
    ? [
        {
          label: `Desktop: v${electronVersion}`,
          size: 'small',
          disabled: true,
        },
      ]
    : []

  let downloadDesktopVersion: SettingItem[] =
    hasDesktopVersion && !isElectron
      ? [
          {
            label: `Download Desktop App`,
            size: 'small',
            disabled: false,
            onClick: () => {
              window.open('https://usenoor.com/download')
            },
          },
        ]
      : []

  const settingItems: SettingItem[] = [
    {
      label: 'Talk to Us',
      onClick: () => {
        logEvent('User Clicked Help')
        if (ipc) {
          ipc?.invoke('open-help-window', currentUser)
        } else {
          window.open(crispUrl, '__blank')
        }
      },
      size: 'normal',
    },

    {
      label:
        updateState === 'downloaded' || isUpdateAvailable
          ? 'Click to Update (1)'
          : downloadedPercent
          ? `Downloading... %${downloadedPercent.toFixed(1)}`
          : updateState === 'checking'
          ? 'Checking for Updates'
          : `Check for Updates`,
      size: 'normal',
      onClick: () => {
        console.info('updateState=', updateState)
        // setDownloadProgress(0)

        if (updateState === 'downloaded' || showBadge) {
          // Workaround for v0.17.10
          setTimeout(() => {
            ipc?.invoke('there:exit-now')
          }, 2500)
          setTimeout(() => {
            electronApi?.quitApp()
          }, 4000)
        }

        showSkipWaiting ? accepted() : ipc?.invoke('there:update')

        if (!showSkipWaiting) {
          // check
          updateServiceWorker()
        }
      },
    },

    {
      label: 'Preferences',
      onClick: () => {
        dispatch({
          type: 'open window',
          window: 'preferences',
        })
      },
      size: 'normal',
    },

    // ---
    {
      label: `Noor v${process.env.version} (${versionDate})`,
      size: 'small',
      disabled: true,
    },
    ...downloadDesktopVersion,
    ...desktopVersion,

    {
      label: isProSpace ? 'You are pro' : 'Upgrade to Pro',
      onClick: () => {
        if (isProSpace) return
        if (activeSpaceId) {
          const spaceInfo = fromGlobalId(activeSpaceId)
          openLink(`https://usenoor.com/billing/invoice?id=${spaceInfo.id}`)
        }
      },
      size: 'small',
      disabled: isProSpace,
    },

    {
      label: 'Twitter - @NoorChat',
      onClick: () => {
        openLink('https://x.com/NoorChat')
      },
      size: 'small',
    },

    {
      label: 'Log Out',
      size: 'small',
      onClick: () => {
        logOut()
      },
    },
    {
      label: 'Quit',
      onClick: () => {
        electronApi?.quitApp()
      },
      size: 'small',
    },
  ]

  const {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    toggleMenu,
    closeMenu,
  } = useSelect({
    items: settingItems,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return
      if (selectedItem.onClick) {
        selectedItem.onClick()
      }
    },
  })

  useListenToIpc(
    'there:update-downloaded',
    useCallback(() => {
      setShowBadge(true)
      setUpdateState('downloaded')
    }, []),
  )

  let downloadedPercentRef = useLatest(downloadedPercent)

  useListenToIpc(
    'update-download-progress',
    useCallback(
      (_, data: { percent: number }) => {
        if (
          downloadedPercentRef.current &&
          data.percent < downloadedPercentRef.current
        ) {
          // Ignore a second download that started afterwards
          return
        }

        setDownloadProgress(data.percent)
      },
      [downloadedPercentRef],
    ),
  )

  useListenToIpc(
    'update-state-change',
    useCallback((_, data) => {
      setUpdateState(data.state)
    }, []),
  )

  // In downshift we need to put separators separately
  const separatorsAtIndex = new Set([3, 5, 7])

  const renderBadge = useCallback(() => {
    if (isUpdateAvailable) {
      return (
        <BadgeWrapper>
          <Badge />
        </BadgeWrapper>
      )
    }

    if (downloadedPercent) {
      return (
        <BadgeWrapper>
          <DownloadingIcon width={8} height={8} color="#757D86" />
        </BadgeWrapper>
      )
    }

    if (updateState === 'checking') {
      return (
        <BadgeWrapper>
          <CheckingIcon width={10} height={10} color="#757D86" />
        </BadgeWrapper>
      )
    }

    return null
  }, [downloadedPercent, isUpdateAvailable, updateState])

  return (
    <Dropdown.Root>
      <Dropdown.Toggle onPress={toggleMenu}>
        <Tooltip label="Settings" placement="bottomCenter">
          {v2 ? (
            <GoodButton
              icon={
                <>
                  <SettingsIcon color="#757D86" width={18} height={18} />
                  {renderBadge()}
                </>
              }
              onPressHandled={true}
              variant="transparent"
              active={isOpen}
              {...getToggleButtonProps(
                { refKey: 'noref' },
                { suppressRefError: true },
              )}
            />
          ) : (
            <CircleButton
              backgroundMode="transparent"
              isActive={isOpen}
              {...getToggleButtonProps(
                { refKey: 'noref' },
                { suppressRefError: true },
              )}
            >
              <SettingsIcon
                color={t.colors.tertiaryLight}
                width={16}
                height={16}
              />
              {renderBadge()}
            </CircleButton>
          )}
        </Tooltip>
      </Dropdown.Toggle>

      <Dropdown.Menu
        {...getMenuProps(undefined, { suppressRefError: true })}
        isOpen={isOpen}
        onClose={closeMenu}
        width={160}
      >
        <Dropdown.Section>
          <Space vertical={4} />
          {settingItems.map((item, index) => {
            let separator = null
            let itemJsx = null

            // Separator
            if (separatorsAtIndex.has(index)) {
              separator = (
                <Dropdown.Inner type="large">
                  <Separator />
                </Dropdown.Inner>
              )
            }

            // Item Props
            let props = getItemProps({
              item,
              index,
            })
            let highlighted = item.onClick && highlightedIndex === index

            // Decide item style
            if (item.size === 'small') {
              itemJsx = (
                <Dropdown.MiniItem highlighted={highlighted} {...props}>
                  {item.label}
                </Dropdown.MiniItem>
              )
            } else {
              itemJsx = (
                <>
                  <Dropdown.Item
                    isDim={!item.onClick || item.disabled}
                    highlighted={highlighted}
                    {...props}
                  >
                    {item.label}
                  </Dropdown.Item>
                  {item.label.includes('Downloading') ? (
                    <View
                      style={{
                        marginVertical: 2,
                        marginHorizontal: 8,
                        height: 2,
                        backgroundColor: 'rgba(255,255,255,0.1)',
                      }}
                    >
                      <View
                        style={{
                          width: `${downloadedPercent.toFixed(2)}%`,
                          height: 2,
                          backgroundColor: '#4C94FF',
                          borderRadius: 3,
                        }}
                      />
                    </View>
                  ) : null}
                </>
              )
            }

            return (
              <React.Fragment key={item.label}>
                {separator}
                {itemJsx}
              </React.Fragment>
            )
          })}
          <Space vertical={4} />
        </Dropdown.Section>
      </Dropdown.Menu>
    </Dropdown.Root>
  )
})

const BadgeWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <View
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 2,
        borderRadius: 200,
        backgroundColor: '#2E313C',
        padding: 2,
      }}
    >
      {children}
    </View>
  )
}

export const SettingsButton = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 30px;
  border: 1.5px solid ${(p) => p.theme.colors.transparentInputBackground};
  box-sizing: border-box;
  /* background: #e9e5e6; */

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes['15']}px;
  color: ${(p) => p.theme.colors.secondaryText};

  transition: ${(p) => p.theme.transition.background};

  :hover {
    background: ${(p) => p.theme.colors.hoverItemBackground};
  }

  :active {
    background: ${(p) => p.theme.colors.activeItemBackground};
  }
`

const Separator = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  margin: 4px 0px;
`

const Badge = styled.div`
  width: ${8}px;
  height: ${8}px;
  background: ${(p) => p.theme.colors.primary};
  border-radius: 50%;
`

const CogIcon = (props: { color?: string }) => {
  return (
    <svg width={22} height={22} fill="none" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.69V5h3.69l2.9-2.9 2.9 2.9H19v4.51L21.49 12 19 14.49V19h-4.51l-2.9 2.9-2.9-2.9H5v-3.69L1.69 12 5 8.69zM12 15a3 3 0 100-6 3 3 0 000 6z"
        fill={props.color ? props.color : '#fff'}
      />
    </svg>
  )
}
