import { AddStatusMessage, AddStatusResult } from '@there/sun/modules/addStatus'
import { DocumentInfo } from '@there/sun/utils/node-types'
import cuid from 'cuid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ActivityIndicator,
  Image,
  ScrollView,
  Text,
  TextInput,
  View,
} from 'react-native'
import { BubbleMessageIcon } from '../feed/BubbleMessageIcons'
import { useTheme } from '../feed/ThemeContext'
import { AutoHeightTextInput } from '../feedBox/AutoHeightTextInput'
import { useAppContext } from '../shared/AppContext'
import { useNurMutation } from '../sun/use-mutation'
import { useTransition, a, useSpring } from '@react-spring/native'
import { GoodButton } from '../shared/GoodButton'
import { SendMessageIcon } from '../chat/SendMessageIcon'
import { usePickerContext } from '../shared/PickerContext'
import { useAtom } from 'jotai'
import { feedOpenAtom } from '../atoms/feedAtoms'
import { useMemberships } from '../feed/useMemberships'
import { Pressable } from '../shared/Pressable'
import { useNoorayContext } from '../shared/NoorayContext'
import { useDrop } from '../v2/useDrop'
import { useChatUploader } from '../v2/useNoorayUploadManager'

type SpaceListType = {
  id: string
  name: string
}

export const NCompose = () => {
  let theme = useTheme()
  let textInputRef = useRef<TextInput | null>(null)
  let [text, setText] = useState('')
  let { activeSpaceId, currentUserId } = useAppContext()
  let { memberships } = useMemberships()
  // let { selectedSpaceIds, dispatch } = useNoorayContext()
  let [draftedImage, setDraftedImage] = useState<string | undefined>(undefined)
  let [draftedImageFile, setDraftedImageFile] = useState<File | undefined>(
    undefined,
  )
  let [draftedImageDimes, setDraftedImageDims] = useState<
    PhotoDimensions | undefined
  >(undefined)
  let { uploadFile, draftFile, isUploading } = useChatUploader()

  // useEffect(() => {
  //   if (!activeSpaceId) return
  //   if (!selectedSpaceIds || selectedSpaceIds.length === 0) {
  //     dispatch({
  //       type: 'selected spaceIds changed',
  //       selectedSpaceIds: [activeSpaceId],
  //     })
  //   }
  // }, [activeSpaceId, activeSpaceName, dispatch, selectedSpaceIds])

  // let spaceList: SpaceListType[] = useMemo(() => {
  //   if (!memberships) return []
  //   return memberships.map((membership) => {
  //     return { id: membership.space.id, name: membership.space.name }
  //   })
  // }, [memberships])

  // let toggleSpaceSelect = useCallback(
  //   (spaceId) => {
  //     let isSelected = selectedSpaceIds.includes(spaceId)
  //     if (isSelected) {
  //       dispatch({
  //         type: 'selected spaceIds changed',
  //         selectedSpaceIds: selectedSpaceIds.filter((id) => id !== spaceId),
  //       })
  //     } else {
  //       dispatch({
  //         type: 'selected spaceIds changed',
  //         selectedSpaceIds: [...selectedSpaceIds, spaceId],
  //       })
  //     }
  //   },
  //   [dispatch, selectedSpaceIds],
  // )

  let {
    open: openPicker,
    selectedGif,
    clearGif,
    close: closePicker,
    selectedEmoji,
    clearEmoji,
  } = usePickerContext()
  let pickerWrapperRef = useRef<View | null>(null)
  const openGifPicker = useCallback(() => {
    openPicker({
      key: 'status',
      ref: pickerWrapperRef,
      activeModes: ['gif'],
      initialMode: 'gif',
    })
  }, [openPicker])

  let [, addStatus] = useAddStatus()

  let { statuses } = useStatusesContext()
  let [replyToStatusId, setReplyToStatusId] = useAtom(replyToStatusIdAtom)
  let [viewState, setViewState] = useAtom(statusFeedStateAtom)

  let replyToStatus = useMemo(() => {
    return statuses.find((status) => status.id === replyToStatusId)
  }, [replyToStatusId, statuses])

  const { postedStatus } = useStatusStreak()

  const submit = useCallback(
    async (media?: DocumentInfo | undefined) => {
      if (!activeSpaceId || !currentUserId) return
      if (!text.trim() && !media) return null

      addStatus({
        id: `Status:` + cuid(),
        spaceIds: [activeSpaceId],
        text,
        document: media,

        // we have replyToStatusId only when topic not created yet
        // replyToStatusId:
        //   replyToStatus && viewState === 'status'
        //     ? replyToStatus.topic
        //       ? undefined
        //       : replyToStatus.id
        //     : undefined,
        replyToStatusId,
        topicId:
          replyToStatus && viewState === 'status'
            ? replyToStatus?.topic
              ? replyToStatus.topic.id
              : `Topic:` + cuid()
            : undefined,
        __extra: {
          userId: currentUserId,
        },
      })

      setText('')

      logEvent('User Posted Status')

      postedStatus()
    },
    [
      activeSpaceId,
      addStatus,
      currentUserId,
      replyToStatus,
      replyToStatusId,
      text,
      viewState,
      postedStatus,
    ],
  )

  let statusSelectedGif = selectedGif?.status
  // status gif selected
  useEffect(() => {
    if (!statusSelectedGif) return
    submit({
      id: cuid(),
      compressed: false,
      fileExt: 'mp4',
      fileName: statusSelectedGif.name,
      type: 'Gif',
      objectId: null,
      size: statusSelectedGif.size,
      mediaW: statusSelectedGif.dims[0],
      mediaH: statusSelectedGif.dims[1],
      remoteUrl: statusSelectedGif.url,
      remotePreview: statusSelectedGif.preview,
    })
    clearGif('status')
    closePicker()
  }, [statusSelectedGif, clearGif, submit, closePicker])

  let statusSelectedEmoji = selectedEmoji?.status
  useEffect(() => {
    if (!statusSelectedEmoji) return
    let emojiUnicode = statusSelectedEmoji.image
      .split('-')
      .map((u) => String.fromCodePoint(parseInt(u, 16)))
      .join('')

    setText((t) => t + emojiUnicode)
    textInputRef.current?.focus()
    clearEmoji('status')
  }, [clearEmoji, statusSelectedEmoji])

  let [isFeedOpen] = useAtom(feedOpenAtom)
  useEffect(() => {
    if (!textInputRef || !textInputRef.current) return
    if (isFeedOpen) {
      textInputRef.current.focus()
    }
  }, [isFeedOpen])

  let openPhotoPicker = useCallback(async () => {
    try {
      if (typeof window !== 'undefined') {
        const fileHandles = await window.showOpenFilePicker(pickerOpts)
        let fileHandle = fileHandles[0]

        if (!fileHandle) return

        const file = await fileHandle.getFile()
        if (file.size > 25_000_000) {
          alert('Too big!')
          return
        }

        draftFile({ file }).then((fileData) => {
          if (!fileData || !fileData.objectUrl) return
          setDraftedImage(fileData.objectUrl)
          setDraftedImageDims(fileData.dimensions)
          setDraftedImageFile(file)
        })
      }
    } catch (error) {
      if (!(error instanceof Error)) return
      if (error.message.toLowerCase().includes('abort')) {
        return
      }
      // Failed to uploadThe user aborted a request.
      alert('Failed to upload' + error.message)
      // console.log(error)
    }
  }, [draftFile])

  let { aboutToDrop, registerDropAction } = useDrop({
    onDrop: (file) =>
      draftFile({ file }).then((fileData) => {
        if (!fileData || !fileData.objectUrl) return
        setDraftedImage(fileData.objectUrl)
        setDraftedImageDims(fileData.dimensions)
        setDraftedImageFile(file)
      }),
  })

  const submitImage = useCallback(() => {
    if (!draftedImageFile) return
    uploadFile({ file: draftedImageFile }).then((document) => {
      submit(document)
      setDraftedImage(undefined)
      setDraftedImageDims(undefined)
      setDraftedImageFile(undefined)
    })
  }, [draftedImageFile, submit, uploadFile])

  const onPaste = useCallback(
    (file: File) => {
      draftFile({ file }).then((fileData) => {
        if (!fileData || !fileData.objectUrl) return
        setDraftedImage(fileData.objectUrl)
        setDraftedImageDims(fileData.dimensions)
        setDraftedImageFile(file)
      })
    },
    [draftFile],
  )
  usePaste(onPaste)

  let hasDraftImage = draftedImage && draftedImageDimes
  let draftedImageHeight = draftedImageDimes
    ? (100 * draftedImageDimes.h) / draftedImageDimes.w
    : 100

  let draftedImageTransition = useTransition(hasDraftImage, {
    from: {
      opacity: 0,
      height: 0,
    },
    enter: {
      opacity: 1,
      height: draftedImageHeight + 10,
    },
    leave: {
      opacity: 0,
      height: 0,
    },
    config: {
      mass: 0.3,
      tension: 400,
      friction: 20,
    },
  })

  let buttonTransition = useTransition(text.length > 0 || hasDraftImage, {
    from: {
      opacity: 0,
      scale: 0.5,
    },
    enter: {
      opacity: 1,
      scale: 1,
    },
    leave: {
      opacity: 0,
      scale: 0.5,
    },
    config: {
      tension: 500,
      friction: 15,
      mass: 0.1,
    },
  })

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingRight: 12,
          paddingLeft: 14,
        }}
        ref={(ref) => {
          registerDropAction(ref)
        }}
      >
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',

            paddingHorizontal: theme.spaces.sidePaddingNarrow,

            borderColor: theme.colors.avatarBorder,
            borderWidth: 1,
            borderRadius: 12,
            backgroundColor: aboutToDrop
              ? 'rgba(255, 255, 255, 0.05)'
              : undefined,
          }}
        >
          <View
            style={{
              width: '100%',

              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <AutoHeightTextInput
              ref={textInputRef}
              lineHeight={18}
              paddingVertical={20}
              style={[
                {
                  flexGrow: 1,
                  marginRight: 6,
                },
                {
                  fontSize: theme.fontSizes.normal,
                  color: theme.colors.primaryText,
                  paddingTop: 10,
                  paddingBottom: 10,
                },
              ]}
              placeholder="What's up?"
              placeholderTextColor={theme.colors.tertiaryText}
              value={text}
              onChangeText={(text) => setText(text)}
              onSubmitEditing={() => {
                if (draftedImageFile) {
                  submitImage()
                } else {
                  submit()
                }
              }}
              onKeyPress={(event) => {
                //@ts-ignore
                switch (event.code) {
                  case 'Escape':
                    setReplyToStatusId(undefined)
                    setViewState('feed')
                    break
                  case 'Enter':
                    //@ts-ignore
                    if (event.shiftKey) return
                    event.preventDefault()
                    if (draftedImageFile) {
                      submitImage()
                    } else {
                      submit()
                    }
                    break
                }
              }}
            />
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 38,
                alignSelf: 'flex-end',
              }}
            >
              {buttonTransition(({ scale, opacity }, item) => {
                return item ? (
                  <a.View
                    style={{
                      opacity,
                      transform: [{ scale }],
                    }}
                  >
                    {isUploading ? (
                      <ActivityIndicator style={{ marginRight: 2 }} />
                    ) : (
                      <GoodButton
                        variant="transparent"
                        icon={<SendMessageIcon />}
                        onPress={() => {
                          if (draftedImageFile) {
                            submitImage()
                          } else {
                            submit()
                          }
                        }}
                      />
                    )}
                  </a.View>
                ) : null
              })}

              <View ref={pickerWrapperRef}>
                <GoodButton
                  icon={<GifIcon />}
                  variant="transparent"
                  onPress={openGifPicker}
                />
              </View>
              <GoodButton
                icon={<ImageIcon color={aboutToDrop ? '#757D86' : '#666E79'} />}
                variant="transparent"
                onPress={openPhotoPicker}
              />
            </View>
          </View>
          {draftedImageTransition(({ height, opacity }, item) => {
            return item ? (
              <a.View
                style={{
                  width: '100%',
                  paddingLeft: 22,
                  paddingTop: 5,
                  paddingBottom: 5,
                  opacity,
                  height,
                }}
              >
                <Image
                  source={{ uri: draftedImage }}
                  style={{
                    width: 100,
                    height: draftedImageHeight,
                    borderRadius: 6,
                  }}
                />
                <Pressable
                  onPress={() => {
                    setDraftedImage(undefined)
                    setDraftedImageDims(undefined)
                    setDraftedImageFile(undefined)
                  }}
                  style={({ hovered, pressed }) => [
                    {
                      position: 'absolute',
                      top: 0,
                      left: 17,
                      width: 14,
                      height: 14,
                      borderRadius: 16,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',

                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                    hovered && {
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    },
                    pressed && {
                      backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    },
                  ]}
                >
                  <SmallCrossIcon color={theme.colors.tertiaryText} />
                </Pressable>
              </a.View>
            ) : null
          })}
          {/* <Space vertical={spaceList.length > 1 ? 0 : 10} />
          <ScrollView
            style={{
              paddingVertical: 5,
              display: spaceList.length > 1 ? 'flex' : 'none',
            }}
            horizontal
            showsHorizontalScrollIndicator={false}
          >
            {spaceList.map((space, index) => {
              let isSelected = selectedSpaceIds.includes(space.id)
              return (
                <Pressable
                  onPress={() => {
                    toggleSpaceSelect(space.id)
                  }}
                  key={space.id}
                  style={{
                    paddingHorizontal: 15,
                    paddingVertical: 4,
                    backgroundColor: isSelected
                      ? 'rgba(255, 255, 255, 0.2)'
                      : 'rgba(255, 255, 255, 0.05)',
                    borderRadius: 24,
                    marginLeft: index > 0 ? 10 : 0,
                  }}
                >
                  <Text
                    style={{
                      fontSize: theme.fontSizes.small,
                      color: theme.colors.tertiaryText,
                    }}
                  >
                    {space.name}
                  </Text>
                </Pressable>
              )
            })}
          </ScrollView> */}
        </View>
      </div>
    </>
  )
}

const pickerOpts = {
  types: [
    {
      description: 'Image',
      accept: {
        'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
      },
    },
  ],
  excludeAcceptAllOption: true,
  multiple: false,
}

import * as React from 'react'
import Svg, { SvgProps, G, Rect, Path } from 'react-native-svg'
import { PhotoDimensions } from '../v2/ChatUploadButton'
import { SmallCrossIcon } from '../main/mainIcons'
import { Space } from '../shared/Space'
import { useReadStatus } from '../feedBox/useReadStatus'
import { useAtomValue } from 'jotai/utils'
import { replyToStatusIdAtom, statusFeedStateAtom } from '../atoms/statusAtom'
import { useStatusesContext } from './useStatusesContext'
import { usePaste } from '../hooks/usePaste'
import { useAddStatus } from '../main/useAddStatus'
import { logEvent } from '@there/components/shared/use-analytics'
import { useStatusStreak } from '@there/components/nooray/StatusStreak'

const ImageIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <G opacity={1}>
      <Rect
        x={1.5}
        y={5.5}
        width={21}
        height={14}
        rx={3.5}
        stroke={props.color ? props.color : '#fff'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m5 17 5.5-7 5.5 7H5Z"
        fill={props.color ? props.color : '#fff'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m12 17 3.5-5 3.5 5h-7Zm5.5-6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        fill={props.color ? props.color : '#fff'}
      />
    </G>
  </Svg>
)

const GifIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <G opacity={0.3}>
      <Rect
        x={1.5}
        y={5.5}
        width={21}
        height={14}
        rx={3.5}
        stroke={props.color ? props.color : '#fff'}
      />
      <Path
        d="M4.5 12.49c0-1.07.3-1.93.92-2.6.61-.67 1.42-1 2.41-1 .77 0 1.42.2 1.95.61.53.4.9.96 1.1 1.67H9.87c-.33-.92-1.01-1.38-2.04-1.38-.73 0-1.3.24-1.73.73a2.93 2.93 0 0 0-.63 1.98c0 .83.21 1.5.64 1.98.43.49 1 .73 1.73.73.6 0 1.1-.17 1.48-.5.38-.33.62-.83.72-1.5H8.11v-.88h2.95v.22c0 1.07-.3 1.94-.91 2.59-.6.65-1.38.97-2.34.97a3.1 3.1 0 0 1-2.4-1 3.74 3.74 0 0 1-.91-2.62ZM13.28 16h-.97V9h.97v7Zm2.6 0h-.96V9h4.42v.9h-3.45v2.23h3.15v.9h-3.15V16Z"
        fill={props.color ? props.color : '#fff'}
      />
    </G>
  </Svg>
)
