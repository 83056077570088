import React from 'react'

export const TypingIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={9} height={7} viewBox="0 0 9 7" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0a1 1 0 00-1 1v5c0 .6.4 1 1 1h7c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1H1zm1 1H1v1h1V1zm3 0h1v1H5V1zm2 0h1v1H7V1zM3 1h1v1H3V1zm2 2H4v1h1V3zM2 5.5c0-.3.2-.5.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM3 3H2v1h1V3zm3 0h1v1H6V3z"
      fill={props.color ? props.color : '#fff'}
    />
  </svg>
)
