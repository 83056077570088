import React from 'react'
import cuid from 'cuid'
import { useTheme } from '../feed/ThemeContext'
import { useHover } from '@there/components/shared/use-hover'
import { useAppContext } from '@there/components/shared/AppContext'
import { useCreateDialog } from '@there/components/main/useCreateDialog'
import { DialogBox } from '@there/components/main/DialogItem'
import { RoomPlusIconLight } from '@there/components/room/RoomPlusIcon'
import { useSpring, a } from '@react-spring/native'
import { Tooltip } from '@there/components/shared/Tooltip'
import { logEvent } from '@there/components/shared/use-analytics'
import { nanoid } from 'nanoid/non-secure'

export const CreateRoomItem = ({ showPlus }: { showPlus: boolean }) => {
  let theme = useTheme()
  let { activeSpaceId } = useAppContext()
  const [hovered, hoverListeners] = useHover()
  let [, createDialog] = useCreateDialog()

  function created() {
    if (!activeSpaceId) return

    createDialog({
      id: cuid(),
      chatId: cuid(),
      sessionId: nanoid(18),
      spaceId: activeSpaceId,
      title: '',
      iconEmoji: null,
    })

    logEvent('User Created a Room')
  }

  let animatedStyle = useSpring({
    opacity: showPlus ? 1 : 0,
    config: {
      mass: 0.1,
      tension: 500,
    },
  })

  return (
    <>
      <DialogBox
        transparent={false}
        active={false}
        hovered={hovered}
        onPress={created}
        centered={true}
        {...hoverListeners}
      >
        <a.View key="1" style={animatedStyle}>
          <RoomPlusIconLight color={theme.colors.quaternaryLight} />
        </a.View>
      </DialogBox>
    </>
  )
}
