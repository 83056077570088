import React from 'react'

type Props = {
  horizontal?: number | string
  vertical?: number | string
}

export const Space = ({ horizontal, vertical }: Props) => (
  <div style={{ width: horizontal, height: vertical, flexShrink: 0 }} />
)
