import styled from 'styled-components'

export const H1 = styled.h1`
  margin: 0;

  font-size: ${(p) => p.theme.fontSizes['17']}px;
  font-weight: ${(p) => p.theme.fontWeights.pageHeading};
  line-height: 1;

  color: ${(p) => p.theme.colors.text};
`

export const H2 = styled.h2`
  margin: 0;

  font-size: ${(p) => p.theme.fontSizes[15]}px;
  font-weight: ${(p) => p.theme.fontWeights.heading};
  line-height: 1;

  color: ${(p) => p.theme.colors.text};
`
