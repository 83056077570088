import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react'

type State = {
  hasCustomBackground: boolean
  previewBackground: string | undefined
  previewBgDarkenPercent: number
}

const initialState = {
  hasCustomBackground: false,
  previewBackground: undefined,
  previewBgDarkenPercent: 0,
}

export type Action =
  | { type: 'custom background state changed'; state: boolean }
  | {
      type: 'preview background added'
      background: string
    }
  | {
      type: 'preview background removed'
    }
  | {
      type: 'preview darken percent changed'
      previewBgDarkenPercent: number
    }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'custom background state changed': {
      // prevent unnecessary re-render
      if (state.hasCustomBackground === action.state) {
        return state
      }
      return { ...state, hasCustomBackground: action.state }
    }
    case 'preview background added': {
      return {
        ...state,
        previewBackground: action.background,
      }
    }
    case 'preview background removed': {
      return {
        ...state,
        previewBackground: undefined,
      }
    }
    case 'preview darken percent changed': {
      return {
        ...state,
        previewBgDarkenPercent: action.previewBgDarkenPercent,
      }
    }

    default:
      return state
  }
}

type UiContextType = State & {
  dispatch: React.Dispatch<Action>
}

const initialContext = {
  ...initialState,
  dispatch: () => {},
}

export const UiContext = createContext<UiContextType>(initialContext)
export const useUiContext = () => useContext(UiContext)

export const UiContextProvider = ({ children }: { children: ReactNode }) => {
  let [state, dispatch] = useReducer(reducer, initialState)

  const value = useMemo(() => {
    return {
      ...state,
      dispatch,
    }
  }, [state, dispatch])

  return <UiContext.Provider value={value}>{children}</UiContext.Provider>
}
