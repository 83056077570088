import { View, ImageBackground, Image } from 'react-native'
import Svg, { SvgProps, Path } from 'react-native-svg'
import { useTheme } from '../feed/ThemeContext'
import { BubbleMessageIcon } from '../feed/BubbleMessageIcons'
import { EmojiView } from '../main/EmojiView'
import {
  ProfilePhoto,
  radiusConstant,
} from '@there/components/feed/ProfilePhoto'
import { ChatPeer } from '@there/components/types/chat'

export const ChatAvatar = ({
  peer,
  size,
  isActive,
  isSelf,
}: {
  peer: ChatPeer
  size: number
  isActive?: boolean
  isSelf?: boolean
}) => {
  let theme = useTheme()
  let peerTopic = 'topic' in peer ? peer.topic : null
  let peerUser = 'user' in peer ? peer.user : null
  return (
    <ImageBackground
      style={{
        width: size,
        height: size,
        borderRadius: size * radiusConstant,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: isActive
        //   ? theme.colors.quaternaryLight
        //   : theme.colors.quinaryLight,
        overflow: 'hidden',
      }}
      source={{ uri: '/side/chat-avatar-bg.jpg', width: size, height: size }}
    >
      {peerTopic ? (
        peerTopic.iconEmoji ? (
          <EmojiView size={25}>{peerTopic.iconEmoji}</EmojiView>
        ) : (
          <ChatIcon />
          // <BubbleMessageIcon
          //   opacity={isActive ? 1 : 0.8}
          //   width={size < 20 ? 10 : 15}
          // />
        )
      ) : peerUser ? (
        <ProfilePhoto size={size} user={peerUser} />
      ) : null}
    </ImageBackground>
  )
}
function SaveIcon(props: SvgProps) {
  return (
    <Svg
      width={12}
      // height={19}
      viewBox="0 0 14 19"
      fill="none"
      {...props}
    >
      <Path
        d="M10 0H4a4 4 0 00-4 4v15l7-5 7 5V4a4 4 0 00-4-4z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}

function ChatIcon(props: SvgProps) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" fill="none" {...props}>
      <Path
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.279 16.5l-1.548 2.802a.917.917 0 01-1.566.063l-1.9-2.865H4.583a2.75 2.75 0 01-2.75-2.75V5.5a2.75 2.75 0 012.75-2.75h12.834a2.75 2.75 0 012.75 2.75v8.25a2.75 2.75 0 01-2.75 2.75h-4.138z"
        fill="#fff"
      />
      <Path
        opacity={0.95}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 6.417h8.25a.917.917 0 110 1.833H5.5a.917.917 0 010-1.833zm0 3.666h4.583a.917.917 0 110 1.834H5.5a.917.917 0 010-1.834z"
        fill="#fff"
      />
    </Svg>
  )
}
