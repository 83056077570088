import styled, { css } from 'styled-components'

export const Body = styled.p<{
  level?: 'tertiaryText' | 'secondaryText'
  maxWidth?: number
  align?: 'center'
}>`
  margin: 0;
  margin-top: 6px;

  font-size: ${(p) => p.theme.fontSizes[13]}px;
  font-weight: ${(p) => p.theme.fontWeights.body};
  line-height: ${(p) => p.theme.lineHeights.body};
  letter-spacing: ${(p) => p.theme.letterSpacing.body};

  ${(p) =>
    p.maxWidth &&
    css`
      max-width: ${p.maxWidth}px;
    `};

  ${(p) =>
    p.align &&
    css`
      text-align: ${p.align};
      margin-left: auto;
      margin-right: auto;
    `};

  ${(p) =>
    p.align === 'center' &&
    p.maxWidth &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};

  color: ${(p) => p.theme.colors[p.level ? p.level : 'secondaryText']};
`

export const SmallBody = styled.p<{
  level?: 'tertiaryText' | 'quaternaryText'
  singleLine?: boolean
}>`
  margin: 0;
  margin-top: 4px;

  font-size: ${(p) => p.theme.fontSizes[11]}px;
  font-weight: ${(p) => p.theme.fontWeights.body};
  line-height: ${(p) => (p.singleLine ? 1 : p.theme.lineHeights.body)};

  color: ${(p) => p.theme.colors[p.level ? p.level : 'tertiaryText']};
`

export const TextColor = styled.span<{
  level: 'secondaryText' | 'tertiaryText' | 'quaternaryText' | 'quinaryText'
}>`
  display: inline;
  color: ${(p) => p.theme.colors[p.level]};
`
