import {
  DataEntity,
  DataValues,
  DataValuesList,
  Link,
  Nodes,
  ObjectData,
  ObjectNode,
  QueryData,
} from '@there/components/sun/cache'
import { filterFalsy } from '@there/shared/utilities/filter-falsy'

export type Deps = { [id: string]: QueryData }

export function normalizeData(
  data: QueryData,
): // root , deps
[QueryData, Deps] {
  let root: DataValues | DataValues[] = {}
  let dependencies: Deps = {}

  if (
    // Added in 2.0
    data === undefined ||
    data === null ||
    typeof data === 'number' ||
    typeof data === 'string' ||
    typeof data === 'boolean'
  ) {
    root = data
  } else if (Array.isArray(data)) {
    let shouldMakeLinks = true
    root = data.map((dataValue: DataValues) => {
      let [normalized, innerDeps] = normalizeData(dataValue)

      if (!isLinkOrNull(normalized)) {
        shouldMakeLinks = false
      }

      dependencies = { ...dependencies, ...innerDeps }
      return normalized
    }) as DataValues[]

    // minify links array
    if (shouldMakeLinks) {
      root = {
        __link: (root as (Link | null)[])
          .map((fullLink) => (fullLink ? fullLink.__link : false))
          .filter(filterFalsy)
          .flat(),
      }
    }
  } else if (typeof data === 'object') {
    root = {}
    for (let [key, value] of Object.entries(data)) {
      let [output, innerDeps] = normalizeData(value)

      dependencies = { ...dependencies, ...innerDeps }
      root[key] = output as DataValues
      // if (isNodeLike(value) || Array.isArray(value) ) {
      //   // link to it
      //   let [link, innerDeps] = normalizeData(value)

      //   // Save
      //   dependencies = { ...dependencies, ...innerDeps }
      //   // @ts-ignore
      //   root[key] = link
      // } else {
      //   root[key] = value
      // }
    }

    if (isNodeLike(data)) {
      // extract root
      dependencies = {
        ...dependencies,
        [(data as ObjectNode).id]: (root as any) as ObjectNode,
      }
      root = { __link: (data as ObjectNode).id }
    } else {
      // leave as is
      root = root
    }
  }

  return [root, dependencies]
}

export function resolveData(
  nodes: Nodes,
  rootNode: DataValuesList,
): DataValuesList | null {
  let result: DataValuesList | null = null

  // Resolve
  if (rootNode === null || typeof rootNode === 'undefined') {
    // do noting to return `null`
  } else if (
    typeof rootNode === 'number' ||
    typeof rootNode === 'string' ||
    typeof rootNode === 'boolean'
  ) {
    return rootNode
  } else if ('__link' in rootNode) {
    result = resolveData(nodes, resolveLink(nodes, rootNode as Link))
  } else if (Array.isArray(rootNode)) {
    result = rootNode.map(
      (node: DataValues) => resolveData(nodes, node) as DataValues,
    )
  } else if (typeof rootNode === 'object') {
    // @ts-ignore
    result = {} as Node | ObjectData
    for (let [key, value] of Object.entries(rootNode)) {
      // @ts-ignore
      result[key] = resolveData(nodes, value ?? null)
    }
  }
  // Clear memory
  rootNode = null

  // Return
  if (result === null) {
    return null
  } else {
    return result
  }
}

export function resolveDataExperimental(
  readNode: (key: string) => DataEntity,
  rootNode: DataValuesList,
): DataValuesList | null {
  let result: DataValuesList | null = null

  // Resolve
  if (rootNode === null || typeof rootNode === 'undefined') {
    // do noting to return `null`
  } else if (
    typeof rootNode === 'number' ||
    typeof rootNode === 'string' ||
    typeof rootNode === 'boolean'
  ) {
    return rootNode
  } else if ('__link' in rootNode && typeof rootNode.__link !== 'undefined') {
    result = resolveDataExperimental(
      readNode,
      resolveLinkExperimental(readNode, rootNode as Link),
    )
  } else if (Array.isArray(rootNode)) {
    result = rootNode.map(
      (node: DataValues) =>
        resolveDataExperimental(readNode, node) as DataValues,
    )
  } else if (typeof rootNode === 'object') {
    // @ts-ignore
    result = {} as Node | ObjectData
    for (let [key, value] of Object.entries(rootNode)) {
      // @ts-ignore
      result[key] = resolveDataExperimental(readNode, value ?? null)
    }
  }
  // Clear memory
  rootNode = null

  // Return
  if (result === null) {
    return null
  } else {
    return result
  }
}
function resolveLinkExperimental(
  getNode: (key: string) => DataEntity,
  link: Link,
): ObjectNode | ObjectNode[] {
  if (typeof link.__link === 'string') {
    return getNode(link.__link) as ObjectNode
  } else if (Array.isArray(link.__link)) {
    return link.__link.map((linkId) => getNode(linkId) as ObjectNode)
  } else {
    return []
  }
}

export function resolveDependencyIds(
  rootNode: DataValuesList,
  dependencyIds: string[] = [],
): string[] {
  // Resolve
  if (rootNode === null || typeof rootNode === 'undefined') {
    // do noting to return `null`
    return dependencyIds
  } else if (
    typeof rootNode === 'number' ||
    typeof rootNode === 'string' ||
    typeof rootNode === 'boolean'
  ) {
    return dependencyIds
  } else if ('__link' in rootNode) {
    return [
      ...dependencyIds,
      ...(Array.isArray(rootNode.__link) ? rootNode.__link : [rootNode.__link]),
    ]
  } else if (Array.isArray(rootNode)) {
    return rootNode.flatMap((node: DataValues) =>
      resolveDependencyIds(node, dependencyIds),
    )
  } else if (typeof rootNode === 'object') {
    let result: string[] = dependencyIds
    for (let key in rootNode) {
      result = [
        ...result,
        ...resolveDependencyIds(rootNode[key] || null, dependencyIds),
      ]
    }
    return result
  }

  return dependencyIds
}

export function resolveLink(
  nodes: Nodes,
  link: Link,
): ObjectNode | ObjectNode[] {
  if (typeof link.__link === 'string') {
    return nodes[link.__link] as ObjectNode
  } else {
    return link.__link.map((linkId) => nodes[linkId] as ObjectNode)
  }
}

export function isLink(dataValue: QueryData) {
  return (
    typeof dataValue === 'object' && dataValue !== null && '__link' in dataValue
  )
}
export function isLinkOrNull(dataValue: QueryData) {
  return dataValue === null || isLink(dataValue)
}

export function isNodeLike(data: QueryData) {
  return typeof data === 'object' && data && 'id' in data
}
