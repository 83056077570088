import * as React from 'react'
import { Dimensions, ScaledSize } from 'react-native'

type DimensionsType = { window: ScaledSize; screen: ScaledSize }

export function useDimensions(): DimensionsType {
  const [dimensions, setDimensions] = React.useState<DimensionsType>({
    window: Dimensions.get('window'),
    screen: Dimensions.get('screen'),
  })

  React.useEffect(() => {
    const onChange = ({
      window,
      screen,
    }: {
      window: ScaledSize
      screen: ScaledSize
    }) => {
      setDimensions({ window, screen })
    }
    Dimensions.addEventListener('change', onChange)

    return () => Dimensions.removeEventListener('change', onChange)
  }, [])

  return dimensions
}
