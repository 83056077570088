import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import { View } from 'react-native'
import { replyToStatusIdAtom, statusFeedStateAtom } from '../atoms/statusAtom'
import { FeedContent } from './FeedContent'
import { StatusContent } from './StatusContent'

export const StatusFeedMain = ({ wrapperWidth }: { wrapperWidth: number }) => {
  let replyToStatusId = useAtomValue(replyToStatusIdAtom)
  let viewState = useAtomValue(statusFeedStateAtom)

  return (
    <View style={{ width: (wrapperWidth * 60) / 100 }}>
      {(viewState === 'feed' || !replyToStatusId) && <FeedContent />}
      {viewState === 'status' && replyToStatusId && <StatusContent />}
    </View>
  )
}
