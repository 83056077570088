import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Tooltip } from '../shared/Tooltip'
import { CircleButton } from '../main/CircleButton'
import { ScreenShareIcon } from '../shared/ScreenShareIcon'
import { useTheme } from '@there/components/feed/ThemeContext'

import { ipc } from '@there/desktop/utils/electron-api'
import type { Permissions } from '@there/desktop/main/config'
import { Pressable, TouchableOpacity } from 'react-native'
import { useRtc, useRtcContext } from '../shared/use-rtc'

export const ScreenSharePinButton = memo(({ active }: { active?: boolean }) => {
  let theme = useTheme()
  const { toggleScreenSharing } = useRtcContext()
  let [hasScreenAccess, setHasScreenAccess] = useState<boolean | null>(null)

  const getHasAccess = useCallback(() => {
    if (!ipc) return Promise.resolve(true)

    return ipc
      .invoke('has-resource-access', ['screen'])
      .then((permissions: Permissions) => {
        setHasScreenAccess(!!permissions.gotScreen)

        return permissions.gotScreen
      })
  }, [])

  const promptToReadGivePermission = useCallback(() => {
    getHasAccess().then((hasScreenAccess) => {
      if (!hasScreenAccess) {
        ipc?.invoke('open-screen-permissions-guide-modal').catch(() => {})
      }
    })
  }, [getHasAccess])

  useEffect(() => {
    getHasAccess()
  }, [getHasAccess])

  const buttonRef = useRef<TouchableOpacity>(null)

  return (
    <TouchableOpacity
      ref={buttonRef}
      onPress={() => {
        const weSharingScreen = Boolean(active)

        if (!hasScreenAccess) {
          // Request permissions
          promptToReadGivePermission()
        } else if (weSharingScreen) {
          toggleScreenSharing()
        } else {
          //get x , y of the button
          buttonRef.current?.measureInWindow((x, y, width, height) => {
            showScreensMenu({
              x,
              y,
            })
          })
        }
      }}
    >
      <Tooltip
        size="mini"
        label={
          <>
            <span>Share screen</span>
          </>
        }
        placement="topLeft"
      >
        {/* todo if is sharing screen we should change the button color */}
        <CircleButton
          size={28}
          backgroundColor="pinButtonBackground"
          activeColor="screenShareButtonIcon"
          isActive={active}
          style={{ marginLeft: 3 }}
        >
          <ScreenShareIcon
            color={
              active ? theme.colors.secondaryLight : theme.colors.tertiaryLight
            }
          />
        </CircleButton>
      </Tooltip>
    </TouchableOpacity>
  )
})

function checkScreenPermissions() {
  ipc?.invoke('request-media-access', ['screen'])
}

export function showScreensMenu({ x, y }: { x: number; y: number }) {
  ipc?.invoke('open-pin-screen-menu', { x, y })
}
