import * as React from 'react'
import Svg, { SvgProps, Path, G, Circle, Rect } from 'react-native-svg'

export const TetherlandLogo = (props: SvgProps) => {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <Rect width="200" height="200" fill="#009B87" />
      <Path d="M152.907 48H48V61.1365H152.907V48Z" fill="white" />
      <Path d="M107.016 93.9781H93.8799V153H107.016V93.9781Z" fill="white" />
    </Svg>
  )
}

export const RoboflowLogo = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 600 600" {...props}>
      <Circle
        cx={300}
        cy={300}
        r={300}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#6706ce"
      />
      <G fillRule="evenodd" clipRule="evenodd" fill="#fff">
        <Path d="M279 268c28-22 16-51-30-46-53 7-121 59-137 121a128 128 0 0 0-4 46 111 111 0 0 0 25 58c12 15 27 27 43 37l11 5c6 0 10-5 10-11 1-2 0-4-1-6-13-23-25-58-22-81 5-55 61-89 105-123z" />
        <Path d="M500 364c-3-7-10-11-17-10-5 0-9 3-13 6-5 5-9 12-13 18l-16 21c-10 13-23 25-37 34-25 14-48 22-75 8-19-16-13-35 4-50 28-26 62-44 88-72 25-27 47-60 52-96 2-11 2-21 0-31-8-49-51-79-95-92a246 246 0 0 0-231 53l-23 23c-11 13-23 29-25 46-2 11 8 19 19 17 7 0 13-5 18-9l17-17c17-17 36-33 58-43 28-13 60-19 91-16s69 17 81 50c12 34-14 67-37 89-39 35-111 71-103 134 4 34 28 62 59 75 54 22 112-5 149-46 12-13 30-36 46-70 4-6 6-15 3-22z" />
      </G>
    </Svg>
  )
}
