import { useEffect, useMemo, useRef } from 'react'

export const useSoundEffect = (
  src: string,
  options?: {
    /** Multiple to apply to normal sound effect sound between 0 to 2 */
    volumeMultiplier: number
  },
) => {
  let audioRef = useRef<HTMLAudioElement | null>(null)
  let volumeMultiplier = options?.volumeMultiplier || 1

  useEffect(() => {
    let audioElement = audioRef.current

    if (!audioElement) {
      audioRef.current = audioElement = new Audio()
    }

    audioElement.src = src
    audioElement.volume = 0.3 * volumeMultiplier

    return () => {
      audioRef.current?.remove()
      audioRef.current = null
    }
  }, [src, volumeMultiplier])

  return useMemo(
    () => [
      () => {
        if (!audioRef.current) return
        audioRef.current.currentTime = 0
        audioRef.current.play()
      },
    ],
    [],
  )
}
