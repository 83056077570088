import Sentry from '@there/app/utils/sentry'
import { EventEmitter } from '@there/components/shared/event-emitter'
import {
  CombinedError,
  makeSunErrorFromMessage,
} from '@there/components/sun/utils/error'
import { getSubscriptionId } from '@there/components/sun/utils/id'
import { filterVariables } from '@there/components/sun/utils/stringifyVariables'
import {
  JQLAnyServerMessage,
  JQLClientMessage,
  JQLClientMessageKind,
  JQLClientPingMessage,
  JQLServerErrorMessage,
  JQLServerMessageKind,
  JQLServerReply,
} from '@there/sun/ws/protocol'
import { nanoid } from 'nanoid'
import { nanoid as nanoidNonSecure } from 'nanoid/non-secure'
import { ConnectionInitMessage } from 'sun/modules/connectionInit'
import {
  Network,
  NurResult,
  Request,
  RequestId,
  Result,
  ResultCallback,
  Sink,
  SubscriptionResult,
  SubscriptionResultCallback,
} from './utils/types'

export type OnError = (error: CombinedError) => void

interface ConnectionOptions {
  url: string
  connectionParams: ConnectionInitMessage['payload']
  onError?: OnError
}

export type ConnectionState = 'open' | 'closed' | 'connecting'

export class DefaultNetwork extends EventEmitter implements Network {
  static _instance: DefaultNetwork | undefined

  results: Map<RequestId, Result>
  activeSubscribers: Map<
    RequestId,
    Set<ResultCallback | SubscriptionResultCallback>
  >
  socket: WebSocket | undefined
  state: ConnectionState
  url: string
  connectionParams: Record<string, any>
  onError: OnError

  queueList: { request: Request; sink: Sink }[]
  nonAckedRequests: Map<RequestId, { request: Request; sink: Sink }>
  recentlyProcessed: Set<string>

  retries: number
  destroyed: boolean

  addListener(event: 'stateChange', cb: (state: ConnectionState) => void): void
  addListener(event: string, cb: (...args: any) => void) {
    super.addListener(event, cb)
  }

  removeListener(
    event: 'stateChange',
    cb: (state: ConnectionState) => void,
  ): this
  removeListener(event: string, cb: (...args: any) => void) {
    return super.removeListener(event, cb)
  }

  emit(event: 'stateChange', state: ConnectionState, ...args: any[]): boolean
  emit(event: string, value: any, ...args: any[]) {
    return super.emit(event, value, ...args)
  }

  constructor(input: ConnectionOptions) {
    super()

    this.url = input.url
    this.connectionParams = input.connectionParams
    this.onError = input.onError || (() => {})
    this.results = new Map()
    this.activeSubscribers = new Map()
    this.queueList = []
    this.nonAckedRequests = new Map()
    this.recentlyProcessed = new Set()

    this.retries = 0
    this.destroyed = false

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.handleOnline = this.handleOnline.bind(this)

    this.state = 'closed'

    // Singleton to not spin new connections
    if (DefaultNetwork._instance) {
      return DefaultNetwork._instance
    } else {
      DefaultNetwork._instance = this
    }

    // auto connect
    this.connect()

    if (typeof window !== 'undefined') {
      window.addEventListener('online', this.handleOnline)
    }
  }

  private debug(base: string, ...args: string[]) {
    console.info(`[network] ${base}`, ...args)
  }

  private setState(state: ConnectionState) {
    this.state = state
    this.debug('stateChange =', state)
    this.emit('stateChange', state)

    if (state === 'open') {
      // Reset retries on connect
      this.retries = 0
      this.clearRetryTimeout()

      // start ping ponging
      this.ping()

      // flush queue
      this.flush()
    }

    if (state === 'connecting') {
      this.clearRetryTimeout()
    }
  }

  connect() {
    if (this.destroyed) return
    // Destroy previous socket
    if (this.socket) {
      this.destroy()
    }
    this.destroyed = false

    this.socket =
      typeof WebSocket !== 'undefined'
        ? new WebSocket(this.url, 'nur-protocol-v1')
        : undefined

    if (!this.socket) {
      if (typeof window !== 'undefined') {
        // console.warn('No socket impl is available')
      }
      return
    }

    this.setState('connecting')

    this.socket?.addEventListener('open', this.handleOpen)
    this.socket?.addEventListener('close', this.handleClose)
    this.socket?.addEventListener('message', this.handleMessage)
  }

  private handleOpen() {
    if (!this.socket) return

    this.debug('socket established, authenticating...')

    let initId = nanoid()

    // Authenticate
    this.socket.send(
      JSON.stringify({
        id: initId,
        kind: JQLClientMessageKind.ConnectionInit,
        time: Date.now(),
        payload: this.connectionParams,
      } as ConnectionInitMessage),
    )
  }

  private handleClose(event: WebSocketCloseEvent) {
    this.setState('closed')
    this.debug('close event', event.reason || '', String(event.code || ''))
    this.retry()
  }

  retryTimeoutId: number | undefined
  private retry() {
    this.clearRetryTimeout()
    let currentRetries = this.retries
    this.retryTimeoutId = setTimeout(() => {
      if (this.destroyed) return
      if (currentRetries !== this.retries) return
      this.debug('connection retry', `${this.retries || ''}`)
      this.retries++
      this.connect()
    }, retryWait(this.retries))
  }

  private clearRetryTimeout() {
    if (this.retryTimeoutId) clearTimeout(this.retryTimeoutId)
  }

  private alreadyProcessed(message: JQLAnyServerMessage) {
    let uniqueId = getMessageUniqueId(message)

    // We might send the same reply twice, prevent issues
    if (this.recentlyProcessed.has(uniqueId)) {
      return true
    }

    // Not processed yet, continue
    this.recentlyProcessed.add(uniqueId)
    return false
  }

  private handleMessage(event: WebSocketMessageEvent) {
    let data = event.data

    if (!data) return
    let message: JQLAnyServerMessage = JSON.parse(data)
    if (!message) return

    if (this.alreadyProcessed(message)) return

    switch (message.kind) {
      case JQLServerMessageKind.ConnectionAcknowledgment:
        console.info(`Server:`, message.server)
        this.setState('open')
        break

      case JQLServerMessageKind.Pong:
        this.handlePong()
        break

      case JQLServerMessageKind.Error:
        this.onError(
          new CombinedError({ SunError: makeSunErrorFromMessage(message) }),
        )
      case JQLServerMessageKind.Reply:
        let result = this.processReply(message)
        // send message to subs
        // eslint-disable-next-line unicorn/no-array-for-each
        this.activeSubscribers.get(result.id)?.forEach((callback) => {
          callback(result)
        })
        break

      case JQLServerMessageKind.Acknowledgment: {
        this.nonAckedRequests.delete((message.id as any) as RequestId)
        break
      }

      case JQLServerMessageKind.Message: {
        let payload = message.payload as Record<string, any> | null
        // send message to subs
        this.activeSubscribers
          .get(getSubscriptionId(message.method))
          // eslint-disable-next-line unicorn/no-array-for-each
          ?.forEach((callback) => {
            // ;(callback as SubscriptionResultCallback)({
            // @ts-ignore
            callback({
              data: payload,
              error: null,
            })
          })
        payload = null
        break
      }
    }
  }

  pingTimeoutId: number | undefined
  noPongTimeoutId: number | undefined
  ping() {
    if (this.destroyed) return
    if (!this.socket) return

    // Was 6s, but too small.
    let pingTimeout = document.visibilityState == 'hidden' ? 70_000 : 8000
    let pingExpiry = 5000 // was 1s!!

    this.clearPingPongTimeout()
    this.pingTimeoutId = setTimeout(() => {
      this.socket?.send(
        JSON.stringify({
          id: nanoidNonSecure(5),
          kind: JQLClientMessageKind.Ping,
        } as JQLClientPingMessage),
      )

      if (this.noPongTimeoutId) clearTimeout(this.noPongTimeoutId)
      this.noPongTimeoutId = setTimeout(() => {
        // Reconnect if pong wasn't received in time
        this.connect()
      }, pingExpiry)
    }, pingTimeout)
  }
  clearPingPongTimeout() {
    if (this.pingTimeoutId) clearTimeout(this.pingTimeoutId)
    if (this.noPongTimeoutId) clearTimeout(this.noPongTimeoutId)
  }
  handlePong() {
    this.ping()
  }

  processReply(message: JQLServerReply | JQLServerErrorMessage): NurResult {
    let requestId = getRequestIdFromMessage(message)
    let result: NurResult = this.results.get(requestId) || {
      data: null,
      error: null,
      fetching: false,
      id: requestId,
    }

    if (message.kind === JQLServerMessageKind.Error) {
      let SunError = makeSunErrorFromMessage(message)
      result.error = new CombinedError({ SunError })
      result.fetching = false
      return result
    }

    if (message.kind === JQLServerMessageKind.Reply) {
      result.error = null
      result.data = message.payload
      result.fetching = false
      result.stale = false
      return result
    }

    throw new Error('Invalid message to process')
  }

  destroy(error?: Error) {
    this.destroyed = true
    if (this.socket) {
      try {
        this.socket.close(
          error ? 4000 : 1000,
          error?.message || 'Normal destroy.',
        )
      } catch (error_) {
        // ignore
      }

      this.socket.removeEventListener('open', this.handleOpen)
      this.socket.removeEventListener('close', this.handleClose)
      this.socket.removeEventListener('message', this.handleMessage)
      this.socket = undefined
    }

    if (typeof window !== 'undefined') {
      window.removeEventListener('online', this.handleOnline)
    }

    this.clearPingPongTimeout()
    this.clearRetryTimeout()
  }

  /** Equivalent of "subscriptions" in GraphQL */
  onEvent(topic: string, sink: Sink<SubscriptionResult>) {
    let requestId = getSubscriptionId(topic)
    this.addSubscriber(requestId, sink.next)

    return {
      unsubscribe: () => {
        this.removeSubscriber(requestId, sink.next)
      },
    }
  }

  send(request: Request, sink: Sink) {
    let requestId = (request.id as any) as RequestId

    if (this.socket && this.state === 'open') {
      this.socket.send(
        JSON.stringify({
          id: (requestId as any) as string,
          kind: request.kind,
          time: Date.now(),
          method: request.method,
          payload: filterVariables(request.variables),
        } as JQLClientMessage),
      )
      this.nonAckedRequests.set(requestId, { request, sink })
    } else {
      this.queue({ request, sink })
    }

    this.addSubscriber(requestId, sink.next)

    return {
      unsubscribe: () => {
        this.removeSubscriber(requestId, sink.next)
      },
    }
  }

  queue(requestParams: { request: Request; sink: Sink }) {
    this.queueList.push(requestParams)
  }
  flush() {
    // Send messages that didn't ACK bc we got disconnected after sending
    if (this.nonAckedRequests.size > 0) {
      console.info('Applying nonAckedRequests', this.nonAckedRequests.size)
    }
    for (const [, requestParams] of this.nonAckedRequests) {
      this.send(requestParams.request, requestParams.sink)
    }
    this.nonAckedRequests.clear()

    // Send messages that we were disconnected when sent
    for (const requestParams of this.queueList) {
      this.send(requestParams.request, requestParams.sink)
    }
    this.queueList = []
  }

  addSubscriber(requestId: RequestId, subscriber: ResultCallback) {
    let subscribersSet = this.activeSubscribers.get(requestId)

    if (subscribersSet) {
      subscribersSet.add(subscriber)

      if (subscribersSet.size > 500) {
        console.error(
          '[NurNetwork] Unusual number of subscribers registered for a request',
        )
      }
    } else {
      subscribersSet = new Set([subscriber])
    }

    this.activeSubscribers.set(requestId, subscribersSet)
  }

  removeSubscriber(requestId: RequestId, subscriber: ResultCallback) {
    let subscribersSet = this.activeSubscribers.get(requestId)

    if (subscribersSet) {
      subscribersSet.delete(subscriber)

      if (subscribersSet.size === 0) {
        // Clear field when no active subscription is left
        subscribersSet = undefined
        this.activeSubscribers.delete(requestId)
      } else {
        this.activeSubscribers.set(requestId, subscribersSet)
      }
    }
  }

  handleOnline() {
    if (this.destroyed) return
    // if (this.state === 'open') return

    this.debug('Detected "online", re-connecting')
    Sentry.captureMessage('Detected "online", re-connecting')
    this.connect()
  }
}

function getRequestIdFromMessage(
  message: JQLServerReply | JQLServerErrorMessage,
): RequestId {
  return (message.id as any) as RequestId
}

function retryWait(attempt: number) {
  let minimum = 400
  // For faster reconnect on quick disconnects
  let maximum = attempt < 10 ? 8000 : 30_000
  let random = Math.floor(Math.random() * (2000 - 200) + 200)
  /** Pick a number between 100 - 200 */
  let reasonableMultiplier = 500

  let delay = Math.min(
    Math.max(Math.pow(2, attempt) * reasonableMultiplier + random, minimum),
    maximum,
  )

  return delay
}

export function networkStateToConnectionState(
  state: ConnectionState,
): 'connected' | 'disconnected' | 'connecting' {
  return state === 'open'
    ? 'connected'
    : state === 'closed'
    ? 'disconnected'
    : 'connecting'
}

/** Used to check if a message is processed twice */
function getMessageUniqueId(message: JQLAnyServerMessage) {
  return `${message.kind}_${message.id}`
}
