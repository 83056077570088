import ms from 'ms'

export function msToMinutes(millis: number) {
  return Math.floor(millis / ms('1m'))
}
export function msToSeconds(millis: number) {
  return Math.floor(millis / 1000)
}

export function msToHours(millis: number) {
  return Math.floor(millis / ms('1h'))
}

export const getDateAgo = (date: Date) => {
  let now = new Date(Date.now())

  let diffInMs = now.getTime() - date.getTime()

  if (diffInMs < ms('10h')) {
    if (diffInMs < ms('1m')) {
      return `${msToSeconds(diffInMs)}s ago`
    } else if (diffInMs < ms('1h')) {
      return `${msToMinutes(diffInMs)}m ago`
    } else {
      return `${msToHours(diffInMs)}h ago`
    }
  }
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

export const convertMsToTime = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  seconds = seconds % 60
  minutes = minutes % 60
  hours = hours % 24

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds,
  )}`
  // return `${hours > 0 ? `${padTo2Digits(hours)}:` : ''}${padTo2Digits(
  //   minutes,
  // )}:${padTo2Digits(seconds)}`
}
