//@ts-ignore
import EMOJI_REGEX from '../../components/lib/twemojiRegex'
import { EmojiCategory, EmojiData, EmojiRawData } from './use-picker'

// Due to the fact that emoji from Apple do not contain some characters, it is necessary to remove them from emoji-data
const EXCLUDE_EMOJIS = new Set(['female_sign', 'male_sign', 'medical_symbol'])

const ISO_FLAGS_OFFSET = 127_397

// Non-standard variations of emojis, used on some devices
const EMOJI_EXCEPTIONS: [string | RegExp, string][] = [
  [/\u{1F3F3}\u200D\u{1F308}/gu, '\u{1F3F3}\uFE0F\u200D\u{1F308}'], // 🏳‍🌈
  [/\u{1F3F3}\u200D\u26A7\uFE0F/gu, '\u{1F3F3}\uFE0F\u200D\u26A7\uFE0F'], // 🏳️‍⚧️
  [/\u{1F937}\u200D\u2642/gu, '\u{1F937}\u200D\u2642\uFE0F'], // 🤷‍♂️
]

function unifiedToNative(unified: string) {
  const unicodes = unified.split('-')
  const codePoints = unicodes.map((i) => parseInt(i, 16))

  return String.fromCodePoint(...codePoints)
}

export function fixNonStandardEmoji(text: string) {
  // Non-standard sequences typically parsed as separate emojis, so no need to fix text without any
  if (!EMOJI_REGEX.test(text)) return text
  // eslint-disable-next-line no-restricted-syntax
  for (const [regex, replacement] of EMOJI_EXCEPTIONS) {
    text = text.replace(regex, replacement)
  }

  return text
}

export function nativeToUnified(emoji: string) {
  let code

  if (emoji.length === 1) {
    code = emoji.charCodeAt(0).toString(16).padStart(4, '0')
  } else {
    const pairs = []
    for (let i = 0; i < emoji.length; i++) {
      if (emoji.charCodeAt(i) >= 0xd8_00 && emoji.charCodeAt(i) <= 0xdb_ff) {
        if (
          emoji.charCodeAt(i + 1) >= 0xdc_00 &&
          emoji.charCodeAt(i + 1) <= 0xdf_ff
        ) {
          pairs.push(
            (emoji.charCodeAt(i) - 0xd8_00) * 0x4_00 +
              (emoji.charCodeAt(i + 1) - 0xdc_00) +
              0x1_00_00,
          )
        }
      } else if (
        emoji.charCodeAt(i) < 0xd8_00 ||
        emoji.charCodeAt(i) > 0xdf_ff
      ) {
        pairs.push(emoji.charCodeAt(i))
      }
    }

    code = pairs.map((x) => x.toString(16).padStart(4, '0')).join('-')
  }

  return code
}

export function unCompressEmoji(data: EmojiRawData): EmojiData {
  const emojiData: EmojiData = { categories: [], emojis: {} }

  for (let i = 0; i < data.length; i += 2) {
    const category = {
      id: data[i][0],
      name: data[i][1],
      emojis: [],
    } as EmojiCategory

    for (let j = 0; j < data[i + 1].length; j++) {
      const emojiRaw = data[i + 1][j]
      if (!EXCLUDE_EMOJIS.has(emojiRaw[1][0])) {
        category.emojis.push(emojiRaw[1][0])
        emojiData.emojis[emojiRaw[1][0]] = {
          id: emojiRaw[1][0],
          names: emojiRaw[1] as string[],
          native: unifiedToNative(emojiRaw[0] as string),
          image: (emojiRaw[0] as string).toLowerCase(),
        }
      }
    }

    emojiData.categories.push(category)
  }

  return emojiData
}

export function isoToEmoji(iso: string) {
  const code = iso.toUpperCase()

  if (!/^[A-Z]{2}$/.test(code)) return iso
  const codePoints = [...code].map((c) => c.codePointAt(0)! + ISO_FLAGS_OFFSET)
  return String.fromCodePoint(...codePoints)
}
