import { Rectangle } from '@there/shared/desktop/types'
import {
  DeleteStatusMessage,
  DeleteStatusResult,
} from '@there/sun/modules/deleteStatus'
import { StatusInfo } from '@there/sun/utils/node-types'
import { copyToClipboard } from 'copy-lite'
import { useCallback } from 'react'
import { useNativeDropdown } from '../dropdown/useNativeDropdown'
import { useModals } from '../feed/ModalsContext'
import { useAddStatusReaction } from '../main/useAddStatusReaction'
import { useDeleteStatusReaction } from '../main/useDeleteStatusReaction'
import { getFileFullPath } from '../message/helpers'
import { useAppContext } from '../shared/AppContext'
import { logEvent } from '../shared/use-analytics'
import { useNurMutation } from '../sun/use-mutation'
import { writeImageToClipboard } from '../utils/copyImage'

export const useStatusActions = (statuses: StatusInfo[] | null | undefined) => {
  let { activeSpaceId, currentUserId } = useAppContext()
  let [, addStatusReaction] = useAddStatusReaction()
  let [, deleteStatusReaction] = useDeleteStatusReaction()

  let addReaction = useCallback(
    ({ emoji, statusId }: { emoji: string; statusId: string }) => {
      if (!activeSpaceId || !currentUserId) return
      logEvent('User Reacted to Status')
      addStatusReaction({
        emoji,
        spaceId: activeSpaceId,
        statusId,
        __extra: {
          userId: currentUserId,
        },
      })
    },
    [activeSpaceId, addStatusReaction, currentUserId],
  )
  let deleteReaction = useCallback(
    ({ emoji, statusId }: { emoji: string; statusId: string }) => {
      if (!activeSpaceId || !currentUserId) return
      deleteStatusReaction({
        spaceId: activeSpaceId,
        emoji,
        statusId,
        __extra: {
          userId: currentUserId,
        },
      })
    },
    [activeSpaceId, currentUserId, deleteStatusReaction],
  )

  let [, modalsDispatch] = useModals()
  let viewPhoto = useCallback(
    (status: StatusInfo, position?: Rectangle) => {
      modalsDispatch({
        type: 'modal opened',
        modalName: 'viewPhoto',
        modalData: {
          status,
          position,
          type: 'status photo',
        },
      })
    },
    [modalsDispatch],
  )

  const copyStatusText = useCallback(
    (id: string) => {
      if (!statuses) return
      let statusText = statuses.find((status) => status.id === id)?.text
      if (!statusText) return
      copyToClipboard(statusText)
    },
    [statuses],
  )

  let [, doDeleteStatus] = useNurMutation<
    DeleteStatusResult,
    DeleteStatusMessage['payload']
  >({
    method: 'deleteStatus',
  })
  const deleteStatus = useCallback(
    (id: string) => {
      if (!statuses) return
      if (!activeSpaceId) return
      let status = statuses.find((status) => status.id === id)
      if (!status) return
      doDeleteStatus({ id: status.id, spaces: [activeSpaceId] })
    },
    [activeSpaceId, doDeleteStatus, statuses],
  )

  const getDropdownItems = useCallback(
    (status: StatusInfo) => {
      let copyTextItem =
        status.text && status.text.length > 0
          ? [
              {
                label: 'Copy Text',
                type: 'copy-status-text',
              },
            ]
          : []

      let copyMediaItem = status.document
        ? [
            {
              type: 'copy-status-media',
              label: 'Copy Media',
            },
          ]
        : []

      let separatorItem =
        copyMediaItem.length > 0 || copyTextItem.length > 0
          ? [
              {
                label: 'sep',
                type: '__separator',
              },
            ]
          : []
      let deleteStatusItem =
        status.userId === currentUserId
          ? [
              {
                label: 'Delete Status',
                type: 'delete-status-item',
              },
            ]
          : []

      return [
        ...copyTextItem,
        ...copyMediaItem,
        ...separatorItem,
        ...deleteStatusItem,
      ]
    },
    [currentUserId],
  )

  let { openMenu } = useNativeDropdown({
    items: [],
    key: 'status-item',
    onSelectItem: (selectedItemType: string, id: string) => {
      switch (selectedItemType) {
        case 'copy-status-text':
          copyStatusText(id)
          break
        case 'delete-status-item':
          deleteStatus(id)
          break
        case 'copy-status-media':
          let status = statuses?.find((s) => s.id === id)
          if (!status || !status.document) return
          let path = getFileFullPath(status.document)
          if (!path) return
          writeImageToClipboard(path)
          break
      }
    },
  })

  const openContextMenu = useCallback(
    (status: StatusInfo) => {
      let items = getDropdownItems(status)
      if (items.length === 0) return
      openMenu(status.id, items)
    },
    [getDropdownItems, openMenu],
  )

  return {
    addReaction,
    deleteReaction,
    viewStatusPhoto: viewPhoto,
    openContextMenu,
  }
}
