import { featurePreviewsAtom } from '@there/components/atoms/feedAtoms'
import { useRoomsScrollViewManager } from '@there/components/feed/roomsScroll'
import { useTheme } from '@there/components/feed/ThemeContext'
import { PeopleScrollView } from '@there/components/main/PeopleScrollView'
import { PeopleContainer } from '@there/components/main/Rooms'
import { logEvent } from '@there/components/shared/use-analytics'
import { HomeHeader } from '@there/components/v2/HomeHeader'
import { MainTabButton } from '@there/components/v2/MainTabButton'
import cuid from 'cuid'
import { useAtom } from 'jotai'
import { nanoid } from 'nanoid/non-secure'
import React, { useEffect, useRef, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, View } from 'react-native'
import { useModals } from '../feed/ModalsContext'
import { CurrentGameBox } from '../gameRoom/CurrentGameBox'
import { GameRoomView } from '../gameRoom/GameRoomView'
import { selectedIdAtom } from '../main/DialogItem'
import { useCreateDialog } from '../main/useCreateDialog'
import { RateCall } from '../modal/RateCall'
import { StatusFeedMain } from '../nooray/StatusFeedMain'
import { useAppContext } from '../shared/AppContext'
import { useSpaceContext } from '../shared/spaceContext'
import { useMainWindowContext } from '../shared/use-main-window'
import { MaxWidthWeb, useWindowContext } from '../shared/WindowContext'
import { DoubleClickPopup } from './DoubleClickPopup'

export const Home = () => {
  let { dispatch, mode } = useMainWindowContext()

  return (
    <>
      <View style={styles.headWrapper}>
        <View style={styles.tabsWrapper}>
          {/* rooms, chats, etc... */}
          <MainTabButton
            title="Rooms"
            active={mode === 'room'}
            onPress={() => dispatch({ type: 'change mode', mode: 'room' })}
          />
          <MainTabButton
            title="Chats"
            active={mode === 'chat'}
            onPress={() => dispatch({ type: 'change mode', mode: 'chat' })}
          />
        </View>

        <HomeHeader />
      </View>

      {mode === 'room' && <RoomsTab />}
      {/* {tab === 'chats' && <ChatsTab />} */}
    </>
  )
}

const styles = StyleSheet.create({
  tabsWrapper: { height: 42, flexDirection: 'row' },
  headWrapper: {
    paddingLeft: 18,
    paddingRight: 12,

    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export const RoomsTab = () => {
  let theme = useTheme()
  let { mode, isFeedOpen, isGameRoom } = useMainWindowContext()
  let { activeSpaceId, statusFeed, dispatch } = useAppContext()
  let [, createDialog] = useCreateDialog()
  let [, modalsDispatch] = useModals()
  let [{ currentDialog: dialog }] = useSpaceContext()

  let [parentWidth, setParentWidth] = useState<null | number>(null)

  let { isElectron } = useWindowContext()
  let peopleScrollViewRef = useRef<ScrollView>(null)

  useRoomsScrollViewManager({ scrollViewRef: peopleScrollViewRef })

  function createNewRoom() {
    if (!activeSpaceId) return

    createDialog({
      id: cuid(),
      chatId: cuid(),
      sessionId: nanoid(18),
      spaceId: activeSpaceId,
      title: '',
      iconEmoji: null,
    })

    logEvent('User Created a Room')
  }
  let [selectedId, setSelectedId] = useAtom(selectedIdAtom)

  // status feed new status
  let [featurePreviews] = useAtom(featurePreviewsAtom)
  useEffect(() => {
    if (featurePreviews.includes('feed')) {
      dispatch({
        type: 'status feed changed',
        value: true,
      })
    } else {
      dispatch({
        type: 'status feed changed',
        value: false,
      })
    }
  }, [dispatch, featurePreviews])

  // let showStatusesFeed = statusFeed
  // let feedRef = useRef<View | null>(null)
  // const scrollToFeed = useCallback(() => {
  //   feedRef?.current?.measure((width, height, px, py, fx, fy) => {
  //     peopleScrollViewRef.current?.scrollTo({
  //       y: height - 20,
  //       animated: true,
  //     })
  //   })
  // }, [])
  // const [unreadStatus, setUnreadStatus] = useState<StatusesType>([])
  // const [scrolledManually, setScrolledManually] = useState(false)
  // let peopleScrollViewRef = useRef<ScrollView | null>(null)
  // const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
  //   let layoutHeight = event.nativeEvent.layoutMeasurement.height
  //   let contentSizeHeight = event.nativeEvent.contentSize.height
  //   let endY = contentSizeHeight - layoutHeight
  //   let y = event.nativeEvent.contentOffset?.y
  //   let nextScrolledManually = Math.abs(endY - y) > 20

  //   if (nextScrolledManually) {
  //     setScrolledManually(true)
  //   }
  //   // // don't set this if messages haven't rendered fully
  //   // if (
  //   //   messagesRendered &&
  //   //   initiallyScrolledRef.current &&
  //   //   isScrolledManuallyRef.current !== nextScrolledManually
  //   // ) {
  //   //   setScrolledManually(nextScrolledManually)
  //   //   isScrolledManuallyRef.current = nextScrolledManually
  //   // }
  // }

  let [height, setHeight] = useState<number | null>(null)

  let [wrapperWidth, setWrapperWidth] = useState<number>(480)
  return (
    <>
      <Pressable
        onLayout={(event) => {
          let layout = event.nativeEvent.layout
          setWrapperWidth(layout.width)
        }}
        style={{
          flexDirection: 'row',
          justifyContent: isElectron ? 'flex-start' : 'center',
          width: '100%',
          flex: 1,
          //@ts-ignore
          cursor: 'default',
        }}
        onPress={() => {
          setSelectedId('')
        }}
      >
        {isFeedOpen && <StatusFeedMain wrapperWidth={wrapperWidth} />}

        {/* rooms and members side bar  */}
        <View
          style={{
            width: isFeedOpen ? (wrapperWidth * 40) / 100 : wrapperWidth,
            maxWidth: isElectron ? undefined : MaxWidthWeb,
          }}
        >
          {/* // call control */}
          {/* <HomeCallBar /> */}
          {/* <ScrollToButtonFeed
            isScrolledManually={!scrolledManually}
            scrollViewRef={peopleScrollViewRef}
          /> */}

          {isGameRoom && dialog ? (
            <GameRoomView wrapperWidth={wrapperWidth} />
          ) : (
            <PeopleScrollView
              ref={peopleScrollViewRef}
              // onScroll={onScroll}
              scrollEventThrottle={200}
              onLayout={({
                nativeEvent: {
                  layout: { height, width },
                },
              }) => {
                setHeight(height)
                setParentWidth(width)
              }}
            >
              <View
                style={{
                  paddingHorizontal: 10,
                  width: '100%',
                  marginBottom: 10,
                }}
              >
                <CurrentGameBox isMinimalView={isFeedOpen} />
              </View>

              <PeopleContainer
                minHeight={height || 0}
                parentWidth={parentWidth || 0}
              />
              {/* </View> */}
              {/* {showStatusesFeed && (
              <View
                ref={feedRef}
                style={{
                  width: '100%',
                  paddingLeft: 10,
                  paddingRight: 12,
                  paddingBottom: 12,
                  marginBottom: 14,
                }}
              >
                <FeedBox
                  scrollToFeed={scrollToFeed}
                  // unreadStatusChanged={unreadStatusChanged}
                />
              </View>
            )} */}
            </PeopleScrollView>
          )}
          <DoubleClickPopup />
        </View>
      </Pressable>
      <RateCall />
    </>
  )
}
