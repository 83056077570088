import { useTheme } from '@there/components/feed/ThemeContext'
import { EmojiView } from '@there/components/main/EmojiView'
import { Pressable } from '@there/components/shared/Pressable'
import { logEvent } from '@there/components/shared/use-analytics'
import { usesOldDomain } from '@there/components/utils/compat'
import {
  electronApi,
  electronVersion,
  ipc,
  isElectron,
} from '@there/desktop/utils/electron-api'
import { filterFalsy } from '@there/shared/utilities/filter-falsy'
import { useEffect } from 'react'
import { memo, useState } from 'react'
import { Text, View } from 'react-native'

type BannerInfo = {
  /** unique for react lists */
  key: string
  icon: string
  title: string
  subtitle: string
  /** Return a patch to update banner */
  onPress: () => Partial<BannerInfo> | void
  requireAction: boolean
}

export const Banners = () => {
  let [banners, setBanners] = useState<BannerInfo[]>([])

  useEffect(() => {
    if (typeof localStorage !== 'undefined') return
    let banners = [updateToV2_10()].filter(filterFalsy)
    setBanners(banners)
  }, [])

  let theme = useTheme()
  return (
    <View
      style={{
        borderTopWidth: 1,
        borderTopColor: 'rgba(255, 255, 255, 0.06)',
      }}
    >
      {banners.map((info) => {
        return (
          <Pressable
            key={info.key}
            onPress={() => {
              let patch = info.onPress()
              if (patch) {
                setBanners((banners) =>
                  banners.map((banner) => {
                    if (banner.key === info.key) {
                      return { ...banner, ...patch }
                    } else {
                      return banner
                    }
                  }),
                )
              }
            }}
            style={({ hovered, pressed }) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              paddingLeft: 14,
              paddingRight: 8,
              height: 56,
              backgroundColor:
                hovered && !pressed
                  ? theme.colors.cardHovered
                  : theme.colors.card,
            })}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 4,
              }}
            >
              {info.icon && (
                <EmojiView children={info.icon} size={18} marginRight={8} />
              )}

              <Text
                style={{
                  color: theme.colors.secondaryText,
                  fontSize: theme.fontSizes.normal,
                }}
              >
                {info.title}
              </Text>
            </View>

            <Text
              style={{
                color: theme.colors.tertiaryText,
                fontSize: theme.fontSizes.normal,
                lineHeight: 15,
              }}
            >
              {info.subtitle}
            </Text>
          </Pressable>
        )
      })}
    </View>
  )
}

Banners.displayName = 'Banners'

/**
 * todo
 * 1. track via Amplitude
 * 2. show for users that are on there.so
 * 3. Link to website download link if version starts with 1.
 */

function updateToV2_10(): BannerInfo | false {
  let showBanner = usesOldDomain()
  let debugMode = localStorage.getItem('__debug_v2.10_banner')

  if (!debugMode && (!showBanner || !isElectron)) return false

  let requiresWebsiteDownload =
    electronVersion?.replace('v', '').startsWith('1.') ||
    electronVersion?.replace('v', '').startsWith('0.')

  return {
    key: 'v2_10',
    title: requiresWebsiteDownload
      ? 'Manual Update Required'
      : 'Important Update',
    subtitle: requiresWebsiteDownload
      ? 'Click to download from the website.'
      : 'Click to get the latest version now',
    icon: '🆙',
    requireAction: true,
    onPress: () => {
      logEvent('Manual Update Banner Clicked')
      if (requiresWebsiteDownload) {
        electronApi?.openExternalUrl('https://usenoor.com/download')
        return {
          title: 'Opened in the browser...',
          subtitle: 'Quit the app and install the new one',
        }
      } else {
        ipc?.invoke('there:update')

        return {
          title: 'Started...',
          subtitle: 'Check progress in the ⚙️ menu.',
          onPress: () => {},
        }
      }
    },
  }
}
