import { useSpring, a } from '@react-spring/native'
import { useSpaceContext } from '../shared/spaceContext'
import { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { useUiContext } from '../shared/UiContext'
import { opacify } from 'polished'

export const CustomBackground = () => {
  let [{ currentDialog }] = useSpaceContext()
  let { dispatch, previewBackground, previewBgDarkenPercent } = useUiContext()

  let backgroundUrl = currentDialog?.bgObjectId
  let bgDarkenPercent = currentDialog?.bgDarkenPercent || 0
  let [isLoaded, setLoaded] = useState(false)

  const [styleProps, animate] = useSpring({ opacity: 0, darkenOpacity: 0 }, [])
  const [previewStyleProps, animatePreview] = useSpring(
    { opacity: 0, darkenOpacity: 0 },
    [],
  )
  let backgroundUrlRef = useRef<string | undefined | null>(undefined)
  let backgroundChanged = backgroundUrl !== backgroundUrlRef.current
  let hasBackground = Boolean(backgroundUrl)

  useEffect(() => {
    if (!hasBackground || !isLoaded) {
      animate({
        opacity: 0,
        darkenOpacity: 0,
        delay: 0,
        config: { duration: 1 },
      })
      backgroundUrlRef.current = backgroundUrl
      dispatch({ type: 'custom background state changed', state: false })
      return
    }
    animate({
      darkenOpacity: bgDarkenPercent / 100,
      config: { duration: 1000, clamp: true },
    })
    animate({
      opacity: 1,
      delay: 300,
      config: { duration: 700 },
    })
    dispatch({ type: 'custom background state changed', state: true })
    backgroundUrlRef.current = backgroundUrl
  }, [
    animate,
    backgroundUrl,
    bgDarkenPercent,
    dispatch,
    hasBackground,
    isLoaded,
  ])

  useEffect(() => {
    if (!previewBackground) {
      animatePreview({
        opacity: 0,
        darkenOpacity: 0,
        delay: 0,
        config: { duration: 1 },
      })
      return
    }
    animatePreview({
      opacity: 1,
      darkenOpacity: previewBgDarkenPercent / 100,
      config: { duration: 400 },
    })
  }, [animatePreview, previewBgDarkenPercent, previewBackground])

  return (
    <>
      <a.Image
        children={null}
        onLoadEnd={() => setLoaded(true)}
        source={{ uri: backgroundUrl || '' }}
        style={[
          {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: '100vw',
            height: '100vh',
            zIndex: -1,
          },
          {
            width: '100vw',
            height: '100vh',
          },
          {
            opacity: styleProps.opacity,
          },
        ]}
      />
      <a.View
        children={null}
        style={[
          {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: '100vw',
            height: '100vh',
            zIndex: -1,

            backgroundColor: '#000',
          },
          {
            width: '100vw',
            height: '100vh',
          },
          {
            opacity: styleProps.darkenOpacity,
          },
        ]}
      />
      {previewBackground && (
        <a.Image
          children={null}
          source={{ uri: previewBackground }}
          style={[
            {
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: '100vw',
              height: '100vh',
              zIndex: -1,
            },

            {
              opacity: previewStyleProps.opacity,
            },
          ]}
        />
      )}
      {previewBackground && (
        <a.View
          style={[
            {
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: '100vw',
              height: '100vh',
              zIndex: -1,
              backgroundColor: '#000',
            },
            {
              opacity: previewStyleProps.darkenOpacity,
            },
          ]}
          children={null}
        />
      )}
    </>
  )
}
