import { LocalEditDialogVariables } from '@there/components/main/useEditDialog'
import { LocalLeaveDialogVariables } from '@there/components/main/useLeaveDialog'
import { updates } from '@there/components/sun/updates'
import { LocalJoinDialogVariables } from '@there/components/shared/useJoinDialog'
import editDialogUpdates from '@there/sun/model/editDialogUpdates'
import { getJoinDialogUpdates } from '@there/sun/model/joinDialogUpdates'
import { getLeaveDialogUpdates } from '@there/sun/model/leaveDialogUpdates'
import { DialogInfo, UserInfo } from '@there/sun/utils/node-types'
import {
  ApiClientMessage,
  GotChatMessageMessage,
  NodeUpdateMessage,
} from '@there/sun/ws/message-types'
import { OptimisticFunction } from './cache'
import { LocalSendChatMessageVariables } from '../main/useSendChatMessage'
import { LocalSendNewMessageVariables } from '../main/useSendNewMessage'
import { LocalAddStatusReactionMessageVariables } from '../main/useAddStatusReaction'
import { LocalDeleteStatusReactionMessageVariables } from '../main/useDeleteStatusReaction'
import { LocalAddStatusMessageVariables } from '../main/useAddStatus'
import { DeleteStatusMessage } from '@there/sun/modules/deleteStatus'
import { LocalUpdateFocusMessageVariables } from '../main/useUpdateFocus'

type Optimistic = Record<
  ApiClientMessage['method'] | string,
  OptimisticFunction
>

export const optimistic: Optimistic = {
  joinDialog: (args: LocalJoinDialogVariables, cache): any => {
    let dialog = cache.readNode({ id: args.dialogId }) as DialogInfo | null
    let oldDialog = (args.__extra.oldDialogId
      ? cache.readNode({
          id: args.__extra.oldDialogId,
        })
      : null) as DialogInfo | null
    let spaceId = args.__extra.spaceId
    let avatarId = args.__extra.avatarId
    if (!dialog) {
      console.error('Dialog to join in optimistic not found')
      return false
    }
    let nodeUpdates = getJoinDialogUpdates(
      {
        joinedAt: args.joinedAt,
        dialogId: args.dialogId,
        spaceId: spaceId,
        newSessionId: args.newSessionId,
      },
      { avatarId: avatarId, dialog, oldDialog },
    )

    return { updates: nodeUpdates }
  },

  leaveDialog: (args: LocalLeaveDialogVariables, cache): boolean => {
    let dialogId = args.__extra.dialogId
    let spaceId = args.__extra.spaceId
    let avatarId = args.__extra.avatarId
    let userId = args.__extra.userId
    if (!dialogId) {
      console.error('Dialog to leave in optimistic not found')
      return false
    }

    let nodeUpdates = getLeaveDialogUpdates({
      dialogId,
      avatarId,
      userId,
    })

    let nodeUpdatesMessage: NodeUpdateMessage['payload'] = {
      updates: nodeUpdates,
    }
    updates.subscription['nodeUpdate']?.(nodeUpdatesMessage, { spaceId }, cache)
    return true
  },

  editDialog: (args: LocalEditDialogVariables, cache): boolean => {
    let dialogId = args.id
    let spaceId = args.__extra.spaceId

    let nodeUpdates = editDialogUpdates(
      { ...args, dialogId: args.id },
      {
        dialogId,
        spaceId,
      },
    )

    let nodeUpdatesMessage: NodeUpdateMessage['payload'] = {
      updates: nodeUpdates,
    }
    updates.subscription['nodeUpdate']?.(nodeUpdatesMessage, { spaceId }, cache)
    return true
  },

  sendChatMessage: (
    args: LocalSendChatMessageVariables,
    cache,
  ): { done: boolean } => {
    return { done: true }
  },

  sendNewMessage: (
    args: LocalSendNewMessageVariables,
    cache,
  ): { done: boolean } => {
    return { done: true }
  },

  addStatusReaction: (
    args: LocalAddStatusReactionMessageVariables,
    cache,
  ): { done: boolean } => {
    return { done: true }
  },

  deleteStatusReaction: (
    args: LocalDeleteStatusReactionMessageVariables,
    cache,
  ): { done: boolean } => {
    return { done: true }
  },

  addStatus: (
    args: LocalAddStatusMessageVariables,
    cache,
  ): { done: boolean } => {
    return { done: true }
  },

  deleteStatus: (
    args: DeleteStatusMessage['payload'],
    cache,
  ): { done: boolean } => {
    return { done: true }
  },

  updateFocus: (
    args: LocalUpdateFocusMessageVariables,
    cache,
  ): { user: UserInfo } => {
    return {
      user: {
        id: args.__extra.userId,
        focused: args.focus,
      },
    }
  },
}
