import { Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { AddPeopleCopyButton } from '../modal/AddPeopleCopyButton'
import { AddPeopleEmailInput } from '../modal/AddPeopleEmailInput'
import { Header } from '../modal/Header'
import { HelpText } from '../modal/HelpText'
import { ModalLabel } from '../modal/ModalLabel'
import { Space } from '../shared/Space'
import { useUsersContext } from '../shared/UsersContext'

export const AddPeople = () => {
  let theme = useTheme()
  let { members, currentUser } = useUsersContext()

  if (!currentUser || !members || (members && members.length > 1)) return null
  return (
    <View
      style={{
        width: '100%',
        paddingHorizontal: theme.spaces.sidePaddingNarrow,
        paddingBottom: 5,
      }}
    >
      <Text
        style={{
          fontSize: theme.fontSizes.large,
          color: theme.colors.secondaryText,
        }}
      >
        Invite teammates
      </Text>
      <Space vertical={15} />
      <AddPeopleCopyButton />
      <Space vertical={4} />
      <HelpText text="Anyone with the link can join" />
      <Space vertical={10} />
      <ModalLabel text="Invite by email" />
      <Space vertical={5} />
      <AddPeopleEmailInput onClose={() => {}} />
    </View>
  )
}
