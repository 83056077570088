import * as React from 'react'
import Svg, { SvgProps, G, Path } from 'react-native-svg'

export const MicrophoneIcon = React.memo(
  ({ muted, color, ...props }: SvgProps & { muted?: boolean }) => {
    return (
      <Svg width={25} height={25} viewBox="0 0 25 25" fill="none" {...props}>
        {muted ? (
          <G>
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.379 5.209a3.125 3.125 0 016.25 0v6.25c0 .483-.11.941-.306 1.35L9.379 6.865V5.209zm4.715 11.21l1.598 1.599a7.24 7.24 0 01-2.15.659l.002.073v3.125a1.042 1.042 0 01-2.083 0V18.75c0-.024 0-.049.002-.073a7.293 7.293 0 01-6.252-7.218h2.083a5.208 5.208 0 006.8 4.96zm-1.846-1.846l-2.859-2.858a3.126 3.126 0 002.859 2.858zm4.599-.24a5.184 5.184 0 00.864-2.874h2.083a7.26 7.26 0 01-1.453 4.368l-1.494-1.494z"
              fill={color ? color : '#fff'}
            />
            <Path
              stroke={color ? color : '#fff'}
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.135 4.052l15.911 15.911"
            />
          </G>
        ) : (
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.504 2.084a3.125 3.125 0 00-3.125 3.125v6.25a3.125 3.125 0 006.25 0v-6.25c0-1.726-1.4-3.125-3.125-3.125zM11.46 18.75c0-.024 0-.049.002-.073a7.293 7.293 0 01-6.252-7.218h2.083a5.208 5.208 0 1010.417 0h2.083a7.293 7.293 0 01-6.252 7.218l.002.073v3.125a1.042 1.042 0 01-2.083 0V18.75z"
            fill={color ? color : '#fff'}
          />
        )}
      </Svg>
    )
  },
)
