import { useLatest } from '@there/components/shared/use-latest'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import {
  IsInViewportContext,
  useInViewportManager,
} from '../hooks/useInViewport'
import { PickerKeys, PickerMode } from '../shared/PickerContext'
import { Pressable } from '../shared/Pressable'
import { Space } from '../shared/Space'
import { Category } from './Category'
import { categoryIcons, People } from './CategoryIcons'
import { GifIcon } from './GifIcon'
import { GifPickerContainer } from './GifPickerContainer'
import { SearchEmoji } from './SearchEmoji'
import { AllEmojis, ConfigType, Emoji, EmojiCategory } from './use-picker'
import { GifMedia } from './use-tenor'

type Props = {
  config: ConfigType
  onEmojiPress: (emojiId: Emoji) => void
  onGifPress: (gif: GifMedia) => void
  isOpen: boolean
  recentUsedEmojis: string[]
  emojis: AllEmojis | undefined
  categories: EmojiCategory[] | undefined
  activeModes: PickerMode[]
  initialMode: PickerMode
  pickerKey: PickerKeys | undefined
}

export const PickerContent = memo(
  ({
    config,
    onEmojiPress,
    isOpen,
    recentUsedEmojis,
    emojis,
    categories,
    activeModes,
    initialMode,
    onGifPress,
    pickerKey,
  }: Props) => {
    let t = useTheme()
    const scrollViewRef = useRef<ScrollView | null>(null)

    const [activeCategoryIndex, setActiveCategoryIndex] = useState(0)
    let [categoryPosition, setCategoryPosition] = useState<number[]>([])

    let [pickerMode, setPickerMode] = useState<'emoji' | 'gif'>('emoji')

    let activeModesRef = useLatest(activeModes)
    let lastPickerKeyRef = useRef<PickerKeys | undefined>(undefined)
    useEffect(() => {
      if (pickerKey && pickerKey === lastPickerKeyRef.current) return
      if (activeModesRef.current.includes('gif')) {
        setPickerMode(initialMode)
      } else {
        setPickerMode('emoji')
      }
      lastPickerKeyRef.current = pickerKey
    }, [activeModesRef, initialMode, pickerKey])

    let [isSearching, setIsSearching] = useState(false)

    let allCategories = useMemo(() => {
      if (!categories) {
        return []
      }
      let allCats = [...categories]
      if (recentUsedEmojis?.length > 0) {
        allCats.unshift({
          id: 'recent',
          name: 'Recent',
          emojis: recentUsedEmojis,
        })
      }
      return allCats
    }, [categories, recentUsedEmojis])

    const onEmojiClick = useCallback(
      (emoji: Emoji) => {
        onEmojiPress(emoji)
        // onEmojiPress(
        //   emoji.image
        //     .split('-')
        //     .map((u) => String.fromCodePoint(parseInt(u, 16)))
        //     .join(''),
        // )
      },
      [onEmojiPress],
    )

    const selectCategory = (index: number) => {
      setActiveCategoryIndex(index)
      if (!scrollViewRef.current) return

      scrollViewRef.current.scrollTo({
        y: categoryPosition[index] - categoryPosition[0] + 48, // search box height
        animated: false,
      })
    }

    const {
      context,
      onContentSizeChange,
      onLayout,
      onScroll,
    } = useInViewportManager({
      target: scrollViewRef,
      items: allCategories,
      itemToKey: (msg) => msg.id,
      inViewItems: (inViewCategories: EmojiCategory[]) => {
        if (!isOpen) return
        // Update active category
        let activeIndex = allCategories.findIndex(
          (item) =>
            item.id ===
            inViewCategories[
              inViewCategories.length - 1 > 0 ? inViewCategories.length - 1 : 0
            ]?.id,
        )
        setActiveCategoryIndex(activeIndex > 0 ? activeIndex : 0)
      },
      debounceMs: 100,
    })

    useEffect(() => {
      if (!isOpen) {
        scrollViewRef.current?.scrollTo({
          y: 0,
          animated: false,
        })
        setActiveCategoryIndex(0)
      }
    }, [isOpen])

    return !emojis ? (
      <View
        style={[
          styles.wrapper,
          {
            width: config.containerWidth + t.spaces.sidePaddingNarrow * 2,
            height: config.containerHeight,
            backgroundColor: t.colors.emojiPickerBackground,
            borderRadius: t.borderRadius.large,
            borderColor: t.colors.emojiPickerBorder,
            overflow: 'hidden',

            //@ts-ignore
            pointerEvents: 'auto',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}
      >
        <ActivityIndicator />
      </View>
    ) : (
      <View
        style={[
          styles.wrapper,
          {
            width: config.containerWidth + t.spaces.sidePaddingNarrow * 2,
            height: config.containerHeight,
            backgroundColor: t.colors.emojiPickerBackground,
            borderRadius: t.borderRadius.large,
            borderColor: t.colors.emojiPickerBorder,
            overflow: 'hidden',

            //@ts-ignore
            pointerEvents: 'auto',
          },
        ]}
      >
        <View
          style={{
            display: pickerMode === 'emoji' ? 'flex' : 'none',
          }}
        >
          <View
            style={[
              {
                width: '100%',
                height: 42,
                backgroundColor: t.colors.emojiPickerBackground,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                paddingHorizontal: t.spaces.sidePaddingNarrow,
              },
            ]}
          >
            {allCategories.map((category: EmojiCategory, index: number) => {
              let categoryIconKey =
                activeCategoryIndex === index
                  ? category.id + `Active`
                  : category.id
              return (
                <TouchableOpacity
                  onPress={() => selectCategory(index)}
                  activeOpacity={0.85}
                  key={category.id}
                  style={[
                    {
                      width: 32,
                      height: 32,
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                  ]}
                >
                  {categoryIcons[categoryIconKey]}
                </TouchableOpacity>
              )
            })}
          </View>
          <IsInViewportContext.Provider value={context}>
            <ScrollView
              ref={scrollViewRef}
              scrollEventThrottle={200}
              onScroll={onScroll}
              onLayout={onLayout}
              onContentSizeChange={onContentSizeChange}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{
                alignItems: 'center',
              }}
              style={[
                {
                  height:
                    config.containerHeight -
                    42 -
                    (activeModes.includes('gif') ? 36 : 0),
                  paddingVertical: t.spaces.sidePaddingNarrow,
                  paddingTop: 0,
                },
              ]}
            >
              <SearchEmoji
                config={config}
                onEmojiClick={onEmojiClick}
                emojis={emojis}
                setIsSearching={setIsSearching}
                isOpen={isOpen}
              />
              <View
                style={[
                  {
                    display: 'flex',
                    width: config.containerWidth + config.emojiMargin * 2,
                    marginTop: 10,
                  },
                  isSearching && { display: 'none' },
                ]}
              >
                {allCategories.map((category, index) => {
                  return (
                    <View
                      key={`emoji-picker${index}`}
                      onLayout={(event) => {
                        if (categoryPosition[index]) return
                        setCategoryPosition((positionsArray) => {
                          positionsArray[index] =
                            //@ts-ignore
                            event.nativeEvent.target.offsetTop
                          return positionsArray
                        })
                      }}
                    >
                      <Category
                        key={`emoji-picker${index}`}
                        index={index}
                        category={category}
                        config={config}
                        allEmojis={emojis}
                        shouldRender={
                          activeCategoryIndex >= index - 1 &&
                          activeCategoryIndex <= index + 1
                        }
                        onEmojiSelect={onEmojiClick}
                      />
                    </View>
                  )
                })}
              </View>
            </ScrollView>
          </IsInViewportContext.Provider>
        </View>
        {
          // Gif Picker
        }
        {/* do not render or GPU goes up */}
        {pickerMode === 'gif' && isOpen && (
          <View
            style={{
              display: pickerMode === 'gif' ? 'flex' : 'none',
            }}
          >
            <GifPickerContainer
              config={config}
              isOpen={isOpen}
              onGifPress={onGifPress}
            />
          </View>
        )}
        <View
          style={{
            width: '100%',
            height: 36,
            display: activeModes.includes('gif') ? 'flex' : 'none',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PickerModeButton
            isActive={pickerMode === 'emoji'}
            onPress={() => {
              setPickerMode('emoji')
            }}
          >
            {({ hovered, pressed }) => (
              <People
                color={
                  pickerMode === 'emoji'
                    ? t.colors.primary
                    : hovered
                    ? t.colors.secondaryLight
                    : t.colors.tertiaryText
                }
                width={20}
              />
            )}
          </PickerModeButton>
          <Space horizontal={5} />
          <PickerModeButton
            isActive={pickerMode === 'gif'}
            onPress={() => {
              setPickerMode('gif')
            }}
          >
            {({ hovered, pressed }) => (
              <GifIcon
                color={
                  pickerMode === 'gif'
                    ? t.colors.primary
                    : hovered
                    ? t.colors.secondaryLight
                    : t.colors.tertiaryText
                }
                width={20}
              />
            )}
          </PickerModeButton>
        </View>
      </View>
    )
  },
)

const PickerModeButton = ({
  children,
  isActive,
  onPress,
}: {
  isActive: boolean
  onPress: () => void
  children: ({
    hovered,
    pressed,
  }: {
    hovered?: boolean
    pressed?: boolean
  }) => React.ReactNode
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {({ pressed, hovered }) => children({ hovered, pressed })}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 6,

    borderWidth: 1,
  },
})
