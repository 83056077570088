import * as React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

export const FocusIcon = (props: SvgProps) => (
  <Svg width={13} height={13} viewBox="0 0 13 13" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 11.38a4.87 4.87 0 1 1 0-9.75 4.87 4.87 0 0 1 0 9.75Zm0-1.63a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z"
      fill={props.color ? props.color : '#000'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      fill={props.color ? props.color : '#000'}
    />
  </Svg>
)
