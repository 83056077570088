import { useNurMutation } from '@there/components/sun/use-mutation'
import { DeleteChatMessageMessage } from '@there/sun/modules/deleteChatMessage'
interface Data {
  done: boolean
}

export const useDeleteChatMessage = () => {
  return useNurMutation<Data, DeleteChatMessageMessage['payload']>({
    method: 'deleteChatMessage',
  })
}
