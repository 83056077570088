import { useState } from 'react'
import { AnimatedView } from '@there/components/shared/AnimatedView'
import { useTransition } from '@react-spring/native'
import {
  NotificationsDispatch,
  NotificationsState,
  NotificationType,
} from '@there/components/shared/use-notification-state'
import { JoinRoom, Nudge, ScreenShareInvite } from './NotificationStyles'
import { View } from 'react-native'
import { NotificationAudio } from './NotificationAudio'

type Props = {
  notificationsState: NotificationsState
  notificationsDispatch: NotificationsDispatch
  notifications: NotificationType[]
}

export const Notifications = ({
  notificationsState: state,
  notificationsDispatch: dispatch,
  notifications,
}: Props) => {
  let [refMap] = useState(() => new WeakMap())

  let animatedNotifications = useTransition(notifications, {
    key: (item: any) => item.id,
    from: {
      opacity: 0,
      height: 0,
    },
    enter: (item: any) => async (next: any, stop: any) =>
      await next({
        opacity: 1,
        height: refMap.get(item).offsetHeight,
      }),
    leave: (item: any) => async (next: any, cancel: any) => {
      await next({ opacity: 0, height: 0 })
    },
    config: { tension: 300, friction: 26, precision: 0.1 },
  })

  return (
    <>
      {animatedNotifications(({ opacity, height }, notification) => {
        return (
          <AnimatedView
            style={{
              opacity,
              height,
              overflow: 'hidden',
              width: '100%',

              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <View
              ref={(ref) => ref && refMap.set(notification, ref)}
              style={{
                // @ts-ignore
                pointerEvents: 'auto',
              }}
            >
              {notification.type === 'nudge' && (
                <Nudge
                  notification={notification}
                  notificationDispatch={dispatch}
                />
              )}
              {notification.type === 'joinRoom' && (
                <JoinRoom
                  notification={notification}
                  notificationDispatch={dispatch}
                />
              )}
              {notification.type === 'screenShareInvite' && (
                <ScreenShareInvite
                  notification={notification}
                  notificationDispatch={dispatch}
                />
              )}
            </View>
          </AnimatedView>
        )
      })}
      <NotificationAudio notificationState={state} />
    </>
  )
}
