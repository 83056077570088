import { atom, useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useModals } from '../feed/ModalsContext'
import { useLatest } from '../shared/use-latest'
import { ChatUploadFileInfo } from '../urql/useUploadChatFile'
import { MessageTypeInput } from './ChatCompose'
import { ChatsStateDispatch, DraftMessage } from './useChatsState'
import { useChatUploadFile } from '../urql/useUploadChatFile'
import { PhotoDimensions } from './ChatUploadButton'
import {
  ChatUploadDocumentInfo,
  useChatUploadDocument,
} from '../urql/useUploadDocument'

// Atom
type UploadFileType = ({}: {
  file: File
  draftText?: string
}) => Promise<ChatUploadDocumentInfo>
let chatUploaderAtom = atom<{
  uploadFile: UploadFileType
  isUploading: boolean
}>({
  uploadFile: ({ file, draftText }) =>
    new Promise<ChatUploadDocumentInfo>(() => {
      console.warn('uploadFile called before init')
    }),
  isUploading: false,
})

// Hook
export const useFileUploadManager = () => {
  let setChatUploader = useUpdateAtom(chatUploaderAtom)
  const [isUploading, setIsUploading] = useState(false)
  const [
    { fetching: uploadFileLoading },
    doUploadFile,
  ] = useChatUploadDocument()
  let [, modalsDispatch] = useModals()

  // Define actions

  const uploadFile: UploadFileType = ({ file, draftText }) =>
    new Promise<ChatUploadDocumentInfo>(async (resolve, reject) => {
      if (photoMimes.has(file.type)) {
        const objectUrl = URL.createObjectURL(file)
        const dimensions = await getImageDimensions(objectUrl)

        // open modal
        modalsDispatch({
          type: 'modal opened',
          modalName: 'previewPhoto',
          modalData: {
            dimensions: dimensions,
            // Get this from current chat input
            textMessage: draftText,
            doUpload: async () => {
              setIsUploading(true)

              const uploadData = await doUploadFile({ file: file })
              // send file
              const objectId = uploadData.data?.documentInfo?.objectId
              if (
                uploadData.data?.documentInfo &&
                objectId &&
                objectId !== ''
              ) {
                resolve(uploadData.data.documentInfo)
                //   sendFileMessage({ documentInfo: uploadData.data.documentInfo })
              }
              setIsUploading(false)
              // clear
              URL.revokeObjectURL(objectUrl)
            },
            photoUrl: objectUrl,
          },
        })
      } else {
        setIsUploading(true)
        const uploadData = await doUploadFile({ file: file })
        // send file
        const objectId = uploadData.data?.documentInfo?.objectId
        if (uploadData.data?.documentInfo && objectId && objectId !== '') {
          // sendFileMessage({ documentInfo: uploadData.data.documentInfo })
          resolve(uploadData.data.documentInfo)
        }
        setIsUploading(false)
      }
    })

  // Stability the functions for perf to avoid rerenders
  const FileUploaderFunction = useLatest(uploadFile)

  useEffect(() => {
    // Pass everything to atom
    setChatUploader({
      isUploading,
      uploadFile: (...args) => FileUploaderFunction.current?.(...args),
    })
  }, [isUploading, FileUploaderFunction, setChatUploader])
}

export const useChatUploader = () => {
  return useAtomValue(chatUploaderAtom)
}

// Utility
export const getImageDimensions = (file: string): Promise<PhotoDimensions> => {
  return new Promise(function (resolved, rejected) {
    let i = new self.Image()
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    i.onload = function () {
      resolved({ w: i.width, h: i.height })
      // @ts-ignore
      i = null
    }
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    i.onerror = (error) => {
      console.error('Failed to get dimensions', error, file)
      rejected(error)
    }
    i.src = file
  })
}

const photoMimes = new Set([
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
])
