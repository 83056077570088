import { a, useSpring, useTransition } from '@react-spring/native'
import { useModals } from '@there/components/feed/ModalsContext'
import { useTheme } from '@there/components/feed/ThemeContext'
import { EmojiView } from '@there/components/main/EmojiView'
import { useAppContext } from '@there/components/shared/AppContext'
import { Tooltip } from '@there/components/shared/Tooltip'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { radiusConstant } from '../feed/ProfilePhoto'
import { Pressable } from '../shared/Pressable'
import { useHover } from '../shared/use-hover'

export const AvatarNudge = (props: {
  disabled?: boolean
  show: boolean
  isUs?: boolean
  delay?: number
  size: number
  sendNudge: (emoji: string) => void
}) => {
  let theme = useTheme()
  let audioRef = useRef<HTMLAudioElement | null>(null)
  let [hovered, hoverListener] = useHover()

  useEffect(() => {
    let audioElement = audioRef.current

    if (!audioElement) {
      audioRef.current = audioElement = new Audio()
    }
    audioElement.src = '/tones/nudge.mp3'
    audioElement.volume = 0.5
  }, [])

  // Show with delay
  let [delayedShow, setDelayedShow] = useState(false)
  useEffect(() => {
    if (!props.show) {
      setDelayedShow(false)
    } else {
      let timeout = setTimeout(() => {
        setDelayedShow(true)
      }, props.delay)

      return () => clearTimeout(timeout)
    }
  }, [props.delay, props.show])

  let show = props.delay ? delayedShow : props.show

  let renderTransition = useTransition(show, {
    enter: {
      opacity: 1,
      y: 0,
      delay: 30,
    },
    from: {
      opacity: 0.1,
      y: 0,
    },
    leave: {
      opacity: 0,
      y: 0,
    },
    config: {
      tension: 180,
      friction: 10,
      mass: 0.1,
    },
  })

  let nudgeAnimation = useSpring({
    scale: hovered ? 1.2 : 1,
    config: {
      tension: 350,
      friction: 20,
      mass: 0.1,
    },
  })

  let [, modalsDispatch] = useModals()
  let { nudgeLearned } = useAppContext()
  const nudgeLearning = useCallback(() => {
    if (nudgeLearned) return
    modalsDispatch({ type: 'modal opened', modalName: 'nudgeLearning' })
  }, [modalsDispatch, nudgeLearned])

  return (
    <>
      {renderTransition(({ opacity, y }, item) =>
        item ? (
          <a.View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: props.size,
              height: props.size,
              borderRadius: props.size * radiusConstant,
              overflow: 'hidden',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              opacity,
              transform: [{ translateY: y }],
            }}
            {...hoverListener}
          >
            <Tooltip
              label={
                props.isUs ? (
                  <>
                    Try nudge to see <br />
                    how it looks like!
                  </>
                ) : (
                  'Nudge'
                )
              }
              placement="bottomRight"
            >
              <Pressable
                style={{
                  width: props.size,
                  height: props.size,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: props.size * radiusConstant,
                }}
                disabled={props.disabled}
                onPress={() => {
                  if (audioRef && audioRef.current) {
                    audioRef.current.play()
                  }
                  props.sendNudge('👋')
                  nudgeLearning()
                }}
              >
                {({ pressed }) => (
                  <a.View
                    style={[
                      {
                        width: props.size,
                        height: props.size,
                        alignItems: 'center',
                        justifyContent: 'center',
                        transform: [
                          { translateY: 3 },
                          { translateX: 3 },
                          { scale: nudgeAnimation.scale },
                          { translateY: -3 },
                          { translateX: -3 },
                        ],
                      },
                      pressed && {
                        transform: [{ scale: 1.1 }],
                      },
                    ]}
                  >
                    <EmojiView size={22}>👋</EmojiView>
                  </a.View>
                )}
              </Pressable>
            </Tooltip>
          </a.View>
        ) : null,
      )}
    </>
  )
}
