import { a } from '@react-spring/native'
import { a as webA, useSpring } from '@react-spring/web'
import { Size } from '@there/app/types/general'
import {
  useModals,
  ViewPhotoModalData,
} from '@there/components/feed/ModalsContext'
import { useTheme } from '@there/components/feed/ThemeContext'
import useWindowSize from '@there/components/hooks/useWindowSize'
import {
  getFileFullPath,
  getMessageMediaFullPath,
} from '@there/components/message/helpers'
import { MessageMeta } from '@there/components/message/MessageMeta'
import { usePhotoViewers } from '@there/components/photoViewer/state'
import { useAppContext } from '@there/components/shared/AppContext'
import { PopoversPortal } from '@there/components/shared/ClientOnlyPortal'
import { useLatest } from '@there/components/shared/use-latest'
import { usePrevious } from '@there/components/shared/use-previous'
import { ChatMessageInfo } from '@there/components/types/chat'
import { easeOutCubic } from '@there/components/utils/easings'
import { throttle } from '@there/components/utils/schedulers'
import { Rectangle } from '@there/desktop/shared/types'
import { DocumentInfo, StatusInfo } from '@there/sun/utils/node-types'
import { toGlobalId } from '@there/tower/utils/global-id'
import { useDrag, usePinch, useWheel } from '@use-gesture/react'
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { useNurNode } from '../sun/use-node'
import { Pointers, SelfPointer } from './Pointers'

export const ViewPhotoModal = () => {
  let theme = useTheme()
  let [modalsState, modalsDispatch] = useModals()
  let [closing, setClosing] = useState(false)
  // Set to true after scale down animating
  let [closed, setClosed] = useState(false)

  const onClose = () => {
    setClosing(true)
    modalsDispatch({ type: 'modal closed', modalName: 'viewPhoto' })
  }

  let isOpen = modalsState.modals.includes('viewPhoto')
  let modalData = modalsState.modalsData['viewPhoto'] as
    | ViewPhotoModalData
    | undefined

  // used while closing
  let prevData = usePrevious(modalData)
  let currentData = closing && !modalData ? prevData : modalData

  let position = currentData?.position
  let message: ChatMessageInfo | undefined
  let status: StatusInfo | undefined
  if (currentData?.type === 'message photo') {
    message = currentData?.message
  } else {
    status = currentData?.status
  }

  // Reset
  useEffect(() => {
    if (!isOpen) return
    setClosing(false)
    setClosed(false)
  }, [isOpen])

  let openConfig = {
    tension: 300,
    friction: 24,
    mass: 0.4,
  }

  let closeConfig = {
    tension: 100,
    friction: 10,
    mass: 1,
  }

  let [document] = useNurNode<DocumentInfo>({
    id:
      message && message.documentId
        ? message.documentId ||
          toGlobalId('Document', message?.documentId || '')
        : status && status.document
        ? //@ts-ignore
          status.document.id || status.document.__link
        : null,
  })

  let srcImage = useMemo(() => {
    if (message && message.document) {
      return getMessageMediaFullPath(message)
    }
    if (status && status.document) {
      return getFileFullPath(status.document)
    }
    if (document) {
      return getFileFullPath(document)
    }
  }, [document, message, status])

  return (
    <PopoversPortal
      isOpen={isOpen}
      onClose={onClose}
      back={true}
      // To be able to animate photo collapsing
      keepMounted={140}
      backMode={'normal'}
      inside={
        <a.View
          style={[
            {
              zIndex: 20,
              height: '100vh',
              width: '100vh',
              position: 'absolute',
              top: 0,
              left: 0,
              //@ts-ignore
              pointerEvents: isOpen ? 'all' : 'none',
            },
          ]}
        >
          {message && srcImage && !closed && (
            <Photo
              message={message}
              messageId={message.id}
              src={srcImage}
              onClose={onClose}
              onCloseAnimationEnd={() => {
                setClosed(true)
              }}
              closing={closing}
              position={position}
            />
          )}
          {status && srcImage && !closed && (
            <Photo
              status={status}
              messageId={status.id}
              src={srcImage}
              onClose={onClose}
              onCloseAnimationEnd={() => {
                setClosed(true)
              }}
              closing={closing}
              position={position}
            />
          )}
        </a.View>
      }
    />
  )
}

const Photo = ({
  message,
  status,
  messageId,
  src,
  onClose,
  onCloseAnimationEnd,
  position,
  closing,
}: {
  status?: StatusInfo | undefined
  message?: ChatMessageInfo | undefined
  messageId: string
  src: string
  onClose: () => void
  onCloseAnimationEnd: () => void
  position?: Rectangle
  closing: boolean
}) => {
  const parent = useRef<HTMLDivElement | null>(null)
  const target = useRef<HTMLImageElement | null>(null)

  const [initialSize, setInitialSize] = useState<{
    height: number
    width: number
  } | null>(null)
  const [zoom, setZoom] = useState(1)
  const [zoomScale, setZoomScale] = useState(1)

  useEffect(() => {
    // See https://use-gesture.netlify.app/docs/hooks/#about-the-pinch-gesture
    const preventGesture = (e: any) => e.preventDefault()
    document.addEventListener('gesturestart', preventGesture)
    document.addEventListener('gesturechange', preventGesture)
    return () => {
      document.removeEventListener('gesturestart', preventGesture)
      document.removeEventListener('gesturechange', preventGesture)
    }
  }, [])

  let [pinching, setPinching] = useState(false)

  usePinch(
    ({ active, last, offset: [scale], pinching }) => {
      setPinching(active)
      if (active) {
        setZoomScale(scale)
        setZoom(1)
        return
      }

      if (last) {
        // todo: make 1 object to avoid both not changing at the same time
        // Order is important, first make it bigger then 1
        setZoom(zoom * scale)
        setZoomScale(1)
      }
    },
    {
      target: parent,
      // pointer: { touch: true },
      eventOptions: { passive: false },
      scaleBounds: { min: 0.2, max: 3 },
    },
  )

  const imageHeight = initialSize ? initialSize.height * zoom : undefined
  const imageWidth = initialSize ? initialSize.width * zoom : undefined

  const style = {
    width: imageWidth,
    height: imageHeight,
    // While zooming, temporarily scale
    transform: `scale(${zoomScale})`,
  }

  let { updateMousePosition } = usePhotoViewers()

  let debouncedUpdateMousePos = useMemo(() => {
    return throttle(updateMousePosition, 16)
  }, [updateMousePosition])

  let prevMove = useRef({ x: 0, y: 0 })

  // Transfer mouse message
  const handleMouseMove = useCallback(
    (event: MouseEvent<HTMLImageElement>) => {
      if (!initialSize) return

      let imageWidth = initialSize.width * zoom
      let imageHeight = initialSize.height * zoom
      // Because image is in center
      let mouseX = event.pageX - (window.innerWidth - imageWidth) / 2
      let mouseY = event.pageY - (window.innerHeight - imageHeight) / 2

      let xPercent = (mouseX * 100) / imageWidth
      let yPercent = (mouseY * 100) / imageHeight

      if (prevMove.current.x === xPercent && prevMove.current.y === yPercent) {
        // ignore
        return
      }

      debouncedUpdateMousePos(xPercent, yPercent)
      prevMove.current.x = xPercent
      prevMove.current.y = yPercent
    },
    [debouncedUpdateMousePos, initialSize, zoom],
  )

  // --------------------------
  // Drag / Scroll
  // --------------------------
  // where image can be displayed (ex bars, menus, etc)
  const safeParentBounds = useWindowSize({ throttleMs: 100 })
  const [scroll, setScroll] = useState({ y: 0, x: 0 })
  const [dragOffset, setDragOffset] = useState({ y: 0, x: 0 })
  // When zooming out, we apply a temporary correct to prevent off-center zoom-out
  const [correctionOffset, setCorrectionOffset] = useState({ y: 0, x: 0 })

  // Divide by 2 because we start from center initially
  let maxScrollVertical = imageHeight
    ? Math.max(0, (imageHeight - safeParentBounds.height) / 2)
    : 0
  let maxScrollHorizontal = imageWidth
    ? Math.max(0, (imageWidth - safeParentBounds.width) / 2)
    : 0

  const scrollBounds = {
    // Subtract dragged offset
    top: Math.floor(
      (maxScrollVertical + dragOffset.y + correctionOffset.y) * -1,
    ),
    bottom: Math.floor(maxScrollVertical - dragOffset.y - correctionOffset.y),
    left: Math.floor(
      (maxScrollHorizontal + dragOffset.x + correctionOffset.x) * -1,
    ),
    right: Math.floor(maxScrollHorizontal - dragOffset.x - correctionOffset.x),
  }

  const dragBounds = {
    // Subtract scrolled offset
    top: Math.floor((maxScrollVertical + scroll.y + correctionOffset.x) * -1),
    bottom: Math.floor(maxScrollVertical - scroll.y - correctionOffset.x),
    left: Math.floor(
      (maxScrollHorizontal + scroll.x + correctionOffset.x) * -1,
    ),
    right: Math.floor(maxScrollHorizontal - scroll.x - correctionOffset.x),
  }

  // Sum of drag and scroll
  const movedOffset = {
    x: scroll.x + dragOffset.x + correctionOffset.x,
    y: scroll.y + dragOffset.y + correctionOffset.y,
  }

  // Handle wheeling wheen zoomed in
  useWheel(
    ({ offset: [x, y] }) => {
      setScroll({ x, y })
    },
    {
      target: parent,
      eventOptions: { passive: false },
      transform: ([x, y]) => [x * -1, y * -1],
      bounds: scrollBounds,
    },
  )

  // Drag to scroll
  useDrag(
    ({ tap, offset: [x, y] }) => {
      setDragOffset({ x, y })

      if (tap) {
        // If single tap, close it
        onClose()
      }
    },
    {
      target: parent,
      bounds: dragBounds,
    },
  )

  let dragOffsetRef = useLatest(dragOffset)
  let scrollOffsetRef = useLatest(scroll)

  // Reset scroll if zoomed out (thus not anymore scrollable)
  useEffect(() => {
    if (!initialSize) return
    // Times zoomScale because we need it correct even while pinching
    let currentImageWidth =
      zoom !== 1 ? initialSize.width * zoom : initialSize.width * zoomScale
    let currentImageHeight =
      zoom !== 1 ? initialSize.height * zoom : initialSize.height * zoomScale

    // Image has become smaller than viewport, reset offset to realign at center
    if (
      currentImageHeight < safeParentBounds.height &&
      currentImageWidth < safeParentBounds.width
    ) {
      setCorrectionOffset({ x: 0, y: 0 })
      setScroll({ x: 0, y: 0 })
      setDragOffset({ x: 0, y: 0 })
      return
    }

    // Calc new max
    let maxScrollVertical = Math.max(
      0,
      (currentImageHeight - safeParentBounds.height) / 2,
    )
    let maxScrollHorizontal = Math.max(
      0,
      (currentImageWidth - safeParentBounds.width) / 2,
    )

    if (!dragOffsetRef.current) return
    if (!scrollOffsetRef.current) return

    // Clamp, and get the diff
    // x
    let sumX = dragOffsetRef.current.x + scrollOffsetRef.current.x
    let idealX = clamp(sumX, -1 * maxScrollHorizontal, maxScrollHorizontal)
    let diffToIdealX = sumX - idealX
    // y
    let sumY = dragOffsetRef.current.y + scrollOffsetRef.current.y
    let idealY = clamp(sumY, -1 * maxScrollVertical, maxScrollVertical)
    let diffToIdealY = sumY - idealY

    setCorrectionOffset({ x: -1 * diffToIdealX, y: -1 * diffToIdealY })
  }, [
    dragOffsetRef,
    imageHeight,
    imageWidth,
    initialSize,
    safeParentBounds.height,
    safeParentBounds.width,
    scrollOffsetRef,
    zoom,
    zoomScale,
  ])

  // Styles to move
  const scrollContentStyle = {
    transform: `translate3d(${movedOffset.x}px, ${movedOffset.y}px, 0)`,
  }

  // -------------------------------- start
  // Animate from the image area
  let center = {
    x: safeParentBounds.width / 2,
    y: safeParentBounds.height / 2,
  }
  /** Diff window center with message photo center */
  let msgDiffFromCenter = { x: 0, y: 0 }
  if (position) {
    msgDiffFromCenter = {
      x: position.x - center.x + position.width / 2,
      y: position.y - center.y + position.height / 2,
    }
  }
  let pause = !initialSize || !position
  let initialPosition = {
    ...msgDiffFromCenter,
    scale: Number(position?.width) / (Number(initialSize?.width) * zoom),
  }
  let finalPosition = { x: 0, y: 0, scale: 1 }

  const initialStyle = useSpring({
    pause: closing ? false : pause,
    from: closing ? finalPosition : initialPosition,
    to: closing ? initialPosition : finalPosition,
    // config: { duration: 140, easing: easeOutCubic },
    onRest: () => {
      if (closing) {
        onCloseAnimationEnd()
      }
    },
    config: closing
      ? { duration: 150, easing: easeOutCubic }
      : {
          mass: 0.1,
          friction: 12,
          tension: 200,
        },
  })
  let scaleFromOriginal =
    (initialSize?.width || 1) / ((position?.width || 1) / zoom)
  // -------------------------------- end

  let { currentUserId } = useAppContext()
  let sentDate = useMemo(
    () =>
      message
        ? new Date(message.sentAt)
        : status
        ? new Date(status.createdAt)
        : new Date(),
    [message, status],
  )

  return (
    <Scrollable
      ref={parent}
      style={{
        width: '100vw',
        height: '100vh',
        // scroll handled manually
        overflow: 'hidden',
        // Prevent capturing scroll while closing animation
        pointerEvents: closing ? 'none' : 'all',
      }}
    >
      <SelfPointer isOpen={!closing} />

      <webA.div
        style={{
          position: 'relative',
          // animate
          ...initialStyle,
          // Prevent capturing scroll while closing animation
          pointerEvents: closing ? 'none' : 'all',
        }}
      >
        <div
          style={{
            ...scrollContentStyle,
          }}
        >
          {/* Pointers wrapper */}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: `translate3d(-50%,-50%, 0)`,
              width: `${zoomScale * 100}%`,
              height: `${zoomScale * 100}%`,
              zIndex: 10,
              pointerEvents: 'none',
            }}
          >
            {initialSize && (
              <Pointers
                messageId={messageId}
                imageWidth={initialSize.width}
                imageHeight={initialSize.height}
                scale={zoom * zoomScale}
              />
            )}
          </div>

          <img
            // ref={target}
            ref={(el) => {
              if (!el) return
              target.current = el

              // Only do initially
              if (!initialSize) {
                const initialSize = containInPage({
                  height: el.offsetHeight,
                  width: el.offsetWidth,
                })
                setInitialSize(initialSize)
              }
            }}
            onMouseMove={handleMouseMove}
            src={src}
            draggable="false"
            style={{
              // @ts-ignore
              pointerEvents: 'auto',
              userSelect: 'none',
              ...style,

              // for close animation
              borderRadius: closing ? 10 : 0,
            }}
          />

          {message && closing && (
            <div
              style={{
                transform: `scale(${scaleFromOriginal})`,
                transformOrigin: 'bottom right',
              }}
            >
              <MessageMeta
                message={message}
                isUs={message.senderId === currentUserId}
                largeEmojiMode={false}
                sendDate={sentDate}
                sent={true}
                hasRead={false}
              />
            </div>
          )}
        </div>
      </webA.div>
    </Scrollable>
  )
}

const Scrollable = styled.div`
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
`

function containInPage({ width, height }: Size): Size {
  const maxOfPage = 0.9
  const maxWidth = window.innerWidth * maxOfPage
  const maxHeight = window.innerHeight * maxOfPage
  const downScaleWidth = width > maxWidth ? maxWidth / width : 1
  const downScaleHeight = height > maxHeight ? maxHeight / height : 1
  const downScale = Math.min(downScaleWidth, downScaleHeight)

  return {
    width: width * downScale,
    height: height * downScale,
  }
}

const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max)
}
