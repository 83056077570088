import { useCallback, useEffect, useRef, useState } from 'react'
import { View, Text } from 'react-native'
import { useTheme } from 'styled-components'
import { useModals } from '../feed/ModalsContext'
import { StandardButton } from '../main/StandardButton'
import { Pressable } from '../shared/Pressable'
import { Space } from '../shared/Space'
import { ModalDescription } from './ModalDescription'
import { ModalWrapper } from './ModalWrapper'
import { useSpring, a } from '@react-spring/native'
import { useAppContext } from '../shared/AppContext'
import { ModalTitle } from './ModalTitle'

export const NudgeLearningModal = () => {
  let [modalsState, modalsDispatch] = useModals()
  const onClose = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'nudgeLearning' })
  }
  let { dispatch } = useAppContext()

  return (
    <ModalWrapper
      isOpen={modalsState.modals.includes('nudgeLearning')}
      onClose={onClose}
    >
      <View
        style={{
          width: 215,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalTitle align="center">How Nudge Works</ModalTitle>
        <Space vertical={15} />
        <NudgeLearningVideo />
        <Space vertical={15} />
        <ModalDescription align="center">
          A lightweight way to ping teammates. When in a room, they see a join
          button!
        </ModalDescription>
        <Space vertical={10} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <StandardButton
            text="Done"
            backgroundColor="primaryButtonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={() => {
              onClose()
              dispatch({ type: 'nudge learned' })
            }}
          />
        </View>
      </View>
    </ModalWrapper>
  )
}

const NudgeLearningVideo = () => {
  let videoRef = useRef<HTMLVideoElement | null>(null)
  let [isVideoEnded, setVideoEnded] = useState(false)
  let theme = useTheme()

  let replay = useCallback(() => {
    if (!videoRef || !videoRef.current) return
    setVideoEnded(false)
    videoRef.current.play()
  }, [])

  useEffect(() => {
    let video = videoRef.current
    let onVideoEnded = () => {
      setVideoEnded(true)
    }

    let timeout = setTimeout(() => {
      if (!video) return
      video.play()
    }, 700)

    if (video) {
      video.addEventListener('ended', onVideoEnded)
    }

    return () => {
      clearTimeout(timeout)
      if (video) {
        video.removeEventListener('ended', onVideoEnded)
      }
    }
  }, [])

  let videoEndedStyles = useSpring({
    opacity: isVideoEnded ? 1 : 0,
    config: {
      tension: 300,
    },
  })

  return (
    <View
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <video
        ref={videoRef}
        src="/learning/nudge.mp4"
        controls={false}
        style={{
          width: 215,
          height: 127,

          borderRadius: 10,
        }}
      />
      <a.View
        style={[
          {
            width: 215,
            height: 127,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: 10,

            position: 'absolute',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          },
          {
            opacity: videoEndedStyles.opacity,
          },
        ]}
      >
        <Pressable
          //@ts-ignore
          style={({ hovered, pressed }) => [
            {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              paddingHorizontal: 10,
              paddingVertical: 4,
              borderBottomRightRadius: 10,
              borderTopLeftRadius: 10,

              shadowOffset: { width: 0, height: 3 },
              shadowRadius: 8,
              shadowColor: 'rgba(0, 0, 0, 0.2)',
            },
            hovered && {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
            pressed && {
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
          ]}
          onPress={replay}
        >
          <Text
            style={{
              fontSize: theme.fontSizes.normal,
              color: theme.colors.tertiaryText,
            }}
          >
            Replay
          </Text>
        </Pressable>
      </a.View>
    </View>
  )
}
