import { useCallback, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { Pressable } from '@there/components/shared/Pressable'
import { useSpring, a } from '@react-spring/native'
import { useTheme } from '@there/components/feed/ThemeContext'
import { useLatest } from '@there/components/shared/use-latest'

const HandleSize = 20

type Props = {
  disabled?: boolean
  onValueChange?: (value: number) => void
  value?: number
  icon?: React.ReactNode
  onDrag?: () => void
  onRelease?: () => void
}

export const Slider = ({
  disabled = false,
  onValueChange = () => {},
  value = 0,
  icon = <></>,
  onDrag = () => {},
  onRelease = () => {},
}: Props) => {
  let theme = useTheme()
  let [isMovable, setMovable] = useState(false)
  let [localValue, setLocalValue] = useState(0)
  let width = useRef(0)
  let minimumValue = 0
  let maximumValue = width.current - HandleSize

  let onValueChangeFunction = useLatest(onValueChange)

  const update = useCallback(
    (nextValue: number) => {
      let newValue = Math.min(
        Math.max(minimumValue, nextValue),
        width.current - HandleSize,
      )

      setLocalValue(newValue)

      // Map value into [0 100] range
      onValueChangeFunction.current((newValue * 100) / maximumValue)
    },
    [maximumValue, minimumValue, onValueChangeFunction],
  )

  let localValueRef = useLatest(localValue)

  // Set value to handle component on mouse move
  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!isMovable) return
      update(localValueRef.current + event.movementX)
    },
    [isMovable, localValueRef, update],
  )

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [onMouseMove])

  // Stop movement on MouseUp released
  const onMouseUp = useCallback(() => {
    setMovable(false)
    onRelease()
  }, [onRelease])

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp)
    return () => {
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [onMouseUp])

  // Handle Component Movement Animation
  const [styleProps, animate] = useSpring(() => ({
    x: localValue,
    width: localValue + 10,
    config: {
      duration: 16,
    },
  }))

  // Start animation on drag
  useEffect(() => {
    animate({ x: localValue, width: localValue + 10 })
  }, [animate, isMovable, localValue, width])

  return (
    <Pressable
      onLayout={(event) => {
        width.current = event.nativeEvent.layout.width

        // map value depend to component width
        setLocalValue(
          (value * (event.nativeEvent.layout.width - HandleSize)) / 100,
        )
      }}
      style={{
        width: '100%',
        height: 20,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: 20,
        position: 'relative',
        overflow: 'hidden',
      }}
      onResponderStart={(event) => {
        setMovable(true)

        update(event.nativeEvent.locationX - HandleSize / 2)
      }}
    >
      <a.View
        style={{
          position: 'absolute',
          width: styleProps.width,
          height: '100%',
          backgroundColor: '#fff',
          paddingLeft: 5,
          zIndex: -1,

          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {icon}
      </a.View>
      <a.View
        style={[
          { position: 'relative', zIndex: 1 },
          {
            transform: [{ translateX: styleProps.x }],
          },
        ]}
      >
        <Pressable
          style={({ pressed }) => [
            {
              width: HandleSize,
              height: HandleSize,
              borderRadius: HandleSize,
              backgroundColor: '#fff',

              shadowRadius: 2,
              shadowColor: '#000',
            },
            pressed && {
              backgroundColor: 'rgba(235, 235, 235, 1)',
            },
          ]}
          onResponderStart={(event) => {
            setMovable(true)
            onDrag()
          }}
          onResponderRelease={(event) => {
            setMovable(false)
            onRelease()
          }}
        />
      </a.View>
    </Pressable>
  )
}
