import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export function HomeIcon(props: SvgProps) {
  return (
    <Svg width={14} height={16} viewBox="0 0 14 16" fill="none" {...props}>
      <Path
        d="M5.5 15H3a2 2 0 01-2-2V7.98a2 2 0 01.46-1.28l4-4.84a2 2 0 013.08 0l4 4.84c.3.36.46.81.46 1.28V13a2 2 0 01-2 2H8.8m-3.3 0v-3.97a1.65 1.65 0 113.3 0V15m-3.3 0h3.3"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
      />
    </Svg>
  )
}

export function HomeFillIcon(props: SvgProps) {
  return (
    <Svg width="12" height="15" viewBox="0 0 12 15" fill="none" {...props}>
      <Path
        d="M0.292893 5.70711L5.29289 0.707107C5.68342 0.316583 6.31658 0.316583 6.70711 0.707107L11.7071 5.70711C11.8946 5.89464 12 6.149 12 6.41421V14C12 14.5523 11.5523 15 11 15H9C8.44771 15 8 14.5523 8 14V11C8 10.4477 7.55228 10 7 10H5C4.44772 10 4 10.4477 4 11V14C4 14.5523 3.55228 15 3 15H1C0.447715 15 0 14.5523 0 14V6.41421C0 6.149 0.105357 5.89464 0.292893 5.70711Z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}
