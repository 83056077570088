import { useNurMutation } from '@there/components/sun/use-mutation'
import type {
  UpdateFocusMessage,
  UpdateFocusResult,
} from '@there/sun/modules/updateFocus'

type RemoteVariables = UpdateFocusMessage['payload']

export interface LocalUpdateFocusMessageVariables extends RemoteVariables {
  __extra: {
    userId: string
  }
}

export const useUpdateFocus = () => {
  return useNurMutation<UpdateFocusResult, LocalUpdateFocusMessageVariables>({
    method: 'updateFocus',
  })
}
