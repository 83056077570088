import { Text, TextStyle } from 'react-native'
import { useTheme } from '../feed/ThemeContext'

export const HelpText = ({
  children,
  alignment,
}: {
  children: string
  alignment?: TextStyle['textAlign']
}) => {
  let theme = useTheme()
  return (
    <Text
      style={{
        fontSize: theme.fontSizes.normal,
        color: theme.colors.quaternaryText,
        width: '100%',
        textAlign: alignment,
      }}
    >
      {children}
    </Text>
  )
}
