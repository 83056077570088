import React, { useCallback, useEffect, useState } from 'react'
import { View, StyleSheet, ViewProps } from 'react-native'
import { useTheme } from '@there/components/feed/ThemeContext'
import { useAppContext } from '@there/components/shared/AppContext'
import { useHover } from '@there/components/shared/use-hover'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { WebkitAppRegion, WebStyled } from 'components/shared/WebStyled'
import { useSpring, a } from '@react-spring/native'
import { RtcAvatar } from '../shared/use-rtc-avatars'
import { ipc } from '@there/desktop/utils/electron-api'
import { DialogInfo } from '@there/sun/utils/node-types'
import { NativeWindowClose } from '../shared/NativeWindowClose'
import { VideoAvatar } from '@there/components/rtc/VideoAvatar'
import { VerticalControls } from '@there/components/rtc/VerticalControls'
import { transparentize } from 'polished'
import { CameraDropdown } from '@there/components/dropdown/CameraDropdown'
import { useWindowContext } from '../shared/WindowContext'
import { MoveIcon } from '../main/MoveIcon'
import { useCameraBoxCover } from '@there/components/screen-share/useCameraBoxCover'

/** Width size of controls */
export const controlsWidth = 35
const baseSize = controlsWidth + 12
export const cameraControlsAndPaddingWidth = 44
const bottomPadding = 8
export const topPadding = 8
export const avatarsMargin = 8
export const webAvatarSize = 140
export function getCameraViewAvatarSize(windowHeight: number) {
  // for now derive the avatar size
  // TODO: get it dynamically
  let avatarSize = windowHeight - bottomPadding - topPadding + avatarsMargin
  return avatarSize
}
export function getCameraViewWidth(input: {
  avatarsCount: number
  windowHeight: number
}) {
  let avatarSize = getCameraViewAvatarSize(input.windowHeight)
  return Math.floor(baseSize + input.avatarsCount * avatarSize)
}
export function getCameraViewWidthWeb(input: {
  avatarsCount: number
  windowHeight: number
}) {
  return Math.floor(
    baseSize + input.avatarsCount * (webAvatarSize + avatarsMargin),
  )
}

type Props = {
  avatars: RtcAvatar[]
  dialog?: DialogInfo | undefined
  overObserver?: boolean
  toolBar?: boolean
  hideSelfView?: boolean
  avatarCount?: number
  setHideSelfView: (value: boolean) => void
}

export const CameraView = ({
  avatars,
  dialog,
  overObserver,
  avatarCount,
  toolBar = true,
  hideSelfView = false,
  setHideSelfView = () => {},
}: Props) => {
  const theme = useTheme()
  const { currentUserId } = useAppContext()
  let { isElectron, isMacOs } = useWindowContext()

  const {
    walkieState,
    _streams,
    toggleTalk,
    stopScreenSharing,
    stopCamera,
    joinParticipantScreen,
  } = useRtcContext()
  const [windowHovered, windowHoverListener] = useHover()

  // todo: remove fallback (it's legacy)
  let renderingCount = avatarCount || avatars?.length

  const { isWindows } = useWindowContext()
  const [avatarSize, setAvatarSize] = useState(webAvatarSize)

  let isSelfViewHidden = hideSelfView

  const toggleSelfView = useCallback(() => {
    setHideSelfView(!hideSelfView)
  }, [hideSelfView, setHideSelfView])

  /**
   * Resize based on people
   */

  useEffect(() => {
    if (renderingCount === 0) return
    if (overObserver) return
    const resize = (count: number) => {
      let avatarSize = getCameraViewAvatarSize(window.innerHeight)
      let windowWidth = getCameraViewWidth({
        avatarsCount: count,
        windowHeight: window.innerHeight,
      })
      setAvatarSize(avatarSize)

      ipc?.invoke('rtc:avatars-count-updated', count)
      ipc?.invoke(
        'rtc:resize',
        {
          width: windowWidth,
        },
        true,
      )

      console.info('Resizing', windowWidth)
    }

    resize(renderingCount)

    return () => {}
  }, [renderingCount, overObserver])

  useEffect(() => {
    function calculate() {
      let avatarSize = getCameraViewAvatarSize(window.innerHeight)
      setAvatarSize(avatarSize)
    }

    let delay = 100, // delay between calls
      throttled = false // are we currently throttled?
    function resized() {
      // only run if we're not throttled
      if (!throttled) {
        // actual callback action
        calculate()
        // we're throttled!
        throttled = true
        // set a timeout to un-throttle
        setTimeout(function () {
          throttled = false
        }, delay)
      }
    }

    window.addEventListener('resize', resized, { passive: true })

    return () => {
      window.removeEventListener('resize', resized)
    }
  })

  let animatedStyleProps = useSpring({
    backgroundColor: windowHovered
      ? transparentize(0.3, theme.colors.background)
      : 'rgba(0, 0, 0, 0)',
    // borderWidth: 2,
    borderRadius: isMacOs ? 10 : 0,
    config: {
      tension: 400,
    },
  })

  let floatingMode = overObserver || !isElectron

  return (
    <WebkitAppRegion
      mode={isWindows ? 'no-drag' : 'drag'}
      style={{
        position: 'relative',
        //@ts-ignore
        cursor: 'move',
        borderRadius: isElectron ? undefined : 10,
        overflow: 'hidden',

        ...(overObserver
          ? {
              position: 'fixed',
              bottom: 8,
              right: 8,
              borderRadius: 30,
              background: `linear-gradient(225deg, #292929 20%, rgba(59, 61, 75, 0.5) 80%)`,
              backdropFilter: `blur(5px)`,
            }
          : {}),
      }}
    >
      <>
        <CameraDropdown
          isUs={true}
          isSelfViewHidden={isSelfViewHidden}
          toggleSelfView={toggleSelfView}
        >
          <View {...windowHoverListener}>
            {!floatingMode && <CloseWindowButton show={windowHovered} />}
            <a.View
              style={[
                animatedStyleProps,
                {
                  paddingBottom: bottomPadding,
                  paddingTop: topPadding,
                  height: !floatingMode
                    ? '100vh'
                    : webAvatarSize + avatarsMargin * 2,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                },
              ]}
            >
              {toolBar && <VerticalControls show={windowHovered} />}
              <View
                style={[
                  {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    flexShrink: 1,
                  },
                ]}
              >
                {avatars.map((pinAvatar, index) => {
                  let user = pinAvatar.user
                  let isUs = currentUserId === user.id
                  let participant = walkieState.participants[user.id] || {}

                  if (isSelfViewHidden && isUs) return null
                  return (
                    <AvatarWrapper
                      key={user.id}
                      fluid={index + 1 === renderingCount}
                      style={{}}
                    >
                      <VideoAvatar
                        size={avatarSize}
                        user={user}
                        isUs={isUs}
                        hiddenSelfView={isUs && isSelfViewHidden}
                        isTalking={
                          isUs ? walkieState.weTalking : !!participant.isTalking
                        }
                        isSharingCamera={
                          isUs
                            ? walkieState.weSharingCamera
                            : !!participant.isSharingCamera
                        }
                        isSharingScreen={
                          isUs
                            ? walkieState.weSharingScreen
                            : !!participant.isSharingScreen
                        }
                        cameraStream={
                          isUs
                            ? _streams?.camera.stream
                            : walkieState.participants[user.id]?.cameraStream
                        }
                        joinParticipantScreen={joinParticipantScreen}
                      />
                    </AvatarWrapper>
                  )
                })}
              </View>
            </a.View>
          </View>
        </CameraDropdown>

        {isWindows && <WindowsMoveHandle />}
      </>
    </WebkitAppRegion>
  )
}

const AvatarWrapper = ({
  style,
  fluid,
  ...props
}: ViewProps & { children: React.ReactNode; fluid?: boolean }) => {
  return (
    <View
      style={[
        // @ts-ignore
        {
          marginHorizontal: avatarsMargin / 2,
          height: '100%',
          // @ts-ignore
          aspectRatio: `1 / 1`,
        },
        // fluid && {
        //   height: 'auto',
        //   width: 'auto',
        //   maxHeight: '100%',
        //   flexShrink: 1,
        // },
        style,
      ]}
      {...props}
    />
  )
}

const WindowsMoveHandle = () => {
  let theme = useTheme()
  return (
    <WebkitAppRegion
      mode="drag"
      style={{
        position: 'absolute',
        right: 2,
        bottom: 2,
        transform: 'rotate(-140deg)',
        cursor: 'move',
      }}
    >
      <MoveIcon width={16} color={theme.colors.quaternaryText} />
    </WebkitAppRegion>
  )
}

const CloseWindowButton = ({ show }: { show: boolean }) => {
  const { isElectron } = useWindowContext()
  const { stopCamera } = useRtcContext()

  let fadeStyleProps = useSpring({
    opacity: show ? 1 : 0,
    config: {
      tension: 400,
    },
  })
  return (
    <a.View
      style={[
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        },
        fadeStyleProps,
        { height: 12, position: 'absolute', top: 6, left: 6, zIndex: 2 },
        !isElectron && {
          borderRadius: 10,
          overflow: 'hidden',
        },
      ]}
    >
      {isElectron && (
        <NativeWindowClose
          onPress={(close) => {
            stopCamera()
            close()
          }}
        />
      )}
    </a.View>
  )
}
