import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { useAtom } from 'jotai'
import { useSpaceContext } from '../shared/spaceContext'
import { contentViewAtom } from '../atoms/contentViewAtom'
import { GameItem } from './GameItem'
import { useEditDialog } from '../main/useEditDialog'
import { useAppContext } from '../shared/AppContext'
import { useCallback } from 'react'
import { AvatarInfo, AvatarWithUserInfo } from '@there/sun/utils/node-types'
import { useRtcAvatarsContext } from '../shared/use-rtc-avatars'
import { NewDialogItem } from '../main/DialogItem'
import { useUsersContext } from '../shared/UsersContext'
import { CurrentGameBox } from './CurrentGameBox'
import { useTheme } from '@there/components/feed/ThemeContext'
import { electronApi, isElectron } from '@there/desktop/utils/electron-api'
import { useConfirm } from '../modal/use-confirm'
import { GameSetBox } from './GameSetBox'
import { useMainWindowContext } from '../shared/use-main-window'
import { Pressable } from '../shared/Pressable'
import { LeftArrowIcon } from '../main/mainIcons'
import { GameInfo, gamesArray, useGameRoom } from './useGame'

type DialogGameInfo = {
  game: GameInfo
  gameServer: string
}

type Props = {
  wrapperWidth: number
}
export const GameRoomView = ({ wrapperWidth }: Props) => {
  let [{ currentDialog: dialog, getDialogAvatars }] = useSpaceContext()
  let { isFeedOpen, dispatch } = useMainWindowContext()
  let { activeSpaceId } = useAppContext()
  let [, editDialog] = useEditDialog()
  let [, setContentView] = useAtom(contentViewAtom)
  const [selectedGame, setSelectedGame] = useState<GameInfo | null>(null)
  let theme = useTheme()
  let isMinimalView = isFeedOpen

  let { avatars: activeDialogAvatars } = useRtcAvatarsContext()
  let { getUser } = useUsersContext()

  const fullDialog = useMemo(() => {
    if (!dialog) return null
    // let avatars: AvatarInfo[] =getDialogAvatars(dialog.id) || activeDialogAvatars
    let avatars: AvatarInfo[] = activeDialogAvatars
    return {
      ...dialog,
      // avatars: dialog.avatars
      avatars: avatars
        // Use map and filter at the same time
        .reduce(
          (filteredAvatars: AvatarWithUserInfo[], avatar: AvatarInfo) => {
            // Filter if not user
            let user = avatar.userId ? getUser(avatar.userId) : null

            if (!user) return filteredAvatars

            filteredAvatars.push({
              ...avatar,
              user,
            })

            return filteredAvatars
          },
          // filtered avatars
          [] as AvatarWithUserInfo[],
        ),
    }
  }, [activeDialogAvatars, dialog, getUser])

  const selectGame = useCallback((game: GameInfo) => {
    setSelectedGame(game)
  }, [])

  const gameServerChanged = useCallback(
    ({ gameServer, game }: { game: GameInfo; gameServer: string }) => {
      if (!dialog) return
      if (!activeSpaceId) return

      editDialog({
        id: dialog?.id,
        gameId: game.id,
        gameServer: gameServer,
        __extra: {
          spaceId: activeSpaceId,
        },
      })
    },
    [activeSpaceId, dialog, editDialog],
  )

  // if (!dialog) {
  //   // redirect to home room
  //   setContentView('rooms')
  //   return null
  // }

  return (
    <ScrollView
      style={[
        styles.gameRoomWrapper,
        {
          width: '100%',
          flexGrow: 1,
        },
      ]}
    >
      <Pressable
        style={{
          opacity: 0.7,
          paddingVertical: 5,
          marginVertical: 5,

          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        onPress={() => {
          dispatch({ type: 'change gameRoom mode', isGameRoom: false })
        }}
      >
        <LeftArrowIcon height={10} />
        <Text
          style={[
            {
              color: '#fff',
              marginLeft: 4,
              fontSize: theme.fontSizes.small,
              fontWeight: 'bold',
            },
          ]}
        >
          back to room view
        </Text>
      </Pressable>
      {fullDialog && (
        <View style={styles.headWrapper}>
          <NewDialogItem dialog={fullDialog} compactRoom={false} />
        </View>
      )}

      {/* don't show current game server when in editMod */}
      <View
        style={{
          paddingHorizontal: 20,
          width: '100%',
          marginBottom: 10,
        }}
      >
        <CurrentGameBox isMinimalView={isMinimalView} />
      </View>

      {/* when a game is selected */}
      {selectedGame && (
        <GameSetBox
          game={selectedGame}
          setGame={gameServerChanged}
          backToGameList={() => {
            setSelectedGame(null)
          }}
          isMinimalView={isMinimalView}
        />
      )}

      {!selectedGame && (
        <View
          style={[
            {
              width: '100%',
              paddingHorizontal: 15,

              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            },
            isMinimalView && {
              flexDirection: 'column',
              justifyContent: 'flex-start',
            },
          ]}
        >
          {gamesArray.map((game) => {
            return (
              <GameItem
                onClick={() => {
                  selectGame(game)
                }}
                key={game.id}
                logo={game.logo}
                title={game.title}
                time={game.time}
                players={game.players}
              />
            )
          })}
        </View>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  gameRoomWrapper: {
    flexDirection: 'column',
    paddingBottom: 20,
  },
  tabsWrapper: { height: 42, flexDirection: 'row' },
  headWrapper: {
    paddingLeft: 20,
    paddingRight: 20,

    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
