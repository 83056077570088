import { useNurMutation } from '@there/components/sun/use-mutation'
import type { UpdateProfileMessage } from '@there/sun/modules/updateProfile'
import { NodeUpdateInfo } from '@there/sun/utils/node-types'
interface Data {
  updates: NodeUpdateInfo[]
}

export const useUpdateProfile = () => {
  return useNurMutation<Data, UpdateProfileMessage['payload']>({
    method: 'updateProfile',
  })
}
