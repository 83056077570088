import * as React from 'react'
import Svg, { SvgProps, G, Circle, Path, Rect } from 'react-native-svg'

export function DoorIcon(props: SvgProps) {
  return (
    <Svg width={23} height={23} viewBox="0 0 21 21" fill="none" {...props}>
      <Path
        d="M6.125 4.283v14.092h-1.75V2.625h5.25v1.658h-3.5z"
        fill={props.color ? props.color : '#fff'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.906 1.966l7 1.269a.875.875 0 01.719.86v12.81a.875.875 0 01-.719.86l-7 1.268a.875.875 0 01-1.031-.86V2.826a.875.875 0 011.031-.86zm1.521 9.327c.604 0 1.094-.533 1.094-1.19 0-.656-.49-1.188-1.094-1.188-.604 0-1.094.532-1.094 1.189 0 .656.49 1.189 1.094 1.189z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}

export function ClosedDoorIcon(props: SvgProps) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        opacity={0.8}
        d="M5.25 3.671V15.75h-1.5V2.25h4.5v1.421h-3z"
        fill={props.color ? props.color : '#fff'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.634 1.686l6 1.087a.75.75 0 01.616.738v10.978a.75.75 0 01-.616.738l-6 1.087a.75.75 0 01-.884-.738V2.425a.75.75 0 01.884-.738zM8.937 9.68c.518 0 .938-.457.938-1.02s-.42-1.019-.938-1.019c-.517 0-.937.456-.937 1.02 0 .562.42 1.019.938 1.019z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}

export function AddPeopleIcon(props: SvgProps) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <G fill={props.color ? props.color : '#fff'}>
        <Path d="M12 5.333h-1.333a.667.667 0 010-1.333H12V2.667a.667.667 0 011.333 0V4h1.334a.667.667 0 110 1.333h-1.334v1.334a.667.667 0 01-1.333 0V5.333zm-6 2A2.667 2.667 0 116 2a2.667 2.667 0 010 5.333zM0 13.466c.259-3.182 2.841-4.8 5.989-4.8 3.192 0 5.814 1.53 6.01 4.8.007.131 0 .534-.501.534H.485c-.167 0-.499-.36-.485-.534z" />
      </G>
    </Svg>
  )
}

export function PlusIcon(props: SvgProps) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M1 8h7m7 0H8m0 0V1m0 7v7"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export function CrossIcon(props: SvgProps) {
  return (
    <Svg width={10} height={10} viewBox="0 0 10 10" fill="none" {...props}>
      <Path
        d="M9 1L5 5M1 9l4-4m0 0l4 4M5 5L1 1"
        stroke={props.color ? props.color : '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export function SmallCrossIcon(props: SvgProps) {
  return (
    <Svg width={6} height={6} viewBox="0 0 6 6" fill="none" {...props}>
      <Path
        d="M6 0.75L5.25 0L3 2.25L0.75 0L0 0.75L2.25 3L0 5.25L0.75 6L3 3.75L5.25 6L6 5.25L3.75 3L6 0.75Z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}

export function ButtonPlusIcon(props: SvgProps) {
  return (
    <Svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <Path
        d="M1 7h6m6 0H7m0 0V1m0 6v6"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
export function SmileEmojiIcon({ color, ...props }: SvgProps) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <G opacity={0.65} fill={color ? color : '#fff'}>
        <Path opacity={0.3} d="M16.5 9a7.5 7.5 0 10-15 0 7.5 7.5 0 0015 0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.626 10.916a.75.75 0 111.248-.832C6.742 11.386 7.766 12 9 12s2.258-.614 3.126-1.916a.75.75 0 011.248.832C12.242 12.614 10.766 13.5 9 13.5c-1.766 0-3.242-.886-4.374-2.584z"
        />
      </G>
    </Svg>
  )
}

export function RightArrow(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        opacity={0.3}
        d="M5 11a1 1 0 100 2h7a1 1 0 100-2H5z"
        fill={props.color ? props.color : '#fff'}
      />
      <Path
        d="M11.293 17.293a1 1 0 001.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}

export function TopicDefaultIcon(props: SvgProps) {
  return (
    <Svg width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
      <Path
        opacity={0.3}
        d="M5.25 17.5H3.5c0-2.416 1.959-3.5 4.375-3.5h5.25c2.416 0 4.375 1.084 4.375 3.5h-1.75c0-1.45-1.175-1.75-2.625-1.75h-5.25c-1.45 0-2.625.3-2.625 1.75z"
        fill="#fff"
      />
      <Path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.125 7h-1.598a1.75 1.75 0 00-1.75 1.75v1.75a.875.875 0 01-.875.875H5.136a.875.875 0 01-.875-.875V8.75A1.75 1.75 0 002.51 7H.875A2.625 2.625 0 013.5 4.375h14A2.625 2.625 0 0120.125 7z"
        fill="#fff"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.125 8.75v4.375A2.625 2.625 0 0117.5 15.75h-14a2.625 2.625 0 01-2.625-2.625V8.75h1.636v1.75a2.625 2.625 0 002.625 2.625h10.766a2.625 2.625 0 002.625-2.625V8.75h1.598z"
        fill="#fff"
      />
    </Svg>
  )
}

export function ThreeDotHorizontal(props: SvgProps) {
  return (
    <Svg width={18} height={4} viewBox="0 0 18 4" fill="none" {...props}>
      <Circle
        cx={16}
        cy={2}
        r={2}
        fill={props.color ? props.color : '#fff'}
        fillOpacity={0.45}
      />
      <Circle
        cx={9}
        cy={2}
        r={2}
        fill={props.color ? props.color : '#fff'}
        fillOpacity={0.45}
      />
      <Circle
        cx={2}
        cy={2}
        r={2}
        fill={props.color ? props.color : '#fff'}
        fillOpacity={0.45}
      />
    </Svg>
  )
}

export function ScreenShareButtonMini(props: SvgProps) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <Rect x={2} y={3} width={15} height={9} rx={2} fill="#fff" />
      <Rect x={6} y={14} width={7} height={2} rx={1} fill="#fff" />
    </Svg>
  )
}

export function ArrowRight(props: SvgProps) {
  return (
    <Svg width={5} height={8} viewBox="0 0 5 8" {...props}>
      <Path
        d="M1 1l3 3-3 3"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export function LeaveIcon(props: SvgProps) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        d="M6.75 15.75h-3a1.5 1.5 0 01-1.5-1.5V3.75a1.5 1.5 0 011.5-1.5h3M12 12.75L15.75 9 12 5.25M15.75 9h-9"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export function NoSelfView(props: SvgProps) {
  return (
    <Svg width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
      <Path
        d="M10.5 19.25v-3.5 3.5zm-4.375 0H10.5 6.125zm8.75 0H10.5h4.375z"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        d="M2.809 1.691l15.507 13.875"
      />

      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.783 3.517a7.75 7.75 0 1011.52 10.369l-1.114-1.003a6.25 6.25 0 11-9.29-8.362L4.782 3.517zm3.252 2.927a3.375 3.375 0 105.016 4.515l-1.115-1.004A1.875 1.875 0 119.15 7.448L8.036 6.444zm5.83 2.557L9.898 5.429A3.375 3.375 0 0113.867 9zm2.402 2.161a6.25 6.25 0 00-8.772-7.894l-1.167-1.05a7.75 7.75 0 0111.107 9.996l-1.168-1.052z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}
export function Lighting(props: SvgProps) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28 14.954l4.387-6.58a.563.563 0 00-.468-.874h-3.45V3.358a.563.563 0 00-1.03-.312l-4.386 6.58a.562.562 0 00.468.874H8.25v4.142a.563.563 0 001.03.312z"
        fill="#fff"
        opacity={0.37}
      />
    </Svg>
  )
}

export function Image(props: SvgProps) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      stroke={props.color ? props.color : '#fff'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <Rect x={3} y={3} width={18} height={18} rx={2} ry={2} />
      <Circle cx={8.5} cy={8.5} r={1.5} />
      <Path d="M21 15l-5-5L5 21" />
    </Svg>
  )
}
export function DownloadIcon(props: SvgProps) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <Path d="M8 17l4 4 4-4M12 12v9" />
      <Path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29" />
    </Svg>
  )
}
export function PeopleIcon(props: SvgProps) {
  return (
    <Svg width={13} height={13} viewBox="0 0 13 13" fill="none" {...props}>
      <Path
        opacity={0.6}
        d="M9.75 7.583a1.625 1.625 0 1 1 0-3.25 1.625 1.625 0 0 1 0 3.25ZM4.875 5.958a2.167 2.167 0 1 1 0-4.333 2.167 2.167 0 0 1 0 4.333Z"
        fill={props.color ? props.color : '#fff'}
      />
      <Path
        d="M9.534 8.125c1.845.02 3.352.953 3.465 2.925.005.08 0 .325-.294.325h-2.088c0-1.22-.403-2.344-1.083-3.25ZM0 10.941c.21-2.585 2.309-3.9 4.866-3.9 2.593 0 4.724 1.243 4.883 3.9.006.107 0 .434-.407.434H.394c-.136 0-.405-.293-.394-.434Z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}

export function AttachFileIcon(props: SvgProps) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <Circle cx={12} cy={12} r={10} />
      <Path d="M12 8v8M8 12h8" />
    </Svg>
  )
}
export function AttachFileMessage(props: SvgProps) {
  return (
    <Svg
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 24 24"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <Path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
      <Path d="M13 2v7h7" />
    </Svg>
  )
}

export function LeftArrowIcon(props: SvgProps) {
  return (
    <Svg width={7} height={16} viewBox="0 0 7 16" fill="none" {...props}>
      <Path
        d="M6 1L1 8l5 7"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
