import React, { useState } from 'react'
import { ipc } from '@there/desktop/utils/electron-api'
import Sentry from '@there/app/utils/sentry'
import { H1 } from '../shared/Headings'
import { FlexRow } from '../shared/Flex'
import { Space } from '../shared/Space'
import { Body, SmallBody } from '../shared/Body'
import { InlineButton } from '../shared/InlineButton'
import styled from 'styled-components'
import { useAppContext } from '@there/components/shared/AppContext'
import { ActionButton } from '../shared/ActionButton'

type Props = {
  children: React.ReactNode
}

type State = {
  hasError: boolean
  error: Error | undefined
  errorInfo: any
  showError: boolean
}

export class FeedErrorBoundary extends React.Component<
  Props,
  {
    hasError: boolean
    error: Error | undefined
    errorInfo: any
    showError: boolean
  }
> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: undefined,
      errorInfo: undefined,
      showError: false,
    }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({
      hasError: true,
      errorInfo,
      error,
    })

    Sentry.withScope(() => {
      Sentry.setExtra('errorInfo', JSON.stringify(errorInfo))
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <FallbackUI {...this.state} />
    }

    return this.props.children
  }
}

function FallbackUI(props: State) {
  const { currentUser } = useAppContext()
  const [showError, setShowError] = useState(false)
  return (
    <Wrapper>
      <FlexRow justifyContent="center">
        <H1>🥺</H1>
      </FlexRow>

      <Space vertical={16} />
      <div style={{ textAlign: 'center' }}>
        <H1>Something went wrong.</H1>
        <Body level="tertiaryText">
          We already got notified. You can also ping us in chat. (
          <InlineButton
            onClick={() => {
              setShowError(true)
            }}
          >
            See Error
          </InlineButton>
          )
        </Body>
      </div>

      <Space vertical={16} />

      <FlexRow justifyContent={'center'}>
        <ActionButton onClick={() => location.reload()} variant="primary">
          Reload
        </ActionButton>
        <Space horizontal={8} />

        <ActionButton
          onClick={() => {
            ipc?.invoke('open-help-window', currentUser)
          }}
        >
          Open Chat
        </ActionButton>

        <Space horizontal={8} />

        <ActionButton
          onClick={() => {
            localStorage.clear()
            alert('Done!')
          }}
        >
          Clear Cache
        </ActionButton>
      </FlexRow>

      {showError && (
        <SmallBody level="quaternaryText" style={{ userSelect: 'text' }}>
          <code>
            <pre>
              <strong>{props.error?.message}</strong>: {props.error?.stack}
            </pre>
          </code>
        </SmallBody>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 ${(p) => p.theme.spaces.largeSidePadding}px;
  padding-top: 70px;
`
