// For some reason it fails
// import type { Rectangle, IpcRendererEvent } from 'electron'
import { GotSignalData } from '@there/components/main/useGotSignal'
import { SafeDisplay } from '@there/components/shared/CurrentMediaDevicesContext'
import { useLatest } from '@there/components/shared/use-latest'
import { ShortcutActions } from '@there/components/shared/use-shortcut-manager'
import { SubscriptionResult } from '@there/components/sun/utils/types'
import { PermissionsConfig } from '@there/desktop/main/config'
import type { LocationType } from '@there/desktop/main/ipc'
import {
  FeedWindowMode,
  NotificationResponse,
  RoomActionResponse,
  VoicePickedIpcData,
} from '@there/shared/desktop/types'
import { PeerConnectionLog } from '@there/sun/model/logPeerConnection'
import type { PeerStatsRecord } from '@there/sun/model/rtcStats'
import {
  EmojiReceivedData,
  NotificationReceivedData,
  VoiceReceivedData,
} from '@there/tower/types'
import { useEffect } from 'react'

type Rectangle = any
type IpcRendererEvent = any

export const electronApi =
  typeof window !== 'undefined' && 'electronApi' in window
    ? window.electronApi
    : undefined

export const ipc =
  typeof window !== 'undefined' && 'ipc' in window ? window.ipc : undefined

export const isElectron =
  typeof window !== 'undefined' && 'ipc' in window ? true : false

export const isBeta =
  typeof window !== 'undefined' && 'electronMeta' in window
    ? window.electronMeta?.isBeta
    : false

export const electronPlatform =
  typeof window !== 'undefined' && 'electronMeta' in window
    ? window.electronMeta?.platform
    : undefined

export const electronShouldUseDarkColors =
  typeof window !== 'undefined' && 'electronMeta' in window
    ? window.electronMeta?.shouldUseDarkColors
    : undefined

export const electronVersion =
  typeof window !== 'undefined' && 'electronMeta' in window
    ? window.electronMeta?.version
    : undefined

export const electronSupports =
  typeof window !== 'undefined' && 'supports' in window
    ? window.supports
    : undefined

export const electronData =
  typeof window !== 'undefined' && 'electronData' in window
    ? window.electronData
    : undefined

export let platform = electronPlatform

export const currentPlatform =
  typeof navigator === 'undefined'
    ? 'unknown'
    : navigator.appVersion.includes('Win')
    ? 'windows'
    : navigator.appVersion.includes('Mac')
    ? 'mac'
    : navigator.appVersion.includes('Linux')
    ? 'linux'
    : 'unknown'

export const isMacOS = platform === 'darwin'
export const isWinOS = platform === 'win32'
export const isMac = currentPlatform === 'mac'
export const isLinux = currentPlatform === 'linux'
export const isWin = currentPlatform === 'windows'

type IPCListeningChannels =
  | 'overlay:bounds-arrived'
  | 'picker-shortcut-pressed'
  | 'voice:received'
  | 'emoji:received'
  | 'notification:received'
  | 'notification:onAction'
  | 'update-user-name'
  | 'update-user-status'
  | 'voice:picked'
  | 'feed:picker-mode'
  | 'there:update-downloaded'
  // | 'walkie:prepare-voice'
  | 'room:joined'
  | 'room:left'
  | 'room:show'
  | 'room:pinned'
  | 'feed:room-talking'
  | 'feed:mode-changed'
  | 'voice:prepare'
  | 'there:windows-ready'
  | 'messages-state-changed'
  | 'shortcut-pressed'
  | 'rtc:got-signal'
  | 'rtc:broadcast-signal'
  | 'update-download-progress'
  | 'update-state-change'
  | 'screen-picked'
  | 'dialog:leave'
  | 'rtc:user-muted'
  | 'system-mute-changed'
  | 'feed:general-ipc'
  | 'dropdown:click-action'
  | 'notification:clicked'
  | 'rtc-data-channel-data'
  | 'rtc-data-send'
  | 'file-dl-event'
  | 'rtc:mic-alert'
  | 'win:moved'
  | 'get-region'

export interface RtcDataChannelDataOverIpc {
  userId: string
  data: any
}
export type MediaAccessStatus =
  | 'not-determined'
  | 'granted'
  | 'denied'
  | 'restricted'
  | 'unknown'

export function useListenToIpc(
  channel: 'feed:general-ipc',
  listener: (
    event: IpcRendererEvent,
    message:
      | { event: 'nur-send-rtc-stats'; payload: PeerStatsRecord[] }
      | {
          event: 'nur-log-peer-connection'
          payload: {
            logs: PeerConnectionLog[]
          }
        }
      | {
          event: 'change-space'
          payload: {
            spaceId: string
          }
        },
  ) => void,
): void
export function useListenToIpc(
  channel: 'notification:clicked',
  listener: (event: IpcRendererEvent, id: string) => void,
): void
export function useListenToIpc(
  channel: 'rtc-data-channel-data',
  listener: (event: IpcRendererEvent, data: RtcDataChannelDataOverIpc) => void,
): void
export function useListenToIpc(
  channel: 'rtc-data-send',
  listener: (event: IpcRendererEvent, data: RtcDataChannelDataOverIpc) => void,
): void
export function useListenToIpc(
  channel: 'system-mute-changed',
  listener: (event: IpcRendererEvent, muted: boolean) => void,
): void
export function useListenToIpc(
  channel: 'dialog:leave',
  listener: (event: IpcRendererEvent, data: undefined) => void,
): void
export function useListenToIpc(
  channel: 'update-download-progress',
  listener: (event: IpcRendererEvent, data: { percent: number }) => void,
): void
export function useListenToIpc(
  channel: 'update-state-change',
  listener: (
    event: IpcRendererEvent,
    data: { state: 'downloading' | 'downloaded' | 'checking' | 'none' },
  ) => void,
): void
export function useListenToIpc(
  channel: 'overlay:bounds-arrived',
  listener: (
    event: IpcRendererEvent,
    data: { screenId: string; bounds: Rectangle },
  ) => void,
): void
export function useListenToIpc(
  channel: 'overlay:bounds-arrived',
  listener: (
    event: IpcRendererEvent,
    data: { screenId: string; bounds: Rectangle },
  ) => void,
): void
export function useListenToIpc(
  channel: 'picker-shortcut-pressed',
  listener: (
    event: IpcRendererEvent,
    data?: { isDevToolsOpen?: boolean; isFeedOpen?: boolean },
  ) => void,
): void
export function useListenToIpc(
  channel: 'voice:received',
  listener: (event: IpcRendererEvent, data: VoiceReceivedData) => void,
): void
export function useListenToIpc(
  channel: 'voice:picked',
  listener: (event: IpcRendererEvent, data: VoicePickedIpcData) => void,
): void
export function useListenToIpc(
  channel: 'room:joined',
  listener: (event: IpcRendererEvent, data: RoomActionResponse) => void,
): void
export function useListenToIpc(
  channel: 'room:left',
  listener: (event: IpcRendererEvent, data: { roomId: string }) => void,
): void
export function useListenToIpc(
  channel: 'room:show',
  listener: (event: IpcRendererEvent, data?: null) => void,
): void
export function useListenToIpc(
  channel: 'room:pinned',
  listener: (event: IpcRendererEvent, data?: null) => void,
): void
export function useListenToIpc(
  channel: 'voice:prepare',
  listener: (event: IpcRendererEvent, data: undefined) => void,
): void
export function useListenToIpc(
  channel: 'feed:picker-mode',
  listener: (
    event: IpcRendererEvent,
    data?: { selectedUserId?: string },
  ) => void,
): void
export function useListenToIpc(
  channel: 'feed:mode-changed',
  listener: (event: IpcRendererEvent, data: { mode: FeedWindowMode }) => void,
): void
export function useListenToIpc(
  channel: 'feed:room-talking',
  listener: (event: IpcRendererEvent, data?: null) => void,
): void

export function useListenToIpc(
  channel: 'emoji:received',
  listener: (event: IpcRendererEvent, data: EmojiReceivedData) => void,
): void
export function useListenToIpc(
  channel: 'notification:received',
  listener: (event: IpcRendererEvent, data: NotificationReceivedData) => void,
): void
export function useListenToIpc(
  channel: 'notification:onAction',
  listener: (
    event: IpcRendererEvent,
    // we use senderId as notification Id
    data: { action: NotificationResponse; notificationId: string },
  ) => void,
): void

export function useListenToIpc(
  channel: 'update-user-name',
  listener: ((event: IpcRendererEvent, data: null) => void) | undefined,
): void
export function useListenToIpc(
  channel: 'update-user-status',
  listener: ((event: IpcRendererEvent, data: null) => void) | undefined,
): void
export function useListenToIpc(
  channel: 'there:update-downloaded',
  listener: ((event: IpcRendererEvent, data: null) => void) | undefined,
): void
export function useListenToIpc(
  channel: 'shortcut-pressed',
  listener:
    | ((
        event: IpcRendererEvent,
        data: { action: ShortcutActions; shortcutString: string },
      ) => void)
    | undefined,
): void
export function useListenToIpc(
  channel: 'there:windows-ready',
  listener: (
    event: IpcRendererEvent,
    data: { mini?: boolean; room?: boolean } | undefined,
  ) => void,
): void
export function useListenToIpc(
  channel: 'rtc:got-signal',
  listener: (
    event: IpcRendererEvent,
    signalData: SubscriptionResult<GotSignalData> | undefined,
  ) => void,
): void
export function useListenToIpc(
  channel: 'rtc:broadcast-signal',
  listener: (
    event: IpcRendererEvent,
    signalData: { dialogId: string; receiverId: string; signal: string },
  ) => void,
): void

export function useListenToIpc(
  channel: 'screen-picked',
  listener: (
    event: IpcRendererEvent,
    data: { display: SafeDisplay; source: DisplaySource } | undefined,
  ) => void,
): void

export function useListenToIpc(
  channel: 'rtc:user-muted',
  listener: (
    event: IpcRendererEvent,
    data: { userId: string } | undefined,
  ) => void,
): void

export function useListenToIpc(
  channel: 'dropdown:click-action',
  listener: (
    event: IpcRendererEvent,
    data: { type: string; payload: any; key: string } | undefined,
  ) => void,
): void

export function useListenToIpc(
  channel: 'file-dl-event',
  listener: (
    event: IpcRendererEvent,
    data: {
      documentId: string
      type: 'started' | 'completed' | 'percent'
      progress: number
      downloadedBytes: number
    },
  ) => void,
): void
export function useListenToIpc(
  channel: 'rtc:mic-alert',
  listener: (event: IpcRendererEvent, data: undefined) => void,
): void
export function useListenToIpc(
  channel: 'win:moved',
  listener: (event: IpcRendererEvent, data: { x: number; y: number }) => void,
): void
export function useListenToIpc(
  channel: IPCListeningChannels,
  listener: ((event: IpcRendererEvent, data: any) => void) | undefined,
): void {
  let listenerRef = useLatest(listener)
  useEffect(() => {
    function listener(event: IpcRendererEvent, data: any) {
      listenerRef.current?.(event, data)
    }
    ipc?.addListener(channel, listener)
    return () => {
      ipc?.removeListener(channel, listener)
    }
  }, [channel, listenerRef])
}

type IpcChannels =
  | 'create-cursor-window'
  | 'participant-cursor-moved'
  | 'participant-cursor-remove'
  | 'participant-cursor-clicked'
  | 'enable-do-not-disturb'
  | 'disable-do-not-disturb'
  | ''

export type DisplaySource = import('electron').DesktopCapturerSource
export type Display = import('electron').Display

export type IdleStateValues = 'active' | 'idle' | 'locked' | 'unknown'
export type IdleState = {
  idleState: IdleStateValues
  idleSeconds: number
  appVersion: string
}

type IpcType = import('electron').IpcRenderer & {
  invoke(
    channel: 'open-add-menu-request',
    data: { bounds: Rectangle; joinUrl: string },
  ): Promise<any>
  invoke(channel: 'has-resource-access'): Promise<PermissionsConfig>
  invoke(channel: 'user-location-requested'): Promise<LocationType>
  invoke(channel: 'idle-state-requested'): Promise<IdleState>
  invoke(channel: 'voice:received', data: VoiceReceivedData): Promise<void>
  invoke(channel: 'emoji:received', data: EmojiReceivedData): Promise<void>
  invoke(channel: 'get-region'): Promise<'global' | 'iran'>
  invoke(
    channel: 'notification:received',
    data: NotificationReceivedData,
  ): Promise<void>
  invoke(
    channel: 'notification:onAction',
    data: { action: NotificationResponse; notificationId: string },
  ): Promise<void>
  invoke(channel: 'system:is-muted'): Promise<boolean | undefined>
  invoke(
    channel: 'system:idle-state',
  ): Promise<'active' | 'idle' | 'locked' | undefined>
  invoke(channel: 'update-user-name'): Promise<void>
  invoke(channel: 'update-user-status'): Promise<void>
  invoke(
    channel: 'overlay:bounds-arrived',
    data: {
      screenId: string
      bounds: { width: number; height: number; x: number; y: number }
    },
  ): Promise<any>
  invoke(
    channel: 'call:switch-mode',
    data: {
      callId: string
      mode: 'observer' | 'host'
    },
  ): Promise<any>
  invoke(
    channel: 'get-user-info',
    data: undefined,
  ): Promise<{
    token: string | null
    user: { id: string; name: string | null; email: string | null } | null
  }>
  invoke(
    channel: 'get-sources',
    data: undefined,
  ): Promise<{
    sources: DisplaySource[]
  }>
  invoke(channel: IpcChannels, ...args: any[]): Promise<any>
  send(channel: IpcChannels, ...args: any[]): void
}

// Add typings
declare global {
  interface Window {
    ipc?: IpcType

    supports?: {
      cameraV1?: boolean
      sleepBlocker?: boolean
      muteUser?: boolean
      opusRed?: boolean
      alwaysOnTop?: boolean
      nativeTitleBar?: boolean
      nativeNotifications?: boolean
      dropdownSeparator?: boolean
      rtcAvatarsCountIpc?: boolean
      fileDownload?: boolean
      capsLockShortcut?: boolean
      disableDock?: boolean
      electron18?: boolean
    }

    electronData?: {
      token: string | null
    }

    electronMeta?: {
      platform?: 'darwin' | 'win32' | 'linux'
      version?: string
      isBeta?: boolean
      shouldUseDarkColors?: boolean
    }
    electronApi?: {
      openExternalUrl(url: string): void
      quitApp(): void
      login(
        token: string,
        user: { id: string; email: string; name?: string | null },
      ): void
      getDesktopCapturerSources(): Promise<DisplaySource[]>
      getAllDisplays(): import('electron').Display[]
      openMainMenu(bounds: import('electron').Rectangle): void
      logOut: {
        addListener(callback: () => void): void
        removeListener(callback: () => void): void
      }

      participantCursorClicked(data: {
        /**@deprecated */
        x?: number
        /**@deprecated */
        y?: number
        xPercent: number
        yPercent: number
        doubleClick: boolean
        button: 'right' | 'left' | 'middle'
        modifiers?: string | string[]
        userId: string
        screenId?: string
        displayScaleFactor?: number
      }): void

      participantCursorScrolled(data: {
        /**@deprecated */
        x?: number
        /**@deprecated */
        y?: number
        xPercent: number
        yPercent: number
        deltaX: number
        deltaY: number
        userId: string
        screenId?: string
      }): void

      windowOpening: {
        addListener(callback: () => void): void
        removeListener(callback: () => void): void
      }
      windowClosing: {
        addListener(
          callback: (
            _: any,
            data: { window: 'feed' | 'room' | 'mini' },
          ) => void,
        ): void
        removeListener(
          callback: (
            _: any,
            data: { window: 'feed' | 'room' | 'mini' },
          ) => void,
        ): void
      }

      windowUnfocused: {
        addListener(callback: (_: any) => void): void
        removeListener(callback: (_: any) => void): void
      }

      rtcWindowResized: {
        addListener(callback: (_: any) => void): void
        removeListener(callback: (_: any) => void): void
      }

      windowFocused: {
        addListener(callback: (_: any) => void): void
        removeListener(callback: (_: any) => void): void
      }

      fullScreenChange: {
        addListener(callback: (event: any, isFullScreen: boolean) => void): void
        removeListener(
          callback: (event: any, isFullScreen: boolean) => void,
        ): void
      }
      window: {
        isFullScreen(): boolean
        setSize(width: number, height: number, animate?: boolean): void
        hide(): void
        close(): void
        center(): void
        minimize(): void
        toggleMaximize(): void
        toggleFullScreen(): void
      }
      incomingCallMode: {
        activate(): void
        deactivate(): void
      }
      fullName: () => Promise<string | undefined>
      updateTrayIconForCall: (data: {
        dataUrl: string | undefined
        startedAt: number
      }) => void
      removeTrayIconForCall: () => void
    }
  }
}
