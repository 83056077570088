import { useNurMutation } from '@there/components/sun/use-mutation'
import type { SeenMessageMessage } from '@there/sun/modules/seenMessage'
interface Data {
  done: boolean
}

type RemoteVariables = SeenMessageMessage['payload']

export interface LocalSeenMessageMessageVariables extends RemoteVariables {
  __extra: {
    spaceId: string
    type: 'userChat' | 'topicChat'
  }
}

export const useSeenMessage = () => {
  return useNurMutation<Data, LocalSeenMessageMessageVariables>({
    method: 'seenMessage',
  })
}
