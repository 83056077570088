import { StatusInfo } from '@there/sun/utils/node-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { View } from 'react-native'
import { useAppContext } from '../shared/AppContext'
import { StatusItem } from './FeedList'
import { useStatusActions } from './useStatusActions'
import { useStatusesContext } from './useStatusesContext'

type Props = {
  status: StatusInfo | undefined
  scrollToBottom: () => void
}

export const StatusChat = ({ status, scrollToBottom }: Props) => {
  let { maxReadAt, getStatusReplies } = useStatusesContext()
  let statuses = useMemo(() => (status ? getStatusReplies(status?.id) : []), [
    getStatusReplies,
    status,
  ])
  let { currentUserId } = useAppContext()

  let {
    addReaction,
    deleteReaction,
    openContextMenu,
    viewStatusPhoto,
  } = useStatusActions(statuses)

  let statusesLength = statuses.length
  let lastStatusId = statuses[statuses.length - 1]?.id
  // let statusesLengthRef = useRef<number | undefined>(undefined)
  let lastStatusIdRef = useRef<string | undefined>(undefined)
  useEffect(() => {
    if (!statuses || !status) return
    if (!lastStatusIdRef || !lastStatusIdRef.current) {
      lastStatusIdRef.current = lastStatusId
      scrollToBottom()
      return
    }
    if (lastStatusIdRef.current === lastStatusId) {
      return
    }
    if (statuses[statusesLength - 1]?.userId === currentUserId) {
      lastStatusIdRef.current = lastStatusId
      scrollToBottom()
    }
  }, [
    currentUserId,
    lastStatusId,
    scrollToBottom,
    status,
    statuses,
    statusesLength,
  ])

  if (!status) return null
  return (
    <View>
      <StatusItem
        onContextMenu={() => {
          openContextMenu(status)
        }}
        key={status.id + 'alone'}
        status={status}
        read={status.createdAt < maxReadAt}
        addReaction={addReaction}
        deleteReaction={deleteReaction}
        viewPhoto={viewStatusPhoto}
      />
      {statuses.map((statusItem) => {
        return (
          <StatusItem
            onContextMenu={() => {
              openContextMenu(statusItem)
            }}
            key={statusItem.id}
            status={statusItem}
            read={statusItem.createdAt < maxReadAt}
            addReaction={addReaction}
            deleteReaction={deleteReaction}
            viewPhoto={viewStatusPhoto}
          />
        )
      })}
    </View>
  )
}
