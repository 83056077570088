import React, { forwardRef, LegacyRef } from 'react'

export const WebStyled = React.forwardRef(
  (
    {
      style,
      children,
      ...props
    }: React.HTMLProps<HTMLDivElement> & {
      style?: React.CSSProperties & { WebkitAppRegion?: 'drag' | 'no-drag' }
      children?: React.ReactNode
    },
    ref,
  ) => {
    return (
      <div
        style={style}
        {...props}
        ref={ref as LegacyRef<HTMLDivElement> | undefined}
      >
        {children}
      </div>
    )
  },
)

export const WebkitAppRegion = (props: {
  children?: React.HTMLProps<HTMLDivElement>
  mode: 'drag' | 'no-drag'
  style?: React.CSSProperties
}) => {
  return (
    //@ts-ignore
    <div style={{ WebkitAppRegion: props.mode, ...(props.style || null) }}>
      {props.children}
    </div>
  )
}
