import { ipc } from '@there/desktop/utils/electron-api'
import { NewMessageInfo } from '@there/sun/utils/node-types'
import { useEffect, useRef, useState } from 'react'
import { Pressable, Image as RnImage, ActivityIndicator } from 'react-native'
import useCanvasBlur from '../lib/useCanvasBlur'
import { calculateImageDimensions } from '../v2/ChatUploadButton'
import { getMessageMediaFullPath, getMessageMediaThumbDataUri } from './helpers'
import { useSpring, a } from '@react-spring/native'
import useIsMounted from '@there/components/hooks/useIsMounted'

export const MAX_MEDIA_WIDTH = 220
type props = {
  message: NewMessageInfo
  onPress: () => void
}

export const PhotoMessage = ({ message, onPress }: props) => {
  const messagePhoto = message.document || message

  const thumbnailUrl = getMessageMediaThumbDataUri(message)
  const thumbRef = useCanvasBlur(thumbnailUrl, !Boolean(thumbnailUrl), true)
  let isMounted = useIsMounted()

  const imageDims =
    messagePhoto.mediaW && messagePhoto.mediaH
      ? calculateImageDimensions(
          {
            w: messagePhoto.mediaW,
            h: messagePhoto.mediaH,
          },
          MAX_MEDIA_WIDTH,
        )
      : { w: MAX_MEDIA_WIDTH, h: MAX_MEDIA_WIDTH }

  const hasLoaded = useRef(false)
  const [showMainSize, setShwMainSize] = useState(
    // Show immediately, unless after 40ms it didn't load, start the loading
    true,
  )

  useEffect(() => {
    let t = setTimeout(() => {
      if (!hasLoaded.current) {
        setShwMainSize(false)
      }
    }, 40)

    return () => {
      t && clearTimeout(t)
    }
  }, [])

  const thumbnailStyles = useSpring({
    opacity: showMainSize ? 0 : 1,

    config: {
      duration: 100,
    },
  })

  useEffect(() => {
    if (!thumbnailUrl) return

    let img = new Image()
    img.src = thumbnailUrl
  }, [thumbnailUrl])
  return (
    <Pressable
      style={[
        {
          // We limit it by the parent bubble
          // maxWidth: '100%',
          width: '100%',
          height: imageDims.h,
          flex: 1,
          overflow: 'hidden',
          marginBottom: 0,
          position: 'relative',
          borderColor: 'white',
        },
      ]}
      onPress={onPress}
    >
      <RnImage
        onLoad={() => {
          if (!isMounted.current) return
          hasLoaded.current = true
          setShwMainSize(true)
        }}
        style={{
          minWidth: '100%',
          width: imageDims.w,
          height: imageDims.h,
          opacity: showMainSize ? 1 : 0,
          // zIndex: 1,
        }}
        source={{
          uri: getMessageMediaFullPath(message),
        }}
      />
      <a.View
        style={[
          thumbnailStyles,
          {
            position: 'absolute',
            top: 0,
            left: 0,
          },
        ]}
      >
        <canvas
          ref={thumbRef}
          className="thumbnail"
          style={{
            minWidth: '100%',
            width: imageDims.w,
            height: imageDims.h,
          }}
        />
        <ActivityIndicator
          size={28}
          color="rgba(255,255,255,0.4)"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: [{ translateX: -14 }, { translateY: -14 }],
          }}
        />
      </a.View>
    </Pressable>
  )
}
