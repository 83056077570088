import { TopicWithDialogWithAvatarInfo } from '../../urql/fragments/topicInfo'
import {
  DialogInfo,
  FullSpaceInfo,
  AvatarInfo,
  DialogWithAvatarsInfo,
} from '@there/sun/utils/node-types'

export interface ArrangeOutput {
  dialogs: DialogWithAvatarsInfo[]
  avatarsByDialogId: Record<string, AvatarInfo[] | undefined>
  dialogsByTopicId: Record<string, DialogInfo[] | undefined>
  topicsById: Record<string, TopicWithDialogWithAvatarInfo>
  dialogsById: Record<string, DialogWithAvatarsInfo>
  avatarsById: Record<string, AvatarInfo>
  avatarIdByUserId: Record<string, string>
  currentDialog: DialogInfo | undefined
  lobbyDialog: DialogInfo | undefined
}

export const emptyArrangeOutput: ArrangeOutput = {
  dialogs: [],
  avatarsByDialogId: {},
  dialogsByTopicId: {},
  topicsById: {},
  dialogsById: {},
  avatarsById: {},
  avatarIdByUserId: {},
  currentDialog: undefined,
  lobbyDialog: undefined,
}

interface ArrangeTopicsExtra {
  currentAvatarId?: string | null
}

export const arrangeNodes = (
  data: FullSpaceInfo,
  extra: ArrangeTopicsExtra,
): ArrangeOutput => {
  let topics: TopicWithDialogWithAvatarInfo[] = []
  let dialogs: DialogWithAvatarsInfo[] = []

  let topicsById: Record<string, TopicWithDialogWithAvatarInfo> = {}
  let dialogsById: Record<string, DialogWithAvatarsInfo> = {}
  let avatarsById: Record<string, AvatarInfo> = {}
  let avatarIdByUserId: Record<string, string> = {}
  let avatarsByDialogId: Record<string, AvatarInfo[] | undefined> = {}
  let dialogsByTopicId: Record<string, DialogInfo[] | undefined> = {}
  let currentDialog: DialogInfo | undefined = undefined
  let lobbyDialog: DialogInfo | undefined = undefined

  for (let rawDialog of data.dialogs || []) {
    let dialog = { ...rawDialog, avatars: [] }

    dialogsById[dialog.id] = dialog

    if (dialog.topicId) {
      let dialogsArray = dialogsByTopicId[dialog.topicId]
      if (!dialogsArray) {
        dialogsByTopicId[dialog.topicId] = [dialog]
      } else {
        dialogsArray.push(dialog)
      }
    }

    if (dialog.specialType === 'lobby') {
      lobbyDialog = dialog
    }
  }

  /**
   * Adds avatar to dialogsById to be used for getDialog
   */
  function pushAvatarToDialog(avatar: AvatarInfo) {
    let dialogId = avatar.dialogId
    if (!dialogId || !dialogsById[dialogId]) {
      return
    }
    dialogsById[dialogId].avatars.push(avatar)
  }

  for (let avatar of data.avatars || []) {
    avatarsById[avatar.id] = avatar
    if (avatar.userId) {
      avatarIdByUserId[avatar.userId] = avatar.id
    }

    if (avatar.dialogId) {
      let avatarsArray = avatarsByDialogId[avatar.dialogId]

      // Fill out dialogs by id
      pushAvatarToDialog(avatar)

      if (!avatarsArray) {
        avatarsByDialogId[avatar.dialogId] = [avatar]
      } else {
        avatarsArray.push(avatar)
      }
    }

    // Current dialog
    if (avatar.id === extra.currentAvatarId && avatar.dialogId) {
      currentDialog = dialogsById[avatar.dialogId]
    }
  }

  for (let rawTopic of data.topics || []) {
    // @ts-ignore
    let topic: TopicWithDialogWithAvatarInfo = { ...rawTopic, dialogs: [] }

    for (let rawDialog of dialogsByTopicId[rawTopic.id] || []) {
      let avatars = avatarsByDialogId[rawDialog.id] || []
      let dialog = { ...rawDialog, avatars }
      // Add avatars to dialog
      /** @ts-ignore */
      topic.dialogs.push(dialog)
    }

    // Now add the dialogs we prepared above to the main topics array
    topics.push(topic)
    topicsById[topic.id] = topic
  }

  // todo: make more efficient
  dialogs = Object.values(dialogsById)

  return {
    dialogs,
    avatarsByDialogId,
    dialogsByTopicId,
    dialogsById,
    avatarsById,
    topicsById,
    avatarIdByUserId,
    currentDialog,
    lobbyDialog,
  }
}
