import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
import { useModals } from '../feed/ModalsContext'
import { GoodButton } from '../shared/GoodButton'
import { Tooltip } from '../shared/Tooltip'
import { logEvent } from '../shared/use-analytics'

export const NewGroupButton = () => {
  let [, modalsDispatch] = useModals()
  return (
    <Tooltip label={<>Create new group</>} placement="bottomLeft">
      <GoodButton
        variant="transparent"
        icon={<NewGroupIcon color="#757D86" width={17} height={17} />}
        onPress={() => {
          logEvent('User Clicked New Group Button')
          modalsDispatch({ type: 'modal opened', modalName: 'newGroup' })
        }}
      />
    </Tooltip>
  )
}

const NewGroupIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 15.63V12a3 3 0 0 0-3-3H6.16V5.53C6.16 4.13 7.3 3 8.7 3h11.79C21.88 3 23 4.13 23 5.53v7.58l.02 4.68a.5.5 0 0 1-.85.35l-2.51-2.5H16Z"
      fill={props.color ? props.color : '#000'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99 18v-5a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H4.1l-1.24 1.2a.5.5 0 0 1-.85-.37v-2.5C2 18.21 2 18.1 2 18Zm4.51-4a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5Zm3 2a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2Z"
      fill={props.color ? props.color : '#000'}
    />
  </Svg>
)
