import { ProfilePhoto } from '@there/components/feed/ProfilePhoto'
import { useTheme } from '@there/components/feed/ThemeContext'
import { CrossIcon } from '@there/components/main/mainIcons'
import { SenderName } from '@there/components/message/SenderName'
import { Pressable } from '@there/components/shared/Pressable'
import { Space } from '@there/components/shared/Space'
import { useNurNode } from '@there/components/sun/use-node'
import {
  DocumentInfo,
  ReactionInfo,
  StatusInfo,
  UserInfo,
} from '@there/sun/utils/node-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native'
import { Gif } from '../emoji-picker/GifPickerContainer'
import { useAddStatusReaction } from '../main/useAddStatusReaction'
import { useDeleteStatusReaction } from '../main/useDeleteStatusReaction'
import { getFileFullPath, getMessageMediaFullPath } from '../message/helpers'
import { useAppContext } from '../shared/AppContext'
import { usePickerContext } from '../shared/PickerContext'
import { useHover } from '../shared/use-hover'
import { calculateImageDimensions } from '../v2/ChatUploadButton'
import { useTransition, a } from '@react-spring/native'
import { useModals } from '../feed/ModalsContext'
import { Rectangle } from '@there/shared/desktop/types'
import { PhotoStatus } from '../feedBox/PhotoStatus'
import { Reactions } from '../feedBox/Reactions'
import { ReactionBox } from '../feedBox/ReactionBox'
import { SpaceData } from '../feed/useMemberships'
import { StatusInfoWithSpaceIds } from './useStatuses'
import Linkify from 'react-linkify'
import { electronApi, isElectron } from '@there/desktop/utils/electron-api'
import { useNativeDropdown } from '../dropdown/useNativeDropdown'
import { WebStyled } from '../shared/WebStyled'
import { copyToClipboard } from 'copy-lite'
import { useNurMutation } from '../sun/use-mutation'
import {
  DeleteStatusMessage,
  DeleteStatusResult,
} from '@there/sun/modules/deleteStatus'
import { getRelativeDate } from '../main/get-last-seen'
import { Tooltip } from '../shared/Tooltip'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { replyToStatusIdAtom, statusFeedStateAtom } from '../atoms/statusAtom'
import { useStatusActions } from './useStatusActions'
import { ReplyIcon } from './ReplyIcon'
import { useMainWindowContext } from '../shared/use-main-window'
import { logEvent } from '../shared/use-analytics'
import { useUnread } from '@there/components/shared/UnreadContext'

type Props = {
  statuses: StatusInfo[] | null | undefined
  getStatusSpace: (status: StatusInfoWithSpaceIds) => SpaceData[]
  maxReadAt: number
  statusToReplies: Record<string, string[]>
}

const MAX_MEDIA_WIDTH = 180

export const FeedList = ({ statuses, maxReadAt, statusToReplies }: Props) => {
  const {
    addReaction,
    deleteReaction,
    openContextMenu,
    viewStatusPhoto,
  } = useStatusActions(statuses)
  let renderedTopicRepliesRef = useRef<Record<string, number>>({})
  let { readStatus } = useUnread()
  let { activeSpaceId } = useAppContext()

  useEffect(() => {
    if (!activeSpaceId) return
    readStatus({ spaceId: activeSpaceId })
  }, [activeSpaceId, readStatus])

  return (
    <>
      <View
        style={{
          width: '100%',
        }}
      >
        {/* Header */}

        <ScrollView>
          {statuses?.map((status, i) => {
            let repliesNum = 0
            // if (i === 0) {
            //   renderedTopicRepliesRef.current = {}
            // }
            // if (status.topic) {
            //   let renderedReplies = renderedTopicRepliesRef.current[
            //     status.topic.id
            //   ]
            //     ? renderedTopicRepliesRef.current[status.topic.id] + 1
            //     : 1
            //   renderedTopicRepliesRef.current[status.topic.id] = renderedReplies
            //   if (
            //     renderedReplies === topicsToStatusIds[status.topic.id].length
            //   ) {
            //     repliesNum = topicsToStatusIds[status.topic.id].length - 1
            //   }
            // }

            if (status.replyToStatusId) return null
            // let statusSpacesData = getStatusSpace(status)
            return (
              <StatusItem
                onContextMenu={() => {
                  openContextMenu(status)
                }}
                key={status.id}
                status={status}
                read={status.createdAt < maxReadAt}
                addReaction={addReaction}
                deleteReaction={deleteReaction}
                viewPhoto={viewStatusPhoto}
                repliesNum={statusToReplies[status.id]?.length || 0}
              />
            )
          })}
        </ScrollView>
      </View>
    </>
  )
}

type ItemProps = {
  status: StatusInfo
  viewPhoto: (status: StatusInfo, position?: Rectangle | undefined) => void
  // statusSpacesData: SpaceData[]
  read: boolean
  addReaction: ({
    emoji,
    statusId,
  }: {
    emoji: string
    statusId: string
  }) => void
  deleteReaction: ({
    emoji,
    statusId,
  }: {
    emoji: string
    statusId: string
  }) => void
  onContextMenu: () => void
  repliesNum?: number
}

export const StatusItem = (props: ItemProps) => {
  let {
    status,
    addReaction,
    deleteReaction,
    viewPhoto,
    // statusSpacesData,
    read,
    onContextMenu,
    repliesNum,
  } = props
  let { currentUserId } = useAppContext()
  let viewState = useAtomValue(statusFeedStateAtom)

  let {
    open,
    isOpen: isPickerOpen,
    selectedEmoji,
    clearEmoji,
    close: closePicker,
  } = usePickerContext()
  let [pickerOpenedHere, setPickerOpenedHere] = useState(false)
  let wrapperRef = useRef<null | View>(null)
  let openPicker = useCallback(() => {
    open({
      key: 'statusReaction',
      ref: wrapperRef,
      activeModes: ['emoji'],
      initialMode: 'emoji',
    })
    setPickerOpenedHere(true)
  }, [open])

  useEffect(() => {
    if (isPickerOpen) return
    setPickerOpenedHere(false)
  }, [isPickerOpen])

  let theme = useTheme()
  let [user] = useNurNode<UserInfo>({ id: props.status.userId })

  let reactionsByEmoji = useMemo(() => {
    let reactionObject: Record<string, string[]> = {}
    for (let reaction of status.reactions || []) {
      let userIds = reactionObject[reaction.emoji] || []
      reactionObject[reaction.emoji] = [...userIds, reaction.userId]
    }
    return reactionObject
  }, [status.reactions])

  let toggleReaction = useCallback(
    (emoji: string) => {
      let userIds = reactionsByEmoji[emoji] || []
      let weReactBefore = currentUserId && userIds.includes(currentUserId)
      if (weReactBefore) {
        deleteReaction({
          emoji,
          statusId: status.id,
        })
      } else {
        addReaction({
          emoji,
          statusId: status.id,
        })
      }
    },
    [addReaction, currentUserId, deleteReaction, reactionsByEmoji, status.id],
  )

  let selectedReactionPickerEmoji = selectedEmoji?.statusReaction
  useEffect(() => {
    if (!pickerOpenedHere) return
    if (!selectedReactionPickerEmoji) return
    let emojiUnicode = selectedReactionPickerEmoji.image
      .split('-')
      .map((u) => String.fromCodePoint(parseInt(u, 16)))
      .join('')
    toggleReaction(emojiUnicode)
    clearEmoji('statusReaction')
    closePicker()
  }, [
    clearEmoji,
    closePicker,
    pickerOpenedHere,
    selectedReactionPickerEmoji,
    toggleReaction,
  ])

  let [hovered, hoverListener] = useHover()
  let shouldRenderReactionBox = hovered || (isPickerOpen && pickerOpenedHere)

  let transitions = useTransition(shouldRenderReactionBox, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    config: {
      mass: 0.5,
      tension: 400,
      friction: 18,
    },
  })

  let setReplyToStatusId = useUpdateAtom(replyToStatusIdAtom)
  let replyToStatusId = useAtomValue(replyToStatusIdAtom)
  let setViewState = useUpdateAtom(statusFeedStateAtom)

  return (
    <WebStyled
      onContextMenu={onContextMenu}
      style={{
        width: '100%',
      }}
    >
      <Pressable
        onPress={() => {
          if (status.replyToStatusId) return
          setViewState('status')
          setReplyToStatusId(status.id)
          logEvent('User Opens a Status')
        }}
        style={({ hovered }) => [
          {
            width: '100%',
            paddingVertical: 10,
            paddingTop: repliesNum && repliesNum > 0 ? 5 : 10,
            paddingHorizontal: theme.spaces.sidePaddingNarrow,
            borderBottomColor: theme.colors.separatorLine,
            borderBottomWidth: 1,
            backgroundColor: read ? `transparent` : `rgba(255,255,255,0.04)`,
            flexDirection: 'column',
            overflow: 'hidden',

            //@ts-ignore
            cursor: 'default',
          },
          hovered &&
            !status.replyToStatusId &&
            viewState === 'feed' && {
              backgroundColor: 'rgba(255, 255, 255, 0.01)',
            },
          viewState === 'status' &&
            !status.replyToStatusId && {
              backgroundColor: 'rgba(255, 255, 255, 0.03)',
            },
        ]}
        {...hoverListener}
      >
        {repliesNum && repliesNum > 0 ? (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 5,
            }}
          >
            <View
              style={{
                width: 40,
                paddingRight: 8,
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <ReplyIcon color="#72787F" />
            </View>
            <Text
              style={{
                fontSize: theme.fontSizes.small,
                color: theme.colors.quaternaryText,
                fontWeight: 'bold',
              }}
            >
              received {repliesNum > 1 ? `${repliesNum} replies` : 'a reply'}
            </Text>
          </View>
        ) : null}
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ width: 40 }}>
            {user && <ProfilePhoto user={user} size={32} />}
          </View>
          <View style={{ flexGrow: 1, flexShrink: 1 }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 22,
              }}
            >
              <SenderName userId={status.userId} normalWeight={true} />
              {/* <Text
              style={{
                fontSize: theme.fontSizes.normal,
                color: theme.colors.quaternaryText,
                fontWeight: 'bold',
                marginBottom: 2,
                marginLeft: 2,
              }}
            >
              {statusSpacesData.map((spaceData) => {
                return `@${spaceData.name} `
              })}
            </Text> */}
              <Tooltip
                label={
                  new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'long',
                  }).format(status.createdAt) +
                  ' at ' +
                  new Intl.DateTimeFormat('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                  }).format(status.createdAt)
                }
                placement="bottomCenter"
              >
                <Text
                  style={{
                    color: theme.colors.quinaryText,
                    fontSize: theme.fontSizes.small,
                    lineHeight: 11,
                    height: 11,
                    marginLeft: 5,
                  }}
                >
                  {getRelativeDate(status.createdAt)}
                  {/* {new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: '2-digit',
              }).format(status.createdAt)} */}
                </Text>
              </Tooltip>
            </View>

            <View style={{ marginTop: 2 }}>
              {status.text ? (
                <Text
                  style={[
                    {
                      color: theme.colors.primaryText,
                      fontSize: theme.fontSizes.normal,
                    },
                    replyToStatusId === status.id &&
                      viewState === 'status' && {
                        fontSize: theme.fontSizes.large,
                      },
                  ]}
                  selectable={viewState === 'status'}
                >
                  <Linkify componentDecorator={linkDecorator}>
                    {status.text}
                  </Linkify>
                </Text>
              ) : null}
              {status.document && (
                <>
                  {status.document.type === 'Gif' && status.document.remoteUrl && (
                    <Gif
                      marginTop={5}
                      gif={{
                        dims: [
                          status.document.mediaW || 0,
                          status.document.mediaH || 0,
                        ],
                        id: status.document.id,
                        name: status.document.fileName,
                        preview: status.document.remotePreview || undefined,
                        size: status.document.size,
                        url: status.document.remoteUrl,
                      }}
                      width={Math.min(
                        status.document.mediaW || 0,
                        MAX_MEDIA_WIDTH,
                      )}
                    />
                  )}
                  {status.document.type === 'Photo' && (
                    <PhotoStatus
                      media={status.document}
                      maxMediaWidth={MAX_MEDIA_WIDTH}
                      onPress={() => {
                        viewPhoto(status)
                      }}
                    />
                  )}
                </>
              )}

              {status.reactions && (
                <Reactions
                  reactions={reactionsByEmoji}
                  toggleReaction={toggleReaction}
                />
              )}
            </View>
            {/* Abs */}
          </View>
        </View>
        {transitions(({ opacity }, item) => {
          return item ? (
            <a.View
              style={[
                {
                  opacity,
                  marginTop: 1.5,
                  position: 'absolute',
                  top: 10,
                  right: 10,
                },
              ]}
              ref={wrapperRef}
            >
              <ReactionBox
                toggleReaction={toggleReaction}
                openPicker={openPicker}
              />
            </a.View>
          ) : null
        })}
      </Pressable>
    </WebStyled>
  )
}

const styles = StyleSheet.create({
  bg: {
    backgroundColor: `rgba(255,255,255,0.1)`,
    backdropFilter: `blur(5px)`,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },
  thumbnail: {
    width: 150,
    height: 100,
  },
})

const linkDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number,
): React.ReactNode => {
  return (
    <View style={{ display: 'flex', flexDirection: 'column' }}>
      <Text
        accessibilityRole="link"
        onPress={(event) => {
          if (isElectron) {
            event.preventDefault()
            electronApi?.openExternalUrl(decoratedHref)
          } else if (typeof window !== 'undefined') {
            window.open(decoratedHref, '_blank')
          }
        }}
        //@ts-ignore
        href={decoratedHref}
        style={{
          textDecorationLine: 'underline',
          textDecorationStyle: 'solid',
        }}
        key={key}
      >
        {decoratedText}
      </Text>
      <OgComponent href={decoratedHref} />
    </View>
  )
}

const OgComponent = ({ href }: { href: string }) => {
  let [ogData, setOgData] = useState<any | undefined>(undefined)
  useEffect(() => {
    fetch(`https://www.opengraph.xyz/.netlify/functions/metadata/?url=${href}`)
      .then((res) => res.json())
      .then((res) => {
        setOgData(res)
      })
  }, [href])
  let theme = useTheme()
  if (!ogData || !ogData.ogImage || !ogData.ogImage.url) return null
  return (
    <View
      style={{
        marginTop: 5,
        borderLeftWidth: 2,
        borderLeftColor: 'rgba(255, 255, 255, 0.2)',
        paddingLeft: 5,
        paddingVertical: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <View>
        {ogData.ogTitle && (
          <Text
            style={{
              fontSize: theme.fontSizes.normal,
              color: theme.colors.tertiaryText,
              overflow: 'hidden',
              height: 17,
            }}
            numberOfLines={1}
          >
            {ogData.ogTitle}
          </Text>
        )}
        {ogData.ogDescription && (
          <Text
            style={{
              fontSize: theme.fontSizes.small,
              color: theme.colors.quaternaryText,
              overflow: 'hidden',
            }}
          >
            {ogData.ogDescription}
          </Text>
        )}
      </View>
      <img
        src={ogData.ogImage.url}
        style={{ width: 200, marginTop: 2, borderRadius: 4 }}
      />
    </View>
  )
}
