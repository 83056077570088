import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLatest } from '../shared/use-latest'

type Props = {
  dragRegionRef?: MutableRefObject<HTMLDivElement | null>
  onDrop: (file: File) => void
}

export const useDrop = ({ dragRegionRef, onDrop: onDropAction }: Props) => {
  const [aboutToDrop, setAboutToDrop] = useState(false)
  let onDropActionRef = useLatest(onDropAction)

  // this is ann
  let cleanUpRef = useRef<null | (() => void)>(null)
  let lastRef = useRef<any>(null)
  let registerDropAction = useCallback(
    (dragRegionRef: HTMLDivElement | null) => {
      if (!dragRegionRef) return
      if (lastRef.current === dragRegionRef) return
      lastRef.current = dragRegionRef
      let element = dragRegionRef

      // Ref: https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
      let draggedOverCounter = 0

      const onDrop = (event: DragEvent) => {
        setAboutToDrop(false)
        draggedOverCounter = 0

        event.preventDefault()
        event.stopPropagation()

        if (!event.dataTransfer) return

        let allFiles = event.dataTransfer.files
        let firstFile: File | undefined = allFiles[0]

        if (!canUploadFile(firstFile)) return
        // todo: handle multiple
        // for (const f of event.dataTransfer.files) {
        // Using the path attribute to get absolute file path
        // console.log('File Path of dragged files:', f.path)
        // }
        // }

        onDropActionRef.current(firstFile)
      }

      const onDragover = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
      }

      const onDragenter = (event: DragEvent) => {
        draggedOverCounter++

        // Note(@mo): For now we can't detect file type...
        // Check if file type is accepted
        // const file: File | undefined = event.dataTransfer?.files[0]
        // if (!canUploadFile(file)) return
        setAboutToDrop(true)
      }

      const onDragleave = (event: DragEvent) => {
        draggedOverCounter--

        if (draggedOverCounter !== 0) return
        // console.log('File has left the Drop Space')
        setAboutToDrop(false)
      }

      element.addEventListener('drop', onDrop)
      element.addEventListener('dragover', onDragover)
      element.addEventListener('dragenter', onDragenter)
      element.addEventListener('dragleave', onDragleave)

      cleanUpRef.current = () => {
        element?.removeEventListener('drop', onDrop)
        element?.removeEventListener('dragover', onDragover)
        element?.removeEventListener('dragenter', onDragenter)
        element?.removeEventListener('dragleave', onDragleave)
      }
    },
    [onDropActionRef],
  )

  useEffect(() => {
    return () => {
      cleanUpRef.current?.()
      cleanUpRef.current = null
      setAboutToDrop(false)
    }
  }, [dragRegionRef, onDropActionRef])

  return { aboutToDrop, registerDropAction }
}

const canUploadFile = (file: File | undefined) => {
  if (!file) return false
  // if (
  //   !['image/jpeg', 'image/png', 'image/gif', 'image/webp'].includes(file.type)
  // ) {
  //   return false
  // }
  return true
}
