import React from 'react'
import { ipc } from '@there/desktop/utils/electron-api'

export class OverlayErrorBoundary extends React.PureComponent<{
  children: React.ReactNode
}> {
  componentDidCatch() {
    ipc?.invoke('overlay:crashed')
  }

  render() {
    return this.props.children
  }
}
