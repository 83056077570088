import {
  avatarFormatter,
  dialogFormatter,
  userFormatter,
  memberFormatter,
  topicFormatter,
} from '@there/sun/utils/formatters'
import { NodeUpdateInfo } from '@there/sun/utils/node-types'
import { filterFalsy } from '@there/shared/utilities/filter-falsy'
import { toGlobalId } from '@there/tower/utils/global-id'

export const formatUpdates = (updates: NodeUpdateInfo[]): NodeUpdateInfo[] => {
  let newups = updates.map((update) => {
    switch (update.type) {
      case 'Avatar':
        return {
          mutation: update.mutation as any,
          type: update.type,
          node: removeEmptyFromObject(avatarFormatter(update.node)),
        }
        break

      case 'Dialog':
        return {
          mutation: update.mutation as any,
          type: update.type,
          spaceId:
            'spaceId' in update
              ? toGlobalId('Space', update.spaceId)
              : undefined,
          node: removeEmptyFromObject(dialogFormatter(update.node)),
        }
        break

      case 'Member':
        return {
          mutation: update.mutation as any,
          type: update.type,
          spaceId:
            'spaceId' in update
              ? toGlobalId('Space', update.spaceId)
              : undefined,
          node: memberFormatter(update.node),
        }
        break

      case 'Topic':
        return {
          mutation: update.mutation as any,
          type: update.type,
          spaceId:
            'spaceId' in update
              ? toGlobalId('Space', update.spaceId)
              : undefined,
          node: topicFormatter(update.node),
        }
        break

      case 'User':
        return {
          mutation: update.mutation as any,
          type: update.type,
          node: removeEmptyFromObject(userFormatter(update.node)),
        }
        break

      default:
        return update
    }
  })

  return newups.filter(filterFalsy) as NodeUpdateInfo[]
}

function removeEmptyFromObject(obj: Record<string, any>) {
  // DO NOT FILTER NULL
  for (const key of Object.keys(obj))
    typeof obj[key] === 'undefined' ? delete obj[key] : {}

  return obj
}
