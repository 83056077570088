export function preferCodec({
  signal,
  codec,
}: {
  signal: Record<string, any>
  codec: 'vp8' | 'vp9' | 'h264'
}) {
  let sdp = signal.sdp

  if (!sdp || typeof sdp !== 'string') {
    return signal
  }

  sdp = preferCodecInSdp(sdp, codec)

  return { ...signal, sdp }
}

function splitLines(sdp: string) {
  const info: Record<string, any> = {}
  for (const line of sdp.split('\n')) {
    if (line.indexOf('m=video') === 0) {
      info.videoCodecNumbers = []
      for (let codecNumber of line.split('SAVPF')[1].split(' ')) {
        codecNumber = codecNumber.trim()
        if (!codecNumber || codecNumber.length === 0) continue
        info.videoCodecNumbers.push(codecNumber)
        info.videoCodecNumbersOriginal = line
      }
    }
    if (line.includes('VP8/90000') && !info.vp8LineNumber) {
      info.vp8LineNumber = line.replace('a=rtpmap:', '').split(' ')[0]
    }
    if (line.includes('VP9/90000') && !info.vp9LineNumber) {
      info.vp9LineNumber = line.replace('a=rtpmap:', '').split(' ')[0]
    }
    if (line.includes('H264/90000') && !info.h264LineNumber) {
      info.h264LineNumber = line.replace('a=rtpmap:', '').split(' ')[0]
    }
  }
  return info
}
export function preferCodecInSdp(sdp: string, codec: 'vp8' | 'vp9' | 'h264') {
  if (!sdp) {
    return sdp
  }

  const info = splitLines(sdp)

  let preferCodecNumber = ''
  if (codec === 'vp8') {
    if (!info.vp8LineNumber) {
      return sdp
    }
    preferCodecNumber = info.vp8LineNumber
  }

  if (codec === 'vp9') {
    if (!info.vp9LineNumber) {
      return sdp
    }
    preferCodecNumber = info.vp9LineNumber
  }

  if (codec === 'h264') {
    if (!info.h264LineNumber) {
      return sdp
    }
    preferCodecNumber = info.h264LineNumber
  }

  let newLine = info.videoCodecNumbersOriginal.split('SAVPF')[0] + 'SAVPF '
  const newOrder = [preferCodecNumber]

  for (const codecNumber of info.videoCodecNumbers) {
    if (codecNumber === preferCodecNumber) continue
    newOrder.push(codecNumber)
  }
  newLine += newOrder.join(' ')
  sdp = sdp.replace(info.videoCodecNumbersOriginal, newLine)

  return sdp
}
