import { useEffect, useCallback, Dispatch, useRef, useMemo } from 'react'
import { ipc, isElectron } from '@there/desktop/utils/electron-api'

export function useUniversalReducer<State = any, Action = any>(
  args: [State, Dispatch<Action>],
  options:
    | { key: string }
    | { key: string; enableInitialHydrate: boolean; isSource: boolean },
): [
  [State, React.Dispatch<Action>],
  { localDispatch: React.Dispatch<Action> },
] {
  let [inputState, inputDispatch] = args
  const isInitialRef = useRef(true)

  //update initial data at first
  useEffect(() => {
    if (!isInitialRef.current) return

    // fetch data using ipc to get state
    // send ipc "universal-state-requested"
    // add a "load" action to the reducer
    //set source on one

    if (
      'isSource' in options &&
      options.enableInitialHydrate &&
      !options.isSource
    ) {
      ipc?.invoke('universal-state-requested')
    }

    isInitialRef.current = false
  }, [options])

  let dispatch: Dispatch<Action> = useCallback(
    (action: Action) => {
      if (isElectron) {
        // Stringify for IPC, to avoid peer, MediaStream objects, etc
        ipc?.invoke(
          'universal-action-dispatched',
          JSON.stringify({ ...action, universalKey: options.key }),
        )
      }

      inputDispatch(action)
    },
    [inputDispatch, options.key],
  )

  useEffect(() => {
    function listener(_: any, actionJSON?: string /* WalkieAction */) {
      if (!actionJSON || typeof actionJSON !== 'string') return
      let action: Action & { universalKey: string } = JSON.parse(actionJSON)
      if (action.universalKey === options.key) {
        inputDispatch(action)
      }
    }

    if (isElectron) {
      ipc?.addListener('universal-action-dispatched', listener)
      return () => {
        ipc?.removeListener('universal-action-dispatched', listener)
      }
    }
  }, [inputDispatch, options.key])

  useEffect(() => {
    if (!('isSource' in options && options.isSource)) return

    // only respond if we are source
    function listener(_: any, input?: { webContentsId: number }) {
      ipc?.invoke(
        'universal-action-dispatched',
        JSON.stringify({
          type: 'load',
          state: inputState,
          universalKey: options.key,
        }),
        { webContentsId: input?.webContentsId },
      )
    }

    if (isElectron) {
      ipc?.addListener('universal-state-requested', listener)
      return () => {
        ipc?.removeListener('universal-state-requested', listener)
      }
    }
  }, [inputDispatch, inputState, options])

  return useMemo(
    () => [[inputState, dispatch], { localDispatch: inputDispatch }],
    [inputState, dispatch, inputDispatch],
  )
}
