import { DialogInfo } from '@there/sun/utils/node-types'

// a first : -1
// b first : 1
export function sortDialogs(a: DialogInfo, b: DialogInfo) {
  // lobby always on top
  if (a.specialType) return -1
  if (b.specialType) return 1

  //room first on less than 4 person on lobby and room with +4 person
  // if (
  //   a.specialType &&
  //   (a.avatars?.length || 0) < 4 &&
  //   (b.avatars?.length || 0) >= 4
  // ) {
  //   return 1
  // }
  // if (
  //   b.specialType &&
  //   (b.avatars?.length || 0) < 4 &&
  //   (a.avatars?.length || 0) >= 4
  // ) {
  //   return -1
  // }

  // lobby first
  if (a.specialType) {
    return -1
  }

  if (b.specialType) {
    return 1
  }

  // check if we have avatars info
  if ('avatars' in a && a.avatars && b.avatars) {
    let aAvatars = a.avatars?.length || 0
    let bAvatars = b.avatars?.length || 0
    if (aAvatars > 0 && bAvatars === 0) {
      return -1
    } else if (bAvatars > 0 && aAvatars === 0) {
      return 1
    } else if (bAvatars > 0 && aAvatars > 0 && a.avatars && b.avatars) {
      // Sort by first joined
      let aFirstAvatarJoinedAt = Math.min(
        ...a.avatars.map((avatar) => avatar.joinedAt || Date.now()),
      )
      let bFirstAvatarJoinedAt = Math.min(
        ...b.avatars.map((avatar) => avatar.joinedAt || Date.now()),
      )

      return aFirstAvatarJoinedAt - bFirstAvatarJoinedAt
    }
  }

  if (a.createdAt && b.createdAt) {
    return a.createdAt - b.createdAt
  }

  return 0
}

export function sortDialogsSpecialType(a: DialogInfo, b: DialogInfo) {
  // lobby always on top
  if (a.specialType) return -1
  if (b.specialType) return 1

  if (a.createdAt && b.createdAt) {
    return a.createdAt - b.createdAt
  }

  return 0
}
