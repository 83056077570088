import { View, Text } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { CircleButton } from '../main/CircleButton'
import { CrossIcon } from '../main/mainIcons'

export const Header = ({
  label,
  onClose,
}: {
  label: string
  onClose: () => void
}) => {
  let theme = useTheme()

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Text
        style={{
          fontSize: theme.fontSizes.large,
          color: theme.colors.secondaryText,
        }}
      >
        {label}
      </Text>
      <CircleButton
        onPress={() => onClose()}
        size={16}
        hoverColor="circleButtonBackgroundHover2"
        activeColor="circleButtonBackgroundActive"
      >
        <CrossIcon width={8} height={8} color={theme.colors.quaternaryText} />
      </CircleButton>
    </View>
  )
}
