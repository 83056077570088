import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export function SendMessageIcon(props: SvgProps) {
  return (
    <Svg width={24} height={24} fill="none" viewBox="0 0 21 21" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 11.521L17.891 10.5 7 9.479V5.05a.437.437 0 01.617-.399l12.11 5.45a.438.438 0 010 .798l-12.11 5.45a.437.437 0 01-.617-.4v-4.428z"
        fill="#3988FF"
      />
      <Path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 14h.875a.875.875 0 010 1.75H3.5a.875.875 0 010-1.75zM.875 9.625h3.5a.875.875 0 010 1.75h-3.5a.875.875 0 010-1.75zM3.5 5.25h.875a.875.875 0 110 1.75H3.5a.875.875 0 110-1.75z"
        fill="#3988FF"
      />
    </Svg>
  )
}
