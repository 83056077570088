import React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { CrossButton } from '../main/CrossButton'
import { StandardInput } from '../main/StandardInput'
import { StandardButton } from '../main/StandardButton'
import { useGameRoom } from './useGame'
import { Space } from '../shared/Space'

export const CurrentGameBox = ({
  isMinimalView,
}: {
  isMinimalView?: boolean
}) => {
  const backToGameList = () => {}
  let { endGame, gameServer, openGame, selectedGameInfo } = useGameRoom()

  const endServer = () => {
    // TODO: Prompt End Game
    endGame()
  }

  if (!selectedGameInfo || !gameServer) return null
  return (
    <View
      style={[
        {
          minHeight: 70,
          borderWidth: 1,
          borderColor: '#787878',
          borderRadius: 10,
          backgroundColor: 'rgba(72, 92, 133, 0.4)',

          justifyContent: 'flex-start',
          flexDirection: 'row',

          padding: 10,
          marginTop: 10,
        },
        isMinimalView && {
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        },
      ]}
    >
      <View
        style={[
          {
            width: 110,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0,
          },
        ]}
      >
        {selectedGameInfo.logo && (
          <Image
            style={styles.gameLogoStyle}
            source={{ uri: selectedGameInfo.logo }}
            resizeMode={'contain'}
          />
        )}
        {selectedGameInfo.title && (
          <Text style={styles.gameTitle}>{selectedGameInfo.title}</Text>
        )}
      </View>
      <View
        style={[
          {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 0,
            marginLeft: 10,
            flexGrow: 1,
          },
          isMinimalView && {
            flexDirection: 'column',
            marginLeft: 0,
            marginTop: 10,
          },
        ]}
      >
        <View
          style={{
            flexGrow: 1,
            flexShrink: 0,
            flex: 1,
          }}
        >
          <StandardInput
            disabled={true}
            placeholder="server url"
            value={gameServer}
            background="transparentInputBackground"
            setValue={() => {}}
            bordered={true}
            align="center"
          />
        </View>
        <View
          style={[
            {
              flexDirection: 'row',
              flexShrink: 1,
              flexGrow: 0,
              marginLeft: 10,
            },
            isMinimalView && {
              flexShrink: 0,
              flexGrow: 1,
              width: '100%',
              justifyContent: 'space-between',
              marginLeft: 0,
              marginTop: 20,
            },
          ]}
        >
          <StandardButton
            text={'Join'}
            backgroundColor="primaryButtonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={() => openGame()}
          />
          <StandardButton
            style={{ marginLeft: isMinimalView ? 0 : 5 }}
            text={'End'}
            backgroundColor="red"
            textColor="secondaryText"
            size="normal"
            onPress={() => endServer()}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  gameWrapper: {},
  gameLogoStyle: {
    width: 88,
    height: 33,
  },
  gameDetails: {},
  gameTitle: {
    color: '#fff',
  },
  closeButton: {
    position: 'absolute',
    left: 5,
    top: 5,
    zIndex: 100,
  },
  gameData: {},
  input: {
    flexGrow: 1,
  },
})
