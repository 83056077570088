import { useLatest } from '@there/components/shared/use-latest'
import { debounce } from '@there/components/utils/schedulers'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  View,
  LayoutChangeEvent,
} from 'react-native'

type OnContentSizeChange = (w: number, h: number) => void
type OnScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => void
type OnLayout = (event: LayoutChangeEvent) => void

type InViewportContextType = {
  registerItem(item: any, ref: ViewRef): () => void
}

export const IsInViewportContext = createContext<InViewportContextType>({
  registerItem: () => {
    return () => {}
  },
})

type ViewRef = { current: View | null }
type Input = {
  target: { current: ScrollView | null }
  items: any[]
  /** Convert item to a stable value */
  itemToKey: (item: any) => any
  inViewItems: (items: any[]) => void
  debounceMs?: number
}

type Output = {
  context: InViewportContextType
  onContentSizeChange: OnContentSizeChange
  onScroll: OnScroll
  onLayout: OnLayout
}

const DEBOUNCE_CHANGE = 150

export function useInViewportManager(input: Input): Output {
  // State
  const layoutHeight = useRef<null | number>(null)
  const allItems = useRef<
    Map<
      any,
      {
        ref: ViewRef
        offset: [number, number] | null
        height: number | null
        item: any
      }
    >
  >(new Map())
  const itemsInView = useRef<Array<any>>([])

  // Input
  const debounceMs = input.debounceMs || DEBOUNCE_CHANGE
  const itemToKeyFunction = useLatest(input.itemToKey)
  const inViewItemsFunction = useLatest(input.inViewItems)

  // Call with updated items in view
  const debouncedInViewItems = useMemo(
    () => {
      return debounce(() => {
        let items = []

        // Convert keys to items
        for (let key of itemsInView.current) {
          let itemInfo = allItems.current?.get(key)
          if (!itemInfo) continue
          items.push(itemInfo.item)
        }

        // Callback
        inViewItemsFunction.current?.(items)
      }, debounceMs)
    },
    // Must not change
    [debounceMs, inViewItemsFunction],
  )

  let itemsWithOffset = useRef(0)
  let itemsLength = useLatest(input.items.length)

  // Update with performance.now() so we trigger an update
  let [calculationDone, setCalculationDone] = useState(0)

  // For debug
  // useEffect(() => {
  //   // @ts-ignore
  //   window.inViewPort = () => ({
  //     layoutHeight: layoutHeight.current,
  //     allItems: allItems.current,
  //     itemsInView: itemsInView.current,
  //   })
  // }, [])

  const measure = useCallback(
    (itemKey: string, initial?: boolean) => {
      let nodeHandle = input.target.current?.getInnerViewNode()
      let itemInfo = allItems.current.get(itemKey)
      if (!itemInfo) return
      let { ref } = itemInfo

      ref.current?.measureLayout(
        nodeHandle,
        (left, top, _width, height) => {
          if (!itemInfo) return

          // Update offset
          allItems.current.set(itemKey, {
            ...itemInfo,
            offset: [left, top],
            height,
          })

          // After initiing all
          if (initial) {
            itemsWithOffset.current++
          }
          let isLast = itemsWithOffset.current === itemsLength.current
          if (initial && isLast) {
            setCalculationDone(performance.now())
          }
        },
        () => {
          console.error('Failed to measure in viewport target')
        },
      )
    },
    [input.target, itemsLength],
  )

  const updateItemsInView = useCallback(
    (layoutHeight: number, scrollY: number) => {
      let inViews = []

      // check is in viewport every time
      for (let [itemKey, { offset, height }] of allItems.current) {
        if (!offset || !height) {
          measure(itemKey)
          continue
        }

        let itemY = offset[1]
        let viewStart = scrollY
        let viewEnd = scrollY + layoutHeight
        let isInView = viewStart < itemY + height && itemY < viewEnd

        if (isInView) {
          inViews.push(itemKey)
        }
      }

      itemsInView.current = inViews
      debouncedInViewItems()
    },
    [debouncedInViewItems, measure],
  )

  useEffect(() => {
    if (!layoutHeight.current) return

    updateItemsInView(layoutHeight.current, 0)
  }, [
    // Update items initially when all items are measured
    calculationDone,
    updateItemsInView,
    layoutHeight,
  ])

  // Handlers
  // When items are added or removed or resized, we need to
  // recalculate in view items.
  const onContentSizeChange = (w: number, h: number) => {
    let nodeHandle = input.target.current?.getInnerViewNode()

    if (!nodeHandle) return

    // measure once
    for (let [itemKey] of allItems.current) {
      measure(itemKey)
    }
  }

  let scrollY = useRef(0)

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    let layoutHeight = event.nativeEvent.layoutMeasurement.height
    let y = event.nativeEvent.contentOffset?.y

    updateItemsInView(layoutHeight, y)
    scrollY.current = y
  }

  const onLayout = (event: LayoutChangeEvent) => {
    layoutHeight.current = event.nativeEvent.layout.height

    updateItemsInView(layoutHeight.current, scrollY.current)
  }

  // Context value
  const context: InViewportContextType = useMemo(() => {
    return {
      registerItem(item, ref) {
        const key = itemToKeyFunction.current?.(item)

        if (!key) {
          console.warn('Key was not generated')
          return () => {}
        }

        allItems.current.set(key, { ref, offset: null, height: null, item })
        measure(key, true)

        return () => {
          allItems.current.delete(key)
        }
      },
    }
  }, [itemToKeyFunction, measure])

  // Output
  return {
    context,
    onContentSizeChange,
    onScroll,
    onLayout,
  }
}

export const useInViewportTarget = (item: any, ref: ViewRef) => {
  let { registerItem } = useContext(IsInViewportContext)

  useEffect(() => {
    let unregister = registerItem(item, ref)

    return () => {
      unregister()
    }
  }, [item, ref, registerItem])
}
