import { useNurMutation } from '@there/components/sun/use-mutation'
import { EditChatMessageSimpleMessage } from '@there/sun/modules/editChatMessageSimple'
interface Data {
  done: boolean
}

export const useEditChatMessageSimple = () => {
  return useNurMutation<Data, EditChatMessageSimpleMessage['payload']>({
    method: 'editChatMessageSimple',
  })
}
