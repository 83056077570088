import { Omit } from '@there/shared/types'
import {
  Pressable as RnPressable,
  PressableProps,
  StyleProp,
  ViewStyle,
} from 'react-native'

export type PressableState = Readonly<{
  pressed: boolean
  hovered?: boolean
  focused?: boolean
}>
export type PressableChildren =
  | React.ReactNode
  | ((state: PressableState) => React.ReactNode)

export const Pressable = (
  props: Omit<PressableProps, 'children' | 'style'> & {
    children?: React.ReactNode | ((state: PressableState) => React.ReactNode)
    style?:
      | StyleProp<ViewStyle>
      | ((state: PressableState) => StyleProp<ViewStyle>)
  },
) => <RnPressable {...props} />
