import { Pressable } from '@there/components/shared/Pressable'
import { useWindowContext } from '@there/components/shared/WindowContext'
import { rgba } from 'polished'
import { memo, useCallback } from 'react'
import { GestureResponderEvent, Text } from 'react-native'
import { Emoji, ConfigType } from './use-picker'
import { THERE_EMOJI_VERSION } from './use-emoji-cache'

type Props = {
  emoji: Emoji
  onPress: (emoji: Emoji) => void
  config: ConfigType
}

let productionPath = ''
export const EMOJI_FILE_LINK =
  process.env.NODE_ENV === 'production'
    ? `${productionPath}/emojis-${THERE_EMOJI_VERSION}`
    : `http://localhost:${
        process.env.APP_ENV === 'nooray' ? '7777' : '7000'
      }/emojis-${THERE_EMOJI_VERSION}`

export const EmojiButton = memo(({ emoji, onPress, config }: Props) => {
  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      event.preventDefault()

      onPress(emoji)
    },
    [emoji, onPress],
  )

  return (
    <Pressable
      onPress={handlePress}
      style={({ hovered }) => [
        {
          width: config.emojiSize,
          height: config.emojiSize,
          marginHorizontal: config.emojiMargin,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 6,
        },
        hovered && {
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
        },
      ]}
    >
      {/* {isMacOs ? (
        <Text
          style={{
            fontSize: 28,
            lineHeight: config.emojiSize,
          }}
        >
          {emoji.native}
        </Text>
      ) : ( */}
      <img
        style={{ width: config.emojiSize - 8 }}
        src={`${EMOJI_FILE_LINK}/img-apple-64/${emoji.image}.png`}
        alt=""
      />
      {/* )} */}
    </Pressable>
  )
})
