import gql from 'graphql-tag'
import { NexusGenFieldTypes } from '@there/tower/generated/graphql'
import { MemberInfo } from '@there/components/urql/fragments/spaceMembersInfo'
import { MemberWithUserInfo } from '@there/components/urql/fragments/memberInfo'

export const spaceInfoFragment = gql`
  fragment spaceInfo on Space {
    id
    name
    joinSecretCode
    __typename
  }
`

type Source = NexusGenFieldTypes['Space']

export interface SpaceInfo {
  id: Source['id']
  name: Source['name']
  joinSecretCode: Source['joinSecretCode']
}

export interface SpaceWithMembers extends SpaceInfo {
  members: MemberWithUserInfo[]
}
