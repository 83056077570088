import React, { useCallback, useEffect } from 'react'
import { View, TouchableOpacity, Text, ImageBackground } from 'react-native'
import { ProfilePhoto } from '../feed/ProfilePhoto'
import { useTheme } from '@there/components/feed/ThemeContext'
import {
  NotificationType,
  NotificationsDispatch,
  NotificationResponse,
  NudgeNotificationType,
  JoinRoomNotificationType,
} from '../shared/use-notification-state'
import { useHover } from '../shared/use-hover'
import { tpmt } from '../utils/tmpt'
import { useSpring, a } from '@react-spring/native'
import { Space } from '../shared/Space'
import { getShortName } from '@there/shared/utilities/get-short-name'
import { StandardButton } from './StandardButton'
import { ipc } from '@there/desktop/utils/electron-api'
import { Pressable } from '../shared/Pressable'
import { CrossButton } from './CrossButton'
import { useAppContext } from '../shared/AppContext'

type Props = {
  notification: NotificationType
  notificationDispatch: NotificationsDispatch
}

const notificationDuration: Record<string, number> = {
  nudge: 8000,
  joinRoom: 8000,
  screenShareInvite: 60_000,
  default: 5000,
}

export const Nudge = (props: Props) => {
  let { activeSpaceId } = useAppContext()
  let t = useTheme()
  let [hovered, hoverListener] = useHover()
  let notificationDispatch = props.notificationDispatch
  let notification = props.notification as NudgeNotificationType

  let removeNotification = useCallback(() => {
    notificationDispatch({
      type: 'incoming dismissed',
      notification: notification,
    })
  }, [notificationDispatch, notification])

  const dismiss = useCallback(() => {
    removeNotification()
    ipc?.invoke('notification:onAction', {
      notificationId: notification.senderId, // we use senderId as notificationId here
      action: NotificationResponse.Dismissed,
    })
  }, [notification, removeNotification])

  let openFeed = useCallback(() => {
    ipc?.invoke('feed:focus')
    dismiss()
  }, [dismiss])

  // Remove notification after timeout
  useEffect(() => {
    let duration =
      notificationDuration[props.notification.type] ||
      notificationDuration['default']
    let t = setTimeout(() => {
      removeNotification()
    }, duration)

    return () => clearTimeout(t)
  }, [props.notification.type, removeNotification])

  let crossStyleProps = useSpring({
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: hovered ? 1 : 0,
    config: {
      mass: 0.1,
      tension: 300,
      friction: 20,
    },
  })

  const join = useCallback(() => {
    // add to responded queu notitifications
    notificationDispatch({
      type: 'incoming responded',
      notification,
      response: NotificationResponse.Accepted,
    })
    ipc?.invoke('notification:onAction', {
      notificationId: notification.senderId, // we use senderId as notificationId here
      action: NotificationResponse.Dismissed,
    })
  }, [notification, notificationDispatch])

  const joinSpace = useCallback(() => {
    if (!notification.spaceId) return
    if (notification.spaceId === activeSpaceId) return

    ipc?.invoke('feed:general-ipc', {
      event: 'change-space',
      payload: {
        spaceId: notification.spaceId,
      },
    })

    // dismiss notification
    ipc?.invoke('notification:onAction', {
      notificationId: notification.senderId, // we use senderId as notificationId here
      action: NotificationResponse.Dismissed,
    })
  }, [activeSpaceId, notification.senderId, notification.spaceId])

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        paddingVertical: 10,
        paddingTop: 0,
        marginTop: 2,
      }}
    >
      <TouchableOpacity
        activeOpacity={1}
        style={{
          position: 'relative',
        }}
        onPress={() => openFeed()}
        {...hoverListener}
      >
        <View style={{ position: 'relative' }}>
          <ProfilePhoto
            size={44}
            user={notification.user}
            borderWidth={1}
            borderColor="avatarBorder"
            style={{
              shadowOffset: { width: 0, height: 3 },
              shadowRadius: 8,
              shadowColor: t.colors.black,
              shadowOpacity: 0.15,
              elevation: 2,
            }}
          />
          <Text
            style={{
              position: 'absolute',
              bottom: -4,
              left: -4,
              fontSize: 22,

              textShadowOffset: { width: 0, height: 3 },
              textShadowRadius: 8,
              textShadowColor: 'rgba(0, 0, 0, 0.2)',
            }}
          >
            👋
          </Text>
        </View>
        <a.View
          //@ts-ignore
          style={crossStyleProps}
        >
          <CrossButton onPress={() => dismiss()} />
        </a.View>
      </TouchableOpacity>
      {notification.spaceId && notification.spaceId !== activeSpaceId ? (
        <Pressable
          style={({ hovered, pressed }) => [
            {
              marginTop: 4,
              backgroundColor: t.colors.textButtonBackground,
              paddingHorizontal: 10,
              paddingVertical: 3,
              borderRadius: 9,
              borderWidth: 1,
              borderColor: t.colors.transparentInputBorder,
            },
            hovered && {
              backgroundColor: t.colors.textButtonBackgroundHover,
            },
            pressed && {
              backgroundColor: t.colors.textButtonBackgroundActive,
            },
          ]}
          onPress={joinSpace}
        >
          <Text
            style={{
              fontSize: t.fontSizes.normal,
              color: t.colors.secondaryText,
            }}
          >
            Join Space
          </Text>
        </Pressable>
      ) : notification.dialogId ? (
        <Pressable
          style={({ hovered, pressed }) => [
            {
              marginTop: 4,
              backgroundColor: t.colors.textButtonBackground,
              paddingHorizontal: 10,
              paddingVertical: 3,
              borderRadius: 9,
              borderWidth: 1,
              borderColor: t.colors.transparentInputBorder,
            },
            hovered && {
              backgroundColor: t.colors.textButtonBackgroundHover,
            },
            pressed && {
              backgroundColor: t.colors.textButtonBackgroundActive,
            },
          ]}
          onPress={join}
        >
          <Text
            style={{
              fontSize: t.fontSizes.normal,
              color: t.colors.secondaryText,
            }}
          >
            Join
          </Text>
        </Pressable>
      ) : null}
    </View>
  )
}

export const JoinRoom = (props: Props) => {
  let t = useTheme()
  let [hovered, hoverListener] = useHover()
  let notificationDispatch = props.notificationDispatch
  let notification = props.notification as JoinRoomNotificationType

  let removeNotification = useCallback(() => {
    notificationDispatch({
      type: 'incoming dismissed',
      notification: notification,
    })
  }, [notificationDispatch, notification])

  let openFeed = useCallback(() => {
    ipc?.invoke('feed:focus')
    removeNotification()
  }, [removeNotification])

  // Remove notification after timeout
  useEffect(() => {
    let duration =
      notificationDuration[props.notification.type] ||
      notificationDuration['default']
    let t = setTimeout(() => {
      removeNotification()
    }, duration)

    return () => clearTimeout(t)
  }, [props.notification.type, removeNotification])

  let crossStyleProps = useSpring({
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: hovered ? 1 : 0,
    config: {
      mass: 0.1,
      tension: 300,
      friction: 20,
    },
  })

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        paddingVertical: 10,
        paddingTop: 0,
        marginTop: 2,
      }}
    >
      <TouchableOpacity
        activeOpacity={1}
        style={{
          position: 'relative',
        }}
        onPress={() => openFeed()}
        {...hoverListener}
      >
        <View style={{ position: 'relative' }}>
          <ProfilePhoto
            size={44}
            user={notification.user}
            borderWidth={1}
            borderColor="avatarBorder"
            style={{
              shadowOffset: { width: 0, height: 3 },
              shadowRadius: 8,
              shadowColor: t.colors.black,
              shadowOpacity: 0.15,
              elevation: 2,
            }}
          />
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,

              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: 10,
                textAlign: 'center',
                color: t.colors.secondaryText,

                backgroundColor: t.colors.textButtonBackground,
                paddingHorizontal: 4,
                paddingVertical: 1,
                borderRadius: 8,
                borderWidth: 1,
                borderColor: t.colors.transparentInputBorder,
              }}
            >
              Joined
            </Text>
          </View>
        </View>
        <a.View
          //@ts-ignore
          style={crossStyleProps}
        >
          <CrossButton onPress={() => removeNotification()} />
        </a.View>
      </TouchableOpacity>
    </View>
  )
}

export const ScreenShareInvite = (props: Props) => {
  let t = useTheme()
  let [hovered, hoverListener] = useHover()
  let notificationDispatch = props.notificationDispatch
  let notification = props.notification

  let removeNotification = useCallback(() => {
    notificationDispatch({
      type: 'incoming dismissed',
      notification: notification,
    })
  }, [notificationDispatch, notification])

  // Remove notification after timeout
  useEffect(() => {
    let duration =
      notificationDuration[props.notification.type] ||
      notificationDuration['default']
    let t = setTimeout(() => {
      removeNotification()
    }, duration)

    return () => clearTimeout(t)
  }, [props.notification.type, removeNotification])

  const acceptInvite = useCallback(() => {
    notificationDispatch({
      type: 'incoming responded',
      notification,
      response: NotificationResponse.Accepted,
    })
  }, [notification, notificationDispatch])

  let crossStyleProps = useSpring({
    position: 'absolute',
    top: 10,
    left: 0,
    opacity: hovered ? 1 : 0,
    config: {
      precision: 180,
      friction: 20,
      duration: 300,
      delay: 2000,
      easing: (t) => 1 - tpmt(t),
    },
  })

  return (
    <View style={{ position: 'relative' }} {...hoverListener}>
      <View
        style={{
          //@ts-ignore
          cursor: 'pointer',
          position: 'relative',
          marginTop: 10,
          width: 177,
          height: 110,
          borderRadius: 27,
          overflow: 'hidden',

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          shadowOffset: { width: 0, height: 3 },
          shadowRadius: 8,
          shadowColor: t.colors.black,
          shadowOpacity: 0.15,
          elevation: 2,
        }}
      >
        <div
          style={{
            backgroundImage: 'url("/notification/screen-share-bg.png")',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            filter: `blur(4px)`,
            position: 'absolute',
            width: 200,
            height: 150,
          }}
        />

        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ProfilePhoto
            size={30}
            user={notification.user}
            borderWidth={1}
            borderColor="avatarBorder"
            style={{
              shadowOffset: { width: 0, height: 3 },
              shadowRadius: 8,
              shadowColor: t.colors.black,
              shadowOpacity: 0.15,
              elevation: 2,
            }}
          />
          <Space vertical={5} />
          <Text
            style={{
              fontSize: t.fontSizes.normal,
              fontWeight: 'normal',
              textAlign: 'center',
              color: t.colors.secondaryText,
            }}
          >
            View {getShortName(notification.user)}
            {"'"}s Screen
          </Text>
          <Space vertical={5} />

          <StandardButton
            size="small"
            onPress={() => acceptInvite()}
            backgroundColor="primary"
            text="Open"
            textColor="white"
          />
        </View>
      </View>
      <a.View
        //@ts-ignore
        style={crossStyleProps}
      >
        <CrossButton onPress={() => removeNotification()} />
      </a.View>
    </View>
  )
}
