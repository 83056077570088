import { DefaultTheme } from 'styled-components'
import { feedBackgroundDark } from '@there/shared/desktop/windows-config'

export const staticTheme = {
  borderRadius: {
    subtle: 1,
    small: 4,
    large: 8,
    larger: 14,
    xLarger: 50,

    dialog: 6,
    searchInput: 8,

    listSwitch: 14,
    avatar: 18,
    avatarMini: 9,
    avatarNudge: 20,
    buddy: 14,
    room: 14,
    modal: 12,
  },

  spaces: {
    largeSidePadding: 24,
    bottomPaddingNarrow: 8,
    sidePaddingNarrow: 12,
    sidePadding: 14,
    contentSidePadding: 18,

    roomNodeHorizontalPadding: 5,
    roomSpaceBetween: 2,

    chatListLeftPadding: 8,
    chatListRightPadding: 6,
    chatHeaderHorizontalPadding: 11,
    chatDialogLeftSpace: 3,
    chatDialogWrapperBottomPadding: 10,
    topicSectionHeadTextLeftPadding: 10,

    onboardingPrimaryButtonPadding: 30,

    listLeftPadding: 14,
    listRightPadding: 12,
  },

  fonts: {
    system: `-apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol'`,
    body: `-apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol'`,
    heading: 'inherit',
  },

  fontSizes: {
    tiny: 10,
    small: 11,
    normal: 13,
    large: 15,
    xLarge: 17,
    // small: 12,
    // normal: 14,
    // large: 16,

    unsafe9: 9,
    unsafe105: 10.5,
    '11': 11,
    '12': 12,
    '13': 13,
    '14': 14,
    '15': 15,
    '16': 16,
    '17': 17,
    '18': 18,
    '20': 20,
    '21': 21,
  },

  fontWeights: {
    body: 400,
    button: 700,
    medium: 400,
    heading: 700,
    pageHeading: 700,
    bold: 700,
    bolder: 700,
  },

  letterSpacing: {
    body: '0.02em',
  },
}

const baseColors = {
  text: 'rgba(255, 255, 255, 1)',
  primaryText: 'rgba(255, 255, 255, 0.94)',
  secondaryText: 'rgba(255, 255, 255, 0.87)',
  tertiaryText: 'rgba(255, 255, 255, 0.60)',
  quaternaryText: 'rgba(255, 255, 255, 0.37)',
  quinaryText: 'rgba(255, 255, 255, 0.25)',
}

const darkTheme: DefaultTheme = {
  ...staticTheme,
  darkMode: true,

  colors: {
    ...baseColors,

    contrast: '#fff',
    zeroContrast: 'black',

    secondaryLight: 'rgba(255, 255, 255, 0.75)',
    tertiaryLight: 'rgba(255, 255, 255, 0.50)',
    quaternaryLight: 'rgba(255, 255, 255, 0.25)',
    quinaryLight: 'rgba(255, 255, 255, 0.15)',

    dangerouslySecondary: '#EF6F6F',
    background: feedBackgroundDark,
    sideBg: 'rgba(255,255,255, 0.03)',
    mainBg: '#161A20',
    popoverBg: '#2F343E',
    tooltipBg: '#151921',
    // tooltipBg: '#373D43',
    hoverItemBackground: '#5D636A',
    activeItemBackground: '#50575E',
    profileBackground: '#CB4F87',
    callBarBackground: '#263138',
    callBarBackgroundActive: '#325ECE',

    transparentOverlay: 'rgba(255,255,255,0.05)',
    transparentInputBackground: 'rgba(255, 255, 255, 0.1)',
    transparentInputBorder: 'rgba(255, 255, 255, 0.1)',
    transparentButton: 'rgba(255,255,255,0.15)',
    transparentButtonBackground: 'rgba(0,0,0,0.12)',
    transparentButtonBorder: 'rgba(0, 0, 0, 0.1)',

    /** @deprecated */
    modalCrossBackground: '',
    modalCrossBackgroundHover: '',
    modalCrossBackgroundActive: '',

    transparent: 'rgba(255,255,255,0)',
    separatorLine: 'rgba(255,255,255,0.03)',
    verticalSeparator: '#0E1621',

    // primary: '#0057DA',
    primary: '#0E9AFF',
    orange: '#FF9D42',
    yellow: '#FFD60A',
    red: '#ff0266',
    pink: '#FF46B5',
    blue: '#2094FF',
    palePink: '#F2CCFF',
    green: '#00E291',
    white: '#fff',
    black: '#000',
    muted: '#f6f6f6',
    systemBlueVibrant: '#0075FF',
    toolbarHover: '#DAD9D7',
    toolbarActive: '#DAD9D7',

    spaceName: baseColors.secondaryText,
    spaceToggle: 'rgba(255, 255, 255, 0.25)',

    headerBackground: '#131F27',
    footerBackground: '#191F27',

    circleButtonBackground: '#34363B',
    circleButtonBackgroundHover: '#44454B',
    circleButtonBackgroundHover2: '#44454B',
    circleButtonBackgroundActive: '#38393E',

    buttonBackground: 'rgba(255,255,255,0.15)',
    inviteLinkButtonBackground: '#0085FF',

    outlineButtonBorderColor: 'rgba(255, 255, 255, 0.2)',

    textButtonBackground: '#395063',
    textButtonBackgroundHover: '#41586A',
    textButtonBackgroundActive: '#2C3E52',
    textButtonColor: 'rgba(255, 255, 255, 0.65)',

    primaryButtonBackground: '#4C94FF',
    primaryButtonBackgroundHover: '#66A3FF',
    primaryButtonBackgroundActive: '#227AE2',

    roomBackgroundEmpty: '#212A37',
    roomBackground: '#252F3D',
    roomBackgroundHover: '#2C3543',
    roomActiveBorderColor: '#2F73DA',

    onboardingButtonBackground: '#69a5ff',

    emojiPickerBackground: '#1E2630',
    emojiPickerBorder: 'rgba(255, 255, 255, 0.1)',
    emojiPickerSearchBackground: '#2D343E',
    emojiPickerSearchIcon: 'rgba(255, 255, 255, 0.35)',
    emojiPickerSearchText: 'rgba(255, 255, 255, 0.35)',
    emojiPickerCategory: 'rgba(255, 255, 255, 0.45)',
    emojiPickerCategoryActive: '#266ACF',
    emojiPickerEmojiBackgroundHover: '#232C36',

    avatarBorder: 'rgba(255, 255, 255, 0.1)',
    avatarActiveBorder: '#265BE3',
    avatarTalkingBorder: '#0AC883',
    avatarBorderTalking: '#00CD21',
    avatarOnlineIndicator: '#00DA71',
    // avatarOnlineIndicator: '#00bbf9',
    // avatarOnlineIndicator: 'rgba(255, 255, 255, 0.9)',
    avatarOnlineIndicatorBorder: '#17212B',
    avatarIdleIndicator: '#FFE03E',
    avatarWorkingIndicator: '#00DA71',
    avatarName: 'rgba(255, 255, 255, 0.45)',

    personActiveHover: '#323941',
    personActiveStatus: '#4C94FF',
    personMutedMicBadgeBackground: '#263038',

    listSwitchWrapperBorder: 'rgba(255, 255, 255, 0.05)',
    listSwitchButtonBackgroundActive: 'rgba(255, 255, 255, 0.1)',
    listSwitchButtonBackgroundHover: 'rgba(255, 255, 255, 0.05)',
    listSwitchButtonText: 'rgba(255, 255, 255, 0.35)',
    listSwitchButtonTextActive: 'rgba(255, 255, 255, 0.85)',

    buddyName: baseColors.secondaryText,
    buddyNameActive: '#80B3FF',
    buddyNameTalking: '#6BF392',
    buddyNameOffline: baseColors.tertiaryText,
    buddyDescription: 'rgba(255, 255, 255, 0.35)',
    buddySelfBackground: '#232C38',
    buddyHoverBackground: '#232C38',
    buddyDoorIcon: '#0085FF',
    buddyEnterText: '#0085FF',
    buddyClosedDoorIcon: '#FFAE64',
    buddyKnockText: '#FFAE64',

    doorIcon: '#898A8C',

    slideModalBackground: '#283341',
    slideModalHeaderTitle: 'rgba(255, 255, 255, 0.45)',
    slideModalCloseButtonBackground: 'rgba(0, 0, 0, 0.31)',
    slideModalCloseButtonBackgroundHovered: 'rgba(0, 0, 0, 0.21)',
    slideModalCloseButtonIcon: 'rgba(255, 255, 255, 0.25)',
    slideModalInputBackground: 'rgba(255, 255, 255, 0.1)',

    modalBackground: 'rgba(40, 52, 65, 0.44)',
    modalBorder: 'rgba(255, 255, 255, 0.15)',
    modalPrimaryButtonBackground: '#0085FF',
    modalSecondaryButtonBackground: '#404A56',
    modalButtonColor: '#fff',

    selectNewEmojiButtonBackground: 'rgba(255, 255, 255, 0.07)',
    selectNewEmojiButtonBackgroundHover: 'rgba(255, 255, 255, 0.1)',
    acceptCreateRoomButtonBackground: '#0B6CFF',
    acceptCreateRoomButtonBackgroundHover: '#1B76FF',

    focusModeButtonBackground: '#FFD60A',
    focusModeButtonText: 'rgba(0, 0, 0, 0.6)',

    screenShareButtonIcon: '#AC69D6',
    cameraButtonIcon: '#DB469F',
    remoteControlButtonIcon: '#F8D41C',
    systemAudioButtonIcon: '#e76f51',

    bubbleMessageBackground: '#404E5A',
    bubbleMessageSelfBackground: '#547AC5',
    bubbleMessageInputBackground: '#3E474E',
    bubbleMessageBorder: '#515960',
    bubbleMessageIcon: '#878D91',
    bubbleMessagePlaceholder: '#959A9E',
    bubbleMessageText: baseColors.tertiaryText,

    profilePickerBackground: '#DB469F',
    profilePickerBackgroundHover: '#E256AA',
    profilePickerBackgroundActive: '#B63B84',
    profilePickerPlus: '#E7C2D3',
    pinButtonBackground: '#212B37',
    pinLeaveButtonBackground: '#FF6D6D',

    updateBadgeBackground: '#2094FF',
    changelogBadgeBackground: '#3A3E47',

    lockRoomIcon: '#06d6a0',

    card: `#2C3A4B`,
    cardHovered: `#334357`,
  },

  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },

  transition: {
    colors: `color 0.12s ease-in-out, background 0.12s ease-in-out, opacity 0.12s ease-in-out, fill 0.12s ease-in-out`,
    color: `color 0.12s ease-in-out`,
    opacity: `opacity 0.12s ease-in-out`,
    background: `background 0.12s ease-in-out`,
    border: `border 0.12s ease-in-out`,
    filter: `filter 0.12s ease-in-out`,
    shadow: `box-shadow 0.12s ease-in-out`,
    fill: `fill 0.12s ease-in-out`,
    transform: `transform 0.12s ease-in-out`,
  },
}

export type ThemeType = DefaultTheme
export { darkTheme, darkTheme as lightTheme }
