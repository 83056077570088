import { useSelect } from 'downshift'
import { Dropdown, DropdownItemType } from '@there/components/feed/Dropdown'
import { Space } from '@there/components/shared/Space'
import { ToggleRow } from '../settings/ToggleRow'
import { View } from 'react-native'

type Props = {
  isUs: boolean
  children: React.ReactNode
  toggleSelfView: () => void
  isSelfViewHidden: boolean
}

export const CameraDropdown = (props: Props) => {
  let { children, isUs } = props

  let items: DropdownItemType[] = [
    {
      label: props.isSelfViewHidden ? 'Show self view' : 'Hide self view',
      onClick: () => {
        props.toggleSelfView()
      },
    },
  ]

  let {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    closeMenu,
    openMenu,
  } = useSelect({
    items: items,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.MenuBlur:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          }
      }
      return changes
    },
  })

  const onContextMenu = () => {
    if (!isUs) return
    openMenu()
  }

  return (
    <Dropdown.Root>
      <Dropdown.Toggle
        toggleProps={getToggleButtonProps}
        onContextMenu={onContextMenu}
      >
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu
        {...getMenuProps(undefined, { suppressRefError: true })}
        isOpen={isOpen}
        onClose={closeMenu}
        width={145}
      >
        <View
          style={{
            paddingHorizontal: 8,
            paddingVertical: 6,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ToggleRow
            title="Self View"
            value={!props.isSelfViewHidden}
            description="show/hide avatar"
            onValueChange={(value) => {
              props.toggleSelfView()
            }}
          />
        </View>
      </Dropdown.Menu>
    </Dropdown.Root>
  )
}
