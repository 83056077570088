import React from 'react'
import { View } from 'react-native'
import { headerHeight } from '../feed/Header'
import { useNotificationsContext } from '../shared/NotificationsManager'
import { Notifications } from './Notifications'

export const WebNotification = () => {
  let { state, dispatch, notifications } = useNotificationsContext()

  return (
    <View
      style={{
        position: 'absolute',
        width: 90,
        top: headerHeight,
        left: 0,
        right: 0,
        marginHorizontal: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        // @ts-ignore
        pointerEvents: 'none',
      }}
    >
      <Notifications
        notifications={notifications}
        notificationsState={state}
        notificationsDispatch={dispatch}
      />
    </View>
  )
}
