import { a, useSpring, useTransition } from '@react-spring/native'
import { useModals } from '@there/components/feed/ModalsContext'
import { useTheme } from '@there/components/feed/ThemeContext'
import {
  currentDialogIdAtom,
  joinDialogAtom,
} from '@there/components/feed/useLobbyMan'
import { Person } from '@there/components/main/Person'
import { RoomMenu } from '@there/components/main/RoomMenu'
import {
  dilogPaddingLeft,
  itemHeight,
  itemWidth,
} from '@there/components/main/Rooms'
import { sortAvatarsByJoined } from '@there/components/main/sortAvatars'
import { RoomEditableEmoji } from '@there/components/room/RoomEditableEmoji'
import { useAppContext } from '@there/components/shared/AppContext'
import { MicrophoneIcon } from '@there/components/shared/MicrophoneIcon'
import { Pressable } from '@there/components/shared/Pressable'
import { useHover } from '@there/components/shared/use-hover'
import { useInitialRender } from '@there/components/shared/useInitialRender'
import {
  AvatarWithUserInfo,
  DialogInfo,
  DialogWithFullAvatarsInfo,
} from '@there/sun/utils/node-types'
import { atom, useAtom } from 'jotai'
import React, { memo, useEffect, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'
import {
  PressableProps,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { roomViewAtom } from '../atoms/roomAtom'
import { PersonDropdown } from '../dropdown/PersonDropdown'
import { LockIcon } from '../room/LockIcon'
import { Tooltip } from '../shared/Tooltip'
import { useUiContext } from '../shared/UiContext'
import { useMainWindowContext } from '../shared/use-main-window'
import { useShortcutManager } from '../shared/use-shortcut-manager'
import { OpenGameView } from './OpenGameView'
// import { setTimeout } from 'timers'

export let dialogHeight = 115
export let minDialogHeight = 38
export let dialogMarginHorizontal = 3
let horizontalPadding = 7
export let dialogWidth = horizontalPadding * 2 + itemWidth
export let dialogBoxBorderWidth = 2

// Also bottom padding of dialog
const peopleVerticalGap = 6

export const roomSelectedAtom = atom(false)
export const selectedIdAtom = atom('')
export const NewDialogItem = memo(
  ({
    dialog,
    compactRoom,
  }: {
    dialog: DialogWithFullAvatarsInfo
    compactRoom?: boolean
  }) => {
    const [hovered, hoverListeners] = useHover()
    let { currentUserId } = useAppContext()
    let [currentDialogId] = useAtom(currentDialogIdAtom)
    let [{ joinDialog }] = useAtom(joinDialogAtom)
    let [, modalsDispatch] = useModals()
    let { dispatch } = useMainWindowContext()

    let hasJoinPrivilege = !Boolean(
      dialog.avatars.length > 0 && dialog.doorClosed,
    )

    let isInitialRender = useInitialRender()
    // default switch to room view on join
    // later: should add it in preferences
    let [, setRoomView] = useAtom(roomViewAtom)

    function joinHere() {
      // do not join when locked
      if (!hasJoinPrivilege) return
      joinDialog(dialog.id)

      // go to game room view
      if (dialog?.title?.toLowerCase().includes('game')) {
        // dispatch({ type: 'change mode', mode: 'room', isGameRoom: true })
      }

      // do not show room view on join
      setRoomView(false)

      // remove selected
      setSelectedId('')
    }

    function rename() {
      modalsDispatch({
        modalName: 'editRoom',
        type: 'modal opened',
        modalData: {
          dialogId: dialog.id,
          dialog: { ...dialog, avatars: undefined },
        },
      })
    }

    let isLobby = dialog.specialType === 'lobby'
    let weHere = currentDialogId === dialog.id
    let isActive = weHere
    let dialogTitle = isLobby ? 'Lobby' : dialog.title
    let avatarsCount =
      typeof dialog.avatars.length !== 'undefined' ? dialog.avatars.length : 0

    let sortedPeople = dialog.avatars.sort(sortAvatarsByJoined)

    // animate people
    let animatedPeople = useTransition(sortedPeople, {
      key: (item: AvatarWithUserInfo) => item.id,
      from: isInitialRender
        ? undefined
        : {
            opacity: 0.1,
            scale: 0,
            width: 0,
            height: 0,
          },
      enter: {
        opacity: 1,
        scale: 1,
        width: itemWidth,
        height: itemHeight,
      },
      leave: {
        opacity: 0,
        scale: 0,
        width: 0,
        height: 0,
      },
      config: {
        mass: 0.1,
        tension: 400,
        friction: 20,
      },
    })

    // Render people
    let peopleNodes = animatedPeople(({ opacity, scale, width }, avatar) => {
      let isUs = avatar.userId === currentUserId

      return (
        <a.View
          style={{
            opacity,
            transform: [{ scale }],
            width,
          }}
        >
          <PersonDropdown isUs={isUs} userId={avatar.user.id}>
            <Person
              inCallView={true}
              avatar={avatar}
              user={avatar.user}
              userId={avatar.user.id}
              avatarId={avatar.id}
              isUs={isUs}
              key={avatar.id}
            />
          </PersonDropdown>
        </a.View>
      )
    })
    // Double click
    let [roomSelected, setRoomSelected] = useAtom(roomSelectedAtom)
    let timeOutId = useRef(0)
    let [selectedId, setSelectedId] = useAtom(selectedIdAtom)
    let isDialogSelected = dialog.id === selectedId

    let { doubleClick } = useAppContext()
    let joinByDoubleClick = doubleClick
    function onPress() {
      if (!joinByDoubleClick) {
        joinHere()
        return
      }

      if (isDialogSelected) {
        joinHere()
      }

      if (timeOutId.current) {
        clearTimeout(timeOutId.current)
        timeOutId.current = 0
        return
      }

      // highlight selected room
      if (isDialogSelected) {
        // clear
        // setSelectedId('')
      } else {
        setSelectedId(dialog.id)

        timeOutId.current = setTimeout(() => {
          setRoomSelected(!roomSelected)
          timeOutId.current = 0
        }, 2500)
      }
    }

    //esc to deselect dialog
    let {
      subscribeToEscape,
      unsubscribeFromEscape,
      getEscapeSubscribersCount,
    } = useShortcutManager()

    useEffect(() => {
      // only subscribe to escape when a dialog is selected (not deselected)
      if (!selectedId) return

      function onEscape() {
        setSelectedId('')

        clearTimeout(timeOutId.current)
        timeOutId.current = 0
        setRoomSelected(false)
      }
      subscribeToEscape(onEscape)

      selectedId

      return () => {
        unsubscribeFromEscape(onEscape)
      }
    }, [
      // do not remove
      selectedId,
      setRoomSelected,
      setSelectedId,
      subscribeToEscape,
      unsubscribeFromEscape,
    ])

    let canJoin = weHere || !hasJoinPrivilege
    let fullMode = avatarsCount === 0 && !compactRoom
    let isAnyoneInside = Boolean(avatarsCount > 0)

    return (
      <View
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <DialogBox
          transparent={isLobby}
          active={isActive}
          hovered={hovered}
          select={isDialogSelected}
          isAnyoneInside={Boolean(avatarsCount > 0)}
          // Let's move join button
          // disabled={true}
          disabled={weHere || !hasJoinPrivilege}
          onPress={weHere || !hasJoinPrivilege ? undefined : onPress}
          {...hoverListeners}
          onDoubleClick={() => {
            if (!joinByDoubleClick) return
            clearTimeout(timeOutId.current)
            joinHere()
            setSelectedId('')
          }}
        >
          <TitleNode
            dialog={dialog}
            active={isActive}
            isLobby={isLobby}
            hovered={hovered}
            compactMode={compactRoom}
            isAnyoneInside={isAnyoneInside}
            // fullMode={false}
            fullMode={fullMode}
            showMenu={isActive}
            onPress={
              !isAnyoneInside
                ? // because it's handled in dialog box
                  undefined
                : weHere
                ? isLobby
                  ? undefined
                  : rename
                : joinHere
            }
          >
            {dialogTitle}
          </TitleNode>

          {/* People */}
          {avatarsCount > 0 && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                // justifyContent: 'center',
                flexWrap: 'wrap',
                marginTop: 0,
                width: '100%',
              }}
            >
              {peopleNodes}
              {/* {hovered && !weHere && <PersonJoinButton dialogId={dialog.id} />} */}
            </View>
          )}
        </DialogBox>
      </View>
    )
  },
  isEqual,
)

export const DialogBox = ({
  children,
  outline,
  transparent,
  hovered,
  disabled,
  active,
  centered,
  isAnyoneInside,
  style,
  select,
  onDoubleClick,
  ...viewProps
}: {
  children: React.ReactNode
  // Lobby doesn't have bg and border
  /** @deprecated */
  transparent?: boolean
  outline?: boolean
  hovered: boolean
  disabled?: boolean
  isAnyoneInside?: boolean
  active: boolean
  centered?: boolean
  select?: boolean
  onDoubleClick?: () => void
} & PressableProps) => {
  const theme = useTheme()
  let [width, setWidth] = useState(116)
  let { hasCustomBackground } = useUiContext()

  return (
    <div onDoubleClick={onDoubleClick} style={{ height: '100%' }}>
      <Pressable
        onLayout={({ nativeEvent }) => {
          setWidth(nativeEvent.layout.width)
        }}
        style={[
          {
            // paddingHorizontal: horizontalPadding,
            minHeight: minDialogHeight,

            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // So title stays up even if height is animating
            justifyContent: 'flex-start',
            borderRadius: theme.borderRadius.larger,
            position: 'relative',
            borderWidth: dialogBoxBorderWidth,
            // @ts-ignore
            pointerEvents: 'auto',

            backgroundColor: isAnyoneInside
              ? 'rgba(255, 255, 255, 0.05)'
              : 'rgba(255, 255, 255, 0.03)',
            borderColor: active ? theme.colors.blue : `transparent`,
          },

          isAnyoneInside
            ? {
                borderColor: active
                  ? theme.colors.blue
                  : `rgba(255, 255, 255, 0.03)`,
              }
            : {
                // borderColor: `rgba(255, 255, 255, 0.02)`,
              },

          outline && {
            backgroundColor: 'transparent',
            borderColor: active ? '#6D7BFE' : undefined,
          },

          hovered &&
            !active && {
              borderColor: `rgba(255, 255, 255, 0.03)`,
            },

          hasCustomBackground && {
            backgroundColor: `rgba(255, 255, 255, 0.06)`,
          },

          typeof style === 'object' ? style : false,
          select && {
            backgroundColor: 'rgba(243, 249, 255, 0.08)',
            borderColor: 'rgba(255, 255, 255, 0.1)',
            borderWidth: 1,
          },
        ]}
        disabled={disabled}
        {...viewProps}
      >
        {/* Middle holder, just get width for enough person we can fit */}
        <View
          style={[
            {
              // Adopt
              // width: Math.floor(width / itemWidth) * itemWidth,
              width: '100%',
              paddingLeft: dilogPaddingLeft,
              alignItems: 'flex-start',
              flexGrow: 1,
              // Full dialog height
              // minHeight: dialogHeight - 2 /** borderwidth */,

              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',

              //@ts-ignore
              pointerEvents: 'auto',
            },
            centered && { justifyContent: 'center', alignItems: 'center' },
          ]}
        >
          {children}
        </View>
      </Pressable>
    </div>
  )
}

export const titleHeight = 28

export function TitleNode({
  children,
  isLobby,
  iconEmoji,
  active,
  hovered,
  dialog,
  compactMode,
  isAnyoneInside,
  fullMode,
  showMenu,
  onPress,
}: {
  children: string | null | undefined
  isLobby?: boolean
  active: boolean
  dialog: DialogInfo
  iconEmoji?: string | null
  isAnyoneInside?: boolean
  hovered?: boolean
  fullMode?: boolean
  compactMode?: boolean
  showMenu?: boolean
  onPress?: () => void
}) {
  let theme = useTheme()
  let hasTitle = !!children
  let hasIcon = !!dialog.iconEmoji || isLobby || active
  let smallIconWidth = 14
  // let bigIconWidth = 30
  let bigIconWidth = 24
  let showLockIcon = dialog.doorClosed && (dialog.avatars?.length || 0) > 0

  let { hasCustomBackground } = useUiContext()

  let [wrapperStyles, animate] = useSpring(
    {
      opacity: 1,
      config: {
        tension: 200,
        mass: 0.1,
        friction: 10,
      },
    },
    [],
  )

  useEffect(() => {
    if (!fullMode) {
      animate.set({ opacity: 1 })
      return
    }

    animate.set({ opacity: 0 })

    let t = setTimeout(() => {
      animate({ opacity: 1 })
    }, 180)

    return () => {
      clearTimeout(t)
    }
  }, [animate, fullMode])

  let titleEditable = !(isLobby || !active || fullMode)

  const isGameRoom = dialog?.title?.toLowerCase().includes('game') && active

  return (
    <a.View
      style={[
        fullMode
          ? {
              height: '100%',
            }
          : {
              height: titleHeight,
              paddingTop: 6,
            },
        {
          flexGrow: 0,
          flexShrink: 0,
          position: 'relative',
          width: '100%',
          paddingRight: 12,
        },

        wrapperStyles,
      ]}
    >
      <TouchableOpacity
        style={[
          {
            height: '100%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexGrow: 1,
            flexShrink: 0,
          },
          fullMode
            ? {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }
            : {
                flexDirection: 'row',
                alignItems: 'center',
                // justifyContent: 'center',
                justifyContent: 'flex-start',
                paddingLeft: 4,
              },
          ,
          compactMode && {
            justifyContent: 'center',
          },

          // @ts-ignore
          { cursor: titleEditable ? 'text' : 'default' },
        ]}
        activeOpacity={0.8}
        onPress={onPress}
        disabled={!isAnyoneInside}
      >
        {hasIcon && !isLobby && (
          <View
            style={[
              {
                width: smallIconWidth,
                height: smallIconWidth,
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: fullMode ? 0 : 4,
              },
              fullMode && {
                marginBottom: 4,
                width: bigIconWidth,
                height: bigIconWidth,
              },
            ]}
          >
            <RoomEditableEmoji
              dialog={dialog}
              editable={active}
              size={fullMode ? bigIconWidth : 18}
            />
          </View>
        )}

        <Text
          style={[
            {
              color:
                //active && hasTitle && isAnyoneInside
                hasTitle && active
                  ? theme.colors.secondaryText
                  : hasCustomBackground
                  ? theme.colors.secondaryText
                  : theme.colors.tertiaryText,
              fontSize: theme.fontSizes.normal,
              marginLeft: fullMode ? 0 : 2,
              marginTop: -2,
            },
            hasCustomBackground && {
              textShadowOffset: { width: 0, height: 1 },
              textShadowRadius: 2,
              textShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
          ]}
          numberOfLines={1}
        >
          {children || (active ? 'Set a topic' : '')}
        </Text>
      </TouchableOpacity>

      {/* Menu */}

      <View style={styles.roomMenuWrapper}>
        {showLockIcon ? (
          <Tooltip label="Room is locked">
            <LockIcon
              color={theme.colors.lockRoomIcon}
              width={18}
              height={18}
              style={{ marginTop: showMenu ? 0 : -2, marginRight: 2 }}
            />
          </Tooltip>
        ) : null}
        {dialog.silent ? (
          <Tooltip label="Silent (You can't talk in this room)">
            <MicrophoneIcon
              color={theme.colors.quaternaryLight}
              width={18}
              height={18}
              muted={true}
              style={{ marginTop: showMenu ? 0 : 3, marginRight: 2 }}
            />
          </Tooltip>
        ) : null}
        {isGameRoom && <OpenGameView currentDialog={dialog} />}
        {showMenu ? <RoomMenu currentDialog={dialog} /> : null}
      </View>
    </a.View>
  )
}

let styles = StyleSheet.create({
  roomMenuWrapper: {
    position: 'absolute',
    right: 3,
    top: 4,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})
