import React, { memo } from 'react'
import { View, Text, FlatList } from 'react-native'
import { useTheme } from '@there/components/feed/ThemeContext'
import { ConfigType, Emoji } from './use-picker'
import { EmojiButton } from './EmojiButton'

type Props = {
  emojis: Emoji[]
  onEmojiClick: (emoji: Emoji) => void
  config: ConfigType
}

export const SearchResult = memo(({ onEmojiClick, emojis, config }: Props) => {
  const theme = useTheme()
  const height =
    Math.ceil(emojis.length / config.emojiPerLine) *
    (config.emojiSize + config.emojiMargin)

  return (
    <View
      key="SearchResult"
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Text
        style={{
          fontSize: theme.fontSizes.normal,
          color: theme.colors.tertiaryText,
          marginLeft: 8,
        }}
      >
        Search Result
      </Text>

      <View
        style={{
          height,
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {emojis.map((emoji, index) => {
          const displayedEmoji = emoji
          return (
            <EmojiButton
              emoji={displayedEmoji}
              onPress={onEmojiClick}
              key={displayedEmoji.id}
              config={config}
            />
          )
        })}
      </View>
    </View>
  )
})
