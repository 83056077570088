import gql from 'graphql-tag'
import { NexusGenFieldTypes } from '@there/tower/generated/graphql'
import { memberInfoFragment, MemberInfo } from './memberInfo'
import { Override } from '@there/shared/types'

export const spaceMembersInfoFragment = gql`
  fragment spaceMembersInfo on Space {
    members {
      ...memberInfo
    }
  }
  ${memberInfoFragment}
`

/** @deprecated */
export type SpaceMembersInfo = Override<
  NexusGenFieldTypes['Space'],
  {
    members: MemberInfo[]
  }
>

export type { MemberInfo } from './memberInfo'
export type { MemberWithUserInfo as MemberWithUser } from './memberInfo'
