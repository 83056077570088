import { useImmerReducer } from 'use-immer'
import { Draft } from 'immer'
import React, { Dispatch } from 'react'
import { toGlobalId } from '@there/tower/utils/global-id'

export type State = {
  pendingMessageIds: string[]
}
export type LoadingState = State

export type DispatchType = Dispatch<Actions>

const initialState: State = {
  pendingMessageIds: [],
}

export type Actions =
  | { type: 'new message pending to send'; id: string }
  | {
      type: 'message sent'
      id: string
    }

function reducer(draft: Draft<State>, action: Actions): void | State {
  switch (action.type) {
    case 'new message pending to send':
      draft.pendingMessageIds.push(action.id)
      break
    case 'message sent':
      draft.pendingMessageIds = draft.pendingMessageIds.filter(
        (id) => id !== action.id,
      )
      break
  }
}

export const useChatLoading = (): [State, React.Dispatch<Actions>] => {
  return useImmerReducer<State, Actions>(reducer, initialState)
}
