import gql from 'graphql-tag'
import { NexusGenFieldTypes } from '@there/tower/generated/graphql'

export const userInfoFragment = gql`
  fragment userInfo on User {
    id
    email
    name
    nickname
    timezone
    profilePhoto
    pendingSetup
    emailVerified
    online
    lastOnline
    doorClosed
    updatedAt
    createdAt
  }
`
type Source = NexusGenFieldTypes['User']

export interface UserInfo {
  id: Source['id']
  email: Source['email']
  name: Source['name']
  nickname: Source['nickname']
  timezone: Source['timezone']
  profilePhoto: Source['profilePhoto']
  pendingSetup: Source['pendingSetup']
  emailVerified: Source['emailVerified']
  online: Source['online']
  lastOnline: Source['lastOnline']
  doorClosed: Source['doorClosed']
  updatedAt: Source['updatedAt']
  createdAt: Source['createdAt']
  __typename?: 'User'
}

// export type UserInfo = User
export type PartialUser = UserInfo

type MinUserSource = NexusGenFieldTypes['MinUser']
export interface MinUserInfo {
  id: MinUserSource['id']
  name: MinUserSource['name']
  nickname: MinUserSource['nickname']
  email: MinUserSource['email']
  profilePhoto: MinUserSource['profilePhoto']
  userId: MinUserSource['userId']
  pendingSetup: MinUserSource['pendingSetup']
}
