import { View, Text } from 'react-native'
import { ConfirmModalData, useModals } from '../feed/ModalsContext'
import { StandardButton } from '../main/StandardButton'
import { Space } from '../shared/Space'
import { ModalDescription } from './ModalDescription'
import { ModalTitle } from './ModalTitle'
import { ModalWrapper } from './ModalWrapper'

export const ConfirmModal = () => {
  let [modalsState, modalsDispatch] = useModals()
  const onClose = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'confirm' })
    modalData.onCancel()
  }
  let modalData = modalsState.modalsData['confirm'] as ConfirmModalData

  if (!modalData) return null
  return (
    <ModalWrapper
      isOpen={modalsState.modals.includes('confirm')}
      onClose={onClose}
    >
      <View
        style={{
          width: 215,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {modalData.alertIcon ? (
          <View style={{ marginBottom: 5 }}>{modalData.alertIcon}</View>
        ) : null}
        <ModalTitle align="center">{modalData.alertText}</ModalTitle>
        {modalData.alertDescription ? (
          <>
            <Space vertical={5} />
            <ModalDescription align="center">
              {modalData.alertDescription}
            </ModalDescription>
          </>
        ) : null}
        <Space vertical={10} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <StandardButton
            text={modalData.cancelLabel ? modalData.cancelLabel : 'Stay'}
            backgroundColor="buttonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={onClose}
          />
          <Space horizontal={10} />
          <StandardButton
            text={modalData.submitLabel ? modalData.submitLabel : 'Stay'}
            backgroundColor="primaryButtonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={() => {
              if (modalData) {
                modalData.onSubmit()
                modalsDispatch({ type: 'modal closed', modalName: 'confirm' })
              }
            }}
          />
        </View>
      </View>
    </ModalWrapper>
  )
}
