import { useState, useEffect, useRef, useMemo } from 'react'
import ms from 'ms'
import { useAtom } from 'jotai'
import { connectionStateAtom } from '@there/components/atoms'

export type ConnectionState = 'connecting' | 'connected' | 'disconnected'

const OFFLINE_DELAY = ms('1500 ms')

/**
 * ⚠️☣️👩‍🔬 Not intended for programmatic usage
 *
 * This is intentionally not accurate
 */
export function usePresentedConnectivity() {
  let { connectionState } = useConnectivity()
  const [online, setOnline] = useState<boolean>(
    typeof window !== 'undefined'
      ? window.navigator.onLine
      : // Fake
        true,
  )
  const offlineTimeoutId = useRef<number>()

  useEffect(() => {
    offlineTimeoutId.current && clearTimeout(offlineTimeoutId.current)
    if (connectionState === 'connected') {
      // Online
      setOnline(true)
    } else if (connectionState === 'disconnected') {
      // Offline
      offlineTimeoutId.current = setTimeout(() => {
        setOnline(false)
      }, OFFLINE_DELAY)
    }

    return () => {
      if (offlineTimeoutId.current) {
        clearTimeout(offlineTimeoutId.current)
      }
    }
  }, [connectionState])

  useEffect(() => {
    let timeoutId: number | undefined = undefined

    const wentOffline = () => {
      timeoutId && clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setOnline(false)
      }, OFFLINE_DELAY)
    }

    const wentOnline = () => {
      timeoutId && clearTimeout(timeoutId)
    }

    window.addEventListener('offline', wentOffline)
    window.addEventListener('online', wentOnline)

    return () => {
      timeoutId && clearTimeout(timeoutId)
      window.removeEventListener('offline', wentOffline)
      window.removeEventListener('online', wentOnline)
    }
  }, [])

  return online
}

export const useConnectivity = (input?: {
  /** Called when we switch from closed to open. Must be useCallback. */
  onReconnect?: () => void
}) => {
  let [connectionState] = useAtom(connectionStateAtom)

  let wasDisconnected = useRef(false)
  let onReconnect = input?.onReconnect

  useEffect(() => {
    if (!onReconnect) return

    if (!wasDisconnected.current && connectionState === 'disconnected') {
      wasDisconnected.current = true
      return
    }

    if (wasDisconnected.current && connectionState === 'connected') {
      onReconnect()
      // reset
      wasDisconnected.current = false
    }

    return () => {}
  }, [connectionState, onReconnect])

  return useMemo(() => ({ connectionState }), [connectionState])
}
