import type { FeatureNames } from '@prisma/client'
import { atom } from 'jotai'

type FeedMode = 'Observer' | 'Main'

export const feedModeAtom = atom<FeedMode>('Main')
export const feedOpenAtom = atom(true)
export const featurePreviewsAtom = atom<FeatureNames[]>([])
export const featurePreviewsLoadingAtom = atom<{
  initiallyFetching: boolean
}>({
  initiallyFetching: false,
})
