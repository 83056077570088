import { useNurMutation } from '@there/components/sun/use-mutation'
import type { AddStatusReactionMessage } from '@there/sun/modules/addStatusReaction'
interface Data {
  done: boolean
}

type RemoteVariables = AddStatusReactionMessage['payload']
export interface LocalAddStatusReactionMessageVariables
  extends RemoteVariables {
  __extra: {
    userId: string
  }
}

export const useAddStatusReaction = () => {
  return useNurMutation<Data, LocalAddStatusReactionMessageVariables>({
    method: 'addStatusReaction',
  })
}
