import React from 'react'

// @ts-ignore
export const WiFiOut = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-wifi-off"
    {...props}
  >
    <path d="M2.572 2.571L21.429 21.43M16.72 11.06c.82.4 1.585.9 2.28 1.49M5 12.55a10.94 10.94 0 015.17-2.39M10.71 5.05A16 16 0 0122.58 9M1.42 9a15.91 15.91 0 014.7-2.88M8.53 16.11a6 6 0 016.95 0M12 20h.01" />
  </svg>
)
