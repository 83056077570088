import { useSelect } from 'downshift'
import { Dropdown, DropdownItemType } from '@there/components/feed/Dropdown'
import { Space } from '@there/components/shared/Space'
import { WebStyled } from '@there/components/shared/WebStyled'
import { useRtcContext } from '../shared/use-rtc'
import {
  electronSupports,
  ipc,
  isElectron,
} from '@there/desktop/utils/electron-api'
import { useNudge } from '@there/components/feed/use-nudge'
import { UserVolumeManager } from '@there/components/feed/UserVolumeManager'

export const personDropdownWidth = 140
export const volumeSliderMargins = Dropdown.sidePadding * 2

type Props = {
  isUs: boolean
  userId: string
  children: React.ReactNode
}

export const PersonDropdown = (props: Props) => {
  let { children, isUs, userId } = props
  let { isParticipant, muteUser } = useRtcContext()
  const [, sendNudge] = useNudge({ recipientId: userId })

  let items: DropdownItemType[] = [
    {
      label: 'Mute',
      onClick: () => {
        if (isElectron) {
          if (electronSupports?.muteUser) {
            ipc?.invoke('rtc:user-muted', userId)
          } else {
            alert('Update the app from the ⚙️ menu to use.')
          }
        } else {
          muteUser(userId)
        }
      },
    },
    {
      label: 'Nudge',
      onClick: () => {
        sendNudge('👋')
      },
    },
  ]

  let {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    closeMenu,
    openMenu,
  } = useSelect({
    items: items,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          }
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.MenuBlur:
          return {
            ...changes,
            isOpen: true,
          }
      }
      return changes
    },
  })

  const onContextMenu = () => {
    if (isUs) return
    if (!isParticipant(userId)) return
    openMenu()
  }

  return (
    <Dropdown.Root>
      <WebStyled>
        <Dropdown.Toggle
          toggleProps={getToggleButtonProps}
          onContextMenu={onContextMenu}
        >
          {children}
        </Dropdown.Toggle>
      </WebStyled>
      <Dropdown.Menu
        {...getMenuProps(undefined, { suppressRefError: true })}
        isOpen={isOpen}
        onClose={closeMenu}
        width={personDropdownWidth}
      >
        <Dropdown.Section>
          <Space vertical={10} />
          <Dropdown.Inner>
            <UserVolumeManager userId={userId} />
          </Dropdown.Inner>
          <Space vertical={6} />
          {items.map((item, index) => {
            let highlighted =
              item.onClick && !item.disabled && highlightedIndex === index
            return (
              <Dropdown.Item
                key={item.label}
                isDim={!item.onClick || item.disabled}
                highlighted={highlighted}
                {...getItemProps({
                  item,
                  index,
                })}
                onClick={(event) => {
                  if (item.onClick) {
                    event.stopPropagation()
                    item.onClick()
                    closeMenu()
                  }
                }}
              >
                {item.label}
              </Dropdown.Item>
            )
          })}
          <Space vertical={4} />
        </Dropdown.Section>
      </Dropdown.Menu>
    </Dropdown.Root>
  )
}
