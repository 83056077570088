import { a, useTransition } from '@react-spring/native'
import { useModals } from '@there/components/feed/ModalsContext'
import { useTheme } from '@there/components/feed/ThemeContext'
import { EmojiView } from '@there/components/main/EmojiView'
import { useAppContext } from '@there/components/shared/AppContext'
import { GoodButton } from '@there/components/shared/GoodButton'
import { Tooltip } from '@there/components/shared/Tooltip'
import React, { useCallback, useEffect, useRef, useState } from 'react'

export const NudgeButton = (props: {
  disabled?: boolean
  show: boolean
  isUs?: boolean
  delay?: number
  sendNudge: (emoji: string) => void
}) => {
  let theme = useTheme()
  let audioRef = useRef<HTMLAudioElement | null>(null)
  let [pressed, togglePressed] = useState(false)

  useEffect(() => {
    let audioElement = audioRef.current

    if (!audioElement) {
      audioRef.current = audioElement = new Audio()
    }
    audioElement.src = '/tones/nudge.mp3'
    audioElement.volume = 0.5
  }, [])

  // Show with delay
  let [delayedShow, setDelayedShow] = useState(false)
  useEffect(() => {
    if (!props.show) {
      setDelayedShow(false)
    } else {
      let timeout = setTimeout(() => {
        setDelayedShow(true)
      }, props.delay)

      return () => clearTimeout(timeout)
    }
  }, [props.delay, props.show])

  let show = props.delay ? delayedShow : props.show

  let renderTransition = useTransition(show, {
    enter: {
      scale: 1,
      opacity: 1,
      y: 0,
      delay: 30,
    },
    from: {
      scale: 0.4,
      opacity: 0.1,
      y: -6,
    },
    leave: {
      scale: 0.3,
      opacity: 0,
      y: -6,
    },
    config: {
      tension: 180,
      friction: 10,
      mass: 0.1,
    },
  })

  let [, modalsDispatch] = useModals()
  let { nudgeLearned } = useAppContext()
  const nudgeLearning = useCallback(() => {
    if (nudgeLearned) return
    modalsDispatch({ type: 'modal opened', modalName: 'nudgeLearning' })
  }, [modalsDispatch, nudgeLearned])

  return renderTransition(({ scale, opacity, y }, item) =>
    item ? (
      <Tooltip
        label={
          props.isUs ? (
            <>
              Try nudge to see <br />
              how it looks like!
            </>
          ) : (
            'Nudge'
          )
        }
        placement="bottomRight"
      >
        <a.View style={{ opacity, transform: [{ translateY: y }, { scale }] }}>
          <GoodButton
            icon={<EmojiView size={19}>👋</EmojiView>}
            variant="normal"
            disabled={props.disabled}
            onPress={() => {
              if (audioRef && audioRef.current) {
                audioRef.current.play()
              }
              props.sendNudge('👋')
              nudgeLearning()
            }}
          />
        </a.View>
      </Tooltip>
    ) : null,
  )
}
