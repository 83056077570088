import { useAtom } from 'jotai'
import { useCallback, useMemo } from 'react'
import { currentDialogIdAtom, lobbyDialogIdAtom } from '../feed/useLobbyMan'
import { useSoundEffect } from '../feed/useSoundEffect'
import { useAppContext } from '../shared/AppContext'
import { currentDialogLockStatusAtom } from '../shared/spaceContext/context'
import { useEditDialog } from './useEditDialog'

export const useLockDialog = () => {
  let [playLock] = useSoundEffect('/tones/lock.mp3')
  let [playUnlock] = useSoundEffect('/tones/unlock.mp3')
  let [, editDialog] = useEditDialog()
  let [currentDialogId] = useAtom(currentDialogIdAtom)
  let { activeSpaceId } = useAppContext()
  let [currentDialogLockStatus] = useAtom(currentDialogLockStatusAtom)
  let [lobbyDialogId] = useAtom(lobbyDialogIdAtom)

  let hasLockPermission = useMemo(() => {
    let isLobby = lobbyDialogId === currentDialogId
    return !isLobby
  }, [currentDialogId, lobbyDialogId])

  let toggleLockDialog = useCallback(
    (lock) => {
      if (!activeSpaceId) return
      if (!currentDialogId) return
      editDialog({
        id: currentDialogId,
        doorClosed: lock,
        __extra: { spaceId: activeSpaceId },
      })
      if (lock) {
        playLock()
      } else {
        playUnlock()
      }
    },
    [activeSpaceId, currentDialogId, editDialog, playLock, playUnlock],
  )

  return { toggleLockDialog, currentDialogLockStatus, hasLockPermission }
}
