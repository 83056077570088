import { BackgroundHooks } from '@there/components/feed/BackgroundHooks'
import { DevFeedWrapper } from '@there/components/feed/Devtools'
import { FeedSkeleton } from '@there/components/feed/FeedSkeleton'
import {
  ModalsContext,
  useCreateModalsManager,
} from '@there/components/feed/ModalsContext'
import { Portal } from '@there/components/shared/Portal'
import { ServiceWorker } from '@there/components/shared/ServiceWorker'
import { useCreateGraphqlClient } from '@there/components/urql/client'
import React, { ReactNode } from 'react'
import { Provider as UrqlProvider } from 'urql'
import { useIsAuthenticatedPage } from '../shared/useIsAuthenticatedPage'
import { useIsFeed } from '../shared/useIsFeed'
import { NurProvider } from './NurProvider'
export function MainPagesWrapper({ children }: { children: ReactNode }) {
  // ----------------- from APP
  const { client } = useCreateGraphqlClient()
  // -----------------

  const modalsContext = useCreateModalsManager()

  let isAuthenticated = useIsAuthenticatedPage()

  if (!client) {
    return (
      <>
        <FeedSkeleton />
        <Portal />
      </>
    )
  }

  // let NurProviderOrFragment = isAuthenticated ? NurProvider : React.Fragment
  return (
    <>
      <UrqlProvider value={client}>
        <ServiceWorker>
          <ModalsContext.Provider value={modalsContext}>
            <>
              <DevFeedWrapper>
                {isAuthenticated ? (
                  <NurProvider>{children}</NurProvider>
                ) : (
                  children
                )}

                {/* <NurProviderOrFragment>{children}</NurProviderOrFragment> */}
                <BackgroundHooks />
              </DevFeedWrapper>
            </>
          </ModalsContext.Provider>
        </ServiceWorker>
      </UrqlProvider>

      <Portal />
    </>
  )
}
export const MainContextWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  let isFeed = useIsFeed()

  if (isFeed) {
    return (
      <>
        <MainPagesWrapper>{children}</MainPagesWrapper>
      </>
    )
  } else {
    return <>{children}</>
  }
}
