import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export const CustomReactionIcon = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" viewBox="0 0 20 20" {...props}>
    <Path
      d="M6.316 6.579a1.579 1.579 0 0 0-1.14 2.707 1.579 1.579 0 1 0 1.14-2.707Zm6.316 0a1.578 1.578 0 0 0-1.491.966 1.578 1.578 0 0 0 0 1.225 1.579 1.579 0 0 0 3.034-.612 1.58 1.58 0 0 0-1.543-1.579Zm-.607 6.316c-.547.943-1.476 1.579-2.644 1.579-1.166 0-2.096-.636-2.642-1.58h5.286Zm2.186-1.58H4.737a4.737 4.737 0 0 0 9.474 0Z"
      fill={props.color ? props.color : '#000'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.395 2.763V.921a.921.921 0 0 1 .92-.921.921.921 0 0 1 .922.921v1.842h1.842a.921.921 0 0 1 0 1.842h-1.842v1.842a.921.921 0 0 1-.921.921.921.921 0 0 1-.921-.92V4.604h-1.842a.921.921 0 0 1 0-1.842h1.842Z"
      fill={props.color ? props.color : '#000'}
    />
    <Path
      d="M0 10.526c0-4.966 3.822-9.04 8.684-9.441.435-.036.79.321.79.757s-.355.785-.789.829c-3.984.396-7.106 3.768-7.106 7.855 0 4.354 3.542 7.895 7.895 7.895 4.087 0 7.459-3.122 7.855-7.106.044-.434.393-.789.829-.789s.793.355.757.79A9.474 9.474 0 0 1 0 10.525Z"
      fill={props.color ? props.color : '#000'}
    />
  </Svg>
)
