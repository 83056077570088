import React, { memo } from 'react'
import { useSpring } from '@react-spring/native'
import { AnimatedView } from '@there/components/shared/AnimatedView'

export const PuffSpinner = memo(
  ({ size = 50, color }: { size: number; color?: string }) => {
    // @ts-ignore
    let styleProps: { opacity: number; diameter: number } = useSpring({
      from: { opacity: 1, diameter: 0 },
      to: { opacity: 0, diameter: size },
      config: { mass: 1.2, tension: 300, friction: 50 },
      loop: true,
    })

    return (
      <>
        <AnimatedView
          style={[
            {
              borderWidth: size / 5,
              borderColor: color ? color : 'rgba(255,255,255,0.4)',
              backgroundColor: color ? color : `rgba(255,255,255,0.1)`,
              borderRadius: size,
              height: styleProps.diameter,
              opacity: styleProps.opacity,
              width: styleProps.diameter,
              zIndex: 1,
            },
          ]}
        >
          <></>
        </AnimatedView>
      </>
    )
  },
)

// static style = ({ props }) => ({
//   border: "10px solid black",
//   borderRadius: "100%",
//   height: props.diameter,
//   left: "50%",
//   opacity: props.opacity,
//   position: "absolute",
//   top: "50%",
//   transform: "translateX(-50%) translateY(-50%)",
//   width: props.diameter,
//   zIndex: 1
// });
//     <AnimatedView
//       reset
//       from={{ opacity: 1, diameter: 0 }}
//       to={{ opacity: 0, diameter: 100 }}
//       onRest={this.handleRest}
//     >
//       {props => <div style={Loading.style({ props })} />}
//     </Spring>
