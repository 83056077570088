import { ipc } from '@there/desktop/utils/electron-api'
import { useSelect } from 'downshift'
import React, { useEffect } from 'react'
import { Dropdown } from '../feed/Dropdown'
import { useAppContext } from '../shared/AppContext'
import { Space } from '../shared/Space'
import { logEvent } from '../shared/use-analytics'
import {
  ShortcutItemType,
  toReadableShortcut,
  useShortcutManager,
} from '../shared/use-shortcut-manager'
import {
  Check,
  DropdownButtonWrapper,
  DropdownIcon,
  DropdownLabel,
  Label,
  Row,
  Section,
  SectionHeader,
  Separator,
} from './SettingsUI'
import { ToggleRow } from './ToggleRow'

export const ShortcutsSection = () => {
  let {
    shortcutPicked,
    state: { keymap },
    defaultComboOptions,
  } = useShortcutManager()

  let { onlineFirstSide, dispatch } = useAppContext()

  return (
    <>
      <Section>
        <SectionHeader>General</SectionHeader>
        <Space vertical={8} />
        <ToggleRow
          title="Sort sidebar based on online"
          description="By default we show recent chats at the top. Enable to see online users first."
          value={!!onlineFirstSide}
          onValueChange={(value) => {
            dispatch({ type: 'online first changed', value })
          }}
        />
      </Section>

      <Space vertical={18} />

      <Section>
        <SectionHeader>Shortcuts</SectionHeader>
        <Space vertical={8} />

        <ShortcutPickerRow
          label="Talk / Mute"
          selected={keymap['toggleMicrophone']}
          onChange={(selected) => {
            shortcutPicked('toggleMicrophone', selected)
            logEvent('User Changed Mic Shortcut')
          }}
          items={defaultComboOptions}
        />

        <Space vertical={8} />
        <ShortcutPickerRow
          label="Open / Leave"
          selected={keymap['toggleVoice']}
          onChange={(selected) => shortcutPicked('toggleVoice', selected)}
          items={defaultComboOptions}
        />
        <Space vertical={8} />
        <ShortcutPickerRow
          label="Toggle Camera"
          selected={keymap['toggleCamera']}
          onChange={(selected) => shortcutPicked('toggleCamera', selected)}
          items={defaultComboOptions}
        />
        <Space vertical={8} />
        <Separator />
      </Section>
    </>
  )
}

export function useSyncKeymapOnStartup() {
  useEffect(() => {
    let raw = localStorage.getItem('keyMap')
    if (raw) {
      try {
        let keymap = JSON.parse(raw)
        ipc?.invoke('there:keymap-updated', keymap)
      } catch (error) {}
    }
  }, [])
}

const ShortcutPickerRow = ({
  label,
  selected,
  items,
  onChange,
}: {
  label: string
  selected: string | null | undefined
  items: ShortcutItemType[]
  onChange: (selected: string | null | undefined) => void
}) => {
  const {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    toggleMenu,
    closeMenu,
  } = useSelect({
    items,
    defaultSelectedItem: selected,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem)
    },
  })

  return (
    <Row>
      <Label>{label}</Label>
      <Dropdown.Root>
        <Dropdown.Toggle>
          <DropdownButtonWrapper width={140} {...getToggleButtonProps()}>
            <DropdownLabel>
              {selected ? toReadableShortcut(selected) : 'None'}
            </DropdownLabel>
            <DropdownIcon>
              <svg width={8} height={4} fill="none" viewBox="0 0 8 4">
                <path d="M4 4L0 0h8L4 4z" fill="#B3B3B3" />
              </svg>
            </DropdownIcon>
          </DropdownButtonWrapper>
        </Dropdown.Toggle>

        <Dropdown.Menu
          {...getMenuProps(undefined, { suppressRefError: true })}
          isOpen={isOpen}
          onClose={closeMenu}
          width={160}
        >
          <Dropdown.Section>
            <Space vertical={4} />
            {items.map((item, index) => {
              return (
                <React.Fragment key={String(item) + index}>
                  <Dropdown.Item
                    {...getItemProps({
                      item,
                      index,
                      title: item || 'None selected',
                    })}
                    selected={selected === item}
                    highlighted={highlightedIndex === index}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item ? toReadableShortcut(item) : 'None'}
                      </div>
                      {selected === item && <Check />}
                    </div>
                  </Dropdown.Item>
                  <Space vertical={1} />
                </React.Fragment>
              )
            })}
            <Space vertical={3} />
          </Dropdown.Section>
        </Dropdown.Menu>
      </Dropdown.Root>
    </Row>
  )
}
