import { useMemo } from 'react'
import { usersAtom } from '@there/components/shared/UsersContext'
import { UserInfo } from '@there/sun/utils/node-types'
import { useAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import isEqual from 'react-fast-compare'

export function useUser(userId: string): UserInfo | undefined {
  let userAtom = useMemo(
    () => selectAtom(usersAtom, (users: any) => users[userId], isEqual),
    [userId],
  )
  let [user] = useAtom(userAtom)
  return user
}
