import { NIGHTLY_APP_URL } from '@there/components/config'

export const usesOldDomain = () => {
  if (typeof window === 'undefined') return
  return window.location.host.endsWith('there.so')
}

export const isOnNightly = () => {
  if (typeof window === 'undefined') return
  return window.location.href.includes(NIGHTLY_APP_URL)
}
