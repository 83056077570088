import { useNurMutation } from '@there/components/sun/use-mutation'
import { EditTopicMessage, EditTopicResult } from '@there/sun/modules/editTopic'

type Variables = EditTopicMessage['payload']

export const useEditTopic = () => {
  return useNurMutation<EditTopicResult, Variables>({
    method: 'editTopic',
  })
}
