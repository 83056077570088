import React, {
  useReducer,
  useRef,
  useEffect,
  memo,
  useCallback,
  useMemo,
} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native'
import styled, { css } from 'styled-components'
import { truncate } from '@there/components/utils/css-snippets'
import { logEvent } from '../shared/use-analytics'
import { isBeta } from '@there/desktop/utils/electron-api'
import { Space } from '../shared/Space'
import { useSelect } from 'downshift'
import { useAppContext } from '../shared/AppContext'
import { Dropdown } from './Dropdown'
import { useCreateSpaceMutation } from '@there/components/urql/createSpace'
import { useUpdateSpaceMutation } from '@there/components/urql/updateSpace'
import { useLogOutAction } from './BackgroundHooks/use-logout'
import { ToggleArrowIcon } from './HeaderIcons'
import { useMainWindowContext } from '../shared/use-main-window'
import { TransparentButton } from '../shared/TransparentButton'
import { useTheme } from './ThemeContext'
import { MemberWithSpaceInfo } from '@there/components/urql/fragments/memberInfo'
import { useChangeSpace } from '@there/components/urql/changeSpace'
import { Tooltip } from '../shared/Tooltip'
import isEqual from 'react-fast-compare'
import { useModals } from './ModalsContext'
import { currentDialogIdAtom, useLobbyMan } from './useLobbyMan'
import { useAtom } from 'jotai'
import { useLeaveDialog } from '../main/useLeaveDialog'
import { NoorLogo } from './NoorLogo'
import { useUiContext } from '../shared/UiContext'
import { useProSpace } from '@there/components/pro/useProSpace'
import { spaceLogos } from '../pro/SpaceLogos'
import { useLeaveSpace } from '@there/components/main/useLeaveSpace'

type Props = {
  memberships: Array<MemberWithSpaceInfo> | undefined
  currentMember: MemberWithSpaceInfo | undefined
  smaller?: boolean
}

let leftPadding = 6

export const SpaceManager = memo(({ memberships, currentMember }: Props) => {
  const t = useTheme()
  const { dispatch } = useAppContext()

  const inputRef = useRef<TextInput>(null)

  const [spaceState, spaceDispatch] = useReducer(
    spaceReducer,
    spaceInitialState,
  )

  const [, createSpace] = useCreateSpaceMutation()
  const [, updateSpace] = useUpdateSpaceMutation()

  const spaceSubmitted = async (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    event.preventDefault()

    try {
      if (spaceState.creatingMode) {
        // Create a new space...
        createSpace({
          name: spaceState.spaceName,
        }).then((res) => {
          if (!res.data || !res.data.user) {
            throw new Error('user wan not returned')
          }

          let memberships = res.data.user.memberships || []

          let newMembership = memberships.find(
            (membership) => membership.space?.name === spaceState.spaceName,
          )

          if (!newMembership) {
            throw new Error('new membership was not returned.')
          }

          let spaceId = newMembership.space?.id
          let spaceName = newMembership.space?.name
          let memberId = newMembership?.id

          dispatch({
            type: 'active space changed',
            spaceId,
            memberId,
            spaceName: spaceName || '',
          })

          logEvent('User Created a Space', {
            name: spaceState.spaceName,
            from: 'space manager',
          })
        })
      } else if (spaceState.editing) {
        // Edit current space's name
        await updateSpace({
          spaceId: spaceState.editing,
          name: spaceState.spaceName,
        })
        logEvent('User Changed a SpaceName', {
          name: spaceState.spaceName,
          from: 'space manager',
        })
      }

      spaceDispatch({ type: 'succeeded' })
    } catch (error) {
      console.error(error)
      spaceDispatch({ type: 'failed' })
    }
  }

  const keyDowned = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    if (event.nativeEvent.key === 'Escape') {
      spaceDispatch({ type: 'cancelled' })
    }
    if (event.nativeEvent.key === 'Enter') {
      spaceSubmitted(event)
    }
  }

  useEffect(() => {
    if (spaceState.creatingMode) {
      inputRef.current?.focus()
    }
  }, [spaceState.creatingMode])

  const create = (
    <View
      style={[
        styles.spaceInputBox,
        {
          backgroundColor: t.colors.transparentInputBackground,
          borderRadius: t.borderRadius.large,
          shadowColor: t.colors.quinaryLight,
        },
      ]}
    >
      <TextInput
        style={[
          styles.spaceInput,
          {
            fontSize: t.fontSizes.large,
            fontWeight: 'normal',
            color: t.colors.secondaryText,
          },
        ]}
        ref={inputRef}
        value={spaceState.spaceName}
        autoFocus={true}
        multiline={false}
        editable={!spaceState.submitting}
        onChangeText={(text) =>
          spaceDispatch({
            type: 'space name changed',
            name: text,
          })
        }
        onBlur={() => {
          spaceDispatch({ type: 'cancelled' })
        }}
        onKeyPress={keyDowned}
        placeholder="Type a name..."
      />
    </View>
  )

  if (typeof memberships === 'undefined' || memberships === null) {
    return null
  }

  if (memberships.length === 0) {
    return create
  }

  return spaceState.creatingMode || spaceState.editing ? (
    create
  ) : (
    <SpaceManagerDropdown
      memberships={memberships}
      currentMember={currentMember}
      spaceDispatch={spaceDispatch}
    />
  )
}, isEqual)

SpaceManager.displayName = 'SpaceManager'

const SpaceManagerDropdown = memo(function SpaceManagerDropdown({
  memberships,
  currentMember,
  spaceDispatch,
}: {
  memberships: Array<MemberWithSpaceInfo> | undefined
  currentMember: MemberWithSpaceInfo | undefined
  spaceDispatch: React.Dispatch<SpaceAction>
}) {
  const t = useTheme()
  const { activeSpaceId, dispatch, avatarId, currentUserId } = useAppContext()
  const { dispatch: windowDispatch } = useMainWindowContext()
  const logOut = useLogOutAction()
  const [, changeSpace] = useChangeSpace()
  let [, modalsDispatch] = useModals()
  let { hasCustomBackground } = useUiContext()
  let [, doLeaveSpace] = useLeaveSpace()

  let [currentDialogId] = useAtom(currentDialogIdAtom)
  let [, leaveDialog] = useLeaveDialog()

  const { items, currentSpaceItem, settingItems } = useMemo(() => {
    const mainSpaceItems = memberships
      ? memberships.map(({ space, id }) => {
          return {
            id: space.id,
            name: space.name,
            type: 'Member',
            memberId: id,
          }
        })
      : []

    const currentSpaceAsItem = mainSpaceItems.find(
      (item) => item.id === activeSpaceId,
    )

    const settingItems = currentSpaceAsItem
      ? [
          {
            id: 'rename',
            name: `Rename '${currentSpaceAsItem?.name || 'Your space'}'`,
            type: 'Action',
            memberId: currentSpaceAsItem.memberId,
          },
          {
            id: 'create',
            name: 'Create a space',
            type: 'Action',
          },
          {
            id: 'leave',
            name: 'Leave Space',
            type: 'Action',
            memberId: currentSpaceAsItem.memberId,
          },
        ]
      : []

    let items = [...settingItems, ...mainSpaceItems]

    return { items, currentSpaceItem: currentSpaceAsItem, settingItems }
  }, [activeSpaceId, memberships])

  const rename = useCallback(() => {
    if (!currentMember) {
      return
    }

    spaceDispatch({
      type: 'switch to editing',
      id: currentMember.space.id,
      currentName: currentMember.space.name,
    })
  }, [currentMember, spaceDispatch])

  const leaveSpace = useCallback(
    (memberId: string) => {
      if (!memberships) return
      let alternativeMemberships = memberships.filter(
        (membership) => membership.id !== memberId,
      )
      if (!alternativeMemberships || alternativeMemberships.length === 0) {
        // go to create or join space
        location.assign('/login/space')
        return
      }

      //do leave space
      doLeaveSpace({ memberId })

      //switch to alter space
      changeSpace({ spaceId: alternativeMemberships[0].space.id })
    },
    [changeSpace, doLeaveSpace, memberships],
  )

  const {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    toggleMenu,
    closeMenu,
  } = useSelect({
    items,
    selectedItem: currentSpaceItem,
    itemToString: (item) => (item && item.id) || '',
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return
      //rename
      if (selectedItem.id === 'rename' && selectedItem.type === 'Action') {
        rename()
        return
      }

      //logout
      if (selectedItem.id === 'logout' && selectedItem.type === 'Action') {
        logOut()
        return
      }

      if (selectedItem.id === 'create' && selectedItem.type === 'Action') {
        // Go to create-space
        spaceDispatch({ type: 'switch to creating' })
        return
      }

      //settings
      if (selectedItem.id === 'settings' && selectedItem.type === 'Action') {
        windowDispatch({ type: 'settings state changed', state: true })
        return
      }

      if (selectedItem.id === 'leave' && selectedItem.type === 'Action') {
        if (!selectedItem.memberId) return
        modalsDispatch({
          type: 'modal opened',
          modalName: 'leaveSpace',
          modalData: {
            spaceName: currentSpaceItem?.name,
            onSubmit: () => {
              leaveSpace(selectedItem.memberId)
              logEvent('User Left a Space')
            },
          },
        })
        return
      }

      console.info('Switching space')

      changeSpace({
        spaceId: selectedItem.id,
      }).catch((error) => {
        console.error('failed to submit changeSpace', error)
      })

      if (currentDialogId) {
        modalsDispatch({
          type: 'modal opened',
          modalName: 'changeSpace',
          modalData: {
            newSpace: selectedItem.name,
            onSubmit: () => {
              if (!avatarId) return
              if (!activeSpaceId) return
              if (!currentUserId) return

              leaveDialog({
                spaceId: activeSpaceId,
                __extra: {
                  avatarId,
                  dialogId: currentDialogId,
                  spaceId: activeSpaceId,
                  userId: currentUserId,
                },
              })
              logEvent('User Left a Room', { from: 'Hibernate' })

              setTimeout(() => {
                dispatch({
                  type: 'active space changed',
                  spaceId: selectedItem.id,
                  spaceName: selectedItem.name,
                  // BUG
                  memberId: selectedItem.memberId || '',
                })
              }, 80)
            },
          },
        })
      } else {
        dispatch({
          type: 'active space changed',
          spaceId: selectedItem.id,
          spaceName: selectedItem.name,
          // BUG
          memberId: selectedItem.memberId || '',
        })
      }

      logEvent('User Switched Space', {
        spaceName: selectedItem.name,
        from: 'space manager',
      })
    },
  })

  let { isProSpace } = useProSpace(activeSpaceId)

  return (
    <Dropdown.Root>
      <Dropdown.Toggle onPress={toggleMenu}>
        <TransparentButton size={22} options={() => getToggleButtonProps()}>
          <Tooltip
            label={
              <>
                <span>Manage your space or </span>
                <br />
                <span>switch between spaces</span>
              </>
            }
            placement="bottomRight"
          >
            <View style={styles.spacePicker}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {activeSpaceId && isProSpace && spaceLogos[activeSpaceId] && (
                  <>
                    {spaceLogos[activeSpaceId]}
                    <Space horizontal={4} />
                  </>
                )}
                <Text
                  style={[
                    styles.spaceName,
                    {
                      fontSize: t.fontSizes.large,
                      fontWeight: 'bold',
                      color: t.colors.spaceName,
                      lineHeight: 15,
                      marginTop: 1,
                      maxWidth: 95,
                    },
                    hasCustomBackground && {
                      textShadowOffset: { width: 0, height: 1 },
                      textShadowRadius: 2,
                      textShadowColor: 'rgba(0, 0, 0, 0.25)',
                    },
                  ]}
                  numberOfLines={1}
                >
                  {currentMember ? currentMember.space.name : 'Pick a space'}
                </Text>
                <ToggleArrowIcon
                  color={t.colors.tertiaryLight}
                  style={{ marginLeft: 6 }}
                  height={11}
                />
              </View>
            </View>
          </Tooltip>
        </TransparentButton>
        {/* <Text
          style={[
            styles.spaceSubtitle,
            {
              fontSize: t.fontSizes.small,
              marginTop: -2,
              color: t.colors.quaternaryText,
              marginLeft: leftPadding,
            },
          ]}
        >
          {isProSpace ? 'Pro' : 'Free'}
        </Text> */}
      </Dropdown.Toggle>

      <Dropdown.Menu
        {...getMenuProps({}, { suppressRefError: true })}
        isOpen={isOpen}
        onClose={closeMenu}
        width={160}
      >
        <Dropdown.Section>
          <Space vertical={9} />
          <Dropdown.Head>Manage</Dropdown.Head>
          <Space vertical={4} />
          {items.map((item, index) => {
            return (
              <React.Fragment key={item.id + index}>
                {index === settingItems.length && (
                  <>
                    <Space vertical={9} />
                    <Dropdown.Head>Switch</Dropdown.Head>
                    <Space vertical={4} />
                  </>
                )}
                <Dropdown.Item
                  highlighted={highlightedIndex === index}
                  {...getItemProps({
                    item,
                    index,
                    title: item.name,
                  })}
                >
                  {item.name}
                </Dropdown.Item>
              </React.Fragment>
            )
          })}
          <Space vertical={4} />
        </Dropdown.Section>
      </Dropdown.Menu>
    </Dropdown.Root>
  )
},
isEqual)

const styles = StyleSheet.create({
  spacePicker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: leftPadding,
  },
  spaceName: {
    minWidth: 0,

    fontWeight: 'bold',
    lineHeight: 18,
  },
  spaceSubtitle: {},

  spaceInputBox: {
    maxWidth: 120,
    height: 26,
    paddingHorizontal: 6,
    shadowRadius: 2,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    elevation: 2,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spaceInput: {
    maxWidth: '100%',
    height: 26,
    backgroundColor: 'none',
    borderWidth: 0,
  },
})

export const SpaceInput = styled.input`
  max-width: 100px;
  min-width: 0;
  width: 100%;
  height: 26px;
  margin-left: 3px;
  border-radius: ${(p) => p.theme.borderRadius.small}px;
  border: none;
  background: none;
  flex-shrink: 1;
  font-size: ${(p) => p.theme.fontSizes[15]}px;
  font-weight: ${(p) => p.theme.fontWeights.bolder};
  color: ${(p) => p.theme.colors.text};
  outline: none;
  text-align: center;
  &::placeholder {
    color: ${(p) => p.theme.colors.tertiaryText};
  }
`

export const SpacePicker = styled.div<{ active?: boolean }>`
  position: relative;
  min-width: 0;
  height: 26px;
  padding: 6px 6px;
  border-radius: ${(p) => p.theme.borderRadius.large}px;
  background: ${(p) => (p.active ? '#eaeaea' : 'none')};
  font-size: ${(p) => p.theme.fontSizes['normal']}px;
  font-weight: ${(p) => p.theme.fontWeights.bolder};
  color: ${(p) => p.theme.colors.text};
  line-height: 18px;
  letter-spacing: 0.01em;
  transition: ${(p) => p.theme.transition.colors};
  display: flex;
  align-items: center;
  flex-shrink: 1;
  -webkit-app-region: no-drag;
  svg {
    margin-left: 8px;
    color: ${(p) => p.theme.colors.secondaryText};
    /* opacity: 0.5; */
  }
  :hover {
    background: ${(p) => p.theme.colors.buttonBackground};
  }
  :active {
    background: #e0e0e0;
  }
  ${(p) =>
    p.active &&
    css`
      color: #fff;
    `}
`

export const SpaceName = styled.div<{ smaller?: boolean }>`
  flex-shrink: 1;
  min-width: 0;
  letter-spacing: 0.01em;
  font-size: ${(p) => p.theme.fontSizes[15]}px;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  color: ${(p) => p.theme.colors.secondaryText};
  ${truncate};
`
export const ClearButton = styled.div``

type SpaceState = {
  spaceName: string
  creatingMode: boolean
  submitting: boolean
  editing: string | undefined
}

const spaceInitialState = {
  spaceName: '',
  creatingMode: false,
  submitting: false,
  editing: undefined,
}

type SpaceAction =
  | { type: 'switch to creating' }
  | { type: 'cancelled' }
  | { type: 'user submitted' }
  | { type: 'succeeded' }
  | { type: 'failed' }
  | { type: 'space name changed'; name: string }
  | {
      type: 'switch to editing'
      id: string
      currentName: string
    }

function spaceReducer(state: SpaceState, action: SpaceAction): SpaceState {
  switch (action.type) {
    case 'switch to creating':
      return { ...state, creatingMode: true }

    case 'cancelled':
      return {
        ...state,
        creatingMode: false,
        spaceName: '',
        editing: undefined,
      }

    case 'user submitted':
      return { ...state, submitting: true }

    case 'succeeded':
      return {
        ...state,
        submitting: false,
        creatingMode: false,
        spaceName: '',
        editing: undefined,
      }

    case 'failed':
      return { ...state, submitting: false }

    case 'space name changed':
      return { ...state, spaceName: action.name }

    case 'switch to editing':
      return {
        ...state,
        spaceName: action.currentName,
        editing: action.id,
        creatingMode: false, // cannot have both at the same time
      }
  }
  return state
}

export function getJoinLink(space: { joinSecretCode: string }) {
  return `https://usenoor.com/join/${space.joinSecretCode}${
    isBeta ? `?beta=1` : ''
  }`
}
