import { LocalSendNewMessageVariables } from '@there/components/main/useSendNewMessage'
import { Cache } from '@there/components/sun/utils/types'
import { AddStatusResult } from '@there/sun/modules/addStatus'
import { AddStatusReactionResult } from '@there/sun/modules/addStatusReaction'
import { ChatQueryReply } from '@there/sun/modules/chat'
import {
  DeleteStatusMessage,
  DeleteStatusResult,
} from '@there/sun/modules/deleteStatus'
import { DismissNotificationResult } from '@there/sun/modules/dismissNotification'
import {
  EditChatMessageSimpleMessage,
  EditChatMessageSimpleReply,
} from '@there/sun/modules/editChatMessageSimple'
import { GotNewMessageMessage } from '@there/sun/modules/gotNewMessage'
import { GotStatusMessage } from '@there/sun/modules/gotStatus'
import { JoinDialogReply } from '@there/sun/modules/joinDialog'
import { JoinSpaceResult } from '@there/sun/modules/joinSpace'
import { NewChatQueryReply } from '@there/sun/modules/newChat'
import { NewUpdateMessage } from '@there/sun/modules/newUpdate'
import { NotificationQueryReply } from '@there/sun/modules/notification'
import { SeenMessageReply } from '@there/sun/modules/seenMessage'
import { SelfQueryData } from '@there/sun/modules/self'
import { SendMessageReply } from '@there/sun/modules/sendChatMessage'
import { SendNewMessageReply } from '@there/sun/modules/sendNewMessage'
import {
  NotificationMessage,
  NudgeNotification,
} from '@there/sun/modules/sendNotification'
import { SpaceQueryReply } from '@there/sun/modules/space'
import { SpaceChatQueryReply } from '@there/sun/modules/spaceChats'
import { StatusesQueryReply } from '@there/sun/modules/statuses'
import { UpdateFocusResult } from '@there/sun/modules/updateFocus'
import { UpdatePresenceResult } from '@there/sun/modules/updatePresence'
import { UserChatsQueryReply } from '@there/sun/modules/userChats'
import {
  Api,
  MessageInfo,
  NewChatInfo,
  NewMessageInfo,
  TopicInfo,
} from '@there/sun/utils/node-types'
import {
  ApiClientMessage,
  GotChatMessageMessage,
  NodeUpdateMessage,
} from '@there/sun/ws/message-types'
import { toGlobalId } from '@there/tower/utils/global-id'
import cuid from 'cuid'
import { LocalAddStatusMessageVariables } from '../main/useAddStatus'
import { LocalAddStatusReactionMessageVariables } from '../main/useAddStatusReaction'
import { LocalDeleteStatusReactionMessageVariables } from '../main/useDeleteStatusReaction'
import { LocalSeenMessageMessageVariables } from '../main/useSeenMessage'
import { LocalUpdateFocusMessageVariables } from '../main/useUpdateFocus'
import { UpdateFunction } from './cache'

type Updates = Record<ApiClientMessage['method'] | string, UpdateFunction>

export const updates: { subscription: Updates; mutation: Updates } = {
  mutation: {
    joinDialog: (data: JoinDialogReply, args: any, cache) => {
      // Experimental
      updates.subscription.nodeUpdate(data, undefined, cache)
    },

    updatePresence: (data: UpdatePresenceResult, args: any, cache) => {
      // Experimental
      if (!data?.user) return
      cache.writeNode(data.user)
    },

    joinSpace: (data: JoinSpaceResult, args: any, cache) => {
      // Experimental
      updates.subscription.nodeUpdate(data, undefined, cache)
    },

    dismissNotification: (
      data: DismissNotificationResult,
      args: any,
      cache,
    ) => {
      if (!data || !data.done) return
      let senderId: string = args.senderId
      let clearAll = args.clearAll

      cache.updateQuery<NotificationQueryReply>(
        {
          method: 'notificationList',
        },
        (data) => {
          if (!data) return null

          if (clearAll) {
            return { notifications: [] }
          } else {
            if (!senderId) {
              console.warn('dismiss notification, senderId is not defined')
              return data
            }

            let filteredData = (data.notifications || []).filter(
              (notification) => notification.senderId !== senderId.toString(),
            )
            return { notifications: filteredData }
          }
        },
      )
    },

    sendChatMessage: (data: SendMessageReply, args: any, cache) => {
      if (!data || !data.done) return

      let senderId = args.__extra.senderId
      let messageId = toGlobalId('Message', args.id)
      let message: MessageInfo = {
        id: messageId,
        chatId: args.chatId,
        text: args.text,
        senderId: senderId,
        sentAt: Date.now(),
      }

      cache.updateQuery<ChatQueryReply>(
        { method: 'chat', variables: { chatId: args.chatId } },
        (data) => {
          if (!data) return null
          let messages = data.chat.messages ? data.chat.messages : []
          let hasMessage = messages.some(({ id }) => id === messageId)
          //don't re-add
          if (hasMessage) {
            return data
          }
          return {
            chat: {
              ...data.chat,
              messages: [...messages, message],
            },
          }
        },
      )
    },

    sendNewMessage: (
      data: SendNewMessageReply,
      args: LocalSendNewMessageVariables,
      cache,
    ) => {
      if (!data || !data.done) return

      let self = cache.readQuery<SelfQueryData>({
        method: 'self',
      })

      let senderId = args.__extra.senderId
      let messageId = args.msgId

      let message: Api['NewMessage'] = {
        id: messageId,
        edited: false,
        peerTopicId: args.peerTopicId,
        peerUserId: args.peerUserId,
        text: args.text || '',
        senderId: senderId,
        sentAt: args.sentAt,
        replyToMessageId: args.replyToMessageId,
        mediaObjectId: args.mediaObjectId,
        mediaType: args.mediaType,
        thumbObjectId: args.thumbObjectId,
        mediaW: args.mediaW,
        mediaH: args.mediaH,
        document: args.document,
      }

      cache.updateQuery<NewChatQueryReply>(
        {
          method: 'newChat',
          variables: {
            peerTopicId: args.peerTopicId,
            // Outgoing.
            peerUserId: args.peerUserId,
          },
        },
        (data) => {
          if (!data) return null
          let messages = data.messages || []
          let hasMessage = messages.some(({ id }) => id === messageId)
          //don't re-add
          if (hasMessage) {
            return data
          }
          return {
            messages: [...messages, message],
            chat: data.chat,
          }
        },
      )

      let topic = (args.peerTopicId
        ? cache.readNode({
            id: args.peerTopicId,
            __typename: 'Topic',
          })
        : null) as TopicInfo | null

      updateTopMessageAndUnread({
        newMessage: message,
        peerTopicId: args.peerTopicId,
        peerUserId: args.peerUserId,
        senderId,
        type: 'newMessage',

        // out: true,
        spaceId: topic?.spaceId,
        cache,
        self,
      })
    },

    editChatMessageSimple: (
      data: EditChatMessageSimpleReply,
      args: EditChatMessageSimpleMessage['payload'],
      cache,
    ) => {
      if (!data || !data.done) return
      if (!args.peerUserId) return

      cache.updateQuery<NewChatQueryReply>(
        {
          method: 'newChat',
          variables: {
            peerTopicId: null,
            peerUserId: args.peerUserId,
          },
        },
        (queryData) => {
          let index = queryData?.messages.findIndex(
            (message) => message.id === args.id,
          )

          // check message exists
          if (queryData && typeof index !== 'undefined' && index >= 0) {
            queryData.messages[index] = {
              ...queryData?.messages[index],
              text: args.text || null,
            }
          }
          return queryData
        },
      )
    },

    seenMessage: (
      data: SeenMessageReply,
      args: LocalSeenMessageMessageVariables,
      cache,
    ) => {
      if (!data || !data.done) return
      if (args.__extra.type === 'userChat') {
        cache.updateQuery<UserChatsQueryReply>(
          {
            method: 'userChats',
          },
          (data) => {
            if (!data) return data
            let index = data.chats?.findIndex((chat) => chat.id === args.chatId)

            if (typeof index === 'undefined') return data
            if (data.chats && index >= 0 && data.chats[index]) {
              let chat = data.chats[index]
              data.chats[index] = {
                ...chat,
                lastSeenMessageId: args.lastSeenMessageId,
                unreadCount: chat.unreadCount - args.seenMessagesNum,
              }
            }

            return data
          },
        )
      } else if (args.__extra.type === 'topicChat') {
        cache.updateQuery<SpaceChatQueryReply>(
          {
            method: 'spaceChats',
            variables: {
              spaceId: args.__extra.spaceId,
            },
          },
          (data) => {
            if (!data) return data
            let chats = data.spaceChats.chats
            let index = data.spaceChats.chats?.findIndex(
              (chat) => chat.id === args.chatId,
            )
            if (typeof index === 'undefined') return data
            if (chats && index >= 0 && chats[index]) {
              let chat = chats[index]
              chats[index] = {
                ...chat,
                lastSeenMessageId: args.lastSeenMessageId,
                unreadCount: chat.unreadCount - args.seenMessagesNum,
              }
            }

            return {
              spaceChats: {
                ...data.spaceChats,
                chats,
              },
            }
          },
        )
      }
    },
    addStatusReaction: (
      data: AddStatusReactionResult,
      args: LocalAddStatusReactionMessageVariables,
      cache,
    ) => {
      if (!data || !data.done) return
      if (!args.__extra.userId) return
      let spaceId = args.spaceId
      let reaction = {
        userId: args.__extra.userId,
        statusId: args.statusId,
        emoji: args.emoji,
      }
      cache.updateQuery<StatusesQueryReply>(
        {
          method: 'statuses',
          variables: {
            spaceIds: [spaceId],
            addOffset: 30,
          },
        },
        (data) => {
          if (!data) return data
          let statusIndex = data.userSpaceStatuses[spaceId].findIndex(
            (status) => status.id === reaction.statusId,
          )
          if (statusIndex < 0) return data
          let status = data.userSpaceStatuses[spaceId][statusIndex]
          let statusReactions = status.reactions || []
          let hasReaction = statusReactions.some(
            (reactionItem) =>
              reactionItem.emoji === reaction.emoji &&
              reactionItem.statusId === reaction.statusId &&
              reactionItem.userId === reaction.userId,
          )
          if (hasReaction) return data
          data.userSpaceStatuses[spaceId][statusIndex] = {
            ...status,
            reactions: [...statusReactions, reaction],
          }

          return data
        },
      )
      return
    },
    deleteStatusReaction: (
      data: AddStatusReactionResult,
      args: LocalDeleteStatusReactionMessageVariables,
      cache,
    ) => {
      if (!data || !data.done) return
      if (!args.__extra.userId) return
      let spaceId = args.spaceId
      let reaction = {
        userId: args.__extra.userId,
        statusId: args.statusId,
        emoji: args.emoji,
      }
      cache.updateQuery<StatusesQueryReply>(
        {
          method: 'statuses',
          variables: {
            spaceIds: [spaceId],
            addOffset: 30,
          },
        },
        (data) => {
          if (!data) return data
          let statusIndex = data.userSpaceStatuses[spaceId].findIndex(
            (status) => status.id === reaction.statusId,
          )
          if (statusIndex < 0) return data
          let status = data.userSpaceStatuses[spaceId][statusIndex]
          let statusReactions = status.reactions || []

          data.userSpaceStatuses[spaceId][
            statusIndex
          ].reactions = statusReactions.filter(
            (r) =>
              r.emoji !== reaction.emoji ||
              r.statusId !== reaction.statusId ||
              r.userId !== reaction.userId,
          )

          return data
        },
      )
      return
    },
    addStatus: (
      data: AddStatusResult,
      args: LocalAddStatusMessageVariables,
      cache,
    ) => {
      let now = Date.now()
      let newStatus = {
        ...args,
        userId: args.__extra.userId,
        __extra: undefined,
        createdAt: now,
        updatedAt: now,
      }
      let spaceId = args.spaceIds[0]
      let replyToStatusId = args.replyToStatusId
      let userId = args.__extra.userId
      let topic = false
      cache.updateQuery<StatusesQueryReply>(
        {
          method: 'statuses',
          variables: {
            spaceIds: [spaceId],
            addOffset: 30,
          },
        },
        (data) => {
          if (!data) return data
          // if there is no status in space
          if (!data.userSpaceStatuses[spaceId]) {
            data.userSpaceStatuses[spaceId] = [newStatus]
          } else if (
            // if status is not updated before
            !data.userSpaceStatuses[spaceId].some(
              (status) => status.id === newStatus.id,
            )
          ) {
            // add status at the top of statuses
            data.userSpaceStatuses[spaceId].unshift(newStatus)
          }
          return data
        },
      )
      return
    },

    deleteStatus: (
      data: DeleteStatusResult,
      args: DeleteStatusMessage['payload'],
      cache,
    ) => {
      let spaceId = args.spaces[0]
      let statusId = args.id
      cache.updateQuery<StatusesQueryReply>(
        {
          method: 'statuses',
          variables: {
            spaceIds: [spaceId],
            addOffset: 30,
          },
        },
        (data) => {
          if (!data) return data
          let spaceStatuses = data.userSpaceStatuses[spaceId]
          if (!spaceStatuses) return data
          spaceStatuses = spaceStatuses.filter(
            (status) => status.id !== statusId,
          )
          data.userSpaceStatuses[spaceId] = spaceStatuses
          return data
        },
      )
      return
    },

    updateFocus: (
      data: UpdateFocusResult,
      args: LocalUpdateFocusMessageVariables,
      cache,
    ) => {
      cache.writeNode({ id: args.__extra.userId, focused: args.focus })
      return
    },
  },

  subscription: {
    nodeUpdate: (data: NodeUpdateMessage['payload'], args: any, cache) => {
      // When it errors
      if (!data) return
      let updates = data.updates

      if (updates) {
        for (let update of updates) {
          switch (update.mutation) {
            case 'Update':
              switch (update.type) {
                case 'UserFocus': {
                  let spaceId = update.spaceId
                  let focusUpdate = update.node
                  let userFocus =
                    focusUpdate?.UserFocus && focusUpdate.UserFocus[0]
                  cache.updateQuery<SpaceQueryReply>(
                    { method: 'space', variables: { spaceId } },
                    (data) => {
                      if (!data) return data
                      let memberIndex = data.space.members.findIndex(
                        (member) => member.user.id === focusUpdate.id,
                      )
                      if (memberIndex < 0) return data
                      let member = data.space.members[memberIndex]
                      member.user.focused = focusUpdate.focused
                      if (focusUpdate.focused) {
                        member.user.UserFocus = [
                          {
                            id: toGlobalId('UserFocus', cuid()),
                            userId: focusUpdate.id,
                            startedAt: userFocus?.startedAt,
                            endedAt: undefined,
                          },
                        ]
                      }
                      data.space.members[memberIndex] = member
                      return data
                    },
                  )
                  break
                }
                default: {
                  cache.writeNode(update.node)
                }
              }
              break

            case 'Delete':
            case 'Create':
              let spaceId
              if ('spaceId' in update) {
                spaceId = update.spaceId
              } else if (update.type !== 'Chat' && update.node.spaceId) {
                spaceId = update.node.spaceId
              } else {
                if (update.type !== 'Chat') {
                  console.warn(
                    '[updates] Update with type "Create" did not have space id',
                  )
                }
                break
              }

              cache.updateQuery<SpaceQueryReply>(
                { method: 'space', variables: { spaceId } },
                (data) => {
                  if (!data) return null

                  if (update.mutation === 'Delete') {
                    switch (update.type) {
                      case 'Topic':
                        data.space.topics = data.space.topics?.filter(
                          (topic) => update.node.id !== topic.id,
                        )
                        break
                      case 'Member': {
                        let node = update.node
                        // Rm member
                        data.space.members = data.space.members?.filter(
                          (member) => node.id !== member.id,
                        )
                        // Remove avatar
                        data.space.avatars = data.space.avatars?.filter(
                          (avatar) => {
                            return avatar.userId !== node.userId
                          },
                        )
                        break
                      }

                      case 'Dialog':
                        data.space.dialogs = data.space.dialogs?.filter(
                          (dialog) => update.node.id !== dialog.id,
                        )
                        console.info('putting avatars out of dialog')
                        // Remove dialogId from avatar in the deleted Dialog
                        data.space.avatars = data.space.avatars?.map(
                          (avatar) => {
                            if (avatar.dialogId === update.node.id) {
                              return {
                                ...avatar,
                                dialogId: null,
                              }
                            } else {
                              return avatar
                            }
                          },
                        )
                        break
                    }

                    // Remove from cache
                    cache.invalidate(update.node)
                  } else {
                    switch (update.type) {
                      case 'Avatar':
                        if (data.space.avatars) {
                          data.space.avatars.push(update.node)
                        } else {
                          data.space.avatars = [update.node]
                        }
                        break

                      case 'Topic':
                        if (data.space.topics) {
                          data.space.topics.push(update.node)
                        } else {
                          data.space.topics = [update.node]
                        }
                        break

                      case 'Dialog':
                        if (data.space.dialogs) {
                          data.space.dialogs.push(update.node)
                        } else {
                          data.space.dialogs = [update.node]
                        }
                        break

                      case 'User':
                        break

                      case 'Member':
                        if (data.space.members) {
                          data.space.members.push(update.node)
                          let newMemberInfo = update.node

                          cache.updateQuery<UserChatsQueryReply>(
                            { method: 'userChats' },
                            (chatData) => {
                              let self = cache.readQuery<SelfQueryData>({
                                method: 'self',
                              })
                              if (!chatData) return chatData
                              if (!self?.userId) return chatData
                              let newOptimisticChat = {
                                id: 'NewChat:' + cuid(),
                                userId: self.userId,
                                maxReadInboxMsgId: null,
                                unreadCount: 0,
                                pinned: false,
                                peerUserId: newMemberInfo.user.id,
                              }
                              if (chatData.chats) {
                                chatData.chats.push(newOptimisticChat)
                              } else {
                                chatData.chats = [newOptimisticChat]
                              }
                              return chatData
                            },
                          )
                        } else {
                          data.space.members = [update.node]
                        }
                        // on join space we also need to add avatar to space, otherwise it breaks
                        // we do it in a single update
                        let avatar = update.node.user.avatar
                        if (avatar) {
                          if (data.space.avatars) {
                            data.space.avatars.push(avatar)
                          } else {
                            data.space.avatars = [avatar]
                          }
                        }

                        // create a new chat for member
                        let self = cache.readQuery<SelfQueryData>({
                          method: 'self',
                        })

                        cache.updateQuery<UserChatsQueryReply>(
                          { method: 'userChats' },
                          (data) => {
                            if (!data) return data
                            if (!self?.userId) return data
                            let newOptimisticChat = {
                              id: cuid(),
                              userId: self.userId,
                              maxReadInboxMsgId: null,
                              unreadCount: 0,
                              pinned: false,
                            }
                            if (data.chats) {
                              data.chats.push(newOptimisticChat)
                            } else {
                              data.chats = [newOptimisticChat]
                            }
                            return data
                          },
                        )
                        break
                    }
                  }

                  return data
                },
              )
          }
        }
      }
    },
    notification: (data: NotificationMessage['payload'], args: any, cache) => {
      if (!data) return
      switch (data.type) {
        case 'nudge':
          let nudgeNotification = data as NudgeNotification
          cache.updateQuery<NotificationQueryReply>(
            {
              method: 'notificationList',
            },
            (data) => {
              if (!data) return null
              let newNotification: NudgeNotification = {
                id: nudgeNotification.id,
                type: 'nudge',
                sentAt: nudgeNotification.sentAt,
                senderId: nudgeNotification.senderId,
                spaceId: nudgeNotification.spaceId,
                dialogId: nudgeNotification.dialogId,
                user: {
                  name: nudgeNotification.user?.name,
                  profilePhoto: nudgeNotification.user?.profilePhoto,
                },
              }

              if (!data.notifications) {
                data.notifications = [newNotification]
              } else {
                data.notifications.unshift(newNotification)
              }
              return data
            },
          )
      }
    },

    gotChatMessage: (
      data: GotChatMessageMessage['payload'],
      args: { chatId: string },
      cache,
    ) => {
      if (!data) return
      let { chatId, id: messageId, senderId } = data.message

      cache.updateQuery<ChatQueryReply>(
        {
          method: 'chat',
          variables: {
            chatId,
          },
        },
        (queryData) => {
          if (
            !queryData?.chat.messages?.some(({ id }) => id === messageId) &&
            data.type === 'Create'
          ) {
            queryData?.chat.messages?.push(data.message)
          }
          if (data.type === 'Update' && queryData?.chat?.messages) {
            let index = queryData?.chat.messages?.findIndex(
              (message) => message.id === messageId,
            )
            // Check message exists
            if (index >= 0) {
              queryData.chat.messages[index] = {
                ...queryData.chat.messages[index],
                text: data.message.text,
              }
            }
          }
          if (data.type === 'Delete' && queryData?.chat?.messages) {
            return {
              ...queryData,
              chat: {
                ...queryData.chat,
                messages: queryData.chat.messages.filter(
                  (message) => message.id !== messageId,
                ),
              },
            }
          }

          return queryData
        },
      )

      return true
    },

    gotNewMessage: (
      data: GotNewMessageMessage['payload'],
      args: unknown,
      cache,
    ) => {
      if (!data) return
      let { peerTopicId, peerUserId, id: messageId, senderId } = data.message

      let self = cache.readQuery<SelfQueryData>({
        method: 'self',
      })

      if (data.type === 'Update' && data.message.id) {
        cache.writeNode({ ...data.message, id: data.message.id })
        return data
      }

      let newMessage = data.message

      let topic = (newMessage.peerTopicId
        ? cache.readNode({
            id: newMessage.peerTopicId,
            __typename: 'Topic',
          })
        : null) as TopicInfo | null

      cache.updateQuery<NewChatQueryReply>(
        {
          method: 'newChat',
          variables: {
            peerTopicId: peerTopicId || null,
            peerUserId: !peerTopicId
              ? self?.userId === senderId
                ? peerUserId
                : senderId
              : null, // this should never be undefined
          },
        },
        (queryData) => {
          if (
            !queryData?.messages?.some(({ id }) => id === messageId) &&
            data.type === 'Create'
          ) {
            queryData?.messages?.push(data.message)
          }

          // if (data.type === 'Update' && queryData?.messages) {
          //   let index = queryData?.messages?.findIndex(
          //     (message) => message.id === messageId,
          //   )
          //   // Check message exists
          //   if (queryData && typeof index !== undefined && index >= 0) {
          //     queryData.messages[index] = {
          //       ...queryData?.messages[index],
          //       text: data.message.text || null,
          //     }
          //   }
          // }

          if (data.type === 'Delete' && queryData?.messages) {
            let messageClone = { ...data.message }
            // It must  run after query update, otherwise we get null error
            // setTimeout(() => {
            //   // Remove from cache
            //   if (messageClone.id) {
            //     cache.invalidate({ ...messageClone, id: messageClone.id })
            //   }
            // }, 1)

            // Filter out the message =
            let newMessages = queryData.messages.filter(
              (message) => message.id !== messageId,
            )

            // Update top message id
            // There could be no other message left, hence null
            let newTopMessage = newMessages[newMessages.length - 1] || null

            updateTopMessageAndUnread({
              cache,
              newMessage: newTopMessage,
              peerTopicId: data.message.peerTopicId,
              peerUserId: data.message.peerUserId,
              // Check
              senderId: data.message.senderId,
              type: 'updateTopMessage',
              self,
              // out: self?.userId === newTopMessage,
              spaceId: topic?.spaceId,
            })

            let chat: NewChatQueryReply['chat'] = queryData.chat
              ? {
                  ...queryData.chat,
                  topMessageId: newTopMessage.id,
                }
              : null

            // if (queryData.chat) {
            //   console.log('queryData.chat', queryData.chat)
            //   cache.writeNode({
            //     id: queryData.chat.id,
            //     topMessageId: newTopMessage.id,
            //   })
            // }

            return {
              chat,
              messages: newMessages,
            }
          }
          return queryData
        },
      )

      if (data.type === 'Create') {
        updateTopMessageAndUnread({
          newMessage: data.message,
          cache,
          peerTopicId: data.message.peerTopicId,
          peerUserId: data.message.peerUserId,
          senderId: data.message.senderId,
          type: 'newMessage',
          // out: false,
          spaceId: topic?.spaceId,
          self,
        })
      }

      return true
    },

    //
    newUpdate: (data: NewUpdateMessage['payload'], args: any, cache) => {
      let self = cache.readQuery<SelfQueryData>({
        method: 'self',
      })

      if (!self?.userId) {
        console.warn('Could not self info in newUpdate handler')
        return
      }

      let userId = self.userId

      for (let update of data.updates) {
        switch (update.event) {
          case 'newTopic': {
            let topic = update.topic
            cache.updateQuery<SpaceChatQueryReply>(
              {
                method: 'spaceChats',
                variables: { spaceId: update.topic.spaceId },
              },
              (data) => {
                if (!data?.spaceChats) return data

                if (!data.spaceChats.topics.some((t) => t.id === topic.id)) {
                  data.spaceChats.topics.push(topic)

                  let newChat: NewChatInfo = {
                    id: toGlobalId('NewChat', cuid()),
                    userId: userId,
                    maxReadInboxMsgId: null,
                    pinned: false,
                    unreadCount: 0,
                    updatedAt: topic.updatedAt || Date.now(),
                    peerTopicId: topic.id,
                  }
                  if (!data.spaceChats.chats) {
                    data.spaceChats.chats = [newChat]
                  } else {
                    data.spaceChats.chats.push(newChat)
                  }
                }

                return data
              },
            )
          }
          case 'updateTopic': {
            let updatedTopic = update.topic
            cache.updateQuery<SpaceChatQueryReply>(
              {
                method: 'spaceChats',
                variables: { spaceId: update.topic.spaceId },
              },
              (data) => {
                if (!data?.spaceChats) return data

                let topicIndex:
                  | number
                  | undefined = data.spaceChats.topics.findIndex(
                  (t) => t.id === updatedTopic.id,
                )
                if (typeof topicIndex === 'undefined') return data
                if (topicIndex >= 0) {
                  let topic = data.spaceChats.topics[topicIndex]
                  let title = updatedTopic.title
                    ? updatedTopic.title
                    : topic.title
                  let iconEmoji = updatedTopic.iconEmoji
                    ? updatedTopic.iconEmoji
                    : topic.iconEmoji
                  topic = {
                    ...topic,
                    title,
                    iconEmoji,
                  }
                  data.spaceChats.topics[topicIndex] = topic
                }

                return data
              },
            )
            return
          }
          case 'updateChat': {
            let newChatData = update.chat
            if (newChatData.peerTopicId) {
              cache.updateQuery<SpaceChatQueryReply>(
                {
                  method: 'spaceChats',
                  variables: { spaceId: update.spaceId },
                },
                (data) => {
                  if (!data?.spaceChats.chats) return data

                  let chatIndex:
                    | number
                    | undefined = data.spaceChats.chats.findIndex(
                    (t) => t.peerTopicId === newChatData.peerTopicId,
                  )
                  if (typeof chatIndex === 'undefined') return data
                  if (chatIndex >= 0) {
                    let chat = data.spaceChats.chats[chatIndex]
                    let mute =
                      typeof newChatData.muted !== 'undefined'
                        ? { muted: newChatData.muted }
                        : {}
                    let unreadCount =
                      typeof newChatData.unreadCount !== 'undefined'
                        ? { unreadCount: newChatData.unreadCount }
                        : {}
                    let hide =
                      typeof newChatData.hide !== 'undefined'
                        ? { hide: newChatData.hide }
                        : {}
                    chat = {
                      ...chat,
                      ...mute,
                      ...hide,
                      ...unreadCount,
                    }
                    data.spaceChats.chats[chatIndex] = chat
                  }

                  return data
                },
              )
            } else if (newChatData.peerUserId) {
              cache.updateQuery<UserChatsQueryReply>(
                { method: 'userChats' },
                (data) => {
                  if (!data || !data.chats) return data

                  let chatIndex: number | undefined = data.chats.findIndex(
                    (t) => t.peerUserId === newChatData.peerUserId,
                  )
                  if (typeof chatIndex === 'undefined') return data
                  if (chatIndex >= 0) {
                    let chat = data.chats[chatIndex]
                    let mute =
                      typeof newChatData.muted !== 'undefined'
                        ? { muted: newChatData.muted }
                        : {}
                    let unreadCount =
                      typeof newChatData.unreadCount !== 'undefined'
                        ? { unreadCount: newChatData.unreadCount }
                        : {}

                    let lastReadOutboxMsgId =
                      newChatData.lastReadOutboxMsgId !== 'undefined'
                        ? {
                            lastReadOutboxMsgId:
                              newChatData.lastReadOutboxMsgId,
                          }
                        : {}
                    let lastReadOutboxDate = newChatData.lastReadOutboxDate
                      ? { lastReadOutboxDate: newChatData.lastReadOutboxDate }
                      : {}

                    chat = {
                      ...chat,
                      ...mute,
                      ...unreadCount,
                      ...lastReadOutboxMsgId,
                      ...lastReadOutboxDate,
                    }
                    data.chats[chatIndex] = chat
                  }

                  return data
                },
              )
            }

            return
          }
          case 'deleteChat': {
            let peerTopicId = update.peerTopicId
            let removedTopic: TopicInfo
            let removedChat: NewChatInfo
            cache.updateQuery<SpaceChatQueryReply>(
              {
                method: 'spaceChats',
                variables: { spaceId: update.spaceId },
              },
              (data) => {
                if (!data?.spaceChats) return data

                data.spaceChats.topics = data.spaceChats.topics.filter(
                  (topic) => {
                    if (topic.id === peerTopicId) {
                      removedTopic = topic
                    }
                    return topic.id !== peerTopicId
                  },
                )
                data.spaceChats.chats = data.spaceChats.chats?.filter(
                  (chat) => {
                    if (chat.peerTopicId === peerTopicId) {
                      removedChat = chat
                    }
                    return !Boolean(chat.peerTopicId === peerTopicId)
                  },
                )
                cache.invalidate(removedTopic)
                cache.invalidate(removedChat)
                return data
              },
            )
            return
          }

          //
          case 'createStatus': {
            let newStatus = update.status
            let spaceId = update.spaceId
            let replyToStatusId = update.replyToStatusId
            let topic = update.status.topic
            cache.updateQuery<StatusesQueryReply>(
              {
                method: 'statuses',
                variables: {
                  spaceIds: [spaceId],
                  addOffset: 30,
                },
              },
              (data) => {
                if (!data) return data
                if (userId === newStatus.userId) return data
                // if there is no status in space
                if (!data.userSpaceStatuses[spaceId]) {
                  data.userSpaceStatuses[spaceId] = [newStatus]
                } else if (
                  // if status is not updated before
                  !data.userSpaceStatuses[spaceId].some(
                    (status) => status.id === newStatus.id,
                  )
                ) {
                  // add status at the top of statuses
                  data.userSpaceStatuses[spaceId].unshift(newStatus)
                }

                if (
                  replyToStatusId &&
                  topic &&
                  data.userSpaceStatuses[spaceId]
                ) {
                  let replyToStatusIndex = data.userSpaceStatuses[
                    spaceId
                  ].findIndex((status) => status.id === replyToStatusId)
                  let replyToStatus =
                    data.userSpaceStatuses[spaceId][replyToStatusIndex]
                  if (replyToStatus) {
                    data.userSpaceStatuses[spaceId][replyToStatusIndex] = {
                      ...replyToStatus,
                      updatedAt: newStatus.updatedAt,
                      topic,
                    }
                  }
                }
                return data
              },
            )
            return
          }
          case 'deleteStatus': {
            let spaceId = update.spaceId
            let statusId = update.statusId
            cache.updateQuery<StatusesQueryReply>(
              {
                method: 'statuses',
                variables: {
                  spaceIds: [spaceId],
                  addOffset: 30,
                },
              },
              (data) => {
                if (!data) return data
                let spaceStatuses = data.userSpaceStatuses[spaceId]
                if (!spaceStatuses) return data
                spaceStatuses = spaceStatuses.filter(
                  (status) => status.id !== statusId,
                )
                data.userSpaceStatuses[spaceId] = spaceStatuses
                return data
              },
            )
            return
          }
          case 'addStatusReaction': {
            let reaction = update.reaction
            let spaceId = update.spaceId
            cache.updateQuery<StatusesQueryReply>(
              {
                method: 'statuses',
                variables: {
                  spaceIds: [spaceId],
                  addOffset: 30,
                },
              },
              (data) => {
                if (!data) return data
                if (userId === reaction.userId) return data
                let statusIndex = data.userSpaceStatuses[spaceId].findIndex(
                  (status) => status.id === reaction.statusId,
                )
                if (statusIndex < 0) return data
                let status = data.userSpaceStatuses[spaceId][statusIndex]
                let statusReactions = status.reactions || []
                data.userSpaceStatuses[spaceId][statusIndex] = {
                  ...status,
                  reactions: [...statusReactions, reaction],
                }

                return data
              },
            )
            return
          }
          case 'deleteStatusReaction': {
            let reaction = update.reaction
            let spaceId = update.spaceId
            cache.updateQuery<StatusesQueryReply>(
              {
                method: 'statuses',
                variables: {
                  spaceIds: [spaceId],
                  addOffset: 30,
                },
              },
              (data) => {
                if (!data) return data
                if (userId === reaction.userId) return data

                let statusIndex = data.userSpaceStatuses[spaceId].findIndex(
                  (status) => status.id === reaction.statusId,
                )
                if (statusIndex < 0) return data
                let status = data.userSpaceStatuses[spaceId][statusIndex]
                let statusReactions = status.reactions || []

                data.userSpaceStatuses[spaceId][
                  statusIndex
                ].reactions = statusReactions.filter(
                  (r) =>
                    r.emoji !== reaction.emoji ||
                    r.statusId !== reaction.statusId ||
                    r.userId !== reaction.userId,
                )

                return data
              },
            )
          }
        }
      }
    },

    // Added in v2.1.0
    gotStatus: (data: GotStatusMessage['payload'], args: any, cache) => {
      if (!data) return

      // Update user status
      cache.writeNode({
        id: data.userId,
        __typename: 'User',
        online: data.online,
      })
    },
  },
}

// TODO: Handle when no message is left
// TODO: Handle when no message is left
// TODO: Handle when no message is left
// TODO: Handle when no message is left
// TODO: Handle when no message is left
function updateTopMessageAndUnread({
  cache,
  newMessage,
  spaceId,
  type = 'newMessage',
  peerTopicId,
  peerUserId,
  self,
  senderId,
}: {
  cache: Cache
  /** Set to null if there is no message left */
  newMessage: NewMessageInfo | null
  peerTopicId: string | null | undefined
  peerUserId: string | null | undefined
  senderId: string | null | undefined
  type: 'newMessage' | 'updateTopMessage'
  spaceId: string | null | undefined
  self: SelfQueryData | null
}) {
  if (!self) {
    console.error('Did not get self')
    return
  }

  let ourUserId = self.userId
  let out = newMessage && newMessage.senderId === ourUserId
  let nextTopMessageId = newMessage?.id || null

  // Save top message id
  if (peerTopicId && spaceId) {
    cache.updateQuery<SpaceChatQueryReply>(
      {
        method: 'spaceChats',
        variables: { spaceId },
      },
      (data) => {
        if (!data) return data

        let oldTopMessageId: string | null | undefined

        // Update chat of this topic  for its top message id
        data.spaceChats.chats = data.spaceChats.chats?.map((chat) => {
          if (chat.peerTopicId && chat.peerTopicId === peerTopicId) {
            let oldUnreadCount = chat.unreadCount
            oldTopMessageId = chat?.topMessageId

            let newUnreadCount: number = chat.unreadCount
            //Only update if it's a new message
            if (type === 'newMessage' && newMessage) {
              newUnreadCount =
                self?.userId === newMessage.senderId ? 0 : oldUnreadCount + 1
            }

            return {
              ...chat,
              topMessageId: nextTopMessageId,
              unreadCount: newUnreadCount,
              updatedAt: type === 'newMessage' ? Date.now() : undefined,
            }
          } else {
            return chat
          }
        })

        // Remove prev top message
        if (oldTopMessageId) {
          data.spaceChats.messages = data.spaceChats.messages.filter(
            (message) => {
              return message && message.id !== oldTopMessageId
            },
          )
        }

        // Insert new message
        data.spaceChats.messages.push(newMessage)

        return data
      },
    )
  }

  // Save top message id
  if (peerUserId) {
    cache.updateQuery<UserChatsQueryReply>(
      {
        method: 'userChats',
      },
      (data) => {
        if (!data) return data

        let oldTopMessageId: string | undefined | null

        // To know if we need to create a new chat
        let hadExistingChat = false

        data.chats = data.chats?.map((chat) => {
          if (chat.peerUserId === (out ? peerUserId : senderId)) {
            hadExistingChat = true
            oldTopMessageId = chat?.topMessageId
            let oldUnreadCount = chat.unreadCount

            let newUnreadCount: number = chat.unreadCount
            //Only update if it's a new message
            if (type === 'newMessage') {
              newUnreadCount = out ? 0 : oldUnreadCount + 1
            }

            return {
              ...chat,
              topMessageId: nextTopMessageId,
              unreadCount: newUnreadCount,
              updatedAt: type === 'newMessage' ? Date.now() : undefined,
            }
          } else {
            return chat
          }
        })

        if (!hadExistingChat && peerUserId && newMessage) {
          if (!data.chats) data.chats = []

          console.info('creating new chat in Cache')
          // Create new chat
          data.chats.push({
            id: cuid(),
            pinned: false,
            maxReadInboxMsgId: null,
            lastSeenMessageId: undefined,
            unreadCount: 1,
            userId: out ? ourUserId : peerUserId,
            peerUserId: out ? peerUserId : ourUserId,
            topMessageId: nextTopMessageId,
            updatedAt: Date.now(),
          })
        }

        // Remove prev top message
        if (oldTopMessageId) {
          data.messages = data.messages.filter((message) => {
            return message.id !== oldTopMessageId
          })
        }

        if (newMessage) {
          // Insert new message
          data.messages.push(newMessage)
        }

        return data
      },
    )
  }
}
