// import { PartialUser } from '@there/components/urql/fragments/userInfo'

export enum CallAgentMode {
  Host,
  Observer,
}

export enum FeedWindowMode {
  Building,
  ScreenShareHost,
  ScreenShareObserver,
  Pin,
}

export enum FeedWindowPages {
  Space,
  Places,
  Friends,
}

export enum RoomMode {
  mini,
  board,
}

export enum VoiceWindowMode {
  // Picker,
  Boop,
  // Walkie,
}

export enum NotificationType {
  Knock,
  Nudge,
  ScreenRequest,
}

export enum NotificationResponse {
  Accepted,
  Dismissed,
  Timeout,
}

export enum RtcWindowMode {
  Pin = 0,
  Observer = 1,
  Host = 2,
  Hidden = 3,
  CameraView = 4,
}

export type PermitType = 'Guest'

export type PermitStatus = 'Granted' | 'Revoked' | 'Pending' | 'Expired'

export type VoicePickedIpcData = {
  user?: import('@there/components/urql/fragments/userInfo').PartialUser
  channel?: any
  roomId?: string
  spaceId: string
  position?: { x: number; y: number }
  isOnline?: boolean
}

export type RoomActionResponse = {
  room: any
  roomCredentials:
    | {
        token: string
        iceServersJson: string | null
      }
    | null
    | undefined
}

export type Position = {
  x: number
  y: number
}

export type Rectangle = {
  x: number
  y: number
  width: number
  height: number
}

export type RoomShowIpcData = {
  elementDimension: {
    width: number
    height: number
  }
  mousePositionInFeed: {
    x: number
    y: number
  }
  mode: 'mini' | 'board'
}
