import {
  SubscriptionInput,
  useNurSubscription,
} from '@there/components/sun/use-subscription'
import { GlobalTypingMessage } from '@there/sun/modules/gotGlobalTyping'

export type GlobalTypingData = GlobalTypingMessage['payload']

export const useGotGlobalTyping = (
  args: Omit<SubscriptionInput<GlobalTypingData>, 'method'>,
) => {
  return useNurSubscription<GlobalTypingData>({
    method: 'gotGlobalTyping',
    ...args,
  })
}
