import { useAlert } from '@there/components/modal/use-alert'
import { ButtonRow } from '@there/components/settings/ButtonRow'
import { ToggleRow } from '@there/components/settings/ToggleRow'
import { useAppContext } from '@there/components/shared/AppContext'
import { useCurrentMediaDevicesContext } from '@there/components/shared/CurrentMediaDevicesContext'
import { useMediaDevices } from '@there/components/shared/use-media-devices'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { useWindowContext } from '@there/components/shared/WindowContext'
import { electronSupports } from '@there/desktop/utils/electron-api'
import { copyToClipboard } from 'copy-lite'
import React from 'react'
import { Space } from '../shared/Space'
import { Section, SectionHeader } from './SettingsUI'

export const CallSection = () => {
  let {
    resilientVoice,
    camDefaultOn,
    autoEnableMic,
    lowCpuShare,
    dispatch,
    doubleClick,
    awayDot,
  } = useAppContext()
  let { triggerConnectionReset } = useRtcContext()
  let { isElectron } = useWindowContext()
  let {
    currentMic,
    currentSpeaker,
    currentCamera,
    microphones,
  } = useMediaDevices()
  const { systemAudioDevice } = useCurrentMediaDevicesContext()

  let report = `
    Mic: ${currentMic?.name || 'N/A'}
    Cam: ${currentCamera?.name || 'N/A'}
    Speaker: ${currentSpeaker?.name || 'N/A'}
    SysAudio: ${systemAudioDevice?.name || 'N/A'}
    -----
    All Mics:
    ${microphones.map((mic) => `|- ${mic.name}`).join('\n')}
  `

  let { openModal } = useAlert({
    alertIcon: `📜`,
    alertText: 'Here`s your report!',
    alertDescription: report,
    submitLabel: 'Copy',
    dismissLabel: 'Close',
  })

  return (
    <Section>
      <SectionHeader>Call Settings</SectionHeader>
      <Space vertical={8} />

      <ToggleRow
        title="Resilient voice (beta)"
        description="Enable if you experience robotic or choppy sound"
        disabledReason={
          !isElectron
            ? `Requires desktop app`
            : !electronSupports?.opusRed
            ? 'Update your app from menu to enable'
            : undefined
        }
        disabled={!isElectron || !electronSupports?.opusRed}
        value={!!resilientVoice}
        onValueChange={(value) => {
          dispatch({ type: 'resilient voice changed', value })
          setTimeout(() => {
            // Check if it works
            triggerConnectionReset()
          }, 300)
        }}
      />

      <ToggleRow
        title="Camera default on"
        description="Enabling this auto-enables your camera upon joining a room. We recommend using voice chat as your default instead."
        value={!!camDefaultOn}
        onValueChange={(value) => {
          dispatch({ type: 'cam default changed', value })
        }}
      />
      <ToggleRow
        title="Mic default on"
        description="Enabling this auto-enables your mic upon joining a room."
        value={!!autoEnableMic}
        onValueChange={(value) => {
          dispatch({ type: 'auto enable mic changed', value, auto: false })
        }}
      />

      <ToggleRow
        title="Fast Screen Sharing"
        description="Recommended if you're seeing high CPU usage or you have a 4k screen. (Uses H264 codec)"
        value={!!lowCpuShare}
        onValueChange={(value) => {
          dispatch({ type: 'low cpu sharing changed', value })
          setTimeout(() => {
            // Check if it works
            triggerConnectionReset()
          }, 300)
        }}
      />
      <ToggleRow
        title="Double click to join"
        description="Instead of click, you will join rooms by double clicking."
        value={!!doubleClick}
        onValueChange={(value) => {
          dispatch({ type: 'double click changed', value })
        }}
      />
      <ToggleRow
        title="Away Yellow Indicator"
        description="Enable Away yellow indicator when you are AFK and in room. (You won't see Away indicator for people when you don't share yours.)"
        value={typeof awayDot === 'boolean' ? awayDot : true}
        onValueChange={(value) => {
          dispatch({ type: 'away dot changed', value })
        }}
      />

      <ButtonRow
        buttonText="Generate Report"
        title="Call Settings Report"
        description="Generate a summary of your settings for debugging. Send this to us along your bug report."
        onPress={() => {
          openModal().then(() => {
            copyToClipboard(report)
          })
        }}
      />
    </Section>
  )
}
