import { Context } from '@sentry/types'
import { Draft } from 'immer'
import { useImmerReducer } from 'use-immer'
import {
  useCallback,
  useContext,
  useMemo,
  useState,
  createContext,
  useEffect,
} from 'react'
import { useWindowContext } from '../shared/WindowContext'
import { string } from 'electron-builder/node_modules/@types/yargs'
import { useShortcutManagerAtom } from '@there/components/shared/use-shortcut-manager'

type KeyEvent = {
  id: string
  key: string
  ctrl?: boolean
  shift?: boolean
  alt?: boolean
  action: () => void
}

type OnUnhandledKeyDown = (event: KeyboardEvent) => void

type State = {
  keyEvents: Record<string, KeyEvent[]>
  /** Used handling input focus for chat */
  onUnhandledKeyDown: OnUnhandledKeyDown | undefined
}

type Action =
  | { type: 'register new key event'; keyEvent: KeyEvent }
  | { type: 'unregister key event'; id: string; key: string }
  | { type: 'register unhandled handler'; handler: OnUnhandledKeyDown }
  | { type: 'unregister unhandled handler' }

function reducer(draft: Draft<State>, action: Action): State | void {
  switch (action.type) {
    case 'register new key event':
      let keyEvents = draft.keyEvents[action.keyEvent.key] || []
      keyEvents.push(action.keyEvent)
      draft.keyEvents[action.keyEvent.key] = keyEvents
      return
    case 'unregister key event':
      let registeredEvents = draft.keyEvents[action.key]

      // filter unregistered
      draft.keyEvents[action.key] = registeredEvents.filter(
        (event) => event.id !== action.id,
      )
      break

    case 'register unhandled handler': {
      if (draft.onUnhandledKeyDown) {
        console.error(
          'Already registered onUnhandledKeyDown, check the useKeyContexts for duplicate handling of this event.',
        )
        break
      }
      draft.onUnhandledKeyDown = action.handler
      break
    }

    case 'unregister unhandled handler': {
      draft.onUnhandledKeyDown = undefined
      break
    }

    default:
      break
  }
}

const initialState: State = {
  keyEvents: {},
  onUnhandledKeyDown: undefined,
}

type KeyContextManager = {
  registerKeyEvent: ({ shift, ctrl, alt, action, key }: KeyEvent) => void
  unregisterKeyEvent: ({}: { id: string; key: string }) => void
  registerUnhandledHandler: (handler: OnUnhandledKeyDown) => void
  unregisterUnhandledHandler: () => void
}

export const useKeyContextManager = (): KeyContextManager => {
  let [state, dispatch] = useImmerReducer(reducer, initialState)
  let { isMacOs } = useWindowContext()

  let registerKeyEvent = useCallback(
    (keyEvent: KeyEvent) => {
      dispatch({
        type: 'register new key event',
        keyEvent,
      })
    },
    [dispatch],
  )

  let unregisterKeyEvent = useCallback(
    ({ id, key }: { id: string; key: string }) => {
      dispatch({ type: 'unregister key event', id, key })
    },
    [dispatch],
  )

  let registerUnhandledHandler = useCallback(
    (handler: OnUnhandledKeyDown) => {
      dispatch({ type: 'register unhandled handler', handler })
    },
    [dispatch],
  )

  let unregisterUnhandledHandler = useCallback(() => {
    dispatch({ type: 'unregister unhandled handler' })
  }, [dispatch])

  let { escapeSubscribersRef } = useShortcutManagerAtom()

  let eventHandler = useCallback(
    (event: KeyboardEvent) => {
      let key = event.key
      let ctrl = isMacOs ? event.metaKey : event.ctrlKey
      let alt = event.altKey
      let shift = event.shiftKey

      if (state.keyEvents[key]) {
        for (let keyEvent of Object.values(state.keyEvents[key])) {
          // Check if no other escape handlers are left (modals)
          if (key === 'Escape' && escapeSubscribersRef.current.length > 0) {
            return
          }
          if (
            Boolean(keyEvent.ctrl) === ctrl &&
            Boolean(keyEvent.alt) === alt &&
            Boolean(keyEvent.shift) === shift
          ) {
            keyEvent.action()
          }
        }

        // handled
        return
      }

      // If didn't handle here, handle it here
      state.onUnhandledKeyDown?.(event)
    },
    [escapeSubscribersRef, isMacOs, state],
  )

  useEffect(() => {
    document.addEventListener('keydown', eventHandler)
    return () => {
      document.removeEventListener('keydown', eventHandler)
    }
  }, [eventHandler])

  const value: KeyContextManager = useMemo(() => {
    return {
      registerKeyEvent,
      unregisterKeyEvent,
      registerUnhandledHandler,
      unregisterUnhandledHandler,
    }
  }, [
    registerKeyEvent,
    registerUnhandledHandler,
    unregisterKeyEvent,
    unregisterUnhandledHandler,
  ])

  return value
}

const initialContext: KeyContextManager = {
  registerKeyEvent: () => {},
  unregisterKeyEvent: () => {},
  registerUnhandledHandler: () => {},
  unregisterUnhandledHandler: () => {},
}

export const KeyContext = createContext<KeyContextManager>(initialContext)
export const useKeyContext = () => useContext<KeyContextManager>(KeyContext)
