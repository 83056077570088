import { RefreshIcon } from '../feed/HeaderIcons'
import { GoodButton } from '../shared/GoodButton'
import { Tooltip } from '../shared/Tooltip'
import { logEvent } from '@there/components/shared/use-analytics'
import { useTheme } from '../feed/ThemeContext'

export const RefreshButton = () => {
  let theme = useTheme()
  return (
    <Tooltip
      label={
        <>
          Reload <span className="light">• CMD + R</span>
          <br />{' '}
          <span className="light">
            While in v2 beta, hit Reload
            <br /> to fix any issue.
          </span>
        </>
      }
      placement="bottomLeft"
    >
      <GoodButton
        variant="transparent"
        icon={<RefreshIcon color="#757D86" width={17} height={17} />}
        onPress={() => {
          logEvent('User Clicked Reload')

          window.location.reload()
        }}
      />
    </Tooltip>
  )
}
