import React, { forwardRef } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useTheme } from '@there/components/feed/ThemeContext'
import { WebkitAppRegion, WebStyled } from '@there/components/shared/WebStyled'
import { useHover } from '../shared/use-hover'
import { DefaultTheme } from 'styled-components'
import { darken, lighten } from 'polished'
import { Pressable } from '@there/components/shared/Pressable'

export const CircleButton = (
  {
    children,
    onPress,
    onLongPress,
    variant,
    size,
    backgroundMode = 'normal',
    isActive,
    options,
    disabled,
    backgroundColor = 'circleButtonBackground',
    hoverColor = 'circleButtonBackgroundHover',
    activeColor = 'circleButtonBackgroundActive',
    style,
  }: {
    children: React.ReactNode
    onPress?: () => void
    onLongPress?: () => void
    variant?: 'primary' | 'secondary'
    backgroundMode?: 'transparent' | 'normal'
    size?: number
    isActive?: boolean
    options?: () => any
    disabled?: boolean
    backgroundColor?: keyof DefaultTheme['colors']
    hoverColor?: keyof DefaultTheme['colors']
    activeColor?: keyof DefaultTheme['colors']
    style?: ViewStyle
  },
  ref: any,
) => {
  const t = useTheme()
  const [hovered, hoverListener] = useHover()

  let optionProps = options ? options() : {}
  return (
    <WebkitAppRegion mode="no-drag">
      <Pressable
        disabled={disabled || !onPress}
        activeOpacity={0.85}
        onPress={onPress}
        onLongPress={onLongPress}
        style={({ pressed }) => [
          {
            width: size || 26,
            height: size || 26,
            borderRadius: 26,
            backgroundColor:
              variant === 'primary'
                ? t.colors.primary
                : t.colors[backgroundColor],

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          backgroundMode === 'transparent' && {
            backgroundColor: 'none',
          },
          !disabled &&
            hovered && {
              backgroundColor: lighten(0.03, t.colors[backgroundColor]),
            },
          !disabled &&
            pressed && {
              backgroundColor: darken(0.03, t.colors[backgroundColor]),
            },
          !disabled &&
            isActive && {
              backgroundColor:
                t.colors[activeColor] ||
                darken(0.03, t.colors[backgroundColor]),
            },
          style,
        ]}
        {...hoverListener}
        {...optionProps}
      >
        <WebStyled
          style={{
            width: size || 28,
            height: size || 28,
            borderRadius: 28,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </WebStyled>
      </Pressable>
    </WebkitAppRegion>
  )
}
