import * as React from 'react'
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg'

export function DeafenIcon(props: { dark?: boolean }) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20">
      <path
        d="M13.596 13.457a.625.625 0 11-.61-1.091 2.707 2.707 0 00.018-4.722.625.625 0 01.619-1.086A3.957 3.957 0 0115.625 10c0 1.45-.786 2.762-2.029 3.457zm.395 3.117a.625.625 0 01-.542-1.126A6.041 6.041 0 0016.875 10a6.041 6.041 0 00-3.37-5.42.625.625 0 01.554-1.121A7.29 7.29 0 0118.125 10a7.291 7.291 0 01-4.134 6.574z"
        fill={props.dark ? 'rgba(255, 255, 255, 0.65)' : '#5A5A5A'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.833 13.333l-2.827-.565a1.667 1.667 0 01-1.34-1.634V8.866c0-.794.561-1.478 1.34-1.634l2.827-.565 2.744-2.744A.833.833 0 0110 4.512v10.976a.833.833 0 01-1.423.59l-2.744-2.745z"
        fill={props.dark ? 'rgba(255, 255, 255, 0.65)' : '#5A5A5A'}
      />
    </svg>
  )
}

export function DeafenIconMuted({
  color,
  ...props
}: { color?: string } & SvgProps) {
  return (
    <Svg width={20} height={20} fill="none" viewBox="0 0 20 20" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83 13.33l-2.82-.56a1.67 1.67 0 01-1.34-1.64V8.87c0-.8.56-1.48 1.34-1.64l2.82-.56 2.75-2.75a.83.83 0 011.42.6v10.97a.83.83 0 01-1.42.59l-2.75-2.75z"
        fill={color || 'rgba(255, 255, 255, 0.65)'}
      />
      <Path
        d="M14.71 11.18l-1.17 1.18a.83.83 0 11-1.18-1.18L13.54 10l-1.18-1.18a.83.83 0 011.18-1.18l1.17 1.18 1.18-1.18a.83.83 0 111.18 1.18L15.9 10l1.18 1.18a.83.83 0 01-1.18 1.18l-1.18-1.18z"
        fill={color || 'rgba(255, 255, 255, 0.65)'}
      />
    </Svg>
  )
}

export function SoundManagerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={7} height={5} viewBox="0 0 7 5" fill="none" {...props}>
      <path d="M6 1L3.5 3.5 1 1" stroke="#000" />
    </svg>
  )
}

export function MicrophoneIcon(
  props: React.SVGProps<SVGSVGElement> & { color?: string; muted?: boolean },
) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10.83 14.94v2.56a.83.83 0 01-1.66 0v-2.56c-2.83-.4-5-2.83-5-5.77h1.66a4.17 4.17 0 008.34 0h1.66a5.83 5.83 0 01-5 5.77z"
        fill={props.color ? props.color : '#6F6F6F'}
      />
      <path
        d="M12.5 4.17a2.5 2.5 0 00-5 0v5a2.5 2.5 0 005 0v-5z"
        fill={props.color ? props.color : '#6F6F6F'}
      />
      {/* <line
        x1="0.353553"
        y1="0.646447"
        x2="10.1313"
        y2="10.4242"
        stroke="white"
      /> */}
    </svg>
  )
}

export function Line45deg(
  props: React.SVGProps<SVGSVGElement> & { color?: string },
) {
  return (
    <svg width={20} height={20} viewBox="0 0 21 21" fill="none" {...props}>
      <path
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        d="M19 1.061L1.061 19"
      />
    </svg>
  )
}

export const ToggleArrowIcon = React.memo((props: SvgProps) => {
  return (
    <Svg width={8} height={11} viewBox="0 0 8 11" fill="none" {...props}>
      <Path
        d="M1 4.077L4 1l3 3.077M1 6.923L4 10l3-3.077"
        stroke={props.color ? props.color : '#fff'}
        strokeOpacity={0.37}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
})

export function ArrowDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={9} height={5} viewBox="0 0 9 5" fill="none" {...props}>
      <path
        d="M1 1l3.5 3L8 1"
        stroke={props.color ? props.color : '#FFF'}
        strokeOpacity={1}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function ArrowUpIcon(props: SvgProps) {
  return (
    <Svg width={10} height={6} viewBox="0 0 10 6" fill="none" {...props}>
      <Path d="M1 5l4-4 4 4" stroke={props.color ? props.color : '#fff'} />
    </Svg>
  )
}

export function SoundIcon(
  props: React.SVGProps<SVGSVGElement> & { color?: string },
) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        opacity={1}
        d="M13.596 13.457a.625.625 0 11-.61-1.091A2.707 2.707 0 0014.375 10c0-.985-.53-1.877-1.371-2.356a.625.625 0 01.618-1.086A3.956 3.956 0 0115.625 10c0 1.45-.787 2.762-2.029 3.457zm.395 3.117a.625.625 0 01-.542-1.126A6.041 6.041 0 0016.875 10a6.041 6.041 0 00-3.37-5.42.625.625 0 11.554-1.121A7.29 7.29 0 0118.125 10a7.291 7.291 0 01-4.134 6.574z"
        fill={props.color ? props.color : '#fff'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.833 13.333l-2.827-.565a1.667 1.667 0 01-1.34-1.634V8.866c0-.794.561-1.478 1.34-1.634l2.827-.565 2.744-2.744A.833.833 0 0110 4.512v10.976a.833.833 0 01-1.423.59l-2.744-2.745z"
        fill={props.color ? props.color : '#fff'}
      />
    </svg>
  )
}

export function MoveIcon(
  props: React.SVGProps<SVGSVGElement> & { color?: string },
) {
  return (
    <svg width={15} height={15} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M14.829 6.343l1.414-1.414L12 .686 7.758 4.93l1.414 1.414L12 3.515l2.829 2.828zM4.93 16.243l1.413-1.415L3.515 12l2.828-2.828L4.93 7.757.687 12l4.242 4.243zM7.758 19.071L12 23.314l4.243-4.243-1.414-1.414L12 20.485l-2.828-2.828-1.414 1.414zM17.657 9.172L20.485 12l-2.828 2.828 1.414 1.415L23.314 12 19.07 7.757l-1.414 1.415z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 110 4 2 2 0 010-4z"
        fill="#fff"
      />
    </svg>
  )
}

export function Minimize(
  props: React.SVGProps<SVGSVGElement> & { color?: string },
) {
  return (
    <svg
      width={9}
      height={2}
      viewBox="0 0 9 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 1a1 1 0 011-1h7a1 1 0 010 2H1a1 1 0 01-1-1z" fill="#ffffff" />
    </svg>
  )
}

export function BellIcon(props: SvgProps) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <G
        opacity={0.65}
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M14.5 7a4.5 4.5 0 10-9 0c0 5.25-2.25 6.75-2.25 6.75h13.5S14.5 12.25 14.5 7zM11.297 16.75a1.5 1.5 0 01-2.595 0" />
      </G>
    </Svg>
  )
}

export function SettingsIcon(props: SvgProps) {
  return (
    <Svg
      width={props.width || 18}
      height={props.height || 18}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <G
        opacity={props.opacity ? props.opacity : 1}
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" />
        <Path d="M14.55 11.25a1.238 1.238 0 00.247 1.365l.045.045a1.5 1.5 0 01-1.06 2.562 1.5 1.5 0 01-1.062-.44l-.045-.044a1.238 1.238 0 00-1.365-.248 1.238 1.238 0 00-.75 1.133v.127a1.5 1.5 0 11-3 0v-.068a1.238 1.238 0 00-.81-1.132 1.238 1.238 0 00-1.365.247l-.045.045a1.5 1.5 0 11-2.123-2.122l.046-.045a1.238 1.238 0 00.247-1.365 1.238 1.238 0 00-1.132-.75H2.25a1.5 1.5 0 110-3h.067a1.237 1.237 0 001.133-.81 1.237 1.237 0 00-.248-1.365l-.044-.045A1.5 1.5 0 115.28 3.217l.045.046a1.237 1.237 0 001.365.247h.06a1.237 1.237 0 00.75-1.132V2.25a1.5 1.5 0 013 0v.067a1.238 1.238 0 00.75 1.133 1.237 1.237 0 001.365-.248l.045-.044a1.5 1.5 0 112.123 2.122l-.045.045a1.237 1.237 0 00-.248 1.365v.06a1.237 1.237 0 001.133.75h.127a1.5 1.5 0 010 3h-.068a1.238 1.238 0 00-1.132.75v0z" />
      </G>
    </Svg>
  )
}

export function PlusIcon(props: SvgProps) {
  return (
    <Svg
      width={props.width || 16}
      height={props.height || 16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <Path
        d="M1 8h7m7 0H8m0 0V1m0 7v7"
        stroke={props.color ? props.color : '#fff'}
        strokeOpacity={0.6}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export function ThreeDotsIcon(props: SvgProps) {
  return (
    <Svg
      width={props.width || 18}
      height={props.height || 18}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <Circle
        cx={15.5}
        cy={8.5}
        r={1.5}
        fill={props.color ? props.color : '#fff'}
        fillOpacity={0.6}
      />
      <Circle
        cx={9.5}
        cy={8.5}
        r={1.5}
        fill={props.color ? props.color : '#fff'}
        fillOpacity={0.6}
      />
      <Circle
        cx={3.5}
        cy={8.5}
        r={1.5}
        fill={props.color ? props.color : '#fff'}
        fillOpacity={0.6}
      />
    </Svg>
  )
}

export function LobbyIcon(props: SvgProps) {
  return (
    <Svg
      width={props.width || 16}
      height={props.height || 16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <G
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M2 6l6-4.667L14 6v7.333a1.334 1.334 0 01-1.333 1.334H3.333A1.334 1.334 0 012 13.333V6z" />
        <Path d="M6 14.667V8h4v6.667" />
      </G>
    </Svg>
  )
}

export function Slash() {
  return (
    <Svg width={13} height={18} viewBox="0 0 13 18" fill="none">
      <Path d="M10 1L2 17" stroke="#fff" strokeOpacity={0.25} />
    </Svg>
  )
}

export function RefreshIcon(props: SvgProps) {
  return (
    <Svg width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
      <G
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M18.375 2v5H13.5" />
        <Path d="M2.625 10.5A7.875 7.875 0 0115.75 4.638L18.375 7M2.625 19v-5H7.5" />
        <Path d="M18.375 10.5A7.876 7.876 0 015.25 16.363L2.625 14" />
      </G>
    </Svg>
  )
}

export function HelpIcon(props: SvgProps) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        d="M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.817 6.75a2.25 2.25 0 014.373.75c0 1.5-2.25 2.25-2.25 2.25M9 12.75h.008"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export function YoutubeIcon(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M22.54 6.42a2.78 2.78 0 00-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 00-1.94 2A29 29 0 001 11.75a29 29 0 00.46 5.33A2.78 2.78 0 003.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 001.94-2c.312-1.732.466-3.49.46-5.25a29.005 29.005 0 00-.46-5.33v0z"
        stroke="#FF14A1"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.75 15.02l5.75-3.27-5.75-3.27v6.54z"
        stroke="#FF14A1"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
