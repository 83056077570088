import React, { useEffect } from 'react'
import { Section, SectionHeader } from './SettingsUI'
import { Space } from '../shared/Space'
import { ToggleRow } from '@there/components/settings/ToggleRow'
import { useAppContext } from '@there/components/shared/AppContext'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { electronSupports, ipc } from '@there/desktop/utils/electron-api'
import { useWindowContext } from '@there/components/shared/WindowContext'
import { logEvent } from '@there/components/shared/use-analytics'

export const ChatSection = () => {
  let { chatNoNotification, chatNoSound, dispatch } = useAppContext()
  let { isElectron, isMacOs } = useWindowContext()

  return (
    <Section>
      <SectionHeader>Chat</SectionHeader>
      <Space vertical={8} />

      <ToggleRow
        title="New Message Sound"
        description="Play sound on new direct message"
        value={!chatNoSound}
        onValueChange={(value) => {
          dispatch({
            type: 'chat notif preference changed',
            chatNoSound: !value,
            chatNoNotification: !!chatNoNotification,
          })
        }}
      />
      <ToggleRow
        title="New Message Notification"
        description="Show notification on new group and direct message"
        value={!chatNoNotification}
        onValueChange={(value) => {
          dispatch({
            type: 'chat notif preference changed',
            chatNoSound: !!chatNoSound,
            chatNoNotification: !value,
          })
        }}
      />
    </Section>
  )
}
