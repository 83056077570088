import { atom, PrimitiveAtom } from 'jotai'
import {
  atomWithReducer,
  atomWithStorage,
  useReducerAtom,
  createJSONStorage,
} from 'jotai/utils'

type View = 'home' | 'chat' | 'chats' | 'rooms' | 'gameRoom'
export const contentViewAtom = atom<View>('rooms')

type SideView = 'space' | 'home'
export const sideViewAtom = atom<SideView>('space')

export enum SpaceTabType {
  Space,
  Home,
}

type SpaceTabsState = {
  openTabs: (
    | { type: SpaceTabType.Home }
    | { type: SpaceTabType.Space; id: string; name: string; memberId: string }
  )[]
  activeTabIndex: number | null
}

const initialState: SpaceTabsState = {
  openTabs: [{ type: SpaceTabType.Home }],
  activeTabIndex: 0,
}
type Action =
  | { type: 'clear' }
  | { type: 'load'; state: SpaceTabsState }
  | { type: 'space opened'; id: string; name: string; memberId: string }
  | { type: 'tab closed'; index: number }
  | { type: 'tab switched'; index: number }
  | {
      type: 'tabs reordered'
      newTabs: SpaceTabsState['openTabs']
      activeTabIndex: number
    }

const PERSIST_KEY = 'spaceTabs'
const spaceTabsBaseAtom = atomWithStorage(
  PERSIST_KEY,
  initialState,
  createJSONStorage(() => localStorage),
)

const tabsReducer = (state: SpaceTabsState, action: Action): SpaceTabsState => {
  if (!action) return state

  switch (action.type) {
    case 'load':
      return action.state

    case 'tabs reordered':
      return {
        ...state,
        openTabs: action.newTabs,
        activeTabIndex: action.activeTabIndex,
      }

    case 'clear':
      return initialState

    case 'tab switched':
      return {
        ...state,
        activeTabIndex: action.index,
      }

    case 'space opened': {
      // Check if already opened, select it instead of adding a new tab
      let existingTabIndex = state.openTabs.findIndex(
        (tab) => tab.type === SpaceTabType.Space && tab.id === action.id,
      )

      if (typeof existingTabIndex === 'number' && existingTabIndex > 0) {
        return {
          ...state,
          activeTabIndex: existingTabIndex,
        }
      } else {
        return {
          ...state,
          openTabs: [
            ...state.openTabs,
            {
              type: SpaceTabType.Space,
              id: action.id,
              name: action.name,
              memberId: action.memberId,
            },
          ],
          activeTabIndex: state.openTabs.length,
        }
      }
    }

    case 'tab closed': {
      // Do not close home
      if (state.openTabs.length === 1) return state

      let openTabs = [
        ...state.openTabs.slice(0, action.index),
        ...state.openTabs.slice(action.index + 1),
      ]

      return {
        openTabs: openTabs,
        activeTabIndex: state.activeTabIndex
          ? // If last tab is closed, select previous tab
            Math.min(state.activeTabIndex, openTabs.length - 1)
          : null,
      }
    }

    default:
      return state
  }
}

export const useSpaceTabs = () => {
  let result = useReducerAtom(
    spaceTabsBaseAtom as PrimitiveAtom<SpaceTabsState>,
    tabsReducer,
  )
  return result
}

export const activeTabAtom = atom((get) => {
  let { openTabs, activeTabIndex } = get(spaceTabsBaseAtom)
  return activeTabIndex ? openTabs[activeTabIndex] : null
})
