import { useState } from 'react'
import { useUploadAvatar } from '../urql/uploadAvatar'

const pickerOpts = {
  types: [
    {
      description: 'Images',
      accept: {
        'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
      },
    },
  ],
  excludeAcceptAllOption: true,
  multiple: false,
}

export const useAvatarUploader = (): [
  () => Promise<void>,
  boolean,
  string | undefined,
] => {
  const [{ fetching: uploadAvatarLoading }, uploadAvatar] = useUploadAvatar()
  let [uploading, setUploading] = useState<boolean>(false)
  let [optimisticResult, setOptimisticResult] = useState<string | undefined>(
    undefined,
  )

  const getAvatar = async () => {
    try {
      if (typeof window !== 'undefined') {
        //@ts-ignore
        const fileHandle = await window.showOpenFilePicker(pickerOpts)
        if (fileHandle[0]) {
          setOptimisticResult(undefined)
          setUploading(true)
          const file = await fileHandle[0].getFile()
          const arrayBufferImage = await file.arrayBuffer()
          const base64 = _arrayBufferToBase64(arrayBufferImage)

          setOptimisticResult(`data:${file.type};base64,${base64}`)
          const user = await uploadAvatar({ file: file })
          setUploading(false)
        }
      }
    } catch (error) {}
  }

  return [getAvatar, uploading, optimisticResult]
}

function _arrayBufferToBase64(buffer: Buffer | ArrayBuffer) {
  let binary = ''
  let bytes = new Uint8Array(buffer)
  let len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}
