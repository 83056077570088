globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),

  get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }

};
// import * as Sentry from '@sentry/browser'
import * as Sentry from '@sentry/nextjs';
import config from '@there/desktop/config';
export default Sentry;
export var init = () => {
  Sentry.init({
    enabled: true,
    dsn: config.sentryDsn,
    // release: `app@${process.env.version}`,
    release: process.env.SENTRY_RELEASE
  });
};