import { Pressable } from '@there/components/shared/Pressable'
import { useHover } from '@there/components/shared/use-hover'
import { WebkitAppRegion } from '@there/components/shared/WebStyled'
import { GestureResponderEvent, Text, ViewProps, ViewStyle } from 'react-native'
import { DefaultTheme, useTheme } from 'styled-components'

interface Props {
  title?: string
  icon?: React.ReactNode
  tooltip?: React.ReactNode
  variant?: 'transparent' | 'normal'
  size?: 'normal' | 'small'
  activeColor?: keyof DefaultTheme['colors']
  active?: boolean
  disabled?: boolean

  /** For use in dropdown toggle button that already has a touchable opacity */
  onPressHandled?: boolean
  onPress?: (event: GestureResponderEvent) => void
  style?: ViewStyle
}

export const GoodButton = (props: Props & ViewProps) => {
  let {
    onPress,
    title,
    icon,
    variant = 'normal',
    size = 'normal',
    active,
    activeColor,
    disabled,
    onPressHandled,
    style,
    ...rest
  } = props

  let theme = useTheme()
  let [hovered, hoverListeners] = useHover()

  let iconOnly = !title
  let textOnly = !icon
  let textAndIcon = Boolean(icon && title)

  let sizeInPx = size === 'normal' ? 28 : size === 'small' ? 24 : undefined
  let borderRadius = size === 'normal' ? 28 : size === 'small' ? 8 : undefined
  let backgroundColor = active
    ? activeColor
      ? theme.colors[activeColor]
      : `rgba(255,255,255,0.12)`
    : variant === 'normal'
    ? `rgba(255,255,255,0.12)`
    : 'none'

  return (
    <WebkitAppRegion mode="no-drag">
      <Pressable
        style={({ pressed }) => [
          {
            width: iconOnly ? sizeInPx : 'auto',
            height: sizeInPx,
            borderRadius,
            backgroundColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            // Because at the top of the page, cursor is default, make all default
            // @ts-ignore
            cursor: 'default',
          },

          iconOnly && {
            paddingRight: 0,
            paddingLeft: 0,
          },

          textAndIcon && {
            paddingRight: 11,
            paddingLeft: 5,
          },

          textOnly && {
            paddingHorizontal: 11,
          },

          // Hover
          hovered &&
            !disabled && {
              backgroundColor:
                variant === 'transparent'
                  ? `rgba(255,255,255,0.1)`
                  : `rgba(255,255,255,0.2)`,
            },

          // Pressed
          pressed &&
            !disabled && {
              backgroundColor: `rgba(255,255,255,0.1)`,
              opacity: 0.95,
              transform: [{ scale: 0.95 }],
            },

          !disabled &&
            active &&
            activeColor && {
              backgroundColor: theme.colors[activeColor] || 'none',
            },

          {
            //@ts-ignore
            backdropFilter: `blur(3px)`,
          },

          style,
        ]}
        disabled={onPressHandled || disabled}
        onPress={onPress}
        {...hoverListeners}
        {...rest}
      >
        {icon}

        {title && (
          <Text
            style={{
              color: theme.colors.tertiaryText,
              fontSize: theme.fontSizes.normal,
              marginLeft: 6,
              marginRight: 4,
            }}
          >
            {title}
          </Text>
        )}
      </Pressable>
    </WebkitAppRegion>
  )
}
