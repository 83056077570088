import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ActivityIndicator, Text, View } from 'react-native'
import { rtcCoreAtom, useRtcContext } from '../shared/use-rtc'
import { Dropdown } from './Dropdown'
import { useTheme } from './ThemeContext'
import Lottie, { useLottie } from 'lottie-react'
import connectingAnimation from '../../app/utils/lottie/connecting.json'
import { useLatest } from '../shared/use-latest'
import { useLockDialog } from '../main/useLockDialog'
import { ToggleRow } from '../settings/ToggleRow'
import { useFocusState } from './useFocusState'
import { ScaleLoading } from '../shared/ScaleLoading'

const indicatorSize = 16

export const ConnectionIndicator = () => {
  let [isOpen, setOpen] = useState(false)
  let [{ isInitiallyConnecting }] = useAtom(rtcCoreAtom)
  let { isInCall, participants, walkieState } = useRtcContext()

  let alone = participants.length <= 1
  let wParticipants = walkieState.participants
  let connecting = useMemo(() => {
    let initialConnecting = participants.length > 1 && isInitiallyConnecting
    let peerConnecting = false
    for (let participant of Object.values(wParticipants)) {
      if (
        participant.peerState === 'connecting' ||
        participant.peerState === 'closed'
      ) {
        peerConnecting = true
        break
      }
    }
    return initialConnecting || peerConnecting
  }, [isInitiallyConnecting, participants, wParticipants])

  let closeMenu = useCallback(() => {
    setOpen(false)
  }, [])

  let {
    currentDialogLockStatus,
    toggleLockDialog,
    hasLockPermission,
  } = useLockDialog()

  let theme = useTheme()
  return (
    <View
      style={{
        display: alone ? 'none' : 'flex',
        overflow: 'hidden',
      }}
    >
      <Dropdown.Root>
        <Dropdown.Toggle
          onPress={() => {
            setOpen(true)
          }}
        >
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: indicatorSize,
              height: indicatorSize,
            }}
          >
            {alone ? (
              <></>
            ) : connecting ? (
              <ScaleLoading
                size={14}
                color={theme.colors.primaryButtonBackground}
              />
            ) : (
              <View
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  backgroundColor: theme.colors.green,
                }}
              />
            )}
          </View>
        </Dropdown.Toggle>
        <Dropdown.Menu isOpen={isOpen} onClose={closeMenu} width={160}>
          <View
            style={{
              paddingHorizontal: 8,
              paddingVertical: 4,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text
              style={{
                lineHeight: 26,
                fontSize: theme.fontSizes.normal,
                color: theme.colors.secondaryText,
              }}
            >
              {connecting ? 'Connecting...' : 'Connected'}
            </Text>
            {/* <ToggleRow
              title="Let's Focus"
              description="Volume down others"
              value={!!weFocused}
              onValueChange={(value) => {
                toggleFocus(value)
              }}
            /> */}
            {hasLockPermission && (
              <ToggleRow
                title="Room Lock"
                description="Prevent others to join"
                value={!!currentDialogLockStatus}
                onValueChange={(value) => {
                  toggleLockDialog(value)
                }}
              />
            )}
          </View>
        </Dropdown.Menu>
      </Dropdown.Root>
    </View>
  )
}
