import { useCallback, useEffect, useRef } from 'react'
import { useLatest } from './use-latest'

export const useDebounceCallback = (callback: () => void, delay: number) => {
  const timer = useRef(0)
  const timerId = useRef(0)
  const callbackRef = useLatest(callback)

  useEffect(() => {
    timer.current = performance.now()
  }, [])

  const debouncedCallback = useCallback(() => {
    if (!callbackRef.current) return
    let now = performance.now()
    if (now > delay + timer.current) {
      timer.current = now
      callbackRef.current()
      if (timerId.current) clearTimeout(timerId.current)
    } else {
      timerId.current && clearTimeout(timerId.current)
      // not yet time (safety timeout if no other time it's called)
      timerId.current = setTimeout(() => {
        callbackRef.current && callbackRef.current()
      }, delay)
    }
  }, [callbackRef, delay])

  return {
    debouncedCallback,
    normalCallback: useCallback(() => callbackRef.current?.(), [callbackRef]),
  }
}
