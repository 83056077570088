import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export function CameraIcon(props: SvgProps) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        d="M9.8 4.725H2.518C1.624 4.725.9 5.367.9 6.159v5.738c0 .792.724 1.434 1.618 1.434H9.8c.894 0 1.619-.642 1.619-1.434V6.159c0-.792-.725-1.434-1.619-1.434z"
        fill={props.color ? props.color : '#fff'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.156 6.411v5.234c0 .403-.383.73-.856.73a.937.937 0 01-.605-.214l-3.07-2.617a.656.656 0 010-1.032l3.07-2.617a.964.964 0 011.21 0 .68.68 0 01.251.516z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}
