export function debounce<F extends AnyToVoidFunction>(
  fn: F,
  ms: number,
  shouldRunFirst = true,
  shouldRunLast = true,
) {
  let waitingTimeout: number | undefined

  return (...args: Parameters<F>) => {
    if (waitingTimeout) {
      clearTimeout(waitingTimeout)
      waitingTimeout = undefined
    } else if (shouldRunFirst) {
      // @ts-ignore
      fn(...args)
    }

    // eslint-disable-next-line no-restricted-globals
    waitingTimeout = self.setTimeout(() => {
      if (shouldRunLast) {
        // @ts-ignore
        fn(...args)
      }

      waitingTimeout = undefined
    }, ms)
  }
}

export function throttle<F extends AnyToVoidFunction>(
  fn: F,
  ms: number,
  shouldRunFirst = true,
) {
  let interval: number | undefined
  let isPending: boolean
  let args: Parameters<F>

  return (..._args: Parameters<F>) => {
    isPending = true
    args = _args

    if (!interval) {
      if (shouldRunFirst) {
        isPending = false
        // @ts-ignore
        fn(...args)
      }

      // eslint-disable-next-line no-restricted-globals
      interval = self.setInterval(() => {
        if (!isPending) {
          // eslint-disable-next-line no-restricted-globals
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          self.clearInterval(interval!)
          interval = undefined
          return
        }

        isPending = false
        // @ts-ignore
        fn(...args)
      }, ms)
    }
  }
}

function runNow(fn: NoneToVoidFunction) {
  fn()
}

export const pause = (ms: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms)
  })

export function rafPromise() {
  return new Promise<void>((resolve) => {
    fastRaf(resolve)
  })
}

let fastRafCallbacks: NoneToVoidFunction[] | undefined
let fastRafPrimaryCallbacks: NoneToVoidFunction[] | undefined

// May result in an immediate execution if called from another `requestAnimationFrame` callback
export function fastRaf(callback: NoneToVoidFunction, isPrimary = false) {
  if (!fastRafCallbacks) {
    fastRafCallbacks = isPrimary ? [] : [callback]
    fastRafPrimaryCallbacks = isPrimary ? [callback] : []

    requestAnimationFrame(() => {
      const currentCallbacks = fastRafCallbacks!
      const currentPrimaryCallbacks = fastRafPrimaryCallbacks!
      fastRafCallbacks = undefined
      fastRafPrimaryCallbacks = undefined
      for (const cb of currentPrimaryCallbacks) cb()
      for (const cb of currentCallbacks) cb()
    })
  } else if (isPrimary) {
    fastRafPrimaryCallbacks?.push(callback)
  } else {
    fastRafCallbacks.push(callback)
  }
}

export const safeRequestIdleCallback = (
  callback: () => void,
  options?: IdleRequestOptions | undefined,
): number | undefined => {
  if (typeof requestIdleCallback !== 'undefined') {
    return requestIdleCallback(callback, options)
  } else {
    // Hack for safari
    setTimeout(() => {
      callback()
    }, 0)
  }
}
