import { View, Text, ScrollView } from 'react-native'
import { useSpaceTabs } from '../atoms/contentViewAtom'

import { useTheme } from '../feed/ThemeContext'
import { useMemberships } from '../feed/useMemberships'
import { Pressable } from '../shared/Pressable'
import { MemberWithSpaceInfo } from '../urql/fragments/memberInfo'
import { useRouter } from 'next/router'
import { TextButton } from '@there/components/main/TextButton'
import { useEffect, useRef, useState } from 'react'
import { fromGlobalId } from '@there/tower/utils/global-id'

export const HomeTab = () => {
  let { memberships } = useMemberships()
  let router = useRouter()

  // Sort spaces based on last opened
  let [dates, setDates] = useState<Record<string, number>>({})
  useEffect(() => {
    let dates = getSpaceDates()
    setDates(dates)
  }, [])

  // Tabs
  let [, tabsDispatch] = useSpaceTabs()

  return (
    <View style={{ height: '100%' }}>
      <ScrollView style={{ flexGrow: 1 }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',

            width: '100%',
          }}
        >
          {memberships
            ?.sort((a, b) => {
              let aDate = dates[a.space.id]
              let bDate = dates[b.space.id]
              if (!aDate && !bDate) return 0
              if (!aDate) return 1
              if (!bDate) return -1
              return bDate - aDate
            })
            .map((membership) => {
              let switchSpace = () => {
                tabsDispatch({
                  type: 'space opened',
                  id: membership.space.id,
                  memberId: membership.id,
                  name: membership.space.name || '',
                })
              }

              return (
                <SpaceItem
                  membership={membership}
                  key={membership.id}
                  onPress={() => {
                    saveSpaceDate(membership.space.id)

                    switchSpace()
                  }}
                />
              )
            })}
        </View>
      </ScrollView>

      <View
        style={{
          height: 41,
          borderTopWidth: 1,
          borderTopColor: 'rgba(255, 255, 255, 0.06)',

          paddingHorizontal: 8,

          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <TextButton
          onPress={() => {
            router.push(`/login/create-space`)
          }}
        >
          Create New Space
        </TextButton>
        <TextButton
          onPress={() => {
            router.push(`/join-space?joinSecretCode=ZCBkzcCPEAu2NZteKVTfM`)
          }}
        >
          Join Noor Community
        </TextButton>
      </View>
    </View>
  )
}

const SpaceItem = ({
  membership,
  onPress,
}: {
  membership: MemberWithSpaceInfo
  onPress: () => void
}) => {
  let theme = useTheme()
  return (
    <Pressable
      onPress={onPress}
      style={({ hovered, pressed }) => [
        {
          width: '44%',
          height: 45,
          // height: 73,
          borderWidth: 1,
          borderColor: 'rgba(255, 255, 255, 0.17)',
          borderRadius: 12,

          marginLeft: '4%',
          marginTop: 20,

          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: 12,
        },
        hovered && {
          backgroundColor: 'rgba(255, 255, 255, 0.03)',
        },
        pressed && {
          opacity: 0.8,
        },
      ]}
    >
      <Text
        style={{
          fontSize: theme.fontSizes.large,
          fontWeight: 'bold',
          color: theme.colors.secondaryText,
          maxWidth: '100%',
        }}
        numberOfLines={1}
      >
        {membership.space.name}
      </Text>
    </Pressable>
  )
}

function getSpaceDates() {
  return JSON.parse(localStorage.getItem('space-dates') || '{}')
}

export function saveSpaceDate(spaceId: string) {
  let dates = JSON.parse(localStorage.getItem('space-dates') || '{}')
  dates[spaceId] = Date.now()
  localStorage.setItem('space-dates', JSON.stringify(dates))
}
