import { useCallback, useMemo } from 'react'
import { useUpdateFocus } from '../main/useUpdateFocus'
import { useAppContext } from '../shared/AppContext'
import { useUsersContext } from '../shared/UsersContext'
import { useUser } from '@there/components/atoms/userAtom'

export const useFocusState = () => {
  let { currentUserId } = useAppContext()
  let currentUser = useUser(currentUserId || '')

  let weFocused = currentUser?.focused

  let [, updateFocus] = useUpdateFocus()
  let toggleFocus = useCallback(
    (value: boolean) => {
      if (!currentUserId) return
      updateFocus({
        focus: value,
        __extra: {
          userId: currentUserId,
        },
      })
    },
    [currentUserId, updateFocus],
  )

  return { toggleFocus, weFocused }
}
