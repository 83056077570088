import { SpaceQueryReply } from '@there/sun/modules/space'
import { SpaceChatQueryReply } from '@there/sun/modules/spaceChats'
import { useEffect, useState } from 'react'
import { SpaceTabType, useSpaceTabs } from '../atoms/contentViewAtom'
import { useNurClient } from '../sun/context'

type OpType = {
  unsubscribe: () => void
  reExecute: () => void
  fetchMore: (variables: Record<string, any>) => void
}

export const useSpacesInfo = () => {
  let [tabs, tabsDispatch] = useSpaceTabs()
  let client = useNurClient()
  let [spacesInfo, setSpacesInfo] = useState<
    Record<string, SpaceQueryReply['space']>
  >({})

  let [spacesChats, setSpacesChats] = useState<
    Record<string, SpaceChatQueryReply['spaceChats']>
  >({})

  useEffect(() => {
    if (!client) {
      return
    }
    let ops: OpType[] = []
    for (let space of tabs.openTabs) {
      if (space.type === SpaceTabType.Home) continue
      if (!space.id) continue
      let spaceId = space.id
      let op = client.query(
        {
          method: 'space',
          variables: { spaceId: String(spaceId) },
        },
        {
          next: (result) => {
            setSpacesInfo((spacesInfo) => ({
              ...spacesInfo,
              [spaceId]: result.data?.space,
            }))
          },
          complete: () => {},
        },
        { fetchPolicy: 'cache-only' },
      )
      ops.push(op)
    }

    return () => {
      for (let op of ops) {
        op.unsubscribe()
      }
    }
  }, [client, tabs.openTabs])

  useEffect(() => {
    if (!client) {
      return
    }
    let ops: OpType[] = []
    for (let space of tabs.openTabs) {
      if (space.type === SpaceTabType.Home) continue
      if (!space.id) continue
      let spaceId = space.id
      let op = client.query(
        {
          method: 'spaceChats',
          variables: { spaceId: String(spaceId) },
        },
        {
          next: (result) => {
            if (result.data) {
              setSpacesChats((spacesChats) => ({
                ...spacesChats,
                [spaceId]: result.data.spaceChats,
              }))
            }
          },
          complete: () => {},
        },
        { fetchPolicy: 'cache-only' },
      )
      ops.push(op)
    }

    return () => {
      for (let op of ops) {
        op.unsubscribe()
      }
    }
  }, [client, tabs.openTabs])

  return { spacesInfo, spacesChats }
}
