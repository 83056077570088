import React, {
  Fragment,
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useAppContext } from '@there/components/shared/AppContext'
import { useSpaceContext } from '@there/components/shared/spaceContext'
import { View } from 'react-native'
import {
  AvatarInfo,
  AvatarWithUserInfo,
  DialogInfo,
  DialogWithAvatarsInfo,
  DialogWithFullAvatarsInfo,
  MemberWithUserInfo,
} from '@there/sun/utils/node-types'
import isEqual from 'react-fast-compare'
import {
  dialogBoxBorderWidth,
  minDialogHeight,
  NewDialogItem,
  titleHeight,
} from '@there/components/main/DialogItem'
import { useUsersContext } from '@there/components/shared/UsersContext'
import {
  sortDialogs,
  sortDialogsSpecialType,
} from '@there/components/feed/sortDialogs'
import { CreateRoomItem } from '@there/components/main/CreateRoomItem'
import { useAtom } from 'jotai'
import { useTransition, a } from '@react-spring/native'
import { featurePreviewsAtom } from '@there/components/atoms/feedAtoms'
import { currentDialogIdAtom } from '@there/components/feed/useLobbyMan'
import { useRtcAvatarsContext } from '@there/components/shared/use-rtc-avatars'
import { contentViewAtom } from '../atoms/contentViewAtom'
import { useMainWindowContext } from '../shared/use-main-window'
import { usePrevious } from '@there/components/shared/use-previous'
import { RoomsHeader } from '../v2/RoomsHeader'
import { NIGHTLY_APP_URL } from '../config'
import { CurrentGameBox } from '../gameRoom/CurrentGameBox'
import { useRoomsScrollDialogsManager } from '@there/components/feed/roomsScroll'

export const itemWidth = 56
export const itemHeight = 74 + 4 /** vertical gap */
export const profileGap = 10
export const profileWidth = 46
export const dilogPaddingLeft = 8

// Used in DialogItem to calculate dialogs maxWidth
export const peoplePaddingHorizontal = 5

type PcProps = {
  minHeight?: number
  parentWidth?: number
}

let prevActiveSpaceId = { current: '' }

export const PeopleContainer = ({ minHeight, parentWidth }: PcProps) => {
  let { currentUserId, activeSpaceId } = useAppContext()
  let [
    {
      space,
      getAvatarByUserId,
      lobbyDialog,
      dialogs: allDialogs,
      getDialogAvatars,
      currentDialog,
    },
  ] = useSpaceContext()
  let [currentDialogId] = useAtom(currentDialogIdAtom)
  let { getUser } = useUsersContext()
  let { avatars: activeDialogAvatars } = useRtcAvatarsContext()
  let { dispatch } = useMainWindowContext()

  /**
   * Save latest active space id in
   */
  let updateActiveSpaceIdTimeout = useRef(0)
  useLayoutEffect(() => {
    // Throttle by 100 milliseconds
    clearTimeout(updateActiveSpaceIdTimeout.current)
    updateActiveSpaceIdTimeout.current = setTimeout(() => {
      prevActiveSpaceId.current = activeSpaceId || ''
    }, 100)
  }, [activeSpaceId])

  const members = useMemo(() => {
    /** Add avatar to member user */
    function mapMember(member: MemberWithUserInfo): MemberWithUserInfo {
      let avatar = member.user ? getAvatarByUserId(member.user.id) : undefined
      return {
        ...member,
        user: { ...member.user, avatar },
      }
    }

    return (space?.members || []).map(mapMember)
  }, [getAvatarByUserId, space])

  useEffect(() => {
    if (currentDialog?.title?.toLowerCase().includes('game')) {
      // go to game room view
      // dispatch({ type: 'change mode', mode: 'room', isGameRoom: true })
    }
  }, [currentDialog, dispatch])
  const [dialogs, avatarsInDialogs] = useMemo(() => {
    let avatarsInDialogs: AvatarWithUserInfo[] = []

    /** Add avatar to member user */
    function mapDialog(
      dialog: DialogWithAvatarsInfo,
    ): DialogWithFullAvatarsInfo {
      let isActive = currentDialogId === dialog.id

      let avatars: AvatarInfo[] =
        getDialogAvatars(dialog.id) || (isActive ? activeDialogAvatars : [])

      let filteredAvatars = avatars
        // Use map and filter at the same time
        .reduce(
          (filteredAvatars: AvatarWithUserInfo[], avatar: AvatarInfo) => {
            // Filter if not user
            let user = avatar.userId ? getUser(avatar.userId) : null

            if (!user) return filteredAvatars

            filteredAvatars.push({
              ...avatar,
              user,
            })

            return filteredAvatars
          },
          // filtered avatars
          [] as AvatarWithUserInfo[],
        )

      avatarsInDialogs.push(...filteredAvatars)

      return {
        ...dialog,
        // avatars: dialog.avatars
        avatars: filteredAvatars,
      }
    }

    let dialogs = (allDialogs || []).map(mapDialog).sort(sortDialogsSpecialType)

    return [dialogs, avatarsInDialogs]
    // Filter lobby
    // .filter(dialog => !dialog.specialType)
  }, [
    activeDialogAvatars,
    currentDialogId,
    allDialogs,
    getDialogAvatars,
    getUser,
  ])

  return (
    <Rooms
      key={activeSpaceId}
      activeSpaceId={activeSpaceId}
      parentWidth={parentWidth}
      members={members}
      dialogs={dialogs}
      avatarsInDialogs={avatarsInDialogs}
      showingLobby={true}
      currentUserId={currentUserId}
      lobbyDialogId={lobbyDialog?.id}
      minHeight={minHeight}
    />
  )
}

type Props = {
  members: MemberWithUserInfo[] | undefined
  dialogs: DialogWithFullAvatarsInfo[]
  showingLobby: boolean
  currentUserId: string | undefined
  avatarsInDialogs: AvatarWithUserInfo[]
  lobbyDialogId: string | undefined
  minHeight?: number
  parentWidth?: number
  activeSpaceId: string | undefined
}

export const Rooms = memo(
  ({
    dialogs,
    minHeight,
    parentWidth,
    activeSpaceId,
    avatarsInDialogs,
  }: Props) => {
    let [featurePreviews] = useAtom(featurePreviewsAtom)
    let [isInitialRender, setInitialRender] = useState(true)

    let { isFeedOpen } = useMainWindowContext()

    let previousActiveSpaceId = usePrevious(activeSpaceId)
    let spaceChanged = previousActiveSpaceId !== activeSpaceId

    useEffect(() => {
      setInitialRender(true)

      let id = setTimeout(() => {
        setInitialRender(false)
      }, 100)

      return () => clearTimeout(id)
    }, [spaceChanged])

    // let hasParentWidth = !!parentWidth
    // useEffect(() => {
    //   if (hasParentWidth) {
    //     setInitialRender(false)
    //   }
    // }, [hasParentWidth])

    const { dialogBoxes, wrapperHeight } = parentWidth
      ? arrangeDialogs({
          dialogs,
          parentWidth: parentWidth - 12,
          forceFullMode: isFeedOpen,
        })
      : { dialogBoxes: [], wrapperHeight: 0 }

    useRoomsScrollDialogsManager(dialogBoxes)

    const renderDialog = (
      animatedStyles: any,
      dialogBox: DialogBox,
      index: number,
    ) => {
      return (
        <a.View
          key={
            dialogBox.type === 'dialog' ? dialogBox.dialog.id : dialogBox.type
          }
          style={[
            {
              // Workaround for JoinLobbyDoubleClick
              // @ts-ignore
              pointerEvents: 'auto',

              position: 'absolute',
              left: 0,
              top: 0,
              overflow: 'hidden',
            },
            {
              width: animatedStyles.width,
              height: animatedStyles.height,
              transform: [
                { translateX: animatedStyles.x },
                { translateY: animatedStyles.y },
              ],
            },
          ]}
        >
          {dialogBox.type === 'dialog' ? (
            <NewDialogItem
              dialog={dialogBox.dialog}
              // compactRoom={dialogBox.height === minDialogHeight}
              compactRoom={false}
            />
          ) : (
            <CreateRoomItem showPlus={true} />
          )}
        </a.View>
      )
    }

    let render = useTransition(dialogBoxes, {
      key: (item: DialogBox) =>
        item.type === 'dialog' ? item.dialog.id : item.type,

      // Overrides from
      from:
        isInitialRender || spaceChanged
          ? null
          : {
              width: 0,
              height: 0,
              x: 0,
              y: 0,
            },
      // Logs a lot of consoles
      // initial: false,
      update: (item) => ({
        width: item.width,
        height: item.height,
        x: item.x,
        y: item.y,
      }),
      enter: (item) => ({
        width: item.width,
        height: item.height,
        x: item.x,
        y: item.y,
        scale: 1,
      }),
      leave: () => {
        return {
          width: 0,
          height: 0,
          opacity: 0,
          scale: 0,
          // When room is deleted, just scale it down.
          // x: 0,
          // y: 0,
        }
      },
      config: () => {
        if (prevActiveSpaceId.current !== activeSpaceId) {
          return { duration: 0 }
        }
        return {
          mass: 0.1,
          tension: 500,
          friction: 30,
        }
      },
    })

    return (
      <>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',

            // Workaround for JoinLobbyDoubleClick
            // @ts-ignore
            pointerEvents: 'none',
            width: '100%',

            // to prevent createRoom and footer buttons overlay when scroll ends
            height: wrapperHeight + 50 - 24,

            minHeight,
          }}
        >
          <RoomsHeader avatarsInDialogs={avatarsInDialogs} />

          {render((styles, item, _, index) =>
            renderDialog(styles, item, index),
          )}
        </View>
      </>
    )
  },
  isEqual,
)

export type DialogBox =
  | {
      type: 'dialog'
      dialog: DialogWithFullAvatarsInfo
      x: number
      y: number
      width: number
      height: number
    }
  | {
      type: 'new'
      x: number
      y: number
      width: number
      height: number
    }

function arrangeDialogs(input: {
  /** sorted dialogs ready to be arranged as they appear */
  dialogs: DialogWithFullAvatarsInfo[]
  parentWidth: number
  forceFullMode?: boolean
}): {
  dialogBoxes: DialogBox[]
  halfWidth: number
  /** Use for scroll view inner wrapper */
  wrapperHeight: number
} {
  let forceFullMode = input.forceFullMode
  // Our numbers
  let parentWidth = input.parentWidth
  let roomGap = 10
  let roomsPerRow = parentWidth > 500 ? 3 : 2
  let personWidth = itemWidth
  // Don't include wrapper sidePaddings
  let halfWidth =
    // dunno what it is the "+ 1" but without it, it breaks at certain points
    (parentWidth - (roomGap + 1) * roomsPerRow) / roomsPerRow
  let fullWidth = parentWidth - roomGap
  let fitsInHalfWidth = Math.floor(
    (halfWidth - dilogPaddingLeft - dialogBoxBorderWidth * 2) / personWidth,
  )
  let fitsInFullWidth = Math.floor(fullWidth / personWidth)

  // Functions
  function getRoomHeight(rowsCount: number, dialog: DialogInfo) {
    // Keep lobby expanded
    // let minRows = dialog.specialType === 'lobby' ? 1 : 0
    // Keep all min 1 row
    let minRows = 1

    return Math.max(
      titleHeight + 2 + itemHeight * Math.max(rowsCount, minRows),
      minDialogHeight,
    )
  }

  // Result
  let rows: {
    dialogBoxes: DialogBox[]
    // Taken space
    height: number
    width: number
  }[] = [{ dialogBoxes: [], height: 0, width: 0 }]

  // Our for-loop state
  let firstRowToCheckIndex = 0

  function pushToFirstSuitableRow(dialogBox: DialogBox) {
    let height = dialogBox.height
    let width = dialogBox.width

    for (
      let rowIndex = firstRowToCheckIndex;
      rowIndex <= rows.length;
      rowIndex++
    ) {
      if (!rows[rowIndex]) {
        // Create next row
        rows.push({
          dialogBoxes: [],
          height: 0,
          width: 0,
        })
      }

      let row = rows[rowIndex]

      if (fullWidth - row.width >= width) {
        // Has space, put it here.
        rows[rowIndex].dialogBoxes.push({
          ...dialogBox,
          x: rows[rowIndex].width + roomGap,
          // todo, later we fill it up
          y: 0,
        })

        // Add row width and height
        rows[rowIndex].width = rows[rowIndex].width + width + roomGap
        /** No gap for height, as it's one row */
        if (rows[rowIndex].height < height) {
          rows[rowIndex].height = height
        }

        // Set next row as first row to check
        if (rows[rowIndex].width > fullWidth) {
          firstRowToCheckIndex++
        }

        break
      }
    }
  }

  for (let dialog of input.dialogs) {
    // Dialog dimensions
    let avatarsCount = Number(dialog.avatars.length)
    let isFullWidth =
      avatarsCount > fitsInHalfWidth ||
      // dialog.specialType === 'lobby' ||
      forceFullMode
    let width = isFullWidth ? fullWidth : halfWidth
    let height = getRoomHeight(
      Math.ceil(avatarsCount / fitsInFullWidth),
      dialog,
    )

    // Find the row it fits in
    pushToFirstSuitableRow({
      type: 'dialog',
      dialog,
      width,
      height,
      // Will be filled later
      x: 0,
      y: 0,
    })
  }

  // Add "new" button to last row
  // Find the row it fits in
  pushToFirstSuitableRow({
    type: 'new',
    width: forceFullMode ? fullWidth : halfWidth,
    height: minDialogHeight,
    // Will be filled later
    x: 0,
    y: 0,
  })

  let fullyArrangedDialogBoxes: DialogBox[] = []

  // Put dialogs in one array
  let currentY = 26 // for roomHeight
  for (let row of rows) {
    for (let dialogBox of row.dialogBoxes) {
      fullyArrangedDialogBoxes.push({
        ...dialogBox,
        y: currentY,
        height: row.height,
      })
    }

    // Update it for next row
    currentY = currentY + row.height + roomGap
  }

  return {
    dialogBoxes: fullyArrangedDialogBoxes,
    halfWidth,
    wrapperHeight: currentY,
  }
}
