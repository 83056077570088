import * as React from 'react'
import Svg, { SvgProps, G, Path } from 'react-native-svg'

export function ScreenShareIcon(props: SvgProps) {
  return (
    <Svg width={15} height={12} viewBox="0 0 15 12" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2a2 2 0 012-2h8a2 2 0 012 2H4a2 2 0 00-2 2v5a2 2 0 01-2-2V2zm6 2a2 2 0 00-2 2v4a2 2 0 002 2h7a2 2 0 002-2V6a2 2 0 00-2-2H6z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}
