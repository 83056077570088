import * as Sentry from '@sentry/browser'
import { ContentHint } from '@there/components/rtc/ContentHintPicker'
import { Quality } from '@there/components/rtc/QualityPicker'
import {
  RemoteTracksMap,
  RtcConnectionStateType,
  RtcPeer,
  RtcSignalData,
  RtcTracksConfig,
} from '@there/components/shared/rtc-peer'
import { useConnectivity } from '@there/components/shared/use-connectivity'
import { usePeerLogger } from '@there/components/shared/use-peer-logger'
import {
  UniversalDataChannel,
  useRtcUniversalData,
} from '@there/components/shared/use-rtc-universal-data'
import { useStatsGatherer } from '@there/components/shared/use-stats-gatherer'
import { useDeepMemo } from '@there/components/shared/useDeepMemo'
import { preferCodecInSdp } from '@there/components/utils/prefer-codec'
import {
  IdleStateValues,
  ipc,
  isElectron,
  isWinOS,
} from '@there/desktop/utils/electron-api'
import { filterFalsy } from '@there/shared/utilities/filter-falsy'
import { IceServer } from '@there/tower/types'
import { Draft } from 'immer'
import ms from 'ms'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useImmerReducer } from 'use-immer'
import { useAppContext } from './AppContext'
import { useLatest } from './use-latest'
import { WalkieCallParticipant } from './use-rtc'
import { StreamsObject } from './use-streams'
import { useUniversalReducer } from './use-universal-reducer'

const debugRaw = require('debug')('desktop:use-room-peer')

function debug(...args: any[]) {
  debugRaw(...args)
  Sentry.addBreadcrumb({ message: args[0], data: args.slice(1) })
}

type ThereTrackName = 'mic' | 'camera' | 'screen' | 'systemAudio'
type RtcPeerType = RtcPeer<ThereTrackName>
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type Override<T, U> = Omit<T, keyof U> & U

export type SendToUser = (userId: string, data: DataType) => boolean
export type SendToAll = (data: DataType) => number
export type PeerOnJsonData = (
  context: PeerEventContext,
  data: DataType,
  remotePrivilege: boolean,
) => void
export type PeerOnRawData = (context: PeerEventContext, data: any) => void
export type SignalFlags = {
  initialOffer: boolean
}
export type TriggerParticipantsReset = (options?: {
  onlyThoseWeAreInitialOfferer?: boolean
  onlyThoseWeAreImpolite?: boolean
}) => void
export type Tracks = {
  microphone?: MediaStreamTrack
  camera?: MediaStreamTrack
  screen?: MediaStreamTrack
}

export type WalkieCallManager = {
  sendToUser: SendToUser
  sendToUserScreenChannel: SendToUser
  sendToAll: SendToAll
  universalDataChannel: UniversalDataChannel
  walkieState: WalkieState
  walkieStateRef: {
    current:
      | Override<WalkieState, { participants: MaybeParticipants }>
      | undefined
  }
  onSignal: (userId: string, signal: string, extra: { peerId: string }) => void
  dispatch: WalkieDispatch
  peerMicrophoneReady: boolean
  triggerConnectionRecheck: () => void
  triggerParticipantReset: (userId: string) => void
  triggerParticipantsReset: TriggerParticipantsReset
  ourObservers: string[]
}

type Props = {
  streams: StreamsObject
  participants: WalkieCallParticipant[]
  callToken: string | undefined
  callId: string | undefined
  sessionId: string | undefined | null
  iceServers?: IceServer[]
  silent: boolean | undefined | null
  enabled: boolean
  shouldUserHearUs?: (
    userId: string,
    participant?: WalkieCallParticipant,
  ) => boolean
  onJsonData?: PeerOnJsonData
  onUniversalJsonData?: (userId: string, data: DataType) => void
  onRawData?: PeerOnRawData
  cleanUp?: () => void
  onEnd?: () => void
  onParticipantLeave?: (userId: string, callId?: string) => void
  onSomeoneTalk?: () => void
  onSomeoneTalkStop?: () => void
  /** Used to know when walkie call goes inactive to end it */
  onActivityHappen?: () => void
  broadcastSignal: (recipientId: string, signalData: RtcSignalData) => void
}

export type WalkiePeersProps = Props

export function useRtcPeers({
  enabled,
  participants,
  streams,
  iceServers: iceServersFromProps,
  shouldUserHearUs = () => true,
  onJsonData,
  onSomeoneTalk,
  onSomeoneTalkStop,
  onActivityHappen,
  callId,
  sessionId,
  silent,
  broadcastSignal,
}: Props): WalkieCallManager {
  const {
    currentUserId,
    resilientVoice,
    camDefaultOn,
    lowCpuShare,
    awayDot,
  } = useAppContext()

  const [[state, dispatch]] = useUniversalReducer(
    useImmerReducer<WalkieState, WalkieAction>(
      walkieReducer,
      initialWalkieState,
    ),
    { key: 'rtc' },
  )

  // ------------------------------------------------------------------

  // ⚠️ Do not use `walkieStateRef` unless you know what you're doing.
  // 99% of times you should use `state` instead
  const walkieStateRef: {
    current:
      | Override<WalkieState, { participants: MaybeParticipants }>
      | undefined
  } = useLatest<WalkieState>(state)

  // Derived
  const iceServers = iceServersFromProps

  const we = useMemo(
    () =>
      participants.find((participant) => participant.userId === currentUserId),
    [participants, currentUserId],
  )

  /**
   * Participants that are in our state
   * (thus we're establishing or have established a peer connection)
   */
  const currentParticipants = useMemo(() => Object.values(state.participants), [
    state.participants,
  ])
  const currentParticipantsRef = useLatest(currentParticipants)

  /** Don't use directly, use "ourObservers" below */
  const hotOurObservers = useMemo(() => {
    let observers: string[] = []

    if (!state.weSharingScreen) {
      // empty
      return observers
    }

    for (let userId in state.participants) {
      if (state.participants[userId].isObserving) {
        observers.push(userId)
      }
    }

    return observers
  }, [state])

  /** Updates only if observers changed */
  let ourObservers = useDeepMemo(() => hotOurObservers, [hotOurObservers])

  /**
   * Whether we should broadcast screen stream to a user
   */
  const shouldUserSeeScreen = useCallback(
    (userId: string) => {
      const isUserObserving = ourObservers.includes(userId)

      return isUserObserving && state.weSharingScreen
    },
    [ourObservers, state.weSharingScreen],
  )

  const getStreamsForUserId = useCallback(
    (userId: string, participant?: WalkieCallParticipant) => {
      let personTracks = []
      // let personStream
      let screenStream
      let microphone
      let camera
      let screen
      let tracksMap: RtcTracksConfig<ThereTrackName> = new Map([
        [
          'mic',
          {
            kind: 'audio',
            track: null,
            streamKey: 'person',
            warmUp:
              // do not warm on silent room
              !silent,
          },
        ],
        [
          'camera',
          { kind: 'video', track: null, streamKey: 'person', warmUp: false },
        ],
        [
          'screen',
          {
            kind: 'video',
            track: null,
            streamKey: 'screen',
            warmUp: false,
          },
        ],
        [
          'systemAudio',
          {
            kind: 'audio',
            track: null,
            // sync with screen
            streamKey: 'screen',
            warmUp: false,
          },
        ],
      ])

      if (streams.mic.stream && !silent) {
        if (shouldUserHearUs(userId, participant)) {
          debug('should hear: true')
          microphone = streams.mic.stream.getAudioTracks()[0]
          personTracks.push(microphone)
          // personStream = new MediaStream([microphone])
          tracksMap.set('mic', {
            kind: 'audio',
            track: microphone,
            streamKey: 'person',
          })
        } else {
          debug('should hear: false, did not set audio track')
          tracksMap.set('mic', {
            kind: 'audio',
            track: null,
            streamKey: 'person',
          })
        }
      }

      if (streams.camera.stream && !silent) {
        camera = streams.camera.stream.getVideoTracks()[0]
        personTracks.push(camera)
        // personStream?.addTrack(camera)
        tracksMap.set('camera', {
          kind: 'video',
          track: camera,
          streamKey: 'person',
          // if has track, set warmup to true
          // warmUp: false,
        })
      }

      // personStream = personTracks.length === 0 ? undefined : personStream

      if (streams.screen.stream && !silent) {
        if (shouldUserSeeScreen(userId)) {
          debug('should see screen: true')
          screen = streams.screen.stream.getVideoTracks()[0]
          tracksMap.set('screen', {
            kind: 'video',
            track: screen,
            streamKey: 'screen',
            warmUp: false,
          })
        } else {
          debug('should see screen: false')
          screenStream = undefined
        }
      }

      if (streams.systemAudio.stream) {
        // Only share when screen is viewed
        if (shouldUserSeeScreen(userId)) {
          debug('should share system audio: true')
          let track = streams.systemAudio.stream.getAudioTracks()[0]
          tracksMap.set('systemAudio', {
            kind: 'audio',
            track,
            streamKey: 'screen',
            warmUp: false,
          })
        } else {
          debug('should hear system audio: false')
        }
      }

      return {
        streams: [
          // personStream,
          screenStream,
        ].filter(filterFalsy),
        // personStream,
        screenStream,
        tracks: {
          microphone,
          camera,
          screen,
        },
        microphone,
        camera,
        tracksMap,
      }
    },
    [
      shouldUserHearUs,
      shouldUserSeeScreen,
      silent,
      streams.camera.stream,
      streams.mic.stream,
      streams.screen.stream,
      streams.systemAudio.stream,
    ],
  )

  let { pathname } = useRouter()

  // Peer change cleaner
  useEffect(() => {
    // WTF do not uncomment this
    // if (!enabled) return
    // DO NOT REMOVE
    if (state.participantsCleaningQueue.length === 0) return

    for (let party of state.participantsCleaningQueue) {
      try {
        // When IPC shares state, Peer object is an empty object and
        // if we call `destroy` on it, it throws a TypeError
        if (party.peer && 'destroy' in party.peer) {
          party.peer.destroy()
        }
      } catch (error) {
        if (isElectron && pathname.startsWith('/feed')) {
          // ignore error
        } else {
          console.warn('Error while cleaning up', error)
        }
      } finally {
        debug('peer cleaned up from queue')
      }
    }
    dispatch({
      type: 'peer queue cleaned up',
      instances: state.participantsCleaningQueue,
    })
  }, [state.participantsCleaningQueue, enabled, dispatch, pathname])

  let participantsByUserId = useMemo(() => {
    let byId: { [id: string]: WalkieCallParticipant } = {}
    for (let participant of participants) {
      byId[participant.userId] = participant
    }
    return byId
  }, [participants])

  let iceServersRef = useLatest(iceServers)

  let [canUseVp9, setCanUseVp9] = useState(true)

  useEffect(() => {
    ipc
      ?.invoke('can-use-vp9')
      .then((can) => {
        setCanUseVp9(can)
        console.info('canUseVp9=', can)
      })
      .catch(() => {})
  }, [])

  const sdpTransformFunction = useLatest(
    useCallback(
      (offer: RTCSessionDescriptionInit): RTCSessionDescriptionInit => {
        if (!offer.sdp) {
          return offer
        }

        // temporarily, let's do cpu detection later...
        // let sdp = preferCodecInSdp(String(offer.sdp), 'h264') as string
        let sdp = preferCodecInSdp(
          String(offer.sdp),
          canUseVp9 ? 'vp9' : 'h264',
          // mo: disable using vp9 for now
          // 'h264',
        ) as string
        return { type: offer.type, sdp }
      },
      [],
    ),
  )

  let ourParticipantId = we?.id
  let weRef = useLatest(we)
  let participantsByUserIdRef = useLatest(participantsByUserId)

  let trackPeer = useStatsGatherer({
    // Mo: For now disable to make sure it doesn't cause issues
    enabled: false,
    // enabled,
    participants,
    dialogId: callId,
    sessionId,
    sharing: [
      state.weTalking ? 'mic' : undefined,
      state.weSharingCamera ? 'camera' : undefined,
      state.weSharingScreen ? 'screen' : undefined,
    ].filter((i) => filterFalsy(i)) as ('mic' | 'screen' | 'camera')[],
  })

  let trackPeerConnection = usePeerLogger({
    enabled,
    participants,
    sessionId,
  })

  // used for debug
  let [iceTransportPolicy, setIceTransportPolicy] = useState<
    RTCIceTransportPolicy
  >('all')

  // used for debug
  let [iceServersOverride, setIceServerOverride] = useState<
    RTCIceServer[] | undefined
  >(undefined)

  useEffect(() => {
    // @ts-ignore
    window.rtcUtils = {
      setIceTransportPolicy: (value: RTCIceTransportPolicy) => {
        setIceTransportPolicy(value)
      },
      setIceServersOverride: (value: RTCIceServer[] | undefined) => {
        setIceServerOverride(value)
      },
    }
  }, [])

  /**
   * Creates a peer for userId with correct streams and ice servers
   */
  const createParticipantPeer = useCallback(
    ({
      userId,
      participant,
      receivedOffer,
      callId,
    }: {
      userId: string
      callId: string
      participant?: WalkieCallParticipant
      receivedOffer?: boolean
      offererPairId?: number
    }): {
      peer: RtcPeerType | undefined
    } => {
      let _participant =
        participant || participantsByUserIdRef.current?.[userId]

      // used to be we
      if (!weRef.current || !ourParticipantId) {
        console.error('Ourselves are not in participants (cannot create peer)')
        Sentry.captureMessage(
          '`We` was not defined by the time we create peer.',
          Sentry.Severity.Error,
        )
        return { peer: undefined }
      }

      // prevent using prev call participants
      if (walkieStateRef.current?.callId !== callId) {
        console.error('Call id not match')
        Sentry.captureMessage('Call id not match', Sentry.Severity.Error)
        return { peer: undefined }
      }

      if (!_participant) {
        debug('Participant not found')
        return { peer: undefined }
      }

      let { tracksMap } = getStreamsForUserId(userId, participant)

      let isPolitePeer = getIsPolitePeer(weRef.current, _participant)
      let isOfferer = getIsOfferer(weRef.current, _participant)

      // DO CLEAN UP IF THERE's AN EXISTING PEER RIGHT HERE BEFORE CREATING A NEW ONE
      let oldPeer = walkieStateRef.current?.participants[userId]?.peer
      if (oldPeer) {
        if (oldPeer && 'destroy' in oldPeer) {
          oldPeer.destroy()
        }
        oldPeer = undefined
      }
      // DO CLEAN UP IF THERE's AN EXISTING PEER RIGHT HERE BEFORE CREATING A NEW ONE

      let peer = new RtcPeer({
        tracks: tracksMap,
        polite: isPolitePeer,
        initialOfferer: isOfferer,
        // Silent rooms do not require
        mediaRequired: !silent,
        iceServers: iceServersOverride
          ? iceServersOverride
          : iceServersRef.current,
        // No need, codec is set another way
        // sdpTransform: sdpTransformFunction.current,
        iceTransportPolicy: iceTransportPolicy || 'all',
        // preferredAudioCodec: resilientVoice ? 'audio/red' : undefined,
        // mo: Disable "red" for now
        preferredAudioCodec: resilientVoice ? 'audio/red' : undefined,
        preferredVideoCodec:
          // lowCpuShare || !canUseVp9 ? 'video/H264' : 'video/VP9',
          isWinOS
            ? // Disable due to bug in windows screen share
              undefined
            : lowCpuShare || !canUseVp9
            ? 'video/H264'
            : 'video/VP9',

        logger: (message, args) => {
          Sentry.addBreadcrumb({
            message: message,
            data: { args: args },
          })
        },
      })

      if (!peer) {
        console.warn('could not create peer')
        return { peer: undefined }
      }

      dispatch({
        type: 'new peer created',
        userId,
        peer,
        isPolitePeer,
        receivedOffer,
      })

      trackPeer(peer, userId)
      trackPeerConnection(peer, userId)

      debug('new peer created')

      return { peer }
    },
    [
      participantsByUserIdRef,
      weRef,
      ourParticipantId,
      walkieStateRef,
      iceTransportPolicy,
      iceServersOverride,
      getStreamsForUserId,
      silent,
      iceServersRef,
      resilientVoice,
      lowCpuShare,
      canUseVp9,
      trackPeer,
      trackPeerConnection,
      dispatch,
    ],
  )

  let streamsRef = useLatest(streams)

  const handleInternalDataEvents: PeerOnJsonData = useCallback(
    (context, data, remotePrivilege) => {
      switch (data.type) {
        case 'talking':
          dispatch({
            type: 'walkie talking state changed',
            userId: context.userId,
            isTalking: true,
          })
          onActivityHappen && onActivityHappen()
          onSomeoneTalk && onSomeoneTalk()
          debug(`onSomeoneTalk`)
          break

        case 'not talking':
          dispatch({
            type: 'walkie talking state changed',
            userId: context.userId,
            isTalking: false,
          })
          onActivityHappen && onActivityHappen()
          onSomeoneTalkStop && onSomeoneTalkStop()
          debug(`onSomeoneTalkStop`)
          break

        case 'sharing screen':
          dispatch({
            type: 'participant sharing screen state changed',
            userId: context.userId,
            isSharingScreen: true,
          })
          break
        case 'not sharing screen':
          dispatch({
            type: 'participant sharing screen state changed',
            userId: context.userId,
            isSharingScreen: false,
          })
          break
        case 'sharing camera':
          dispatch({
            type: 'sharing camera state changed',
            userId: context.userId,
            isSharingCamera: true,
          })
          break
        case 'not sharing camera':
          dispatch({
            type: 'sharing camera state changed',
            userId: context.userId,
            isSharingCamera: false,
          })
          break

        case 'sharing system audio':
          dispatch({
            type: 'sharing system audio state changed',
            userId: context.userId,
            isSharingSystemAudio: true,
          })
          break
        case 'not sharing system audio':
          dispatch({
            type: 'sharing system audio state changed',
            userId: context.userId,
            isSharingSystemAudio: false,
          })
          break

        case 'observing state changed':
          dispatch({
            type: 'participant observing state changed',
            state: data.state,
            userId: context.userId,
          })
          break

        case 'idle state':
          dispatch({
            type: 'got participant idle state',
            userId: context.userId,
            idleState: data.idleState,
          })
          break

        case 'system muted':
          console.info({
            type: 'participant system muted changed',
            userId: context.userId,
            muted: data.muted,
          })
          dispatch({
            type: 'participant system muted changed',
            userId: context.userId,
            muted: data.muted,
          })
          break

        case 'intro':
          dispatch({
            type: 'user introduced itself',
            userId: context.userId,
            pId: data.pId,
          })
          break

        case 'observer-size':
          const participants = walkieStateRef.current?.participants
          const participant = participants && participants[context.userId]
          let screenSender = participant?.peer
            ?.getSenders()
            ?.find(
              (sender) =>
                sender.track?.kind === 'video' &&
                sender.track?.readyState === 'live',
            )
          if (!screenSender || !streamsRef.current?.screen?.stream) break
          const originalHeight = Number(
            streamsRef.current.screen.stream.getVideoTracks()[0]?.getSettings()
              .height,
          )
          if (!originalHeight) break
          const scaleDown = Math.max(originalHeight / data.h, 1)
          const params = screenSender.getParameters()
          debug('resizing vid sender to', scaleDown, data.h)
          screenSender.setParameters({
            ...params,
            encodings: [
              {
                ...(params.encodings[0] || {}),
                scaleResolutionDownBy: scaleDown,
              },
            ],
          })
          break

        case 'observer set q': {
          const participants = walkieStateRef.current?.participants
          const participant = participants && participants[context.userId]
          let screenSender = participant?.peer
            ?.getSenders()
            ?.find(
              (sender) =>
                sender.track?.kind === 'video' &&
                sender.track?.readyState === 'live',
            )
          if (!screenSender || !streamsRef.current?.screen?.stream) break
          // don't do anything on other than high
          if (data.quality !== 'high') break
          const scaleDown = 1
          const params = screenSender.getParameters()
          screenSender.setParameters({
            ...params,
            encodings: [
              {
                ...(params.encodings[0] || {}),
                scaleResolutionDownBy: scaleDown,
              },
            ],
          })
          break
        }

        case 'observer set hint': {
          let hint = data.hint
          const participants = walkieStateRef.current?.participants
          const participant = participants && participants[context.userId]
          let track = participant?.peer?.localTracks.get('screen')?.track
          dispatch({
            type: 'screen settings updated',
            contentHint: data.hint,
          })
          if (!track) break
          if ('contentHint' in track) {
            //@ts-ignore
            track.contentHint = hint
            //@ts-ignore
            if (track.contentHint !== hint) {
              console.info("Invalid video track contentHint: '" + hint + "'")
            }
          } else {
            console.info('MediaStreamTrack contentHint attribute not supported')
          }
          break
        }

        case 'user muted': {
          dispatch({
            type: 'talking',
            talking: false,
          })
          break
        }

        default:
          break
      }
    },
    [
      dispatch,
      onActivityHappen,
      onSomeoneTalk,
      onSomeoneTalkStop,
      streamsRef,
      walkieStateRef,
    ],
  )

  const onData = useCallback(
    (
      data,
      input:
        | { eventContext: PeerEventContext }
        | {
            userId: string
            sendToAll: SendToAll
            sendToUser: SendToUser
          },
    ) => {
      let userId = 'userId' in input ? input.userId : input.eventContext.userId
      let participant = walkieStateRef.current?.participants[userId]
      // Don't handle data channel event if there is no participant
      if (!participant?.peer) return

      const eventContext: PeerEventContext =
        'eventContext' in input
          ? input.eventContext
          : // Rebuild event context if data is incomplete
            {
              sendToAll: input.sendToAll,
              sendToUser: input.sendToUser,
              pairId: undefined,
              streamsRef,
              peer: participant.peer,
              userId: input.userId,
            }

      if (typeof data === 'object') {
        handleInternalDataEvents(
          eventContext,
          data,
          !!walkieStateRef.current?.remoteControlPrivilege,
        )
        onJsonData &&
          onJsonData(
            eventContext,
            data,
            !!walkieStateRef.current?.remoteControlPrivilege,
          )
      }
    },
    [handleInternalDataEvents, onJsonData, streamsRef, walkieStateRef],
  )

  // 🦴
  // Attach Event Handlers to Peers
  const attachPeerEventHandlers = useCallback(
    function attachPeerEventHandlers(eventContext: PeerEventContext) {
      debug(`${peerId(eventContext.userId)}: Attaching peer events`)
      const userId = eventContext.userId
      const peer = eventContext.peer

      const handleData = (data: any) => {
        onData(data, { eventContext })
      }

      const handleDataOpen = () => {
        dispatch({
          type: 'peer data open',
          userId,
        })
      }

      const handleTrack = (event: RTCTrackEvent) => {
        function trackUnmuted(
          track: MediaStreamTrack,
          eventStream?: MediaStream,
        ) {
          const participant = walkieStateRef.current?.participants[userId]
          // Don't use that stream as it could be identical to previous and our RoomAudio might fail
          // let stream = eventStream || new MediaStream([track])
          let stream = new MediaStream([track])

          debug(`${peerId(userId)}: Incoming track: "${track.kind}"`, track)

          if (
            track.kind === 'video' &&
            participant?.isSharingCamera &&
            participant.isSharingScreen
          ) {
            // For now do not handle this case, we'll fix it on "tracks" event
            dispatch({
              type: 'got track',
              userId,
              track,
              stream,
              kind: 'undecided',
            })
          } else if (track.kind === 'audio') {
            dispatch({
              type: 'got track',
              userId,
              track,
              stream,
              kind: 'audio',
            })
          } else if (track.kind === 'video') {
            if (
              participant?.isSharingScreen &&
              (!participant?.screenStream || !participant.screenStream.active)
            ) {
              dispatch({
                type: 'got track',
                userId,
                track,
                stream,
                kind: 'screen',
              })
            } else if (
              participant?.isSharingCamera &&
              !participant.cameraStream
            ) {
              dispatch({
                type: 'got track',
                userId,
                track,
                stream,
                kind: 'camera',
              })
            } else {
              dispatch({
                type: 'got track',
                userId,
                track,
                stream,
                kind: 'undecided',
              })
            }
          }
        }
        // once media for the remote track arrives, show it in the video element
        event.track.onunmute = () => {
          // MEDIA RECEIVED 😌
          trackUnmuted(event.track, event.streams[0])
          // Remove event handler once added
          event.track.onunmute = null
        }
      }

      const handleError = (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra(
            'peersCount',
            Object.keys(walkieStateRef.current?.participants || {}).length,
          )
          scope.setExtra(
            'weSharingCamera',
            walkieStateRef.current?.weSharingCamera,
          )
          scope.setExtra('weTalking', walkieStateRef.current?.weTalking)
          scope.setExtra(
            'weSharingScreen',
            walkieStateRef.current?.weSharingScreen,
          )
          scope.setExtra('isObserving', walkieStateRef.current?.isObserving)
          scope.setExtra(
            'firstParticipantConnected',
            walkieStateRef.current?.firstParticipantConnected,
          )

          Sentry.captureException(error)
        })

        console.error(
          `${peerId(userId)} Peer error`,
          // @ts-ignore
          'code' in error ? String(error['code']) : '',
          error,
        )
      }

      const handleConnectionStateChange = (state: RtcConnectionStateType) => {
        dispatch({ type: 'peer connection state change', userId, state })

        if (state === 'closed') {
          dispatch({ type: 'peer closed', userId })
        }
      }

      const handleSignal = (signalData: RtcSignalData) => {
        broadcastSignal(userId, signalData)
      }

      const handleUpdatedTracks = (tracksMap: RemoteTracksMap) => {
        for (let [trackName, track] of tracksMap) {
          let stream = new MediaStream([track])

          if (
            trackName === 'camera' ||
            trackName === 'screen' ||
            trackName === 'mic' ||
            trackName === 'systemAudio'
          ) {
            console.info('Re-mapped track', trackName, track)
            dispatch({
              type: 'got track',
              userId,
              track,
              stream,
              kind: trackName,
            })
          }
        }
      }

      peer.addListener('track', handleTrack)
      peer.addListener('tracks', handleUpdatedTracks)
      peer.addListener('signal', handleSignal)
      peer.addListener('data', handleData)
      peer.addListener('dataOpen', handleDataOpen)
      peer.addListener('error', handleError)
      peer.addListener('connectionStateChange', handleConnectionStateChange)

      return () => {
        peer.removeListener('track', handleTrack)
        peer.removeListener('signal', handleSignal)
        peer.removeListener('data', handleData)
        peer.removeListener('dataOpen', handleDataOpen)
        peer.removeListener('error', handleError)
        peer.removeListener(
          'connectionStateChange',
          handleConnectionStateChange,
        )
      }
    },
    [broadcastSignal, dispatch, onData, walkieStateRef],
  )

  const participantIdsInState = useMemo(
    () => new Set(Object.keys(state.participants).map((userId) => userId)),
    [state.participants],
  )

  let camDefaultOnRef = useLatest(camDefaultOn)

  // Start call
  useEffect(() => {
    if (!callId) {
      return
    }

    if (!enabled) {
      return
    }

    // Reset previous call
    dispatch({
      type: 'started',
      callId,
      camDefaultOn: camDefaultOnRef.current,
    })

    debug(`New call state.`, callId)

    return () => {
      // Reset previous call
      dispatch({
        type: 'ended',
        left: false,
        callId,
      })
      debug('call end (useeffect cleanup)')
    }
  }, [callId, dispatch, enabled, walkieStateRef, camDefaultOnRef])

  // Sync user talking on rtc page relaod
  useEffect(() => {
    if (!enabled) return
    if (!we?.user) return

    const weTalking = walkieStateRef.current?.weTalking

    if (we.user.talking && !weTalking) {
      dispatch({
        type: 'synced talking initially',
        weTalking: we.user.talking,
      })
      debug('Synced talking initially')
    }
  }, [we, enabled, walkieStateRef, dispatch])

  const { connectionState } = useConnectivity()

  const participantsIds = useMemo(() => {
    let ids = ''
    for (const participant of participants) {
      ids += participant.userId
    }
    return ids
  }, [participants])

  let participantsRef = useLatest(participants)

  let participantIdsIntegrityInState = Object.keys(state.participants).join('')
  let [isOnline, setOnline] = useState(true)
  useEffect(() => {
    if (typeof window == 'undefined') return
    const handleOnline = () => setOnline(true)
    const handleOffline = () => setOnline(false)

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  // 🎬
  // Add Participants to Call
  useEffect(() => {
    if (!callId) {
      return
    }

    if (callId !== state.callId) {
      return
    }

    if (!enabled) {
      return
    }

    if (!weRef.current) {
      debug('[warn] `we` was not in participants.')
      // return
    }

    // DO NOT USE, THIS DOES NOT SYNC ACROSS WINDOWS
    // if (connectionState !== 'connected') {
    //   debug('[warn] not connected.')
    //   return
    // }

    if (!participantsRef.current) {
      return
    }

    // @moein: todo fix later
    // if (connectionState !== 'connected') {
    //   debug('[warn] connectionState=', connectionState)
    //   return
    // }

    for (const participant of participantsRef.current) {
      const userId = participant.userId

      // Skip current user
      if (userId === currentUserId) continue

      // Skip if already added in state
      // let inStateParticipant = participantIdsInState.has(userId)
      // if (inStateParticipant) return

      //
      let participantState =
        walkieStateRef.current?.participants &&
        walkieStateRef.current.participants[userId]

      // Disable offline check
      // if (
      //   participant.user.online !== true &&
      //   (!participantState?.peer ||
      //     // If user has a peer and is connected, don't clean it for now
      //     (participantState?.peer &&
      //       participantState.peerState !== 'connected'))
      // ) {
      //   debug('offline')
      //   // Remove peer
      //   // debug
      //   dispatch({ type: 'user went offline', userId })
      //   continue
      // }

      if (
        participantState?.peer &&
        !participantState?.peer.destroyed &&
        !participantState?.peer.destroying &&
        participantState.peerState !== 'closed'
      ) {
        // already connected
        continue
      }

      // if making new offer, means it's so fresh, prevent a loop
      if (
        participantState?.peer &&
        participantState?.peer.makingOffer &&
        participantState.peerState === 'closed' &&
        !participantState?.peer.destroyed
      ) {
        continue
      }

      debug('Going through participants', participantsRef.current)

      debug(`${peerId(userId)}: Created peer`)
      // safeRequestIdleCallback(
      //   () => {
      createParticipantPeer({ userId, participant, callId })
      // },
      // { timeout: 100 },
      // )

      let dummy = participantsIds
    }
  }, [
    participantsIds,
    //don't put
    // participants,
    participantsRef,
    // Keep here so connection reset works.
    participantIdsIntegrityInState,
    enabled,
    currentUserId,
    weRef,
    dispatch,
    callId,
    state.callId,
    walkieStateRef,
    createParticipantPeer,
    connectionState, // Important: This is fake and is not synced across windows
    isOnline,
  ])

  /**
   * Sync `talking` and `sharing` from participants
   * as a substitute for data channel
   */
  // disabled as it clashes with switch to me logic
  // useEffect(() => {
  //   for (const participant of participants) {
  //     if (
  //       typeof participant.user.sharing !== 'undefined' &&
  //       // has changed
  //       walkieStateRef.current?.participants[participant.user.id]
  //         ?.isSharingScreen !== participant.user.sharing
  //     ) {
  //       console.info('syncing isSharingScreen from db to participants')
  //       dispatch({
  //         type: 'participant sharing screen state changed',
  //         isSharingScreen: participant.user.sharing,
  //         userId: participant.userId,
  //       })
  //     }
  //   }
  // }, [dispatch, participants, walkieStateRef])

  // Used for communicating changes to peers
  // Calc once, use everywhere
  const peersNumbers = useMemo(() => {
    let count = 0
    let transceiversCount = 0
    let connectingPeersCount = 0
    let connectedPeersCount = 0
    let disconnectedPeersCount = 0
    let openDataChannelsCount = 0
    let pendingIceRestartCount = 0

    for (let participant of currentParticipants) {
      if (participant.peer) count++

      if (participant.dataOpen) {
        // Todo: add an event to increase this on time for events to fire
        ++openDataChannelsCount
      }

      switch (participant.peerState) {
        case 'connecting':
          connectingPeersCount++
          break
        case 'connected':
          connectedPeersCount++
          break
        case 'closed':
          disconnectedPeersCount++
          break
      }
    }

    return {
      count,
      connectingPeersCount,
      connectedPeersCount,
      transceiversCount,
      disconnectedPeersCount,
      openDataChannelsCount,
      pendingIceRestartCount,
    }
  }, [currentParticipants])

  const participantsCountChecker = currentParticipants.length
  const allParticipantsCountChecker = participants.length
  // Used for detecting when a new peer is created to attach event handlers
  const peersCount = peersNumbers.count
  const peersIntegrityCheck = useMemo(
    () =>
      currentParticipants.reduce(
        (prev, current) => prev + (current.peerCreatedAt || 0),
        0,
      ),
    [currentParticipants],
  )

  // ☠️
  // Cleanup dead participants
  const checkForStaleParticipants = useCallback(() => {
    for (const userIdInState of participantIdsInState) {
      const isInCurrentListOfParticipants = participants.find(
        (participant) => participant.userId === userIdInState,
      )

      if (!isInCurrentListOfParticipants) {
        debug(`Detected a stale participant, cleaning up...`)
        const participantToCleanUp =
          walkieStateRef.current?.participants[userIdInState]
        if (
          participantToCleanUp
          // &&
          // don't filter participants trying to connect
          // participantToCleanUp.peerState === 'connected' &&
          // participantToCleanUp.outgoingPersonStream
        ) {
          dispatch({ type: 'left', userId: userIdInState })
        }
      }
    }
  }, [dispatch, participantIdsInState, participants, walkieStateRef])

  useEffect(() => {
    if (!enabled) {
      return
    }

    checkForStaleParticipants()
  }, [enabled, participants, checkForStaleParticipants])

  const peerMicrophoneReady = Boolean(
    currentParticipants.some((p) =>
      Boolean(p.microphoneStream && p.microphoneStream.active),
    ),
  )

  const addStreams = useCallback(
    ({
      participant,
      streams,
    }: {
      participant: ParticipantState
      streams: StreamsObject
    }) => {
      if (!enabled) return

      if (!participant || !participant.peer) {
        return
      }

      if (!streams.mic.stream) {
        debug('mic stream not ready yet')
        return
      }

      if (silent) {
        debug('silent, add no tracks')
        return
      }

      // ---
      let { peer, userId } = participant
      let weTalking = state.weTalking
      let shouldHearUs = shouldUserHearUs(userId)

      // Add microphone
      let newMicrophoneTrack = streams.mic.stream.getAudioTracks()[0]

      // Legacy
      newMicrophoneTrack.enabled = weTalking

      if (shouldHearUs) {
        peer.setTrack('mic', newMicrophoneTrack)
      }

      // Check if we have screen stream
      if (streams.screen.stream) {
        let shouldSeeUs = shouldUserSeeScreen(userId)
        let weSharingScreen = state.weSharingScreen
        // check if user is our observer
        if (shouldSeeUs) {
          // Screen Track
          let newScreenTrack = streams.screen.stream.getVideoTracks()[0]
          newScreenTrack.enabled = weSharingScreen
          // Add Screen Track
          peer.setTrack('screen', newScreenTrack)
        } else {
          // TODO: Warmup track
          // if (peer.initialOfferer) {
          // Warmup with all room participants
          // peer.warmUpTrack('screen')
          // }
        }
      }

      if (streams.camera.stream && state.weSharingCamera) {
        let cameraTrack = streams.camera.stream.getVideoTracks()[0]
        peer.setTrack('camera', cameraTrack)
      }

      if (
        streams.systemAudio.stream &&
        (state.weSharingScreen || state.weSharingSystemAudio)
      ) {
        let systemAudioTrack = streams.systemAudio.stream.getAudioTracks()[0]
        peer.setTrack('systemAudio', systemAudioTrack)
      }
    },
    [
      enabled,
      silent,
      state.weTalking,
      state.weSharingCamera,
      state.weSharingScreen,
      state.weSharingSystemAudio,
      shouldUserHearUs,
      shouldUserSeeScreen,
    ],
  )

  useEffect(() => {
    debug(`weTalking=`, state.weTalking)
  }, [state.weTalking])

  // Replace! Removes and adds new ones
  // Add stream to peer
  useEffect(() => {
    if (!callId || !enabled) {
      return
    }

    for (let participant of currentParticipantsRef.current || []) {
      try {
        addStreams({ participant, streams })
      } catch (error) {
        // Failed to set stream
        console.error(error)
        Sentry.captureException(error)
      }
    }
  }, [
    addStreams,
    callId,
    enabled,
    peersIntegrityCheck,
    peersNumbers.connectedPeersCount,
    state.weTalking,
    state.weSharingScreen,
    ourObservers.length,
    streams,
    participants,
    currentParticipantsRef,
    // Be careful, mo just removed "currentParticipants" from here to save cpu
  ])

  type CameraConstraints = 'low' | 'normal' | 'high'
  let previousCameraConstraints = useRef<CameraConstraints>('high')

  // Adjust Video Chat Quality
  // Lower camera quality when in screen share
  useEffect(() => {
    if (!enabled) return
    if (!state.weSharingCamera) return

    let nextCameraConstraints: CameraConstraints = 'high'

    if ((state.weSharingScreen || state.isObserving) && state.weSharingCamera) {
      nextCameraConstraints = 'low'
    }
    // for (let [, participant] of Object.entries(state.participants)) {
    //   // FIXME: probably optimize by changing the stream track
    //   if (
    //     (participant.isSharingScreen || participant.isObserving) &&
    //     participant.isSharingCamera
    //   ) {
    //     nextCameraConstraints = 'low'
    //   }
    // }

    if (nextCameraConstraints === previousCameraConstraints.current) {
      return
    }

    previousCameraConstraints.current = nextCameraConstraints
    console.info('going to make camera q:', nextCameraConstraints)

    // let track = streams.camera.stream?.getVideoTracks()[9]
    streams.camera.changeQuality(nextCameraConstraints)
  }, [
    enabled,
    state.isObserving,
    state.participants,
    state.weSharingCamera,
    state.weSharingScreen,
    streams.camera,
    streams.camera.stream,
  ])

  const sendToParticipant = useCallback(
    (participant: ParticipantState, data: DataType) => {
      if (
        !participant ||
        !participant.peer ||
        !participant.peer.channelReady ||
        participant.peerState !== 'connected'
      ) {
        debug(`${peerId(participant.userId)}: Unable to send data`, data)
        return false
      }

      try {
        participant.peer.sendJson(data)
        return true
      } catch (error) {
        console.error(
          `${peerId(participant.userId)}: Failed to send data`,
          error,
        )
        return false
      }
    },
    [],
  )

  const sendToUser = useCallback(
    (userId: string, data: DataType) => {
      // Because this function will be passed to a lot of useEffect's and useCallback's,
      // it should not change often, hence don't rely on walkieState
      const participant = walkieStateRef.current?.participants[userId]

      if (!participant) {
        debug(`${peerId(userId)} doesn't exist to send data.`)
        return false
      }

      return sendToParticipant(participant, data)
    },
    [walkieStateRef, sendToParticipant],
  )

  const sendToUserScreenChannel = useCallback(
    (userId: string, data: DataType) => {
      const participant = walkieStateRef.current?.participants[userId]

      if (
        !participant ||
        !participant.screenDataChannel ||
        participant.screenDataChannel?.readyState !== 'open' ||
        participant.peerState !== 'connected'
      ) {
        debug(
          `${peerId(participant?.userId || '')}: Unable to send screen data`,
          data,
        )

        if (participant?.dataOpen) {
          sendToParticipant(participant, data)
        }
        return false
      }

      try {
        participant.screenDataChannel.send(JSON.stringify(data))
        return true
      } catch (error) {
        console.error(
          `${peerId(participant.userId)}: Failed to send screen data`,
          error,
        )
        return false
      }
    },
    [sendToParticipant, walkieStateRef],
  )

  const sendToAll = useCallback(
    (data: DataType) => {
      const participants = walkieStateRef.current?.participants

      if (!participants) return 0

      const arrayOfParticipants = Object.values(participants)
      for (let participant of arrayOfParticipants) {
        if (!participant) continue
        if (participant.peerState !== 'connected') continue
        sendToParticipant(participant, data)
      }

      return arrayOfParticipants.length
    },
    [sendToParticipant, walkieStateRef],
  )

  let universalDataChannel = useRtcUniversalData({
    enabled,
    sendToAll,
    sendToUser,
    onData: (data: DataType, userId: string) => {
      // Only handle it once
      if (!enabled) return
      onData(data, { userId, sendToUser, sendToAll })
    },
  })

  const openDataChannelsCount = peersNumbers.openDataChannelsCount

  useEffect(() => {
    if (!enabled) {
      return
    }
    if (!walkieStateRef.current) return

    const removeListenersFunctions = new Set<() => void>()

    for (const participant of Object.values(
      walkieStateRef.current.participants,
    )) {
      if (!participant?.peer) return

      let removeListener = attachPeerEventHandlers({
        peer: participant.peer,
        userId: participant.userId,
        // pairId: participant.pairId,
        pairId: undefined,
        streamsRef,
        sendToAll: sendToAll,
        sendToUser: sendToUser,
      })

      removeListenersFunctions.add(removeListener)
    }

    // let blah = peersNumbers.connectingPeersCount
    return () => {
      for (let removeListeners of removeListenersFunctions) {
        removeListeners()
      }
    }
  }, [
    attachPeerEventHandlers,
    participantsCountChecker,
    allParticipantsCountChecker,
    peersCount,
    peersIntegrityCheck,
    walkieStateRef,
    streamsRef,
    sendToAll,
    sendToUser,
    enabled,
  ])

  /** @Experimental */
  const handleScreenChannelMessage = (
    userId: string,
    event: MessageEvent<any>,
  ) => {
    if (typeof event.data !== 'string') return
    let parsedData = JSON.parse(event.data)
    let peer = walkieStateRef.current?.participants[userId]?.peer
    if (!peer) return

    // console.log('handleScreenChannelMessage', parsedData)
    onJsonData?.(
      {
        sendToAll,
        sendToUser,
        streamsRef,
        userId,
        peer,
        pairId: 0,
      },
      parsedData,
      !!walkieStateRef.current?.remoteControlPrivilege,
    )
  }

  let handleScreenChannelMessageFunction = useLatest(handleScreenChannelMessage)

  // Screen share data channel
  // -> Only add one time, and don't close it
  useEffect(() => {
    if (!enabled) return
    if (!walkieStateRef.current) return

    function createScreenDataChannel(userId: string) {
      let participants = walkieStateRef.current?.participants[userId]
      if (
        participants?.screenDataChannel &&
        participants?.screenDataChannel.readyState === 'open'
      ) {
        return
      }

      try {
        let dataChannel = participants?.peer?.peer?.createDataChannel(
          'Screen',
          {
            id: 3,
            negotiated: true,
            ordered: false,
            maxPacketLifeTime: 30,
          },
        )

        if (!dataChannel) {
          Sentry.captureMessage(
            'Could not create screen share data channel',
            Sentry.Severity.Error,
          )
          console.warn('Could not create screen share data channel')
          return
        }

        // eslint-disable-next-line unicorn/prefer-add-event-listener
        dataChannel.onmessage = (event) => {
          handleScreenChannelMessageFunction.current?.(userId, event)
        }

        dispatch({ type: 'created screen data channel', userId, dataChannel })
      } catch (error) {
        Sentry.captureException(error)
        console.error('Could not create screen share data channel', error)
      }
    }

    if (state.weSharingScreen) {
      // Register data channel
      for (const userId of ourObservers) {
        createScreenDataChannel(userId)
      }
    } else if (state.isObserving && state.hostUserId) {
      createScreenDataChannel(state.hostUserId)
    }
  }, [
    dispatch,
    enabled,
    handleScreenChannelMessageFunction,
    participantIdsIntegrityInState,
    peersNumbers.connectedPeersCount,
    state.hostUserId,
    state.isObserving,
    ourObservers,
    state.weSharingScreen,
    walkieStateRef,
  ])

  useEffect(() => {
    if (!enabled) {
      return
    }

    if (typeof awayDot === 'boolean' && !awayDot) return
    // On new call, get an activity report
    ipc?.invoke('idle-state-requested').then((state) => {
      dispatch({ type: 'got user idle state', idleState: state.idleState })
    })
  }, [callId, peersNumbers.openDataChannelsCount, dispatch, enabled, awayDot])

  // check user idle state every 1min if not talking
  let activeInterval = 60 * 1000
  let idleInterval = 1 * 1000
  useEffect(() => {
    if (!enabled) {
      return
    }
    if (typeof awayDot === 'boolean' && !awayDot) return
    if (state.weTalking) {
      dispatch({ type: 'got user idle state', idleState: 'active' })
      return
    }

    let interval = setInterval(
      () => {
        ipc?.invoke('idle-state-requested').then((state) => {
          dispatch({ type: 'got user idle state', idleState: state.idleState })
        })
      },
      state.idleState === 'idle' ? idleInterval : activeInterval,
    )

    return () => clearInterval(interval)
  }, [
    callId,
    peersNumbers.openDataChannelsCount,
    dispatch,
    enabled,
    state.weTalking,
    state.idleState,
    idleInterval,
    activeInterval,
    awayDot,
  ])

  // ♻️ When someone joins, tell them of our latest state
  useEffect(() => {
    if (!enabled || !walkieStateRef.current) {
      return
    }

    debug('Sending latest state to new peers')

    if (walkieStateRef.current.weTalking) {
      sendToAll({ type: 'talking' })
    } else {
      sendToAll({ type: 'not talking' })
    }

    if (walkieStateRef.current.pId) {
      sendToAll({ type: 'intro', pId: walkieStateRef.current.pId })
    }

    if (walkieStateRef.current.weSharingCamera) {
      sendToAll({ type: 'sharing camera' })
    } else {
      sendToAll({ type: 'not sharing camera' })
    }

    if (walkieStateRef.current.weSharingScreen) {
      sendToAll({ type: 'sharing screen' })
    } else {
      sendToAll({ type: 'not sharing screen' })
    }

    if (walkieStateRef.current.weSharingSystemAudio) {
      sendToAll({ type: 'sharing system audio' })
    } else {
      sendToAll({ type: 'not sharing system audio' })
    }

    if (walkieStateRef.current.idleState) {
      sendToAll({
        type: 'idle state',
        idleState: walkieStateRef.current.idleState,
      })
    }

    if (
      walkieStateRef.current.systemMuted ||
      walkieStateRef.current.volumeMuted
    ) {
      sendToAll({
        type: 'system muted',
        muted:
          walkieStateRef.current.systemMuted ||
          walkieStateRef.current.volumeMuted,
      })
    }

    // Legacy
    if (walkieStateRef.current.hostUserId) {
      sendToAll({
        type: 'observing userId changed',
        hostUserId: walkieStateRef.current.hostUserId,
      })
    }

    if (typeof walkieStateRef.current.isObserving === 'boolean') {
      sendToAll({
        type: 'observing state changed',
        state: walkieStateRef.current.isObserving,
      })
    }

    // let dummy = peersNumbers.connectedPeersCount
  }, [
    enabled,
    // Change when someone joins
    openDataChannelsCount,
    // peersNumbers.connectedPeersCount,
    sendToAll,
    walkieStateRef,
  ])

  useEffect(() => {
    if (!enabled) return
    if (state.idleState) {
      sendToAll({
        type: 'idle state',
        idleState: state.idleState,
      })
    }
  }, [enabled, sendToAll, state.idleState])

  useEffect(() => {
    if (!enabled) return

    sendToAll({
      type: 'system muted',
      muted: state.systemMuted || state.volumeMuted,
    })
  }, [enabled, sendToAll, state.systemMuted, state.volumeMuted])

  /**
   * Legacy - required for old hosts to work
   * broadcast hostUserId changes
   */
  useEffect(() => {
    if (!enabled) return

    sendToAll({
      type: 'observing userId changed',
      hostUserId: state.hostUserId || '',
    })
  }, [sendToAll, enabled, state.hostUserId])

  useEffect(() => {
    // @ts-ignore
    window.getRtc = () => {
      return walkieStateRef.current
    }
    // @ts-ignore
    window.rtc = () => walkieStateRef.current
  }, [walkieStateRef])

  /**
   * Used to trigger a new peer
   */
  const triggerConnectionRecheck = useCallback(() => {
    if (!callId || !enabled) {
      return
    }

    // Don't attempt reconnect when internet is disconnected / or hasn't auth'ed
    // if (connectionState !== 'connected') {
    //   return
    // }

    if (!currentParticipantsRef.current) {
      return
    }

    let now = Date.now()

    for (const participantState of currentParticipantsRef.current) {
      // Only look for disconnected participants
      if (participantState.peerState !== 'closed') continue
      if (
        participantState.lastPeerCreatedAt &&
        participantState.lastPeerCreatedAt + ms('3s') > now
      )
        continue

      // Get roomie info
      let participant = participants.find(
        (roomie) => roomie.userId === participantState.userId,
      )

      if (!participant) continue

      let userId = participantState.userId

      debug(`${peerId(userId)}: Created reconnection peer on recheck`)

      createParticipantPeer({
        userId,
        participant,
        callId,
      })
    }
  }, [
    callId,
    connectionState,
    createParticipantPeer,
    currentParticipantsRef,
    enabled,
    participants,
  ])

  /**
   * Removes participants thus creates new peers again.
   * @experimental - Added to call after optimistic
   */
  const triggerParticipantsReset = useCallback(
    (options?: {
      onlyThoseWeAreInitialOfferer?: boolean
      onlyThoseWeAreImpolite?: boolean
    }) => {
      if (!walkieStateRef.current?.callId) return
      /**
       * Because this is the fastest connection way. Otherwise,
       * we're resetting people that our reset has no effect on them.
       */
      if (options) {
        let participants = Object.values(walkieStateRef.current.participants)
        for (const participant of participants) {
          // 1
          if (
            options.onlyThoseWeAreInitialOfferer &&
            participant?.peer?.initialOfferer === true
          ) {
            debug(`reset connection for ${participant.userId}`)
            dispatch({
              type: 'triggered single participant reset',
              userId: participant.userId,
            })
          }

          // 2
          if (
            options.onlyThoseWeAreImpolite &&
            participant?.peer?.polite === false
          ) {
            debug(`reset impolite connection for ${participant.userId}`)
            dispatch({
              type: 'triggered single participant reset',
              userId: participant.userId,
            })
          }
        }
      } else {
        dispatch({ type: 'triggered participants reset' })
      }
      console.info('triggered participants reset')
      // Do not add any dependencies because it's used in useEffects
    },
    [dispatch, walkieStateRef],
  )
  /**
   * Removes one participant thus creates new peers again.
   * @experimental - Added bc recheck doesn't work across windows
   */
  const triggerParticipantReset = useCallback(
    (userId: string) => {
      /**
       * Because this is the fastest connection way. Otherwise,
       * we're resetting people that our reset has no effect on them.
       */
      dispatch({
        type: 'triggered single participant reset',
        userId: userId,
      })
    },
    [dispatch],
  )

  let sendToAllFunction = useLatest(sendToAll)
  let setMicMuted = streams.mic.setMuted

  useEffect(() => {
    if (!enabled) return
    if (!sendToAllFunction.current) return

    if (state.weTalking) {
      setMicMuted(false)
      // currentParticipantsRef.current?.forEach(
      //   ({ userId, outgoingMicrophoneTrack }) => {
      //     if (shouldUserHearUs(userId) && outgoingMicrophoneTrack) {
      //       outgoingMicrophoneTrack.enabled = true
      //     }
      //   },
      // )
      sendToAllFunction.current({ type: 'talking' })
    } else {
      setMicMuted(true)
      // currentParticipantsRef.current?.forEach(
      //   ({ userId, outgoingMicrophoneTrack }) => {
      //     if (shouldUserHearUs(userId) && outgoingMicrophoneTrack) {
      //       outgoingMicrophoneTrack.enabled = false
      //     }
      //   },
      // )
      sendToAllFunction.current({ type: 'not talking' })
    }
  }, [
    // sendToAll,
    sendToAllFunction,
    enabled,
    state.weTalking,
    currentParticipantsRef,
    setMicMuted,
    shouldUserHearUs,
  ])

  // Sync default screen settings
  useEffect(() => {
    if (
      state.isObserving &&
      state.hostUserId &&
      !walkieStateRef.current?.screenSettings.quality
    ) {
      debug('Set default screen settings to normal, hint: detail')
      dispatch({
        type: 'screen settings updated',
        quality: 'normal',
        contentHint: 'detail',
      })
      sendToUserScreenChannel(state.hostUserId, {
        type: 'observer set q',
        quality: 'normal',
      })
      sendToUserScreenChannel(state.hostUserId, {
        type: 'observer set hint',
        hint: 'detail',
      })
    }
  }, [
    dispatch,
    walkieStateRef,
    state.isObserving,
    state.hostUserId,
    sendToUserScreenChannel,
  ])

  /**
   * update isSharingScreen status for participants
   */
  useEffect(() => {
    if (!enabled) return

    if (state.weSharingScreen) {
      sendToAll({ type: 'sharing screen' })
    } else {
      sendToAll({ type: 'not sharing screen' })
    }
  }, [sendToAll, enabled, state.weSharingScreen])

  /**
   * update isObserving status for participants
   */
  useEffect(() => {
    if (!enabled) return

    sendToAll({
      type: 'observing state changed',
      state: state.isObserving,
    })
    console.info(`Sent observing=${state.isObserving} to all`)
  }, [enabled, state.isObserving, sendToAll])

  /**
   * tell participant to update isSharingCamera status
   */
  useEffect(() => {
    if (!enabled) return

    if (state.weSharingCamera) {
      sendToAll({ type: 'sharing camera' })
    } else {
      sendToAll({ type: 'not sharing camera' })
    }
  }, [
    sendToAll,
    enabled,
    state.weSharingCamera,
    // Re-send to new people
    peersNumbers.openDataChannelsCount,
  ])

  /**
   * update isSharingSystemAudio status for participants
   */
  useEffect(() => {
    if (!enabled) return

    if (state.weSharingSystemAudio) {
      sendToAll({ type: 'sharing system audio' })
    } else {
      sendToAll({ type: 'not sharing system audio' })
    }
  }, [sendToAll, enabled, state.weSharingSystemAudio])

  return {
    onSignal: useCallback(
      (userId, signal, extra) => {
        if (!enabled) {
          return
        }

        let { peerId } = extra || {}

        let state = walkieStateRef.current

        if (!state?.participants[userId]?.peer) {
          console.info('got signal when had no such peer')
          Sentry.captureException('got signal when had no such peer')
        }

        if (!state) return

        state.participants[userId]?.peer?.setRemoteSignal({
          signal: JSON.parse(signal),
          peerId,
        })
      },
      [enabled, walkieStateRef],
    ),
    walkieState: state,
    ourObservers,
    universalDataChannel,
    walkieStateRef,
    sendToUser,
    sendToUserScreenChannel,
    sendToAll,
    dispatch,
    peerMicrophoneReady,
    triggerConnectionRecheck,
    triggerParticipantsReset,
    triggerParticipantReset,
  }
}

export type DataType =
  | { type: 'write-clipboard'; payload: { text: string; userId: string } }
  | {
      type: 'mouse-moved'
      payload: { xPercent: number; yPercent: number; uId: string }
    }
  | {
      type: 'mv'
      x: number
      y: number
    }
  | {
      type: 'observer-size'
      w: number
      h: number
    }
  | {
      type: 'key-press'
      payload: {
        // TODO: better typing
        character: string | undefined
        // new base64
        multiLine?: string | undefined
        key: string | undefined
        modifiers: string[] | string
        // TODO: Extract into its own event
        pasteBoard?: boolean
        //person's userId who pressed key
        userId: string
      }
    }
  | {
      type: 'mouse-clicked'
      payload: {
        xPercent: number
        yPercent: number
        uId: string
        doubleClick: boolean
        button: 'right' | 'left' | 'middle'
        modifiers?: string | string[] | undefined
      }
    }
  | {
      type: 'mouse-scrolled'
      payload: {
        xPercent: number
        yPercent: number
        deltaX: number
        deltaY: number
        uId: string
      }
    }
  | {
      type: 'switched-host'
      payload: {
        hostUserId: string
      }
    }
  | { type: 'ping'; time?: number }
  | { type: 'talking' }
  | { type: 'not talking' }
  | { type: 'sharing screen' }
  | { type: 'not sharing screen' }
  | { type: 'sharing camera' }
  | { type: 'not sharing camera' }
  | {
      type: 'observing userId changed'
      hostUserId: string
    }
  | {
      type: 'observing state changed'
      state: boolean
    }
  | { type: 'pending to view screens changed'; hostUserIds: string[] }
  | { type: 'listening' }
  | { type: 'not listening' }
  | { type: 'signal-broadcast'; senderId: string; signal: string }
  | { type: 'intro'; pId: number }
  | { type: 'idle state'; idleState: IdleStateValues }
  | { type: 'observer set q'; quality: 'high' | 'normal' | 'low' }
  | { type: 'observer set hint'; hint: ContentHint }
  | { type: 'user muted' }
  | { type: 'user idle state changed'; userId: string; isIdle: boolean }
  | { type: 'system muted'; muted: boolean }
  | { type: 'photo cursor moved'; x: number; y: number }
  | { type: 'photo opened'; messageId: string }
  | { type: 'photo closed'; messageId: string }
  | { type: 'sharing system audio' }
  | { type: 'not sharing system audio' }
  | {
      type: 'camera moved'
      /** percent */
      x: number
      /** percent */
      y: number
      /** percent */
      w: number
      /** percent */
      h: number
    }

export type ParticipantState = {
  isMuted?: boolean
  /** Used in `walkie` format */
  isTalking?: boolean
  isSharingScreen?: boolean
  isSharingCamera?: boolean
  isSharingSystemAudio?: boolean
  idleState?: IdleStateValues
  systemMuted?: boolean

  // Incoming streams

  undecidedStreams?: MediaStream[]
  microphoneStream?: MediaStream | undefined
  systemAudioStream?: MediaStream | undefined
  screenStream?: MediaStream | undefined
  cameraStream?: MediaStream | undefined

  /** Microphone and possibly camera (they have to be on the same stream to sync) */
  outgoingPersonStream?: MediaStream | undefined
  outgoingScreenStream?: MediaStream | undefined
  outgoingMicrophoneTrack?: MediaStreamTrack | undefined
  outgoingCameraTrack?: MediaStreamTrack | undefined
  senderOfScreen?: RTCRtpSender
  senderOfMic?: RTCRtpSender
  senderOfCamera?: RTCRtpSender

  // meta
  appVersion?: string | undefined
  userId: string
  pId?: number | undefined
  lastPeerCreatedAt?: number

  // ----
  // peer
  peer?: RtcPeerType | undefined
  dataOpen?: boolean

  // Screen share
  isObserving?: boolean
  hostUserId?: string
  /** Created for  */
  screenDataChannel?: RTCDataChannel

  /**
   * Full Date.now() because it's shared between clients
   * Must update when peer status is "connecting"
   */
  peerCreatedAt?: number
  /**
   * 'connected' - Peer connection is established
   * 'disconnected' - No peer connection is currently established and we're not attempting reconnect right now (it could be we're waiting for the initiator to signal new peer)
   * 'reconnecting' - We're about to create a new peer
   * 'connecting' - Created a new peer and we're trying to signal
   */
  peerState?: RtcConnectionStateType
  isPolitePeer?: boolean
  receivedOffer?: boolean
  receivedAnswer?: boolean
}

type MaybeParticipants = {
  [userId: string]: ParticipantState | undefined
}

export type WalkieState = {
  callId: string | undefined
  callToken: string | undefined
  pId: number | undefined
  idleState: IdleStateValues
  systemMuted: boolean
  volumeMuted: boolean

  /** Used to know when call cleaner to kick in and watch for stale calls */
  firstParticipantConnected: boolean
  /** used to check whether sync initially or not */
  userToggledTalking: boolean
  weTalking: boolean
  weSharingCamera: boolean
  weSharingScreen: boolean
  weSharingSystemAudio: boolean
  remoteControlPrivilege: boolean
  enableCameraAfterSharing: boolean
  isObserving: boolean
  hostUserId: string | undefined
  isDeafen: boolean

  participants: {
    [userId: string]: ParticipantState
  }

  participantsCleaningQueue: ParticipantState[]

  screenSettings: {
    contentHint?: ContentHint
    quality?: Quality
  }

  afterCallHookCalled: boolean
}

export const initialWalkieState: WalkieState = {
  callId: undefined,
  callToken: undefined,
  pId: undefined,
  idleState: 'unknown',
  systemMuted: false,
  volumeMuted: false,
  firstParticipantConnected: false,
  weTalking: false,
  weSharingCamera: false,
  weSharingSystemAudio: false,
  enableCameraAfterSharing: false,
  isDeafen: false,
  userToggledTalking: false,

  participants: {},
  participantsCleaningQueue: [],

  afterCallHookCalled: false,

  weSharingScreen: false,
  isObserving: false,
  hostUserId: undefined,
  remoteControlPrivilege: true,

  screenSettings: {},
}

export type WalkieAction =
  | {
      type: 'started'
      callId: string
      camDefaultOn?: boolean
    }
  | {
      type: 'new peer created'
      userId: string
      peer?: RtcPeerType
      isPolitePeer?: boolean
      receivedOffer?: boolean
    }
  | {
      type: 'someone joined'
      userId: string
    }
  | {
      type: 'synced talking initially'
      weTalking: boolean
    }
  | { type: 'peer got track'; userId: string }
  | { type: 'peer closed'; userId: string }
  | {
      type: 'peer transceiver set'
      transceiver: RTCRtpTransceiver
      kind: 'audio' | 'video'
      userId: string
    }
  | {
      type: 'peer signal received'
      userId: string
      signalType?: RTCSessionDescription['type']
    }
  | { type: 'peer signal sent'; userId: string }
  | { type: 'user went offline'; userId: string }
  | { type: 'peer data open'; userId: string }
  | {
      type: 'peer connection state change'
      userId: string
      state: RtcConnectionStateType
    }
  | {
      type: 'got track'
      userId: string
      kind: 'audio' | 'screen' | 'camera' | 'undecided' | ThereTrackName
      track: MediaStreamTrack | undefined
      stream: MediaStream
    }
  | {
      type: 'outgoing stream set'
      userId: string
      stream: MediaStream
    }
  | {
      type: 'outgoing person stream set'
      userId: string
      senderOfMic: RTCRtpSender
      stream: MediaStream
      audioTrack: MediaStreamTrack
    }
  | {
      type: 'outgoing camera track set'
      userId: string
      senderOfCamera: RTCRtpSender
      track: MediaStreamTrack
    }
  | {
      type: 'outgoing microphone track set'
      userId: string
      track: MediaStreamTrack
    }
  | {
      type: 'outgoing screen stream set'
      userId: string
      sender: RTCRtpSender
      stream: MediaStream
    }
  | {
      type: 'outgoing screen stream ended'
      userId: string
    }
  | {
      type: 'outgoing camera stream ended'
    }
  | {
      type: 'walkie talking state changed'
      userId: string
      isTalking: boolean
    }
  | {
      type: 'talking'
      talking: boolean
    }
  | {
      type: 'walkie deafen state changed'
      isDeafen: boolean
    }
  | {
      type: 'participant sharing screen state changed'
      userId: string
      isSharingScreen: boolean
    }
  | {
      type: 'sharing screen started'
      initialObserverUserId?: string
      currentUserId: string
    }
  | {
      type: 'change participant sharing screen state locally'
      participantUserId: string
    }
  | {
      type: 'sharing screen ended'
    }
  | {
      type: 'participant observing state changed'
      userId: string
      state: boolean
    }
  | {
      type: 'screen share host changed'
      oldHostUserId: string
      newHostUserId: string
    }
  | {
      type: 'observing state changed'
      state: boolean
    }
  | {
      type: 'set hostUserId'
      hostUserId: string
    }
  | {
      type: 'user introduced itself'
      userId: string
      pId: number
    }
  | { type: 'left'; userId: string }
  | {
      type: 'ended'
      callId: string
      left: boolean
    }
  | {
      type: 'peer cleaned up from queue'
      instance: ParticipantState
    }
  | {
      type: 'peer queue cleaned up'
      instances: ParticipantState[]
    }
  | {
      type: 'cleaned up'
      callId: string
    }
  | {
      type: 'after call hook completed'
    }
  | {
      // Self
      type: 'got user idle state'
      idleState: IdleStateValues
    }
  | {
      type: 'got participant idle state'
      userId: string
      idleState: IdleStateValues
    }
  | { type: 'sharing camera started' }
  | {
      type: 'sharing camera stopped'
    }
  | {
      type: 'sharing camera state changed'
      userId: string
      isSharingCamera: boolean
    }
  | {
      type: 'sharing system audio state changed'
      userId: string
      isSharingSystemAudio: boolean
    }
  | {
      type: 'triggered participants reset'
    }
  | {
      type: 'triggered single participant reset'
      userId: string
    }

  // New screen share types
  | {
      type: 'joined participant screen'
      userId: string
    }
  | {
      type: 'left participant screen'
      userId?: string
    }
  | {
      type: 'created screen data channel'
      userId: string
      dataChannel: RTCDataChannel
    }
  | {
      type: 'screen settings updated'
      contentHint?: ContentHint
      quality?: Quality
    }
  | {
      type: 'system muted changed'
      muted: boolean
    }
  | {
      type: 'volume muted changed'
      muted: boolean
    }
  | {
      type: 'participant system muted changed'
      userId: string
      muted: boolean
    }
  | {
      type: 'remote control privilege changed'
      remoteControlPrivilege: boolean
    }
  | {
      type: 'sharing system audio started'
    }
  | {
      type: 'sharing system audio stopped'
    }

export type WalkieDispatch = React.Dispatch<WalkieAction>

export function walkieReducer(
  draft: Draft<WalkieState>,
  action: WalkieAction,
): void | WalkieState {
  switch (action.type) {
    case 'started': {
      const { callId, camDefaultOn } = action

      return {
        ...initialWalkieState,
        callId,

        // Preserve talking and camera
        weTalking: draft.weTalking,
        weSharingCamera: draft.weSharingCamera || !!camDefaultOn,

        // Queue current peer for cleanup
        // @ts-ignore
        participantsCleaningQueue: [
          ...draft.participantsCleaningQueue,
          ...Object.values(draft.participants),
        ],
      }
      break
    }

    case 'ended': {
      if (draft.callId !== action.callId) {
        debug('Skipped `ended` because id.')
        return
      }

      return {
        ...initialWalkieState,

        weTalking: action.left ? false : draft.weTalking,

        // Queue current peer for cleanup
        //@ts-ignore
        participantsCleaningQueue: [
          ...draft.participantsCleaningQueue,
          ...Object.values(draft.participants),
        ],

        // Experimenting
        // afterCallHookCalled: true,
      }
    }

    case 'triggered participants reset': {
      draft.participantsCleaningQueue = [
        ...draft.participantsCleaningQueue,
        ...Object.values(draft.participants),
      ]

      draft.participants = {}
      break
    }

    case 'cleaned up': {
      draft = { ...initialWalkieState }
      break
    }

    case 'new peer created': {
      // Queue current peer for cleanup
      let stalePeer = draft.participants[action.userId]
      if (stalePeer) {
        draft.participantsCleaningQueue.push({ ...stalePeer })
      }

      draft.participants[action.userId] = {
        userId: action.userId,
        peer: action.peer,
        receivedOffer: action.receivedOffer,
        peerCreatedAt: Date.now(),
        isPolitePeer: action.isPolitePeer,
        peerState: 'connecting',
      }
      break
    }

    case 'someone joined': {
      // Queue current peer for cleanup
      let stalePeer = draft.participants[action.userId]
      if (stalePeer) {
        draft.participantsCleaningQueue.push({ ...stalePeer })
      }

      draft.participants[action.userId] = {
        userId: action.userId,
        // Adding this will fail the reconnect on recheck agent completely and miserably
        // peerState: 'disconnected',
      }
      break
    }

    case 'peer closed': {
      if (!draft.participants[action.userId]) break
      draft.participants[action.userId].microphoneStream = undefined
      draft.participants[action.userId].cameraStream = undefined
      draft.participants[action.userId].screenStream = undefined
      draft.participants[action.userId].systemAudioStream = undefined
      draft.participants[action.userId].undecidedStreams = []
      break
    }

    case 'peer signal received': {
      if (!draft.participants[action.userId]) break
      if (action.signalType === 'offer') {
        draft.participants[action.userId].receivedOffer = true
      }
      if (action.signalType === 'answer') {
        draft.participants[action.userId].receivedAnswer = true
      }
      break
    }

    case 'peer connection state change': {
      if (!draft.participants[action.userId]) break

      if (action.state === 'connected') {
        draft.firstParticipantConnected = true
      } else {
        // Data channel closed, this is required so number of data channels accurately changes and we detect new channels
        // Fixes the bug with sending initial state to peers
        draft.participants[action.userId].dataOpen = false
      }

      draft.participants[action.userId].peerState = action.state
      break
    }

    case 'peer data open': {
      if (!draft.participants[action.userId]) break
      draft.participants[action.userId].dataOpen = true
      break
    }

    // Clearing peer is on the dispatchers shoulder (ie. to call cleanUpParticipant)
    case 'user went offline': {
      if (!draft.participants[action.userId]) break
      let participant = draft.participants[action.userId]

      // Queue current peer for cleanup
      let stalePeer = participant
      if (stalePeer) {
        draft.participantsCleaningQueue.push({ ...stalePeer })
      }

      // Note (@mo)
      // DON'T REMOVE FROM PARTICIPANTS OBJECT, AS IT'LL RESULT IN AN INIFINITE LOOP
      // WITH  `participantsIdsInStateIntegrity` dep on peer creator useEffect

      participant.peer = undefined
      participant.lastPeerCreatedAt = participant.peerCreatedAt
      participant.peerCreatedAt = undefined
      participant.microphoneStream = undefined
      participant.screenStream = undefined
      participant.cameraStream = undefined
      participant.senderOfMic = undefined
      participant.senderOfCamera = undefined
      participant.senderOfScreen = undefined
      participant.outgoingPersonStream = undefined
      participant.outgoingScreenStream = undefined
      participant.outgoingMicrophoneTrack = undefined
      participant.outgoingCameraTrack = undefined
      participant.isMuted = undefined
      participant.isTalking = false
      participant.isSharingScreen = false
      participant.isSharingCamera = false

      participant.peerState = 'closed'
      participant.peer = undefined
      participant.microphoneStream = undefined
      participant.screenStream = undefined
      break
    }

    // Clearing peer is on the dispatchers shoulder (ie. to call cleanUpParticipant)
    case 'peer cleaned up from queue': {
      draft.participantsCleaningQueue = draft.participantsCleaningQueue.filter(
        (party) => party.userId !== action.instance.userId,
        // (party) => party !== action.instance,
        // || party.pairId !== action.instance.pairId,
      )
      break
    }

    // Clear a list all at once
    case 'peer queue cleaned up': {
      // draft.participantsCleaningQueue = draft.participantsCleaningQueue
      //   // slice up to the point we cleaned up
      //   .slice(action.instances.length)
      draft.participantsCleaningQueue = (draft.participantsCleaningQueue as ParticipantState[]).filter(
        (instance) =>
          !action.instances.some((aI) => aI.userId === instance.userId),
      )
      break
    }

    case 'triggered single participant reset':
    case 'left': {
      // Queue current peer for cleanup
      let stalePeer = draft.participants[action.userId]
      if (stalePeer) {
        draft.participantsCleaningQueue.push({ ...stalePeer })
      }

      delete draft.participants[action.userId]
      break
    }

    case 'got track': {
      const participant = draft.participants[action.userId]
      if (!participant) break

      // if not undecided, remove from undecided
      // TEMP COMMENTED BC OF CAMERA IN SCREEN BUG
      // if (
      //   action.kind !== 'undecided' &&
      //   participant.undecidedStreams &&
      //   action.track
      // ) {
      //   participant.undecidedStreams = participant.undecidedStreams.filter(
      //     (stream) => !action.track?.id || !stream.getTrackById(action.track.id),
      //   )
      // }
      // ---

      switch (action.kind) {
        case 'audio':
          if (
            !participant.microphoneStream ||
            !participant.microphoneStream.active
          ) {
            participant.microphoneStream = action.stream
            // TODO: Maybe it'll cause bugs
          } else if (
            participant.isSharingScreen ||
            participant.isSharingSystemAudio
          ) {
            // system audio
            participant.systemAudioStream = action.stream
          } else {
            // To fix issue where mic doesn't play
            // FIXME: At least play it somehow
            participant.microphoneStream = new MediaStream([
              ...(participant.microphoneStream?.getTracks() || []),
              ...action.stream.getTracks(),
            ])
            console.warn('Unhandled audio stream 2')
            Sentry.captureMessage('Unhandled audio stream 2')
          }
          break

        case 'screen':
          if (participant.screenStream?.id !== action.stream.id) {
            participant.screenStream = action.stream
          }
          break

        case 'camera':
          if (participant.cameraStream?.id !== action.stream.id) {
            participant.cameraStream = action.stream
          }
          break

        case 'systemAudio':
          participant.systemAudioStream = action.stream
          break

        case 'mic':
          if (participant.microphoneStream?.id !== action.stream.id) {
            participant.microphoneStream = action.stream
          }
          break

        case 'undecided':
          if (!participant.undecidedStreams) {
            participant.undecidedStreams = []
          }

          if (participant.isSharingScreen && participant.isSharingCamera) {
            // do not do anything to prevent flash
            break
          }

          // check again if participant is sharing screen and it is active and has video tracks, then add stream to screen streams
          if (
            participant.isSharingScreen &&
            action.stream.active &&
            action.stream.getVideoTracks().length > 0
          ) {
            participant.screenStream = action.stream
          } else if (
            // don't allow screen and camera at the same time for now
            participant.isSharingCamera &&
            action.stream.active &&
            action.stream.getVideoTracks().length > 0
          ) {
            participant.cameraStream = action.stream
          } else {
            participant.undecidedStreams.push(action.stream)
          }

          break
      }
      break
    }

    case 'outgoing person stream set': {
      const participant = draft.participants[action.userId]
      if (!participant) break

      participant.outgoingPersonStream = action.stream
      participant.senderOfMic = action.senderOfMic
      participant.outgoingMicrophoneTrack = action.audioTrack
      break
    }

    case 'outgoing camera track set': {
      const participant = draft.participants[action.userId]
      if (!participant) break

      participant.outgoingCameraTrack = action.track
      participant.senderOfCamera = action.senderOfCamera
      break
    }

    case 'outgoing camera stream ended': {
      const participantsKeys = Object.keys(draft.participants)
      for (const key of participantsKeys) {
        let participant = draft.participants[key]
        if (!participant) continue
        participant.outgoingCameraTrack = undefined
      }
      break
    }

    case 'synced talking initially': {
      if (draft.userToggledTalking) break
      draft.weTalking = action.weTalking
      break
    }

    case 'walkie talking state changed': {
      const participant = draft.participants[action.userId]
      if (!participant) break

      participant.isTalking = action.isTalking
      break
    }

    case 'walkie deafen state changed': {
      draft.isDeafen = action.isDeafen
      break
    }

    case 'talking': {
      draft.userToggledTalking = true
      draft.weTalking = action.talking
      break
    }

    case 'after call hook completed': {
      draft.afterCallHookCalled = false
      break
    }

    case 'got user idle state': {
      draft.idleState = action.idleState
      break
    }

    case 'got participant idle state': {
      const participant = draft.participants[action.userId]
      if (!participant) break

      participant.idleState = action.idleState
      break
    }

    case 'sharing screen started': {
      draft.weSharingScreen = true
      draft.hostUserId = action.currentUserId

      // Disable camera sharing
      // If was sharing camera, re-enable after sharing
      // draft.enableCameraAfterSharing = draft.weSharingCamera
      // draft.weSharingCamera = false

      // Auto switch to me
      if (draft.isObserving) {
        draft.isObserving = false
      }

      // Optimistically set others sharing false
      for (let userId in draft.participants) {
        draft.participants[userId].isSharingScreen = false
      }

      break
    }

    case 'sharing screen ended': {
      draft.weSharingScreen = false
      draft.hostUserId = undefined

      if (draft.enableCameraAfterSharing) {
        draft.weSharingCamera = true
        draft.enableCameraAfterSharing = false
      }

      break
    }

    case 'participant sharing screen state changed': {
      const participant = draft.participants[action.userId]
      if (!participant) break

      participant.isSharingScreen = action.isSharingScreen

      /**
       * If we're sharing screen, switch to them
       */
      if (draft.weSharingScreen && action.isSharingScreen) {
        console.info('draft.weSharingScreen && action.isSharingScreen')
        draft.weSharingScreen = false
        draft.isObserving = true
      }

      if (action.isSharingScreen) {
        draft.hostUserId = action.userId
      }

      /**
       * if participant stopped sharing screen
       * and we are observing him
       * stop observing
       */
      if (
        !action.isSharingScreen &&
        draft.isObserving &&
        draft.hostUserId === action.userId
      ) {
        console.info(
          '!action.isSharingScreen && draft.isObserving && draft.hostUserId === action.userId',
        )
        draft.isObserving = false
        draft.hostUserId = undefined

        // todo extract leaving to a function
        if (draft.enableCameraAfterSharing) {
          draft.weSharingCamera = true
          draft.enableCameraAfterSharing = false
        }
      }

      if (!action.isSharingScreen && draft.hostUserId === action.userId) {
        draft.hostUserId = undefined
      }

      /**
       * if participant is sharing screen and we have a undecidedStream
       * change undecidedStream to screenStream
       */
      if (
        action.isSharingScreen &&
        participant.undecidedStreams &&
        participant.undecidedStreams.length > 0
      ) {
        debug(
          'moved undecidedStream to screenStream because participant sharing screen state changed',
        )
        for (let undecidedStream of participant.undecidedStreams) {
          if (undecidedStream.active) {
            participant.screenStream = undecidedStream
          }
        }
        participant.undecidedStreams = []
      }

      break
    }

    case 'sharing camera state changed': {
      const participant = draft.participants[action.userId]
      if (!participant) break

      participant.isSharingCamera = action.isSharingCamera

      /**
       * if participant is sharing screen and we have a undecidedStream
       * change undecidedStream to screenStream
       */
      if (
        action.isSharingCamera &&
        participant.undecidedStreams &&
        participant.undecidedStreams.length > 0 &&
        // experiemntal to prevent flash
        // and there's no active stream currently
        !participant.cameraStream?.active
      ) {
        debug(
          'moved undecidedStream to cameraStream because participant sharing camera state changed',
        )
        for (let undecidedStream of participant.undecidedStreams) {
          if (undecidedStream.active) {
            participant.cameraStream = undecidedStream
          }
        }
        participant.undecidedStreams = []
      }
      break
    }
    case 'sharing system audio state changed': {
      const participant = draft.participants[action.userId]
      if (!participant) break

      participant.isSharingSystemAudio = action.isSharingSystemAudio
      break
    }

    case 'observing state changed': {
      draft.isObserving = action.state
      break
    }

    case 'sharing camera started': {
      draft.weSharingCamera = true
      break
    }

    case 'sharing camera stopped': {
      draft.weSharingCamera = false
      break
    }

    case 'change participant sharing screen state locally': {
      let participant = draft.participants[action.participantUserId]
      if (!participant) break
      participant.isSharingScreen = true
      break
    }

    case 'participant observing state changed': {
      let participant = draft.participants[action.userId]
      if (!participant) break

      participant.isObserving = action.state
      break
    }

    case 'joined participant screen': {
      draft.isObserving = true
      if (!draft.hostUserId) {
        draft.hostUserId = action.userId
        console.warn('weird behavior: hostUserId was undefined, but we joined')
      }

      // Stop camera
      // draft.enableCameraAfterSharing = draft.weSharingCamera
      // draft.weSharingCamera = false
      break
    }

    case 'left participant screen': {
      draft.isObserving = false

      if (draft.enableCameraAfterSharing) {
        draft.weSharingCamera = true
        draft.enableCameraAfterSharing = false
      }
      break
    }

    case 'created screen data channel': {
      let participant = draft.participants[action.userId]
      if (!participant) break
      participant.screenDataChannel = action.dataChannel
      break
    }

    case 'screen settings updated': {
      if ('quality' in action) {
        draft.screenSettings.quality = action.quality
      }

      if ('contentHint' in action) {
        draft.screenSettings.contentHint = action.contentHint
      }
      break
    }

    case 'system muted changed': {
      draft.systemMuted = action.muted
      break
    }

    case 'volume muted changed': {
      draft.volumeMuted = action.muted
      break
    }

    case 'participant system muted changed': {
      let participant = draft.participants[action.userId]
      if (!participant) break
      participant.systemMuted = action.muted
      break
    }

    case 'remote control privilege changed': {
      draft.remoteControlPrivilege = action.remoteControlPrivilege
      break
    }

    case 'sharing system audio started': {
      draft.weSharingSystemAudio = true
      break
    }

    case 'sharing system audio stopped': {
      draft.weSharingSystemAudio = false
      break
    }

    default:
      break
  }
}

function peerId(userId: string) {
  return `Peer#${userId.slice(0, 9)}`
}

function sdpTransform(
  offer: RTCSessionDescriptionInit,
): RTCSessionDescriptionInit {
  if (!offer.sdp) {
    return offer
  }

  // temporarily, let's do cpu detection later...
  // let sdp = preferCodecInSdp(String(offer.sdp), 'h264') as string
  let sdp = preferCodecInSdp(String(offer.sdp), 'vp9') as string
  return { type: offer.type, sdp }
}

export type PeerEventContext = {
  peer: RtcPeerType
  pairId: number | undefined
  userId: string
  streamsRef: { current: StreamsObject | undefined }
  sendToAll: (data: DataType) => number
  sendToUser: (userId: string, data: DataType) => boolean
}

// Whoever has joined last, is polite,
// For example: Always first person is the impolite (rude) peer
// function getIsPolitePeer(
//   we: WalkieCallParticipant,
//   participant: WalkieCallParticipant,
// ) {

//   // Whoever has joined last, is initiator,
//   // For example: Always first person is not initiator of any peer
//   let theirJoinedAt = new Date(participant.joinedAt).getTime()
//   let ourJoinedAt = new Date(we.joinedAt).getTime()

//   let isInitiator: boolean | undefined

//   if (theirJoinedAt === ourJoinedAt) {
//     isInitiator = Number(participant.index) < Number(we.index)
//   } else {
//     isInitiator = theirJoinedAt < ourJoinedAt
//   }

//   return isInitiator
// }

function getIsPolitePeer(
  we: WalkieCallParticipant,
  participant: WalkieCallParticipant,
) {
  // first joined should be the IMPOLITE & OFFERER
  return !getIsFirstJoined(we, participant)
}

function getIsOfferer(
  we: WalkieCallParticipant,
  participant: WalkieCallParticipant,
) {
  return getIsFirstJoined(we, participant)
}

function getIsFirstJoined(
  we: WalkieCallParticipant,
  participant: WalkieCallParticipant,
) {
  // Whoever has joined last, is initiator,
  let theirJoinedAt = participant.joinedAt
    ? new Date(participant.joinedAt).getTime()
    : undefined
  let ourJoinedAt = we.joinedAt ? new Date(we.joinedAt).getTime() : undefined

  if (!ourJoinedAt || !theirJoinedAt || ourJoinedAt === theirJoinedAt) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        ourJoinedAt,
        theirJoinedAt,
      })
      Sentry.captureMessage('Joined at were equal')
    })
    // fallback
    return (
      participant.id.charCodeAt(14) +
        participant.id.charCodeAt(10) +
        participant.id.charCodeAt(11) <
      we.id.charCodeAt(14) + we.id.charCodeAt(10) + we.id.charCodeAt(11)
    )
  }

  // the latest joiner sends an offer
  // return ourJoinedAt > theirJoinedAt
  return ourJoinedAt < theirJoinedAt
}
