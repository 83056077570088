import { NurResult } from '@there/components/sun/utils/types'
import { useNurClient } from '@there/components/sun/context'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CombinedError } from '@there/components/sun/utils/error'
import { QueryOptions } from '@there/components/sun/client'

export interface QueryResult<Data = any> {
  stale?: boolean
  fetching: boolean
  data: Data | null | undefined
  error: CombinedError | undefined | null
}

interface QueryInput<Variables = Record<string, any>> {
  method: string
  variables?: Variables
  pause?: boolean
}

export type FetchMore = (variables: Record<string, any>) => void

export const useNurQuery = <Data = any, Variables = Record<string, any>>(
  query: QueryInput<Variables> & QueryOptions,
): [
  QueryResult<Data>,
  () => void,
  (variables: Record<string, any>) => void,
] => {
  let client = useNurClient()
  let [result, setResult] = useState<NurResult>({
    // @ts-ignore
    id: '',
    stale: false,
    fetching: false,
    data: null,
    error: null,
  })

  let fetchPolicy = query?.fetchPolicy
  let previousQuery = useRef<QueryInput | undefined>()
  let memoizedQuery = useMemo(() => {
    if (
      !previousQuery.current ||
      isDifferentShallowly(query, previousQuery.current)
    ) {
      previousQuery.current = query
      return query
    } else {
      return previousQuery.current
    }
  }, [query])

  let reExecute = useRef(() => {})
  let fetchMore = useRef<FetchMore>(() => {})

  useEffect(() => {
    if (!client) {
      return
    }

    if (memoizedQuery.pause) {
      return
    }

    let op = client.query(
      memoizedQuery,
      {
        next: (result) => {
          // deep equality check?
          setResult(result)
        },
        complete: () => {},
      },
      { fetchPolicy },
    )

    reExecute.current = op.reExecute
    fetchMore.current = op.fetchMore

    return () => {
      op.unsubscribe()
    }
  }, [client, fetchPolicy, memoizedQuery])

  return [
    result,
    useCallback(() => {
      reExecute.current()
    }, [reExecute]),
    useCallback(
      (variables: Record<string, any>) => {
        fetchMore.current(variables)
      },
      [fetchMore],
    ),
  ]
}

/** go one level deep for variables */
function isDifferentShallowly(
  objectA: Record<string, any>,
  objectB: Record<string, any>,
) {
  for (let key in objectA) {
    if (typeof objectA[key] === 'object') {
      if (isDifferentShallowly(objectA[key], objectB[key])) {
        return true
      }
    } else if (objectA[key] !== objectB[key]) {
      return true
    }
  }

  return false
}
