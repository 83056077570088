import { feedBackgroundDark } from '@there/shared/desktop/windows-config'
import React, { memo, useLayoutEffect, useRef, useState } from 'react'
import { Platform, useWindowDimensions, View } from 'react-native'
import { useUiContext } from '@there/components/shared/UiContext'

import { SideChats } from '../sidebar/SideContent'

export const sidebarHorizontalPadding = 16
export const sidebarRightPadding = 10
const defaultSidebarWidth = 200

export const Sidebar = memo(() => {
  const sidebarRef = useRef<View | null>(null)
  const [isResizing, setIsResizing] = useState(false)
  const [sidebarWidth, setSidebarWidth] = useState(defaultSidebarWidth)

  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true)
  }, [])

  const resetToDefault = React.useCallback(() => {
    setSidebarWidth(defaultSidebarWidth)
  }, [])

  const stopResizing = React.useCallback(() => {
    setIsResizing(false)
  }, [])

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (!sidebarRef.current) return

      if (isResizing) {
        sidebarRef.current.measureInWindow((x: number) => {
          let left = x
          setSidebarWidth(mouseMoveEvent.clientX - left)
        })
      }
    },
    [isResizing],
  )

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') return
    document.body.style.cursor = isResizing ? 'col-resize' : 'unset'
  }, [isResizing])

  React.useEffect(() => {
    window.addEventListener('mousemove', resize)
    window.addEventListener('mouseup', stopResizing)
    return () => {
      window.removeEventListener('mousemove', resize)
      window.removeEventListener('mouseup', stopResizing)
    }
  }, [resize, stopResizing])

  let { width } = useWindowDimensions()
  let maxSize = width * 0.3
  let minSize = 200

  let { hasCustomBackground } = useUiContext()

  return (
    <>
      <View
        ref={sidebarRef}
        style={{
          width: Math.max(minSize, Math.min(sidebarWidth, maxSize)),
          height: '100%',
          borderRightWidth: 1,
          borderColor: 'rgba(255, 255, 255, 0.04)',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <SideChats />

        {!hasCustomBackground && (
          <div
            style={{
              height: 30,
              width: 4,
              position: 'absolute',
              top: 0,
              right: -2,
              zIndex: 3,
              // Fade the edge of side separator
              background: `linear-gradient(180deg, ${feedBackgroundDark} 0%, rgba(31, 40, 51, 0) 100%)`,
            }}
          />
        )}

        <div
          style={{
            height: '100%',
            width: 4,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            zIndex: 3,
            cursor: 'col-resize',
            background: isResizing ? `rgba(255,255,255,0.05)` : 'none',
          }}
          onDoubleClick={resetToDefault}
          onMouseDown={startResizing}
        ></div>
      </View>
    </>
  )
})
