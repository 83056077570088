import { JQLServerErrorMessage } from '@there/sun/ws/protocol'

/** An error which can consist of Nur errors and Network errors. */
export class SunError extends Error {
  public name: string
  public message: string
  public code: string

  constructor(message: string, code: string) {
    super(message)

    this.name = 'SunError'
    this.message = message
    this.code = code
  }

  toString() {
    return `${this.code} - ${this.message}`
  }
}

const generateErrorMessage = (networkErr?: Error, SunError?: SunError) => {
  let error = ''
  if (networkErr !== undefined) {
    return (error = `[Network] ${networkErr.message}`)
  }

  if (SunError !== undefined) {
    error += `[GraphQL] ${SunError.message}\n`
  }

  return error.trim()
}

const rehydrateSunError = (error: any): SunError => {
  if (typeof error === 'string') {
    return new SunError(error, 'UNKNOWN')
  } else if (typeof error === 'object' && error.message) {
    return new SunError(error.message, error.code)
  } else {
    return error as any
  }
}

/** An error which can consist of GraphQL errors and Network errors. */
export class CombinedError extends Error {
  public name: string
  public message: string
  public SunError: SunError | undefined
  public networkError?: Error
  public response?: any

  constructor({
    networkError,
    SunError,
    response,
  }: {
    networkError?: Error
    SunError?: string | SunError | Error
    response?: any
  }) {
    const normalizedSunError = SunError
      ? rehydrateSunError(SunError)
      : undefined
    const message = generateErrorMessage(networkError, normalizedSunError)

    super(message)

    this.name = 'CombinedError'
    this.message = message
    this.SunError = normalizedSunError
    this.networkError = networkError
    this.response = response
  }

  toString() {
    return this.message
  }
}

export function makeSunErrorFromMessage(serverMessage: JQLServerErrorMessage) {
  return new SunError(serverMessage.message || 'Failed', serverMessage.code)
}
