import * as React from 'react'
import Svg, { SvgProps, Rect, G, Path, Defs, ClipPath } from 'react-native-svg'

export function PhotoIcon(props: SvgProps) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <Rect
        x={0.75}
        y={0.97}
        width={18.5}
        height={18.5}
        rx={6.25}
        stroke="#fff"
        strokeOpacity={0.15}
        strokeWidth={1.5}
      />
      <G opacity={0.6} fill="#fff">
        <Path
          opacity={0.3}
          d="M10 9.553a2.667 2.667 0 110-5.333 2.667 2.667 0 010 5.333z"
        />
        <Path d="M4 15.686c.259-3.182 2.841-4.8 5.989-4.8 3.192 0 5.814 1.53 6.01 4.8.007.131 0 .534-.501.534H4.485c-.167 0-.499-.36-.485-.534z" />
      </G>
    </Svg>
  )
}

export function TwitterIcon(props: SvgProps) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <Rect
        x={0.75}
        y={0.97}
        width={18.5}
        height={18.5}
        rx={9.25}
        stroke="#fff"
        strokeOpacity={0.15}
        strokeWidth={1.5}
      />
      <G opacity={0.37} clipPath="url(#prefix__clip0)">
        <Path
          d="M15 7.119a4.096 4.096 0 01-1.178.323c.423-.254.749-.656.902-1.135a4.11 4.11 0 01-1.303.498 2.053 2.053 0 00-3.496 1.87 5.824 5.824 0 01-4.229-2.143 2.054 2.054 0 00.635 2.74 2.043 2.043 0 01-.929-.257c-.022.95.659 1.84 1.646 2.037a2.058 2.058 0 01-.927.035 2.053 2.053 0 001.916 1.425 4.125 4.125 0 01-3.037.85 5.808 5.808 0 003.145.921c3.81 0 5.961-3.217 5.831-6.102.401-.29.749-.65 1.024-1.062z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" transform="translate(5 5.22)" d="M0 0h10v10H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
