import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { View, Text, Image, ImageSourcePropType } from 'react-native'
import { useTheme } from 'styled-components'
import { SetBackgroundModalData, useModals } from '../feed/ModalsContext'
import { StandardButton } from '../main/StandardButton'
import { Pressable } from '../shared/Pressable'
import { Space } from '../shared/Space'
import { ModalDescription } from './ModalDescription'
import { ModalWrapper } from './ModalWrapper'
import { useSpring, a } from '@react-spring/native'
import { useAppContext } from '../shared/AppContext'
import { ModalTitle } from './ModalTitle'
import { UploadIcon } from './UploadIcon'
import { useBackgroundUploader } from '../feed/use-background-uploader'
import { useAtom } from 'jotai'
import { spaceContextRefetchAtom } from '../shared/spaceContext/context'
import { useUiContext } from '@there/components/shared/UiContext'
import { Slider } from '../shared/Slider'
import { useDrop } from '../v2/useDrop'
import { Header } from './Header'
import { SmallCrossIcon } from '../main/mainIcons'
import { useEditDialog } from '../main/useEditDialog'
import { APP_URL, NIGHTLY_APP_URL } from '@there/components/config'

const defaultBackgroundImages = [
  '/room-bg/01.jpg',
  '/room-bg/02.jpg',
  '/room-bg/03.png',
  '/room-bg/04.jpg',
  '/room-bg/05.jpg',
  '/room-bg/06.jpg',
]

export const SetBackgroundModal = () => {
  let theme = useTheme()
  let [modalsState, modalsDispatch] = useModals()
  let [{ refetchSpaceContext }] = useAtom(spaceContextRefetchAtom)
  let { activeSpaceId } = useAppContext()
  let { dispatch } = useUiContext()
  let [isDefaultBackgroundMode, setDefaultBackgroundMode] = useState(true)

  let [darkenFilter, setDarkenFilter] = useState(0)
  let [shouldHide, setHide] = useState(false)
  let [backgroundUrl, setBackgroundUrl] = useState<string | undefined>(
    undefined,
  )

  useEffect(() => {
    dispatch({
      type: 'preview darken percent changed',
      previewBgDarkenPercent: darkenFilter,
    })
  }, [darkenFilter, dispatch])

  let [productionPath, setProductionPath] = useState('')
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setProductionPath(`http://localhost:7000`)
    }
    if (typeof window === undefined) return
    return window.location.href.includes(NIGHTLY_APP_URL)
      ? setProductionPath(NIGHTLY_APP_URL)
      : setProductionPath(APP_URL)
  }, [])

  let [
    getBackground,
    uploadingBackground,
    optimisticBackground,
    uploadBackground,
    clearUploaderStates,
    getFile,
  ] = useBackgroundUploader()

  const dragRegionRef = useRef<HTMLDivElement | null>(null)
  let { aboutToDrop, registerDropAction } = useDrop({
    dragRegionRef,
    onDrop: (file) => {
      setBackgroundUrl(undefined)
      getFile(file)
      setDefaultBackgroundMode(false)
    },
  })

  useEffect(() => {
    if (!optimisticBackground) return

    dispatch({
      type: 'preview background added',
      background: optimisticBackground,
    })
  }, [dispatch, optimisticBackground])

  const onClose = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'setBackground' })
    dispatch({ type: 'preview background removed' })
    refetchSpaceContext()
    clearUploaderStates()
    setBackgroundUrl(undefined)
    setDefaultBackgroundMode(true)
    setHide(false)
    setDarkenFilter(0)
  }

  const setFromDefault = useCallback(
    (imagePath: string) => {
      setDefaultBackgroundMode(true)
      setBackgroundUrl(imagePath)
      dispatch({ type: 'preview background added', background: imagePath })
    },
    [dispatch],
  )

  let [, editDialog] = useEditDialog()

  let modalData = modalsState.modalsData[
    'setBackground'
  ] as SetBackgroundModalData
  if (!modalData) return null

  return (
    <ModalWrapper
      isOpen={modalsState.modals.includes('setBackground')}
      onClose={onClose}
      hide={shouldHide}
    >
      <div
        ref={(ref) => {
          registerDropAction(ref)
        }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          borderRadius: 8,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            width: 285,
          }}
        >
          <Header label="Set Room Background" onClose={onClose} />
          <Space vertical={15} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',

              width: '100%',
              marginTop: -10,
            }}
          >
            {defaultBackgroundImages.map((imagePath) => {
              return (
                <ImageItem
                  key={imagePath}
                  source={{ uri: imagePath }}
                  onPress={() => {
                    setFromDefault(imagePath)
                  }}
                  active={
                    backgroundUrl === imagePath && isDefaultBackgroundMode
                  }
                />
              )
            })}
          </View>
          <Space vertical={15} />

          {optimisticBackground ? (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                height: 60,
              }}
            >
              <View style={{}}>
                <Image
                  source={{ uri: optimisticBackground }}
                  width={85}
                  height={60}
                  style={{
                    width: 85,
                    height: 60,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: isDefaultBackgroundMode
                      ? theme.colors.transparent
                      : theme.colors.primary,
                  }}
                />
                <Pressable
                  onPress={() => {
                    setDefaultBackgroundMode(false)
                    if (!optimisticBackground) return
                    dispatch({
                      type: 'preview background added',
                      background: optimisticBackground,
                    })
                  }}
                  style={[
                    {
                      position: 'absolute',
                      width: 85,
                      height: 60,
                      borderRadius: 4,
                      backgroundColor: '#000',
                      opacity: darkenFilter / 100,
                    },
                  ]}
                />
                <Pressable
                  onPress={() => {
                    clearUploaderStates()
                    dispatch({ type: 'preview background removed' })
                  }}
                  style={({ hovered, pressed }) => [
                    {
                      position: 'absolute',
                      top: -5,
                      left: -5,
                      width: 14,
                      height: 14,
                      borderRadius: 16,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',

                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                    hovered && {
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    },
                    pressed && {
                      backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    },
                  ]}
                >
                  <SmallCrossIcon color={theme.colors.tertiaryText} />
                </Pressable>
              </View>
              <View style={{ width: 150 }}>
                <ModalDescription>Darken Filter Opacity</ModalDescription>
                <Space vertical={5} />
                <Slider
                  value={darkenFilter}
                  onValueChange={(value) => {
                    setDarkenFilter(parseInt(value.toFixed(0)))
                  }}
                  onDrag={() => {
                    setHide(true)
                  }}
                  onRelease={() => {
                    setHide(false)
                  }}
                />
              </View>
            </View>
          ) : (
            <Pressable
              onPress={() => {
                setDefaultBackgroundMode(false)
                setBackgroundUrl(undefined)
                getBackground()
              }}
              style={({ hovered, pressed }) => [
                {
                  width: '100%',
                  height: 60,
                  borderRadius: 4,

                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                !optimisticBackground && {
                  borderWidth: 1,
                  borderColor: aboutToDrop
                    ? theme.colors.quaternaryText
                    : theme.colors.quinaryLight,
                  borderStyle: 'dashed',
                },
                shouldHide && {
                  opacity: 0,
                },

                hovered &&
                  !optimisticBackground && {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  },
                pressed &&
                  !optimisticBackground && {
                    backgroundColor: 'rgba(255, 255, 255, 0.02)',
                  },
              ]}
            >
              <UploadIcon color={theme.colors.tertiaryLight} width={18} />
              <Text
                style={{
                  fontSize: theme.fontSizes.normal,
                  color: theme.colors.tertiaryLight,
                  marginLeft: 5,
                  lineHeight: 16,
                }}
              >
                or Drop here
              </Text>
            </Pressable>
          )}
          <Space vertical={10} />
          <View
            style={[
              {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              },
              shouldHide && {
                opacity: 0,
              },
            ]}
          >
            <StandardButton
              text="Cancel"
              backgroundColor="buttonBackground"
              textColor="secondaryText"
              size="normal"
              onPress={() => {
                onClose()
              }}
            />
            <Space horizontal={10} />
            <StandardButton
              text={uploadingBackground ? 'Uploading...' : 'Upload'}
              backgroundColor="primaryButtonBackground"
              textColor="secondaryText"
              size="normal"
              disabled={
                (!optimisticBackground && !backgroundUrl) || uploadingBackground
              }
              onPress={
                isDefaultBackgroundMode
                  ? () => {
                      if (!backgroundUrl) return
                      if (!activeSpaceId) return
                      let url = productionPath + backgroundUrl
                      editDialog({
                        id: modalData.dialogId,
                        backgroundUrl: url,
                        __extra: {
                          spaceId: activeSpaceId,
                        },
                      })
                        .then(() => {
                          onClose()
                        })
                        .catch(() => {
                          console.error('failed to upload')
                          onClose()
                        })
                    }
                  : () =>
                      uploadBackground({
                        dialogId: modalData.dialogId,
                        bgDarkenPercent: darkenFilter,
                      })
                        .then(() => {
                          onClose()
                        })
                        .catch(() => {
                          console.error('failed to upload')
                          onClose()
                        })
              }
            />
          </View>
        </View>
      </div>
    </ModalWrapper>
  )
}

const ImageItem = ({
  source,
  onPress,
  active,
}: {
  source: ImageSourcePropType
  onPress: () => void
  active?: boolean
}) => {
  let theme = useTheme()
  let [loaded, setLoad] = useState(false)
  let fadeStyleProps = useSpring({
    opacity: loaded ? 1 : 0,
    config: {
      tension: 400,
    },
  })
  return (
    <Pressable
      onPress={onPress}
      style={{
        borderRadius: 4,
        overflow: 'hidden',
        marginTop: 10,
        borderWidth: 1,
        borderColor: active ? theme.colors.primary : theme.colors.transparent,
        width: 85 + 2, // 2 is borderWidth*2
        height: 60 + 2,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <a.View
        style={[
          fadeStyleProps,
          {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            width: 85,
            height: 60,
          },
        ]}
      >
        <Image
          onLoadEnd={() => setLoad(true)}
          source={source}
          style={{ width: 85, height: 60 }}
        />
      </a.View>
    </Pressable>
  )
}
