import Sentry from '@there/app/utils/sentry'
import { electronApi, ipc } from '@there/desktop/utils/electron-api'
import { useCallback, useEffect, useMemo, useState } from 'react'

type Input = { name: string; id: string }
type Output = { name: string; id: string }

export function useInputList() {
  const [audioInputs, setAudioInputs] = useState<Input[]>([])
  const [audioOutputs, setAudioOutputs] = useState<Output[]>([])
  const [screenInputs, setScreenInputs] = useState<Input[]>([])

  useEffect(() => {
    async function run() {
      if (typeof window === 'undefined') {
        return
      }

      let sources
      // Just to get permission
      if (electronApi) {
        // @mo: Ask for permission another way! This takes 200ms
        // try {
        //   sources = await electronApi.getDesktopCapturerSources()
        // } catch (error) {
        //   Sentry.captureException(error)
        // }
      } else {
        // For debugging in browser
        const justForPermission = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        })

        // Stop them
        for (const track of justForPermission.getTracks()) track.stop()
      }

      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return
      }

      const devices = await navigator.mediaDevices.enumerateDevices()
      const audioInputList = devices
        .map(({ label, kind, deviceId }) => {
          if (kind == 'audioinput') {
            return { name: label, id: deviceId }
          }
          return undefined
        })
        .filter(Boolean)

      const audioOutputList = devices
        .map(({ label, kind, deviceId }) => {
          if (kind == 'audiooutput') {
            return { name: label, id: deviceId }
          }
          return undefined
        })
        .filter(Boolean)

      if (sources) {
        setScreenInputs(sources as Input[])
      }
      setAudioInputs(audioInputList as Input[])
      setAudioOutputs(audioOutputList as Output[])
    }

    run()
  }, [])

  return {
    screenInputs,
    audioInputs,
  }
}

export function useMicrophoneList(): [Input[], () => void] {
  const [audioInputs, setAudioInputs] = useState<Input[]>([])

  const getList = useCallback(async function getListInner() {
    if (typeof window === 'undefined') {
      return
    }

    const devices = await navigator.mediaDevices.enumerateDevices()

    const audioList = devices
      .map(({ label, kind, deviceId }) => {
        if (kind == 'audioinput') {
          return { name: label, id: deviceId }
        }
        return undefined
      })
      .filter(Boolean)

    return audioList
  }, [])

  useEffect(() => {
    const cancelled = { current: false }

    getList().then((list) => {
      if (cancelled.current) return
      setAudioInputs(list as Input[])
    })

    return () => {
      cancelled.current = true
    }
  }, [getList])

  let updateList = useCallback(() => {
    getList().then((list) => {
      setAudioInputs(list as Input[])
    })
  }, [getList])

  return [audioInputs, updateList]
}

export function useSpeakerList(): [Output[], () => void] {
  const [audioOutputs, setAudioOutputs] = useState<Output[]>([])

  const getList = useCallback(async function getListInner() {
    if (typeof window === 'undefined') {
      return
    }

    const devices = await navigator.mediaDevices.enumerateDevices()

    const audioList = devices
      .map(({ label, kind, deviceId }) => {
        if (kind == 'audiooutput') {
          return { name: label, id: deviceId }
        }
        return undefined
      })
      .filter(Boolean)

    return audioList
  }, [])

  useEffect(() => {
    const cancelled = { current: false }

    getList().then((list) => {
      if (cancelled.current) return
      setAudioOutputs(list as Output[])
    })

    return () => {
      cancelled.current = true
    }
  }, [getList])

  function updateList() {
    getList().then((list) => {
      setAudioOutputs(list as Output[])
    })
  }

  return [audioOutputs, updateList]
}

export function useDisplayList() {
  const [primaryDisplay, setPrimaryDisplay] = useState<
    Electron.Display | undefined
  >(undefined)
  const [externalDisplayList, setExternalDisplayList] = useState<
    Electron.Display[] | undefined
  >(undefined)

  const getDisplays = useCallback(async () => {
    let displays: {
      displayList: Electron.Display[]
      primaryDisplay: Electron.Display
    }
    displays = await ipc?.invoke('request-display-list')
    return displays
  }, [])

  useEffect(() => {
    const cancelled = { current: false }

    getDisplays().then((result) => {
      if (cancelled.current) return
      if (!result) return

      setPrimaryDisplay(result.primaryDisplay)
      setExternalDisplayList(
        result.displayList.filter(
          (display) => display.id !== result.primaryDisplay.id,
        ),
      )
    })

    return () => {
      cancelled.current = true
    }
  }, [getDisplays])

  let updateList = useCallback(() => {
    getDisplays().then((result) => {
      if (!result) return
      setPrimaryDisplay(result.primaryDisplay)
      setExternalDisplayList(
        result.displayList.filter(
          (display) => display.id !== result.primaryDisplay.id,
        ),
      )
    })
  }, [getDisplays])

  // For dropdown
  const allDisplays = useMemo(() => {
    return primaryDisplay
      ? [primaryDisplay, ...(externalDisplayList || [])]
      : externalDisplayList || []
  }, [primaryDisplay, externalDisplayList])

  return { primaryDisplay, externalDisplayList, allDisplays, updateList }
}

type DeviceGroups = {
  microphones: MediaDeviceInfo[]
  speakers: MediaDeviceInfo[]
  cameras: MediaDeviceInfo[]
}

export function useDevices(): [DeviceGroups, () => void] {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const updateDevices = useCallback(() => {
    if (typeof window === 'undefined') {
      return
    }

    let unmounted = false

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        if (unmounted) return
        setDevices(devices)
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('where', 'enumerateDevices')
          Sentry.captureException(error)
        })
        console.error(error)
      })

    return () => {
      unmounted = true
    }
  }, [])

  useEffect(() => {
    updateDevices()
  }, [updateDevices])

  let deviceGroups = useMemo(() => {
    let groups: DeviceGroups = {
      microphones: [],
      speakers: [],
      cameras: [],
    }

    for (let device of devices) {
      switch (device.kind) {
        case 'audioinput':
          groups.microphones.push(device)
          break

        case 'audiooutput':
          groups.speakers.push(device)

          break

        case 'videoinput':
          groups.cameras.push(device)
          break
      }
    }

    return groups
  }, [devices])

  return [deviceGroups, updateDevices]
}
