import gql from 'graphql-tag'
import { useQuery, UseQueryArgs } from 'urql'
import { UserInfo, userInfoFragment } from './fragments/userInfo'
import { userSpacesInfoFragment } from './fragments/userSpacesInfo'
import { AvatarInfo, avatarInfoFragment } from './fragments/avatarInfo'
import { MemberWithSpaceInfo } from '@there/components/urql/fragments/memberInfo'

export const UserSpacesQuery = gql`
  query userSpaces {
    user: currentUser {
      ...userInfo
      ...userSpacesInfo
      avatar {
        ...avatarInfo
      }
    }
  }
  ${userInfoFragment}
  ${avatarInfoFragment}
  ${userSpacesInfoFragment}
`

interface UserSpacesData extends UserInfo {
  memberships: MemberWithSpaceInfo[]
  avatar: AvatarInfo | null
}

export type Variables = unknown
export type Data = {
  user?: UserSpacesData
}

export const useUserSpaces = (
  args: Omit<UseQueryArgs<Data, Variables>, 'query'> = {},
) => useQuery<Data, Variables>({ query: UserSpacesQuery, ...args })
