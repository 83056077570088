import { useNurMutation } from '@there/components/sun/use-mutation'
import { LeaveDialogMessage } from '@there/sun/modules/leaveDialog'
interface Data {
  done: boolean
}

type Payload = LeaveDialogMessage['payload']
export interface LocalLeaveDialogVariables extends Payload {
  __extra: {
    dialogId: string | undefined | null
    avatarId: string
    spaceId: string
    userId: string
  }
}

export const useLeaveDialog = () => {
  return useNurMutation<Data, LocalLeaveDialogVariables>({
    method: 'leaveDialog',
  })
}
