import { ipc } from '@there/desktop/utils/electron-api'
import type { Permissions } from '@there/desktop/main/config'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLatest } from '@there/components/shared/use-latest'
import { atom, useAtom } from 'jotai'

type Props = {
  enabled: boolean
}

export type EnsureHasAccessFunc = (
  media: ('mic' | 'camera' | 'screen')[],
) => void
type MediaPermissions = {
  status: {
    hasCamera: boolean
    hasScreen: boolean
    hasMic: boolean
  }
  refetchStatus: () => void
  /** If doesn't have access, prompts user */
  ensureHasAccess: EnsureHasAccessFunc
}

export function useMediaPermissions({ enabled }: Props): MediaPermissions {
  let [status, setStatus] = useState<MediaPermissions['status']>({
    hasCamera: true,
    hasScreen: true,
    hasMic: true,
  })

  // Internal
  let [accessStatus, setAccessStatus] = useState<
    Permissions['accessStatus'] | null | undefined
  >(null)

  const refetchStatus = useCallback(() => {
    if (!ipc) return Promise.resolve(true)

    return ipc
      .invoke('has-resource-access')
      .then((permissions: Permissions) => {
        setStatus({
          hasScreen: permissions.gotScreen ?? true,
          hasMic: permissions.gotMicrophone ?? true,
          hasCamera: permissions.gotCamera ?? true,
        })

        // for later use
        if (permissions.accessStatus) {
          setAccessStatus(permissions.accessStatus)
        }
      })
  }, [])

  useEffect(() => {
    if (!enabled) return
    refetchStatus()
  }, [enabled, refetchStatus])

  let accessStatusRef = useLatest(accessStatus)
  let statusRef = useLatest(status)

  const ensureHasAccess: EnsureHasAccessFunc = useCallback(
    async (medias) => {
      await refetchStatus()

      // Debug
      console.info('accessStatus=', accessStatusRef.current, statusRef.current)

      if (medias.includes('camera')) {
        let shouldShowModal =
          (accessStatusRef.current &&
            accessStatusRef.current.camera !== 'granted') ||
          !statusRef.current?.hasCamera

        if (shouldShowModal) {
          ipc?.invoke('open-camera-permissions-guide-modal').catch(() => {})
        }
      }

      if (medias.includes('screen')) {
        let shouldShowModal =
          (accessStatusRef.current &&
            accessStatusRef.current.screen !== 'granted') ||
          !statusRef.current?.hasScreen

        if (shouldShowModal) {
          ipc?.invoke('open-screen-permissions-guide-modal').catch(() => {})
        }
      }

      if (medias.includes('mic')) {
        let shouldShowModal =
          (accessStatusRef.current &&
            accessStatusRef.current.microphone !== 'granted') ||
          !statusRef.current?.hasMic

        if (shouldShowModal) {
          ipc?.invoke('open-microphone-permissions-guide-modal').catch(() => {})
        }
      }
    },
    [accessStatusRef, refetchStatus, statusRef],
  )

  let value = useMemo(
    () => ({
      status,
      refetchStatus,
      ensureHasAccess,
    }),
    [ensureHasAccess, status, refetchStatus],
  )

  let [, setPermissionsAtom] = useAtom(permissionsAtom)

  useEffect(() => {
    if (value) {
      setPermissionsAtom(value)
    }
  }, [value, setPermissionsAtom])

  return value
}

export let permissionsAtom = atom<MediaPermissions>({
  status: {
    hasCamera: true,
    hasScreen: true,
    hasMic: true,
  },
  ensureHasAccess: () => {
    console.warn('Called function in permissions atom before being set')
  },
  refetchStatus: () => {
    console.warn('Called function in permissions atom before being set')
  },
})
