import React, { memo } from 'react'
import { useTheme } from '../feed/ThemeContext'
import styled from 'styled-components'
import { Dropdown } from '../feed/Dropdown'
import { Space } from '../shared/Space'
import { electronApi, ipc } from '@there/desktop/utils/electron-api'
import { useSelect } from 'downshift'
import { Tooltip } from '../shared/Tooltip'
import { CircleButton } from '@there/components/main/CircleButton'
import { HelpIcon, SettingsIcon } from '../feed/HeaderIcons'
import { useAppContext } from '../shared/AppContext'
import { logEvent } from '../shared/use-analytics'

export type SettingItem = {
  label: string
  onClick?: () => void
  size: 'normal' | 'small'
  disabled?: boolean
}

// Links
export const whatsNewNotionLink = `https://www.notion.so/there/What-s-New-77cb4376b2a348e0b263bd1d9c8676da`
export const AppGuide = `https://www.notion.so/there/App-Guide-d3a3164291c241408bdfad0219f300f9`
export const PermissionsGuide = `https://www.notion.so/there/App-Permissions-Guide-dab4567e8c764ed98f3ffe99c7c4c35d`
export const crispUrl = `https://go.crisp.chat/chat/embed/?website_id=cbfa35e7-76b1-45ac-9d9b-6aa76541cba5`
export const legalDocument = `https://www.notion.so/there/Legal-ffc7252f6042495dbcc1af3fb7799c79`
const guidesNotionLink = `https://www.notion.so/there/Quick-Start-2-min-6-steps-cf33845e2755460488b683996ae9aec2`
const statusLink = `https://status.usenoor.com/`

let openLink = (link: string) => {
  if (electronApi) {
    electronApi?.openExternalUrl(link)
  } else {
    if (window) {
      window.open(link, '__blank')
    }
  }
}

export const HelpMenu = memo(() => {
  const t = useTheme()
  let { currentUser } = useAppContext()

  const items: SettingItem[] = [
    {
      label: '💬 Feedback & Help',
      onClick: () => {
        logEvent('User Clicked Help')
        if (ipc) {
          ipc?.invoke('open-help-window', currentUser)
        } else {
          window.open(crispUrl, '__blank')
        }
      },
      size: 'normal',
    },

    {
      label: "🎉 What's New",
      onClick: () => openLink(whatsNewNotionLink),
      size: 'normal',
    },
    {
      label: '☕️ App Guide',
      onClick: () => {
        openLink(AppGuide)
        logEvent('User Clicked Guides')
      },
      size: 'normal',
    },
    {
      label: '☑️ Permissions Guide',
      onClick: () => {
        openLink(PermissionsGuide)
        logEvent('User Clicked Guides')
      },
      size: 'normal',
    },

    {
      label: '👋 Join our Community',
      onClick: () => {
        openLink('https://usenoor.com/community')
      },
      size: 'normal',
    },

    {
      label: 'Twitter - @NoorChat',
      onClick: () => {
        openLink('https://x.com/NoorChat')
      },
      size: 'small',
    },

    {
      label: 'Privacy & terms',
      onClick: () => {
        openLink(legalDocument)
      },
      size: 'small',
    },

    {
      label: 'Status',
      onClick: () => {
        openLink(statusLink)
      },
      size: 'small',
    },
  ]

  const {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    toggleMenu,
    closeMenu,
  } = useSelect({
    items,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return
      if (selectedItem.onClick) {
        logEvent('User Clicked Help')
        selectedItem.onClick()
      }
    },
  })

  return (
    <Dropdown.Root>
      <Dropdown.Toggle onPress={toggleMenu}>
        <Tooltip label="Help & Guides" placement="bottomCenter">
          <CircleButton
            backgroundMode="transparent"
            {...getToggleButtonProps(
              { refKey: 'noref' },
              { suppressRefError: true },
            )}
          >
            <HelpIcon color={t.colors.tertiaryLight} width={16} height={16} />
          </CircleButton>
        </Tooltip>
      </Dropdown.Toggle>

      <Dropdown.Menu
        {...getMenuProps(undefined, { suppressRefError: true })}
        isOpen={isOpen}
        onClose={closeMenu}
        width={160}
      >
        <Space vertical={4} />

        <Dropdown.Section>
          {items.map((item, index) => {
            let highlighted = index === highlightedIndex

            // Decide item style
            if (item.size === 'small') {
              return (
                <Dropdown.MiniItem
                  key={item.label}
                  highlighted={highlighted}
                  {...getItemProps({ item, index })}
                >
                  {item.label}
                </Dropdown.MiniItem>
              )
            }

            return (
              <Dropdown.Item
                key={item.label}
                highlighted={highlighted}
                size="normal"
                {...getItemProps({ item, index })}
              >
                {item.label}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Section>
        <Space vertical={4} />
      </Dropdown.Menu>
    </Dropdown.Root>
  )
})

export const SettingsButton = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 30px;
  border: 1.5px solid ${(p) => p.theme.colors.transparentInputBackground};
  box-sizing: border-box;
  /* background: #e9e5e6; */

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes['15']}px;
  color: ${(p) => p.theme.colors.secondaryText};

  transition: ${(p) => p.theme.transition.background};

  :hover {
    background: ${(p) => p.theme.colors.hoverItemBackground};
  }

  :active {
    background: ${(p) => p.theme.colors.activeItemBackground};
  }
`
