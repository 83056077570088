import { memo } from 'react'
import { Tooltip } from '../shared/Tooltip'
import { CircleButton } from '../main/CircleButton'
import { CameraIcon } from '../shared/CameraIcon'
import { useTheme } from '@there/components/feed/ThemeContext'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { electronSupports, isElectron } from '@there/desktop/utils/electron-api'
import { logEvent } from '@there/components/shared/use-analytics'
import { GoodButton } from '@there/components/shared/GoodButton'

export const CameraButton = memo(() => {
  let theme = useTheme()
  let { toggleCamera, walkieState } = useRtcContext()
  let sharingCamera = walkieState.weSharingCamera

  return (
    <>
      <Tooltip
        label={
          <>
            <span>{sharingCamera ? 'Disable' : 'Enable'} camera</span>
            <br />
            <span className="light">Beta 🎉</span>
          </>
        }
        placement="bottomCenter"
      >
        <GoodButton
          variant="transparent"
          onPress={() => {
            if (isElectron && !electronSupports?.cameraV1) {
              alert(
                'Please update your app to support camera! Click the settings icon and then "Check for Updates".',
              )
              return
            }
            logEvent('User Toggled Camera')
            toggleCamera()
          }}
          active={sharingCamera}
          activeColor={'cameraButtonIcon'}
          icon={
            <CameraIcon
              width={18}
              height={18}
              color={theme.colors.white}
              opacity={sharingCamera ? 1 : 0.6}
            />
          }
        />
      </Tooltip>
    </>
  )
})
