import { RtcManager, useRtcContext } from '@there/components/shared/use-rtc'
import {
  DataType,
  PeerOnJsonData,
} from '@there/components/shared/use-rtc-peers'
import { useListenToIpc } from '@there/desktop/utils/electron-api'
import { atom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useCallback, useEffect } from 'react'
import { useImmerReducer } from 'use-immer'

export type PhotoViewersState = {
  viewingMessageId: string | null
  pointersById: Record<string, PointerState | undefined>
  usersByMessageId: Record<string, string[] | undefined>
}

type PointerState = {
  /* percent */
  x: number
  y: number
  active: boolean
}

const initialState: PhotoViewersState = {
  viewingMessageId: null,
  usersByMessageId: {},
  pointersById: {},
}

type Action =
  | { type: 'active photo changed'; messageId: string | null }
  | { type: 'pointer updated'; userId: string; x: number; y: number }
  | { type: 'user joined'; messageId: string; userId: string }
  | { type: 'user left'; messageId: string; userId: string }

export const photoViewersReducer = (
  draft: PhotoViewersState,
  action: Action,
) => {
  switch (action.type) {
    case 'active photo changed': {
      draft.viewingMessageId = action.messageId
      break
    }

    case 'pointer updated': {
      // if (
      //   draft.viewingMessageId &&
      //   !draft.usersByMessageId[draft.viewingMessageId]?.includes(action.userId)
      // ) {
      //   // not in participants
      //   return
      // }

      draft.pointersById[action.userId] = {
        x: action.x,
        y: action.y,
        active: true,
      }
      break
    }

    case 'user joined': {
      draft.usersByMessageId[action.messageId] = [
        action.userId,
        ...(draft.usersByMessageId[action.messageId] || []),
      ]
      break
    }

    case 'user left': {
      draft.usersByMessageId[action.messageId] = draft.usersByMessageId[
        action.messageId
      ]?.filter((id) => id !== action.userId)

      delete draft.pointersById[action.messageId]
      break
    }
  }
}

export const usePhotoViewerManager = ({
  rtcManager,
}: {
  rtcManager: RtcManager
}) => {
  let [state, dispatch] = useImmerReducer(photoViewersReducer, initialState)
  const {
    addJsonDataEventListener,
    removeJsonDataEventListener,
    // sendToUser,
    universalDataChannel,
    walkieState,
  } = rtcManager

  const dataHandler = useCallback(
    (userId: string, data: DataType) => {
      switch (data.type) {
        case 'photo cursor moved':
          dispatch({
            type: 'pointer updated',
            userId: userId,
            x: data.x,
            y: data.y,
          })
          break

        case 'photo opened':
          dispatch({
            type: 'user joined',
            userId: userId,
            messageId: data.messageId,
          })
          break

        case 'photo closed':
          dispatch({
            type: 'user left',
            messageId: data.messageId,
            userId: userId,
          })
          break
      }
    },
    [dispatch],
  )

  useEffect(() => {
    let handler: PeerOnJsonData = ({ userId }, data) =>
      dataHandler(userId, data)
    addJsonDataEventListener(handler)

    return () => {
      removeJsonDataEventListener(handler)
    }
  }, [
    addJsonDataEventListener,
    dataHandler,
    dispatch,
    removeJsonDataEventListener,
  ])

  useListenToIpc('rtc-data-channel-data', (_, { userId, data }) => {
    dataHandler(userId, data)
  })

  const opened = useCallback(
    (messageId: string) => {
      dispatch({
        type: 'active photo changed',
        messageId,
      })
      universalDataChannel.sendToAll({
        type: 'photo opened',
        messageId,
      })
    },
    [dispatch, universalDataChannel],
  )

  const closed = useCallback(
    (messageId: string) => {
      dispatch({
        type: 'active photo changed',
        messageId: null,
      })
      universalDataChannel.sendToAll({
        type: 'photo closed',
        messageId,
      })
    },
    [dispatch, universalDataChannel],
  )
  const updateMousePosition = useCallback(
    (x: number, y: number) => {
      if (!state.viewingMessageId) return
      let usersOnPhoto = state.usersByMessageId[state.viewingMessageId]
      if (!usersOnPhoto) return

      for (let user of usersOnPhoto) {
        // Send to others
        universalDataChannel.sendToUser(user, {
          type: 'photo cursor moved',
          x,
          y,
        })
      }
    },
    [state.usersByMessageId, state.viewingMessageId, universalDataChannel],
  )

  // Update atoms
  const setViewers = useUpdateAtom(viewersAtom)
  const setPointers = useUpdateAtom(pointersAtom)

  useEffect(() => {
    setViewers({
      usersByMessageId: state.usersByMessageId,
      viewingMessageId: state.viewingMessageId,
      opened,
      closed,
      updateMousePosition,
    })
  }, [
    closed,
    opened,
    setViewers,
    state.usersByMessageId,
    state.viewingMessageId,
    updateMousePosition,
  ])

  useEffect(() => {
    setPointers({
      pointersById: state.pointersById,
    })
  }, [setPointers, state.pointersById])

  return { state, opened, closed }
}

// Atom use hooks
export const usePhotoViewers = () => {
  return useAtomValue(viewersAtom)
}
export const usePhotoPointer = () => {
  return useAtomValue(pointersAtom)
}

// Atoms
const viewersAtom = atom<{
  viewingMessageId: PhotoViewersState['viewingMessageId']
  usersByMessageId: PhotoViewersState['usersByMessageId']
  opened: (messageId: string) => void
  closed: (messageId: string) => void
  updateMousePosition: (x: number, y: number) => void
}>({
  viewingMessageId: null,
  usersByMessageId: {},
  opened: () => {},
  closed: () => {},
  updateMousePosition: () => {},
})

const pointersAtom = atom<{
  pointersById: PhotoViewersState['pointersById']
}>({
  pointersById: {},
})
