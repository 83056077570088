import { ChatPeerId } from '@there/components/v2/useRenderingPhase'
import { useImmerReducer } from 'use-immer'
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAtom } from 'jotai'
import { activeNewChatPeerAtom } from '../atoms/chatAtom'
import { useLatest } from '../shared/use-latest'
import {
  FeedWindowManager,
  useMainWindowContext,
} from '../shared/use-main-window'

export const useChatsState = ({
  feedWindowManager,
}: {
  feedWindowManager: FeedWindowManager
}) => {
  let [state, dispatch] = useImmerReducer(reducer, initialState)
  let [activeChatPeer, setActiveChatPeer] = useAtom(activeNewChatPeerAtom)
  let activeChatPeerRef = useLatest(activeChatPeer)
  let mainWindowDispatch = feedWindowManager.dispatch

  useEffect(() => {
    dispatch({ type: 'active chat peer updated', activeChatPeer })
  }, [activeChatPeer, dispatch])

  const [hydrated, setHydrated] = useState(false)

  // Load state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    const stateJson = localStorage.getItem('chatsState')

    if (
      !stateJson ||
      typeof stateJson !== 'string' ||
      !stateJson.startsWith('{')
    ) {
      setHydrated(true)
      return
    }

    // Hydrate
    let cachedState = JSON.parse(stateJson) as State
    dispatch({ type: 'load state', state: cachedState })
    if (
      (cachedState.activeChatPeer?.peerTopicId ||
        cachedState.activeChatPeer?.peerUserId) &&
      !activeChatPeerRef.current.peerTopicId &&
      !activeChatPeerRef.current.peerUserId
    ) {
      setActiveChatPeer(cachedState.activeChatPeer)
      mainWindowDispatch({ type: 'change mode', mode: 'chat' })
    }
    setHydrated(true)
  }, [activeChatPeerRef, dispatch, mainWindowDispatch, setActiveChatPeer])

  // Persist state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    // Don't replace our cache with empty state
    if (!hydrated) {
      return
    }

    localStorage.setItem('chatsState', JSON.stringify(state))
  }, [hydrated, state])

  let value = useMemo(() => {
    return { state, dispatch }
  }, [dispatch, state])

  return value
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'draft updated': {
      let peerState = state.byPeerId[action.peer.id]
      // Init peer state
      if (!peerState) {
        state.byPeerId[action.peer.id] = peerState = { ...initialPeerState }
      }
      peerState.draft = {
        ...peerState.draft,
        ...action.draft,
      }
      break
    }

    case 'editing msgId updated': {
      let peerState = state.byPeerId[action.peer.id]
      // Init peer state
      if (!peerState) {
        state.byPeerId[action.peer.id] = peerState = { ...initialPeerState }
      }
      peerState.editingMsgId = action.msgId
      break
    }

    case 'replying msgId updated': {
      let peerState = state.byPeerId[action.peer.id]
      // Init peer state
      if (!peerState) {
        state.byPeerId[action.peer.id] = peerState = { ...initialPeerState }
      }
      peerState.replyingToMsgId = action.msgId
      break
    }
    case 'active chat peer updated': {
      state.activeChatPeer = action.activeChatPeer
      break
    }
    case 'load state': {
      return action.state
    }
  }
}

type State = {
  byPeerId: Record<string, ChatState>
  activeChatPeer: ActivePeerType
}

export type ChatState = {
  editingMsgId: string | null
  replyingToMsgId: string | null
  draft: DraftMessage | undefined
}

let initialPeerState: ChatState = {
  draft: undefined,
  editingMsgId: null,
  replyingToMsgId: null,
}

export type DraftMessage = {
  message: string
}

type ActivePeerType = {
  peerTopicId?: null | string | undefined
  peerUserId?: null | string | undefined
}
const initialState: State = {
  byPeerId: {},
  activeChatPeer: {
    peerTopicId: null,
    peerUserId: null,
  },
}

type Action =
  | { type: 'replying msgId updated'; peer: ChatPeerId; msgId: string | null }
  | { type: 'draft updated'; peer: ChatPeerId; draft: DraftMessage }
  | { type: 'editing msgId updated'; peer: ChatPeerId; msgId: string | null }
  | { type: 'load state'; state: State }
  | { type: 'active chat peer updated'; activeChatPeer: ActivePeerType }

export type ChatsStateDispatch = React.Dispatch<Action>

type ChatContextType = {
  state: State
  dispatch: ChatsStateDispatch
}
const initialValue: ChatContextType = {
  state: initialState,
  dispatch: () => {},
}

export const ChatContext = createContext<ChatContextType>(initialValue)
export const useChatContext = () => {
  return useContext(ChatContext)
}
