import { NewChat, UserSpaceStatuses } from '@prisma/client'
import { BucketEndpoint } from '@there/shared/api/config'
import { filterFalsy } from '@there/shared/utilities/filter-falsy'
import { Api, FullDb } from '@there/sun/utils/node-types'
import { toGlobalId } from '@there/tower/utils/global-id'

export const formatList = <T extends null | undefined | any[]>(
  formatter: (type: any) => any,
  list: T,
): undefined | any[] => {
  return typeof list !== 'undefined' && list !== null
    ? (list.map(formatter) as any[])
    : undefined
}

export const spaceFormatter = (
  space: undefined | FullDb['Space'] | Api['Space'],
): Api['Space'] | undefined => {
  if (!space) return space

  return {
    id: toGlobalId('Space', space.id),
    name: space.name || '',
    joinSecretCode: space.joinSecretCode || '',
    type: space.type,

    members: space.members
      ? formatList(memberFormatter, space.members)
      : undefined,
    avatars:
      typeof space.members !== 'undefined'
        ? // @ts-ignore
          space.members
            // todo: fix this type
            // @ts-ignore
            .map((member: Api['Member'] | FullDb['Member']) =>
              member.user?.avatar ? avatarFormatter(member.user.avatar) : false,
            )
            .filter(filterFalsy)
        : undefined,
    topics: space.topics ? formatList(topicFormatter, space.topics) : undefined,
    dialogs: space.dialogs
      ? formatList(dialogFormatter, space.dialogs)
      : undefined,
    featurePreviews: space.featurePreviews,
    creatorId: space.creatorId
      ? toGlobalId('User', space.creatorId)
      : undefined,
    logo: space.logo ? documentFormatter(space.logo) : undefined,
  }
}

export const memberFormatter = (
  node: FullDb['Member'] | Api['Member'],
): Api['Member'] => {
  if (!node) return node

  return {
    id: toGlobalId('Member', node.id),
    user: node.user ? userFormatter(node.user) : undefined,
    userId: node.userId ? toGlobalId('User', node.userId) : undefined,
    inviteAccepted: node.inviteAccepted,
    role: node.role,
    spaceId: node.spaceId ? toGlobalId('Space', node.spaceId) : undefined,
    space: spaceFormatter(node.space),
  }
}

export const userFormatter = (
  node: FullDb['User'] | Api['User'],
): Api['User'] => {
  if (!node) return node

  let result: Api['User'] = {
    id: toGlobalId('User', node.id),
    email: node.email,
    name: node.name,
    nickname: node.nickname,
    status: node.status,
    timezone: node.timezone,
    profilePhoto: formatProfilePhoto(node.profilePhoto),
    pendingSetup: node.pendingSetup,
    activeSpaceId: node.activeSpaceId
      ? toGlobalId('Space', node.activeSpaceId)
      : undefined,
    emailVerified:
      'isEmailVerified' in node ? node.isEmailVerified : node.emailVerified,
    doorClosed: node.doorClosed,
    focused: node.focused,
    talking: node.talking,
    sharing: node.sharing,
    online: node.online,
    lastOnline: formatTime(node.lastOnline),
    updatedAt: formatTime(node.updatedAt),
    createdAt: formatTime(node.createdAt),
    memberships: formatList(memberFormatter, node.memberships),
    avatar: node.avatar ? avatarFormatter(node.avatar) : undefined,
    presenceId: node.presence
      ? toGlobalId('Presence', node.presence.id)
      : undefined,
    UserFocus:
      node.UserFocus && node.UserFocus.length > 0
        ? formatList(userFocusFormatter, node.UserFocus)
        : undefined,
  }

  return result
}

export const userListFormatter = (
  users: FullDb['User'][] | Api['User'][],
): Api['User'][] => {
  return formatList(userFormatter, users) as Api['User'][]
}

export const userFocusFormatter = (
  node: FullDb['UserFocus'] | Api['UserFocus'],
): Api['UserFocus'] => {
  if (!node) return node

  return {
    id: toGlobalId('UserFocus', node.id),
    userId: toGlobalId('User', node.userId),
    startedAt: formatTime(node.startedAt),
    endedAt: formatTime(node.endedAt),
  }
}

export const avatarFormatter = (
  node: FullDb['Avatar'] | Api['Avatar'],
): Api['Avatar'] => {
  if (!node) return node

  return {
    id: toGlobalId('Avatar', node.id),
    user: node.user ? userFormatter(node.user) : undefined,
    dialog: node.dialog ? dialogFormatter(node.dialog) : undefined,
    dialogId: node.dialogId ? toGlobalId('Dialog', node.dialogId) : null,
    joinedAt: formatTime(node.joinedAt),
    userId: node.userId ? toGlobalId('User', node.userId) : undefined,
  }
}

export const dialogFormatter = (
  node: FullDb['Dialog'] | Api['Dialog'],
): Api['Dialog'] => {
  if (!node) return node

  return {
    id: toGlobalId('Dialog', node.id),
    spaceId:
      typeof node.spaceId !== 'undefined'
        ? node.spaceId !== null
          ? toGlobalId('Space', node.spaceId)
          : null
        : undefined,
    chatId: undefined,
    // typeof node.chatId !== 'undefined'
    //   ? node.chatId !== null
    //     ? toGlobalId('Chat', node.chatId)
    //     : null
    //   :
    //   undefined,
    avatarCount:
      typeof node.doorClosed !== 'undefined'
        ? node.avatarCount || 0
        : undefined,
    direct: typeof node.doorClosed !== 'undefined' ? node.direct : undefined,
    doorClosed:
      typeof node.doorClosed !== 'undefined' ? node.doorClosed : undefined,
    silent: typeof node.silent !== 'undefined' ? node.silent : undefined,
    title: typeof node.title !== 'undefined' ? node.title : undefined,
    iconEmoji:
      typeof node.iconEmoji !== 'undefined' ? node.iconEmoji : undefined,
    bgObjectId:
      typeof node.bgObjectId !== 'undefined'
        ? formatDialogBackground(node.bgObjectId)
        : undefined,
    bgDarkenPercent:
      typeof node.bgDarkenPercent !== 'undefined'
        ? node.bgDarkenPercent
        : undefined,
    creatorId:
      typeof node.creatorId !== 'undefined'
        ? node.creatorId
          ? toGlobalId('User', node.creatorId)
          : null
        : undefined,
    specialType:
      typeof node.specialType !== 'undefined' ? node.specialType : undefined,
    sessionId:
      typeof node.sessionId !== 'undefined' ? node.sessionId : undefined,
    createdAt:
      typeof node.createdAt !== 'undefined'
        ? formatTime(node.createdAt)
        : undefined,
    avatars:
      'avatars' in node && node.avatars
        ? formatList(avatarFormatter, node.avatars) || []
        : undefined,
    space: node.space ? spaceFormatter(node.space) || undefined : undefined,
    topic: node.topic ? topicFormatter(node.topic) || undefined : undefined,
    gameId: typeof node.gameId !== 'undefined' ? node.gameId : undefined,
    gameServer:
      typeof node.gameServer !== 'undefined' ? node.gameServer : undefined,
  }
}

export const chatFormatter = (
  node: FullDb['Chat'] | Api['Chat'],
): Api['Chat'] => {
  if (!node) return node

  return {
    id: toGlobalId('Chat', node.id),
    dialogId: node.dialogId ? toGlobalId('Dialog', node.dialogId) : undefined,
    messages:
      'messages' in node && node.messages
        ? formatList(messageFormatter, node.messages) || []
        : undefined,
  }
}
export const messageFormatter = (
  node: FullDb['Message'] | Api['Message'],
): Api['Message'] => {
  if (!node) return node

  let fileUrl

  //Convert objectId to url
  if (node.objectId && node.objectId !== '') {
    fileUrl = `${BucketEndpoint}/${node.objectId}`
  }

  return {
    id: toGlobalId('Message', node.id),
    chatId: toGlobalId('Chat', node.chatId),
    senderId: toGlobalId('User', node.senderId),
    sentAt: formatNonNullTime(node.sentAt),
    text: node.text,
    objectId: fileUrl,
    thumbnail: node.thumbnail,
    fileSize: node.fileSize,
  }
}

export const topicFormatter = (
  node: FullDb['Topic'] | Api['Topic'],
): Api['Topic'] => {
  if (!node) return node

  let spaceId = node.spaceId || node.space?.id
  let ownerId = node.ownerId

  return {
    id: toGlobalId('Topic', node.id),
    title: node.title || '',
    iconEmoji: node.iconEmoji || null,
    spaceId: spaceId ? toGlobalId('Space', spaceId) : undefined,
    space:
      'space' in node
        ? (node.space && spaceFormatter(node.space)) || null
        : undefined,
    ownerId: ownerId ? toGlobalId('User', ownerId) : undefined,
    owner:
      'owner' in node
        ? (node.owner && userFormatter(node.owner)) || null
        : undefined,
    // chatId: node.chatId,
    chatId: undefined,
    // chat: 'chat' in node ? node.chat && chatFormatter(node.chat) : undefined,
    chat: undefined,
    updatedAt: formatTime(node.updatedAt),
    createdAt: formatTime(node.createdAt),
  }
}

export const topicListFormatter = (
  topics: FullDb['Topic'][] | Api['Topic'][],
) => {
  return formatList(topicFormatter, topics)
}

export function formatTime(
  dateTime: number | string | Date | undefined | null,
) {
  return typeof dateTime === 'number'
    ? dateTime
    : dateTime
    ? new Date(dateTime)?.getTime()
    : undefined
}

function formatNonNullTime(dateTime: number | string | Date) {
  return typeof dateTime === 'number' ? dateTime : new Date(dateTime).getTime()
}

const formatProfilePhoto = (profilePhoto: string | undefined | null) => {
  if (profilePhoto?.includes('https://')) {
    return profilePhoto
  }
  if (profilePhoto && profilePhoto !== '') {
    const awsProfileUrl = `${BucketEndpoint}/${profilePhoto}`

    return awsProfileUrl
  }

  return profilePhoto
}

const formatDialogBackground = (bgObjectId: string | undefined | null) => {
  if (
    bgObjectId?.includes('https://') ||
    bgObjectId?.includes('http://localhost:7000')
  ) {
    return bgObjectId
  }
  if (bgObjectId && bgObjectId !== '') {
    const awsProfileUrl = `${BucketEndpoint}/${bgObjectId}`

    return awsProfileUrl
  }

  return bgObjectId
}

export const notificationFormatter = (
  node: FullDb['Notification'],
): Api['Notification'] => {
  if (!node) return node

  let senderName = node.sender?.name || node.sender?.name
  let senderProfile = node.sender?.profilePhoto

  return {
    id: toGlobalId('Notification', node.id),
    senderId: node.senderId ? toGlobalId('User', node.senderId) : undefined,
    receiverId: node.receiverId
      ? toGlobalId('User', node.receiverId)
      : undefined,
    type: node.type,
    dismissed: node.dismissed,
    spaceId: node.spaceId ? toGlobalId('Space', node.spaceId) : undefined,
    updatedAt: formatTime(node.updatedAt),
    createdAt: formatTime(node.createdAt),
    expireAt: formatTime(node.expireAt),
    sentAt: formatTime(node.sentAt),
    user:
      senderName && senderProfile
        ? {
            name: senderName,
            profilePhoto: formatProfilePhoto(senderProfile) || undefined,
          }
        : undefined,
  }
}

export const notificationListFormatter = (
  notifications: Api['Notification'][] | FullDb['Notification'][],
) => {
  return formatList(notificationFormatter, notifications)
}

export const presenceFormatter = (
  node: FullDb['Presence'] | Api['Presence'],
): Api['Presence'] => {
  if (!node) return node

  return {
    id: toGlobalId('Presence', node.id),
    userId: toGlobalId('User', node.userId),
    working: node.working,
    workStartedAt: formatTime(node.workStartedAt),
    workEndedAt: formatTime(node.workEndedAt),
    updatedAt: formatTime(node.updatedAt),
    createdAt: formatTime(node.createdAt),
  }
}

export const presenceListFormatter = (
  presences: Api['Presence'][] | FullDb['Presence'][],
) => {
  return formatList(presenceFormatter, presences)
}

export const newChatFormatter = (node: NewChat): Api['NewChat'] => {
  if (!node) return node

  return {
    id: toGlobalId('NewChat', node.id),
    userId: toGlobalId('User', node.userId),
    peerTopicId: node.peerTopicId
      ? toGlobalId('Topic', node.peerTopicId)
      : undefined,
    peerUserId: node.peerUserId
      ? toGlobalId('User', node.peerUserId)
      : undefined,
    pinned: node.pinned,
    unreadCount: node.unreadCount,
    maxReadInboxMsgId: node.maxReadInboxMsgId,
    topMessageId: node.topMessageId || undefined,
    updatedAt: formatTime(node.updatedAt),
    lastSeenMessageId: node.lastSeenMessageId || undefined,
    muted: typeof node.muted === 'boolean' ? node.muted : false,
    hide: typeof node.hide === 'boolean' ? node.hide : undefined,
    lastReadOutboxMsgId: node.lastReadOutboxMsgId || undefined,
    lastReadOutboxDate: formatTime(node.lastReadOutboxDate) || undefined,
  }
}

export const documentFormatter = (
  node: FullDb['Document'] | Api['Document'],
): Api['Document'] => {
  if (!node) return node
  return {
    id: toGlobalId('Document', node.id),
    type: node.type,
    fileExt: node.fileExt,
    fileName: node.fileName,
    size: node.size,
    mediaW: node.mediaW,
    mediaH: node.mediaH,
    objectId: node.objectId,
    thumbObjectId: node.thumbObjectId,
    compressed: node.compressed,
    createdAt: formatTime(node.createdAt),
    remoteUrl: node.remoteUrl,
    remotePreview: node.remotePreview,
  }
}

export const newMessageFormatter = (
  node: FullDb['NewMessage'] | Api['NewMessage'],
): Api['NewMessage'] => {
  if (!node) return node
  let documentId = node.documentId || node.document?.id

  return {
    id: node.id,
    senderId: toGlobalId('User', node.senderId),
    peerTopicId: node.peerTopicId
      ? toGlobalId('Topic', node.peerTopicId)
      : undefined,
    peerUserId: node.peerUserId
      ? toGlobalId('User', node.peerUserId)
      : undefined,
    edited: node.edited,
    text: node.text,
    sentAt: formatNonNullTime(node.sentAt),
    replyToMessageId: node.replyToMessageId,

    mediaType: node.mediaType,
    mediaObjectId: node.mediaObjectId,
    thumbObjectId: node.thumbObjectId,
    mediaW: node.mediaW || undefined,
    mediaH: node.mediaH || undefined,
    documentId: documentId ? toGlobalId('Document', documentId) : undefined,
    document: node.document ? documentFormatter(node.document) : undefined,
  }
}

export const reactionFormatter = (
  node: FullDb['Reaction'] | Api['Reaction'],
): Api['Reaction'] => {
  if (!node) return node

  return {
    emoji: node.emoji,
    statusId: toGlobalId('Status', node.statusId),
    userId: toGlobalId('User', node.userId),
  }
}

export const reactionListFormatter = (
  reactions: Api['Reaction'][] | FullDb['Reaction'][],
) => {
  return formatList(reactionFormatter, reactions)
}

export const statusFormatter = (
  node: FullDb['Status'] | Api['Status'],
): Api['Status'] => {
  if (!node) return node

  return {
    id: toGlobalId('Status', node.id),
    userId: toGlobalId('User', node.userId),
    text: node.text,
    createdAt: formatNonNullTime(node.createdAt),
    updatedAt: formatNonNullTime(node.updatedAt),
    document: node.document ? documentFormatter(node.document) : undefined,
    reactions: node.reactions
      ? reactionListFormatter(node.reactions)
      : undefined,
    topic: node.topic ? topicFormatter(node.topic) : undefined,
    replyToStatusId: node.replyToStatusId
      ? toGlobalId('Status', node.replyToStatusId)
      : undefined,
  }
}

export const userSpaceStatusesFormatter = (
  node: UserSpaceStatuses | Api['UserSpaceStatuses'],
): Api['UserSpaceStatuses'] => {
  if (!node) return node

  return {
    id: node.id,
    userId: toGlobalId('User', node.userId),
    spaceId: toGlobalId('Space', node.spaceId),
    lastSeenStatusId: node.lastSeenStatusId || null,
    lastSeenStatusTime: formatTime(node.lastSeenStatusTime) || null,
  }
}
