import { useEffect, useCallback, useState, useRef } from 'react'
import { ipc } from '@there/desktop/utils/electron-api'
import { PartialUser } from '@there/components/urql/fragments/userInfo'
import Sentry from '@there/app/utils/sentry'
import { safeRequestIdleCallback } from '@there/components/utils/schedulers'

export type EventsType =
  | 'User Opened Feed'
  | 'User Logged Out'
  | 'User Logged In'
  | 'User Logged In With Email'
  | 'User Login Attempt With Email'
  | 'User Opened Invite Modal'
  | 'User Invited Friend By Email'
  | 'User Copied Invite Link'
  | 'User Created a Space'
  | 'User Changed a SpaceName'
  | 'User Switched Space'
  | 'Desktop App Updated'
  | 'User Clicked Help'
  | 'User Clicked Guides'
  | 'User Changed Mic Shortcut'
  | 'User Changed Mic'
  | 'User Changed Speaker'
  | 'User Changed Camera'
  | 'User Toggeled Mic - Mic click'
  | 'User Toggeled Mic - Shortcut'
  | 'User Muted Speaker'
  | 'User Nudged'
  | 'User Created a Room'
  | 'User Deleted a Room'
  | 'User Joined a Room'
  | 'User Left a Room'
  | 'User Joined a Pv'
  | 'User Invited Someone To ScreenShare'
  | 'User Toggled Voice - Shortcut'
  | 'User Toggled Screen Share'
  | 'User Clicked Invite'
  | 'User Clicked Reload'
  | 'User Toggled Camera'
  | 'Feed Guide Dismissed'
  | 'Feed Guide Opened'
  | 'Quick Message Sent'
  | 'Chat Message Sent'
  | 'Global Typing Enabled'
  | 'Global Typing Disabled'
  | 'User Left a Space'
  | 'User Clicked New Group Button'
  | 'Manual Update Banner Clicked'
  | 'User Toggled Remote Privilege'
  | 'User Toggled Focus'
  | 'User Toggled Share System Audio'
  | 'User Opened Statuses'
  | 'User Posted Status'
  | 'User Reacted to Status'
  | 'User Opens a Status'

let amplitude: typeof import('amplitude-js') | undefined
if (typeof window !== 'undefined') {
  amplitude = require('amplitude-js').default
}

const AMPLITUDE_DEV_PROJECT = 'f4bc94268cb647bc61cac8102a7f742a'
const AMPLITUDE_PROD_PROJECT = 'd2e34b9254dda6b0164c3bab16ceef10'
const AMPLITUDE_ID =
  process.env.NODE_ENV === 'production'
    ? AMPLITUDE_PROD_PROJECT
    : AMPLITUDE_DEV_PROJECT

export const logEvent = (event: EventsType, data?: any) => {
  return new Promise<void>((resolve) => {
    if (amplitude && event) {
      amplitude.getInstance().logEvent(event, data, () => {
        resolve()
      })
    }
  })
}

let appVersion: string | undefined = undefined

const identify = async (user: PartialUser) => {
  setTimeout(() => {
    safeRequestIdleCallback(async () => {
      if (!amplitude) {
        return Promise.reject()
      }

      const properties: any = {}
      if (user.id) {
        properties.id = user.id
      }

      if (user.email) {
        properties.email = user.email
      }

      if (user.name) {
        properties.name = user.name
      }

      if (!appVersion) {
        appVersion = ipc
          ? await ipc.invoke('there:app-version')
          : process.env.version
      }

      if (appVersion) {
        properties.appVersion = appVersion

        amplitude.getInstance().setVersionName(appVersion)
      }

      amplitude.getInstance().setUserProperties(properties)

      if (user.id) {
        Sentry.setUser({ email: user.email || undefined, id: user.id })
        Sentry.setTag('appVersion', process.env.version)
      }
    })

    // Delay because it's blocking
  }, 200)
}

export const useAnalytics = (input?: { user: PartialUser | undefined }) => {
  let initiated = useRef(false)

  useEffect(() => {
    if (initiated.current) return

    if (amplitude && input?.user) {
      amplitude
        .getInstance()
        .init(AMPLITUDE_ID, input.user.id, undefined, () => {
          console.info('amplitude init done')
          initiated.current = true
        })
    }
  }, [input])

  return {
    logEvent,

    identify,
  }
}
