import { useTheme } from 'components/feed/ThemeContext'
import { logEvent } from '../shared/use-analytics'
import { PinControlButton } from './PinControlButton'
import { MicrophoneIcon } from '@there/components/shared/MicrophoneIcon'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { CameraIcon } from '@there/components/shared/CameraIcon'
import { ClosedDoorIcon, LeaveIcon } from '@there/components/main/mainIcons'
import { ipc } from '@there/desktop/utils/electron-api'

export const PinLeaveButton = () => {
  const theme = useTheme()

  return (
    <PinControlButton
      onPress={() => {
        ipc?.invoke('dialog:leave')
      }}
      style={{ backgroundColor: theme.colors.pinButtonBackground }}
    >
      <ClosedDoorIcon color="#FF1384" />
    </PinControlButton>
  )
}
