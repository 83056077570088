import useInterval from '@use-it/interval'
import ms from 'ms'
import { useState } from 'react'
export const useRerenderEvery = (msValue: string | null) => {
  let [time, setTime] = useState(0)

  useInterval(
    () => {
      console.info('rerender')
      setTime(performance.now())
    },
    msValue ? ms(msValue) : null,
  )
}
