import { UserWithAvatarInfo } from '@there/components/urql/fragments/avatarInfo'
import {
  SpaceInfo,
  spaceInfoFragment,
} from '@there/components/urql/fragments/spaceInfo'
import { NexusGenFieldTypes } from '@there/tower/generated/graphql'
import gql from 'graphql-tag'
import { UserInfo, userInfoFragment } from './userInfo'

export const memberInfoFragment = gql`
  fragment memberInfo on Member {
    id
    invitedCode
    inviteAccepted
    createdAt
    user {
      ...userInfo
    }
  }
  ${userInfoFragment}
`

export const memberOnlyInfoFragment = gql`
  fragment memberOnlyInfo on Member {
    id
    invitedCode
    inviteAccepted
    createdAt
    role
  }
`

type SourceMember = NexusGenFieldTypes['Member']

export interface MemberInfo {
  id: SourceMember['id']
  invitedCode: SourceMember['invitedCode']
  inviteAccepted: SourceMember['inviteAccepted']
  createdAt: SourceMember['createdAt']
  role: SourceMember['role']
  __typename?: 'Member'
}

export interface MemberWithUserInfo extends MemberInfo {
  // we should not have null, but here we are in code I keep getting to places where we hit errors
  user: UserInfo | null
}

export interface MemberWithUserWithAvatarInfo extends MemberInfo {
  user: UserWithAvatarInfo
}

export interface MemberWithSpaceInfo extends MemberInfo {
  space: SpaceInfo
}
