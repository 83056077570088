import { useImmerReducer } from 'use-immer'
import { Draft } from 'immer'
import { useUniversalReducer } from '@there/components/shared/use-universal-reducer'

export type State = {
  callId: string | undefined
  weSharing: boolean
  ourObservers: Set<string>
  createdInviteAt: Record<string, Date>
  hostUserId?: string
}

export const initialState: State = {
  callId: undefined,
  weSharing: false,
  ourObservers: new Set(),
  createdInviteAt: {},
  hostUserId: undefined,
}

type ScreenShareInviteAction =
  | {
      type: 'call ended'
    }
  | {
      type: 'invite added'
      userId: string
      callId?: string
    }
  | {
      type: 'invite cleared'
      userId?: string
    }
  | {
      type: 'invite accepted'
      callId: string
      hostUserId: string
    }

export function reducer(
  draft: Draft<State>,
  action: ScreenShareInviteAction,
): void | State {
  switch (action.type) {
    case 'call ended': {
      // remove callId and observers
      draft.callId = undefined
      draft.hostUserId = undefined
      draft.ourObservers = new Set()
      draft.weSharing = false

      return
    }
    case 'invite added': {
      // add screenShare's callId
      if (!draft.callId && action.callId) {
        draft.callId = action.callId
      }

      // add invitee to potentially observers
      draft.ourObservers.add(action.userId)
      draft.createdInviteAt[action.userId] = new Date()

      // set screen state
      draft.weSharing = true

      return
    }
    case 'invite cleared': {
      draft.hostUserId = undefined
      if (action.userId) {
        draft.ourObservers.delete(action.userId)
        delete draft.createdInviteAt[action.userId]
      }

      return
    }
    case 'invite accepted': {
      draft.callId = action.callId
      draft.hostUserId = action.hostUserId
      draft.weSharing = false

      return
    }

    default:
      return
  }
}

export const useScreenShareInviteManager = (): [
  State,
  React.Dispatch<ScreenShareInviteAction>,
] => {
  let [reducerTuple] = useUniversalReducer(
    useImmerReducer<State, ScreenShareInviteAction>(reducer, initialState),
    {
      key: 'screenShare-invite-manager',
    },
  )

  return reducerTuple
}
