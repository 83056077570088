import { View } from 'react-native'
import React from 'react'
import { TopBar } from './NewTopBar'

/** Adds necessary views for showing a top bar, must wrap your whole app UI */
export const WithNewTopBar = ({
  children,
  skeleton,
}: {
  children: React.ReactNode
  skeleton?: boolean
}) => {
  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100vh',

        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TopBar skeleton={skeleton} />
      <View
        style={{
          width: '100%',
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        {children}
      </View>
    </View>
  )
}
