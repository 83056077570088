import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export function DeleteIcon(props: SvgProps) {
  return (
    <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.03 3v1h-.378A14.3 14.3 0 004.4 5l-.086.034A.5.5 0 004 5.5c0 .276.22.5.493.5h.497l.001.059.78 14.058C5.828 21.175 6.653 22 7.65 22h8.697c.998 0 1.823-.825 1.881-1.883l.78-14.058L19.01 6h.497a.502.502 0 00.178-.966L19.6 5c-1.675-.66-3.455-1-5.252-1h-.378V3c0-.552-.44-1-.985-1h-1.97a.993.993 0 00-.985 1zm.556 6c0-.552-.421-1-.942-1-.52 0-.942.448-.942 1v9c0 .552.422 1 .942 1s.942-.448.942-1V9zm4.712 0c0-.552-.422-1-.942-1s-.943.448-.943 1v9c0 .552.422 1 .943 1 .52 0 .942-.448.942-1V9z"
        fill={props.color ? props.color : '#000'}
      />
    </Svg>
  )
}

export function RoomBgIcon(props: SvgProps) {
  return (
    <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        d="M17 2H4a1 1 0 00-1 1v3c0 .6.4 1 1 1h13c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1zm-5 10h-2a1 1 0 00-1 1v8c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-8c0-.6-.4-1-1-1z"
        fill={props.color ? props.color : '#000'}
      />
      <Path
        opacity={0.6}
        d="M12 12h-2v-1c0-1.1.9-2 2-2h7V5.5h-1v-2h1a2 2 0 012 2V9a2 2 0 01-2 2h-7v1z"
        fill={props.color ? props.color : '#000'}
      />
    </Svg>
  )
}

export function RemoveIcon(props: SvgProps) {
  return (
    <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        opacity={0.3}
        d="M12 22a10 10 0 100-20 10 10 0 000 20z"
        fill={props.color ? props.color : '#000'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.04 10.62l2.82-2.83a1 1 0 111.42 1.42l-2.83 2.83 2.83 2.82a1 1 0 01-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 01-1.42-1.42l2.83-2.82L7.8 9.2a1 1 0 011.42-1.42l2.83 2.83z"
        fill={props.color ? props.color : '#000'}
      />
    </Svg>
  )
}

export function RenameIcon(props: SvgProps) {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        opacity="0.3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 19H5V21H3V19ZM8 19H10V21H8V19ZM13 19H15V21H13V19ZM18 19H20V21H18V19Z"
        fill={props.color ? props.color : '#000'}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.504 3.25598H12.466L17.956 16H15.364L14.176 13.084H8.65L7.498 16H4.96L10.504 3.25598ZM13.384 11.14L11.422 5.95598L9.424 11.14H13.384Z"
        fill={props.color ? props.color : '#000'}
      />
    </Svg>
  )
}

export function Door(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path opacity={0.3} d="M7 4.895V21H5V3h6v1.895H7z" fill="#fff" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.178 2.247l8 1.45a1 1 0 01.822.984v14.638a1 1 0 01-.822.984l-8 1.45A1 1 0 019 20.769V3.23a1 1 0 011.178-.984zm1.739 10.659c.69 0 1.25-.609 1.25-1.359s-.56-1.359-1.25-1.359-1.25.608-1.25 1.359c0 .75.56 1.359 1.25 1.359z"
        fill="#fff"
      />
    </Svg>
  )
}
