/**
 * ☢️ Universal
 *
 */
import { formatUpdates } from '@there/sun/utils/formatters/formatUpdates'
import { DialogInfo, NodeUpdateInfo } from '@there/sun/utils/node-types'
import { GlobalId } from '@there/tower/utils/global-id'

interface CacheData {
  avatarId: string
  dialog: DialogInfo
  oldDialog: DialogInfo | undefined | null
}

interface Input {
  spaceId: string
  dialogId: string
  joinedAt: number
  // Only if it's a new session
  newSessionId?: string
  newChatId?: string
}

export const getJoinDialogUpdates = (
  input: Input,
  cacheData: CacheData,
): NodeUpdateInfo[] => {
  let dialog = cacheData.dialog
  let avatarId = cacheData.avatarId

  // Cleanup, important
  delete dialog.avatars

  // Create optimistic
  let newDialog: NodeUpdateInfo = {
    mutation: 'Update',
    type: 'Dialog',
    node: {
      ...dialog,
      // make sure to not override it if it's not set...
      ...(input.newSessionId
        ? { sessionId: input.newSessionId || dialog.sessionId }
        : null),
      // ...(input.newChatId
      //   ? { chatId: input.newChatId || dialog.chatId }
      // : null),
      ...(input.newSessionId ? { doorClosed: false } : null),
    },
  }

  let updatedAvatar: NodeUpdateInfo = {
    mutation: 'Update',
    type: 'Avatar',
    node: {
      id: avatarId,
      joinedAt: input.joinedAt || Date.now(), //-
      // randomize to prevent equal
      // TODO: Use client side join time
      // 500 +
      // ((Math.random() * 1000) | 0),
      dialogId: (input.dialogId as unknown) as GlobalId,
    },
  }

  let updates = [newDialog, updatedAvatar]

  return formatUpdates(updates)
}
