import { Pressable } from '../shared/Pressable'
import { CrossIcon } from '../main/mainIcons'
import { StyleSheet, Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { MessageInfo, NewMessageInfo } from '@there/sun/utils/node-types'
import { Dispatch, SetStateAction } from 'react'
import { a, useTransition } from '@react-spring/native'
import { rgba } from '@react-spring/shared'
import { useUiContext } from '../shared/UiContext'

type Props = {
  editingMessage: NewMessageInfo | undefined
  setEditingMessageId: (msgId: string | null) => void
}

export const ChatEdit = ({ editingMessage, setEditingMessageId }: Props) => {
  let theme = useTheme()
  let { hasCustomBackground } = useUiContext()

  return (
    <>
      <View
        style={[
          {
            // position: 'absolute',
            // left: 0,
            // top: -40,
            // width: '100%',
            height: 40,
            zIndex: 2,

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 10,
            backgroundColor: hasCustomBackground
              ? undefined
              : theme.colors.background,
            borderTopColor: editingMessage
              ? theme.colors.quinaryLight
              : 'rgba(0, 0, 0, 0)',
            borderTopWidth: 1,
          },
          hasCustomBackground && styles.blur,
        ]}
      >
        <View style={{ flex: 1, marginRight: 10 }}>
          <Text
            style={{
              fontSize: theme.fontSizes.tiny,
              fontWeight: 'bold',
              color: theme.colors.quinaryText,
            }}
          >
            Edit Message
          </Text>
          <Text
            numberOfLines={1}
            style={{
              fontSize: theme.fontSizes.small,
              color: theme.colors.tertiaryText,
              lineHeight: 14,
              height: 14,
              overflow: 'hidden',
            }}
          >
            {editingMessage?.text}
          </Text>
        </View>
        <Pressable
          onPress={() => {
            setEditingMessageId(null)
          }}
          style={({ hovered, pressed }) => [
            {
              width: 18,
              height: 18,
              borderRadius: 18,

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 2,

              borderWidth: 1,
              borderColor: theme.colors.quinaryText,
            },
            hovered && {
              backgroundColor: 'rgba(255, 2555, 255, 0.05)',
            },
            pressed && {
              opacity: 0.8,
            },
          ]}
        >
          <CrossIcon color={theme.colors.tertiaryText} />
        </Pressable>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  blur: {
    //@ts-ignore
    backdropFilter: `blur(5px)`,
  },
})
