import React, { useCallback } from 'react'
import { TouchableOpacity } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { UserInfo } from '@there/sun/utils/node-types'
import { ScreenShareIcon } from '@there/components/shared/ScreenShareIcon'
import { useHover } from '@there/components/shared/use-hover'
import { lighten } from 'polished'

let size = 23
export const PersonSharingIndicator = ({
  user,
  isUs,
  onPress,
}: {
  user: UserInfo
  isUs: boolean | undefined
  onPress: (() => void) | undefined
}) => {
  const theme = useTheme()
  let [hovered, hoverListeners] = useHover()

  let disabled = !onPress
  const _onPress = useCallback(() => {
    if (onPress) {
      onPress()
    }
  }, [onPress])

  return (
    <TouchableOpacity
      onPress={_onPress}
      activeOpacity={0.85}
      disabled={isUs || disabled}
      style={[
        {
          width: size,
          height: size,
          borderRadius: size,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.colors.background,
          shadowOffset: { height: 2, width: 0 },
          shadowOpacity: 0.6,
          shadowColor: theme.colors.screenShareButtonIcon,
          shadowRadius: 8,
          elevation: 4,
        },
        hovered && {
          backgroundColor: lighten(0.04, theme.colors.background),
        },
      ]}
      {...hoverListeners}
    >
      <ScreenShareIcon color={theme.colors.screenShareButtonIcon} width={15} />
    </TouchableOpacity>
  )
}
