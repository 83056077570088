import React, { useEffect } from 'react'
import {
  Section,
  SectionHeader,
  Row,
  Label,
  Separator,
  DropdownButtonWrapper,
  DropdownLabel,
  DropdownIcon,
  Check,
} from './SettingsUI'
import { Space } from '../shared/Space'
import { useSelect } from 'downshift'
import { Dropdown } from '../feed/Dropdown'
import { ipc } from '@there/desktop/utils/electron-api'
import {
  useShortcutManager,
  ShortcutItemType,
  toReadableShortcut,
} from '../shared/use-shortcut-manager'
import { logEvent } from '../shared/use-analytics'

export const ShortcutsSection = () => {
  let {
    shortcutPicked,
    state: { keymap },
    defaultComboOptions,
  } = useShortcutManager()

  return (
    <Section>
      <SectionHeader>Shortcuts</SectionHeader>
      <Space vertical={8} />

      <ShortcutPickerRow
        label="Show / Hide window"
        selected={keymap['toggleWindowVisible']}
        onChange={(selected) => {
          shortcutPicked('toggleWindowVisible', selected)
          // logEvent('User Changed Mic Shortcut')
        }}
        items={defaultComboOptions}
      />
    </Section>
  )
}

export function useSyncKeymapOnStartup() {
  useEffect(() => {
    let raw = localStorage.getItem('keyMap')
    if (raw) {
      try {
        let keymap = JSON.parse(raw)
        ipc?.invoke('there:keymap-updated', keymap)
      } catch (error) {}
    }
  }, [])
}

const ShortcutPickerRow = ({
  label,
  selected,
  items,
  onChange,
}: {
  label: string
  selected: string | null | undefined
  items: ShortcutItemType[]
  onChange: (selected: string | null | undefined) => void
}) => {
  const {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    toggleMenu,
    closeMenu,
  } = useSelect({
    items,
    defaultSelectedItem: selected,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem)
    },
  })

  return (
    <Row>
      <Label>{label}</Label>
      <Dropdown.Root>
        <Dropdown.Toggle>
          <DropdownButtonWrapper width={140} {...getToggleButtonProps()}>
            <DropdownLabel>
              {selected ? toReadableShortcut(selected) : 'None'}
            </DropdownLabel>
            <DropdownIcon>
              <svg width={8} height={4} fill="none" viewBox="0 0 8 4">
                <path d="M4 4L0 0h8L4 4z" fill="#B3B3B3" />
              </svg>
            </DropdownIcon>
          </DropdownButtonWrapper>
        </Dropdown.Toggle>

        <Dropdown.Menu
          {...getMenuProps(undefined, { suppressRefError: true })}
          isOpen={isOpen}
          onClose={closeMenu}
          width={160}
        >
          <Dropdown.Section>
            <Space vertical={4} />
            {items.map((item, index) => {
              return (
                <React.Fragment key={String(item) + index}>
                  <Dropdown.Item
                    {...getItemProps({
                      item,
                      index,
                      title: item || 'None selected',
                    })}
                    selected={selected === item}
                    highlighted={highlightedIndex === index}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item ? toReadableShortcut(item) : 'None'}
                      </div>
                      {selected === item && <Check />}
                    </div>
                  </Dropdown.Item>
                  <Space vertical={1} />
                </React.Fragment>
              )
            })}
            <Space vertical={3} />
          </Dropdown.Section>
        </Dropdown.Menu>
      </Dropdown.Root>
    </Row>
  )
}
