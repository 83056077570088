import { useNurClient } from '@there/components/sun/context'
import { NurResult, Variables } from '@there/components/sun/utils/types'
import { useCallback, useEffect, useRef, useState } from 'react'

interface MutationInput {
  method: string
  /** will be merged with the variables from mutate */
  variables?: Record<string, any>
}

export const useNurMutation = <Data = any, V = Variables>(
  input: MutationInput,
): [NurResult<Data>, (variables: V) => Promise<Data>] => {
  let client = useNurClient()
  let [result, setResult] = useState<NurResult>({
    fetching: false,
    // @ts-ignore
    id: '',
    stale: false,
    data: null,
    error: null,
  })

  let unsubscribeFunction = useRef(() => {})

  const execute = useCallback(
    (variables: Variables): Promise<Data> => {
      return new Promise((resolve, reject) => {
        if (!client) {
          console.warn('Called mutation too fast', input.method)
          return
        }
        let op = client.mutate(
          {
            method: input.method,
            variables: { ...variables, ...input.variables },
          },
          {
            next: (result) => {
              setResult(result)
              if (result.stale) return
              if (result.error) {
                reject(result.error)
              } else {
                resolve(result.data)
              }
            },
            complete: () => {},
          },
        )

        unsubscribeFunction.current = op.unsubscribe
      })
    },
    [client, input.method, input.variables],
  )

  useEffect(() => {
    return () => {
      // unsubscribe when unmounted
      unsubscribeFunction.current()
    }
  }, [])

  return [result, execute]
}
