import { useFileUploadManager } from './useNoorayUploadManager'

export const WithNoorayUploadManager = ({
  children,
}: {
  children: React.ReactNode
}) => {
  useFileUploadManager()
  return <>{children}</>
}
