export default {
  sentryDsn: 'https://9af296e18c5a42c3815115227b4fe3cc@sentry.io/1767898',
  changelogUrl:
    'https://usenoor.com/docs/Whats-New-77cb4376b2a348e0b263bd1d9c8676da',
  protocol: 'therex',
  appId:
    process.env.BUILD_QUALITY === 'nightly'
      ? 'so.there.app.nightly'
      : 'team.there.x',
  /** in seconds */
  idleUserThreshold: 100,
  defaultShortcutOption: 4,
  shortcutOptions: ['L', 'L', 'K', 'J', /*Option+*/ 'L'],
}
