import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { View } from 'react-native'

import { usePicker, ConfigType, AllEmojis, EmojiCategory } from './use-picker'
import { PopoversPortal } from 'components/shared/ClientOnlyPortal'
import { headerHeight } from '../feed/Header'
import { useSpring, animated } from '@react-spring/native'
import { PickerManager } from '../shared/PickerContext'
import { PickerContent } from './PickerContent'
import { useAppContext } from '../shared/AppContext'
import { useHover } from '../shared/use-hover'
import { unCompressEmoji } from './utils'

const AnimatedView = animated(View)

export const Picker = memo(
  ({ pickerManager }: { pickerManager: PickerManager }) => {
    let {
      isOpen,
      close,
      position,
      isFullScreen,
      onEmojiPress,
      setPickerContentHovered,
      pickerKey,
      activeModes,
      initialMode,
      onGifPress,
    } = pickerManager
    let [emojiPickerHovered, emojiPickerHoverListener] = useHover()

    const { config, emojiRawData } = usePicker()

    let [emojis, categories] = useMemo(() => {
      let emojis: AllEmojis | undefined
      let categories: EmojiCategory[] | undefined
      if (emojiRawData) {
        let emojiData = unCompressEmoji(emojiRawData)
        categories = emojiData.categories
        emojis = emojiData.emojis as AllEmojis
      }
      return [emojis, categories]
    }, [emojiRawData])

    let {
      appState: { recentUsedEmojis },
    } = useAppContext()
    let onClose = useCallback(() => {
      close()
    }, [close])

    useEffect(() => {
      setPickerContentHovered(emojiPickerHovered)
    }, [emojiPickerHovered, setPickerContentHovered])

    let shouldRender = isOpen && position
    let styleProps = useSpring({
      displayStatus: shouldRender ? 1 : 0,
      delay: 50,
      opacity: shouldRender ? 1 : 0,
      config: {
        mass: 0.5,
        tension: 400,
        friction: 18,
      },
    })

    return (
      <PopoversPortal
        isOpen={isOpen}
        onClose={onClose}
        backMode="transparent"
        back={pickerKey !== 'chat'}
        unMount={false}
        inside={
          <AnimatedView
            //@ts-ignore
            style={{
              ...styleProps,
              position: 'absolute',
              top: isFullScreen ? headerHeight : position?.top,
              left: isFullScreen ? 0 : position?.left,
              right: isFullScreen ? 0 : position?.right,
              bottom: isFullScreen ? 0 : position?.left,
              width: isFullScreen ? '100vw' : undefined,
              alignItems: 'center',
              zIndex: styleProps.displayStatus.interpolate((status) =>
                status === 0 ? 0 : 30,
              ),
              display: styleProps.displayStatus.interpolate((status) =>
                status === 0 ? 'none' : 'flex',
              ),
              //@ts-ignore
              pointerEvents: 'none',
            }}
            {...emojiPickerHoverListener}
          >
            <PickerContent
              config={config}
              onEmojiPress={onEmojiPress}
              onGifPress={onGifPress}
              isOpen={isOpen}
              recentUsedEmojis={recentUsedEmojis}
              emojis={emojis}
              categories={categories}
              activeModes={activeModes}
              initialMode={initialMode}
              pickerKey={pickerKey}
            />
          </AnimatedView>
        }
      />
    )
  },
)
