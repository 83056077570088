import { DocumentInfo } from '@there/sun/utils/node-types'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { ActivityIndicator, Image } from 'react-native'
import { getFileFullPath, getMediaThumbDataUri } from '../message/helpers'
import { Pressable } from '../shared/Pressable'
import { PhotoDimensions } from '../v2/ChatUploadButton'
import { useSpring, a } from '@react-spring/native'
import useIsMounted from '@there/components/hooks/useIsMounted'
import useCanvasBlur from '../lib/useCanvasBlur'

export const PhotoStatus = memo(
  ({
    media,
    maxMediaWidth,
    onPress,
  }: {
    media: DocumentInfo
    maxMediaWidth: number
    onPress: () => void
  }) => {
    const thumbnailUrl = getMediaThumbDataUri(media)
    const thumbRef = useCanvasBlur(thumbnailUrl, !Boolean(thumbnailUrl), true)
    let isMounted = useIsMounted()
    const hasLoaded = useRef(false)

    let imageDims = useMemo(() => {
      return media.mediaW && media.mediaH
        ? calculateImageDimensions(
            {
              w: media.mediaW,
              h: media.mediaH,
            },
            maxMediaWidth,
          )
        : { w: maxMediaWidth, h: maxMediaWidth }
    }, [maxMediaWidth, media.mediaH, media.mediaW])

    const [showMainSize, setShwMainSize] = useState(
      // Show immediately, unless after 40ms it didn't load, start the loading
      true,
    )

    useEffect(() => {
      let t = setTimeout(() => {
        if (!hasLoaded.current) {
          setShwMainSize(false)
        }
      }, 40)

      return () => {
        t && clearTimeout(t)
      }
    }, [])

    const thumbnailStyles = useSpring({
      opacity: showMainSize ? 0 : 1,

      config: {
        duration: 100,
      },
    })

    return (
      <Pressable
        style={{
          width: imageDims.w,
          height: imageDims.h,
          borderRadius: 6,
          overflow: 'hidden',
          marginTop: 5,
        }}
        onPress={onPress}
      >
        <Image
          onLoad={() => {
            if (!isMounted.current) return
            hasLoaded.current = true
            setShwMainSize(true)
          }}
          style={{
            width: imageDims.w,
            height: imageDims.h,
            borderRadius: 6,
            opacity: showMainSize ? 1 : 0,
            zIndex: 1,
            resizeMode: 'cover',
          }}
          source={{
            uri: getFileFullPath(media),
          }}
        />
        <a.View
          style={[
            thumbnailStyles,
            {
              position: 'absolute',
              top: 0,
              left: 0,
            },
          ]}
        >
          <canvas
            ref={thumbRef}
            className="thumbnail"
            style={{
              width: imageDims.w,
              height: imageDims.h,
            }}
          />
          <ActivityIndicator
            size={28}
            color="rgba(255,255,255,0.4)"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: [{ translateX: -14 }, { translateY: -14 }],
            }}
          />
        </a.View>
      </Pressable>
    )
  },
)

export const calculateImageDimensions = (
  dimensions: PhotoDimensions,
  maxDim: number,
): {
  w: number
  h: number
} => {
  let imageHeight: number = dimensions.h
  let imageWidth: number = dimensions.w
  if (dimensions.h >= dimensions.w) {
    imageHeight = maxDim
    imageWidth = (dimensions.w * maxDim) / dimensions.h
  } else {
    imageHeight = (dimensions.h * maxDim) / dimensions.w
    imageWidth = maxDim
  }

  return {
    w: imageWidth,
    h: imageHeight,
  }
}
