import { useNurMutation } from '@there/components/sun/use-mutation'
import type { EditDialogMessage } from '@there/sun/ws/message-types'
import type { EditDialogResult } from '@there/sun/modules/editDialog'

type Variables = EditDialogMessage['payload']
export interface LocalEditDialogVariables extends Variables {
  __extra: {
    spaceId: string
  }
}

export const useEditDialog = () => {
  return useNurMutation<EditDialogResult, LocalEditDialogVariables>({
    method: 'editDialog',
  })
}
