import { NoorLogo } from '../feed/NoorLogo'
import { RoboflowLogo, TetherlandLogo } from '../feed/SpaceLogoSvgs'
import { SvgProps } from 'react-native-svg'

export const spaceLogos: Record<string, React.ReactNode | undefined> = {
  'Space:ckkyg8tdc5136esfliyjzq9e1': (
    <NoorLogo color="rgba(255, 255, 255, 0.87)" width={14} />
  ),
  'Space:ckrjpdn2638152eyfjsgmghd55': <TetherlandLogo width={14} />,
  'Space:ckwcl48k4213728eylzhj539l4u': <RoboflowLogo width={14} />,
}

export const spaceLogosComponents: Record<
  string,
  ((props: SvgProps) => JSX.Element) | undefined
> = {
  'Space:ckkyg8tdc5136esfliyjzq9e1': ({ width }: SvgProps) => (
    <NoorLogo color="rgba(255, 255, 255, 0.87)" width={width} />
  ),
  'Space:ckrjpdn2638152eyfjsgmghd55': TetherlandLogo,
  'Space:ckwcl48k4213728eylzhj539l4u': RoboflowLogo,
}
