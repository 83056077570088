import { Text } from 'react-native'
import { useTheme } from '../feed/ThemeContext'

export const Title = ({ children }: { children: string }) => {
  let theme = useTheme()
  return (
    <Text
      style={{
        fontSize: theme.fontSizes.xLarge,
        fontWeight: 'bold',
        color: theme.colors.secondaryText,
      }}
    >
      {children}
    </Text>
  )
}
