import { useNurMutation } from '@there/components/sun/use-mutation'
import type { CreateChatTopicMessage } from '@there/sun/modules/createChatTopic'
interface Data {
  done: boolean
}

export const useCreateChatTopic = () => {
  return useNurMutation<Data, CreateChatTopicMessage['payload']>({
    method: 'createChatTopic',
  })
}
