import { useNurMutation } from '@there/components/sun/use-mutation'
import { EditChatMessage, EditChatResult } from '@there/sun/modules/editChat'

type Variables = EditChatMessage['payload']

export const useEditChat = () => {
  return useNurMutation<EditChatResult, Variables>({
    method: 'editChat',
  })
}
