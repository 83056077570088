import {
  electronApi,
  electronVersion,
  ipc,
  useListenToIpc,
} from '@there/desktop/utils/electron-api'
import { darken, lighten } from 'polished'
import { useCallback, useEffect, useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { DefaultTheme, useTheme } from 'styled-components'
import { useAppContext } from '../shared/AppContext'
import { Pressable } from '../shared/Pressable'
import { useServiceWorker } from '../shared/ServiceWorker'
import { useSpring, a, useTransition } from '@react-spring/native'
import { changeLog } from '@there/components/main/changeLog'
import { useHover } from '../shared/use-hover'
import { CrossButton } from '@there/components/main/CrossButton'

import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
import { useConfirm } from '../modal/use-confirm'

const debug = require('debug')('app:update')

const whatsNewNotionLink =
  'https://www.notion.so/there/What-s-New-77cb4376b2a348e0b263bd1d9c8676da'

export const UpdateView = () => {
  let {
    desktopVersion: storedDesktopVersion,
    appVersion: storedAppVersion,
    dispatch,
    hydrated,
  } = useAppContext()

  let { showSkipWaiting } = useServiceWorker()
  let isAppUpdateAvailable = showSkipWaiting

  let currentDesktopVersion = electronVersion
  let currentAppVersion = process.env.version

  let [isDesktopUpdateAvailable, setDesktopUpdateAvailable] = useState(false)
  useListenToIpc(
    'there:update-downloaded',
    useCallback(() => {
      setDesktopUpdateAvailable(true)
    }, []),
  )

  let [isAppChangelogAvailable, setAppChangeLogAvailable] = useState(false)
  let [isDesktopChangelogAvailable, setDesktopChangeLogAvailable] = useState(
    false,
  )

  // check for changelog available after app updates
  useEffect(() => {
    if (!hydrated) return
    if (!currentAppVersion) {
      debug('current app version is not available')
      return
    }

    // if it is first time to set version, don't show change logs
    if (!storedAppVersion) {
      debug(`first time app version updated to ${currentAppVersion}`)
      dispatch({ type: 'app version updated', newVersion: currentAppVersion })
      return
    }

    // when we have storedAppVersion but current version is different, it means app updated
    let isAppUpdated = storedAppVersion !== currentAppVersion

    if (isAppUpdated) {
      debug(`app updated from ${storedAppVersion} to ${currentAppVersion}`)
      if (changeLog.app[currentAppVersion]) {
        setAppChangeLogAvailable(true)
        debug(`app changelog available ${currentAppVersion}`)
      } else {
        dispatch({ type: 'app version updated', newVersion: currentAppVersion })
        debug(
          `all updated from ${storedAppVersion} to ${currentAppVersion}, and changelog is not available`,
        )
      }
    }
  }, [currentAppVersion, dispatch, hydrated, storedAppVersion])

  // check for changelog available after desktop updates
  useEffect(() => {
    if (!hydrated) return
    if (!currentDesktopVersion) {
      debug('current desktop version is not available')
      return
    }

    // if it is first time to set version, don't show change logs
    if (!storedDesktopVersion) {
      debug(`first time desktop version updated to ${currentDesktopVersion}`)
      dispatch({
        type: 'desktop version updated',
        newVersion: currentDesktopVersion,
      })
      return
    }

    // when we have storedAppVersion but current version is different, it means desktop updated
    let isDesktopUpdated = storedDesktopVersion !== currentDesktopVersion

    if (isDesktopUpdated) {
      if (changeLog.desktop[currentDesktopVersion]) {
        setDesktopChangeLogAvailable(true)
        debug(`desktop changelog available ${currentDesktopVersion}`)
      } else {
        dispatch({
          type: 'desktop version updated',
          newVersion: currentDesktopVersion,
        })
        debug(
          `desktop updated from ${storedDesktopVersion} to ${currentDesktopVersion}, and changelog is not available`,
        )
      }
    }
  }, [currentDesktopVersion, dispatch, hydrated, storedDesktopVersion])

  let isUpdateAvailable = isDesktopUpdateAvailable || isAppUpdateAvailable
  let isChangelogAvailable =
    isDesktopChangelogAvailable || isAppChangelogAvailable

  let onChangelogDismiss = useCallback(() => {
    setDesktopChangeLogAvailable(false)
    setAppChangeLogAvailable(false)

    if (currentDesktopVersion) {
      dispatch({
        type: 'desktop version updated',
        newVersion: currentDesktopVersion,
      })
      debug(
        `desktop updated from ${storedDesktopVersion} to ${currentDesktopVersion}`,
      )
    }

    if (currentAppVersion) {
      dispatch({
        type: 'app version updated',
        newVersion: currentAppVersion,
      })
      debug(`all updated from ${storedAppVersion} to ${currentAppVersion}`)
    }
  }, [
    currentAppVersion,
    currentDesktopVersion,
    dispatch,
    storedAppVersion,
    storedDesktopVersion,
  ])

  let transitions = useTransition(isUpdateAvailable || isChangelogAvailable, {
    from: {
      opacity: 0,
      y: 50,
    },
    enter: {
      opacity: 1,
      y: 0,
    },
    leave: {
      opacity: 0,
      y: 50,
    },
    config: {
      mass: 0.4,
      tension: 400,
      friction: 22,
    },
  })

  return transitions(({ opacity, y }, item) => {
    return item ? (
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          // @ts-ignore
          pointerEvents: 'none',
        }}
      >
        <a.View
          style={[
            {
              width: '100%',
              padding: 8,
              paddingBottom: 8,
              marginBottom: 41, // 41 is selfManager height
              paddingTop: 0,
              opacity,
              transform: [{ translateY: y }],

              // @ts-ignore
              pointerEvents: 'auto',
            },
          ]}
        >
          {isUpdateAvailable ? (
            <UpdateButton type={isDesktopUpdateAvailable ? 'desktop' : 'app'} />
          ) : isChangelogAvailable ? (
            <ChangelogButton
              type={isDesktopChangelogAvailable ? 'desktop' : 'app'}
              version={
                isDesktopChangelogAvailable
                  ? currentDesktopVersion
                  : currentAppVersion
              }
              onDismiss={onChangelogDismiss}
            />
          ) : null}
        </a.View>
      </View>
    ) : null
  })
}

const UpdateButton = ({ type }: { type: 'app' | 'desktop' }) => {
  let theme = useTheme()

  let { accepted } = useServiceWorker()

  let updateDesktop = useCallback(() => {
    ipc?.invoke('there:update')
    setTimeout(() => {
      ipc?.invoke('there:exit-now')
    }, 2500)
    setTimeout(() => {
      electronApi?.quitApp()
    }, 4000)
  }, [])

  let { openModal } = useConfirm({
    alertText: 'Update ready to use',
    alertDescription: 'Would you like to use it now?',
    alertIcon: '🎉',
    submitLabel: 'Reload',
    cancelLabel: 'Cancel',
  })

  let onPress = useCallback(() => {
    openModal()
      .then(() => {
        if (type === 'desktop') {
          updateDesktop()
        } else if (type === 'app') {
          accepted()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [accepted, openModal, type, updateDesktop])

  return (
    <ButtonWrapper background="updateBadgeBackground" onPress={onPress}>
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingHorizontal: 10,
        }}
      >
        <UpdateIcon color={theme.colors.secondaryText} width={14} />
        <Text
          style={{
            color: theme.colors.text,
            fontSize: theme.fontSizes.normal,
            marginLeft: 5,
            textAlign: 'center',
          }}
          numberOfLines={1}
        >
          A new version is available
        </Text>
      </View>
    </ButtonWrapper>
  )
}

const ChangelogButton = ({
  type,
  version = '',
  onDismiss = () => {},
}: {
  type: 'app' | 'desktop'
  version?: string
  onDismiss?: () => void
}) => {
  let theme = useTheme()
  let onPress = useCallback(() => {
    window.open(whatsNewNotionLink)
    // openLink(whatsNewNotionLink)
    onDismiss()
  }, [onDismiss])

  let [changelogDescription, setChangelogDescription] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    let changelog = changeLog[type][version]
    if (changeLog) {
      setChangelogDescription(changelog)
    }

    return () => {
      setChangelogDescription(undefined)
    }
  }, [type, version])

  return (
    <ButtonWrapper
      background="cardHovered"
      onPress={onPress}
      onDismiss={onDismiss}
    >
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            color: theme.colors.text,
            fontSize: theme.fontSizes.normal,
            lineHeight: theme.fontSizes.normal,
          }}
        >
          See what’s new 🎉
        </Text>
        {changelogDescription ? (
          <Text
            numberOfLines={1}
            style={{
              color: theme.colors.tertiaryText,
              fontSize: theme.fontSizes.small,
            }}
          >
            {changelogDescription}
          </Text>
        ) : null}
      </View>
    </ButtonWrapper>
  )
}

const ButtonWrapper = ({
  background = 'updateBadgeBackground',
  onPress,
  onDismiss,
  children,
}: {
  children: React.ReactNode
  background?: keyof DefaultTheme['colors']
  onPress: () => void
  onDismiss?: () => void
}) => {
  let theme = useTheme()
  let [hovered, hoverListener] = useHover()

  let crossStyleProps = useSpring({
    position: 'absolute',
    top: -4,
    left: -4,
    opacity: hovered ? 1 : 0,
    delay: hovered ? 300 : 0,
    config: {
      mass: 0.1,
      tension: 300,
      friction: 20,
    },
  })

  return (
    <Pressable
      style={({ hovered, pressed }) => [
        {
          position: 'relative',
          width: '100%',
          height: 42,
          backgroundColor: theme.colors[background],
          borderRadius: 8,

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        hovered && {
          backgroundColor: lighten(0.05, theme.colors[background]),
        },
        pressed && {
          backgroundColor: darken(0.03, theme.colors[background]),
        },
      ]}
      onPress={onPress}
      {...hoverListener}
    >
      {children}
      <a.View
        //@ts-ignore
        style={[!onDismiss && { display: 'none' }, crossStyleProps]}
      >
        <CrossButton onPress={onDismiss} />
      </a.View>
    </Pressable>
  )
}

let openLink = (link: string) => {
  if (electronApi) {
    electronApi?.openExternalUrl(link)
  } else {
    window.open(link, '__blank')
  }
}

const UpdateIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" viewBox="0 0 16 16" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.83 9.15H4.54A.52.52 0 0 1 4.18 9a.59.59 0 0 1-.04-.8l3.46-4a.52.52 0 0 1 .8 0l3.46 4.01c.09.1.14.24.14.38 0 .32-.24.57-.54.57H9.17v2.28c0 .32-.24.57-.54.57H7.37a.55.55 0 0 1-.54-.57V9.15Z"
      fill={props.color ? props.color : '#000'}
    />
  </Svg>
)
