import React, { useEffect, useState } from 'react'

export const DelayedRender = ({
  wait = 500,
  children,
}: {
  wait?: number
  children: React.ReactNode
}) => {
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    let id = setTimeout(() => {
      setIsShown(true)
    }, wait)

    return () => {
      clearTimeout(id)
    }
  }, [wait])

  return isShown ? <>{children}</> : null
}
