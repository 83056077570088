globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),

  get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }

};
import * as Sentry from '@sentry/nextjs';
import config from '@there/desktop/config';
var SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
Sentry.init({
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // tracesSampleRate: 1,
  enabled: true,
  dsn: config.sentryDsn,
  release: process.env.SENTRY_RELEASE // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

});