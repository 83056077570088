import { TOWER_URL } from '@there/components/config'
import { useAppContext } from '@there/components/shared/AppContext'
import { optimisticUpdates } from '@there/components/urql/optimisticUpdates'
import { makeLocalStorage } from '@there/components/urql/storage'
import { mutationUpdates } from '@there/components/urql/updates'
import schema from '@there/tower/generated/schema.json'
import { fromGlobalId } from '@there/tower/utils/global-id'
import { devtoolsExchange } from '@urql/devtools'
import { authExchange } from '@urql/exchange-auth'
import { offlineExchange } from '@urql/exchange-graphcache'
import { DefaultStorage } from '@urql/exchange-graphcache/default-storage'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'
import { retryExchange } from '@urql/exchange-retry'
import { getToken } from 'components/utils/get-token'
import { useEffect, useState } from 'react'
import {
  Client as UrqlClient,
  createClient,
  dedupExchange,
  errorExchange,
  Exchange,
  Operation,
} from 'urql'

const TOWER_IRAN_URL = 'https://tower.ir3.usenoor.com'

const baseUrl =
  process.env.NODE_ENV === 'production' ? TOWER_URL : 'http://localhost:7003'

export let storage: DefaultStorage | undefined

if (typeof window !== 'undefined') {
  storage = makeLocalStorage()
}

const urqlExchanges = [
  (devtoolsExchange as unknown) as Exchange,
  dedupExchange,
  (offlineExchange({
    // @ts-ignore
    schema: schema,
    storage,
    keys: {
      Dialog: (data) => String(data.id),
      Avatar: (data) => String(data.id),
      User: (data) => String(data.id),
    },
    updates: {
      Mutation: mutationUpdates,
    },
    optimistic: optimisticUpdates,
    resolvers: {
      Query: {
        //@ts-ignore
        node(parent, args: { id: string }) {
          return {
            __typename: fromGlobalId(args.id).type,
            id: args.id,
          }
        },
      },
    },
  }) as unknown) as Exchange,

  (authExchange({
    addAuthToOperation,
    getAuth,
  }) as unknown) as Exchange,

  (retryExchange({
    maxNumberAttempts: 3,
    retryIf: (error) => {
      if (error.networkError) {
        return true
      }

      return false
    },
  }) as unknown) as Exchange,

  errorExchange({
    onError(error, operation) {
      console.error(
        `GraphQL error on "${operation.key}":"${operation.kind}"`,
        error,
      )
    },
  }),
  (multipartFetchExchange as unknown) as Exchange,
]

export type ConnectionState = 'connecting' | 'connected' | 'disconnected'
// fetchOptions: () => {
//   let token = getToken()
//   return {
//     headers: {
//       authorization: token ? `Bearer ${token}` : '',
//     },
//   }
// },

function createOurClient() {
  let url =
    typeof window !== 'undefined' && window.location.href.includes('ir3')
      ? TOWER_IRAN_URL
      : baseUrl
  return createClient({
    url: `${url}/v2/graphql?appVersion=${process.env.version}`,
    exchanges: urqlExchanges,
  })
}
export const useCreateGraphqlClient = () => {
  let { token, hydrated } = useAppContext()
  // let urqlClientRef = useRef<UrqlClient>(createOurClient())
  // let urqlClient = urqlClientRef.current
  let [urqlClient, setClient] = useState<UrqlClient | undefined>()

  useEffect(() => {
    if (!hydrated) return

    // Recreate client on token change (login / logout) urql best practice
    // https://formidable.com/open-source/urql/docs/api/auth-exchange/
    setClient(createOurClient())
  }, [hydrated, token])

  return { client: urqlClient, connectionState: 'connected' }
}

type AuthState = {
  token: string | null
}

async function getAuth({
  authState,
}: {
  authState: AuthState | null
}): Promise<AuthState | null> {
  if (authState?.token) return authState
  let token = await getToken()
  if (!token) return null
  return { token }
}

function addAuthToOperation({
  authState,
  operation,
}: {
  authState: AuthState | null
  operation: Operation
}): Operation {
  // the token isn't in the auth state, return the operation without changes
  if (!authState || !authState.token) {
    return operation
  }

  // fetchOptions can be a function (See Client API) but you can simplify this based on usage
  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {}

  return {
    ...operation,
    context: {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          authorization: authState.token ? `Bearer ${authState.token}` : '',
          // "Authorization": authState.token,
        },
      },
    },
  }
}
