import { useCallback, useEffect } from 'react'
import { electronApi, ipc } from '@there/desktop/utils/electron-api'
import { useAppContext } from '../../shared/AppContext'
import { useRouter } from 'next/router'
import { logEvent } from '../../shared/use-analytics'
import { storage } from '@there/components/urql/client'

export const useLogOutAction = (props?: { notifyViaIpc?: boolean }) => {
  const { purgeState, appState } = useAppContext()
  const { push } = useRouter()

  const logOut = useCallback(async () => {
    console.info('Logging out...')

    // Clear app context
    purgeState()

    // Clear Apollo's in memory cache
    // if (cache) {
    //   console.info('Clearing apollo`s in memory cache')
    //   await cache.reset()
    // }

    // Clear Apollo cache from Storage
    // if (persistor) {
    //   console.info('Clearing apollo`s cache from localStorage')
    //   await persistor.purge()
    // }

    // Remove token (legacy)
    console.info('Removing token')
    localStorage.removeItem('token')
    localStorage.removeItem('appState')
    console.info('Clearing urql cache')
    localStorage.removeItem('urql')
    localStorage.removeItem('urqlMetadata')
    // Clear sun cache
    localStorage.removeItem('nur_v1')
    try {
      await storage?.clear()

      if (props?.notifyViaIpc !== false) {
        await ipc?.invoke('there:logout')
      }
    } catch (error) {}

    logEvent('User Logged Out')
    // Redirect to login
    console.info('Redirect to /login')
    push('/login')
  }, [purgeState, props, push])

  return logOut
}

export const useLogOutListener = () => {
  const logout = useLogOutAction({ notifyViaIpc: false })

  useEffect(() => {
    if (!electronApi) return

    electronApi.logOut.addListener(logout)

    return () => {
      if (electronApi) {
        electronApi.logOut.removeListener(logout)
      }
    }
  }, [logout])
}

export const doLogout = () => {
  console.info('Logging out...')

  console.info('Removing token')
  localStorage.removeItem('token')
  localStorage.removeItem('appState')
  console.info('Clearing urql cache')
  localStorage.removeItem('urql')
  localStorage.removeItem('urqlMetadata')

  console.info('Clearing sun cache')
  storage?.clear()

  // Redirect to login
  console.info('Redirect to /login')
  window.location.assign('/login')
}
