import { useEffect, useRef, useState } from 'react'
import { TextInput, View } from 'react-native'
import { useSpaceContext } from '../shared/spaceContext'
import { StandardInput } from '../main/StandardInput'
import { logEvent } from '../shared/use-analytics'
import { useInviteMutation } from '../urql/invite'
import { isBeta } from '@there/desktop/utils/electron-api'

export const AddPeopleEmailInput = ({ onClose }: { onClose: () => void }) => {
  const [{ space }, refetch] = useSpaceContext()

  let emailInputRef = useRef<TextInput | null>(null)
  let [inputValue, setInputValue] = useState('')
  let [emails, setEmails] = useState<string[]>([])
  let [loading, setLoading] = useState(false)
  const [{ error: inviteEmailError }, invite] = useInviteMutation()

  // if reached maximum email invites
  useEffect(() => {
    if (
      inviteEmailError?.message.includes(
        'Reached maximum pending email invites',
      )
    ) {
      alert(
        'Reached maximum pending email invites. You can invite new users by link.',
      )
    }
  }, [inviteEmailError])

  useEffect(() => {
    let verifiedEmails: string[] = []

    let splited = inputValue.split(/[\s,]+/)
    for (let item of splited) {
      if (item.length > 5 && item.includes('@') && item.includes('.')) {
        verifiedEmails.push(item)
      }
    }
    setEmails(verifiedEmails)
  }, [inputValue])

  const sendInviteEmail = () => {
    if (!space || !space?.joinSecretCode) return

    setLoading(true)

    invite({
      emails,
      spaceId: space.id,
      spaceJoinSecretCode: space?.joinSecretCode,
      isBeta,
    })
      .then(() => {
        // setEmailSent(true)

        if (emailInputRef && emailInputRef.current) {
          emailInputRef.current.focus()
        }
      })
      .finally(() => {
        logEvent('User Invited Friend By Email', {
          spaceId: space.id,
          emailsNum: emails.length,
          from: 'header - invite modal',
        })
        setLoading(false)
        setInputValue('')
        refetch()
      })
  }

  return (
    <View style={{ width: '100%', position: 'relative' }}>
      <StandardInput
        ref={emailInputRef}
        placeholder="Enter email"
        onSubmit={sendInviteEmail}
        onCancel={onClose}
        background="transparentInputBackground"
        textColor="text"
        value={inputValue}
        setValue={setInputValue}
        valueValidated={emails.length > 0}
        isMultiline={true}
        helpText={
          emails.length > 0
            ? `Will invite ${emails.length} people`
            : 'Separate multiple emails using return'
        }
        disabled={loading}
      />
    </View>
  )
}
