import { useNurMutation } from '@there/components/sun/use-mutation'
import { AddStatusMessage, AddStatusResult } from '@there/sun/modules/addStatus'
import { DeleteStatusReactionMessage } from '@there/sun/modules/deleteStatusReaction'

type RemoteVariables = AddStatusMessage['payload']
export interface LocalAddStatusMessageVariables extends RemoteVariables {
  __extra: {
    userId: string
  }
}

export const useAddStatus = () => {
  return useNurMutation<AddStatusResult, LocalAddStatusMessageVariables>({
    method: 'addStatus',
  })
}
