import { Text, View } from 'react-native'
import { useWindowContext } from '../shared/WindowContext'
import { useSpring, a, useTransition } from '@react-spring/native'
import { useAppContext } from '../shared/AppContext'
import { useTheme } from '../feed/ThemeContext'
import { Pressable } from '../shared/Pressable'
import { useCallback, useState } from 'react'
import { CrossButton } from '../main/CrossButton'
import { useHover } from '../shared/use-hover'

export const DownloadDesktopBanner = () => {
  let { isElectron, hasDesktopVersion } = useWindowContext()
  let { downloadDesktopBannerDismissed, dispatch } = useAppContext()
  let isBrowser = !isElectron
  let theme = useTheme()

  let dismissDownloadBanner = useCallback(() => {
    dispatch({ type: 'dismiss desktop banner', value: true })
  }, [dispatch])

  let transitions = useTransition(
    isBrowser && hasDesktopVersion && !downloadDesktopBannerDismissed,
    {
      from: {
        opacity: 0,
        y: 50,
      },
      enter: {
        opacity: 1,
        y: 0,
      },
      leave: {
        opacity: 0,
        y: 50,
      },
      config: {
        mass: 0.4,
        tension: 400,
        friction: 22,
      },
    },
  )

  let [hovered, hoverListener] = useHover()

  let crossStyleProps = useSpring({
    position: 'absolute',
    top: -4,
    left: -4,
    opacity: hovered ? 1 : 0,
    delay: hovered ? 300 : 0,
    config: {
      mass: 0.1,
      tension: 300,
      friction: 20,
    },
  })

  return transitions(({ opacity, y }, item) => {
    return item ? (
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          // @ts-ignore
          pointerEvents: 'none',
        }}
      >
        <a.View
          style={[
            {
              width: '100%',
              padding: 20,
              opacity,
              transform: [{ translateY: y }],
              // @ts-ignore
              pointerEvents: 'auto',
            },
          ]}
        >
          <Pressable
            style={({ pressed }) => [
              {
                backgroundColor: theme.colors.primaryButtonBackground,
                borderRadius: 8,
                padding: 10,

                shadowRadius: 3,
                shadowOffset: {
                  height: 2,
                  width: 0,
                },
                shadowColor: theme.colors.black,
                shadowOpacity: 0.2,
              },
              hovered && {
                backgroundColor: theme.colors.primaryButtonBackgroundHover,
              },
              pressed && {
                opacity: 0.9,
                transform: [{ scale: 0.98 }],
              },
            ]}
            onPress={() => {
              window.open('https://usenoor.com/download')
              dismissDownloadBanner()
            }}
            {...hoverListener}
          >
            <Text
              style={{
                color: theme.colors.secondaryText,
                fontSize: theme.fontSizes.large,
              }}
            >
              Download Desktop Version
            </Text>
            <Text
              style={{
                color: theme.colors.tertiaryLight,
                fontSize: theme.fontSizes.normal,
                marginTop: 2,
              }}
            >
              For more features and better performance
            </Text>
            <a.View
              //@ts-ignore
              style={[crossStyleProps]}
            >
              <CrossButton onPress={() => dismissDownloadBanner()} />
            </a.View>
          </Pressable>
        </a.View>
      </View>
    ) : null
  })
}
