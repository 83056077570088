import { ToggleRow } from '@there/components/settings/ToggleRow'
import { useAppContext } from '@there/components/shared/AppContext'
import { useWindowContext } from '@there/components/shared/WindowContext'
import { electronSupports, ipc } from '@there/desktop/utils/electron-api'
import React from 'react'
import { Space } from '../shared/Space'
import { Section, SectionHeader } from './SettingsUI'

export const WindowSection = () => {
  let {
    alwaysOnTop,
    hidePin,
    hideDock,
    hideTrayUsers,
    dispatch,
  } = useAppContext()
  let { isElectron } = useWindowContext()

  return (
    <Section>
      <SectionHeader>Window Management</SectionHeader>
      <Space vertical={8} />

      <ToggleRow
        title="Always on top"
        description="Enabling this makes noor stay at the top of all windows."
        disabledReason={
          !isElectron
            ? `Requires desktop app`
            : !electronSupports?.alwaysOnTop
            ? 'Update your app from menu to enable'
            : undefined
        }
        disabled={!isElectron || !electronSupports?.alwaysOnTop}
        value={!!alwaysOnTop}
        onValueChange={(value) => {
          dispatch({ type: 'always on top changed', value })
        }}
      />

      <ToggleRow
        title="Hide floating voice window"
        description="Don't show the mini voice window when joining a room"
        disabledReason={!isElectron ? `Requires desktop app` : undefined}
        disabled={!isElectron}
        value={!!hidePin}
        onValueChange={(value) => {
          dispatch({ type: 'hide pin changed', value })
        }}
      />

      <ToggleRow
        title="Hide from dock"
        description="Use Noor as a menubar app"
        disabledReason={!isElectron ? `Requires desktop app` : undefined}
        disabled={!isElectron}
        value={!!hideDock}
        onValueChange={(value) => {
          dispatch({ type: 'hide dock changed', value })
          ipc?.invoke('desktop-mode-changed', value ? 'tray' : 'both')
        }}
      />

      <ToggleRow
        title="Hide avatars in the menubar"
        description="To save space in your macOS menubar, hide the avatars of online members."
        disabledReason={!isElectron ? `Requires desktop app` : undefined}
        disabled={!isElectron}
        value={!!hideTrayUsers}
        onValueChange={(value) => {
          dispatch({ type: 'hide tray users changed', value })
        }}
      />
    </Section>
  )
}
