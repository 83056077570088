export const getAmPmTime = (time: Date) => {
  let hours = time.getHours()
  let minutes = time.getMinutes()
  let ampm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12
  hours = hours ? hours : 12 // the hour 0 should be 12

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0',
  )} ${ampm}`
}
