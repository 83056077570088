import styled, { CSSProperties } from 'styled-components'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { OverlayErrorBoundary } from '../overlay/ErrorBoundary'
import { useAppContext } from '../shared/AppContext'
import { getShortName } from '@there/components/utils/get-short-name'
import { WalkieCallParticipant } from '../shared/use-rtc'
import { useScreenShareMouseEvents } from './useScreenShareEvents'
import { RtcAvatar } from '../shared/use-rtc-avatars'
import {
  ipc,
  RtcDataChannelDataOverIpc,
} from '@there/desktop/utils/electron-api'
import useInterval from '@use-it/interval'
import { a, useSpring } from '@react-spring/web'
import { filterFalsy } from '@there/shared/utilities/filter-falsy'
import { OverlayState } from '@there/components/shared/useSyncOverlayState'
import { useLatest } from '@there/components/shared/use-latest'
import { useWindowContext } from '@there/components/shared/WindowContext'

const colors = ['#1C80F6', '#FF4487', '#6C3597']

type Bounds = {
  width: number
  height: number
}

export type Participant = {
  name: string | null
  id: string
  profilePhoto: string | undefined | null
}

type Props = {
  pointersNode: React.ReactNode
}

export function Overlay({ pointersNode }: Props) {
  let { platform } = useWindowContext()
  let [style, animate] = useSpring(() => ({
    redBorderOpacity: 0,
    borderWidth: 2,
    config: {
      mass: 0.1,
      friction: 22,
      tension: 400,
    },
  }))

  useEffect(() => {
    animate({ redBorderOpacity: 1, borderWidth: 4, delay: 100 })
    animate({ redBorderOpacity: 0.7, borderWidth: 2.5, delay: 2500 })
  }, [animate])

  return (
    <>
      <OverlayErrorBoundary>
        <Wrapper>{pointersNode}</Wrapper>

        {/* top left */}
        <a.div
          style={{
            position: 'absolute',
            width: '100px',
            height: '100px',
            borderLeftWidth: style.borderWidth,
            borderTopWidth: style.borderWidth,
            borderBottomWidth: 0,
            borderRightWidth: 0,
            borderColor: '#ff0266',
            borderStyle: 'solid',
            opacity: style.redBorderOpacity,
          }}
        />
        {/* top right */}
        <a.div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '100px',
            height: '100px',
            borderLeftWidth: 0,
            borderTopWidth: style.borderWidth,
            borderBottomWidth: 0,
            borderRightWidth: style.borderWidth,
            borderColor: '#ff0266',
            borderStyle: 'solid',
            opacity: style.redBorderOpacity,
          }}
        />
        {/* bottom right */}
        <a.div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100px',
            height: '100px',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: style.borderWidth,
            borderRightWidth: style.borderWidth,
            borderColor: '#ff0266',
            borderStyle: 'solid',
            opacity: style.redBorderOpacity,
          }}
        />
        {/* bottom left */}
        <a.div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100px',
            height: '100px',
            borderLeftWidth: style.borderWidth,
            borderTopWidth: 0,
            borderBottomWidth: style.borderWidth,
            borderRightWidth: 0,
            borderColor: '#ff0266',
            borderStyle: 'solid',
            opacity: style.redBorderOpacity,
          }}
        />
      </OverlayErrorBoundary>
    </>
  )
}

type PointersProps = {
  bounds: Bounds | undefined
  avatars: RtcAvatar[]
  overlayState: OverlayState | undefined
}

type CursorType = {
  shortName: string | undefined
  x: number
  y: number
  color: string
  id: string
  active?: boolean
}

// Passing it using composite components pattern to avoid re-render of whole Overlay on pointer move
export function Pointers(props: PointersProps) {
  const { bounds, avatars, overlayState } = props
  const { currentUserId } = useAppContext()
  const {
    cursorsState,
    handleScreenShareMouseEvents,
  } = useScreenShareMouseEvents()

  //mouse JsonData event listener
  //keyboard JsonData event listener
  let remotePrivilege = overlayState ? overlayState.remotePrivilege : true
  useEffect(() => {
    const handleEvents = (
      event: any,
      dataChannelData: RtcDataChannelDataOverIpc,
    ) => {
      // TODO: check if we're screen sharing and they are in call and then proceed
      handleScreenShareMouseEvents(dataChannelData, remotePrivilege)
    }

    // addJsonDataEventListener(handleEvents)
    ipc?.addListener('rtc-data-channel-data', handleEvents)

    return () => {
      ipc?.removeListener('rtc-data-channel-data', handleEvents)
    }
  }, [handleScreenShareMouseEvents, remotePrivilege])

  const people = useMemo(() => {
    return (avatars || [])
      .filter(function (
        n?: WalkieCallParticipant | false,
      ): n is WalkieCallParticipant {
        if (!n) return false

        // filter based on observing
        let isObserving = overlayState?.ourObservers?.includes(n.userId)

        if (overlayState?.ourObservers && isObserving === false) return false

        return n.userId !== currentUserId
      })
      .map((p, i) => {
        return {
          ...p,
          color: colors[i],
        }
      })
  }, [avatars, currentUserId, overlayState])

  const [currentTime, setTime] = useState(0)

  useInterval(() => {
    setTime(performance.now())
  }, 1000)

  let cursors: CursorType[] = people.map((participant, i) => {
    let point = cursorsState.positions[participant.user.id]
    let avatar = participant
    if (!point) point = { x: -100, y: -100, lastMoveAt: 0 }
    let safeBounds = bounds || { width: -100, height: -100 }
    let active = true
    if (point.lastMoveAt + 2000 < currentTime) {
      // do not render
      // return false
      active = false
    }
    const x = Number(point.x || 0) * (safeBounds.width || 0)
    const y = Number(point.y || 0) * (safeBounds.height || 0)

    let shortName = avatar?.user && getShortName(avatar.user)
    return {
      shortName,
      x,
      y,
      color: participant.color,
      id: participant.user.id,
      active,
    }
  })
  // .filter(filterFalsy)

  // let renderTransitions = useTransition(cursors, {
  //   keys: item => item.id,
  //   from: {
  //     opacity: 0,
  //   },
  //   enter: {
  //     opacity: 1,
  //   },
  //   leave: {
  //     opacity: 0,
  //   },
  // })

  let cursorsRef = useLatest(cursors)
  let renderTransitions = useCallback(
    (
      render: (
        style: { opacity: number },
        item: CursorType,
        index: number,
      ) => React.ReactNode,
    ) => {
      if (!cursorsRef.current) return null
      return cursorsRef.current.map((item, index) =>
        render({ opacity: 1 }, item, index),
      )
    },
    [cursorsRef],
  )

  if (!avatars || !bounds) {
    return null
  }

  return (
    <>
      {renderTransitions((style, item, index) => (
        <div
          key={item.id}
          style={{
            width: 150,
            height: 40,
            position: 'absolute',
            transform: `translate3d(${item.x}px, ${item.y}px, 0)`,
            opacity: style.opacity,
            transition: `transform 18ms`,
          }}
        >
          <Cursor
            color={item.color}
            name={item.shortName || `User ${index}`}
            active={item.active}
          />
        </div>
      ))}
    </>
  )
}

export type CursorProps = {
  color: string
  name: string
  active?: boolean
  onlyName?: boolean
}

export const Cursor = memo(({ name, color, active, onlyName }: CursorProps) => {
  return (
    <>
      {!onlyName && <ArrowSvg color={color} active={active} />}
      <CursorName
        color={active ? color : 'rgba(0,0,0,0.2)'}
        style={{ opacity: active ? 1 : 0.4 }}
      >
        {name}
      </CursorName>
    </>
  )
})

const ArrowSvg = memo(
  ({ color = '#348FFA', active }: { color: string; active?: boolean }) => (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      style={svgStyle}
    >
      <path
        d="M1.6551 2.25217L0.89209 1.74774L1.02574 2.6729L2.78613 14.8582L2.93205 15.8683L3.52346 15.0493L6.44423 11.0048L11.6479 9.71278L12.5874 9.47945L11.7738 8.94157L1.6551 2.25217Z"
        fill={active ? color : 'rgba(0,0,0,0.1)'}
        style={{ transition: `fill 120ms ease-out` }}
        stroke={active ? 'white' : `rgba(255,255,255,0.3)`}
      />
    </svg>
  ),
)

const svgStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'block',
}

type CNProps = { color: string }

const CursorName = styled.div<CNProps>`
  padding-left: 4px;
  padding-right: 4px;
  height: 17px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 12px;
  top: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 10px;
  border-radius: 3px;

  /* Controlled by main */
  color: white;
  background: ${(p) => p.color || ' #348ffa'};
  transition: background 120ms ease-out;
`

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
`

export const ActionMenuItem = styled.div`
  width: 22px;
  height: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-app-region: no-drag;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

  &:active {
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;
  }
`
