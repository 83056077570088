import { DefaultCache } from '@there/components/sun/cache'
import { DefaultNetwork, OnError } from '@there/components/sun/network'
import { optimistic } from '@there/components/sun/optimistic'
import { policies } from '@there/components/sun/policies'
import { updates } from '@there/components/sun/updates'
import { hash } from '@there/components/sun/utils/hash'
import { stringifyVariables } from '@there/components/sun/utils/stringifyVariables'
import { JQLClientMessageKind } from '@there/sun/ws/protocol'
import { nanoid } from 'nanoid'
import { ConnectionInitMessage } from 'sun/modules/connectionInit'
import {
  Cache,
  Network,
  NodeQuery,
  NodeSubscriptionCallback,
  Query,
  QueryWithKind,
  Request,
  RequestId,
  ResultCallback,
  Sink,
  Subscription,
  SubscriptionResult,
  SubscriptionResultCallback,
} from './utils/types'
const debug = require('debug')('nur:client')

interface ConnectionOptions {
  url: string
  connectionParams: ConnectionInitMessage['payload']
  onError?: OnError
}

export type QueryOptions = {
  fetchPolicy?: 'cache-and-network' | 'cache-only' | undefined
}

export class Client {
  network: Network | DefaultNetwork
  cache: Cache
  pendingRequests: Set<Request>
  /** Request subscribers */
  activeSubscribers: Map<
    // RequestId,
    number,
    Set<ResultCallback | SubscriptionResultCallback>
  >

  constructor(options: ConnectionOptions) {
    this.pendingRequests = new Set()
    this.activeSubscribers = new Map()
    this.network = new DefaultNetwork(options)
    this.cache = new DefaultCache({
      updates,
      optimistic,
      policies,
    })

    if (this.cache instanceof DefaultCache) {
      for (let method in updates.subscription) {
        this.network.onEvent(method, {
          next: (payload) => {
            let cache = this.cache as DefaultCache
            cache.onEvent({ method }, payload)
          },
          complete: () => {},
        })
      }
    }

    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.nurClient = this
    }
  }

  private debug(base: string, ...args: string[]) {
    debug(`${base}`, ...args)
  }

  executeRequest(initialRequest: Request, sink: Sink, options?: QueryOptions) {
    const doRequest = (inputRequest: Request) => {
      let fetchPolicy = options?.fetchPolicy || 'cache-and-network'

      // dedup (only for queries)
      // let request = inputRequest
      let request =
        inputRequest.kind === JQLClientMessageKind.Query
          ? this.dedup(inputRequest)
          : inputRequest

      // add to pending ops
      this.pendingRequests.add(request)
      const clientSink: Sink = {
        // next: (result) => {
        //   if (result.data) {
        //     this.debug(
        //       `[result] method =`,
        //       result.data,
        //       `, id =`,
        //       (result.id as any) as string,
        //       String(result.fetching),
        //     )
        //   }

        //   sink.next(result)
        // },

        // KEEP THE REFERENCE THE SAME, ABOVE COMMENT IS WRONG
        // KEEP THE REFERENCE THE SAME, ABOVE COMMENT IS WRONG
        // KEEP THE REFERENCE THE SAME, ABOVE COMMENT IS WRONG
        next: sink.next,
        complete: () => {},
      }

      this.addSubscriber(request.key, clientSink.next)

      // Send to cache
      let cacheInput = this.cache.request(request, clientSink)

      let networkSink: Sink | undefined
      let networkUnsubscribe = () => {}

      if (fetchPolicy !== 'cache-only') {
        // send over the network
        networkSink = {
          next: (...args) => {
            // Get modified result from cache to apply paused optimistic patches
            let result = cacheInput.next(...args)

            // Experimental: Let's keep it around so subsequent queries subscribe to it
            // this.pendingRequests.delete(request)

            clientSink.next(result)
          },
          complete: clientSink.complete,
        }
        ;({ unsubscribe: networkUnsubscribe } = this.network.send(
          request,
          networkSink,
        ))
      }

      this.debug(
        `[request] method =`,
        inputRequest.method,
        ', type =',
        inputRequest.kind,
      )

      return {
        unsubscribe: () => {
          // remove from pending ops
          this.pendingRequests.delete(request)
          this.removeSubscriber(request.key, clientSink.next, request)
          networkUnsubscribe()
          // unsubscribe
          cacheInput.complete()
        },
        reExecute: () => {
          if (fetchPolicy === 'cache-only') {
            console.error('Cannot refetch cache-only query')
          }
          if (networkSink) {
            this.pendingRequests.delete(request)
            networkUnsubscribe()

            // Change request id (otherwise it would be marked as)
            let newRequest = { ...request, id: (nanoid() as any) as RequestId }
            this.pendingRequests.add(newRequest)

            // We call send WITH THE EXACT SAME SINK to avoid
            // re-adding it to subscribers
            ;({ unsubscribe: networkUnsubscribe } = this.network.send(
              newRequest,
              networkSink,
            ))
          }
        },
      }
    }

    let hasUnsubscribed = false
    const { reExecute, unsubscribe } = doRequest(initialRequest)
    const unsubscribeFromRequestRef = { current: unsubscribe }
    const reExecuteRef = { current: reExecute }

    return {
      unsubscribe: () => {
        hasUnsubscribed = true
        unsubscribeFromRequestRef.current()
      },

      reExecute: () => {
        if (hasUnsubscribed) {
          console.warn('Called refetch on unsubscribed query=', request)
          return
        }

        reExecuteRef.current()
      },

      fetchMore: (variables: Record<string, any>) => {
        // Unsubscribe from prev request
        unsubscribeFromRequestRef.current()

        // Repeat request with new variables
        const { reExecute, unsubscribe } = doRequest(
          makeRequest({
            ...initialRequest,
            variables: { ...initialRequest.variables, ...variables },
          }),
        )

        // Save new functions
        unsubscribeFromRequestRef.current = unsubscribe
        reExecuteRef.current = reExecute
      },
    }
  }

  private dedup(request: Request): Request {
    let existingRequest: Request | undefined

    for (let pendingRequest of this.pendingRequests) {
      if (pendingRequest.key === request.key) {
        existingRequest = pendingRequest
        break
      }
    }

    return existingRequest || request
  }

  // query ('space', { spaceId: 'there' }).subscribe(() => {})
  query(query: Query, sink: Sink, options?: QueryOptions) {
    let request = makeRequest({ ...query, kind: JQLClientMessageKind.Query })
    let { unsubscribe, reExecute, fetchMore } = this.executeRequest(
      request,
      sink,
      options,
    )

    return {
      unsubscribe,
      reExecute,
      fetchMore,
    }
  }

  subscribe(subscription: Subscription, sink: Sink<SubscriptionResult>) {
    let key = getSubscriptionKey(subscription)
    this.addSubscriber(key, sink.next)
    let { unsubscribe } = this.network.onEvent(subscription.method, sink)

    return {
      unsubscribe: () => {
        unsubscribe()
        this.removeSubscriber(key, sink.next)
      },
    }
  }

  mutate(query: Query, sink: Sink) {
    let request = makeRequest({ ...query, kind: JQLClientMessageKind.Mutation })
    let { unsubscribe } = this.executeRequest(request, sink)

    return {
      unsubscribe,
      reExecute: () => {
        this.executeRequest(request, sink)
      },
    }
  }

  /** Register a callback so when a node changes, it gets called with the fresh node */
  node(node: NodeQuery, callback: NodeSubscriptionCallback) {
    // Send initial
    callback(this.cache.readNode({ id: node.id }))
    // Subscribe to changes
    return this.cache.subscribeToNode(node, callback)
  }

  addSubscriber(requestKey: number, subscriber: ResultCallback) {
    let subscribersSet = this.activeSubscribers.get(requestKey)

    if (subscribersSet) {
      subscribersSet.add(subscriber)

      if (subscribersSet.size > 500) {
        console.error(
          '[NurClient] Unusual number of subscribers registered for a request',
        )
      }
    } else {
      subscribersSet = new Set([subscriber])
    }

    this.activeSubscribers.set(requestKey, subscribersSet)
  }

  removeSubscriber(
    requestKey: number,
    subscriber: ResultCallback,
    request?: Request,
  ) {
    let subscribersSet = this.activeSubscribers.get(requestKey)

    if (subscribersSet) {
      subscribersSet.delete(subscriber)

      if (subscribersSet.size === 0) {
        // Clear field when no active subscription is left
        subscribersSet = undefined
        this.activeSubscribers.delete(requestKey)
        if (request) {
          this.pendingRequests.delete(request)
        }
      } else {
        this.activeSubscribers.set(requestKey, subscribersSet)
      }
    }
  }
}

function stringifyQuery(query: Query) {
  return `${query.method}(${
    query.variables ? stringifyVariables(query.variables) : ''
  })`
}

function makeRequest(query: QueryWithKind): Request {
  return {
    id: (nanoid() as any) as RequestId,
    key: hash(stringifyQuery(query)),
    kind: query.kind,
    method: query.method,
    variables: query.variables,
  }
}

export function getSubscriptionKey(subscription: Subscription): number {
  // let id = (`subscription#${topic}` as any) as RequestId
  return hash(stringifyQuery(subscription))
}
