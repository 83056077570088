import Sentry from '@there/app/utils/sentry'
import { useAppContext } from '@there/components/shared/AppContext'
import { useEffect, useMemo, useRef } from 'react'
import { useCurrentMediaDevicesContext } from '../shared/CurrentMediaDevicesContext'
import { useLatest } from '../shared/use-latest'
import { useRtcContext } from '../shared/use-rtc'
import { useOutputVolume } from './useOutputVolume'

const focusedVolume = 5

export function RtcAudio() {
  let rtcManager = useRtcContext()
  let { currentUser, currentUserId } = useAppContext()
  const enabled = rtcManager.enabled
  const currentParticipants = useMemo(
    () => Object.values(rtcManager.walkieState.participants),
    [rtcManager.walkieState.participants],
  )
  const currentParticipantsRef = useLatest(currentParticipants)
  const numOfParticipants = currentParticipants.length

  const audioRefs = useRef<{ [peerId: string]: HTMLAudioElement | undefined }>(
    {},
  )

  const { usersOutputVolume } = useOutputVolume()
  const { currentSpeaker } = useCurrentMediaDevicesContext()

  const callId = rtcManager?.walkieState?.callId

  // Clean up previous call's audio tags
  // (preventing memory leak)
  useEffect(() => {
    if (!enabled) return
    for (const audio of Object.values(audioRefs.current)) audio?.remove()
    audioRefs.current = {}
    // @ts-ignore
    window.getAudioTags = () => {
      return audioRefs.current
    }
  }, [callId, enabled])

  // Clean up audio tags
  useEffect(() => {
    if (!enabled) return

    if (!currentParticipantsRef.current) return

    for (let [elementKey, audio] of Object.entries(audioRefs.current)) {
      // format: userId_key
      let [userId] = elementKey.split('_')

      const stillAParticipant =
        currentParticipantsRef.current.findIndex(
          (participant) => participant?.userId === userId,
        ) > -1

      if (stillAParticipant) continue

      if (audio) {
        // eslint-disable-next-line unicorn/prefer-add-event-listener
        audio.onpause = null
        audio.remove()
      }
      audioRefs.current[elementKey] = undefined
    }
  }, [currentParticipantsRef, enabled, numOfParticipants])

  // Add audio tags for each participant
  useEffect(() => {
    if (!enabled) return

    let participantsArray = Object.values(rtcManager.walkieState.participants)

    async function doTheJob() {
      async function playUserAudio(
        userId: string,
        stream: MediaStream,
        streamKey: 'mic' | 'system',
      ) {
        // must use _ (see above split)
        let elementKey = `${userId}_${streamKey}`
        let audioElement = audioRefs.current[elementKey]

        if (!audioElement) {
          audioRefs.current[elementKey] = audioElement = new Audio()
        }

        let isPlaying = !audioElement.paused

        let audioVolume = 1.0
        if (streamKey === 'system') {
          audioVolume = usersOutputVolume[userId]?.systemOutput ?? 1.0
        } else {
          audioVolume = usersOutputVolume[userId]?.userOutput ?? 1.0
        }

        audioElement.volume = audioVolume

        if (currentSpeaker && 'setSinkId' in audioElement) {
          try {
            //@ts-ignore
            await audioElement?.setSinkId(currentSpeaker.id)
          } catch (error) {
            let deviceNotFound =
              error instanceof Error &&
              error.message?.includes('Requested device not found')

            console.warn(error)
            // Ignore requested not found errors polling the console
            if (!deviceNotFound) {
              console.error(error)
              Sentry.captureException(error)
            }
          }
        }

        // Fix for AirPods pausing audio when you take one AirPod off
        // eslint-disable-next-line unicorn/prefer-add-event-listener
        audioElement.onpause = () => {
          audioElement?.play().catch((error) => {
            console.warn('Failed to play audio:', error)
          })
        }

        if (stream?.getAudioTracks()[0]) {
          stream.getAudioTracks()[0].onunmute = () => {
            if (audioElement) {
              console.info('[onunmute] handler called')
              audioElement.srcObject = stream || null
              audioElement?.play().catch((error) => {
                console.warn('Failed to play audio:', error)
              })
            }
          }
        }

        if (
          stream &&
          (!isPlaying ||
            !audioElement.srcObject ||
            (audioElement.srcObject instanceof MediaStream &&
              // or tracks not match (important)
              (audioElement.srcObject?.id !== stream?.id ||
                audioElement.srcObject?.getAudioTracks()[0]?.id !==
                  stream.getAudioTracks()[0]?.id)) ||
            audioElement.srcObject !== stream)
        ) {
          audioElement.srcObject = stream

          // play
          audioElement?.play().catch((error) => {
            console.warn('Failed to play audio:', error)
          })
        }
      }

      for (let {
        userId,
        microphoneStream,
        systemAudioStream,
      } of participantsArray) {
        if (microphoneStream) {
          await playUserAudio(userId, microphoneStream, 'mic').catch(
            (error) => {
              console.error('Error playing microphone audio:', error)
              Sentry.captureException(error)
            },
          )
        }
        if (systemAudioStream) {
          await playUserAudio(userId, systemAudioStream, 'system').catch(
            (error) => {
              console.error('Error playing system audio:', error)
              Sentry.captureException(error)
            },
          )
        }
      }
    }

    doTheJob().catch((error) => {
      console.error('Error in doTheJob:', error)
      Sentry.captureException(error)
    })

    return () => {
      for (let { microphoneStream } of participantsArray) {
        if (!microphoneStream?.getAudioTracks()[0]) return
        microphoneStream.getAudioTracks()[0].onunmute = null
      }
    }
  }, [currentSpeaker, enabled, rtcManager.walkieState, usersOutputVolume])

  return null
}
