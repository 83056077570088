import gql from 'graphql-tag'
import { spaceInfoFragment } from './spaceInfo'
import { UserInfo } from '@there/components/urql/fragments/userInfo'
import {
  // This one does not include user thus, it's suitable here
  memberOnlyInfoFragment,
  MemberWithSpaceInfo,
} from '@there/components/urql/fragments/memberInfo'

export const userSpacesInfoFragment = gql`
  fragment userSpacesInfo on User {
    memberships {
      ...memberOnlyInfo
      space {
        ...spaceInfo
      }
    }
  }
  ${spaceInfoFragment}
  ${memberOnlyInfoFragment}
`

export interface UserWithSpacesInfo extends UserInfo {
  memberships: Array<MemberWithSpaceInfo>
}
