import { useTheme } from '@there/components/feed/ThemeContext'
import { EmojiView } from '@there/components/main/EmojiView'
import { Tooltip } from '@there/components/shared/Tooltip'
import { useLatest } from '@there/components/shared/use-latest'
import { atom, useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import ms from 'ms'
import { useEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'

type StatusStreak = {
  streak: number
  lastStreak: number
  lastStatusPosted: number | undefined
}

const initialState = {
  streak: 0,
  lastStreak: 0,
  lastStatusPosted: undefined,
}

const storageKey = 'statusStreak'

function clear() {
  localStorage.setItem(storageKey, JSON.stringify(initialState))
}

let statusStreakAtom = atom<StatusStreak>(initialState)
let statusStreakManagerAtom = atom<{ postedStatus: () => void }>({
  postedStatus: () => {},
})

export const StatusStreak = () => {
  let state = useAtomValue(statusStreakAtom)
  let theme = useTheme()

  return (
    <Tooltip
      label={
        <>
          Streak of days posting status: {state.streak}
          {state.lastStreak ? (
            <>
              <br />
              <span className="light">Previous Streak: {state.lastStreak}</span>
            </>
          ) : null}
        </>
      }
    >
      <View style={styles.wrapper}>
        <EmojiView children="⭐" marginRight={4} size={16} />{' '}
        <Text
          style={{
            color: theme.colors.tertiaryText,
            fontSize: theme.fontSizes.small,
          }}
        >
          {state.streak}
        </Text>
      </View>
    </Tooltip>
  )
}

/**
 * Used in composer
 */
export const useStatusStreak = () => {
  let [manager] = useAtom(statusStreakManagerAtom)
  return manager
}

/** Must always be mounted */
export const StatusStreakManager = () => {
  let [state, setState] = useAtom(statusStreakAtom)
  let [hydrated, setHydrated] = useState(false)
  let hydratedRef = useLatest(hydrated)

  // Bring back from localStorage
  useEffect(() => {
    if (hydrated) return

    let value = localStorage.getItem(storageKey)
    if (value) {
      try {
        setState(JSON.parse(value))
        setHydrated(true)
      } catch (error) {
        console.error('Failed to parse streakState', error)
        clear()
      }
    } else {
      setHydrated(true)
    }
  }, [hydrated, setState])

  // Save
  useEffect(() => {
    // Use the ref to skip the first render caused by hydration
    if (!hydratedRef.current) return
    localStorage.setItem(storageKey, JSON.stringify(state))
  }, [hydratedRef, state])

  // Used to hook into the status composer
  let [, updateManager] = useAtom(statusStreakManagerAtom)
  useEffect(() => {
    updateManager(() => ({
      postedStatus: () => {
        // FIXME: optimize
        // if it has been more than a day in local timezone since the last status, reset the streak
        // trick to get day time
        let { today, todayDate, yesterday } = getTimes()

        if (!state.lastStatusPosted) {
          return setState((state) => ({
            ...state,
            streak: 1,
            lastStatusPosted: todayDate.getTime(),
          }))
        }

        // To be changed
        let nextStreak = state.streak

        if (
          yesterday <= state.lastStatusPosted &&
          state.lastStatusPosted <= today
        ) {
          nextStreak += 1
        }

        setState((state) => ({
          ...state,
          streak: nextStreak,
          lastStatusPosted: Date.now(),
        }))
      },
    }))
  }, [setState, state.lastStatusPosted, state.streak, updateManager])

  // Lose streak
  useEffect(() => {
    let { yesterday } = getTimes()

    if (state.lastStatusPosted && state.lastStatusPosted < yesterday) {
      // Lose streak
      setState((state) => ({
        streak: 0,
        lastStreak: state.streak,
        // reset
        lastStatusPosted: undefined,
      }))
    }
  }, [setState, state.lastStatusPosted])

  return null
}

function getTimes() {
  // FIXME: optimize
  // if it has been more than a day in local timezone since the last status, reset the streak
  let todayDate = new Date()
  // trick to get day time
  let today = todayDate.setHours(0, 0, 0, 0)
  let yesterday = new Date(todayDate.getTime() - ms('24h')).getTime()

  return { today, yesterday, todayDate }
}

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: 6,
    backgroundColor: `rgba(255,255,255,0.05)`,
    paddingHorizontal: 8,
    paddingVertical: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
