import { NewChatMessage, NewChatQueryReply } from '@there/sun/modules/newChat'
import {
  StatusesMessage,
  StatusesQueryReply,
} from '@there/sun/modules/statuses'
import { ApiClientMessage } from '@there/sun/ws/message-types'

type Value = {
  keyArgs: string[]
  merge: (
    newResults: any,
    oldResults: any,
    context: {
      variables: any
    },
  ) => any
}

export type SunPolicies = {
  Query: Partial<Record<ApiClientMessage['method'] | string, Value | undefined>>
}

export const policies: SunPolicies = {
  Query: {
    newChat: {
      keyArgs: ['peerTopicId', 'peerUserId'],

      merge(
        incoming: NewChatQueryReply,
        existing: NewChatQueryReply,
        {
          variables: { offsetId, addOffset },
        }: { variables: NewChatMessage['payload'] },
      ): NewChatQueryReply {
        // No paginate, just return fresh data
        // if (!addOffset) return incoming
        if (!offsetId || !addOffset) return incoming

        // Slice since it's immutable
        let messages = existing ? existing.messages.slice(0) : []

        // Sort
        messages = messages.sort((a, b) => a.sentAt - b.sentAt)

        // Index all ids
        let allIds = new Map<string, number>()
        for (let index in messages) {
          allIds.set(messages[index].id, Number(index))
        }

        // Find offsetId
        let offset = allIds.get(offsetId)

        let sign = Math.sign(addOffset)

        if (typeof offset === 'undefined') {
          console.error('Could not find offset from id')
          return incoming
        }

        // Put new data in one by one
        // if (offset === null) {
        //   // No offset, append to end
        //   // eslint-disable-next-line unicorn/no-for-loop
        //   for (let i = 0; i < incoming.messages.length; ++i) {
        //     // Skip duplicate
        //     if (allIds.has(incoming.messages[i].id)) {
        //       continue
        //     }
        //     messages.push(incoming.messages[i])
        //   }
        // } else {
        let incomingMessages =
          sign === 1 ? incoming.messages.reverse() : incoming.messages

        let before = messages.slice(0, offset)
        let middle = []
        let after = messages.slice(offset)

        // Put new data in one by one
        // eslint-disable-next-line unicorn/no-for-loop
        for (let i = 0; i < incomingMessages.length; ++i) {
          // Skip duplicate
          if (allIds.has(incomingMessages[i].id)) {
            // console.warn('Duplicate message')
            continue
          }

          if (sign === 1) {
            middle.unshift(incomingMessages[i])
          } else {
            middle.push(incomingMessages[i])
          }
        }

        // Merge all together
        messages = [...before, ...middle, ...after]
        // }

        return {
          ...incoming,
          messages: messages,
        }
      },
    },
    statuses: {
      keyArgs: ['spaceIds'],
      merge(
        incoming: StatusesQueryReply,
        existing: StatusesQueryReply,
        {
          variables: { offsetId, addOffset },
        }: { variables: StatusesMessage['payload'] },
      ): StatusesQueryReply {
        if (!offsetId || !addOffset) return incoming

        let spaceStatuses = existing ? existing.userSpaceStatuses : {}

        for (let [spaceId, statuses] of Object.entries(spaceStatuses)) {
          if (incoming.userSpaceStatuses[spaceId]) {
            let incomingStatuses = incoming.userSpaceStatuses[spaceId]
            spaceStatuses[spaceId] = [...statuses, ...incomingStatuses]
          }
        }

        return { userSpaceStatuses: spaceStatuses }
      },
    },
  },
}
