import { lightTheme } from '@there/components/utils/theme'
import { createContext, useContext } from 'react'
import { DefaultTheme } from 'styled-components'

type ContextType = DefaultTheme
const initialValue: ContextType = lightTheme
export const ThemeContext = createContext<ContextType>(initialValue)

export const useTheme = () => {
  return useContext(ThemeContext)
}
