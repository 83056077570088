import React, { memo, useEffect, useRef, useState } from 'react'
import { Image, View, Text } from 'react-native'
import { Space } from './Space'
import { PopoversPortal } from '@there/components/shared/ClientOnlyPortal'
import { WebStyled } from './WebStyled'
import { useTransition, a } from '@react-spring/native'
import { useDimensions } from './useDimensions'
import { useTheme } from '../feed/ThemeContext'

type Placement =
  | 'bottomLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomRight'
  | 'bottomCenter'
  | 'topLeft'
  | 'leftCenter'
  | 'rightCenter'

export type Props = {
  children: React.ReactNode
  placement?: Placement
  label: React.ReactNode
  offset?: number
  clickBehavior?: 'close' | 'open'
  enabled?: boolean
  alwaysOn?: boolean
  onTooltipClick?: () => void

  imageUri?: string
  imageWidth?: number
  imageHeight?: number
  size?: 'normal' | 'mini'
} & React.HTMLAttributes<HTMLDivElement>

const defaultOffset = 2

export const Tooltip = memo(
  ({
    children,
    placement = 'bottomLeft',
    offset = 0,
    label,
    clickBehavior = 'close',
    alwaysOn = false,
    enabled = true,
    onTooltipClick = undefined,
    imageUri,
    imageWidth,
    imageHeight,
    size = 'normal',
    ...props
  }: Props) => {
    let { window } = useDimensions()
    let theme = useTheme()
    const [isOpen, setOpen] = useState(false)
    const [position, setPosition] = useState<{
      top?: string | number
      left?: string | number
      bottom?: string | number
      right?: string | number
    }>({ top: 0, left: 0, bottom: 0, right: 0 })
    // let wrapperRef = useRef<HTMLDivElement | null>(null)
    const wrapperRef = useRef<View>(null)

    let transitions = useTransition(isOpen || alwaysOn, {
      from: {
        opacity: 0,
      },
      enter: {
        opacity: 1,
        delay: 340,
      },
      leave: {
        opacity: 0,
      },
      config: {
        mass: 0.4,
        tension: 400,
        friction: 22,
      },
    })

    useEffect(() => {
      if (!wrapperRef || !wrapperRef.current) return
      let mounted = true

      wrapperRef.current?.measureInWindow((x, y, width, height) => {
        if (!mounted) return
        let wrapperBounds = { y, x, width, height }

        // define critical areas statuses
        let criticalAreaFactor = 3
        let isBottomCriticalArea =
          wrapperBounds.y >
            window.height - window.height / criticalAreaFactor ||
          window.height < 60
        let isRightCriticalArea =
          wrapperBounds.x >
          window.width -
            window.width / (window.width < 200 ? 2 : criticalAreaFactor) // change criticalFactor to 2 when window width is small (like tooltip)

        setPosition({
          top: isBottomCriticalArea
            ? undefined
            : wrapperBounds.y + wrapperBounds.height + offset + defaultOffset,
          bottom: isBottomCriticalArea
            ? window.height - wrapperBounds.y + offset + defaultOffset
            : undefined,
          left: isRightCriticalArea ? undefined : wrapperBounds.x + offset,
          right: isRightCriticalArea
            ? window.width - (wrapperBounds.x + wrapperBounds.width) + offset
            : undefined,
        })
      })

      return () => {
        mounted = false
      }
    }, [offset, window.height, window.width, isOpen])

    if (!enabled) {
      return <>{children}</>
    }

    return (
      <View ref={wrapperRef} style={{}}>
        <WebStyled
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onMouseEnter={() => {
            setOpen(true)
          }}
          onMouseLeave={() => {
            setOpen(false)
          }}
          onClick={() => {
            if (clickBehavior === 'close') {
              setOpen(false)
            } else if (clickBehavior === 'open') {
              setOpen(true)
            }
          }}
          {...props}
        >
          {children}

          <PopoversPortal
            isOpen={isOpen || alwaysOn}
            onClose={() => setOpen(false)}
            backMode="transparent"
            back={false}
            inside={
              <>
                {transitions(({ opacity }, item) => {
                  return item && (isOpen || alwaysOn) ? (
                    <a.View
                      style={[
                        {
                          position: 'absolute',
                          backgroundColor: theme.colors.tooltipBg,
                          borderRadius: 3,
                          paddingHorizontal: 8,
                          paddingVertical: 4,
                          opacity,

                          shadowOffset: { width: 0, height: 2 },
                          shadowRadius: 4,
                          shadowColor: 'rgba(0, 0, 0, 0.1)',
                          flexWrap: 'nowrap',
                          flex: 1,
                          ...position,

                          //@ts-ignore
                          pointerEvents: 'none',
                        },

                        size === 'mini' && {
                          paddingHorizontal: 4,
                          paddingVertical: 2,
                        },
                      ]}
                    >
                      <>
                        {imageUri && imageWidth && imageHeight ? (
                          <>
                            <Space vertical={2} />
                            <Image
                              style={{
                                width: imageWidth,
                                height: imageHeight,
                                display: isOpen || alwaysOn ? 'flex' : 'none',
                              }}
                              source={{ uri: imageUri }}
                            />
                          </>
                        ) : null}
                        <Text
                          style={[
                            {
                              fontSize: theme.fontSizes.small,
                              color: theme.colors.secondaryText,
                              flexWrap: 'nowrap',
                            },
                            size === 'mini' && {
                              fontSize: theme.fontSizes.tiny,
                            },
                          ]}
                          numberOfLines={1}
                        >
                          {label}
                        </Text>
                      </>
                    </a.View>
                  ) : null
                })}
              </>
            }
          />
        </WebStyled>
      </View>
    )
  },
)
