// Took from https://github.com/Ajaxy/telegram-tt/blob/32876df6a39cd02c5ab9dc0dd9c588100b400095/src/config.ts#L140
export const RE_LINK_TEMPLATE =
  '((ftp|https?):\\/\\/)?((www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,63})\\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)'

export const ANIMATION_LEVEL_MIN = 0

export const FAST_SMOOTH_MAX_DISTANCE = 1500
export const FAST_SMOOTH_MIN_DURATION = 250
export const FAST_SMOOTH_MAX_DURATION = 600
export const FAST_SMOOTH_SHORT_TRANSITION_MAX_DISTANCE = 500 // px

// URLS
export const TWIVATAR_BASE_URL = `https://usenoor.com/twivatar`
export const TOWER_URL = `https://tower.usenoor.com`
export const WEB_URL = `https://usenoor.com`
export const APP_URL = `https://app.usenoor.com`
export const NIGHTLY_APP_URL = `https://nightly.usenoor.com`
export const SUN_WS_URL = `wss://sun.usenoor.com/v1`

// EMAILS
export const SUPPORT_EMAIL = `support@usenoor.com`
export const FROM_EMAIL = `hey@usenoor.com`
export const TENOR_KEY = `VWEWO4E6AUST`
