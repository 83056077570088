import Sentry from '@there/app/utils/sentry'
import { SUN_WS_URL } from '@there/components/config'
import { Client } from '@there/components/sun/client'
import { getActiveSpaceId, getToken } from '@there/components/utils/get-token'
import { ipc } from '@there/desktop/utils/electron-api'
import { SunErrorCodes } from '@there/sun/ws/errors'
import { createContext, ReactNode, useContext, useEffect } from 'react'

let href =
  typeof window !== 'undefined' ? new URL(window.location.href) : undefined
const devPort = href ? href.searchParams.get('nurPort') || 7005 : 7005
const defaultUrl =
  process.env.NODE_ENV === 'production'
    ? SUN_WS_URL
    : `ws://localhost:${devPort}/v1`

const londonUrl = 'wss://sun.usenoor.com/v1'
const iranUrl = 'wss://ir3.usenoor.com/v1'

const londonSpaceIds = new Set([
  // Noor
  'Space:ckkyg8tdc5136esfliyjzq9e1',
  // Tetherland
  'Space:ckrjpdn2638152eyfjsgmghd55',
  // Inarix
  'Space:ckq6skdwa2585eyh04qvh3hgo',
  // Smokeless
  'Space:ckq6flku82297eyffx1srs1zu',
  // کوه نور
  'Space:ckug0120f9144eygpnsalhsqx',
  // Flightcontrol
  'Space:cl0ppvaia126251ebghnzgy506h',
])

let client: Client | undefined

let lastShownAlertTime: number | undefined

export const createNurClient = async () => {
  let token = await getToken()
  let activeSpaceId = getActiveSpaceId()
  let region = await getRegion()

  let forceUrl = null
  if (typeof localStorage !== 'undefined' && localStorage.getItem('forceSun')) {
    forceUrl = localStorage.getItem('forceSun')
  }

  let useLondon = londonSpaceIds.has(activeSpaceId)
  let url = forceUrl
    ? forceUrl
    : region === 'iran'
    ? iranUrl
    : useLondon
    ? londonUrl
    : defaultUrl

  console.info('region=', region)

  if (client) {
    return client
  } else {
    console.info('creating new client', activeSpaceId)
    client = new Client({
      url,
      connectionParams: {
        token,
        spaceId: activeSpaceId,
      },
      onError: ({ SunError, networkError }) => {
        if (
          SunError &&
          (SunError.code === SunErrorCodes.NOT_AUTHORIZED ||
            SunError.code === SunErrorCodes.CONNECTION_ERROR)
        ) {
          console.error('Nur error NOT_AUTHORIZED')
          Sentry.captureMessage('Sun error NOT_AUTHORIZED')
          // doLogout()
        }
      },
    })
    return client
  }
}

let hasWarnedAboutDefault = false

export let NurContext = createContext<Client | undefined>(undefined)

export const NurProvider = (props: { children: ReactNode; value: Client }) => {
  return (
    <NurContext.Provider value={props.value}>
      {props.children}
    </NurContext.Provider>
  )
}

export const useNurClient = () => {
  let client = useContext(NurContext)

  useEffect(() => {
    if (!client) {
      // log only once
      if (hasWarnedAboutDefault) return
      console.warn(
        'Nur client was not provided. Use NurContext.Provider. If it is intentional, ignore.',
      )
      hasWarnedAboutDefault = true
    }
  }, [client])

  return client
}

export async function getRegion() {
  if (!ipc && typeof window !== 'undefined') {
    return (
      localStorage.getItem('region') ||
      (window.location.href.includes('ir3.') ? 'iran' : 'global')
    )
  }

  try {
    let region = await ipc?.invoke('get-region')
    return region || 'global'
  } catch (error) {
    console.warn('failed to get region', error)
    return 'global'
  }
}
