import { a, useSpring, useTransition } from '@react-spring/native'
import { useSendNewMessage } from '@there/components/main/useSendNewMessage'
import { logEvent } from '@there/components/shared/use-analytics'
import { useNurNode } from '@there/components/sun/use-node'
import { easeOutCubic } from '@there/components/utils/easings'
import {
  ChatsStateDispatch,
  DraftMessage,
} from '@there/components/v2/useChatsState'
import { useMessageActions } from '@there/components/v2/useMessageActions'
import { ChatPeerId } from '@there/components/v2/useRenderingPhase'
import { DocumentInfo, NewMessageInfo } from '@there/sun/utils/node-types'
import cuid from 'cuid'
import { atom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { ChatEdit } from '../chat/ChatEdit'
import { ChatReply } from '../chat/ChatReply'
import { useKeyContext } from '../feed/KeyContext'
import { useModals } from '../feed/ModalsContext'
import { useTheme } from '../feed/ThemeContext'
import { useOnWindowResize } from '../hooks/useOnWindowResize'
import { usePaste } from '../hooks/usePaste'
import { useAppContext } from '../shared/AppContext'
import { usePickerContext } from '../shared/PickerContext'
import { useSpaceChatsContext } from '../shared/SpaceChatsContext'
import { useUiContext } from '../shared/UiContext'
import { useLatest } from '../shared/use-latest'
import { MaxWidthWeb, useWindowContext } from '../shared/WindowContext'
import { NewChatInput, textInputMinHeight } from './NewChatInput'
import { useChatUploader } from './useFileUploadManager'

type ChatProps = {
  // Null means still loading
  peer: ChatPeerId | null
  draft: DraftMessage | undefined
  chatsStateDispatch: ChatsStateDispatch
  editingMsgId: string | null | undefined
  replyingToMsgId: string | null | undefined
}

export type MessageTypeInput = {
  peerTopicId: string | null
  peerUserId: string | null
  text: string
  mediaObjectId?: string
  mediaType?: 'None' | 'File' | 'Photo' | 'Video'
  thumbObjectId?: string | null
  mediaW?: number
  mediaH?: number
  documentId?: string | null
  document?: DocumentInfo
}

type State = {
  editingMessage: NewMessageInfo | undefined
  replyingMessage: NewMessageInfo | undefined
}

const ChatComposeNode = memo(
  ({
    peer,
    editingMessage,
    replyingMessage,
    chatsStateDispatch,
    draft,
  }: ChatProps & State) => {
    let theme = useTheme()
    let { currentUserId, activeSpaceId, dispatch } = useAppContext()
    let { hasCustomBackground } = useUiContext()
    let { messageLoadingDispatch } = useSpaceChatsContext()
    let { isUploading, uploadFile } = useChatUploader()
    let { isOpen: isPickerOpen, close: closePicker } = usePickerContext()

    let [addedMessage, setAddedMessage] = useState<string | null>(null)

    let [modalsState] = useModals()
    let isModalOpened = useMemo(() => {
      return modalsState.modals.length > 0
    }, [modalsState.modals.length])

    let {
      onEditMessage,
      setReplyMsgId,
      onArrowUp,
      setEditingMsgId,
    } = useMessageActions()

    let peerTopicId = peer?.type === 'Topic' ? peer.id : null
    let peerUserId = peer?.type === 'User' ? peer.id : null

    let { registerKeyEvent, unregisterKeyEvent } = useKeyContext()

    useEffect(() => {
      // If it has message typed don't trigger edit on up
      if (draft?.message) return

      registerKeyEvent({
        id: 'edit-last-message',
        action: () => {
          onArrowUp()
        },
        key: 'ArrowUp',
      })

      return () => {
        unregisterKeyEvent({ id: 'edit-last-message', key: 'ArrowUp' })
      }
    }, [draft?.message, onArrowUp, registerKeyEvent, unregisterKeyEvent])

    // Update recent used emojis
    let updateRecentUsedEmojis = useCallback(
      (emojis: string[]) => {
        dispatch({ type: 'recent emojis updated', value: emojis })
      },
      [dispatch],
    )

    // Send chat message
    let [, doSendNewMessage] = useSendNewMessage()
    const sendChatMessage = useCallback(
      ({
        peerTopicId,
        peerUserId,
        text,
        mediaObjectId,
        mediaType,
        thumbObjectId,
        mediaW,
        mediaH,
        documentId,
        document,
      }: MessageTypeInput) => {
        if (!activeSpaceId || !currentUserId) return

        let messageId = cuid()
        messageLoadingDispatch({
          type: 'new message pending to send',
          id: messageId,
        })
        doSendNewMessage({
          msgId: messageId,
          peerTopicId: peerTopicId || null,
          peerUserId: peerUserId || null,
          spaceId: activeSpaceId,
          text,
          sentAt: Date.now(),
          randomId: String(BigInt(String(Math.random()).replace('0.', ''))),
          replyToMessageId: replyingMessage?.id,
          mediaObjectId,
          mediaType,
          thumbObjectId,
          mediaW,
          mediaH,
          documentId,
          document,
          __extra: {
            senderId: currentUserId,
          },
        }).then(() => {
          messageLoadingDispatch({ type: 'message sent', id: messageId })
          setReplyMsgId(null)
        })

        logEvent('Chat Message Sent')
      },
      [
        activeSpaceId,
        currentUserId,
        messageLoadingDispatch,
        doSendNewMessage,
        replyingMessage?.id,
        setReplyMsgId,
      ],
    )
    let sendChatMessageRef = useLatest(sendChatMessage)
    let setChatMessageAtom = useUpdateAtom(sendChatMessageAtom)
    useEffect(() => {
      setChatMessageAtom({
        sendChatMessage: (...args) => {
          sendChatMessageRef.current?.(...args)
        },
      })
    }, [sendChatMessageRef, setChatMessageAtom])

    // Handle page
    let draftMessageRef = useLatest(draft)
    let setDraftMessageAtom = useUpdateAtom(draftMessageAtom)
    useEffect(() => {
      setDraftMessageAtom(draftMessageRef.current)
    }, [draftMessageRef, setDraftMessageAtom])

    const onPaste = useCallback(
      (file: File) => {
        uploadFile({ file }).then((document) => {
          sendChatMessageRef.current({
            peerTopicId: peerTopicId,
            peerUserId: peerUserId,
            text: draftMessageRef.current?.message || '',
            document,
            documentId: document.id,
          })
        })
      },
      [
        draftMessageRef,
        peerTopicId,
        peerUserId,
        sendChatMessageRef,
        uploadFile,
      ],
    )

    usePaste(onPaste)

    useEffect(() => {
      let handler = async (event: ClipboardEvent) => {
        const data = event.clipboardData
        if (!data) return
        const file: File | undefined = data.files[0]

        if (!file) {
          navigator.clipboard.readText().then((text) => {
            setAddedMessage(text)
          })
          return
        }

        if (
          !['image/jpeg', 'image/png', 'image/gif', 'image/webp'].includes(
            file.type,
          )
        ) {
          return
        }
        event.stopPropagation()
        event.preventDefault()
      }
      document.addEventListener('paste', handler)

      return () => {
        document.removeEventListener('paste', handler)
      }
    }, [
      draftMessageRef,
      peerTopicId,
      peerUserId,
      sendChatMessageRef,
      setDraftMessageAtom,
      uploadFile,
    ])
    // --------------------

    // Edit, reply animation

    let transition = useTransition(
      replyingMessage ? 'reply' : editingMessage ? 'edit' : null,
      {
        from: {
          height: 0,
        },
        leave: {
          height: 0,
        },
        enter: {
          height: 40,
        },
        config: {
          duration: 160,
          easing: easeOutCubic,
        },
      },
    )

    const animateStyles = useSpring({
      loop: { reverse: true },
      config: { duration: 500 },
      to: { backgroundColor: '#beff0b' },
      from: { backgroundColor: 'red' },
    })

    let { isElectron } = useWindowContext()
    let isBrowser = !isElectron
    let [wrapped, setWrapped] = useState(isBrowser)
    useOnWindowResize((size) => {
      isBrowser && size.width > MaxWidthWeb + 200 // 200 is an offset to compensate side size
        ? setWrapped(true)
        : setWrapped(false)
    })

    return (
      <View
        style={[
          {
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 1,
            backgroundColor: hasCustomBackground
              ? 'rgba(0, 0, 0, 0.2)'
              : theme.colors.background,
          },
          wrapped && {
            maxWidth: MaxWidthWeb,
            width: '100%',
            alignSelf: 'center',
            paddingBottom: 10,
            borderTopWidth: 1,
            borderColor: theme.colors.transparentInputBorder,
            paddingTop: 10,
          },
        ]}
      >
        {transition(({ height }, item) => {
          return item ? (
            <a.View
              style={{
                height,
                overflow: 'hidden',
              }}
            >
              {item === 'edit' ? (
                <ChatEdit
                  editingMessage={editingMessage}
                  setEditingMessageId={setEditingMsgId}
                />
              ) : item === 'reply' ? (
                replyingMessage && (
                  <ChatReply
                    replyingMessage={replyingMessage}
                    setReplyMsgId={setReplyMsgId}
                  />
                )
              ) : null}
            </a.View>
          ) : null
        })}

        <View
          style={[
            {
              width: '100%',
              display: 'flex',
              borderTopWidth: 1,
              borderColor: theme.colors.transparentInputBorder,
              // paddingVertical: 3,
              paddingVertical: 3,
              minHeight: textInputMinHeight + 6,
              flexDirection: 'row',
              // alignItems: 'flex-end',
              justifyContent: 'space-between',
              position: 'relative',
            },
            wrapped && {
              borderWidth: 1,
              borderRadius: 100,
            },
          ]}
        >
          {peer && (
            <NewChatInput
              peer={peer}
              draft={draft}
              isModalOpened={isModalOpened}
              chatsStateDispatch={chatsStateDispatch}
              sendChatMessage={sendChatMessage}
              editingMessage={editingMessage}
              replyingMessageId={replyingMessage?.id || null}
              setReplyMsgId={setReplyMsgId}
              setEditingMessageId={setEditingMsgId}
              editMessage={onEditMessage}
              peerUserId={peerUserId}
              onArrowUp={onArrowUp}
              updateRecentUsedEmojis={updateRecentUsedEmojis}
              isEmojiPickerOpen={isPickerOpen}
              closeEmojiPicker={closePicker}
              addedMessage={addedMessage}
            />
          )}
        </View>
        {isUploading && (
          <a.View
            style={[
              {
                width: '100%',
                height: 3,
                backgroundColor: '#f00',
                position: 'absolute',
                top: 0,
                right: 0,
              },
              animateStyles,
            ]}
            children={null}
          />
        )}
      </View>
    )
  },
)

export const ChatCompose = (props: ChatProps) => {
  let [replyingMessage] = useNurNode<NewMessageInfo>({
    id: props.replyingToMsgId || null,
  })
  let [editingMessage] = useNurNode<NewMessageInfo>({
    id: props.editingMsgId || null,
  })

  let state: State = {
    replyingMessage: replyingMessage || undefined,
    editingMessage: editingMessage || undefined,
  }

  return <ChatComposeNode {...props} {...state} />
}

let sendChatMessageAtom = atom<{
  sendChatMessage: (props: MessageTypeInput) => void
}>({
  sendChatMessage: () => {
    console.warn('sendChatMessageAtom called before init')
  },
})
export const useSendChatMessage = () => {
  return useAtomValue(sendChatMessageAtom)
}

let draftMessageAtom = atom<DraftMessage | undefined>(undefined)
export const useDraftMessage = () => {
  return useAtomValue(draftMessageAtom)
}
