// @flow
import React from 'react'
import styled from 'styled-components'

// Utilities
type SCProps = {
  size: number
  fontSize: number
  fontWeight: string
  round?: boolean
}

const Avatar = styled.div<SCProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  vertical-align: middle;
  user-select: none;
  -webkit-user-drag: none;
  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.85);
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  font-size: ${(p) => p.fontSize}px;
  font-weight: ${(p) => p.fontWeight};
  border-radius: ${(p) => (p.round ? `50%` : 'none')};
`

type Props = {
  name: string
  size: number
  round?: boolean
  className?: string
}

export const Initials = ({ round, name, size, ...props }: Props) => {
  const fontSizeDivider = size > 26 ? 2.3 : 1.8
  const fontSize = parseInt((size / fontSizeDivider).toFixed(3), 10)
  // eslint-disable-next-line unicorn/no-nested-ternary
  const fontWeight = '500'

  const nameParts = name.split(' ')
  const isOnePart = nameParts.length === 1

  let text = ''
  if (isOnePart) {
    text = name[0]
  } else if (size > 26 && nameParts.length > 1) {
    // Two part
    text = (name[0] || '') + (nameParts[1][0] || '')
  } else {
    text = name[0] || ''
  }

  return (
    <Avatar
      size={size}
      fontSize={fontSize}
      fontWeight={fontWeight}
      round={round}
      {...props}
    >
      {text}
    </Avatar>
  )
}
