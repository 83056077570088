import { Pressable } from '@there/components/shared/Pressable'
import React, { memo, useEffect, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useTheme } from '../feed/ThemeContext'

export const MainTabButton = ({
  title,
  active,
  onPress,
}: {
  title: string
  active: boolean
  onPress: () => void
}) => {
  const theme = useTheme()

  return (
    <Pressable
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 12,
      }}
      disabled={active}
      onPress={onPress}
    >
      <Text
        style={{
          color: active
            ? theme.colors.secondaryText
            : theme.colors.tertiaryText,
        }}
      >
        {title}
      </Text>
    </Pressable>
  )
}
