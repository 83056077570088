import { View, Image, ScrollView } from 'react-native'
import { Header } from './Header'
import { ModalWrapper } from './ModalWrapper'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAtom } from 'jotai'
import { useDisplayList } from '@there/components/shared/use-input-list'
import { Space } from '@there/components/shared/Space'
import {
  electronApi,
  electronSupports,
  ipc,
  isElectron,
} from '@there/desktop/utils/electron-api'
import { useCurrentMediaDevicesContext } from '@there/components/shared/CurrentMediaDevicesContext'
import { logEvent } from '@there/components/shared/use-analytics'
import { permissionsAtom } from '@there/components/shared/use-media-permissions'
import { useModals } from '@there/components/feed/ModalsContext'
import { rtcCoreAtom, useRtcContext } from '@there/components/shared/use-rtc'
import { ModalLabel } from '@there/components/modal/ModalLabel'
import { ModalTitle } from '@there/components/modal/ModalTitle'
import { Pressable } from '@there/components/shared/Pressable'
import Sentry from '@there/app/utils/sentry'

type Item = {
  source: Electron.DesktopCapturerSource
  // For windows, it's empty
  display: Electron.Display | undefined
}

export const PickSourceModal = () => {
  let [{ modals }, modalsDispatch] = useModals()
  let [{ toggleScreenSharing }] = useAtom(rtcCoreAtom)

  let [{ status, refetchStatus, ensureHasAccess }] = useAtom(permissionsAtom)
  let { dispatch } = useCurrentMediaDevicesContext()

  let isOpen = modals.includes('pickSource')

  let { allDisplays, updateList } = useDisplayList()
  let [sources, setSources] = useState<Electron.DesktopCapturerSource[]>([])
  let fetchSources = useCallback(() => {
    if (electronSupports?.electron18) {
      // New way
      ipc
        ?.invoke('get-sources')
        .then(({ sources }) => {
          if (sources.length === 0) {
            alert('Could not obtain display list. Try again.')
            return
          }
          setSources(sources)
        })
        .catch((error) => {
          alert('Could not obtain display list. Try again.')
          Sentry.captureException(error)
        })
    } else {
      // Old way
      electronApi
        ?.getDesktopCapturerSources()
        .then((sources) => {
          if (sources.length === 0) {
            alert('Could not obtain display list. Try again.')
            return
          }
          setSources(sources)
        })
        .catch((error) => {
          alert('Could not obtain display list. Try again.')
          Sentry.captureException(error)
        })
    }
  }, [])

  useEffect(() => {
    // if (status.hasScreen && isOpen) {
    if (isOpen) {
      fetchSources()
      updateList()
      ensureHasAccess(['screen'])
    }
  }, [fetchSources, status.hasScreen, updateList, isOpen, ensureHasAccess])

  useEffect(() => {
    // IMPORTANT: Always do these as it prompts native screen share permission dialog
    // Fetching here postpones getting permission
    refetchStatus()
  }, [
    refetchStatus,
    // Keep
    isOpen,
  ])

  let onPick = (selectedItem: Item) => {
    if (!isElectron) {
      toggleScreenSharing()
      return
    }

    ensureHasAccess(['screen'])

    // Display Picked
    dispatch({
      type: 'preferred source changed',
      source: selectedItem.source,
    })
    dispatch({
      type: 'preferred display changed',
      display: selectedItem.display,
    })

    // Start screen share
    toggleScreenSharing()

    logEvent('User Toggled Screen Share')

    onClose()
  }

  let items: Item[] = useMemo(() => {
    return sources.map((source) => {
      let display = allDisplays.find(
        (display) => Number(display.id) === Number(source.display_id),
      )

      return { source, display }
    })
  }, [sources, allDisplays])

  const onClose = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'pickSource' })
  }

  // TODO optimize image data urls taking memory

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <ScrollView style={{ width: 260, maxHeight: '90vh' }}>
        <Header label="Pick screen or window" onClose={onClose} />
        <Space vertical={15} />

        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          {/* Show items */}
          {items
            .filter((item) => item.source.id.startsWith('screen'))
            .map((item, index) => {
              return (
                <Pressable
                  key={item.source.name + item.source.id}
                  style={({ hovered }) => [
                    {
                      width: 120 + 8,
                      marginRight: (index + 1) % 2 !== 0 ? 2 : 0,
                      borderRadius: 8,
                      padding: 4,
                    },
                    hovered && { backgroundColor: 'rgba(255,255,255,0.1)' },
                  ]}
                  onPress={() => onPick(item)}
                >
                  {item.source.thumbnail && (
                    <>
                      <Image
                        source={{
                          uri: item.source.thumbnail.toDataURL(),
                          width: 120,
                          height: 90,
                        }}
                        style={{
                          borderRadius: 8,
                          borderColor: 'rgba(255,255,255,0.1)',
                          borderWidth: 1,
                        }}
                      />
                      <Space vertical={4} />
                    </>
                  )}
                  <ModalLabel
                    text={
                      item.display?.internal
                        ? 'Internal display'
                        : item.source.name
                    }
                    style={{ marginLeft: 6 }}
                  />
                </Pressable>
              )
            })}
        </View>

        <Space vertical={12} />

        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          {items
            .filter(
              (item) =>
                item.source.id.startsWith('window') &&
                // :YY indicates self window, :0 means third party apps
                item.source.id.endsWith(':0') &&
                // macOS Monterey
                !item.source.name.includes('StatusIndicator'),
            )
            .map((item, index) => {
              return (
                <Pressable
                  key={item.source.name + item.source.id}
                  style={({ hovered }) => [
                    {
                      width: 84,
                      marginRight: (index + 1) % 3 !== 0 ? 2 : 0,
                      borderRadius: 8,
                      padding: 3,
                      position: 'relative',
                    },
                    hovered && { backgroundColor: 'rgba(255,255,255,0.1)' },
                  ]}
                  onPress={() => onPick(item)}
                >
                  {item.source.thumbnail && (
                    <Image
                      source={{
                        uri: item.source.thumbnail.toDataURL(),
                        width: 78,
                        height: 50,
                      }}
                      style={{
                        borderRadius: 8,
                        borderColor: 'rgba(255,255,255,0.1)',
                        borderWidth: 1,
                      }}
                    />
                  )}
                  {item.source.appIcon && (
                    <Image
                      source={{
                        uri: item.source.appIcon?.toDataURL(),
                        width: 26,
                        height: 26,
                      }}
                      style={{ position: 'absolute', top: 22, left: 2 }}
                    />
                  )}
                  <Space vertical={4} />
                  <ModalLabel
                    text={item.source.name}
                    tiny={true}
                    numberOfLines={2}
                  />
                </Pressable>
              )
            })}
        </View>
      </ScrollView>
    </ModalWrapper>
  )
}
