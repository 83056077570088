import { AvatarInfo, DialogInfo, UserInfo } from '@there/sun/utils/node-types'
import { filterFalsy } from '@there/shared/utilities/filter-falsy'
import { Draft } from 'immer'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useImmerReducer } from 'use-immer'
import { useAppContext } from './AppContext'
import { useUniversalReducer } from './use-universal-reducer'
import { UsersManager } from './UsersContext'
import { useDeepMemo } from '@there/components/shared/useDeepMemo'
import { useNurQuery } from '@there/components/sun/use-query'
import { SpaceQueryReply } from '@there/sun/modules/space'
import { useNurNode } from '@there/components/sun/use-node'
import { SpaceContextManager } from '@there/components/shared/spaceContext'

interface SourceInput {
  type: 'source'
  usersManager: UsersManager
  spaceManager: SpaceContextManager
}

interface MirrorInput {
  type: 'mirror'
}

type Input = SourceInput | MirrorInput

export const useRtcAvatars = (input: Input): State => {
  const { avatarId, activeSpaceId } = useAppContext()

  let usersManager
  let spaceManager
  let isSource = input.type === 'source'

  if (input.type === 'source') {
    usersManager = input.usersManager
    spaceManager = input.spaceManager
  }

  const [[state, dispatch]] = useUniversalReducer<State, Action>(
    useImmerReducer<State, Action>(reducer, initialState),
    { key: 'rtc-avatars', isSource, enableInitialHydrate: true },
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      //@ts-ignore
      window.rtcAvatars = state
    }
  }, [state])

  // const spaceGetDialog = spaceManager ? spaceManager[0].getDialog : undefined
  const currentDialogIdInCurrentSpace = spaceManager
    ? spaceManager[0].currentDialog?.id
    : undefined
  // const getAvatar = spaceManager ? spaceManager[0].getAvatar : undefined
  const spaceGetDialogAvatars = spaceManager
    ? spaceManager[0].getDialogAvatars
    : undefined
  const getUserFromManager = usersManager ? usersManager.getUser : undefined

  // Multi-space
  let [avatar] = useNurNode<AvatarInfo>({ id: avatarId })
  let [dialog] = useNurNode<DialogInfo>({ id: avatar?.dialogId })
  let dialogId = currentDialogIdInCurrentSpace || avatar?.dialogId
  let currentDialog = dialog

  // To show the feed
  const [{ data, error, fetching, stale }, refetch] = useNurQuery<
    SpaceQueryReply
  >({
    method: 'space',
    variables: { spaceId: dialog?.spaceId },
    pause: !isSource || !dialog?.spaceId,
    // Has bug
    fetchPolicy: 'cache-only',
  })

  let sameSpace = dialog?.spaceId && activeSpaceId === dialog?.spaceId
  //              dialog?.spaceId && spaceManager?.[0].space?.id === dialog?.spaceId

  const dialogAvatars = useMemo(() => {
    if (!dialogId) return
    // if (!spaceGetDialogAvatars) return
    // if (sameSpace && spaceGetDialogAvatars) {
    //   let avatars = spaceGetDialogAvatars(dialogId)

    //   if (avatars) {
    //     return avatars
    //   }
    // }

    let allAvatars = data?.space.avatars
    if (!allAvatars) return []

    return allAvatars.filter((avatar) => avatar.dialogId === dialogId)
  }, [data?.space.avatars, dialogId])

  const getUser = useCallback(
    (id: string): UserInfo | undefined => {
      if (!getUserFromManager) return undefined

      return getUserFromManager(id)
    },
    [getUserFromManager],
  )

  // get list of participants from cache
  let rtcAvatars = useMemo((): [
    RtcAvatar[],
    string[],
    DialogInfo | undefined,
    TopicInfoMin | undefined,
  ] => {
    if (!avatar || !avatar.dialogId || !currentDialog || !dialogAvatars)
      return [[], [], undefined, undefined]

    const ourAvatar = avatar

    if (!dialogAvatars || dialogAvatars.length === 0) {
      console.warn('No avatars in current dialog', ourAvatar.dialogId)
    }

    let avatarWithUser: RtcAvatar[] = dialogAvatars
      .map((avatar) => {
        const userInfo = avatar.userId ? getUser(avatar.userId) : undefined
        if (!userInfo || !avatar.userId) {
          console.error('userInfo not found in rtc avatar', avatar)
          return false
        }
        return {
          ...avatar,
          userId: avatar.userId || '',
          user: {
            id: userInfo?.id,
            name: userInfo?.name,
            profilePhoto: userInfo?.profilePhoto,
            online: userInfo?.online ?? false,
            talking: userInfo?.talking ?? false,
            sharing: userInfo?.sharing ?? false,
            focused: userInfo?.focused ?? false,
          },
        }
      })
      .filter(filterFalsy)

    let notFilteredUserIds = [
      ...new Set(dialogAvatars.map((a) => a.userId).filter(filterFalsy)),
    ]

    return [avatarWithUser, notFilteredUserIds, currentDialog, undefined]
  }, [avatar, currentDialog, dialogAvatars, getUser])

  /** Super nice new thing to only change these when they actually change */
  const [avatars, avatarUserIds, parentNode, topic] = useDeepMemo(
    () => rtcAvatars,
    [rtcAvatars],
  )

  // useEffect for adding new participants
  useEffect(() => {
    if (input.type == 'mirror') return
    if (!currentDialog) {
      dispatch({
        type: 'left',
      })
      return
    }
    let nodeId = dialogId || undefined

    dispatch({
      type: 'avatars updated',
      avatars,
      avatarUserIds,
      nodeId,
      topicTitle: topic?.title,
      dialog: currentDialog,
      topicIcon: topic?.iconEmoji,
    })
  }, [
    avatarUserIds,
    avatars,
    currentDialog,
    dialogId,
    dispatch,
    input.type,
    parentNode,
    topic,
  ])

  return state
}

export interface RtcAvatar extends AvatarInfo {
  userId: string
  user: {
    id: string
    name: string | undefined | null
    profilePhoto: string | undefined | null
    online: boolean | undefined
    talking: boolean | undefined
    sharing: boolean | undefined
    focused: boolean | undefined
  }
}

export interface TopicInfoMin {
  title: string | undefined
  iconEmoji: string | undefined
}

type State = {
  avatars: RtcAvatar[]
  nodeId: string | undefined
  avatarUserIds: string[]
  topic: TopicInfoMin | undefined
  dialog: DialogInfo | undefined
}

export type RtcAvatars = State

type Action =
  | {
      type: 'avatars updated'
      avatars: RtcAvatar[]
      nodeId: string | undefined
      avatarUserIds: string[]
      topicTitle: string | undefined
      topicIcon: string | undefined
      dialog: DialogInfo | undefined
    }
  | {
      type: 'load'
      state: State
    }
  | { type: 'left' }

const reducer = (draft: Draft<State>, action: Action): State | void => {
  switch (action.type) {
    case 'avatars updated':
      draft.avatars = action.avatars
      draft.avatarUserIds = action.avatarUserIds
      draft.nodeId = action.nodeId
      draft.topic = {
        title: action.topicTitle,
        iconEmoji: action.topicIcon,
      }
      draft.dialog = action.dialog
      break
    case 'load':
      return action.state
      break
    case 'left':
      return initialState
      break
    default:
      break
  }
}

const initialState: State = {
  avatars: [],
  nodeId: undefined,
  avatarUserIds: [],
  topic: undefined,
  dialog: undefined,
}

export const RtcAvatarsContext = createContext<State>(initialState)
RtcAvatarsContext.displayName = 'RtcAvatarsContext'
export const useRtcAvatarsContext = () => useContext<State>(RtcAvatarsContext)
