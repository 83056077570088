import { View } from 'react-native'
import { MicrophoneButton } from '@there/components/v2/MicrophoneButton'
import { Space } from '@there/components/shared/Space'
import { ScreenShareButton } from '@there/components/v2/ScreenShareButton'
import { CameraButton } from '@there/components/v2/CameraButton'
import { PickerButton } from '@there/components/v2/PickerButton'
import { useRtcContext } from '../shared/use-rtc'
import { ProfilePhoto } from './ProfilePhoto'
import { LeaveButton } from '@there/components/v2/LeaveButton'
import { useTransition, a } from '@react-spring/native'
import { RtcAvatar } from '../shared/use-rtc-avatars'
import { useAppContext } from '../shared/AppContext'
import { useTheme } from './ThemeContext'
import {
  NewMessageInfo,
  StatusInfo,
  UserInfo,
} from '@there/sun/utils/node-types'
import { Fragment, memo, useCallback, useEffect, useRef } from 'react'
import { useSoundEffect } from '@there/components/feed/useSoundEffect'
import { Pressable } from '@there/components/shared/Pressable'
import { ScreenShareIcon } from '@there/components/shared/ScreenShareIcon'
import { WebkitAppRegion } from '@there/components/shared/WebStyled'
import { Tooltip } from '@there/components/shared/Tooltip'
import { topBarDarkBg } from '@there/shared/desktop/windows-config'
import { motion } from 'framer-motion'
import { usePhotoViewers } from '@there/components/photoViewer/state'
import { useNurNode } from '@there/components/sun/use-node'
import { EmojiView } from '@there/components/main/EmojiView'
import { useMessageActions } from '@there/components/v2/useMessageActions'
import { useModals } from '@there/components/feed/ModalsContext'
import { Svg, Rect, Circle, Polyline } from 'react-native-svg'
import { ConnectionIndicator } from './ConnectionIndicator'

export const NewCallBar = memo(() => {
  let { isInCall, participants } = useRtcContext()

  let showCallBar = participants.length > 0
  // let showCallBar = isInCall && participants.length > 0

  let transitions = useTransition(showCallBar, {
    from: {
      opacity: 0,
      scale: 0.9,
    },
    enter: {
      opacity: 1,
      scale: 1,
    },
    leave: {
      opacity: 0,
      scale: 0.9,
    },
    config: {
      mass: 0.4,
      tension: 400,
      friction: 22,
    },
  })

  // if (!showCallBar) return null
  return (
    <>
      {transitions(({ scale, opacity }, item) => {
        return item ? (
          <a.View
            key="callbar"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              opacity,
            }}
          >
            <ConnectionIndicator />
            <Space horizontal={5} />
            <CallBarAvatars
              participants={participants}
              show={true}
              // show={contentView !== 'rooms'}
            />

            <Space horizontal={2} />

            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <PickerButton />

              <Space horizontal={2} />
              <CameraButton />

              <Space horizontal={2} />
              <ScreenShareButton />

              <Space horizontal={2} />
              <MicrophoneButton />

              <Space horizontal={6} />

              <LeaveButton />
            </View>
            <Space horizontal={8} />
          </a.View>
        ) : (
          <Fragment key="picker">
            <PickerButton />
            <Space horizontal={8} />
          </Fragment>
        )
      })}
    </>
  )
})

const CallBarAvatars = ({
  show,
  participants,
}: {
  show: boolean
  participants: RtcAvatar[]
}) => {
  let { walkieState, joinParticipantScreen } = useRtcContext()
  let { currentUserId } = useAppContext()
  let theme = useTheme()

  return (
    <motion.div
      variants={{
        hidden: { x: 12, opacity: 0, scale: 0.9 },
        shown: { x: 0, opacity: 1, scale: 1 },
      }}
      initial={'hidden'}
      animate={show ? 'shown' : 'hidden'}
      style={{ overflow: 'hidden' }}
      transition={{ duration: 0.15, ease: 'easeOut' }}
    >
      <View
        style={{
          // width: 3 * (26 + 4),
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {[...participants]
          .sort((a, b) => {
            if (a.userId === currentUserId) return 1
            if (b.userId === currentUserId) return -1
            if (b.user.talking) return -1
            if (a.user.talking) {
              return 1
            }
            return 0
          })
          .slice(0, 4)
          .map((participant) => {
            let isUs = participant.userId === currentUserId
            let participantState = walkieState.participants[participant.userId]
            // if (!participantState && !isUs) return null
            let isTalking = Boolean(
              isUs ? walkieState.weTalking : participantState?.isTalking,
            )
            let isUserSharing = Boolean(
              isUs
                ? walkieState.weSharingScreen
                : participantState?.isSharingScreen,
            )

            return (
              <TinyPerson
                key={participant.id}
                callId={walkieState.callId}
                isUserSharing={isUserSharing}
                isTalking={isTalking}
                size={22}
                user={participant.user}
                isUs={isUs}
                joinParticipantScreen={joinParticipantScreen}
              />
            )
          })}
      </View>
    </motion.div>
  )
}

type TinyPersonProps = {
  callId: string | undefined
  isTalking: boolean
  isUserSharing: boolean
  user: UserInfo
  size: number
  isUs: boolean
  joinParticipantScreen: (userId: string) => void
}

function TinyPerson({
  callId,
  isTalking,
  isUserSharing,
  size,
  user,
  isUs,
  joinParticipantScreen,
}: TinyPersonProps) {
  let theme = useTheme()
  let borderWidth = 2
  let wrapperSize = size + borderWidth * 2

  let [screenShareSound] = useSoundEffect('/tones/share-ding.mp3', {
    volumeMultiplier: 0.5,
  })

  const onJoinScreenShare = () => {
    console.info('Joining user screen')
    joinParticipantScreen(user.id)
  }

  // Screen share ding
  let shouldPlay = useRef(false)
  useEffect(() => {
    // when you left the person in header will ding again
    if (isUs) return
    if (!callId) {
      shouldPlay.current = false
    }

    if (isUserSharing && shouldPlay.current) {
      screenShareSound()
    }

    // Pre-Condition
    shouldPlay.current = callId ? true : false
  }, [isUserSharing, screenShareSound, callId, isUs])

  // -----------------------------
  // isViewingPhoto
  let {
    usersByMessageId,
    viewingMessageId: ourViewingMessageId,
  } = usePhotoViewers()
  let viewingMsgId: string | undefined
  for (let [messageId, userIds] of Object.entries(usersByMessageId)) {
    if (!userIds?.includes(user.id)) continue
    viewingMsgId = messageId
  }

  if (isUs && ourViewingMessageId) {
    viewingMsgId = ourViewingMessageId
  }

  let [viewingMsg] = useNurNode({
    id: viewingMsgId || null,
  })

  // If we have access to msg
  let isViewingPhoto = !!viewingMsg
  // -----------------------------

  // Go to message
  let [, modalsDispatch] = useModals()

  const goToMessage = () => {
    if (!viewingMsg) return
    modalsDispatch({
      type: 'modal opened',
      modalData: viewingMsg.senderId
        ? { message: viewingMsg as NewMessageInfo, type: 'message photo' }
        : { type: 'status photo', status: viewingMsg as StatusInfo },
      modalName: 'viewPhoto',
    })
  }

  let onPress =
    isUserSharing && !isUs
      ? onJoinScreenShare
      : isViewingPhoto
      ? goToMessage
      : undefined

  return (
    <WebkitAppRegion
      mode="no-drag"
      style={{
        marginRight: 2,
      }}
    >
      <View style={{ position: 'relative', width: wrapperSize }}>
        <View
          key={user.id}
          style={{
            width: wrapperSize,
            height: wrapperSize,
            borderRadius: wrapperSize,
            borderWidth: borderWidth,
            borderColor: isTalking
              ? theme.colors.green
              : theme.colors.transparent,
          }}
        >
          <ProfilePhoto onPress={onPress} user={user} size={size} />
        </View>

        {isUserSharing && (
          <Tooltip
            label={isUs ? 'Sharing screen' : 'View shared screen'}
            placement="bottomCenter"
          >
            <Pressable
              style={{
                position: 'absolute',
                bottom: 0,
                right: 1,
                height: 15,
                width: 15,
                borderRadius: 6,
                backgroundColor: topBarDarkBg,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={onPress}
            >
              <ScreenShareIcon
                height={8}
                color={theme.colors.screenShareButtonIcon}
              />
            </Pressable>
          </Tooltip>
        )}

        {!isUserSharing && isViewingPhoto && (
          <Tooltip label={'Viewing photo'} placement="bottomCenter">
            <Pressable
              style={{
                position: 'absolute',
                bottom: 0,
                right: 1,
                height: 15,
                width: 15,
                borderRadius: 6,
                backgroundColor: topBarDarkBg,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={onPress}
            >
              <EmojiView children="🖼️" size={12} />
            </Pressable>
          </Tooltip>
        )}
      </View>
    </WebkitAppRegion>
  )
}
