import { Cache, ResolveInfo } from '@urql/exchange-graphcache'
import {
  Data as UpdateSpaceData,
  Variables as UpdateSpaceVariables,
} from '@there/components/urql/updateSpace'
import { spaceInfoFragment } from './fragments/spaceInfo'

type CustomUpdateResolver = (
  result: any,
  args: any,
  cache: Cache,
  info: ResolveInfo,
) => void

export const mutationUpdates: {
  [fieldName: string]: CustomUpdateResolver
} = {
  updateSpace: (
    result: UpdateSpaceData,
    args: UpdateSpaceVariables,
    cache: Cache,
  ) => {
    if (!result.space) return
    cache.writeFragment(spaceInfoFragment, result.space)
  },
}
