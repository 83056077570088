import { useTheme } from '@there/components/feed/ThemeContext'
import { EmojiView } from '@there/components/main/EmojiView'
import { DeleteIcon } from '@there/components/main/RoomMenuIcons'
import { ChatAvatar } from '@there/components/shared/ChatAvatar'
import { Space } from '@there/components/shared/Space'
import { ChatPeer } from '@there/components/sidebar/SideContent'
import { useNurQuery } from '@there/components/sun/use-query'
import { useNurSubscription } from '@there/components/sun/use-subscription'
import { useChatContext } from '@there/components/v2/useChatsState'
import { useMessageActionsProvider } from '@there/components/v2/useMessageActions'
import {
  ChatPeerId,
  useRenderingPhase,
} from '@there/components/v2/useRenderingPhase'
import { getShortName } from '@there/shared/utilities/get-short-name'
import { NewChatMessage, NewChatQueryReply } from '@there/sun/modules/newChat'
import { NewUpdateMessage } from '@there/sun/modules/newUpdate'
import { MessageActionType } from '@there/sun/modules/sendMessageActionUtils'
import { NewMessageInfo } from '@there/sun/utils/node-types'
import { useSelect } from 'downshift'
import { useUpdateAtom } from 'jotai/utils'
import ms from 'ms'
import * as React from 'react'
import { Text, View } from 'react-native'
import Svg, { Path, SvgProps } from 'react-native-svg'
import { activeNewChatPeerAtom } from '../atoms/chatAtom'
import { Dropdown, PickerItem } from '../feed/Dropdown'
import { ThreeDotsIcon } from '../feed/HeaderIcons'
import { useModals } from '../feed/ModalsContext'
import { BellIcon } from '../main/BellIcon'
import { getRelativeDate } from '../main/get-last-seen'
import { CrossIcon } from '../main/mainIcons'
import { useDeleteChat } from '../main/useDeleteChat'
import { useEditChat } from '../main/useEditChat'
import { useAlert } from '../modal/use-alert'
import { useConfirm } from '../modal/use-confirm'
import { useAppContext } from '../shared/AppContext'
import { GoodButton } from '../shared/GoodButton'
import { Pressable } from '../shared/Pressable'
import { useSpaceChatsContext } from '../shared/SpaceChatsContext'
import { useSpaceContext } from '../shared/spaceContext'
import { Tooltip } from '../shared/Tooltip'
import { useLatest } from '../shared/use-latest'
import { useMainWindowContext } from '../shared/use-main-window'
import { useUsersContext } from '../shared/UsersContext'
import { ChatCompose } from './ChatCompose'
import { FileDropRegion } from './FileDropRegion'
import { MuteIcon } from './MuteIcon'
import { NewChat } from './NewChat'

const CHAT_HEADER_HEIGHT = 48
export const ChatView = () => {
  let { state: chatsState, dispatch: chatsStateDispatch } = useChatContext()
  let activeChatPeer = chatsState.activeChatPeer

  // Manage rendering state of underlying chat components
  let [renderingPhase, dispatch] = useRenderingPhase()

  React.useEffect(() => {
    if (activeChatPeer) {
      dispatch({
        type: 'new chat requested',
        peer: {
          type: activeChatPeer.peerTopicId ? 'Topic' : 'User',
          // @ts-ignore
          id: activeChatPeer.peerTopicId || activeChatPeer.peerUserId,
        },
      })
    }
  }, [activeChatPeer, dispatch])

  const renderedPeer = renderingPhase.pages.find((p) => p.rendering)?.peer
  const chatState = renderedPeer ? chatsState.byPeerId[renderedPeer.id] : null

  let [messageListResult, , fetchMore] = useMessageList(renderedPeer || null)

  let { data, fetching, error, stale } = messageListResult
  let messages = React.useMemo(() => data?.messages || [], [data])

  const editingMsgId = chatState?.editingMsgId
  const replyingToMsgId = chatState?.replyingToMsgId
  const draft = chatState?.draft

  useMessageActionsProvider({
    peer: renderedPeer || null,
    chatsStateDispatch,
    messages,
  })

  return (
    <View
      style={{
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {renderedPeer ? (
        <ChatViewHeader
          peer={renderedPeer}
          // to fix bug that shows typing on all - t-111 in height
          key={renderedPeer.id}
        />
      ) : (
        // Required for scroll to work properly
        <View style={{ height: CHAT_HEADER_HEIGHT }} />
      )}

      {/* file Drop region */}
      <FileDropRegion
        peerTopicId={activeChatPeer.peerTopicId}
        peerUserId={activeChatPeer.peerUserId}
      >
        {/* Wrapper around chat views, both rendered, and one that is in preparing stage */}
        <View
          style={{
            flex: 1,
            position: 'relative',
          }}
        >
          {renderingPhase.pages.map((page) => (
            <NewChat
              key={page.peer.id}
              peer={page.peer}
              dispatch={dispatch}
              hidden={!page.rendering}
              chatState={chatsState.byPeerId[page.peer.id]}
            />
          ))}
        </View>

        <ChatCompose
          peer={renderedPeer || null}
          editingMsgId={editingMsgId}
          replyingToMsgId={replyingToMsgId}
          draft={draft}
          chatsStateDispatch={chatsStateDispatch}
        />
      </FileDropRegion>
    </View>
  )
}

export const useMessageList = (peer: ChatPeerId | null) => {
  let peerTopicId = peer?.type == 'Topic' ? peer.id : undefined
  let peerUserId = peer?.type == 'User' ? peer.id : undefined

  return useNurQuery<NewChatQueryReply, NewChatMessage['payload']>({
    method: 'newChat',
    variables: {
      peerTopicId: peerTopicId || null,
      peerUserId: peerUserId || null,
      addOffset: 50,
    },
    pause: !peer,
  })
}

export type GetMessage = (messageId: string) => NewMessageInfo | undefined

const ChatViewHeader = React.memo(({ peer }: { peer: ChatPeerId }) => {
  let setActiveChatPeer = useUpdateAtom(activeNewChatPeerAtom)
  let { dispatch } = useMainWindowContext()
  let { getUser, currentUser } = useUsersContext()
  let theme = useTheme()
  let [, modalsDispatch] = useModals()
  let [, doEditChat] = useEditChat()
  let [{ space }] = useSpaceContext()
  let { activeSpaceId, currentUserId } = useAppContext()
  let { getChatByPeerId, getTopic } = useSpaceChatsContext()

  let peerTopicId = peer.type === 'Topic' ? peer.id : null
  let peerUserId = peer.type === 'User' ? peer.id : null
  let activeTopic = peerTopicId ? getTopic(peerTopicId) : null

  let activeChat = getChatByPeerId(peerTopicId || peerUserId || '')

  let owner = getUser(activeTopic?.ownerId || '')
  let peerUser = peerUserId ? getUser(peerUserId) : null
  let isSelf = peerUserId === currentUser?.id

  // MESSAGE ACTION START
  let [messageAction, setMessageAction] = React.useState<MessageActionType>(
    MessageActionType.Cancel,
  )
  let actionTimeoutId = React.useRef<number>(0)

  // Message action for user
  useNurSubscription<NewUpdateMessage>({
    method: 'newUpdate',
    onData: React.useCallback(
      ({ data }) => {
        if (
          data.updates[0].event === 'updateMessageAction' &&
          peerUserId === data.updates[0].userId
        ) {
          // set
          setMessageAction(data.updates[0].action)

          // timeout after 6s
          if (actionTimeoutId.current) clearTimeout(actionTimeoutId.current)
          actionTimeoutId.current = setTimeout(() => {
            setMessageAction(MessageActionType.Cancel)
          }, ms('6s'))
        }
      },
      [peerUserId],
    ),
  })

  // MESSAGE ACTION END

  let fullPeer: ChatPeer = {
    topic: activeTopic || null,
    user: peerUser || null,
  }

  let isTopic = Boolean(peerTopicId)
  let isSpaceAdmin = space?.creatorId === currentUserId
  let isChatAdmin = isTopic && activeTopic?.ownerId === currentUserId
  let [, doDeleteChat] = useDeleteChat()

  let { openModal } = useConfirm({
    alertIcon: <ChatAvatar peer={fullPeer} size={38} />,
    alertText: `${activeTopic?.title}`,
    alertDescription: `Are you sure you want ${
      isChatAdmin || isSpaceAdmin ? 'delete' : 'leave'
    } this group?`,
    submitLabel: 'Leave',
    cancelLabel: 'Stay',
  })

  let topicOnlyMenuItems = isTopic
    ? [
        {
          label: isChatAdmin || isSpaceAdmin ? 'Delete Group' : 'Leave Group',
          icon: (
            <DeleteIcon
              color={theme.colors.tertiaryText}
              width={20}
              height={20}
            />
          ),
          onClick: () => {
            openModal()
              .then(() => {
                if (!activeChat || !activeChat.peerTopicId || !activeSpaceId)
                  return
                doDeleteChat({
                  peerTopicId: activeChat.peerTopicId,
                  activeSpaceId,
                })
                dispatch({ type: 'change mode', mode: 'room' })
                setActiveChatPeer({})
              })
              .catch(() => {})
          },
        },
      ]
    : []

  let roomPicker: PickerItem[] = [
    {
      label: activeChat?.muted ? 'Unmute' : 'Mute',
      icon: (
        <BellIcon color={theme.colors.tertiaryLight} width={20} height={20} />
      ),
      onClick: () => {
        if (!activeChat) return
        if (!activeSpaceId) return
        doEditChat({
          activeSpaceId,
          peerTopicId: activeChat.peerTopicId,
          peerUserId: activeChat.peerUserId,
          muted: !Boolean(activeChat.muted),
        })
      },
    },
    ...topicOnlyMenuItems,
  ]

  const {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    toggleMenu,
    closeMenu,
  } = useSelect({
    items: roomPicker,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return
      if (selectedItem.onClick) {
        selectedItem.onClick()
      }
    },
  })

  let { openModal: openAlertModal } = useAlert({
    alertIcon: <EmojiView size={38}>🥺</EmojiView>,
    alertText: 'Chat is not available',
    submitLabel: 'Close',
  })
  let groupDeleted = Boolean(peerTopicId && !activeChat)

  let deleteAlert = React.useCallback(() => {
    openAlertModal()
      .then(() => {
        dispatch({ type: 'change mode', mode: 'room' })
        setActiveChatPeer({})
      })
      .catch(() => {
        dispatch({ type: 'change mode', mode: 'room' })
        setActiveChatPeer({})
      })
  }, [dispatch, openAlertModal, setActiveChatPeer])

  let deleteAlertRef = useLatest(deleteAlert)

  React.useEffect(() => {
    if (!groupDeleted || !deleteAlertRef || !deleteAlertRef.current) return
    deleteAlertRef.current()
  }, [deleteAlertRef, groupDeleted])

  return (
    <View
      style={{
        width: '100%',
        height: CHAT_HEADER_HEIGHT,
        borderBottomColor: theme.colors.separatorLine,
        borderBottomWidth: 1,

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 12,
        }}
      >
        <Pressable
          onPress={() => {
            dispatch({ type: 'change mode', mode: 'room' })
            setActiveChatPeer({})
          }}
          style={({ hovered, pressed }) => [{ padding: 5, paddingLeft: 0 }]}
        >
          {({ hovered, pressed }) => (
            <CrossIcon
              color={
                pressed
                  ? theme.colors.quaternaryText
                  : theme.colors.quaternaryText
              }
            />
          )}
        </Pressable>

        <View style={{ marginLeft: 4 }}>
          <ChatAvatar peer={fullPeer} isSelf={isSelf} size={30} />
          {/* <ProfilePhoto user={peerUser} size={30} /> */}
        </View>

        <View
          style={{
            marginLeft: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Pressable
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            disabled={!activeTopic?.title}
            onPress={() => {
              let topic = activeTopic
              if (!topic) return
              modalsDispatch({
                type: 'modal opened',
                modalName: 'editTopic',
                modalData: { topicId: topic.id, topic },
              })
            }}
          >
            <Text
              style={{
                fontSize: theme.fontSizes.large,
                color: theme.colors.secondaryText,
                lineHeight: 19,
              }}
            >
              {activeTopic?.title ||
                (isSelf ? 'Saved Messages' : null) ||
                (peerUser
                  ? peerUser.name || peerUser.nickname || peerUser.email
                  : '')}
            </Text>
            {!isSelf && activeChat?.muted ? (
              <MuteIcon
                width={14}
                height={14}
                style={{ marginLeft: 5 }}
                color={theme.colors.quaternaryText}
              />
            ) : null}
          </Pressable>

          {!isSelf ? (
            <Text
              style={{
                fontSize: theme.fontSizes.small,
                color: theme.colors.tertiaryText,
              }}
            >
              {peerUser ? (
                <>
                  {/* Status */}
                  {messageAction !== MessageActionType.Cancel
                    ? messageAction === MessageActionType.Typing
                      ? 'typing...'
                      : ''
                    : peerUser.online
                    ? 'online'
                    : peerUser.lastOnline
                    ? `last seen ${getRelativeDate(
                        peerUser.lastOnline,
                      )?.toLowerCase()}`
                    : 'offline'}
                </>
              ) : (
                <>
                  {owner ? `By ${getShortName(owner)}` : ``}{' '}
                  {getRelativeDate(activeTopic?.createdAt)}
                </>
              )}
            </Text>
          ) : null}
        </View>
      </View>
      <View style={{ paddingRight: 12, display: isSelf ? 'none' : 'flex' }}>
        <Dropdown.Root>
          <Dropdown.Toggle onPress={() => toggleMenu()}>
            {({ onPress }) => (
              <Tooltip label="Open menu" placement="bottomCenter">
                <GoodButton
                  variant="transparent"
                  active={isOpen}
                  size="normal"
                  onPress={onPress}
                  icon={<ThreeDotsIcon color={theme.colors.quaternaryLight} />}
                  {...getToggleButtonProps(
                    { refKey: 'nokey' },
                    { suppressRefError: true },
                  )}
                />
              </Tooltip>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu
            {...getMenuProps({ refKey: 'nokey' }, { suppressRefError: true })}
            isOpen={isOpen}
            onClose={closeMenu}
            width={165}
          >
            <Dropdown.Section>
              <Space vertical={4} />
              {roomPicker.map((item, index) => {
                return (
                  <View
                    key={item.label}
                    {...getItemProps({
                      item,
                      index,
                    })}
                  >
                    <Dropdown.ItemWithIcon onPress={item.onClick}>
                      {item.icon}
                      <Text
                        style={{
                          fontSize: theme.fontSizes.normal,
                          color: theme.colors.tertiaryText,
                          flex: 1,
                          marginLeft: 5,
                        }}
                      >
                        {item.label}
                      </Text>
                    </Dropdown.ItemWithIcon>
                  </View>
                )
              })}
              <Space vertical={4} />
            </Dropdown.Section>
          </Dropdown.Menu>
        </Dropdown.Root>
      </View>
    </View>
  )
})

const LeftArrowIcon = (props: SvgProps) => {
  return (
    <Svg width={10} height={20} fill="none" viewBox="0 0 10 20" {...props}>
      <Path
        d="M9 1.5L1.2 9.3a1 1 0 000 1.4L9 18.5"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Svg>
  )
}
