import React from 'react'
import {
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewProps,
} from 'react-native'
import { useTheme } from '@there/components/feed/ThemeContext'
import { ThemeType } from '@there/components/utils/theme'

interface ThemedProps {
  backgroundColor?: keyof ThemeType['colors']
  borderColor?: keyof ThemeType['colors']
  borderBottomColor?: keyof ThemeType['colors']
  borderBottomWidth?: number | undefined
  padding?: keyof ThemeType['spaces']
  marginHorizontal?: keyof ThemeType['spaces']
  paddingHorizontal?: keyof ThemeType['spaces']
  borderRadius?: keyof ThemeType['borderRadius']
}

interface OurViewProps extends ViewProps, ThemedProps {}
interface ThemedViewProps extends OurViewProps {
  children?: React.ReactNode
}

interface OurTouchableOpacityProps extends TouchableOpacityProps, ThemedProps {}
export interface ThemedTouchableOpacityProps extends OurTouchableOpacityProps {
  children?: React.ReactNode
}

function useThemedProps<ComponentProps extends ThemedProps>(
  props: ComponentProps,
) {
  let t = useTheme()
  let {
    backgroundColor,
    padding,
    borderBottomColor,
    borderBottomWidth,
    borderColor,
    borderRadius,
    marginHorizontal,
    paddingHorizontal,
    ...componentProps
  } = props

  return [
    [
      backgroundColor && { backgroundColor: t.colors[backgroundColor] },
      borderBottomColor && { borderBottomColor: t.colors[borderBottomColor] },
      borderColor && { borderColor: t.colors[borderColor] },
      padding && { padding: t.spaces[padding] },
      marginHorizontal && { marginHorizontal: t.spaces[marginHorizontal] },
      paddingHorizontal && { paddingHorizontal: t.spaces[paddingHorizontal] },
      borderRadius && { borderRadius: t.borderRadius[borderRadius] },
      typeof borderBottomWidth === 'number' && {
        borderBottomWidth: borderBottomWidth || undefined,
      },
    ],
    componentProps,
  ]
}

export const ThemedView = (props: ThemedViewProps) => {
  let [themedStyle, componentProps] = useThemedProps<ThemedViewProps>(props)
  return <View {...componentProps} style={[themedStyle, props.style]} />
}

export const ThemedTouchableOpacity = (props: ThemedTouchableOpacityProps) => {
  let [themedStyle, componentProps] = useThemedProps<
    ThemedTouchableOpacityProps
  >(props)
  return (
    <TouchableOpacity {...componentProps} style={[themedStyle, props.style]} />
  )
}
