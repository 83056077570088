import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export const MoveIcon = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 2A1.5 1.5 0 0120 3.5v19a1.5 1.5 0 01-3 0v-19A1.5 1.5 0 0118.5 2zm-6 4A1.5 1.5 0 0114 7.5v12a1.5 1.5 0 01-3 0v-12A1.5 1.5 0 0112.5 6zm-6 5A1.5 1.5 0 018 12.5v2a1.5 1.5 0 01-3 0v-2A1.5 1.5 0 016.5 11z"
        fill={props.color ? props.color : '#000'}
      />
    </Svg>
  )
}
