import { useCallback, useEffect } from 'react'
import { ipc } from '@there/desktop/utils/electron-api'
import { useAppContext } from '../../shared/AppContext'

const useClearCacheAction = (props?: { notifyViaIpc?: boolean }) => {
  const { purgeState } = useAppContext()

  const clearLocalCache = useCallback(async () => {
    console.info('Clearing cache...')

    // Clear app context
    purgeState()

    // Remove token (legacy)
    localStorage.clear()
    localStorage.setItem('urql', '{}')
    localStorage.setItem('urqlMetaData', '{}')
    window.location.assign('/login')
  }, [purgeState])

  return clearLocalCache
}

export const useClearCacheListener = () => {
  const clearCache = useClearCacheAction({ notifyViaIpc: false })

  useEffect(() => {
    ipc?.addListener('clear-cache', clearCache)

    return () => {
      ipc?.removeListener('clear-cache', clearCache)
    }
  }, [clearCache])
}
