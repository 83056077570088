import {
  DispatchType,
  State as LoadingState,
  useChatLoading,
} from '@there/components/chat/useChatLoading'
import { UsersManager } from '@there/components/shared/UsersContext'
import { useNurNode } from '@there/components/sun/use-node'
import { filterFalsy } from '@there/shared/utilities/filter-falsy'
import { SpaceChatQueryReply } from '@there/sun/modules/spaceChats'
import { UserChatsQueryReply } from '@there/sun/modules/userChats'
import {
  NewChatInfo,
  NewMessageInfo,
  TopicInfo,
  UserInfo,
} from '@there/sun/utils/node-types'
import { useAtom } from 'jotai'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { activeNewChatPeerAtom } from '../atoms/chatAtom'
import { useNurQuery } from '../sun/use-query'

type Props = {
  spaceId: string | undefined
  usersManager: UsersManager
}

export interface NewChatWithPeer extends NewChatInfo {
  peerTopic?: TopicInfo | null
  peerUser?: UserInfo | null
}

export type SpaceChatManager = {
  topics: TopicInfo[]
  activeTopic: TopicInfo | undefined
  chats: NewChatWithPeer[]
  allUsers: UserInfo[] | undefined
  allUsersChats: NewChatInfo[] | undefined

  refetchUserChats: () => void
  messageLoadingState: LoadingState
  messageLoadingDispatch: DispatchType
  editingMessageId: string | undefined
  setEditingMessageId: Dispatch<SetStateAction<string | undefined>>
  getTopic: (topicId: string) => TopicInfo | undefined
  getChat: (chatId: string) => NewChatWithPeer | undefined
  getChatByPeerId: (peerId: string) => NewChatWithPeer | undefined
}

export const useSpaceChatsManager = ({
  spaceId,
  usersManager,
}: Props): SpaceChatManager => {
  let [activeNewChatId, setActiveNewChatId] = useAtom(activeNewChatPeerAtom)
  const [{ data, error, fetching, stale }, refetch] = useNurQuery<
    SpaceChatQueryReply
  >({
    method: 'spaceChats',
    variables: { spaceId: String(spaceId) },
    pause: !spaceId,
  })

  const [{ data: userChatsData }, refetchUserChats] = useNurQuery<
    UserChatsQueryReply
  >({
    method: 'userChats',
  })

  let [, { getNode: getUserNode }] = useNurNode<UserInfo>({ id: null })

  let activeTopic: TopicInfo | undefined = useMemo(() => {
    if (!data?.spaceChats.topics) return undefined
    if (!activeNewChatId.peerTopicId) return
    return data.spaceChats.topics.find(
      (topic) => topic.id === activeNewChatId.peerTopicId,
    )
  }, [activeNewChatId.peerTopicId, data?.spaceChats.topics])

  // note @dana: should create a reducer to create more actions, no need for now
  let [editingMessageId, setEditingMessageId] = useState<string | undefined>(
    undefined,
  )

  let [loadingState, loadingDispatch] = useChatLoading()

  let { getUser } = usersManager

  const {
    chats,
    messagesByPeerUserId,
    indexedTopics,
    indexedChats,
    indexedChatsByPeerId,
    allUsers,
    allUsersChats,
  } = useMemo(() => {
    if (!data || !userChatsData) {
      return {
        chats: [],
        messagesByPeerUserId: new Map(),
        indexedTopics: new Map(),
        indexedChats: new Map(),
        indexedChatsByPeerId: new Map(),
      }
    }

    // Index top messages
    let indexedTopMessages = new Map<string, NewMessageInfo>()
    let messages = data?.spaceChats.messages || []
    let messagesByPeerUserId = new Map<string, NewMessageInfo>()

    // Topics
    for (let message of messages) {
      if (!message) continue
      indexedTopMessages.set(message.id, message)
    }

    // DMs
    for (let message of userChatsData.messages) {
      indexedTopMessages.set(message.id, message)

      if (message.peerUserId) {
        messagesByPeerUserId.set(message.peerUserId, message)
      }
    }

    // Mix
    let allChats = [
      ...(data.spaceChats.chats || []),
      ...(userChatsData.chats || []),
    ]
      // Sometimes chat returned is null!
      .filter(filterFalsy)

    // Sort chats
    allChats = allChats?.sort((a, b) => {
      if (!a || !b) return 0

      // compare last message sentAt
      let aSentAt = a.topMessageId
        ? indexedTopMessages.get(a?.topMessageId)?.sentAt
        : 0
      let bSentAt = b.topMessageId
        ? indexedTopMessages.get(b?.topMessageId)?.sentAt
        : 0

      if (bSentAt && !aSentAt) {
        // b first
        return 1
      } else if (!bSentAt && aSentAt) {
        // a first
        return -1
      }

      return (bSentAt || 0) - (aSentAt || 0)
      // return (bSentAt || b.updatedAt || 0) - (aSentAt || a.updatedAt || 0)
    })

    // Index topics
    let indexedTopics = new Map<string, TopicInfo>()
    let topics = data?.spaceChats.topics || []
    for (let topic of topics) {
      indexedTopics.set(topic.id, topic)
    }

    // Index chats
    let indexedChats = new Map<string, NewChatInfo>()
    let indexedChatsByPeerId = new Map<string, NewChatInfo>()
    let chats: NewChatWithPeer[] = []

    for (let chat of allChats) {
      indexedChats.set(chat.id, chat)
      let peerId = chat.peerTopicId || chat.peerUserId || ''
      indexedChatsByPeerId.set(peerId, chat)
      chats.push({
        ...chat,
        peerTopic: chat.peerTopicId
          ? indexedTopics.get(chat.peerTopicId)
          : null,

        peerUser: chat.peerUserId ? getUserNode({ id: chat.peerUserId }) : null,
      })
    }

    return {
      chats: chats || [],
      messagesByPeerUserId,
      indexedTopics,
      indexedChats,
      indexedChatsByPeerId,
      allUsers: userChatsData.users,
      allUsersChats: userChatsData.chats,
    }
  }, [data, getUserNode, userChatsData])

  const getTopic = useCallback(
    (id: string) => {
      return indexedTopics.get(id)
    },
    [indexedTopics],
  )

  const getChat = useCallback(
    (id: string) => {
      return indexedChats.get(id)
    },
    [indexedChats],
  )

  const getChatByPeerId = useCallback(
    (id: string) => {
      return indexedChatsByPeerId.get(id)
    },
    [indexedChatsByPeerId],
  )

  const value: SpaceChatManager = useMemo(
    () => ({
      refetchUserChats,
      topics: data?.spaceChats.topics || [],
      activeTopic,
      chats,
      editingMessageId,
      setEditingMessageId,
      messageLoadingState: loadingState,
      messageLoadingDispatch: loadingDispatch,
      getTopic,
      getChat,
      getChatByPeerId,
      allUsers,
      allUsersChats,
    }),
    [
      refetchUserChats,
      data?.spaceChats.topics,
      activeTopic,
      chats,
      editingMessageId,
      loadingState,
      loadingDispatch,
      getTopic,
      getChat,
      getChatByPeerId,
      allUsers,
      allUsersChats,
    ],
  )

  return value
}

const initialContext: SpaceChatManager = {
  topics: [],
  activeTopic: undefined,
  chats: [],
  editingMessageId: undefined,
  messageLoadingDispatch: () => {},
  messageLoadingState: {
    pendingMessageIds: [],
  },
  refetchUserChats: () => {},
  setEditingMessageId: () => {},
  getTopic: () => undefined,
  getChat: () => undefined,
  getChatByPeerId: () => undefined,
  allUsers: [],
  allUsersChats: [],
}

export const SpaceChatContext = createContext<SpaceChatManager>(initialContext)
export const useSpaceChatsContext = () =>
  useContext<SpaceChatManager>(SpaceChatContext)
