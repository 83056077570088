/**
 * A 3 part module
 * 1. The scroll view register hook - to scroll
 * 2. The dialogs updater hook — to keep track of positions
 * 3. The client hook to use for scrolling in buttons, etc
 */
import { atom, useAtom, useAtomValue } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import { Ref, RefObject, useCallback, useEffect } from 'react'
import { ScrollView } from 'react-native'
import { DialogBox } from '@there/components/main/Rooms'
import { useLatest } from '@there/components/shared/use-latest'

type DialogsPositions = { [id: string]: number }
let dialogPositionsAtom = atom<DialogsPositions>({})

let roomsScrollToAtom = atom<{
  scrollTo: (id: string) => void
}>({ scrollTo: () => {} })

export const useRoomsScrollDialogsManager = (dialogBoxes: DialogBox[]) => {
  let update = useUpdateAtom(dialogPositionsAtom)

  let updateDialogs = useCallback(
    (dialogs: DialogBox[]) => {
      let dialogsPositions: DialogsPositions = {}

      for (let dialog of dialogs) {
        if (dialog.type === 'dialog') {
          dialogsPositions[dialog.dialog.id] = dialog.y
        }
      }

      update(dialogsPositions)
    },
    [update],
  )

  useEffect(() => {
    updateDialogs(dialogBoxes)
  }, [dialogBoxes, updateDialogs])

  return { updateDialogs }
}

export const useRoomsScrollViewManager = ({
  scrollViewRef,
}: {
  /** used to scroll */
  scrollViewRef: RefObject<ScrollView>
}) => {
  let [, updateScrollTo] = useAtom(roomsScrollToAtom)
  let dialogsPositions = useAtomValue(dialogPositionsAtom)
  let dialogsPositionsRef = useLatest(dialogsPositions)
  let scrollTo = useCallback(
    (id: string) => {
      scrollViewRef?.current?.scrollTo({
        y: dialogsPositionsRef.current[id] - 40,
      })
    },
    [dialogsPositionsRef, scrollViewRef],
  )

  useEffect(() => {
    if (!scrollViewRef) {
      return
    }
    updateScrollTo({ scrollTo })
  }, [scrollTo, scrollViewRef, updateScrollTo])
}

export const useRoomsScroll = () => {
  return useAtomValue(roomsScrollToAtom)
}
