import { Text, View } from 'react-native'
import { Fragment } from 'react'
import { useTheme } from '@there/components/feed/ThemeContext'
import React from 'react'
import { Pressable } from '../shared/Pressable'
import { SmallCrossIcon } from '@there/components/main/mainIcons'
import { useHover } from '@there/components/shared/use-hover'
import { feedBackgroundDark } from '@there/shared/desktop/windows-config'
import { useSpring, a } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'

export const NavTab = ({
  icon,
  text,
  isActive,
  tabType = 'normal',
  onClose,
  onPress,
}: {
  icon: React.ReactNode
  text?: string
  isActive: boolean
  tabType: 'normal' | 'home' | 'pinned'
  onClose?: () => void
  onPress?: () => void
}) => {
  let theme = useTheme()

  let [tabHovered, hoverListeners] = useHover()
  let canClose = tabType !== 'home'
  let isHome = tabType === 'home'
  const [{ x, y, zIndex }, api] = useSpring(() => ({ x: 0, y: 0, zIndex: 1 }))
  const bind = useDrag(
    ({ down, movement: [mx, my], memo, tap, pressed, type }) => {
      if (type === 'pointerdown') {
        // if (down && down !== memo?.down) {
        onPress?.()
      }

      if (tabType === 'home') return

      api.start({
        x: down ? mx : 0,
        y: down ? my : 0,
        immediate: down,
        zIndex: down ? 999 : 1,
      })

      return { down }
    },
    { axis: 'x', filterTaps: true, triggerAllEvents: true },
  )

  return (
    <a.div
      style={{
        height: 32,
        //@ts-ignore
        WebkitAppRegion: 'no-drag',
        x,
        y,
        zIndex,
        touchAction: 'none',
        flexShrink: isHome ? 0 : 1,
        overflow: 'hidden',
      }}
      // {...bind()}
    >
      <Pressable
        style={[
          {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: icon && text ? 12 : 12,
            paddingLeft: icon && text ? 12 : 12,
            height: '100%',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            width: '100%',
            maxWidth: 150,
            // Separator
            marginRight: 1,
            marginLeft: 1,

            //@ts-ignore
            cursor: 'default',
          },
          tabHovered && {
            backgroundColor: `#242c38`,
          },
          isActive && {
            backgroundColor: feedBackgroundDark,
            // backgroundColor: `#222A32`,
            // backgroundColor: `rgba(255,255,255,0.05)`,
          },
        ]}
        onPress={onPress}
        {...hoverListeners}
      >
        <Fragment>
          {icon && (
            <View
              style={[
                {
                  width: 18,
                  height: 18,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: icon && text ? 8 : 0,
                },
              ]}
            >
              {/* // close */}
              {canClose && isActive && tabType == 'normal' ? (
                <Pressable
                  onPress={onClose}
                  style={({ hovered, pressed }) => [
                    {
                      width: 12,
                      height: 12,
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      opacity: 0.25,
                    },
                    hovered && { opacity: 0.6 },
                    pressed && { opacity: 1 },
                  ]}
                >
                  <SmallCrossIcon
                    color={theme.colors.text}
                    width={9}
                    height={9}
                  />
                </Pressable>
              ) : (
                icon
              )}
            </View>
          )}
          {text && (
            <Text
              style={{
                color:
                  isActive || tabHovered
                    ? theme.colors.tertiaryText
                    : theme.colors.quaternaryText,
                fontSize: theme.fontSizes.normal,
                maxWidth: '100%',
              }}
              numberOfLines={1}
            >
              {text}
            </Text>
          )}
        </Fragment>
      </Pressable>
    </a.div>
  )
}
