export const getDefaultKeymap = ({
  platform,
}: {
  platform: 'windows' | 'mac' | 'linux' | 'unknown'
}) => ({
  toggleMicrophone: 'alt+s',
  // changed from shift+alt+s
  toggleVoice: 'alt+shift+s',
  acceptRequest: 'none',
  toggleDeepWork: 'none',
  toggleCamera: 'none',
  toggleWindowVisible: 'alt+a',
})
