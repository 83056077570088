import {
  featurePreviewsAtom,
  featurePreviewsLoadingAtom,
} from '@there/components/atoms/feedAtoms'
import {
  spaceLogoDocumentAtom,
  spaceTypeAtom,
} from '@there/components/atoms/spaceAtom'
import { useAppContext } from '@there/components/shared/AppContext'
import {
  arrangeNodes,
  ArrangeOutput,
  emptyArrangeOutput,
} from '@there/components/shared/spaceContext/arrangeNodes'
import { useConnectivity } from '@there/components/shared/use-connectivity'
import { ObjectNode } from '@there/components/sun/cache'
import { useNurQuery } from '@there/components/sun/use-query'
import { CombinedError } from '@there/components/sun/utils/error'
import { SpaceQueryReply } from '@there/sun/modules/space'
import {
  AvatarInfo,
  DialogInfo,
  DialogWithAvatarsInfo,
  FullSpaceInfo,
} from '@there/sun/utils/node-types'
import { atom, useAtom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

// ----------------
// CONTEXT
// ----------------
export interface SpaceContextValue {
  fetching: boolean
  stale: boolean | undefined
  error: CombinedError | undefined | null
  space: FullSpaceInfo | undefined
  // space: SpaceWithMembersAndTopics | undefined

  dialogs: DialogWithAvatarsInfo[] | undefined
  currentDialog: DialogInfo | undefined
  lobbyDialog: DialogInfo | undefined
  getDialogAvatars: (dialogId: string) => AvatarInfo[] | undefined
  getAvatar(id: string): AvatarInfo | undefined
  getAvatarByUserId(userId: string): AvatarInfo | undefined
  /** With avatars */
  getDialog(id: string): DialogInfo | undefined

  subscribeToNode(input: { id: string }, callback: NodeCallback): () => void
}

type DialogWithAvatarInfo = DialogWithAvatarsInfo

const undefinedFunction = () => {
  return undefined
}

const initialContext: SpaceContextManager = [
  {
    fetching: false,
    stale: true,
    error: undefined,
    space: undefined,

    dialogs: undefined,
    currentDialog: undefined,
    lobbyDialog: undefined,
    getDialogAvatars: undefinedFunction,
    getAvatar: undefinedFunction,
    getAvatarByUserId: undefinedFunction,
    getDialog: undefinedFunction,
    subscribeToNode: () => {
      return () => {}
    },
  },
  () => {},
]

export type SpaceContextManager = [SpaceContextValue, () => void]
export const SpaceContext = createContext<SpaceContextManager>(initialContext)
export const useUsersStatusContext = () =>
  useContext<SpaceContextManager>(SpaceContext)
export const useSpaceContext = () => {
  return useContext(SpaceContext)
}

type Input = {
  spaceId: string | undefined
}

type NodeCallback = (node: ObjectNode) => void

// ----------------
// MANAGER HOOK
// ----------------
export const useSpaceManager = (props: Input): SpaceContextManager => {
  // To show the feed
  const [{ data, error, fetching, stale }, refetch] = useNurQuery<
    SpaceQueryReply
  >({
    method: 'space',
    variables: { spaceId: String(props.spaceId) },
    pause: !props.spaceId,
  })
  const { avatarId, activeSpaceName, dispatch } = useAppContext()

  useConnectivity({
    onReconnect: useCallback(() => {
      console.info('re-fetching space on reconnect')
      refetch()
    }, [refetch]),
  })

  let space = data?.space || undefined

  // Feature preview
  let setFeaturePreviews = useUpdateAtom(featurePreviewsAtom)
  let setFeatureLoadingPreviews = useUpdateAtom(featurePreviewsLoadingAtom)
  let featurePreviews = space?.featurePreviews
  useDeepCompareEffect(() => {
    // VERY IMPORTANT
    // This shouldn't change often we use it at root
    setFeaturePreviews(featurePreviews || [])
  }, [setFeaturePreviews, featurePreviews])
  let hasData = !!data
  useEffect(() => {
    setFeatureLoadingPreviews({ initiallyFetching: fetching && !hasData })
  }, [fetching, hasData, setFeatureLoadingPreviews])

  let setSpaceTypeAtom = useUpdateAtom(spaceTypeAtom)
  let spaceType = space?.type
  useDeepCompareEffect(() => {
    setSpaceTypeAtom(spaceType || 'free')
  }, [setSpaceTypeAtom, spaceType])

  let setSpaceLogoDocumentAtom = useUpdateAtom(spaceLogoDocumentAtom)
  let spaceLogo = space?.logo
  useDeepCompareEffect(() => {
    setSpaceLogoDocumentAtom(spaceLogo || undefined)
  }, [setSpaceLogoDocumentAtom, spaceLogo])

  // Space name
  let spaceName = space?.name
  let spaceId = space?.id
  useEffect(() => {
    if (!spaceId || !spaceName) return

    if (spaceName !== activeSpaceName) {
      dispatch({
        type: 'active space name changed',
        spaceId: spaceId,
        spaceName,
      })
    }
  }, [activeSpaceName, dispatch, spaceId, spaceName])

  const {
    dialogs,
    avatarsByDialogId,
    avatarsById,
    avatarIdByUserId,
    dialogsById,
    currentDialog,
    lobbyDialog,
  } = useMemo((): ArrangeOutput => {
    if (!space) {
      return emptyArrangeOutput
    }

    return arrangeNodes(space, { currentAvatarId: avatarId })
  }, [avatarId, space])

  const getDialogAvatars = useCallback(
    (dialogId: string) => {
      return avatarsByDialogId[dialogId] || []
    },
    [avatarsByDialogId],
  )

  const getAvatar = useCallback(
    (id: string): AvatarInfo | undefined => {
      return avatarsById[id]
    },
    [avatarsById],
  )

  const getAvatarByUserId = useCallback(
    (userId: string): AvatarInfo | undefined => {
      let avatarId = avatarIdByUserId[userId]
      if (!avatarId) return undefined
      return getAvatar(avatarId)
    },
    [avatarIdByUserId, getAvatar],
  )

  const getDialog = useCallback(
    (id: string): DialogWithAvatarInfo | undefined => {
      return dialogsById[id]
    },
    [dialogsById],
  )

  // -----
  // Subscription extension,
  // added in SpaceContext v2

  /**
   * NodeId to a set of callbacks
   */
  const nodeSubscribers = useRef<Record<string, undefined | Set<NodeCallback>>>(
    {},
  )
  const subscribeToNode = useCallback(
    ({ id }: { id: string }, callback: NodeCallback) => {
      if (nodeSubscribers.current[id]) {
        nodeSubscribers.current[id]?.add(callback)
      } else {
        nodeSubscribers.current[id] = new Set([callback])
      }

      return function unsubscribe() {
        nodeSubscribers.current[id]?.delete(callback)
      }
    },
    [],
  )

  let [, setSpaceContextRefetch] = useAtom(spaceContextRefetchAtom)

  let [, setCurrentDialogLockStatus] = useAtom(currentDialogLockStatusAtom)
  useEffect(() => {
    if (!currentDialog) {
      setCurrentDialogLockStatus(false)
      return
    }
    setCurrentDialogLockStatus(Boolean(currentDialog.doorClosed))
  }, [currentDialog, setCurrentDialogLockStatus])

  useEffect(() => {
    setSpaceContextRefetch({ refetchSpaceContext: refetch })
  }, [refetch, setSpaceContextRefetch])

  const value: SpaceContextManager = useMemo(() => {
    return [
      {
        dialogs,
        getDialogAvatars,
        currentDialog,
        lobbyDialog,
        space,
        fetching,
        stale,
        error,

        // Getters
        getAvatar,
        getAvatarByUserId,
        getDialog,

        // Subscribers
        subscribeToNode,
      },
      refetch,
    ]
  }, [
    dialogs,
    getDialogAvatars,
    currentDialog,
    space,
    fetching,
    stale,
    error,
    getAvatar,
    getAvatarByUserId,
    getDialog,
    subscribeToNode,
    refetch,
    lobbyDialog,
  ])

  useEffect(() => {
    //@ts-ignore
    window._space = value
  }, [value])

  return value
}

export const spaceContextRefetchAtom = atom({
  refetchSpaceContext: () =>
    console.warn('Space context refetch atom called too fast!'),
})

export const currentDialogLockStatusAtom = atom(false)
