import React, { createContext, useEffect, useMemo, useContext } from 'react'
import { UserStatus } from '@there/shared/types'

import { useLatest } from './use-latest'
import { useAppContext } from './AppContext'
import { MemberWithUser } from '@there/components/urql/fragments/spaceMembersInfo'
import { SpaceInfo } from '@there/components/urql/fragments/spaceInfo'
import {
  FullSpaceInfo,
  MemberWithUserInfo,
  UserInfo,
} from '@there/sun/utils/node-types'

export type UserStatusChangeRecord = {
  [userId: string]: UserStatus
}

interface SpaceMembers extends Omit<SpaceInfo, 'members'> {
  members: MemberWithUserInfo[]
}

type UsersStatusContextType = {
  /** @deprecated */
  getUserStatusOldFormat(userId: string): 'on' | 'off' | 'typing'
  /** If user didn't exist in the current space/isn't present it returns undefined */
  getUserStatus(userId: string): UserStatus | undefined
  getUser(id: string): UserInfo | undefined
  getMember(id: string): MemberWithUserInfo | undefined
  getMemberByUserId(id: string): MemberWithUserInfo | undefined
  members: MemberWithUserInfo[] | undefined
  currentUser: UserInfo | undefined
  currentSpaceName: string | undefined
  refetch(): void
}

export type UsersManager = UsersStatusContextType

const initialContext: UsersStatusContextType = {
  getUserStatusOldFormat() {
    return 'off'
  },
  getUserStatus() {
    return undefined
  },
  getUser() {
    return undefined
  },
  getMember() {
    return undefined
  },
  getMemberByUserId() {
    return undefined
  },
  members: undefined,
  currentUser: undefined,
  currentSpaceName: undefined,
  // addStatusListener() {
  //   return () => {}
  // },
  refetch() {
    return undefined
  },
}

export const UsersContext = createContext<UsersStatusContextType>(
  initialContext,
)
export const useUsersStatusContext = () =>
  useContext<UsersStatusContextType>(UsersContext)

type Input = {
  space: FullSpaceInfo | undefined
  friends: UserInfo[] | undefined
  currentUserId: string | undefined
}

export const useUsersManager = (props: Input) => {
  const { currentUserId } = useAppContext()
  let [, setUsers] = useAtom(usersAtom)

  // Atoms
  useEffect(() => {
    if (!props.space || !props.space.members) {
      return
    }

    let byUserId: Record<string, UserInfo> = {}

    for (let member of props.space.members) {
      let user = member.user

      if (!user) continue
      byUserId[user.id] = user
    }

    setUsers(byUserId)
  }, [props.space, setUsers])

  const currentSpaceName = useMemo(() => {
    if (!props.space) return
    return props.space.name
  }, [props.space])

  const spaceMembers = useMemo(() => {
    if (!props.space || !props.space.members) {
      return
    }

    let userIds = []
    let byUserId: Record<string, UserInfo> = {}
    let byMemberId: Record<string, MemberWithUserInfo> = {}
    let membersByUserId: Record<string, MemberWithUserInfo> = {}
    let currentUser: UserInfo | undefined = undefined

    for (let member of props.space.members) {
      if (!member.user) continue
      userIds.push(member.user.id)
      byUserId[member.user.id] = member.user
      byMemberId[member.id] = member as MemberWithUserInfo
      membersByUserId[member.user.id] = member as MemberWithUserInfo

      //find current user
      if (!currentUser && member.user.id === currentUserId) {
        currentUser = member.user
      }
    }

    if (props.friends) {
      for (let user of props.friends) {
        byUserId[user.id] = user
      }
    }

    return {
      userIds,
      byUserId,
      byMemberId,
      currentUser,
      membersByUserId,
    }
  }, [currentUserId, props.friends, props.space])

  let [, { getNode }] = useNurNode({ id: null })

  let value: UsersStatusContextType = useMemo(() => {
    return {
      currentUser: spaceMembers?.currentUser,
      currentSpaceName,
      /** @deprecated */
      getUserStatusOldFormat(userId: string): 'typing' | 'on' | 'off' {
        let user = spaceMembers?.byUserId[userId]
        if (!user) return 'off'
        return user.online ? 'on' : 'off'
      },
      getUserStatus(userId: string): UserStatus | undefined {
        let user = spaceMembers?.byUserId[userId]
        if (user) {
          return {
            online: user.online || undefined,
          }
        }
      },
      getUser(userId: string) {
        return getNode({ id: userId }) || spaceMembers?.byUserId[userId]
        // return spaceMembers?.byUserId[userId]
      },
      getMember(memberId: string) {
        return spaceMembers?.byMemberId[memberId]
      },
      getMemberByUserId(userId: string) {
        return spaceMembers?.membersByUserId[userId]
      },
      refetch() {
        // Security Vulnerability: Don't pass a list of user ids, ask for getting current active space
        // socket?.emit('space:users-status-requested', spaceMembers?.userIds)
      },
      members: props.space?.members,
    }
  }, [
    currentSpaceName,
    getNode,
    props.space?.members,
    spaceMembers?.byMemberId,
    spaceMembers?.byUserId,
    spaceMembers?.currentUser,
    spaceMembers?.membersByUserId,
  ])

  return value
}

export const UsersProvider = (props: Input & { children: React.ReactNode }) => {
  let value = useUsersManager(props)
  return <UsersContext.Provider value={value} children={props.children} />
}

export const useUsersContext = () => {
  return useContext(UsersContext)
}

// ----------------------------
// Atom Family Refactor
// ----------------------------
import { atom, useAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import isEqual from 'react-fast-compare'
import { useNurNode } from '@there/components/sun/use-node'

/** Gives the same atom for given user (reference by id) */
export const usersAtom = atom<Record<string, UserInfo>>({})
export const userAtomFamily = atomFamily(
  (user: UserInfo) => atom((get) => get(usersAtom)[user.id]),
  isEqual,
)
// export const userAtomFamily = atomFamily((user: Partial<UserInfo>) => {
//   return atom(user)
// }, isEqual)
