import { a, useSpring } from '@react-spring/native'
import { useTheme } from '@there/components/feed/ThemeContext'
import {
  GetPropsCommonOptions,
  UseComboboxGetInputPropsOptions,
} from 'downshift'
import React, { useEffect, useRef, useState } from 'react'
import {
  LayoutChangeEvent,
  Platform,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native'
import { DefaultTheme } from 'styled-components'
import { Space } from '../shared/Space'
import { useHover } from '../shared/use-hover'
import { RightArrow } from './mainIcons'

type Props = {
  background: keyof DefaultTheme['colors']
  bordered?: boolean
  before?: React.ReactNode
  submitButton?: React.ReactNode
  autoSelect?: boolean
  placeholder: string
  placeholderColor?: keyof DefaultTheme['colors']
  textColor?: keyof DefaultTheme['colors']
  align?: TextStyle['textAlign']
  disabled?: boolean
  isMultiline?: boolean
  helpText?: string
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  autoFocus?: boolean
  inputProps?: (
    options?: UseComboboxGetInputPropsOptions | undefined,
    otherOptions?: GetPropsCommonOptions | undefined,
  ) => any

  // if we have onSubmit prop, it should be true to show submit button
  valueValidated?: boolean

  onSubmit?: () => void
  onCancel?: () => void
}

export const StandardInput = React.forwardRef((props: Props, ref) => {
  let t = useTheme()
  let submitButton = props.submitButton
  let [submitHovered, submitHoverListener] = useHover()
  let inputProps = props.inputProps ? props.inputProps() : {}

  let [renderHelpText, setHelpText] = useState(false)
  let [isFocused, setFocused] = useState(false)
  let [inputHeight, setInputHeight] = useState(26)
  let textRef = useRef<LayoutChangeEvent | null>(null)

  useEffect(() => {
    if (props.helpText && (props.value.length > 0 || isFocused)) {
      setHelpText(true)
    } else {
      setHelpText(false)
    }

    // if (textRef && textRef.current) {
    //   setInputHeight(textRef.current.nativeEvent.layout.height)
    // }
  }, [isFocused, props.helpText, props.isMultiline, props.value.length])

  let styleProps = useSpring({
    height: props.value.includes('\n') ? 42 : 32,
    config: {
      mass: 0.1,
      tension: 400,
      friction: 10,
    },
  })

  let textStyleProps = useSpring({
    opacity: renderHelpText ? 1 : 0,
    config: {
      tension: 300,
      friction: 24,
      mass: 0.4,
    },
  })

  let submitAnimation = useSpring({
    opacity: props.valueValidated ? 1 : 0,
    scale: props.valueValidated ? 1 : 0,
    config: {
      tension: 300,
      friction: 24,
      mass: 0.4,
    },
  })

  let submitButtonJsx = props.onSubmit ? (
    <>
      <Space horizontal={5} />
      <a.View
        style={{
          opacity: submitAnimation.opacity,
          transform: [{ scale: submitAnimation.scale }],
          position: 'absolute',
          right: 5,
          bottom: 3,
          overflow: 'hidden',
        }}
      >
        <TouchableOpacity
          onPress={() => (props.onSubmit ? props.onSubmit() : null)}
          activeOpacity={0.85}
          disabled={props.value.length === 0 || props.disabled}
          style={[
            {
              width: 22,
              height: 22,
              borderRadius: 28,
              backgroundColor: t.colors.primaryButtonBackground,
              alignItems: 'center',
              justifyContent: 'center',
            },
            submitHovered && {
              backgroundColor: t.colors.primaryButtonBackgroundHover,
            },
          ]}
          {...submitHoverListener}
        >
          <RightArrow width={20} height={20} />
        </TouchableOpacity>
      </a.View>
    </>
  ) : null

  return (
    <>
      {
        //@ts-ignore
        <a.View
          style={[
            {
              position: 'relative',
              width: '100%',
              height: styleProps.height,
              overflow: 'hidden',
              borderRadius: t.borderRadius.large,
              backgroundColor: t.colors.transparentInputBackground,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              zIndex: 2,
            },
            props.bordered && {
              borderWidth: 1,
              borderColor: t.colors.transparentInputBorder,
            },
          ]}
        >
          {props.before ? (
            <View style={{ alignSelf: 'center', paddingRight: 5 }}>
              {props.before}
              <Space horizontal={5} />
            </View>
          ) : // <Space horizontal={10} />
          null}
          <TextInput
            ref={ref}
            value={props.value}
            multiline={props.isMultiline}
            autoFocus={props.autoFocus}
            autoSelect={props.autoSelect}
            onFocus={() => {
              setFocused(true)
            }}
            onBlur={() => {
              setFocused(false)
            }}
            textAlignVertical="top"
            style={[
              {
                paddingHorizontal: 8,
                paddingVertical: 5,
                height: '100%',
                width: '100%',
                lineHeight: 18,
                borderWidth: 0,
                flex: 1,
                fontSize: t.fontSizes.normal,
                color: t.colors[props.textColor || 'text'],
                WebkitScrollbar: 'none',
              },
              Platform.OS === 'web' && { outlineWidth: 0 },
              props.align && {
                textAlign: props.align,
              },
            ]}
            onChangeText={(text) =>
              props.disabled ? null : props.setValue(text)
            }
            selectTextOnFocus={true}
            placeholder={props.placeholder}
            placeholderTextColor={
              t.colors[props.placeholderColor || 'quaternaryText']
            }
            onKeyPress={(event: TextInputKeyPressEventData) => {
              if (event.key === 'Escape' && props.onCancel) {
                props.onCancel()
              }
              if (
                event.key === 'Enter' &&
                props.onSubmit &&
                !props.isMultiline
              ) {
                props.onSubmit()
              }
            }}
            {...inputProps}
          />
          {submitButtonJsx}

          {submitButton}
        </a.View>
      }
      {props.helpText ? (
        //@ts-ignore
        <a.View style={textStyleProps}>
          <Text
            style={{
              marginTop: 5,
              fontSize: t.fontSizes.small,
              color: t.colors.quaternaryText,
              //@ts-ignore
              pointerEvents: 'none',
            }}
          >
            {props.helpText}
          </Text>
        </a.View>
      ) : null}
    </>
  )
})
