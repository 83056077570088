import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { useHover } from './use-hover'
import { useTheme } from '../feed/ThemeContext'

export const TransparentButton = (
  props: TouchableOpacity['props'] & {
    size: number
    isActive?: boolean
    options?: () => any
    noHoverBg?: boolean
    activeColor?: string
    radius?: number
    disabled?: boolean
    wrapperStyle?: TouchableOpacity['props']
  },
) => {
  const t = useTheme()
  const [hovered, hoverListener] = useHover()

  let options = props.options ? props.options() : {}
  return (
    <TouchableOpacity
      disabled={props.disabled || !props.onPress}
      activeOpacity={0.8}
      {...hoverListener}
      style={[
        {
          minWidth: props.size,
          height: props.size,
          borderRadius: t.borderRadius.large,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        !props.noHoverBg &&
          (hovered || props.isActive) &&
          !props.disabled && {
            backgroundColor: t.colors.circleButtonBackground,
          },

        props.isActive &&
          props.activeColor && {
            backgroundColor: props.activeColor,
          },

        props.radius && {
          borderRadius: props.radius,
        },

        props.disabled && {
          opacity: 0.45,
        },
        props.wrapperStyle?.style,
      ]}
      {...props}
      {...options}
    >
      {props.children}
    </TouchableOpacity>
  )
}
