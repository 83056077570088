import { lighten } from 'polished'
import React from 'react'
import {
  StyleProp,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { DefaultTheme } from 'styled-components'
import { useTheme } from '../feed/ThemeContext'
import { Space } from '../shared/Space'
import { useHover } from '../shared/use-hover'

type Props = {
  text: React.ReactNode
  backgroundColor?: keyof DefaultTheme['colors']
  textColor?: keyof DefaultTheme['colors']
  variant?: 'primary' | 'secondary' | 'outline' | 'pink'
  icon?: React.ReactNode
  iconFlipped?: boolean
  stretch?: boolean
  disabled?: boolean
  size?: 'normal' | 'small' | 'tiny' | 'large'
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}

export const standardButtonHeightSmall = 24

export const StandardButton = (props: Props) => {
  let t = useTheme()
  let [hovered, hoverListener] = useHover()
  let size = typeof props.size !== 'undefined' ? props.size : 'normal'
  let fontSize: 'normal' | 'small' | 'large' =
    size === 'normal'
      ? 'normal'
      : size === 'small'
      ? 'normal'
      : size === 'tiny'
      ? 'small'
      : 'normal'
  let variant = props.variant || 'secondary'
  // for backward compat
  let backgroundColor: keyof DefaultTheme['colors'] =
    props.backgroundColor || 'buttonBackground'

  return (
    <TouchableOpacity
      activeOpacity={0.85}
      disabled={props.disabled || !props.onPress}
      onPress={props.onPress}
      style={[
        size === 'tiny' && {
          height: 22,
          borderRadius: 8,
          paddingHorizontal: props.icon ? 6 : 8,
        },
        size === 'small' && {
          height: 26,
          borderRadius: 10,
          paddingHorizontal: props.icon ? 10 : 12,
        },
        size === 'normal' && {
          height: 28,
          borderRadius: 8,
          paddingHorizontal: props.icon ? 8 : 20,
        },
        size === 'large' && {
          height: 32,
          borderRadius: 8,
          paddingHorizontal: props.icon ? 8 : 20,
        },
        props.stretch && {
          width: '100%',
          paddingHorizontal: props.icon ? 8 : 30,
        },
        {
          backgroundColor: t.colors[backgroundColor],
          alignItems: 'center',
          justifyContent: 'center',
        },
        !props.disabled &&
          hovered && {
            backgroundColor: lighten(0.05, t.colors[backgroundColor]),
          },

        variant === 'outline' && {
          backgroundColor: 'transparent',
          borderWidth: 1,
          borderColor: t.colors.outlineButtonBorderColor,
        },
        variant === 'outline' &&
          !props.disabled &&
          hovered && {
            backgroundColor: t.colors.buttonBackground,
            borderWidth: 1,
            borderColor: t.colors.buttonBackground,
          },
        props.style,
      ]}
      {...hoverListener}
    >
      <View
        style={[
          {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: props.icon
              ? props.iconFlipped
                ? 'row-reverse'
                : 'row'
              : 'column',
          },
          props.stretch && {
            justifyContent: 'center',
          },
        ]}
      >
        {props.icon ? (
          <>
            {props.icon}
            <Space horizontal={props.stretch ? 11 : 2} />
          </>
        ) : null}
        <Text
          style={[
            {
              fontSize: t.fontSizes[fontSize],
              lineHeight: size === 'tiny' ? 22 : 28,
              fontWeight: size === 'tiny' ? 'bold' : 'normal',
              color: t.colors[props.textColor || 'tertiaryText'],
            },
            props.textStyle,
          ]}
          numberOfLines={1}
        >
          {props.text}
        </Text>
      </View>
    </TouchableOpacity>
  )
}
