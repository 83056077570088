import { ActivityIndicator, Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { CheckIcon, DoubleCheckIcon } from '../chat/CheckIcon'
import { getAmPmTime } from '../utils/ampm'
import { usePrevious } from '@there/components/shared/use-previous'
import { ChatMessageInfo } from '@there/components/types/chat'
import { memo, useEffect } from 'react'
import { Tooltip } from '../shared/Tooltip'
import { useSpaceChatsContext } from '../shared/SpaceChatsContext'

type Props = {
  isUs: boolean
  sent: boolean | undefined | null
  largeEmojiMode: boolean
  sendDate: Date
  message: ChatMessageInfo
  hasRead?: boolean
}

export function getMetaWidth(isUs: boolean, edited: boolean) {
  let width = isUs ? 70 : 62
  if (edited) {
    width += 30
  }

  return width
}

// Memo because it's used in photo view modal
export const MessageMeta = memo(
  ({ isUs, sent, largeEmojiMode, sendDate, message, hasRead }: Props) => {
    let hasPhoto =
      message.mediaType === 'Photo' || message.document?.type === 'Photo'
    let hasGif = message.document?.type === 'Gif'
    let hasText = !!message.text
    let hasFile = !!message.document && message.document.type !== 'Photo'
    let edited = !!message.edited

    let theme = useTheme()
    let sentPrev = usePrevious(sent)
    let hasReadPrev = usePrevious(hasRead)
    let width = getMetaWidth(isUs, edited)
    let { messageLoadingDispatch } = useSpaceChatsContext()

    return (
      <View
        style={[
          {
            // Fixate it
            width,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            // justifyContent: 'flex-end',
            justifyContent: 'center',
          },

          // Overlay over photo mode
          // In photos put it over
          !hasText &&
            (hasPhoto || hasGif) && {
              paddingLeft: 0,
              paddingRight: 2,
              position: 'absolute',
              bottom: 6,
              right: 7,
              zIndex: 3,
              backgroundColor: `rgba(0,0,0,0.6)`,
              borderRadius: 8,
              paddingVertical: 2,
            },
          // In photos put it over
          !hasText &&
            hasFile && {
              paddingLeft: 0,
              paddingRight: 2,
              position: 'absolute',
              bottom: 6,
              right: 7,
              zIndex: 3,
              paddingVertical: 2,
            },
        ]}
      >
        <Tooltip
          label={
            new Intl.DateTimeFormat('en-US', {
              dateStyle: 'long',
            }).format(sendDate) +
            ' at ' +
            new Intl.DateTimeFormat('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            }).format(sendDate)
          }
          placement="bottomCenter"
        >
          <Text
            style={[
              {
                fontSize: theme.fontSizes.small,
                color: theme.colors.tertiaryLight,
                marginRight: 4,
                marginLeft: 4,

                // marginTop: 2,
                display: 'flex',
                alignItems: 'center',
              },
              largeEmojiMode && {
                marginLeft: 2,
                height: undefined,
              },
            ]}
            numberOfLines={1}
          >
            {edited ? 'edited ' : ''}
            {getAmPmTime(sendDate)}
          </Text>
        </Tooltip>

        {isUs && (
          <View
            style={[
              {
                width: 12,
                // height: 28,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
              largeEmojiMode && {
                height: 12,
                alignSelf: 'center',
              },
            ]}
          >
            {sent ? (
              <>
                {!!hasRead ? (
                  <DoubleCheckIcon
                    color={theme.colors.quaternaryText}
                    width={13}
                    animate={hasRead === true && hasReadPrev === false}
                  />
                ) : (
                  <CheckIcon
                    color={theme.colors.quaternaryText}
                    width={12}
                    animate={sent === true && sentPrev === false}
                  />
                )}
              </>
            ) : (
              <ActivityIndicator
                size={12}
                color={theme.colors.quaternaryText}
              />
            )}
          </View>
        )}
      </View>
    )
  },
)
