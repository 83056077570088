import { activeNewChatPeerAtom } from '@there/components/atoms/chatAtom'
import {
  IsInViewportContext,
  useInViewportManager,
} from '@there/components/hooks/useInViewport'
import { useConnectivity } from '@there/components/shared/use-connectivity'
import { useDebounceCallback } from '@there/components/shared/use-debounce-callback'
import { ChatMessageInfo } from '@there/components/types/chat'
import { useMessageList } from '@there/components/v2/ChatView'
import { MessageListContent } from '@there/components/v2/MessageListContent'
import { ScrollToButton } from '@there/components/v2/ScrollToBottom'
import { ChatState } from '@there/components/v2/useChatsState'
import { useMessageActions } from '@there/components/v2/useMessageActions'
import {
  ChatPeerId,
  RenderingPhaseDispatch,
} from '@there/components/v2/useRenderingPhase'
import { useSeenMessageManager } from '@there/components/v2/useSeenMessageManager'
import { useListenToIpc } from '@there/desktop/utils/electron-api'
import { useAtom } from 'jotai'
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import deepEqual from 'react-fast-compare'
import { ScrollView, View } from 'react-native'
import { sideViewAtom } from '../atoms/contentViewAtom'
import { useKeyContext } from '../feed/KeyContext'
import { useAppContext } from '../shared/AppContext'
import { useSpaceChatsContext } from '../shared/SpaceChatsContext'
import { useLatest } from '../shared/use-latest'
import { useMainWindowContext } from '../shared/use-main-window'
import { MaxWidthWeb, useWindowContext } from '../shared/WindowContext'
import { useDownloadManager } from './useDownloadManager'

type ChatProps = {
  peer: ChatPeerId
  dispatch: RenderingPhaseDispatch
  hidden?: boolean
  chatState: ChatState | undefined
}

export const NewChat = memo(
  ({ peer, dispatch, hidden, chatState }: ChatProps) => {
    let { currentUserId, activeSpaceId } = useAppContext()
    let { messageLoadingState, chats } = useSpaceChatsContext()
    let { setReplyMsgId, setEditingMsgId } = useMessageActions()
    let { isElectron } = useWindowContext()
    let isBrowser = !isElectron

    /**
     * Chat and messages
     */
    let [, setActiveChatPeer] = useAtom(activeNewChatPeerAtom)
    let { dispatch: windowDispatch } = useMainWindowContext()

    let peerTopicId = peer.type === 'Topic' ? peer.id : null
    let peerUserId = peer.type === 'User' ? peer.id : null
    let replyingMessageId = chatState?.replyingToMsgId
    let editingMessageId = chatState?.editingMsgId

    const [
      { data, fetching, error, stale },
      refetch,
      fetchMore,
    ] = useMessageList(peer || null)

    // TODO: check if it actually works
    useConnectivity({
      onReconnect: useCallback(() => {
        console.info('re-fetching chat on reconnect')
        refetch()
      }, [refetch]),
    })

    useEffect(() => {
      if ((data && stale && fetching) || !fetching) {
        dispatch({ type: 'state changed', peer, state: { fetched: true } })

        if (data?.messages?.length === 0) {
          // No messages
          dispatch({ type: 'chat is empty', peer })
        }
      }
    }, [data, dispatch, fetching, peer, stale])

    let activeChat = useMemo(() => {
      return chats.find(
        (chat) =>
          (peerTopicId && chat.peerTopicId === peerTopicId) ||
          (peerUserId && chat.peerUserId === peerUserId),
      )
    }, [peerTopicId, peerUserId, chats])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let messages = data?.messages || []

    let { registerKeyEvent, unregisterKeyEvent } = useKeyContext()

    let [sideView] = useAtom(sideViewAtom)

    useEffect(() => {
      if (editingMessageId || replyingMessageId) {
        registerKeyEvent({
          id: 'remove-editing-replying-message',
          action: () => {
            setEditingMsgId(null)
            setReplyMsgId(null)
          },
          key: 'Escape',
        })
      } else {
        registerKeyEvent({
          id: 'close-chat-view',
          action: () => {
            if (sideView === 'home') {
              windowDispatch({ type: 'change mode', mode: 'home' })
            } else {
              windowDispatch({ type: 'change mode', mode: 'room' })
            }
            setActiveChatPeer({})
          },
          key: 'Escape',
        })
      }

      return () => {
        unregisterKeyEvent({
          id: 'remove-editing-replying-message',
          key: 'Escape',
        })
        unregisterKeyEvent({
          id: 'close-chat-view',
          key: 'Escape',
        })
      }
    }, [
      editingMessageId,
      registerKeyEvent,
      replyingMessageId,
      setActiveChatPeer,
      setEditingMsgId,
      setReplyMsgId,
      sideView,
      unregisterKeyEvent,
      windowDispatch,
    ])

    /**
     * Scroll and render management
     */
    let scrollViewRef = useRef<ScrollView | null>(null)

    let [contentHeight, setContentHeight] = useState<number>(0)
    let [fillEmptySpace, setFillEmptySpace] = useState(0)

    let scrollViewContainerRef = useRef<number | null>(null)
    let scrollViewContentRef = useRef<number | null>(null)

    let {
      debouncedCallback: calculateEmptySpace,
      normalCallback: calculateEmptySpaceNorDebounced,
    } = useDebounceCallback(() => {
      if (!scrollViewContainerRef.current || !scrollViewContentRef.current)
        return
      setFillEmptySpace(
        contentHeight < scrollViewContainerRef.current
          ? scrollViewContainerRef.current - contentHeight - 24
          : 0,
      )

      dispatch({
        type: 'state changed',
        peer,
        state: { calculatedEmptySpace: true },
      })
    }, 100)

    useEffect(() => {
      // initially calculate empty space
      calculateEmptySpaceNorDebounced()

      // recalculate empty space on resize
      window.addEventListener('resize', calculateEmptySpace)
      return () => {
        window.removeEventListener('resize', calculateEmptySpace)
      }
    }, [
      calculateEmptySpaceNorDebounced,
      calculateEmptySpace,
      contentHeight,
      messages,
    ])

    let weSentLastMessage =
      messages &&
      messages[messages.length - 1] &&
      messages[messages.length - 1].senderId === currentUserId

    let {
      lastSeenMessageId,
      seenMultiple,
      lastSeenMessageSentAt,
    } = useSeenMessageManager(activeChat, activeSpaceId, messages)

    // just set lastSeenMessageIs when component mounts and do not change it on seen
    // this is used for unRead indicator
    let lastSeenMessageIdOnMount = useRef<string | undefined>(undefined)

    useEffect(() => {
      if (!lastSeenMessageId) return
      if (lastSeenMessageIdOnMount.current) return

      lastSeenMessageIdOnMount.current = lastSeenMessageId
    }, [lastSeenMessageId, lastSeenMessageIdOnMount])

    let unreadBadgeY = useRef<number | null>(null)
    let [shownUnreadBadge, setShownUnreadBadge] = useState<boolean>(false)
    // After last message onLayout
    let [messagesRendered, setMessagesRendered] = useState<boolean>(false)

    let isScrolledManuallyRef = useLatest<boolean>(false)
    let lastMessageId = messages ? messages[messages.length - 1]?.id : undefined
    let lastMessageIdRef = useRef(lastMessageId)
    let initiallyScrolledRef = useRef(false)

    // Scroll on new messages
    useEffect(() => {
      if (!scrollViewRef || !scrollViewRef.current) return

      // scroll to end if we send new message
      if (
        initiallyScrolledRef.current &&
        // Either last message is ours and we scroll, or someone sends and we're at the bottom
        (weSentLastMessage || !isScrolledManuallyRef.current) &&
        lastMessageIdRef.current !== lastMessageId
      ) {
        scrollViewRef.current?.scrollToEnd({
          // when we send new message if we scrolled to top manually, do not scroll by animation
          animated:
            isScrolledManuallyRef.current && weSentLastMessage ? false : true,
        })
        return
      }

      // sync message length w/ ref
      lastMessageIdRef.current = lastMessageId
    }, [
      initiallyScrolledRef,
      isScrolledManuallyRef,
      lastMessageId,
      weSentLastMessage,
    ])

    useEffect(() => {
      isScrolledManuallyRef.current = false
    }, [isScrolledManuallyRef])

    let peerRef = useLatest(peer)
    useEffect(() => {
      if (!scrollViewRef || !scrollViewRef.current) return
      if (!messagesRendered) return
      if (!peerRef.current) return
      if (initiallyScrolledRef.current) {
        return
      }

      // prevent to scroll to end when manually scrolled to top
      if (isScrolledManuallyRef.current) {
        return
      }

      if (unreadBadgeY.current) {
        // if unreadBadge is rendered and we have it position, scroll to it
        scrollViewRef.current.scrollTo({
          y: unreadBadgeY.current,
          animated: false,
        })
      } else {
        // scroll to bottom if there is no unread badge
        scrollViewRef.current.scrollToEnd({ animated: false })
      }

      dispatch({
        type: 'state changed',
        peer: peerRef.current,
        state: { scrolled: true },
      })

      initiallyScrolledRef.current = true
    }, [
      isScrolledManuallyRef,
      messagesRendered,
      shownUnreadBadge,
      unreadBadgeY,
      lastMessageId,
      dispatch,
      peerRef,
    ])

    let lastFetchMore = useRef(0)
    let pinnedScrollSizeWhileFetching = useRef<{
      y: number
      contentSizeHeight: number
    } | null>(null)

    const fetchMoreMessages = (
      currentScrollY: number,
      contentSizeHeight: number,
    ) => {
      if (fetching) return
      if (error) return
      if (messages.length === 0) return
      // too quick
      if (lastFetchMore.current + 1000 > performance.now()) return
      if (!messagesRendered) return

      pinnedScrollSizeWhileFetching.current = {
        y: currentScrollY,
        contentSizeHeight,
      }
      console.info('Fetching more...')
      fetchMore({
        offsetId: messages[0].id,
      })
      lastFetchMore.current = performance.now()
    }

    let lastMessageCalculated = useCallback(() => {
      setMessagesRendered(true)

      dispatch({
        type: 'state changed',
        peer,
        state: { calculated: true },
      })
    }, [peer, dispatch])

    let [parentWidth, setParentWidth] = useState(300)
    let [containerHeight, setContainerHeight] = useState<number | undefined>()

    useLayoutEffect(() => {
      if (!initiallyScrolledRef.current) return
      if (isScrolledManuallyRef.current) return
      // TODO: layout effect
      scrollViewRef.current?.scrollToEnd({ animated: false })
    }, [containerHeight, isScrolledManuallyRef])

    let [scrolledManually, setScrolledManually] = useState(false)

    // Download manager
    const {
      inProgressDownloads,
      addDownload,
      updateDownload,
      removeDownload,
    } = useDownloadManager()

    useListenToIpc('file-dl-event', (event, data) => {
      if (!data) return
      if (data.type === 'started') {
        addDownload(data.documentId, data)
      }
      if (data.type === 'percent') {
        addDownload(data.documentId, data)
      }
      if (data.type === 'completed') {
        removeDownload(data.documentId)
      }
      return
    })

    const {
      context,
      onContentSizeChange,
      onLayout,
      onScroll,
    } = useInViewportManager({
      target: scrollViewRef,
      items: messages,
      itemToKey: (msg) => msg.id,
      inViewItems: (inViewMessages: ChatMessageInfo[]) => {
        // Update seen
        seenMultiple(inViewMessages)
      },
      debounceMs: 100,
    })

    return (
      <View
        style={[
          {
            display: 'flex',
            flexDirection: 'column',
            // flex: 1,
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            // backgroundColor: 'rgba(0, 0, 0, 0.26)',
          },
          hidden && {
            opacity: 0,
            zIndex: 1,
            //@ts-ignore
            pointerEvents: 'none',
            /// need to balance both to have the same size
          },
          isBrowser && {},
        ]}
        onLayout={(event) => {
          setParentWidth(event.nativeEvent.layout.width)
        }}
      >
        <IsInViewportContext.Provider value={context}>
          <ScrollView
            style={[
              isBrowser && {
                width: '100%',
              },
            ]}
            onLayout={(event) => {
              onLayout(event)

              scrollViewContainerRef.current = event.nativeEvent.layout.height

              // Container resize observer (caused by Composer reply/webpage panels)
              setContainerHeight(event.nativeEvent.layout.height)
            }}
            onContentSizeChange={(width, height) => {
              onContentSizeChange(width, height)

              // Check if auto scrolled to top because of fetched messages, keep scroll the same
              if (
                pinnedScrollSizeWhileFetching.current &&
                pinnedScrollSizeWhileFetching.current.contentSizeHeight < height
              ) {
                let diff =
                  height -
                  pinnedScrollSizeWhileFetching.current.contentSizeHeight
                scrollViewRef.current?.scrollTo({ y: diff, animated: false })
                // Reset
                pinnedScrollSizeWhileFetching.current = null
              }
            }}
            onScroll={(event) => {
              onScroll(event)

              let layoutHeight = event.nativeEvent.layoutMeasurement.height
              let contentSizeHeight = event.nativeEvent.contentSize.height
              let endY = contentSizeHeight - layoutHeight
              let y = event.nativeEvent.contentOffset?.y
              let nextScrolledManually = Math.abs(endY - y) > 40

              // don't set this if messages haven't rendered fully
              if (
                messagesRendered &&
                initiallyScrolledRef.current &&
                isScrolledManuallyRef.current !== nextScrolledManually
              ) {
                setScrolledManually(nextScrolledManually)
                isScrolledManuallyRef.current = nextScrolledManually
              }

              // If got to the top, fetch more once
              if (
                y < 40 &&
                // If it could scroll
                contentSizeHeight > layoutHeight &&
                fillEmptySpace === 0
              ) {
                fetchMoreMessages(y, contentSizeHeight)
              }
            }}
            scrollEventThrottle={200}
            ref={scrollViewRef}
            contentContainerStyle={{
              padding: 12,
              paddingRight: 20,
            }}
          >
            <View style={{ height: fillEmptySpace }} />
            <View
              onLayout={(event) => {
                let contentHeight = event.nativeEvent.layout.height
                scrollViewContentRef.current = contentHeight
                setContentHeight(contentHeight)
              }}
              style={[
                isBrowser && {
                  width: '100%',
                  maxWidth: MaxWidthWeb,
                  alignSelf: 'center',
                },
              ]}
            >
              <MessageListContent
                messages={messages}
                noAvatars={peer.type === 'User'}
                withUsers={peer.type !== 'User'}
                peer={peer}
                // Legacy
                parentWidth={parentWidth}
                lastSeenMessageSentAt={lastSeenMessageSentAt}
                lastSeenMessageIdOnMount={lastSeenMessageIdOnMount}
                lastMessageCalculated={lastMessageCalculated}
                lastReadOutboxDate={activeChat?.lastReadOutboxDate}
                messageLoadingState={messageLoadingState}
                unreadBadgeYRef={unreadBadgeY}
                inProgressDownload={inProgressDownloads}
              />
            </View>
          </ScrollView>
        </IsInViewportContext.Provider>

        <ScrollToButton
          isScrolledManually={scrolledManually}
          scrollViewRef={scrollViewRef}
          unreadCount={data?.chat?.unreadCount}
        />
      </View>
    )
  },
  deepEqual,
)
