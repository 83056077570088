import React from 'react'
import { Space } from '../shared/Space'
import { WindowHeader } from './SettingsUI'
import { AvatarSection } from './AvatarManager'

export const AccountWindow = () => {
  return (
    <React.Fragment>
      <WindowHeader>Account</WindowHeader>
      <Space vertical={12} />
      <AvatarSection />
    </React.Fragment>
  )
}
