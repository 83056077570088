const colors = [
  'rgb(255, 102, 161)',
  'rgb(216, 114, 255)',
  'rgb(0, 168, 255)',
  'rgb(0, 175, 188)',
  'rgb(0, 181, 113)',
  'rgb(255, 110, 90)',
  'rgb(255, 105, 136)',
]

export const getProfileBackground = (userName: string) => {
  let index =
    userName
      .split('')
      .map((a) => a.charCodeAt(0))
      .reduce((a, b) => a + b, 0) % colors.length
  return colors[index]
}
