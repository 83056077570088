import { NewChatInfo, UserInfo } from '@there/sun/utils/node-types'

const A_FIRST = -1
const B_FIRST = 1

interface UserWithChatInfo extends UserInfo {
  chat?: NewChatInfo
}

export const usersWithChatSortFunction = ({
  currentUserId,
}: {
  currentUserId: string | undefined
}) => (a: UserWithChatInfo, b: UserWithChatInfo) => {
  let userA = a
  let userB = b

  // User was null in production
  if (!userA || !userB) {
    return A_FIRST
  }

  if (userA.id === currentUserId) {
    return A_FIRST
  } else if (userB.id === currentUserId) {
    return B_FIRST
  }

  let userAChatUpdate = userA.chat?.updatedAt
  let userBChatUpdate = userB.chat?.updatedAt

  let userAOnlineStatus = userA.online ? 1 : 0
  let userBOnlineStatus = userB.online ? 1 : 0

  // let userALastSeen = new Date(userA.lastOnline).getTime()
  // let userBLastSeen = new Date(userB.lastOnline).getTime()

  let userAJoin = a.createdAt || 0
  let userBJoin = a.createdAt || 0

  let byOnline = userBOnlineStatus - userAOnlineStatus
  let sameOnline = byOnline === 0

  // let byLastSeen = userBLastSeen - userALastSeen
  let noLastSeen = userA.lastOnline === null && userB.lastOnline === null

  let byJoin = userBJoin - userAJoin

  return userAChatUpdate && userBChatUpdate
    ? userBChatUpdate - userAChatUpdate
    : userAChatUpdate
    ? A_FIRST
    : userBChatUpdate
    ? B_FIRST
    : sameOnline
    ? byJoin
    : byOnline
}

export const onlineFirstUsersSortFunction = ({
  currentUserId,
}: {
  currentUserId: string | undefined
}) => (a: UserWithChatInfo, b: UserWithChatInfo) => {
  let userA = a
  let userB = b

  // User was null in production
  if (!userA || !userB) {
    return A_FIRST
  }

  if (userA.id === currentUserId) {
    return A_FIRST
  } else if (userB.id === currentUserId) {
    return B_FIRST
  }

  let userAOnlineStatus = userA.online ? 1 : 0
  let userBOnlineStatus = userB.online ? 1 : 0

  let userALastOnline = userA.lastOnline
    ? new Date(userA.lastOnline).getTime()
    : a.createdAt || 0
  let userBLastOnline = userB.lastOnline
    ? new Date(userB.lastOnline).getTime()
    : b.createdAt || 0

  let byOnline = userBOnlineStatus - userAOnlineStatus
  let sameOnline = byOnline === 0

  return sameOnline ? userBLastOnline - userALastOnline : byOnline
}
