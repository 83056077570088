export async function writeImageToClipboard(imagePath: string) {
  try {
    const data = await fetch(imagePath, {
      mode: 'cors',
    })
    const blob = await data.blob()

    await navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob,
      }),
    ])
  } catch (error) {
    alert('Could not copy. We will fix it soon.')
    console.error(error.name, error.message)
  }
}
