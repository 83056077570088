import { safeRequestIdleCallback } from '@there/components/utils/schedulers'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { View } from 'react-native'
import { Emoji, usePicker } from '@there/components/emoji-picker/use-picker'
import { GifMedia } from '@there/components/emoji-picker/use-tenor'
import { calculatePopoverPosition } from './calculate-popover-position'
import { useLatest } from './use-latest'
import { useDimensions } from './useDimensions'

type Position = {
  top: number | undefined
  left: number | undefined
  right: number | undefined
  bottom: number | undefined
}

export type PickerKeys =
  | 'createRoom'
  | 'nudge'
  | 'editRoom'
  | 'newGroup'
  | 'chat'
  | 'status'
  | 'statusReaction'

/**
 * CONTEXT
 */

type OpenProps = {
  key: PickerKeys
  ref: React.RefObject<View>
  isFullScreen?: boolean
  initialMode?: PickerMode
  activeModes?: PickerMode[]
}

export interface PickerContextValue {
  isMount: boolean
  isOpen: boolean
  isFullScreen: boolean
  position: Position | undefined
  selectedEmoji: { [key in PickerKeys]?: Emoji | undefined } | undefined
  selectedGif: { [key in PickerKeys]?: GifMedia | undefined } | undefined
  pickerKey: PickerKeys | undefined
  activeModes: PickerMode[]
  initialMode: PickerMode

  mount(): void
  unMount(): void
  open({}: OpenProps): void
  clearEmoji(key: PickerKeys): void
  clearGif(key: PickerKeys): void
  close(): void

  onEmojiPress(emoji: Emoji): void
  onGifPress(gif: GifMedia): void

  setPickerToggleHovered: Dispatch<SetStateAction<boolean>>
  setPickerContentHovered: Dispatch<SetStateAction<boolean>>
}

export type PickerContextManager = PickerContextValue
export type PickerManager = PickerContextValue

export type PickerMode = 'emoji' | 'gif'

let voidFunction = () => {}

const initialContext: PickerContextManager = {
  isMount: true,
  isOpen: false,
  isFullScreen: false,
  position: undefined,
  selectedEmoji: undefined,
  selectedGif: undefined,
  pickerKey: undefined,
  activeModes: ['emoji', 'gif'],
  initialMode: 'emoji',

  mount: voidFunction,
  unMount: voidFunction,
  open: voidFunction,
  close: voidFunction,
  onEmojiPress: voidFunction,
  onGifPress: voidFunction,
  clearEmoji: voidFunction,
  clearGif: voidFunction,

  setPickerToggleHovered: voidFunction,
  setPickerContentHovered: voidFunction,
}

export const PickerContext = createContext<PickerContextManager>(initialContext)
export const usePickerContext = () => {
  return useContext(PickerContext)
}

/**
 * MANAGET HOOK
 */

export const usePickerManager = (): PickerContextManager => {
  let { config } = usePicker()
  let [preMount, setPreMount] = useState(false)
  let [isMount, setMount] = useState(true)
  // let [isMount, setMount] = useState(false)
  let [isOpen, setOpen] = useState(false)
  let [isFullScreen, setFullScreen] = useState(false)
  let [activeModes, setActiveModes] = useState<PickerMode[]>(['emoji', 'gif'])
  let [initialMode, setInitialMode] = useState<PickerMode>('emoji')
  let [position, setPosition] = useState<Position | undefined>(undefined)
  let [selectedEmoji, setEmoji] = useState<
    { [key in PickerKeys]?: Emoji | undefined } | undefined
  >(undefined)
  let [selectedGif, setGif] = useState<
    { [key in PickerKeys]?: GifMedia | undefined } | undefined
  >(undefined)

  let [pickerKey, setPickerKey] = useState<PickerKeys | undefined>(undefined)

  let [pickerToggleHovered, setPickerToggleHovered] = useState(false)
  let [pickerContentHovered, setPickerContentHovered] = useState(false)

  let mount = useCallback(() => {
    // safeRequestIdleCallback(
    //   () => {
    //     setPreMount(true)
    //   },
    //   { timeout: 400 },
    // )
  }, [])

  useEffect(() => {
    if (preMount) {
      setMount(true)
      setPreMount(false)
    }
  }, [preMount])

  let unMount = useCallback(() => {
    // setMount(false)
    setPickerKey(undefined)
    setEmoji(undefined)
    setGif(undefined)
  }, [])

  let { window } = useDimensions()

  let open = useCallback(
    ({
      key,
      ref,
      isFullScreen,
      initialMode = 'emoji',
      activeModes = ['emoji', 'gif'],
    }: OpenProps) => {
      ref.current?.measureInWindow((x, y, width, height) => {
        let position = calculatePopoverPosition({
          offset: 8,
          window,
          toggleBounds: {
            x,
            y,
            width,
            height,
          },
          popoverDimensions: {
            width: config.containerWidth,
            height: config.containerHeight,
          },
        })
        setFullScreen(Boolean(isFullScreen))
        setPosition(position)
      })
      setActiveModes(activeModes)
      setInitialMode(initialMode)
      setPickerKey(key)
      setOpen(true)
    },
    [window, config],
  )

  let close = useCallback(() => {
    setOpen(false)
    // setPosition(undefined)
  }, [])

  let pickerKeyRef = useLatest(pickerKey)

  let onEmojiPress = useCallback(
    (emoji: Emoji) => {
      let state: Record<string, Emoji> = {}
      if (!pickerKeyRef.current) return
      state[pickerKeyRef.current] = emoji
      setEmoji(state)
    },
    // SUPER IMPORTANT TO NOT HAVE ANY DEPENDENCY
    [pickerKeyRef],
  )

  let clearEmoji = useCallback((pickerKey: PickerKeys) => {
    setEmoji((map) => ({ ...(map || null), [pickerKey]: undefined }))
  }, [])

  let onGifPress = useCallback(
    (gif: GifMedia) => {
      let state: Record<string, GifMedia> = {}
      if (!pickerKeyRef.current) return
      state[pickerKeyRef.current] = gif
      setGif(state)
    },
    [pickerKeyRef],
  )

  let clearGif = useCallback((pickerKey: PickerKeys) => {
    setGif((map) => ({ ...(map || null), [pickerKey]: undefined }))
  }, [])

  // manage open chat picker by hover
  let toggleHoverRef = useRef(false)
  let contentHoverRef = useRef(false)
  let pickerContentHoveredRef = useLatest(pickerContentHovered)
  let pickerToggleHoveredRef = useLatest(pickerToggleHovered)

  useEffect(() => {
    let timer: number
    if (pickerKey !== 'chat') return
    if (!pickerToggleHovered && toggleHoverRef.current) {
      timer = setTimeout(() => {
        if (pickerContentHoveredRef.current || pickerToggleHoveredRef.current)
          return
        toggleHoverRef.current = false
        close()
      }, 150)
    }

    if (pickerToggleHovered) {
      toggleHoverRef.current = true
      return
    }
  }, [
    close,
    pickerContentHoveredRef,
    pickerKey,
    pickerToggleHovered,
    pickerToggleHoveredRef,
  ])

  useEffect(() => {
    if (pickerKey !== 'chat') return
    let timer: number
    if (contentHoverRef.current && !pickerContentHovered) {
      timer = setTimeout(() => {
        if (pickerContentHoveredRef.current || pickerToggleHoveredRef.current)
          return
        contentHoverRef.current = false
        close()
      }, 150)
    }
    if (pickerContentHovered) {
      contentHoverRef.current = true
    }

    return () => clearTimeout(timer)
  }, [
    close,
    pickerContentHovered,
    pickerContentHoveredRef,
    pickerKey,
    pickerToggleHoveredRef,
  ])

  const value: PickerContextManager = useMemo(() => {
    return {
      isMount,
      isOpen,
      isFullScreen,
      position,
      selectedEmoji,
      selectedGif,
      pickerKey,
      activeModes,
      initialMode,

      mount,
      unMount,
      open,
      close,
      onEmojiPress,
      clearEmoji,
      onGifPress,
      clearGif,
      setPickerToggleHovered,
      setPickerContentHovered,
    }
  }, [
    isMount,
    isOpen,
    isFullScreen,
    position,
    selectedEmoji,
    selectedGif,
    pickerKey,
    activeModes,
    initialMode,
    mount,
    unMount,
    open,
    close,
    onEmojiPress,
    clearEmoji,
    onGifPress,
    clearGif,
  ])
  return value
}
