import { memo } from 'react'
import { Tooltip } from '../shared/Tooltip'
import { CircleButton } from '../main/CircleButton'
import { ScreenShareIcon } from '../shared/ScreenShareIcon'
import { useTheme } from '@there/components/feed/ThemeContext'
import { rtcCoreAtom } from '@there/components/shared/use-rtc'
import { useAtom } from 'jotai'
import { isElectron, useListenToIpc } from '@there/desktop/utils/electron-api'
import { useCurrentMediaDevicesContext } from '@there/components/shared/CurrentMediaDevicesContext'
import { permissionsAtom } from '@there/components/shared/use-media-permissions'
import { useModals } from '@there/components/feed/ModalsContext'
import { GoodButton } from '@there/components/shared/GoodButton'

export const ScreenShareButton = memo(() => {
  let theme = useTheme()
  let [{ toggleScreenSharing, isSharingScreen }] = useAtom(rtcCoreAtom)
  let [{ ensureHasAccess }] = useAtom(permissionsAtom)
  let { dispatch } = useCurrentMediaDevicesContext()

  useListenToIpc('screen-picked', (event, data) => {
    if (!data) return

    ensureHasAccess(['screen'])

    // Display Picked
    dispatch({
      type: 'preferred source changed',
      source: data.source,
    })
    dispatch({
      type: 'preferred display changed',
      display: data.display,
    })

    // Start screen share
    toggleScreenSharing()

    // Do not continue
    return
  })

  let [, modalsDispatch] = useModals()

  return (
    <Tooltip
      label={
        <>
          <span>Share screen</span>
          <br />
          <span className="light">Beta</span>
        </>
      }
      placement="bottomCenter"
    >
      <GoodButton
        activeColor="screenShareButtonIcon"
        active={isSharingScreen}
        variant="transparent"
        onPress={() => {
          if (isSharingScreen) {
            toggleScreenSharing()
            return
          }

          if (!isElectron) {
            toggleScreenSharing()
            return
          }

          modalsDispatch({ type: 'modal opened', modalName: 'pickSource' })
        }}
        icon={
          <ScreenShareIcon
            color={theme.colors.white}
            opacity={isSharingScreen ? 1 : 0.6}
          />
        }
      />
    </Tooltip>
  )
})
