import { StandardButton } from '@there/components/main/StandardButton'
import { ThemedText } from '@there/components/shared/ThemedText'
import { useHover } from '@there/components/shared/use-hover'
import React from 'react'
import { Switch, Text, View, StyleSheet } from 'react-native'

type Props = {
  title: string
  description: string
  disabledReason?: string
  buttonText: string
  onPress: () => void
  disabled?: boolean
}

export const ButtonRow = ({
  title,
  description,
  disabledReason,
  buttonText,
  disabled,
  onPress,
}: Props) => {
  let [hovered, hoverListener] = useHover()

  return (
    <View
      style={[
        style.wrapper,
        hovered && { backgroundColor: `rgba(255,255,255,0.05)` },
      ]}
      {...hoverListener}
    >
      <View style={style.text}>
        <ThemedText numberOfLines={1} fontSize="normal" color="secondaryText">
          {title}
        </ThemedText>
        <ThemedText
          numberOfLines={2}
          fontSize="small"
          color="tertiaryText"
          style={{ marginTop: 2, width: '100%' }}
        >
          {disabledReason ? (
            <Text
              style={{
                textDecorationLine: 'underline',
                textDecorationStyle: 'dotted',
              }}
            >
              ⚠️ {disabledReason}
            </Text>
          ) : (
            ''
          )}
          {disabledReason ? ' • ' : ''}
          {description}
        </ThemedText>
      </View>
      <View
        style={{
          alignItems: 'flex-end',
          width: 60,
          position: 'relative',
        }}
      >
        <StandardButton
          text={buttonText}
          backgroundColor="primaryButtonBackground"
          textColor="secondaryText"
          onPress={onPress}
          textStyle={{
            width: '100%',
            textAlign: 'center',
          }}
        />
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 6,
    paddingBottom: 6,
    paddingHorizontal: 6,
    marginLeft: -6,
    marginRight: -6,
    borderRadius: 10,
  },
  text: {
    flexGrow: 1,
    flexShrink: 1,
    paddingRight: 14,
  },
})
