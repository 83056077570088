import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

function SvgComponent(props: SvgProps & { color?: string }) {
  return (
    <Svg width={12} height={12} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        d="M9 1v8m0 8V9m0 0h8M9 9H1"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SvgComponent
