import { useRoomsScroll } from '@there/components/feed/roomsScroll'
import { useTheme } from '@there/components/feed/ThemeContext'
import { EmojiView } from '@there/components/main/EmojiView'
import { Pressable } from '@there/components/shared/Pressable'
import { useHover } from '@there/components/shared/use-hover'
import { DialogInfo } from '@there/sun/utils/node-types'
import { useMemo } from 'react'
import { Text } from 'react-native'

export const InlineDialogLink = ({
  dialog,
  fontSize,
}: {
  dialog: DialogInfo | null
  fontSize: number
}) => {
  let theme = useTheme()
  let [hovered, hoverListener] = useHover()

  let { scrollTo } = useRoomsScroll()

  return (
    <>
      <Pressable
        style={({ hovered }) => [
          hovered && { backgroundColor: 'rgba(255,255,255,0.1)' },
          {
            borderRadius: 3,
            paddingLeft: 4,
            paddingRight: 4,
            marginLeft: -4,
            flexDirection: 'row',
            height: fontSize + 4,
          },
        ]}
        onPress={() => {
          if (!dialog) return
          scrollTo(dialog?.id)
        }}
      >
        {dialog?.iconEmoji && (
          <Text>
            <EmojiView
              size={14}
              children={dialog?.iconEmoji || ''}
              marginRight={2}
            />{' '}
          </Text>
        )}
        <Text numberOfLines={1}>{dialog?.title}</Text>
      </Pressable>
    </>
  )
}
