import gql from 'graphql-tag'
import { useMutation } from 'urql'
import { DialogInfo, dialogInfoFragment } from './fragments/dialogInfo'

export const uploadBackground = gql`
  mutation uploadBackground(
    $file: Upload!
    $dialogId: String!
    $bgDarkenPercent: Int
  ) {
    dialog: uploadBackground(
      file: $file
      dialogId: $dialogId
      bgDarkenPercent: $bgDarkenPercent
    ) {
      ...dialogInfo
    }
  }
  ${dialogInfoFragment}
`

export type Variables = {
  file: File
  dialogId: string
  bgDarkenPercent?: number
}
export type Data = {
  dialog?: DialogInfo
}

export const useUploadBackground = () =>
  useMutation<Data, Variables>(uploadBackground)
