import { SunPolicies } from '@there/components/sun/policies'
import { makeLocalStorageStore, Store } from '@there/components/sun/store'
import {
  normalizeData,
  resolveData,
  resolveDataExperimental,
  resolveDependencyIds,
} from '@there/components/sun/utils/normalize'
import { stringifyVariables } from '@there/components/sun/utils/stringifyVariables'
import {
  Sink,
  Query,
  Request,
  Cache,
  Result,
  ResultCallback,
  RequestId,
  Subscription,
  SubscriptionResult,
  NodeSubscriptionCallback,
  NodeQuery,
} from '@there/components/sun/utils/types'
import { safeRequestIdleCallback } from '@there/components/utils/schedulers'
import { JQLClientMessageKind } from '@there/sun/ws/protocol'

export type UpdateFunction = (data: any, args: any, cache: Cache) => void
export type OptimisticFunction = (args: any, cache: Cache) => QueryData
type Updates = Record<string, UpdateFunction>
type OptimisticUpdates = Record<string, OptimisticFunction>

interface CacheOptions {
  updates?: { mutation: Updates; subscription: Updates }
  optimistic?: OptimisticUpdates
  policies?: SunPolicies
}

type CacheDataStore = Record<string, DataEntity>

export class DefaultCache implements Cache {
  private store: Store
  private cache: CacheDataStore

  /** Request key to cache patches - clear when result comes from server */
  private optimisticCachePatches: Map<string, Record<string, DataEntity>>

  private updates: { mutation: Updates; subscription: Updates }
  private optimistic: OptimisticUpdates
  private policies: SunPolicies
  // keep a subscriber list
  // to use for updating other queries

  /** Node id to queries that contain it
   * NodeId -> query cache keys []
   */
  private nodesToQueryKey: Record<string, Set<string>>
  /**
   * active query subscribers
   * (used to call when there is an update to one of their dependencies)
   * query cache key to result callbacks
   */
  private querySubscribers: Map<string, Set<ResultCallback>>

  private nodeSubscribers: Map<
    // node id
    string,
    Set<NodeSubscriptionCallback>
  >

  constructor(options: CacheOptions) {
    this.store = makeLocalStorageStore()
    this.cache = {}
    this.updates = options.updates || { mutation: {}, subscription: {} }
    this.optimistic = options.optimistic || {}
    this.policies = options.policies || { Query: {} }
    this.optimisticCachePatches = new Map()
    this.nodesToQueryKey = {}
    this.querySubscribers = new Map()
    this.nodeSubscribers = new Map()

    // eslint-disable-next-line no-console
    console.time('Read cache')
    // Rehydrate
    this.store.readData().then((fromStore) => {
      this.cache = {
        ...fromStore,
        // if we have new data, override cache
        ...this.cache,
      }

      // Necessary for offline mode
      this.hydrateDependencyGraph(this.cache)

      // eslint-disable-next-line no-console
      console.timeEnd('Read cache')
    })

    this.writeToCache = this.writeToCache.bind(this)
    this.writeNode = this.writeNode.bind(this)
    this.writeQuery = this.writeQuery.bind(this)
    this.broadcastNodeUpdates = this.broadcastNodeUpdates.bind(this)
    this.readCacheKey = this.readCacheKey.bind(this)
  }

  request(request: Request, outputSink: Sink) {
    if (request.kind === JQLClientMessageKind.Query) {
      // get from cache
      let data = this.readQuery(request)

      if (data !== 'null') {
        // call when you have new result from cache
        outputSink.next({
          data,
          error: null,
          fetching: true,
          id: request.id,
          stale: true,
        })
      }

      // subscribe to cache updates
      this.subscribeToUpdates(request, outputSink.next)
    } else if (request.kind === JQLClientMessageKind.Mutation) {
      let optimisticResult = this.registerOptimisticResult(request)

      if (optimisticResult) {
        // Mutations optimistic response
        // immediately send optimistic data
        outputSink.next(optimisticResult)
      }
    }

    return {
      next: (result: Result): Result => {
        // new result from network has come in
        // store.write
        let normalizedData

        switch (request.kind) {
          case JQLClientMessageKind.Query:
            ;({ normalizedData } = this.writeQuery(request, result.data))
            break

          // if type mutation, update all cache request subscribers
          case JQLClientMessageKind.Mutation:
            // Moved up by mo in v1
            this.cancelOptimisticResult(request)

            // @mo:
            // If only we got result from server, call update
            // (for optimistic, it calls itself, for cache stale, no need as well)
            // update other cache entries
            ;({ normalizedData } = this.mutationUpdate(result, {
              writeToCache: this.writeToCache,
            }))

            // Call custom update function
            this.updates.mutation[request.method]?.(
              result.data,
              request.variables,
              this,
            )

            break
        }

        return this.applyOptimisticLocksToResult({ result, normalizedData })
      },
      complete: () => {
        // unsubscribe from cache updates
        this.unsubscribeFromUpdates(request, outputSink.next)

        // TODO: Check if no subscribers left, delete deps graph
      },
    }
  }

  onEvent(subscription: Subscription, result: SubscriptionResult) {
    // Call custom update on subscription
    this.updates.subscription[subscription.method]?.(
      result.data,
      subscription.variables,
      this,
    )
  }

  applyOptimisticLocksToResult({
    result,
    normalizedData,
  }: {
    result: Result
    normalizedData: QueryData | undefined
  }): Result {
    // Don't change if there is no data
    if (!normalizedData) return result

    // Now the optimistic results are applied on top
    let computedData = resolveDataExperimental(
      this.readCacheKey,
      normalizedData,
    )
    // let computedData = resolveData(this.computedCache, normalizedData)

    return { ...result, data: computedData }
  }

  /** Create an optimistic result */
  registerOptimisticResult(request: Request): Result | null {
    if (!this.optimistic[request.method]) {
      // No optimistic response
      return null
    }

    let temporaryCache = new TemporaryCache({
      cache: this.cache,
      policies: this.policies,
    })

    // Generate
    let optimisticData = this.optimistic[request.method]?.(
      request.variables,
      temporaryCache,
    )

    // Update our temp cache
    this.updates.mutation[request.method]?.(
      optimisticData,
      request.variables,
      temporaryCache,
    )

    let result: Result = {
      data: optimisticData,
      // Important
      stale: true,
      error: null,
      fetching: true,
      id: request.id,
    }

    // update other cache entries
    this.mutationUpdate(result, {
      // Don't save on the main cache
      writeToCache: temporaryCache.writeToCache,
    })

    // Get cache key
    let optimisticCacheKey = getMutationCacheKey(request)

    // Save in patches
    // Save reference to clear later
    this.updateOptimisticPatches(optimisticCacheKey, temporaryCache.cache)

    // Update all affected components
    this.broadcastNodeUpdates(temporaryCache.cache)

    // construct
    return result
  }

  /** When a new mutation optimistic result comes, or should be cancelled use this */
  private updateOptimisticPatches(
    mutationKey: string,
    /** Set to null to remove it */
    patch: CacheDataStore | null,
  ) {
    // Save patch
    if (patch !== null) {
      this.optimisticCachePatches.set(mutationKey, patch)
    } else {
      this.optimisticCachePatches.delete(mutationKey)
    }
  }

  /** After mutation result, call this */
  private cancelOptimisticResult(request: Request): void {
    // Get cache key
    let optimisticCacheKey = getMutationCacheKey(request)

    // Delete
    this.updateOptimisticPatches(optimisticCacheKey, null)
  }

  /** Write to cache
   * @returns Normalized data with __links
   */
  writeQuery<Data = QueryData>(
    query: Query,
    data: Data,
  ): { normalizedData: QueryData } {
    let cacheKey = this.getQueryCacheKey(query)
    let toProcessData = data

    // If has merge function
    let mergeFunction = this.policies.Query[query.method]?.merge
    if (mergeFunction) {
      toProcessData = mergeFunction(toProcessData, this.readQuery(query), {
        variables: query.variables,
      })
    }

    // normalize
    let [rootData, dependencies] = normalizeData(
      (toProcessData as unknown) as QueryData,
    )

    this.saveDependencyGraph(query, dependencies)
    this.writeToCache({
      [cacheKey]: rootData,
      ...dependencies,
    })

    // Send to node subs
    // let computedCache = this.computedCache
    for (let depId in dependencies) {
      // this.broadcastToNodeSubscribers(depId, { computedCache })
      this.broadcastToNodeSubscribers(depId)
    }

    // Send to query subscribers
    this.broadcastUpdate(this.getQueryCacheKey(query))

    return { normalizedData: rootData }
  }

  /** Read from cache */
  readQuery<Data = QueryData>(query: Query): Data | null {
    return this.readQueryByCacheKey<Data>(this.getQueryCacheKey(query))
  }

  /**
   * Cache plus applied optimistic patches.
   * This is the source of truth for UI stuff.
   * (For internal stuff use this.cache)
   *
   * It had serious performance issues, and got deleted
   */
  // private get computedCache()

  private readCacheKey(key: string) {
    // TODO: Should reverse???
    // TODO: Should reverse???
    // TODO: Should reverse???
    // Construct cache by applying patches
    for (let [, optimisticPatch] of this.optimisticCachePatches) {
      if (
        key in optimisticPatch &&
        typeof optimisticPatch[key] !== 'undefined'
      ) {
        return optimisticPatch[key]
      }
    }

    return this.cache[key]
  }

  /** Read from cache */
  private readQueryByCacheKey<Data = QueryData>(cacheKey: string): Data | null {
    if (!this.cache[cacheKey]) return null

    // Update out memoized cache
    // normalize
    let data = (resolveDataExperimental(
      this.readCacheKey,
      this.readCacheKey(cacheKey),
    ) as unknown) as Data

    return data
  }

  /** Add query and dependencies to cache */
  private writeToCache(entities: Record<string, DataEntity>) {
    for (let key in entities) {
      this.cache[key] = {
        // @ts-ignore
        ...(typeof this.cache[key] === 'object' ? this.cache[key] : {}),
        // @ts-ignore
        ...(entities[key] || {}),
      }
    }

    this.persist()
  }

  /**
   * Updates query using the given updater function
   * and broadcasts updates.
   */
  updateQuery<Data = QueryData>(
    query: Query,
    updater: (data: Data | null) => Data | null,
  ) {
    try {
      let data = this.readQuery<Data>(query)
      let result = updater(data)
      if (!result) return
      this.writeQuery<Data>(query, result)

      //// @mo
      // this.broadcastUpdate(this.getQueryCacheKey(query))
    } catch (error) {
      console.warn('[Nur] updateQuery failed for query =', query, error)
    }
  }

  /**
   * Called initially when hydrating cache from store
   */
  private hydrateDependencyGraph(cache: CacheDataStore) {
    for (let key in cache) {
      if (isQueryCacheKey(key)) {
        let depIds = resolveDependencyIds(cache[key])
        // Save node ids
        for (let depId of depIds) {
          if (this.nodesToQueryKey[depId]) {
            this.nodesToQueryKey[depId].add(key)
          } else {
            this.nodesToQueryKey[depId] = new Set([key])
          }
        }
      }
    }
  }

  /**
   * Called after each writeQuery
   */
  private saveDependencyGraph(
    query: Query,
    dependencies: Record<string, DataEntity>,
  ) {
    let cacheKey = this.getQueryCacheKey(query)
    for (let key in dependencies) {
      if (this.nodesToQueryKey[key]) {
        this.nodesToQueryKey[key].add(cacheKey)
      } else {
        this.nodesToQueryKey[key] = new Set([cacheKey])
      }
    }
  }

  private deleteDependencyGraph(request: Request) {
    let cacheKey = this.getQueryCacheKey(request)
    for (let key in this.nodesToQueryKey) {
      this.nodesToQueryKey[key].delete(cacheKey)
    }
  }

  private subscribeToUpdates(request: Request, callback: ResultCallback) {
    let cacheKey = this.getQueryCacheKey(request)
    if (this.querySubscribers.get(cacheKey)) {
      this.querySubscribers.get(cacheKey)?.add(callback)
    } else {
      this.querySubscribers.set(cacheKey, new Set([callback]))
    }
  }

  private unsubscribeFromUpdates(request: Request, callback: ResultCallback) {
    let cacheKey = this.getQueryCacheKey(request)
    this.querySubscribers.get(cacheKey)?.delete(callback)
  }

  private broadcastNodeUpdates(nodes: CacheDataStore) {
    if (!nodes || typeof nodes !== 'object') return

    // Now propagate
    for (let nodeKey in nodes) {
      // to update queries
      let queryKeys = this.nodesToQueryKey[nodeKey]

      if (!queryKeys) continue

      for (let queryKey of queryKeys) {
        let queryCallbacks = this.querySubscribers.get(queryKey)
        if (!queryCallbacks) continue

        this.broadcastUpdate(queryKey)
        // let data = this.readQueryByCacheKey(queryKey)

        // for (let callback of queryCallbacks) {
        //   // call when you have new result from cache
        //   callback({
        //     data,
        //     error: null,
        //     // todo: only send patches
        //     fetching: false,
        //     stale: false,
        //     // should replace Id?
        //     // set only until we can patch it
        //     id: ('result.id' as any) as RequestId,
        //   })
        // }
      }

      // Update node subscribers
      // let computedCache = this.computedCache
      // this.broadcastToNodeSubscribers(nodeKey, { computedCache })
      this.broadcastToNodeSubscribers(nodeKey)
    }
  }

  /** Calls node subscribers callback on update */
  private broadcastToNodeSubscribers(
    nodeKey: string,
    context?: { computedCache: CacheDataStore },
  ) {
    let nodeSubscribers = this.nodeSubscribers.get(nodeKey)
    // let computedCache = context?.computedCache || this.computedCache
    if (nodeSubscribers) {
      for (let nodeSubscriber of nodeSubscribers) {
        let node = this.readCacheKey(nodeKey)
        // let node = computedCache[nodeKey]

        nodeSubscriber(
          //@ts-ignore
          node,
        )
      }
    }
  }

  private mutationUpdate(
    result: Result,
    {
      writeToCache,
    }: { writeToCache(entities: Record<string, DataEntity>): void },
  ): { normalizedData: QueryData } {
    if (!result.data) return { normalizedData: result.data }

    let [normalizedData, dependencies] = normalizeData(result.data)

    // Update cache
    writeToCache(dependencies)

    if (!dependencies || typeof dependencies !== 'object') {
      return { normalizedData: normalizedData }
    }

    // Now propagate
    this.broadcastNodeUpdates(dependencies)

    return { normalizedData }
  }

  readNode(node: ObjectNode) {
    return this.cache[getNodeKey(node)] as ObjectNode
  }

  /** To update a single node (avatar, dialog, etc) in cache */
  writeNode(node: ObjectNode) {
    let existingNode = this.readNode(node)

    let mergedNode =
      typeof existingNode === 'object' && typeof node === 'object'
        ? {
            // Merge if object
            ...existingNode,
            ...node,
          }
        : // Just set
          node

    let [rootNode, dependencies] = normalizeData(mergedNode)

    // Update node in cache
    this.writeToCache({
      [getNodeKey(node)]: rootNode,
      ...dependencies,
    })

    // Broadcast
    this.broadcastUpdatesForNode(getNodeKey(node))
  }

  invalidate(node: ObjectNode) {
    let cacheKey = getNodeKey(node)

    delete this.cache[cacheKey]

    this.persist()
  }

  /**
   * Broadcasts last query to any query containing this node
   */
  private broadcastUpdatesForNode(nodeKey: string) {
    this.broadcastToNodeSubscribers(nodeKey)

    // Queries
    let queryKeys: Set<string> | undefined = this.nodesToQueryKey[nodeKey]

    if (!queryKeys) {
      return
    }

    for (let queryKey of queryKeys) {
      // requestIdleCallback(
      //   () => {
      this.broadcastUpdate(queryKey)
      //   },
      //   { timeout: 50 },
      // )
    }
  }

  /**
   * Broadcasts new data to the specified query
   */
  private broadcastUpdate(queryCacheKey: string) {
    let queryCallbacks = this.querySubscribers.get(queryCacheKey)

    if (!queryCallbacks) return

    let data = this.readQueryByCacheKey(queryCacheKey)

    for (let callback of queryCallbacks) {
      // requestAnimationFrame(() => {
      // call when you have new result from cache
      callback({
        data,
        error: null,
        // todo: only send patches
        fetching: false,
        stale: false,
        // should replace Id?
        // set only until we can patch it
        id: ('result.id' as any) as RequestId,
      })
      // })
    }
  }

  /** Register a callback so when a node changes, it gets called with the fresh node */
  subscribeToNode(node: NodeQuery, callback: NodeSubscriptionCallback) {
    let nodeId = node.id
    // subscribe to node
    let subscribersSet = this.nodeSubscribers.get(nodeId)

    if (subscribersSet) {
      subscribersSet.add(callback)

      if (subscribersSet.size > 500) {
        console.error(
          '[NurClient] Unusual number of subscribers registered for a node',
        )
      }
    } else {
      subscribersSet = new Set([callback])
    }
    this.nodeSubscribers.set(nodeId, subscribersSet)

    return {
      unsubscribe: () => {
        let subscribersSet = this.nodeSubscribers.get(nodeId)
        subscribersSet?.delete(callback)
      },
    }
  }

  persistCallbackId: number | undefined = undefined
  persistTimeoutId: number | undefined = undefined

  /** Save in memory cache to Store */
  private persist() {
    if (this.persistTimeoutId) clearTimeout(this.persistTimeoutId)

    // Allow some time for immediate responses
    this.persistTimeoutId = setTimeout(() => {
      // Safari doesn't support this
      if (this.persistCallbackId) cancelIdleCallback(this.persistCallbackId)
      this.persistCallbackId = safeRequestIdleCallback(
        () => {
          this.store.writeData(this.cache)
        },
        { timeout: 1000 },
      )
    }, 500)
  }

  clear() {
    this.cache = {}
    this.store.clear()
  }

  /** Take into account type policies (eg. keyArgs) */
  getQueryCacheKey(query: Query) {
    return getQueryCacheKey(query, this.policies.Query[query.method]?.keyArgs)
  }
}

// -----------------------------
// ---------- TYPES ------------
// -----------------------------

// type QueryData = Record<string, any> | any[]
export type QueryData = DataValues | DataValues[]
export type Nodes = Record<string, DataValues | DataValues[]>
export interface ObjectNode {
  id: string
  __typename?: string
  [key: string]:
    | string
    | number
    | boolean
    | null
    | Record<string, any>
    | any[]
    | undefined
}
export interface Link {
  /** id of a node or nodes  */
  __link: string | string[]
}
type Entity = ObjectNode | Link
export type ObjectData = { [id: string]: DataValues }
export type DataValues = Entity | ObjectData | null | string | number | boolean
export type DataValuesList = DataValues | DataValues[]
export type DataEntity = DataValues | DataValues[]

// -----------------------------
// --------- HELPERS -----------
// -----------------------------
function getNodeKey(node: ObjectNode) {
  return node.id
}

const QueryCacheKeyPrefix = `Query.`
function getQueryCacheKey(query: Query, keyArgs?: string[]): string {
  let firstPart = `${query.method}`
  let secondPart = `${stringifyVariables(query.variables, keyArgs)}`

  return `${QueryCacheKeyPrefix}${firstPart}(${secondPart})`
}
function isQueryCacheKey(key: string): boolean {
  return key.startsWith(QueryCacheKeyPrefix)
}

const MutationCacheKeyPrefix = `Mutation.`
function getMutationCacheKey(mutation: Query, keyArgs?: string[]): string {
  let firstPart = `${mutation.method}`

  // hot patch workaround for dialog jumping out because Mo was lazy
  let variablesClone = { ...mutation.variables }
  if ('newSessionId' in variablesClone) {
    delete variablesClone.newSessionId
  }
  // end hot patch

  let secondPart = `${stringifyVariables(variablesClone, keyArgs)}`

  return `${MutationCacheKeyPrefix}${firstPart}(${secondPart})`
}

// TODO: Make it base for default cache to extend
class TemporaryCache implements Cache {
  public initialCache: CacheDataStore
  public cache: CacheDataStore
  private policies: SunPolicies

  constructor({
    cache,
    policies,
  }: {
    /** Main cache */
    cache: CacheDataStore
    policies: SunPolicies
  }) {
    this.initialCache = cache
    this.cache = {}
    this.policies = policies

    this.writeToCache = this.writeToCache.bind(this)
    this.writeQuery = this.writeQuery.bind(this)
    this.readQueryByCacheKey = this.readQueryByCacheKey.bind(this)
    this.writeNode = this.writeNode.bind(this)
  }

  /** Add query and dependencies to cache */
  writeToCache(entities: Record<string, DataEntity>) {
    for (let key in entities) {
      this.cache[key] = {
        // @ts-ignore
        ...(typeof this.cache[key] === 'object' ? this.cache[key] : {}),
        // @ts-ignore
        ...entities[key],
      }
    }
  }

  /** Take into account type policies (eg. keyArgs) */
  getQueryCacheKey(query: Query) {
    return getQueryCacheKey(query, this.policies.Query[query.method]?.keyArgs)
  }

  /** Write to cache */
  writeQuery<Data = QueryData>(query: Query, data: Data) {
    let cacheKey = this.getQueryCacheKey(query)

    // normalize
    let [rootData, dependencies] = normalizeData((data as unknown) as QueryData)

    this.writeToCache({
      [cacheKey]: rootData,
      ...dependencies,
    })
  }

  /** Read from cache */
  readQuery<Data = QueryData>(query: Query): Data | null {
    return this.readQueryByCacheKey<Data>(this.getQueryCacheKey(query))
  }

  /** Read from cache */
  private readQueryByCacheKey<Data = QueryData>(cacheKey: string): Data | null {
    let rootNodeInCache =
      cacheKey in this.cache
        ? this.cache[cacheKey]
        : this.initialCache[cacheKey]
    if (!rootNodeInCache) return null

    // normalize
    let data = (resolveData(
      { ...this.initialCache, ...this.cache },
      rootNodeInCache,
    ) as unknown) as Data

    return data
  }

  /** To update a single node (avatar, dialog, etc) in cache */
  writeNode(node: ObjectNode) {
    let existingNode = this.readNode(node)

    let mergedNode =
      typeof existingNode === 'object' && typeof node === 'object'
        ? {
            // Merge if object
            ...existingNode,
            ...node,
          }
        : // Just set
          node

    let [rootNode, dependencies] = normalizeData(mergedNode)

    // Update node in cache
    this.writeToCache({
      [getNodeKey(node)]: rootNode,
      ...dependencies,
    })
  }

  readNode(node: ObjectNode) {
    let key = getNodeKey(node)
    return (key in this.cache
      ? this.cache[key]
      : this.initialCache[key]) as ObjectNode | null
  }

  /**
   * Updates query using the given updater function
   * and broadcasts updates.
   */
  updateQuery<Data = QueryData>(
    query: Query,
    updater: (data: Data | null) => Data | null,
  ) {
    try {
      let data = this.readQuery<Data>(query)
      let result = updater(data)
      if (!result) return
      this.writeQuery<Data>(query, result)
    } catch (error) {
      console.warn('[Nur] updateQuery failed for query =', query, error)
    }
  }

  invalidate(node: ObjectNode) {
    let cacheKey = getNodeKey(node)

    delete this.cache[cacheKey]
  }

  request(request: Request, sink: Sink<Result>) {
    return { next: (r: Result) => r, complete: () => {} }
  }

  subscribeToNode(node: any, callback: any) {
    return { unsubscribe: () => {} }
  }
}
