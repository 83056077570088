import { useTheme } from 'components/feed/ThemeContext'
import { logEvent } from '../shared/use-analytics'
import { PinControlButton } from './PinControlButton'
import { MicrophoneIcon } from '@there/components/shared/MicrophoneIcon'
import { useRtcContext } from '@there/components/shared/use-rtc'
import { Tooltip } from '@there/components/shared/Tooltip'

export const PinMicrophoneButton = () => {
  let { walkieState, toggleTalk } = useRtcContext()
  const theme = useTheme()

  return (
    <Tooltip
      label={`${walkieState.weTalking ? 'Mute' : 'Enable mic'}`}
      size="mini"
    >
      <PinControlButton
        onPress={() => {
          toggleTalk()
          logEvent('User Toggeled Mic - Mic click', {
            from: 'pin',
          })
        }}
        activeColor="green"
        isActive={walkieState.weTalking}
      >
        <MicrophoneIcon
          color={theme.colors.white}
          opacity={walkieState.weTalking ? 1 : 0.6}
          // To fix SSR warning
          muted={!walkieState.weTalking}
          width={20}
        />
      </PinControlButton>
    </Tooltip>
  )
}
