import React from 'react'
import { a, useTransition } from '@react-spring/native'
import { useTheme } from '@there/components/feed/ThemeContext'
import { Text } from 'react-native'

export const UnreadCount = (props: {
  show: boolean
  count: number
  active: boolean
}) => {
  let theme = useTheme()

  let renderTransition = useTransition(props.show, {
    enter: {
      scale: 1,
      opacity: 1,
      delay: 30,
    },
    from: {
      scale: 0.4,
      opacity: 0.1,
    },
    leave: {
      scale: 0.3,
      opacity: 0,
    },
    config: {
      duration: 120,
    },
  })

  return renderTransition(({ scale, opacity }, item) =>
    item ? (
      <a.View
        style={{
          opacity,
          transform: [{ scale }],
        }}
      >
        <Text
          style={{
            minWidth: 18,
            height: 18,
            paddingHorizontal: 3,
            backgroundColor: props.active
              ? 'rgba(255, 255, 255, 0.9)'
              : 'rgba(255, 255, 255, 0.22)',
            color: props.active
              ? `rgba(92, 122, 197, 1)`
              : theme.colors.tertiaryText,
            borderRadius: 18,

            fontSize: theme.fontSizes.small,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.count}
        </Text>
      </a.View>
    ) : null,
  )
}
