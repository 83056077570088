import { useCallback, useRef, useState } from 'react'
import { TENOR_KEY } from '../config'

type FetchType = 'search' | 'trending'

export type GifMedia = {
  id: string
  name: string
  preview: string | undefined
  url: string
  dims: [number, number]
  size: number
}

export const useTenor = () => {
  let [gifArray, setGifArray] = useState<any[][]>([[], []])
  let [isSearching, setSearching] = useState(false)
  let [nextPosition, setNextPosition] = useState<string | undefined>(undefined)
  let lastSearchQueryRef = useRef<undefined | string>(undefined)

  let fetchGIFs = useCallback(
    ({
      query,
      limit = 26,
      type,
      pos,
    }: {
      query?: string
      limit?: number
      type: FetchType
      pos?: string
    }) => {
      let queryString = query ? `&q=${query}` : ``
      let limitString = `&limit=${limit}`
      let posString = pos ? `&pos=${pos}` : ``
      let contentfilterString = `&contentfilter=high` // Not even partial nudity
      let url = `https://g.tenor.com/v1/${type}?key=${TENOR_KEY}${limitString}${queryString}${posString}${contentfilterString}`
      return fetch(url, {
        mode: 'cors',
      })
    },
    [],
  )

  let search = useCallback(
    (query: string) => {
      fetchGIFs({ query, type: 'search' })
        .then((res) => res.json())
        .then((res) => {
          setGifArray(parseGifInColumns(res.results))
          setSearching(true)
          lastSearchQueryRef.current = query
          if (res.next === '0') {
            setNextPosition(undefined)
          } else {
            setNextPosition(res.next)
          }
        })
        .catch(() => {})
    },
    [fetchGIFs],
  )

  let fetchTrending = useCallback(() => {
    fetchGIFs({ type: 'trending' })
      .then((res) => res.json())
      .then((res) => {
        setGifArray(parseGifInColumns(res.results))
        setSearching(false)
        lastSearchQueryRef.current = undefined
        if (res.next === '0') {
          setNextPosition(undefined)
        } else {
          setNextPosition(res.next)
        }
      })
      .catch(() => {})
  }, [fetchGIFs])

  let fetchMore = useCallback(() => {
    fetchGIFs({
      type: isSearching ? 'search' : 'trending',
      pos: nextPosition,
      query: isSearching ? lastSearchQueryRef.current : undefined,
    })
      .then((res) => res.json())
      .then((res) => {
        let [col1, col2] = parseGifInColumns(res.results)
        setGifArray((gifArray) => [
          [...gifArray[0], ...col1],
          [...gifArray[1], ...col2],
        ])
        if (res.next === '0') {
          setNextPosition(undefined)
        } else {
          setNextPosition(res.next)
        }
      })
      .catch(() => {})
  }, [fetchGIFs, isSearching, lastSearchQueryRef, nextPosition])

  return { gifArray, search, fetchMore, fetchTrending }
}

const parseGifInColumns = (gifArray: any[]) => {
  let column1 = []
  let column2 = []
  for (let [index, gif] of gifArray.entries()) {
    if (index % 2 === 1) {
      column1.push(gif)
    } else {
      column2.push(gif)
    }
  }
  return [column1, column2]
}
