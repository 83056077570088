import { memo, useRef } from 'react'
import { Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { useInViewportTarget } from '../hooks/useInViewport'
import { EmojiButton } from './EmojiButton'
import {
  AllEmojis,
  ConfigType,
  Emoji,
  EmojiCategory,
  usePicker,
} from './use-picker'

type Props = {
  index: number
  category: EmojiCategory
  allEmojis: AllEmojis
  shouldRender: boolean
  onEmojiSelect: (emoji: Emoji) => void
  config: ConfigType
}

export const Category = memo(
  ({
    category,
    allEmojis,
    index,
    onEmojiSelect,
    shouldRender,
    config,
  }: Props) => {
    let theme = useTheme()
    const emojisPerRow = config.emojiPerLine
    const height =
      Math.ceil(category.emojis.length / emojisPerRow) *
      (config.emojiSize + config.emojiMargin)

    let ref = useRef(null)
    useInViewportTarget(category, ref)
    return (
      <View
        ref={ref}
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Text
          style={{
            fontSize: theme.fontSizes.normal,
            color: theme.colors.tertiaryText,
            marginLeft: 8,
          }}
        >
          {category.name}
        </Text>

        <View
          style={{
            height,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {shouldRender &&
            category.emojis.map((name) => {
              const emoji = allEmojis[name]
              if (!emoji) return undefined

              const displayedEmoji = 'id' in emoji ? emoji : emoji[1]
              return (
                <EmojiButton
                  emoji={displayedEmoji}
                  onPress={onEmojiSelect}
                  key={displayedEmoji.id}
                  config={config}
                />
              )
            })}
        </View>
      </View>
    )
  },
)
