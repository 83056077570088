import React from 'react'
import { lighten } from 'polished'
import { electronApi } from '@there/desktop/utils/electron-api'
import { Pressable } from '@there/components/shared/Pressable'
import { WebkitAppRegion } from '@there/components/shared/WebStyled'

export const NativeWindowClose = (props: {
  onPress?: (close: () => void) => void
}) => {
  return (
    <WebkitAppRegion mode="no-drag">
      <Pressable
        onPress={() => {
          if (props.onPress) {
            props.onPress(() => {
              electronApi?.window.close()
            })
          } else {
            electronApi?.window.close()
          }
        }}
        style={({ pressed, hovered }) => ({
          backgroundColor: pressed
            ? lighterRed
            : hovered
            ? red
            : `rgba(255,255,255,0.15)`,
          borderRadius: 12,
          width: 12,
          height: 12,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        {({ hovered }) => (hovered ? <MacClose /> : null)}
      </Pressable>
    </WebkitAppRegion>
  )
}

const red = `#EC6A5E`
const lighterRed = lighten(0.04, red)

export const MacClose = () => {
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0.75L5.25 0L3 2.25L0.75 0L0 0.75L2.25 3L0 5.25L0.75 6L3 3.75L5.25 6L6 5.25L3.75 3L6 0.75Z"
        fill="#97040C"
      />
    </svg>
  )
}
