import { useNurMutation } from '@there/components/sun/use-mutation'
import type { RemoveDialogBackgroundMessage } from '@there/sun/ws/message-types'
import type { RemoveDialogBackgroundResult } from '@there/sun/modules/removeDialogBackground'

type Variables = RemoveDialogBackgroundMessage['payload']
export interface LocalEditDialogVariables extends Variables {
  __extra: {
    spaceId: string
  }
}

export const useRemoveDialogBackground = () => {
  return useNurMutation<RemoveDialogBackgroundResult, LocalEditDialogVariables>(
    {
      method: 'removeDialogBackground',
    },
  )
}
