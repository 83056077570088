import { minifyUserForProfilePhoto, ProfilePhoto } from '../feed/ProfilePhoto'
import { AvatarIndicator } from '../person/Indicators'
import { useSettingsWindowContext } from '../settings/use-settings-window'
import { Pressable } from '../shared/Pressable'
import { Tooltip } from '../shared/Tooltip'
import { useUsersContext } from '../shared/UsersContext'

export const AccountManagerButton = () => {
  const { settingsWindowDispatch } = useSettingsWindowContext()
  let { getUser, currentUser } = useUsersContext()
  let user = getUser(currentUser?.id || '')
  if (!user) return null
  let isOnline = user.online

  return (
    <Tooltip label={<>Edit profile</>} placement="bottomLeft">
      <Pressable
        style={{ marginRight: 2 }}
        onPress={() => {
          settingsWindowDispatch({
            type: 'open window',
            window: 'account',
          })
        }}
      >
        <ProfilePhoto user={minifyUserForProfilePhoto(user)} size={20} />
        <AvatarIndicator
          right={-1}
          bottom={-1}
          type={isOnline ? 'online' : 'offline'}
        />
      </Pressable>
    </Tooltip>
  )
}
