import { NewUpdateMessage } from '@there/sun/modules/newUpdate'
import { Draft } from 'immer'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useImmerReducer } from 'use-immer'
import { usePersistedReducerState } from '../hooks/usePersistedReducerState'
import { useNurSubscription } from '../sun/use-subscription'
import { useAppContext } from './AppContext'
import { useSpaceChatsContext } from './SpaceChatsContext'
import { useUniversalReducer } from './use-universal-reducer'
import { useSpacesInfo } from './useSpacesInfo'

type State = {
  spaceIdsUnreadStatusIds: Record<string, string[]>
}

type ContextType = {
  readStatus: ({}: { spaceId: string }) => void
  getSpaceUnreadNum: (
    type: 'all' | 'chats' | 'statuses',
    spaceId: string,
  ) => number
  spaceIdToUnread: Record<string, number>
}

export type Action =
  | { type: 'load state'; state: State }
  | { type: 'unread status added'; spaceId: string; statusId: string }
  | { type: 'unread status removed'; spaceId: string; statusId: string }
  | { type: 'read space statuses'; spaceId: string }

function reducer(draft: Draft<State>, action: Action): State | void {
  switch (action.type) {
    case 'load state':
      return action.state

    case 'unread status added': {
      let unreadStatuses = draft.spaceIdsUnreadStatusIds[action.spaceId] || []
      unreadStatuses.push(action.statusId)
      draft.spaceIdsUnreadStatusIds[action.spaceId] = Array.from(
        new Set(unreadStatuses),
      )
      return
    }
    case 'unread status removed': {
      let unreadStatuses = draft.spaceIdsUnreadStatusIds[action.spaceId] || []
      unreadStatuses = unreadStatuses.filter(
        (statusId) => statusId !== action.statusId,
      )
      draft.spaceIdsUnreadStatusIds[action.spaceId] = unreadStatuses
    }
    case 'read space statuses': {
      draft.spaceIdsUnreadStatusIds[action.spaceId] = []
      return
    }
    // if unknown action, don't clear the state
    default:
      break
  }
}

const initialState: State = {
  spaceIdsUnreadStatusIds: {},
}

const initialContext: ContextType = {
  readStatus: () => {},
  getSpaceUnreadNum: () => 0,
  spaceIdToUnread: {},
}

export const Context = createContext<ContextType>(initialContext)
export const useUnread = () => useContext<ContextType>(Context)
export const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = usePersistedReducerState<State, Action>(
    useUniversalReducer<State, Action>(
      useImmerReducer<State, Action>(reducer, initialState),
      { key: 'unread' },
    )[0],
    { key: 'unread' },
  )
  let { currentUserId } = useAppContext()
  let { spacesInfo, spacesChats } = useSpacesInfo()
  let { allUsersChats } = useSpaceChatsContext()

  // peerToUnreadUserChat: update user chats unread num
  let userChatPeerToUnread = useMemo(() => {
    let userChatPeerToUnread: Record<string, number> = {}
    if (allUsersChats && allUsersChats.length > 0) {
      for (let chat of allUsersChats) {
        if (!chat.peerUserId || chat.peerUserId === currentUserId) continue
        userChatPeerToUnread[chat.peerUserId] = chat.unreadCount
      }
    }
    return userChatPeerToUnread
  }, [allUsersChats, currentUserId])

  // peerToUnread: update topics unread num
  // spaceIdsToChatPeerIds
  let [topicChatPeerToUnread, spaceIdsToTopicPeerIds] = useMemo(() => {
    let topicChatPeerToUnread: Record<string, number> = {}
    let spaceIdsToTopicPeerIds: Record<string, string[]> = {}
    if (spacesChats) {
      for (let [spaceId, spaceChat] of Object.entries(spacesChats)) {
        let topicIds: string[] = []
        if (!spaceChat.chats) continue
        for (let chat of spaceChat.chats) {
          if (!chat.peerTopicId) continue
          topicChatPeerToUnread[chat.peerTopicId] = chat.unreadCount
          topicIds.push(chat.peerTopicId)
        }
        spaceIdsToTopicPeerIds[spaceId] = topicIds
      }
    }
    return [topicChatPeerToUnread, spaceIdsToTopicPeerIds]
  }, [spacesChats])

  //  map spaceIds to user chat peer
  let spaceIdsToUserPeerIds = useMemo(() => {
    let spaceIdsToUserPeerIds: Record<string, string[]> = {}
    for (let [, space] of Object.entries(spacesInfo)) {
      let userIds: string[] = []
      if (!space) continue
      for (let member of space.members) {
        if (member.user.id === currentUserId) continue
        userIds.push(member.user.id)
      }
      spaceIdsToUserPeerIds[space.id] = userIds
    }
    return spaceIdsToUserPeerIds
  }, [currentUserId, spacesInfo])

  const readStatus = useCallback(
    ({ spaceId }: { spaceId: string }) => {
      dispatch({ type: 'read space statuses', spaceId })
    },
    [dispatch],
  )

  const getUnreadChats = useCallback(
    (spaceId: string) => {
      let peerToUnread = { ...topicChatPeerToUnread, ...userChatPeerToUnread }
      let spacePeerIds = [
        ...(spaceIdsToTopicPeerIds[spaceId] || []),
        ...(spaceIdsToUserPeerIds[spaceId] || []),
      ]
      let unreadNum = 0

      for (let peerId of spacePeerIds) {
        if (typeof peerToUnread[peerId]) {
          unreadNum = peerToUnread[peerId] + unreadNum
        }
      }
      return unreadNum
    },
    [
      spaceIdsToTopicPeerIds,
      spaceIdsToUserPeerIds,
      topicChatPeerToUnread,
      userChatPeerToUnread,
    ],
  )

  const getUnreadStatuses = useCallback(
    (spaceId: string) => {
      let spaceUnreadStatuses = state.spaceIdsUnreadStatusIds[spaceId] || []
      return spaceUnreadStatuses.length
    },
    [state.spaceIdsUnreadStatusIds],
  )

  const getSpaceUnreadNum = useCallback(
    (type: 'all' | 'chats' | 'statuses', spaceId: string) => {
      switch (type) {
        case 'all':
          return getUnreadChats(spaceId) + getUnreadStatuses(spaceId)
        case 'chats':
          return getUnreadChats(spaceId)
        case 'statuses':
          return getUnreadStatuses(spaceId)
      }
    },
    [getUnreadChats, getUnreadStatuses],
  )

  let spaceIdToUnread = useMemo(() => {
    let spaces = Array.from(
      new Set([
        ...Object.keys(spaceIdsToTopicPeerIds),
        ...Object.keys(spaceIdsToUserPeerIds),
        ...Object.keys(state.spaceIdsUnreadStatusIds),
      ]),
    )
    let spaceToUnread: Record<string, number> = {}
    for (let spaceId of spaces) {
      let unreadChats = getUnreadChats(spaceId)
      let unreadStatuses = getUnreadStatuses(spaceId)
      spaceToUnread[spaceId] = unreadChats + unreadStatuses
    }
    return spaceToUnread
  }, [
    getUnreadChats,
    getUnreadStatuses,
    spaceIdsToTopicPeerIds,
    spaceIdsToUserPeerIds,
    state.spaceIdsUnreadStatusIds,
  ])

  // status subscription
  useNurSubscription<NewUpdateMessage>({
    method: 'newUpdate',
    onData: useCallback(
      ({ data: inputData }) => {
        let data = inputData as NewUpdateMessage['payload']
        for (let update of data.updates) {
          if (update.event === 'createStatus') {
            let spaceId = update.spaceId
            let status = update.status
            if (status.userId === currentUserId) return
            dispatch({
              type: 'unread status added',
              spaceId,
              statusId: status.id,
            })
          } else if (update.event === 'deleteStatus') {
            let spaceId = update.spaceId
            let statusId = update.statusId
            dispatch({ type: 'unread status removed', spaceId, statusId })
          }
        }
      },
      [currentUserId, dispatch],
    ),
  })

  let value: ContextType = useMemo(
    () => ({
      readStatus,
      getSpaceUnreadNum,
      spaceIdToUnread,
    }),
    [getSpaceUnreadNum, readStatus, spaceIdToUnread],
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}
