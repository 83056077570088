import { useMemo } from 'react'
import { useState } from 'react'

export function useWidth(
  defaultWidth?: number | null,
): [number | null, Record<string, undefined | ((event: any) => void)>] {
  let [width, setWidth] = useState(defaultWidth || null)

  let callbacks = useMemo(
    () => ({
      onLayout: ({ nativeEvent }: any) => {
        setWidth(nativeEvent.layout.width)
      },
    }),
    [],
  )

  return [width, callbacks]
}
