import gql from 'graphql-tag'
import { useMutation } from 'urql'
import {
  spaceInfoFragment,
  SpaceWithMembers,
} from '@there/components/urql/fragments/spaceInfo'
import { spaceMembersInfoFragment } from '@there/components/urql/fragments/spaceMembersInfo'

export const InviteMutation = gql`
  mutation invite(
    $emails: [String!]!
    $spaceId: ID!
    $spaceJoinSecretCode: String!
    $isBeta: Boolean
  ) {
    space: invite(
      emails: $emails
      spaceId: $spaceId
      spaceJoinSecretCode: $spaceJoinSecretCode
      isBeta: $isBeta
    ) {
      ...spaceInfo
      ...spaceMembersInfo
    }
  }
  ${spaceInfoFragment}
  ${spaceMembersInfoFragment}
`

export type Variables = {
  spaceId: string
  emails: string[]
  spaceJoinSecretCode: string
  isBeta?: boolean
}
export type Data = {
  space: SpaceWithMembers
}

export const useInviteMutation = () =>
  useMutation<Data, Variables>(InviteMutation)
