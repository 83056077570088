import React, { useRef, useEffect, useState, Fragment } from 'react'
import { createPortal } from 'react-dom'
import { useShortcutManager } from './use-shortcut-manager'

import { a } from '@react-spring/web'
import { useTransition } from '@react-spring/core'

/** Used for dropdowns, dialogs, tooltips, actions */
export function PopoversPortal({
  inside,
  isOpen,
  back = true,
  backMode = 'normal',
  keepMounted = 1,
  onClose,
  unMount = true,
}: {
  inside: React.ReactChild | null
  isOpen: boolean
  back?: boolean
  keepMounted?: number
  backMode?: 'transparent' | 'normal'
  onClose: () => void
  unMount?: boolean
}) {
  const ref = useRef<HTMLElement>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    // @ts-ignore
    ref.current = document.querySelector('#actionMenuPortal')
    setMounted(true)
  }, [])

  let { subscribeToEscape, unsubscribeFromEscape } = useShortcutManager()
  useEffect(() => {
    if (!isOpen) return
    subscribeToEscape(onClose)

    return () => {
      unsubscribeFromEscape(onClose)
    }
  }, [isOpen, onClose, subscribeToEscape, unsubscribeFromEscape])

  const transition = useTransition(isOpen, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
      config: {
        duration: keepMounted ? keepMounted : 150,
      },
    },
  })

  return mounted && ref.current
    ? createPortal(
        <>
          {transition(({ opacity }, item) =>
            item ? (
              <Fragment key="1">
                {back && (
                  <a.div
                    onClick={(event) => {
                      // So it doesn't toggle anything else
                      event.stopPropagation()
                      onClose()
                    }}
                    style={{
                      opacity: backMode === 'transparent' ? 0 : opacity,
                      width: '100vw',
                      height: '100vh',
                      position: 'fixed',
                      backgroundColor: `rgba(0,0,0,0.6)`,
                      top: 0,
                      left: 0,
                      // pointerEvents: isOpen && back ? 'all' : undefined,
                      pointerEvents: isOpen && back ? 'all' : 'none',
                      zIndex: unMount ? 7 : 8,
                      overflow: 'hidden',
                    }}
                  />
                )}
                {unMount && inside}
              </Fragment>
            ) : null,
          )}
          {!unMount && inside}
        </>,
        ref.current,
      )
    : null
}
