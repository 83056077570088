import { View } from 'react-native'
import {
  avatarsMargin,
  CameraView,
  getCameraViewWidth,
  getCameraViewWidthWeb,
  webAvatarSize,
} from '../rtc/CameraView'
import { useRtcContext } from '../shared/use-rtc'
import { useWindowContext } from '../shared/WindowContext'
import { useDrag } from '@use-gesture/react'
import { a, useSpring } from '@react-spring/web'
import { useState } from 'react'

export const WebCamera = () => {
  let { isElectron } = useWindowContext()
  let { walkieState, participants, ourObservers } = useRtcContext()
  let isAnyoneSharingCamera = Boolean(
    participants.some(
      (p) =>
        walkieState.participants[p.userId]?.isSharingCamera &&
        // for now
        !walkieState.participants[p.userId]?.isSharingScreen,
    ),
  )
  let weSharingCamera = walkieState.weSharingCamera
  let isHost = walkieState.weSharingScreen && ourObservers.length > 0
  let isObserving = walkieState.isObserving && walkieState.hostUserId

  let shouldShowCameraView = weSharingCamera || isAnyoneSharingCamera //&& !isHost && !isObserving

  const [{ x, y, zIndex }, api] = useSpring(() => ({ x: 0, y: 0, zIndex: 1 }))
  const bind = useDrag(({ down, offset: [ox, oy], dragging }) => {
    if (!down || !dragging) return
    api.start({
      x: ox,
      y: oy,
      immediate: down,
      zIndex: down ? 999 : 1,
    })
    return { down }
  })
  let windowWidth =
    typeof window !== 'undefined'
      ? getCameraViewWidthWeb({
          avatarsCount: participants.length,
          windowHeight: window.innerHeight,
        })
      : 0

  const [selfViewHidden, setHideSelfView] = useState(false)

  if (isElectron) return null
  if (!shouldShowCameraView) return null
  return (
    <a.div
      style={{
        //@ts-ignore
        WebkitAppRegion: 'no-drag',
        x,
        y,
        zIndex,
        touchAction: 'none',
        overflow: 'hidden',
        pointerEvents: 'auto',
      }}
      {...bind()}
    >
      <View
        style={{
          width: windowWidth || 0,
          height: webAvatarSize + avatarsMargin * 2,
        }}
      >
        <CameraView
          avatars={participants}
          setHideSelfView={setHideSelfView}
          hideSelfView={selfViewHidden}
        />
      </View>
    </a.div>
  )
}
