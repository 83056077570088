import gql from 'graphql-tag'
import { NexusGenFieldTypes } from '@there/tower/generated/graphql'
import { UserInfo } from '@there/components/urql/fragments/userInfo'

export const avatarInfoFragment = gql`
  fragment avatarInfo on Avatar {
    id
    dialogId
    joinedAt
    userId
  }
`
type Source = NexusGenFieldTypes['Avatar']

export interface AvatarInfo {
  __typename?: 'Avatar'
  id: Source['id']
  dialogId: Source['dialogId']
  joinedAt: Source['joinedAt']
  userId: Source['userId']
}

export interface UserWithAvatarInfo extends UserInfo {
  avatar: AvatarInfo | null
}

export interface AvatarWithUserInfo extends AvatarInfo {
  user: UserInfo
}
