import React, { useEffect } from 'react'
import { useSettingsWindowContext } from './use-settings-window'
import { SettingsWindows } from './SettingsWindows'
import { useMainWindowContext } from '../shared/use-main-window'
import { View } from 'react-native'
import { isElectron } from '@there/desktop/utils/electron-api'
import { feedSize } from '@there/shared/desktop/windows-config'
import { useShortcutManager } from '@there/components/shared/use-shortcut-manager'

export const Settings = () => {
  const {
    settingsWindowState: state,
    settingsWindowDispatch: dispatch,
  } = useSettingsWindowContext()
  const { isSettingsOpen, dispatch: windowDispatch } = useMainWindowContext()

  useEffect(() => {
    isSettingsOpen && dispatch({ type: 'open window', window: 'preferences' })
  }, [dispatch, isSettingsOpen])

  let { subscribeToEscape, unsubscribeFromEscape } = useShortcutManager()
  useEffect(() => {
    if (!state.isWindowVisible) return
    function onClose() {
      dispatch({ type: 'close window' })
      windowDispatch({ type: 'settings state changed', state: false })
    }
    subscribeToEscape(onClose)

    return () => {
      unsubscribeFromEscape(onClose)
    }
  }, [
    dispatch,
    state.isWindowVisible,
    subscribeToEscape,
    unsubscribeFromEscape,
    windowDispatch,
  ])

  return (
    <React.Fragment>
      {state.isWindowVisible && (
        <View
          style={[
            {
              zIndex: 6,
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              top: 0,
              left: 0,
            },
          ]}
        >
          <SettingsWindows state={state} dispatch={dispatch} />
        </View>
      )}
    </React.Fragment>
  )
}
