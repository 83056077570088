import { useTheme } from '@there/components/feed/ThemeContext'
import { GoodButton } from '@there/components/shared/GoodButton'
import { MicrophoneIcon } from '@there/components/shared/MicrophoneIcon'
import { Tooltip } from '@there/components/shared/Tooltip'
import { rtcCoreAtom } from '@there/components/shared/use-rtc'
import { useShortcutManager } from '@there/components/shared/use-shortcut-manager'
import { useAtom } from 'jotai'
import { memo } from 'react'
import { View } from 'react-native'
import { logEvent } from '../shared/use-analytics'

export const MicrophoneButton = memo(() => {
  let [{ isTalking, toggleTalk, isInitiallyConnecting }] = useAtom(rtcCoreAtom)

  let theme = useTheme()
  let { getShortcutForAction } = useShortcutManager()

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',

        position: 'relative',
        zIndex: 1,
      }}
    >
      <Tooltip
        alwaysOn={isInitiallyConnecting}
        label={
          isInitiallyConnecting
            ? 'Connecting...'
            : `${isTalking ? 'Mute' : 'Enable mic'} • ${getShortcutForAction(
                'toggleMicrophone',
              )}`
        }
        placement="bottomLeft"
      >
        <GoodButton
          active={isTalking}
          activeColor="green"
          onPress={() => {
            toggleTalk()
            logEvent('User Toggeled Mic - Mic click', {
              from: 'current room',
            })
          }}
          icon={
            <>
              <MicrophoneIcon
                width={19}
                height={19}
                color={theme.colors.white}
                opacity={isTalking ? 1 : 0.6}
                // To fix SSR warning
                muted={!isTalking}
              />
            </>
          }
        />
      </Tooltip>
    </View>
  )
})

MicrophoneButton.displayName = 'MicrophoneButton'
