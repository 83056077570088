import { ipc } from '@there/desktop/utils/electron-api'
import { BucketEndpoint } from '@there/shared/api/config'
import { NewMessageInfo } from '@there/sun/utils/node-types'
import { useEffect, useRef, useState } from 'react'
import { Pressable, ActivityIndicator, View, Text } from 'react-native'
import useCanvasBlur from '../lib/useCanvasBlur'
import { AttachFileMessage, DownloadIcon } from '../main/mainIcons'
import { calculateImageDimensions } from '../v2/ChatUploadButton'
import { getMessageMediaFullPath, getMessageMediaThumbDataUri } from './helpers'
import { useSpring, useTransition, a } from '@react-spring/native'
import useIsMounted from '@there/components/hooks/useIsMounted'
import { useTheme } from '../feed/ThemeContext'
import { humanFileSize } from '@there/shared/utilities/file-size-formatter'
import { DownloadData } from '../v2/useDownloadManager'

type props = {
  message: NewMessageInfo
  onPress: () => void
  downloadStatusData?: DownloadData
}

export const FileMessage = ({
  message,
  onPress,
  downloadStatusData,
}: props) => {
  let isMounted = useIsMounted()
  let theme = useTheme()
  return (
    <Pressable
      style={[
        {
          // We limit it by the parent bubble
          // maxWidth: '100%',
          minHeight: 20,
          flex: 1,
          overflow: 'hidden',
          marginBottom: 0,
          borderColor: 'white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          padding: 5,
        },
      ]}
      onPress={onPress}
    >
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 0,
          borderRadius: 12,
          width: 34,
          height: 34,
          backgroundColor: theme.colors.quaternaryLight,
        }}
      >
        {downloadStatusData?.type === 'percent' ? (
          <ActivityIndicator size={12} color={theme.colors.quaternaryText} />
        ) : (
          <AttachFileMessage />
        )}
      </View>

      <View
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          paddingHorizontal: 5,
          minWidth: 120,
        }}
      >
        <Text
          style={{
            color: theme.colors.secondaryText,
            fontSize: theme.fontSizes.normal,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {message.document?.fileName}
        </Text>
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {message.document?.size ? (
            <Text
              style={{
                color: theme.colors.tertiaryText,
                fontSize: theme.fontSizes.small,
              }}
            >
              {humanFileSize(message.document?.size)}
              {downloadStatusData?.type === 'percent' &&
                ` / ${humanFileSize(downloadStatusData?.downloadedBytes)}`}
            </Text>
          ) : null}
        </View>
      </View>
    </Pressable>
  )
}
