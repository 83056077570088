import { useNurMutation } from '@there/components/sun/use-mutation'
import { DismissNotificationMessage } from '@there/sun/modules/dismissNotification'
interface Data {
  done: boolean
}

export const useDismissNotification = () => {
  return useNurMutation<Data, DismissNotificationMessage['payload']>({
    method: 'dismissNotification',
  })
}
