import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export function UnlockIcon(props: SvgProps) {
  return (
    <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        d="M19 12a2 2 0 00-2-2H9V9a3 3 0 015.37-1.84l1.13-1.73A4.98 4.98 0 007 9v1a2 2 0 00-2 2v6c0 1.1.9 2 2 2h10a2 2 0 002-2v-6z"
        fill={props.color ? props.color : '#000'}
      />
    </Svg>
  )
}
