import { currentDialogIdAtom } from '@there/components/feed/useLobbyMan'
import { EmojiView } from '@there/components/main/EmojiView'
import { AvatarWithUserInfo } from '@there/sun/utils/node-types'
import { useAtom } from 'jotai'
import { Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { useFocusState } from '../feed/useFocusState'
import { useSystemAudio } from '../feed/useSystemAudio'
import { useCurrentMediaDevicesContext } from '../shared/CurrentMediaDevicesContext'
import { GoodButton } from '../shared/GoodButton'
import { Space } from '../shared/Space'
import { Tooltip } from '../shared/Tooltip'
import { logEvent } from '../shared/use-analytics'
import { useRtcContext } from '../shared/use-rtc'
import { useWindowContext } from '../shared/WindowContext'
import { FocusIcon } from './FocusIcon'
import { SystemAudioIcon } from './SystemAudioIcon'

export const RoomsHeader = ({
  avatarsInDialogs,
}: {
  avatarsInDialogs: AvatarWithUserInfo[]
}) => {
  let theme = useTheme()
  let { walkieState, isInCall } = useRtcContext()
  let [currentDialogId] = useAtom(currentDialogIdAtom)
  let isInRoom = !!currentDialogId
  let weSharingSystemAudio = walkieState.weSharingSystemAudio
  let {
    toggleSystemAudio,
    isMultiOutputSpeakerSelected,
    isSharingSpeakerSelected,
    isNoorSpeakerSetToSharingSpeaker,
  } = useSystemAudio()
  let { toggleFocus, weFocused } = useFocusState()
  let { isElectron, isMacOs, isWindows } = useWindowContext()
  const { currentSpeaker } = useCurrentMediaDevicesContext()

  return (
    <View
      style={{
        width: '100%',
        height: 24,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: theme.spaces.sidePaddingNarrow,
        paddingBottom: theme.spaces.bottomPaddingNarrow,

        zIndex: 3,

        //@ts-ignore
        pointerEvents: 'auto',
      }}
    >
      <Text
        style={{
          fontSize: theme.fontSizes.normal,
          color: theme.colors.tertiaryText,
        }}
      >
        Rooms
      </Text>
      {isInRoom && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {isElectron &&
            isMacOs &&
            isSharingSpeakerSelected &&
            (currentSpeaker?.id === 'default' ||
              isNoorSpeakerSetToSharingSpeaker) &&
            weSharingSystemAudio && (
              <View style={{ marginRight: 2 }}>
                <Tooltip
                  placement="bottomLeft"
                  label={
                    <>
                      <strong>Change your speaker in Noor !</strong>
                      <br />
                      <span className="light">
                        When sharing system audio is activated, you
                        <br />
                        should change Noor speaker to a real device.
                      </span>
                    </>
                  }
                >
                  <EmojiView size={14}>⚠️</EmojiView>
                </Tooltip>
              </View>
            )}
          {isElectron &&
            isMacOs &&
            !isMultiOutputSpeakerSelected &&
            !isSharingSpeakerSelected &&
            weSharingSystemAudio && (
              <View style={{ marginRight: 2 }}>
                <Tooltip
                  placement="bottomLeft"
                  label={
                    <>
                      Did you forget to change your system speaker
                      <br />
                      to the &quot;Multi-Output&quot; or &quot;BlackHole&quot;
                      output?
                    </>
                  }
                >
                  <EmojiView size={14}>⚠️</EmojiView>
                </Tooltip>
              </View>
            )}
          {isElectron &&
            isWindows &&
            !isSharingSpeakerSelected &&
            weSharingSystemAudio && (
              <View style={{ marginRight: 2 }}>
                <Tooltip
                  placement="bottomLeft"
                  label={
                    <>
                      Did you forget to change your system speaker
                      <br />
                      to the &quot;LoopBack&quot; output?
                    </>
                  }
                >
                  <EmojiView size={14}>⚠️</EmojiView>
                </Tooltip>
              </View>
            )}

          {isElectron && (
            <Tooltip placement="bottomRight" label="Share computer audio">
              <GoodButton
                activeColor="systemAudioButtonIcon"
                active={weSharingSystemAudio}
                onPress={() => {
                  toggleSystemAudio()
                  logEvent('User Toggled Share System Audio')
                }}
                size="small"
                icon={
                  <SystemAudioIcon
                    width={20}
                    color={theme.colors.white}
                    opacity={weSharingSystemAudio ? 1 : 0.6}
                  />
                }
              />
            </Tooltip>
          )}
          <Space horizontal={5} />
          <Tooltip
            placement="bottomRight"
            label={
              <>
                Focus
                <br />
                <span className="light">
                  Volume down others, mute nudges, and notifications.
                </span>
                <br />
                <span className="light">
                  Note: Turn off your mic to hear others at 10% volume.
                </span>
              </>
            }
          >
            <GoodButton
              activeColor="focusModeButtonBackground"
              active={!!weFocused}
              onPress={() => {
                toggleFocus(!weFocused)
                logEvent('User Toggled Focus')
              }}
              size="small"
              icon={
                <FocusIcon
                  width={20}
                  height={20}
                  color={theme.colors.white}
                  opacity={weFocused ? 1 : 0.6}
                />
              }
            />
          </Tooltip>
        </View>
      )}
    </View>
  )
}
