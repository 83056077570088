import { useTheme } from '@there/components/feed/ThemeContext'
import { EmojiView } from '@there/components/main/EmojiView'
import { SmileEmojiIcon } from '@there/components/main/mainIcons'
import { RoomLobbyIcon } from '@there/components/room/RoomLobbyIcon'
import { useEditDialog } from '@there/components/main/useEditDialog'
import { usePickerContext } from '@there/components/shared/PickerContext'
import { Pressable } from '@there/components/shared/Pressable'
import { DialogInfo } from '@there/sun/utils/node-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { useAppContext } from '@there/components/shared/AppContext'

/**@deprecated */
export const roomEmojiSize = 22

export const RoomEditableEmoji = ({
  dialog,
  editable,
  size = 18,
}: {
  dialog: DialogInfo
  editable: boolean
  size: number
}) => {
  let [iconEmoji, setIconEmoji] = useState<string | null>(null)
  let theme = useTheme()
  let [, editDialog] = useEditDialog()
  let { activeSpaceId } = useAppContext()

  const submit = useCallback(() => {
    console.info('submitting room edit')
    // Send to API
    editDialog({
      id: dialog.id,
      iconEmoji,
      __extra: { spaceId: activeSpaceId || '' },
    })
  }, [activeSpaceId, dialog.id, editDialog, iconEmoji])

  let {
    selectedEmoji,
    open: openEmojiPicker,
    close: closeEmojiPicker,
    clearEmoji,
  } = usePickerContext()

  // to mount emoji picker after animation ended
  // useEffect(() => {
  //   if (editable && !isEmojiPickerMount) {
  //     mountEmojiPicker()
  //   }
  //   if (!editable) {
  //     unMountEmojiPicker()
  //   }
  // }, [editable, isEmojiPickerMount, mountEmojiPicker, unMountEmojiPicker])

  useEffect(() => {
    if (!editable) return
    if (!selectedEmoji?.['editRoom']) return
    setIconEmoji(
      selectedEmoji['editRoom'].image
        .split('-')
        .map((u) => String.fromCodePoint(parseInt(u, 16)))
        .join(''),
    )
    clearEmoji('newGroup')
    closeEmojiPicker()
  }, [clearEmoji, closeEmojiPicker, editable, selectedEmoji])

  useEffect(() => {
    if (!iconEmoji) return
    if (!editable) return
    // if user hasn't picked yet
    if (iconEmoji === dialog.iconEmoji) return

    submit()
    clearEmoji('editRoom')
  }, [iconEmoji, editable, submit, dialog.iconEmoji, clearEmoji])

  let wrapperRef = useRef<View | null>(null)

  return (
    <View ref={wrapperRef}>
      <Pressable
        disabled={!editable}
        onPress={() => {
          if (!editable) return
          // openEmojiPicker('editRoom', wrapperRef, false)
          openEmojiPicker({
            key: 'editRoom',
            ref: wrapperRef,
            isFullScreen: false,
            activeModes: ['emoji'],
          })
        }}
      >
        {({ hovered }) => (
          <View
            style={[
              {
                width: size + 4,
                height: size + 4,
                borderRadius: 8,
                alignItems: 'center',
                justifyContent: 'center',
              },
              hovered && {
                backgroundColor:
                  theme.colors.selectNewEmojiButtonBackgroundHover,
              },
            ]}
          >
            {iconEmoji || dialog.iconEmoji ? (
              <EmojiView size={size}>
                {iconEmoji || dialog.iconEmoji || ''}
              </EmojiView>
            ) : dialog.specialType === 'lobby' ? (
              <RoomLobbyIcon
                color={theme.colors.tertiaryLight}
                style={{ marginTop: -3 }}
              />
            ) : (
              <SmileEmojiIcon
                color={theme.colors.quaternaryText}
                width={size}
                height={size}
              />
            )}
          </View>
        )}
      </Pressable>
    </View>
  )
}
