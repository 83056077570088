import { feedOpenAtom } from '@there/components/atoms/feedAtoms'
import useInterval from '@use-it/interval'
import { useAtom } from 'jotai'
import { useState } from 'react'

export const TimezoneTime = ({ timeZone }: { timeZone: string }) => {
  let [isFeedOpen] = useAtom(feedOpenAtom)
  let timeString = new Intl.DateTimeFormat('en-US', {
    timeZone,
    hour: 'numeric',
    minute: '2-digit',
  }).format()

  let [, setState] = useState(false)

  useInterval(
    () => {
      // Force update
      setState((t) => !t)
    },
    isFeedOpen ? 60_000 : null,
  )

  return <>{timeString}</>
}
