import { useSendNudge } from '@there/components/main/useSendNudge'
import { getShortName } from '@there/components/utils/get-short-name'
import { useAtom } from 'jotai'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useUser } from '../atoms/userAtom'
import { EmojiView } from '../main/EmojiView'
import { useAlert } from '../modal/use-alert'
import { logEvent } from '../shared/use-analytics'
import { useUsersContext } from '../shared/UsersContext'
import { currentDialogIdAtom } from './useLobbyMan'

export type EmojiNames = 'wave' | 'eyes' | '+1'
export function useNudge({
  recipientId,
}: {
  recipientId: string
}): [ReactNode, (emoji: string) => void] {
  let { currentUser } = useUsersContext()
  let [, doSendNudge] = useSendNudge()
  let [currentDialogId] = useAtom(currentDialogIdAtom)
  let [sentNudge, setSentNudge] = useState(0)

  let senderName = currentUser?.nickname || currentUser?.name || undefined
  let senderProfilePhoto = currentUser?.profilePhoto || undefined

  let user = useUser(recipientId)
  let shortName = user ? getShortName(user) : ''

  // nudge on focus alert
  let { openModal: openFocusedNudgeModal } = useAlert({
    alertText: `${shortName} is on Focus mode`,
    alertDescription: `Nudge alert is disabled in focus mode, but still we show it in notifications`,
    alertIcon: <EmojiView size={34} children="🤐" />,
    submitLabel: 'Fine',
  })

  // to limit send max 3 nudge for each person in every 8s
  let limitTimeMs = 8000
  useEffect(() => {
    if (!sentNudge) return
    let timeout = setTimeout(() => {
      setSentNudge(0)
    }, limitTimeMs)

    return () => {
      clearTimeout(timeout)
    }
  }, [limitTimeMs, sentNudge])

  let sendNudge = useCallback(
    async (emoji: string) => {
      if (sentNudge >= 3) {
        console.warn('You reached maximum nudge for one person in 8s')
        return
      }
      if (user?.focused) {
        openFocusedNudgeModal()
      }
      setSentNudge((num) => num + 1)
      doSendNudge({
        userId: recipientId,
        dialogId: currentDialogId || undefined,
        senderName,
        senderProfilePhoto,
      })
      logEvent('User Nudged', { emoji: emoji })
    },
    [
      currentDialogId,
      doSendNudge,
      openFocusedNudgeModal,
      recipientId,
      senderName,
      senderProfilePhoto,
      sentNudge,
      user?.focused,
    ],
  )

  let overlay = <></>

  return [overlay, sendNudge]
}
