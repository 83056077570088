import { TextInput, View, Text, Switch, ScrollView } from 'react-native'
import { useModals } from '../feed/ModalsContext'
import { Pressable } from '../shared/Pressable'
import { Space } from '../shared/Space'
import { Header } from './Header'
import { ModalWrapper } from './ModalWrapper'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
import { useTheme } from '../feed/ThemeContext'
import { StandardButton } from '../main/StandardButton'
import { useCreateChatTopic } from '../main/useCreateChatTopic'
import cuid from 'cuid'
import { useAtom } from 'jotai'
import { contentViewAtom } from '../atoms/contentViewAtom'
import { activeNewChatPeerAtom } from '../atoms/chatAtom'
import { toGlobalId } from '@there/tower/utils/global-id'
import { useSpaceContext } from '../shared/spaceContext'
import { MemberWithUserInfo } from '@there/sun/utils/node-types'
import { ProfilePhoto } from '../feed/ProfilePhoto'
import { getShortName } from '@there/shared/utilities/get-short-name'
import { CheckIcon } from '../chat/CheckIcon'
import { a, useTransition } from '@react-spring/native'
import { borderRadius } from 'polished'
import { useAppContext } from '../shared/AppContext'
import { usePickerContext } from '../shared/PickerContext'
import { EmojiView } from '../main/EmojiView'
import { useMainWindowContext } from '../shared/use-main-window'

export const NewGroupModal = () => {
  let [{ modals }, modalsDispatch] = useModals()
  let theme = useTheme()
  let [title, setTitle] = useState('')
  let [isPrivate, setPrivate] = useState(false)
  let [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([])
  let [iconEmoji, setIconEmoji] = useState<string | null>(null)

  let [, doCreateChatTopic] = useCreateChatTopic()
  let { dispatch } = useMainWindowContext()
  let [, setActiveChatPeer] = useAtom(activeNewChatPeerAtom)
  let { currentUserId } = useAppContext()

  let {
    selectedEmoji,
    open: openPicker,
    close: closePicker,
    clearEmoji,
  } = usePickerContext()

  let isOpen = modals.includes('newGroup')

  // to mount emoji picker after animation ended

  // useEffect(() => {
  //   if (isOpen) {
  //     mountEmojiPicker()
  //   }
  //   return () => {
  //     unMountEmojiPicker()
  //   }
  // }, [mountEmojiPicker, unMountEmojiPicker, isOpen])

  useEffect(() => {
    if (!selectedEmoji?.['newGroup']) return
    setIconEmoji(
      selectedEmoji['newGroup'].image
        .split('-')
        .map((u) => String.fromCodePoint(parseInt(u, 16)))
        .join(''),
    )
    clearEmoji('newGroup')
    closePicker()
  }, [clearEmoji, closePicker, selectedEmoji])

  const onClose = useCallback(() => {
    modalsDispatch({ type: 'modal closed', modalName: 'newGroup' })
    setTitle('')
    setPrivate(false)
    setSelectedMemberIds([])
    clearEmoji('newGroup')
    setIconEmoji(null)
  }, [clearEmoji, modalsDispatch])

  const createGroup = useCallback(
    ({
      title,
      isPrivate,
      memberIds,
      iconEmoji,
    }: {
      title: string | undefined
      isPrivate: boolean
      memberIds: string[]
      iconEmoji?: string
    }) => {
      let chatId = cuid()
      let topicId = cuid()

      doCreateChatTopic({
        chatId,
        topicId,
        title,
        type: 'Group',
        groupType: isPrivate ? 'Private' : 'Public',
        groupParticipants: memberIds,
        iconEmoji,
      }).then(() => {
        setActiveChatPeer({
          peerTopicId: toGlobalId('Topic', topicId),
          peerUserId: null,
        })
        dispatch({ type: 'change mode', mode: 'chat' })
        onClose()
      })
    },
    [dispatch, doCreateChatTopic, onClose, setActiveChatPeer],
  )

  let [{ space, getAvatarByUserId }] = useSpaceContext()
  const members = useMemo(() => {
    /** Add avatar to member user */
    function mapMember(member: MemberWithUserInfo): MemberWithUserInfo {
      let avatar = member.user ? getAvatarByUserId(member.user.id) : undefined
      return {
        ...member,
        user: { ...member.user, avatar },
      }
    }

    return (space?.members || []).map((member) => mapMember(member))
    // .filter(member => filter(member))
  }, [getAvatarByUserId, space])

  let toggleMember = useCallback(
    (id: string) => {
      let isSelected = selectedMemberIds.includes(id)
      if (isSelected) {
        setSelectedMemberIds(
          selectedMemberIds.filter((userId) => userId !== id),
        )
      } else {
        setSelectedMemberIds([...selectedMemberIds, id])
      }
    },
    [selectedMemberIds],
  )

  let transitions = useTransition(isPrivate, {
    from: {
      height: 0,
    },
    enter: {
      height: Math.min(3 * 36, (members.length - 1) * 36) + 20,
    },
    leave: {
      height: 0,
    },
    config: {
      mass: 0.4,
      tension: 400,
      friction: 22,
    },
  })

  let wrapperRef = useRef<View | null>(null)

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <View style={{ width: 290 }}>
        <Header label="New Group" onClose={onClose} />
        <Space vertical={15} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View ref={wrapperRef}>
            <Pressable
              style={({ hovered, pressed }) => [
                {
                  width: 32,
                  height: 32,
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: 42,

                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                hovered && {
                  backgroundColor: 'rgba(255, 255, 255, 0.15)',
                },
                pressed && { opacity: 0.9 },
              ]}
              onPress={() => {
                openPicker({
                  key: 'newGroup',
                  ref: wrapperRef,
                  isFullScreen: true,
                  activeModes: ['emoji'],
                })
              }}
            >
              {iconEmoji ? (
                <EmojiView size={24}>{iconEmoji}</EmojiView>
              ) : (
                <EmojiPlaceholder
                  width={24}
                  height={24}
                  color={theme.colors.tertiaryText}
                />
              )}
            </Pressable>
          </View>
          <TextInput
            value={title}
            onChangeText={setTitle}
            placeholder="Group Name"
            style={{
              marginLeft: 10,
              fontSize: theme.fontSizes.normal,
              color: theme.colors.secondaryText,
              flex: 1,
            }}
          />
        </View>
        <Space vertical={10} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              flex: 1,
            }}
          >
            <Text
              style={{
                fontSize: theme.fontSizes.normal,
                color: theme.colors.secondaryText,
              }}
            >
              Make group private
            </Text>
            <Text
              style={{
                fontSize: theme.fontSizes.small,
                color: theme.colors.tertiaryText,
              }}
            >
              Group is public by default and all members are joined.
            </Text>
          </View>
          <Switch value={isPrivate} onValueChange={setPrivate} />
        </View>
        <Space vertical={10} />
        {transitions(({ height }, item) => {
          return item ? (
            <a.View
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                overflow: 'hidden',
                height,
                marginHorizontal: -8,
              }}
            >
              <Text
                style={{
                  fontSize: theme.fontSizes.normal,
                  color: theme.colors.secondaryText,
                  marginLeft: 8,
                }}
              >
                Select Members
              </Text>
              <ScrollView
                style={{
                  maxHeight: 120,
                  width: '100%',
                  marginTop: 2,
                }}
              >
                {members.map((member) => {
                  let isSelected = selectedMemberIds.includes(member.user.id)
                  if (member.user.id === currentUserId) return
                  return (
                    <Pressable
                      onPress={() => toggleMember(member.user.id)}
                      key={member.id}
                      style={({ hovered, pressed }) => [
                        {
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',

                          marginTop: 2,
                          flex: 1,
                          backgroundColor: hovered
                            ? 'rgba(255, 255, 255, 0.1)'
                            : undefined,
                          paddingVertical: 4,
                          paddingHorizontal: 8,
                          borderRadius: 6,
                        },
                        pressed && {
                          opacity: 0.9,
                        },
                      ]}
                    >
                      <ProfilePhoto user={member.user} size={28} />
                      <Text
                        style={{
                          fontSize: theme.fontSizes.normal,
                          color: theme.colors.secondaryText,
                          marginLeft: 5,
                          flex: 1,
                        }}
                      >
                        {getShortName(member.user)}
                      </Text>
                      <View
                        style={{
                          width: 16,
                          height: 16,
                          borderColor: 'rgba(255, 255, 255, 0.1)',
                          borderWidth: 1,
                          borderRadius: 20,

                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {isSelected && (
                          <CheckIcon
                            color={theme.colors.primary}
                            width={12}
                            animate={true}
                            style={{ marginLeft: 2 }}
                          />
                        )}
                      </View>
                    </Pressable>
                  )
                })}
              </ScrollView>
            </a.View>
          ) : null
        })}

        <Space vertical={10} />
        <StandardButton
          text="Create"
          backgroundColor="primaryButtonBackground"
          textColor="secondaryText"
          onPress={() => {
            createGroup({
              title,
              isPrivate,
              memberIds: selectedMemberIds,
              iconEmoji: iconEmoji || undefined,
            })
          }}
          style={{
            alignSelf: 'center',
          }}
          textStyle={{
            width: '100%',
            textAlign: 'center',
          }}
        />
      </View>
    </ModalWrapper>
  )
}

function EmojiPlaceholder(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zM6.168 13.555C7.678 15.819 9.646 17 12 17c2.354 0 4.322-1.18 5.832-3.445a1 1 0 00-1.664-1.11C15.01 14.181 13.646 15 12 15c-1.646 0-3.01-.82-4.168-2.555a1 1 0 00-1.664 1.11z"
        fill={props.color ? props.color : '#000'}
      />
    </Svg>
  )
}
