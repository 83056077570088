import { ChatMessageInfo } from '@there/components/types/chat'
import { Rectangle } from '@there/shared/desktop/types'
import { DialogInfo, StatusInfo, TopicInfo } from '@there/sun/utils/node-types'
import React, { createContext, useContext, useReducer } from 'react'
import { CallDetails } from './useLobbyMan'

type ModalName =
  | 'changeSpace'
  | 'invite'
  | 'createRoom'
  | 'setFromTwitter'
  | 'editRoom'
  | 'recommendedMic'
  | 'pickSource'
  | 'nudgeLearning'
  | 'setBackground'
  | 'leaveSpace'
  | 'previewPhoto'
  | 'newGroup'
  | 'editTopic'
  | 'confirm'
  | 'alert'
  | 'viewPhoto'
  | 'userRate'
  | 'toast'

export type ChangeSpaceModalData = {
  onSubmit: () => void
  newSpace: string
}

export type LeaveSpaceModalData = {
  onSubmit: () => void
  spaceName?: string
}

export type SetFromTwitterModalData = {
  setTwitter: React.Dispatch<React.SetStateAction<string>>
}

export type EditDialogModalData = {
  dialogId: string
  dialog: DialogInfo
}

export type RecommendedMicModalData = {
  onSubmit: () => void
  onCancel: () => void
}

export type SetBackgroundModalData = {
  dialogId: string
  dialogBgObject: string | null | undefined
}

export type PreviewPhotoModalData = {
  dimensions: any
  photoUrl: string | null | undefined
  doUpload: () => void
  textMessage?: string
}
export type ToastModalData = {
  icon?: React.ReactNode
  title?: string | undefined
  description?: string | undefined

  submitLabel?: string | undefined
  onSubmit: () => void

  dismissLabel?: string | undefined
  onDismiss?: () => void
}
export type ViewPhotoModalData =
  | {
      type: 'message photo'
      message: ChatMessageInfo
      position?: Rectangle
    }
  | {
      type: 'status photo'
      status: StatusInfo
      position?: Rectangle
    }

export type EditTopicModalData = {
  topicId: string
  topic: TopicInfo
}

export type UserRateModalData = {
  callDetails: CallDetails
  dialogId?: string
}

export type ConfirmModalData = {
  alertIcon?: React.ReactNode
  alertText: string
  alertDescription?: string
  submitLabel?: string
  cancelLabel?: string
  textAlign?: 'normal' | 'center'

  onSubmit: () => void
  onCancel: () => void
}

export type AlertModalData = {
  alertIcon?: React.ReactNode
  alertText: string
  alertDescription?: string
  textAlign?: 'normal' | 'center'

  submitLabel?: string
  onSubmit: () => void
  onDismiss: () => void
}

type ModalsData =
  | ChangeSpaceModalData
  | SetFromTwitterModalData
  | EditDialogModalData
  | RecommendedMicModalData
  | SetBackgroundModalData
  | LeaveSpaceModalData
  | PreviewPhotoModalData
  | EditTopicModalData
  | ConfirmModalData
  | ViewPhotoModalData
  | UserRateModalData
  | ToastModalData

interface ModalsState {
  modals: ModalName[]
  modalsData: Record<string, ModalsData>
}

const initialState: ModalsState = {
  modals: [],
  modalsData: {},
}

type ModalsAction =
  | { type: 'modal opened'; modalName: ModalName; modalData?: ModalsData }
  | { type: 'modal closed'; modalName: ModalName }

function modalsReducer(state: ModalsState, action: ModalsAction) {
  switch (action.type) {
    case 'modal opened':
      let modalsData = state.modalsData
      if (action.modalData) {
        modalsData[action.modalName] = action.modalData
      }
      return {
        ...state,
        modals: [
          ...state.modals.filter((modal) => modal !== action.modalName),
          action.modalName,
        ],
        modalsData,
      }
      break
    case 'modal closed':
      let data = state.modalsData
      delete data[action.modalName]
      return {
        ...state,
        modals: [...state.modals.filter((modal) => modal !== action.modalName)],
        modalsData: data,
      }

    default:
      return state
  }
}

// ----------------
// CONTEXT
// ----------------
export type ModalsContextType = [ModalsState, React.Dispatch<ModalsAction>]
const initialContext: ModalsContextType = [initialState, () => {}]
export const ModalsContext = createContext<ModalsContextType>(initialContext)
export const useCreateModalsManager = () => {
  return useReducer(modalsReducer, initialState)
}
export const useModals = () => {
  return useContext<ModalsContextType>(ModalsContext)
}
