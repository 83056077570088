import { useLatest } from '@there/components/shared/use-latest'
import { throttle } from '@there/components/utils/schedulers'
import { useEffect } from 'react'

interface WindowSize {
  width: number
  height: number
}

export function useOnWindowResize(
  callback: (size: WindowSize) => void,
  throttleMs = 200,
) {
  let callbackFunction = useLatest(callback)
  useEffect(() => {
    const handler = throttle(
      () => {
        callbackFunction.current?.({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      },
      throttleMs,
      true,
    )

    // Set size at the first client-side load
    handler()

    window.addEventListener('resize', handler)

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [callbackFunction, throttleMs])
}
