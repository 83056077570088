import styled, { css, CSSProperties } from 'styled-components'
import { darken, lighten } from 'polished'
import { truncate } from '@there/components/utils/css-snippets'
import React, {
  useState,
  useRef,
  useEffect,
  ComponentProps,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native'
import { PopoversPortal } from '@there/components/shared/ClientOnlyPortal'
import { useDebounceCallback } from '@there/components/shared/use-debounce-callback'
import {
  GetPropsCommonOptions,
  UseSelectGetToggleButtonPropsOptions,
} from 'downshift'
import { useDimensions } from '@there/components/shared/useDimensions'
import { useTransition } from '@react-spring/native'
import { AnimatedView } from '@there/components/shared/AnimatedView'
import { Pressable } from '../shared/Pressable'
import { useTheme } from './ThemeContext'
import { WebStyled } from '../shared/WebStyled'

// dropdown list types
export interface DropdownItemType {
  label: string
  onClick?: () => void
  disabled?: boolean
}

export type PickerItem = {
  label: string
  onClick?: () => void
  icon: React.ReactNode
  disabled?: boolean
}

type DropdownPosition = {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

export const RelativeWrapper = styled.div`
  position: relative;
`

type DropdownManager = {
  toggleBounds: {
    x: number
    y: number
    height: number
    width: number
  }
  setToggleBounds: React.Dispatch<
    React.SetStateAction<{
      x: number
      y: number
      height: number
      width: number
    }>
  >
  alignment: {
    horizontal: 'left' | 'right'
    vertical: 'top' | 'bottom'
  }
  setAlignment: React.Dispatch<
    React.SetStateAction<{
      horizontal: 'left' | 'right'
      vertical: 'top' | 'bottom'
    }>
  >
}

const initialValue: DropdownManager = {
  toggleBounds: {
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  },
  alignment: {
    horizontal: 'left',
    vertical: 'top',
  },

  setAlignment: () => {},
  setToggleBounds: () => {},
}

const DropdownContext = createContext<DropdownManager>(initialValue)
const useDropdownContext = () => {
  return useContext(DropdownContext)
}

export const Root = React.forwardRef(
  (
    {
      children,
      style,
    }: { children: React.ReactNode; style?: StyleProp<ViewStyle> },
    ref,
  ) => {
    const [toggleBounds, setToggleBounds] = useState<{
      x: number
      y: number
      height: number
      width: number
    }>({ x: 0, y: 0, width: 0, height: 0 })

    let [alignment, setAlignment] = useState<{
      horizontal: 'left' | 'right'
      vertical: 'top' | 'bottom'
    }>({ horizontal: 'left', vertical: 'top' })

    let value = useMemo(() => {
      return {
        toggleBounds,
        setToggleBounds,
        alignment,
        setAlignment,
      }
    }, [alignment, toggleBounds])

    return (
      <DropdownContext.Provider value={value}>
        <View style={[styles.relative, style]}>{children}</View>
      </DropdownContext.Provider>
    )
  },
)

export const Toggle = ({
  children,
  onPress,
  toggleProps,
  onContextMenu,
  isOpen,
  style,
}: {
  children:
    | React.ReactNode
    | ((input: {
        onPress: (event: GestureResponderEvent) => void
      }) => React.ReactNode)
  onPress?: () => void
  isOpen?: boolean
  onContextMenu?: () => void
  toggleProps?: (
    options?: UseSelectGetToggleButtonPropsOptions | undefined,
    otherOptions?: GetPropsCommonOptions | undefined,
  ) => any
  style?: React.CSSProperties
}) => {
  const { setToggleBounds } = useDropdownContext()
  let toggleButtonProps = toggleProps
    ? toggleProps
    : ({ ref }: { ref: React.RefObject<HTMLButtonElement> }) => {
        return { ref }
      }

  const toggleWrapperRef = useRef<TouchableOpacity>(null)

  //set toggle button bound on click
  const toggleWrapperClicked = useCallback(
    (event: GestureResponderEvent) => {
      event.preventDefault()
      if (!toggleWrapperRef?.current) return

      toggleWrapperRef.current?.measureInWindow((x, y, width, height) => {
        setToggleBounds({ width, height, x, y })
      })

      if (!onPress) return
      onPress()
    },
    [onPress, setToggleBounds],
  )

  //set toggle button bound on click
  const contextMenuClicked: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault()

      setToggleBounds({
        width: 0,
        height: 0,
        x: event.clientX,
        y: event.clientY,
      })

      if (!onContextMenu) return
      onContextMenu()
    },
    [onContextMenu, setToggleBounds],
  )

  // set new toggle bounds on resize
  const { debouncedCallback } = useDebounceCallback(() => {
    if (!toggleWrapperRef?.current) return
    if (onContextMenu) return
    toggleWrapperRef.current?.measureInWindow((x, y, width, height) =>
      setToggleBounds({ width, height, x, y }),
    )
  }, 200)

  useEffect(() => {
    window.addEventListener('resize', debouncedCallback, { passive: true })
    return () => window.removeEventListener('resize', debouncedCallback)
  }, [debouncedCallback])

  let divRef = useRef<HTMLButtonElement>(null)

  if (onContextMenu) {
    return (
      <WebStyled
        ref={divRef}
        {...toggleButtonProps({ ref: divRef }, { suppressRefError: true })}
        onContextMenu={contextMenuClicked}
        onClick={() => {}}
        onMouseUp={() => {}}
        style={style}
      >
        {children}
      </WebStyled>
    )
  }

  return (
    <TouchableOpacity
      activeOpacity={0.85}
      {...toggleButtonProps({
        ref: (toggleWrapperRef as unknown) as React.RefObject<
          HTMLButtonElement
        >,
      })}
      onPress={(event) => toggleWrapperClicked(event)}
    >
      {typeof children === 'function'
        ? children({ onPress: toggleWrapperClicked })
        : children}
    </TouchableOpacity>
  )
}

export const Menu = React.forwardRef(
  (props: ComponentProps<typeof WrapperNode>, ref) => {
    const [dropdownPosition, setDropdownPosition] = useState<
      DropdownPosition | undefined
    >(undefined)

    const [dropdownWidth, setDropdownWidth] = useState<number>(0)
    let { alignment, toggleBounds } = useDropdownContext()

    useEffect(() => {
      setDropdownWidth(props.width)
    }, [props.width, setDropdownWidth])

    let shouldRenderDropdown = useMemo(() => {
      if (!dropdownPosition) return false
      if (!checkIsToggleBoundsAvailable(toggleBounds)) return false
      return true
    }, [dropdownPosition, toggleBounds])

    let transitions = useTransition(props.isOpen, {
      from: {
        opacity: 0,
        scale: 0.5,
      },
      enter: {
        opacity: 1,
        scale: 1,
      },
      leave: {
        opacity: 0,
        scale: 0.8,
      },
      config: {
        mass: 0.4,
        tension: 400,
        friction: 22,
      },
    })

    let positions = {
      top: dropdownPosition?.top || 0,
      left: dropdownPosition?.left || 0,
      bottom: dropdownPosition?.bottom,
    }

    return (
      <PopoversPortal
        // isOpen={shouldRenderDropdown && props.isOpen}
        isOpen={props.isOpen}
        onClose={props.onClose}
        backMode="transparent"
        inside={
          <>
            {transitions(({ scale, opacity }, item) => {
              return item ? (
                <AnimatedView
                  style={{
                    position: 'absolute',
                    opacity: shouldRenderDropdown ? opacity : 0,
                    display: 'flex',
                    ...positions,
                    zIndex: 20,
                    transform: [
                      {
                        translateX:
                          alignment.horizontal === 'left' ? 0 : dropdownWidth,
                      },
                      { translateY: alignment.vertical === 'top' ? 0 : 200 },
                      { scale: scale },
                      {
                        translateX:
                          alignment.horizontal === 'left' ? 0 : -dropdownWidth,
                      },
                      { translateY: alignment.vertical === 'top' ? 0 : -200 },
                    ],
                  }}
                  {...props}
                >
                  <MenuNode
                    isOpen={props.isOpen}
                    dropdownWidth={dropdownWidth}
                    dropdownPosition={dropdownPosition}
                    setDropdownPosition={setDropdownPosition}
                    {...props}
                    {...positions}
                  >
                    {props.children}
                  </MenuNode>
                </AnimatedView>
              ) : null
            })}
          </>
        }
      />
    )
  },
)

const MenuNode = ({
  setDropdownPosition,
  dropdownWidth,
  ...props
}: {
  isOpen: boolean
  dropdownWidth: number
  dropdownPosition: DropdownPosition | undefined
  setDropdownPosition: React.Dispatch<
    React.SetStateAction<DropdownPosition | undefined>
  >
} & ComponentProps<typeof WrapperNode>) => {
  const { toggleBounds, alignment, setAlignment } = useDropdownContext()
  const [maxHeight, setMaxHeight] = useState<number>(0)

  const { window } = useDimensions()

  const OFFSET_TOP = 4
  const OFFSET_BOTTOM = 4
  const minimumBottomPadding = window.height < 200 ? 10 : 10
  const minimumTopPadding = 30

  let isToggleBounds = checkIsToggleBoundsAvailable(toggleBounds)

  useEffect(() => {
    if (!isToggleBounds) return

    // define critical areas statuses
    let smallCriticalFactor = 2
    let largeCriticalFactor = 3
    let isBottomCriticalArea =
      toggleBounds.y >
      window.height -
        window.height /
          (window.height > 250 ? largeCriticalFactor : smallCriticalFactor)
    let isRightCriticalArea =
      toggleBounds.x >
      window.width -
        window.width /
          (window.width > 400 ? largeCriticalFactor : smallCriticalFactor)

    setMaxHeight(
      !isBottomCriticalArea
        ? window.height -
            minimumBottomPadding -
            (toggleBounds.y + toggleBounds.height + OFFSET_TOP)
        : toggleBounds.y - minimumTopPadding,
    )

    setAlignment({
      horizontal: isRightCriticalArea ? 'right' : 'left',
      vertical: isBottomCriticalArea ? 'bottom' : 'top',
    })

    let bottomPadding = 20

    setDropdownPosition({
      top: isBottomCriticalArea
        ? undefined
        : toggleBounds.y + toggleBounds.height + OFFSET_TOP,
      bottom: isBottomCriticalArea
        ? bottomPadding >= minimumBottomPadding
          ? bottomPadding
          : minimumBottomPadding
        : undefined,
      left: isRightCriticalArea
        ? toggleBounds.x + toggleBounds.width - dropdownWidth
        : toggleBounds.x,
      right: undefined,
    })
  }, [
    dropdownWidth,
    isToggleBounds,
    minimumBottomPadding,
    setAlignment,
    setDropdownPosition,
    toggleBounds.height,
    toggleBounds.width,
    toggleBounds.x,
    toggleBounds.y,
    window.height,
    window.width,
  ])

  return (
    <WrapperNode
      key={1}
      placement={alignment.vertical}
      align={alignment.horizontal}
      maxHeight={maxHeight}
      {...props}
    >
      {props.children}
    </WrapperNode>
  )
}

let sidePadding = 10
let sideItemPadding = 4

let sidePaddingLarge = 12
let sidePaddingLarger = 16

export const WrapperNode = styled.div<{
  width?: number
  height?: number
  maxHeight?: number
  align?: 'left' | 'right'
  placement?: 'top' | 'bottom'
  paddingHorizontal?: number
  bottom: number
  left?: number
}>`
  flex-shrink: 0;
  ${(p) =>
    p.width &&
    css`
      width: ${p.width}px;
    `}

  ${(p) =>
    p.height &&
    css`
      height: ${p.height}px;
    `}

  ${(p) =>
    p.maxHeight &&
    css`
      max-height: ${p.maxHeight}px;
    `}

  ${(p) =>
    p.align === 'left'
      ? css`
          transform-origin: top left;
        `
      : css`
          transform-origin: top right;
        `}

  ${(p) =>
    p.placement === 'bottom' &&
    css`
      bottom: ${p.bottom}px;
      ${p.align === 'left'
        ? css`
            transform-origin: bottom left;
          `
        : css`
            transform-origin: bottom right;
          `}
    `}

  
  border-radius: 8px;
  box-shadow: 0px 11px 16px 4px rgba(0, 0, 0, 0.15),
    0px 3px 4px rgba(0, 0, 0, 0.15);
  background: ${(p) => p.theme.colors.popoverBg};
  outline: none;

  position: absolute;

  /* z-index: 8; */
  overflow: scroll;
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`

export const Section = styled.div<{
  width?: number
  paddingHorizontal?: number
}>`
  width: 100%;
  position: relative;
  outline: none;
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;

  ${(p) =>
    p.paddingHorizontal &&
    css`
      padding-left: ${p.paddingHorizontal}px;
      padding-right: ${p.paddingHorizontal}px;
    `}
`

const HeadWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Head = styled.div<{ paddingType?: 'larger' }>`
  ${(p) =>
    p.paddingType === 'larger'
      ? css`
          padding-left: ${sidePaddingLarger}px;
          padding-right: ${sidePaddingLarger}px;
        `
      : css`
          padding-left: ${sidePadding}px;
          padding-right: ${sidePadding}px;
        `}

  font-size: ${(p) => p.theme.fontSizes['small']}px;
  font-weight: ${(p) => p.theme.fontWeights.bolder};
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.1em;
  color: ${(p) => p.theme.colors.quaternaryText};
`

const Name = styled.span`
  margin-left: 5px;

  font-size: ${(p) => p.theme.fontSizes['15']}px;
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 18px;
  letter-spacing: 0.01em;
  color: ${(p) => p.theme.colors.tertiaryText};
`

const Title = styled.span`
  font-size: ${(p) => p.theme.fontSizes['13']}px;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  color: ${(p) => p.theme.colors.text};
`

const Description = styled.p`
  margin: 0px;
  padding: 0px;
  margin-top: 3px;

  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes['12']}px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: ${(p) => p.theme.colors.tertiaryLight};

  span {
    cursor: pointer;
    transition: 0.12s ease-in-out;
    color: ${(p) => p.theme.colors.secondaryText};
    text-decoration: underline;

    :hover {
      color: ${(p) => p.theme.colors.tertiaryText};
    }
    :active {
      color: ${(p) => p.theme.colors.text};
    }

    &.active {
      text-decoration: none;
      cursor: default;

      :hover {
        color: ${(p) => p.theme.colors.secondaryText};
      }
      :active {
        color: ${(p) => p.theme.colors.secondaryText};
      }
    }
  }
`

const SecondaryDescription = styled.p`
  margin: 0px;
  padding: 0px;
  padding-left: ${sidePadding}px;
  padding-right: ${sidePadding}px;

  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes['11']}px;
  line-height: 13px;
  color: ${(p) => p.theme.colors.quaternaryLight};
`

const InputBox = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  background: ${(p) => p.theme.colors.transparentInputBackground};

  display: flex;
  align-items: center;
`

const Input = styled.input`
  position: relative;
  width: 76%;
  height: 32px;
  padding: 8px;
  border-radius: 7px;
  border: none;
  outline: none;
  background: none;

  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.normal}px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.text};
`

const InlineButton = styled.div`
  position: absolute;
  height: 27px;
  right: 3px;
  padding: 0px 5px;
  box-shadow: inset 0px 20px 14px -18px rgba(255, 255, 255, 0.53);
  border-radius: 5px;
  background: ${(p) => p.theme.colors.primary};

  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes['15']}px;
  color: ${(p) => p.theme.colors.tertiaryText};

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: ${(p) => lighten(0.03, p.theme.colors.primary)};
    color: ${(p) => p.theme.colors.tertiaryText};
  }

  :active {
    background: ${(p) => darken(0.05, p.theme.colors.primary)};
    color: ${(p) => p.theme.colors.tertiaryLight};
  }
`

const Item = styled.div<{
  highlighted?: boolean
  width?: number
  isDim?: boolean
  selected?: boolean
}>`
  min-width: 0;
  max-width: 100%;
  height: 26px;
  padding: 4px 8px;
  margin: 0px ${sideItemPadding}px;
  border-radius: ${(p) => p.theme.borderRadius.small}px;
  flex-shrink: 1;
  flex-grow: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes['normal']}px;
  color: ${(p) => p.theme.colors.secondaryText};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  & span {
    ${truncate};
  }

  transition: ${(p) => p.theme.transition.background};

  ${(p) =>
    p.highlighted &&
    css`
      background: ${(p) => p.theme.colors.hoverItemBackground};
    `}

  ${(p) => p.selected && css``}

  ${(p) =>
    p.isDim &&
    css`
      opacity: 0.65;
    `}
`

const MiniItem = styled.div<{ highlighted?: boolean; width?: number }>`
  max-width: 100%;
  height: 22px;
  padding: 5px 8px;
  margin: 0px ${sideItemPadding}px;
  border-radius: ${(p) => p.theme.borderRadius.small}px;
  flex-grow: 0;
  flex-shrink: 1;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */

  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes['small']}px;
  color: ${(p) => p.theme.colors.tertiaryText};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  transition: ${(p) => p.theme.transition.background};

  ${(p) =>
    p.highlighted &&
    css`
      background: ${(p) => p.theme.colors.hoverItemBackground};
    `}
`

const ItemWithIcon = ({
  isHighlighted,
  isActive,
  children,
  onPress = () => {},
}: {
  children: React.ReactNode
  isHighlighted?: boolean
  isActive?: boolean
  onPress?: () => void
}) => {
  let theme = useTheme()
  return (
    <Pressable
      onPress={onPress}
      style={({ hovered, pressed }) => [
        {
          maxWidth: '100%',
          height: 28,
          marginHorizontal: sideItemPadding,
          paddingHorizontal: 4,
          borderRadius: theme.borderRadius.small,

          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexShrink: 0,
        },
        (hovered || isHighlighted) && {
          backgroundColor: theme.colors.hoverItemBackground,
        },
        pressed && {
          backgroundColor: theme.colors.activeItemBackground,
        },
        isActive && {
          backgroundColor: theme.colors.primary,
        },
      ]}
    >
      {children}
    </Pressable>
  )
}

const ItemIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: ${(p) => p.theme.colors.hoverItemBackground};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  transition: ${(p) => p.theme.transition.colors};

  svg path {
    transition: ${(p) => p.theme.transition.colors};
  }
`

const ItemTitle = styled.div<{ width?: number }>`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes['small']}px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.tertiaryText};

  ${truncate}
`

const ItemDescription = styled.div<{ width?: number }>`
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes['small']}px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.tertiaryLight};

  ${truncate}
`

const Inner = styled.div<{ type?: 'large' | 'larger' }>`
  ${(p) =>
    p.type === 'large'
      ? css`
          padding-left: ${sidePaddingLarge}px;
          padding-right: ${sidePaddingLarge}px;
        `
      : p.type === 'larger'
      ? css`
          padding-left: ${sidePaddingLarger}px;
          padding-right: ${sidePaddingLarger}px;
        `
      : css`
          padding-left: ${sidePadding}px;
          padding-right: ${sidePadding}px;
        `}
`

export const Dropdown = {
  sideItemPadding,
  sidePaddingLarge,
  sidePadding,
  Root,
  Toggle,
  Menu,
  WrapperNode,
  Section,
  HeadWithIcon,
  Head,
  Name,
  Title,
  Inner,
  Description,
  SecondaryDescription,
  InputBox,
  Input,
  InlineButton,
  Item,
  MiniItem,
  ItemWithIcon,
  ItemIcon,
  ItemTitle,
  ItemDescription,
}

const styles = StyleSheet.create({
  relative: {
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
  },
})

const checkIsToggleBoundsAvailable = ({
  x,
  y,
  height,
  width,
}: {
  x: number
  y: number
  height: number
  width: number
}) => {
  if (x === 0 && y === 0 && width === 0 && height === 0) {
    return false
  }
  return true
}
