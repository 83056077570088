import {
  SubscriptionInput,
  useNurSubscription,
} from '@there/components/sun/use-subscription'
import type { NotificationMessage } from '@there/sun/modules/sendNotification'

export type NotificationData = NotificationMessage['payload']

export const useNotificationSubscription = (
  args: Omit<SubscriptionInput<NotificationData>, 'method'>,
) => {
  return useNurSubscription<NotificationData>({
    method: 'notification',
    ...args,
  })
}
