import gql from 'graphql-tag'
import { useMutation } from 'urql'
import {
  avatarInfoFragment,
  AvatarInfo,
} from '@there/components/urql/fragments/avatarInfo'
import {
  UserInfo,
  userInfoFragment,
} from '@there/components/urql/fragments/userInfo'
import { userSpacesInfoFragment } from '@there/components/urql/fragments/userSpacesInfo'
import { MemberInfo } from '@there/components/urql/fragments/spaceMembersInfo'
import { Override } from '@there/shared/types'
import { MemberWithSpaceInfo } from '@there/components/urql/fragments/memberInfo'

export const CreateSpaceMutation = gql`
  mutation createSpace($name: String!) {
    user: createSpace(name: $name) {
      ...userInfo
      ...userSpacesInfo
      avatar {
        ...avatarInfo
      }
    }
  }
  ${userInfoFragment}
  ${avatarInfoFragment}
  ${userSpacesInfoFragment}
`

export type Variables = { name: string }
export type Data = {
  user?: Override<
    UserInfo,
    {
      memberships: MemberWithSpaceInfo[]
      avatar?: AvatarInfo
    }
  >
}

export const useCreateSpaceMutation = () =>
  useMutation<Data, Variables>(CreateSpaceMutation)
