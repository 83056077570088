import { useEffect, useState } from 'react'
import {
  electronShouldUseDarkColors,
  ipc,
} from '@there/desktop/utils/electron-api'

export const useNativeTheme = () => {
  const [isDarkMode, setDarkMode] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined') return
    setDarkMode(
      Boolean(
        electronShouldUseDarkColors ||
          window.matchMedia('(prefers-color-scheme: dark)'),
      ),
    )
  }, [])

  useEffect(() => {
    async function getMode() {
      if (ipc) {
        // Don't adopt for now until we make light UI better
        setDarkMode(await ipc.invoke('is-dark-mode'))
      }
    }

    function themeChanged(_: any, nativeTheme: import('electron').NativeTheme) {
      setDarkMode(nativeTheme.shouldUseDarkColors)
      return
    }

    ipc?.on('there:theme-changed', themeChanged)

    getMode()

    return () => {
      ipc?.removeListener('there:theme-changed', themeChanged)
    }
  }, [])

  // dark mode only for now
  return { shouldUseDarkColors: true }
}
