import * as React from 'react'
import Svg, { SvgProps, G, Path } from 'react-native-svg'

export const ReplyIcon = (props: SvgProps) => (
  <Svg width={15} height={15} fill="none" viewBox="0 0 15 15" {...props}>
    <G fill={props.color ? props.color : '#fff'}>
      <Path
        opacity={0.37}
        d="M5.19 2.59.72 6.63a.47.47 0 0 0 0 .7l4.46 4.13a.47.47 0 0 0 .79-.35V2.93a.47.47 0 0 0-.78-.34Z"
      />
      <Path d="M14.56 11.12c0-.51.1-2.54-1.49-4.14C12 5.9 10.4 5.7 8.47 5.62V2.93a.47.47 0 0 0-.78-.34L3.22 6.63a.47.47 0 0 0 0 .7l4.46 4.13a.47.47 0 0 0 .79-.35V8.44c1.22.03 2.09.1 2.88.36 1.14.36 2.02 1.39 2.64 3.08.06.18.24.3.44.3h.13v-1.06Z" />
    </G>
  </Svg>
)
