import { RtcPeer } from '@there/components/shared/rtc-peer'
import { useLatest } from '@there/components/shared/use-latest'
import { RtcAvatar } from '@there/components/shared/use-rtc-avatars'
import { ipc, isElectron } from '@there/desktop/utils/electron-api'
import useInterval from '@use-it/interval'
import { useCallback, useRef } from 'react'
import type { PeerConnectionLog } from '@there/sun/model/logPeerConnection'
import Sentry from '@there/app/utils/sentry'
import { useLogPeerConnection } from '@there/components/main/useLogPeerConnection'

const debug = require('debug')('desktop:peer-logger')

const TRACKING_INTERVAL_SECONDS = 15

interface Input {
  enabled: boolean
  sessionId: string | undefined | null
  participants: RtcAvatar[] | undefined
}

/** To be enabled only in "RtcWindow", if done elsewhere, make sure to check its db sync calls */
export function usePeerLogger({
  enabled,
  sessionId,
  participants,
}: Input): (peer: RtcPeer<any>, peerUserId: string) => void {
  let logs = useRef<PeerConnectionLog[]>([])

  let sessionIdRef = useLatest(sessionId || '')
  let roomSizeRef = useLatest(participants?.length)
  let enabledRef = useLatest(enabled)

  const trackPeer = useCallback(
    (peer: RtcPeer<any>, peerUserId: string) => {
      let logPushed = false

      function start() {
        if (!enabledRef.current) {
          debug('Called trackPeer when not enabled. It will not work.')
          return
        }
        if (!peer.peer) {
          console.warn('[logger] Failed to get peer handle to track')
          return
        }

        let peerLog: PeerConnectionLog = {
          time: Date.now(),
          sessionId: sessionIdRef.current || '',
          roomSize: roomSizeRef.current || 0,
          peerUserId,
          // to be filled
          failed: false,
          connectedInMs: 0,
        }

        function push() {
          logs.current.push(peerLog)
          logPushed = true
        }

        peer.addListener('connectionStateChange', (connectionState) => {
          if (logPushed) return
          if (connectionState === 'connected') {
            // Mark as connected
            peerLog.failed = false
            peerLog.connectedInMs = Math.floor(Date.now() - peerLog.time)
            push()
          }
        })

        peer.addListener('error', () => {
          if (logPushed) return
          // mark as failed
          peerLog.failed = true
          peerLog.connectedInMs = Math.floor(Date.now() - peerLog.time)
          push()
        })
      }

      if (peer.peer) {
        start()
      } else {
        // Slight timeout makes sure peer.peer is created
        peer.addListener('peerInitialize', () => {
          if (logPushed) return
          start()
        })
      }
    },
    [enabledRef, roomSizeRef, sessionIdRef],
  )

  let [, logPeerConnection] = useLogPeerConnection()

  // sync array with db
  useInterval(
    () => {
      if (!logs.current) {
        logs.current = []
        return
      }
      if (logs.current?.length > 12) {
        Sentry.captureMessage(
          'pending peer connection logs are more than 12... Not sending.',
        )
        logs.current = []
        return
      }

      if (logs.current.length === 0) {
        return
      }

      // send
      if (isElectron) {
        ipc
          ?.invoke('feed:general-ipc', {
            event: 'nur-log-peer-connection',
            payload: {
              logs: logs.current,
            },
          })
          .catch(() => {})
      } else {
        // Browser
        logPeerConnection({
          logs: logs.current,
        })
      }

      // flush
      logs.current = []
    },
    enabled ? TRACKING_INTERVAL_SECONDS * 1000 : null,
  )

  // This way it's more stable
  return trackPeer
}
