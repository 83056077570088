import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  View,
  TouchableOpacity,
  StyleSheet,
  StyleProp,
  ViewStyle,
  Text,
} from 'react-native'
import { useTheme } from '@there/components/feed/ThemeContext'
import { UserInfo } from '@there/sun/utils/node-types'
import { PuffSpinner } from '@there/components/shared/PuffSpinner'
import { useSpring, a, useTransition } from '@react-spring/native'
import { Tooltip } from '@there/components/shared/Tooltip'
import { PersonSharingIndicator } from '@there/components/main/PersonSharingIndicator'
import { CameraDropdown } from '@there/components/dropdown/CameraDropdown'
import { NoSelfView } from '@there/components/main/mainIcons'
import { MicrophoneIcon } from '@there/components/shared/MicrophoneIcon'
import { useWindowContext } from '../shared/WindowContext'
import { webAvatarSize } from './CameraView'
import { useWidth } from '../shared/use-width'

type PinAvatarProps = {
  size: number
  isUs: boolean
  hiddenSelfView?: boolean
  user: UserInfo
  cameraStream: MediaStream | null | undefined
  style?: StyleProp<ViewStyle>
  isSharingCamera: boolean
  isTalking: boolean
  isSharingScreen: boolean
  joinParticipantScreen: (userId: string) => void
}

export const VideoAvatar = (props: PinAvatarProps) => {
  let {
    isUs,
    user,
    hiddenSelfView,
    cameraStream,
    isSharingCamera,
    isTalking,
    isSharingScreen,
    joinParticipantScreen,
    size,
  } = props
  const theme = useTheme()
  let videoRef = useRef<HTMLVideoElement | null>(null)
  let [videoLoaded, setVideoLoaded] = useState(false)
  let { isElectron } = useWindowContext()
  let [wrapperWidth, widthHandlers] = useWidth(0)
  let isBrowser = !isElectron

  useEffect(() => {
    let video = videoRef.current
    if (!video) return
    if (!cameraStream) return

    function onLoadedData() {
      setVideoLoaded(true)
    }

    function onUnmute() {
      setVideoLoaded(true)
    }

    function onMute() {
      // setVideoLoaded(false)
    }

    video.srcObject = cameraStream
    video.addEventListener('loadedmetadata', onLoadedData)
    video.play().catch(() => {
      console.warn('Failed to play avatar video')
    })

    let track: MediaStreamTrack | undefined
    try {
      track = cameraStream.getVideoTracks()[0]
      track.onunmute = onUnmute
      track.addEventListener('mute', onMute)
    } catch (error) {}

    return () => {
      if (track) {
        track.onunmute = null
        track.removeEventListener('mute', onMute)
      }
      video?.removeEventListener('loadedmetadata', onLoadedData)
    }
  }, [cameraStream])

  let fadeLoadStyles = useSpring({
    opacity: isSharingCamera && videoLoaded ? 1 : 0,
    delay: isUs || !isSharingCamera ? 0 : 500,
    config: {
      mass: 0.1,
      tension: 300,
    },
  })

  let renderIndicatorTransition = useTransition(isSharingScreen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },

    config: { tension: 200, friction: 20 },
  })

  const onSharingPress = (userId: string) => {
    joinParticipantScreen(userId)
  }

  let radius = (wrapperWidth || webAvatarSize) / 2
  return (
    <View
      style={[
        {
          position: 'relative',
          marginLeft: 2,
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        },
        isBrowser && {
          width: webAvatarSize,
          height: webAvatarSize,
        },
      ]}
      {...widthHandlers}
    >
      {isTalking && (
        <View
          style={{
            borderWidth: 1,
            borderColor: theme.colors.avatarTalkingBorder,
            position: 'absolute',
            top: -3,
            left: -3,
            right: -3,
            bottom: -3,
            borderRadius: wrapperWidth || 200,
          }}
        />
      )}

      <View
        style={[
          {
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: wrapperWidth || 200,
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
          },
          isSharingScreen && {
            borderWidth: 1,
            borderColor: theme.colors.screenShareButtonIcon,
          },
        ]}
      >
        {user ? (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2,

              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!videoLoaded && cameraStream && isSharingCamera ? (
              <FloatingLoading />
            ) : null}
            {hiddenSelfView ? (
              <NoSelfView color={theme.colors.secondaryLight} />
            ) : (
              <StaticProfile user={user} />
            )}
          </View>
        ) : null}

        <a.View
          style={[
            fadeLoadStyles,
            { height: '100%', position: 'relative', zIndex: 2 },
            hiddenSelfView && { display: 'none' },
          ]}
        >
          <video
            ref={videoRef}
            controls={false}
            autoPlay={true}
            muted={true}
            style={{
              transform: isUs ? `scale(-1, 1)` : `unset`,
              height: '100%',
              filter: isSharingCamera && videoLoaded ? undefined : 'blur(5px)',
            }}
          />
        </a.View>
      </View>
      {!isTalking && (
        <View
          style={[
            {
              position: 'absolute',
              left: 0,
              bottom: 0,
              marginLeft: radius - radius * Math.sin(Math.PI / 4) - 12,
              marginBottom: radius - radius * Math.sin(Math.PI / 4) - 12,
            },
          ]}
        >
          <MuteMicIndicator />
        </View>
      )}

      {/* Screen sharing indicator */}
      {renderIndicatorTransition(({ opacity }, isUserSharing) =>
        isUserSharing ? (
          <a.View
            style={{
              //@ts-ignore
              cursor: 'pointer',
              width: 23,
              height: 23,
              position: 'absolute',
              bottom: 0,
              right: 0,
              marginRight: radius - radius * Math.sin(Math.PI / 4) - 12,
              marginBottom: radius - radius * Math.sin(Math.PI / 4) - 12,
              zIndex: 2,
              opacity,
            }}
          >
            <Tooltip label={isUs ? 'Sharing' : 'View'} placement="bottomCenter">
              <PersonSharingIndicator
                user={user}
                onPress={() => {
                  onSharingPress(user.id)
                }}
                isUs={isUs}
              />
            </Tooltip>
          </a.View>
        ) : null,
      )}
    </View>
  )
}

const FloatingLoading = () => {
  return (
    <View
      style={{
        position: 'absolute',
        width: 30,
        height: 30,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
      }}
    >
      <PuffSpinner size={30} />
    </View>
  )
}

/** Photo or small initials */
const StaticProfile = (props: { user: UserInfo }) => {
  let {
    user: { profilePhoto, name, email },
  } = props
  let theme = useTheme()
  let [failedToLoad, setFailedToLoad] = useState(false)

  const onError = useCallback(() => {
    setFailedToLoad(true)
  }, [])

  useEffect(() => {
    setFailedToLoad(false)
  }, [profilePhoto])

  let initials = ''
  let nameString = name || email || ''
  const nameParts = nameString.split(' ')
  const isOnePart = nameParts.length === 1

  if (isOnePart) {
    initials = nameString[0]
  } else if (nameParts.length > 1) {
    // Two part
    initials = (nameString[0] || '') + (nameParts[1][0] || '')
  }

  return (
    <View style={[styles.staticProfileWrapper]}>
      {profilePhoto && !failedToLoad ? (
        <img
          src={profilePhoto}
          width={42}
          height={42}
          onError={onError}
          style={{ borderRadius: '50%', margin: 0 }}
        />
      ) : (
        <Text
          style={{
            fontSize: 15,
            fontWeight: 'bold',
            letterSpacing: 1,
            color: theme.colors.tertiaryText,
          }}
        >
          {initials}
        </Text>
      )}
    </View>
  )
}

const MuteMicIndicator = () => {
  let theme = useTheme()
  return (
    <View
      style={{
        width: 23,
        height: 23,
        borderRadius: 23,
        backgroundColor: theme.colors.personMutedMicBadgeBackground,
        shadowColor: '#000',
        shadowOpacity: 0.25,
        shadowOffset: {
          height: 1,
          width: 0,
        },
        shadowRadius: 0,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MicrophoneIcon
        color={theme.colors.quaternaryText}
        muted={true}
        width={17}
        height={17}
      />
    </View>
  )
}

// VideoAvatar must be fluid
const styles = StyleSheet.create({
  wrapper: {},
  staticProfileWrapper: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 200,
    width: '100%',
    height: '100%',
  },
  avatar: {},
})
