/**
 * ☢️ Universal
 *
 */
import { SunError, SunErrorCodes } from '@there/sun/ws/errors'
import {
  AvatarInfo,
  DialogInfo,
  NodeUpdateInfo,
} from '@there/sun/utils/node-types'
import { formatUpdates } from '@there/sun/utils/formatters/formatUpdates'

/**
 * Universal Module = used in api and client side
 *
 * Actual logic of leave is here that uses abstract data and returns abstract updates array to be applied
 */
export const getLeaveDialogUpdates = ({
  dialog,
  dialogId,
  avatarId,
  userId,
}: {
  /** @deprecated */
  dialog?: DialogInfo
  dialogId: string
  avatarId: string
  userId: string
}): NodeUpdateInfo[] => {
  // Create optimistic
  let updatedAvatars: AvatarInfo[] = []
  let updatedDialog: DialogInfo

  // Only leave ourself
  updatedAvatars = [{ id: avatarId, dialogId: null }]

  // Avatar count was wrong.
  // This was reseting cache on client
  // updatedDialog = {
  //   id: dialogId,
  //   avatarCount:
  //     typeof dialog.avatarCount === 'number'
  //       ? dialog.avatarCount - 1
  //       : undefined,
  // }

  let updates: NodeUpdateInfo[] = [
    ...updatedAvatars.map(
      (node) =>
        ({ mutation: 'Update', type: 'Avatar', node } as NodeUpdateInfo),
    ),
    {
      mutation: 'Update',
      type: 'User',
      node: { id: userId, talking: false, sharing: false },
    },
    // { mutation: 'Update', type: 'Dialog', node: updatedDialog },
  ]

  return formatUpdates(updates)
}
