import { useWindowFocused } from '@there/components/shared/use-window-focused'
import { useWindowUnfocused } from '@there/components/shared/use-window-unfocused'
import { isElectron, platform } from '@there/desktop/utils/electron-api'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'

type State = {
  windowFocused: boolean
  isElectron: boolean
  platform: ElectronPlatform
  isWindows: boolean
  isMacOs: boolean
  hasDesktopVersion: boolean
}

const initialState: State = {
  windowFocused: true,
  isElectron: true,
  platform: undefined,
  isWindows: false,
  isMacOs: false,
  hasDesktopVersion: true,
}

export const MaxWidthWeb = 600

type ElectronPlatform = 'darwin' | 'win32' | 'linux' | undefined

export type Action =
  | { type: 'window focused' }
  | { type: 'window blurred' }
  | {
      type: 'electron state changed'
      isElectron: boolean
      platform: ElectronPlatform
      hasDesktopVersion: boolean
    }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'window focused':
      return { ...state, windowFocused: true }
    case 'window blurred':
      return { ...state, windowFocused: false }
    case 'electron state changed':
      return {
        ...state,
        isElectron: action.isElectron,
        platform: action.platform,
        isWindows: action.platform === 'win32',
        isMacOs: action.platform === 'darwin',
        hasDesktopVersion: action.hasDesktopVersion,
      }

    default:
      return state
  }
}

export const WindowContext = createContext<State>(initialState)
export const useWindowContext = () => useContext(WindowContext)

export const WindowContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  let [state, dispatch] = useReducer(reducer, initialState)

  useWindowUnfocused(
    useCallback(() => {
      dispatch({ type: 'window blurred' })
    }, []),
  )

  useWindowFocused(
    useCallback(() => {
      dispatch({ type: 'window focused' })
    }, []),
  )

  useEffect(() => {
    let hasDesktopVersion =
      navigator.platform === 'MacIntel' || navigator.platform === 'Win32'
    dispatch({
      type: 'electron state changed',
      isElectron,
      platform: platform,
      hasDesktopVersion,
    })
  }, [])

  return (
    <WindowContext.Provider value={state}>{children}</WindowContext.Provider>
  )
}
