import { memo, useCallback, useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Image,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { Pressable } from '../shared/Pressable'
import { Space } from '../shared/Space'
import { useLatest } from '../shared/use-latest'
import { SearchGif } from './SearchGif'
import { ConfigType } from './use-picker'
import { GifMedia, useTenor } from './use-tenor'

const scrollRefetchThreshold = 100

export const GifPickerContainer = ({
  config,
  isOpen,
  onGifPress,
}: {
  onGifPress: (gif: GifMedia) => void
  config: ConfigType
  isOpen: boolean
}) => {
  let { fetchMore, fetchTrending, gifArray, search } = useTenor()
  let [isSearching, setSearching] = useState(false)

  let theme = useTheme()
  let gifWidth =
    (config.containerWidth -
      theme.spaces.sidePaddingNarrow -
      config.emojiMargin) /
    2

  useEffect(() => {
    if (isSearching) return
    fetchTrending()
  }, [fetchTrending, isSearching])

  return (
    <ScrollView
      style={{ height: config.containerHeight - 36, width: '100%' }}
      scrollEventThrottle={200}
      onScroll={(event) => {
        let layoutHeight = event.nativeEvent.layoutMeasurement.height
        let contentSizeHeight = event.nativeEvent.contentSize.height
        let endY = contentSizeHeight - layoutHeight
        let y = event.nativeEvent.contentOffset?.y

        if (y + layoutHeight + scrollRefetchThreshold >= contentSizeHeight) {
          fetchMore()
        }
      }}
      contentContainerStyle={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
      }}
    >
      <SearchGif
        config={config}
        setSearching={setSearching}
        search={search}
        isOpen={isOpen}
      />
      <View
        style={{
          width:
            config.containerWidth +
            theme.spaces.sidePaddingNarrow * 2 -
            config.emojiMargin,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          paddingHorizontal: theme.spaces.sidePaddingNarrow,
        }}
      >
        <GifColumn
          gifArray={gifArray[0]}
          onGifPress={onGifPress}
          width={gifWidth}
        />
        <Space horizontal={theme.spaces.sidePaddingNarrow} />
        <GifColumn
          gifArray={gifArray[1]}
          onGifPress={onGifPress}
          width={gifWidth}
        />
      </View>
    </ScrollView>
  )
}

const GifColumn = memo(
  ({
    gifArray,
    onGifPress,
    width,
  }: {
    gifArray: any[]
    onGifPress: (gif: GifMedia) => void
    width: number
  }) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {gifArray.map((gif) => {
          let selectedGifMedia = gif?.media[0]['tinymp4'] as
            | GifMedia
            | undefined
          if (!selectedGifMedia) return
          return (
            <Gif
              key={selectedGifMedia.url}
              gif={{
                ...selectedGifMedia,
                id: gif.id,
                name: gif.content_description,
              }}
              onPress={onGifPress}
              width={width}
            />
          )
        })}
      </View>
    )
  },
)

export const Gif = ({
  gif,
  onPress,
  width,
  style,
  marginTop = 10,
}: {
  gif: GifMedia
  onPress?: (gif: GifMedia) => void
  width: number
  style?: StyleProp<ViewStyle>
  marginTop?: number
}) => {
  let [loaded, setLoaded] = useState(false)
  let [originalWidth, originalHeight] = gif.dims
  let height = (originalHeight * width) / originalWidth
  let theme = useTheme()

  return (
    <Pressable
      onPress={() => {
        if (onPress) {
          onPress(gif)
        }
      }}
      disabled={!onPress}
      style={[
        {
          width,
          height,
          marginTop: marginTop,
          borderRadius: 6,
          overflow: 'hidden',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
        },
        style,
      ]}
    >
      {gif.preview && (
        <>
          <Image
            source={{ uri: gif.preview }}
            style={{
              width,
              height,
            }}
            blurRadius={2}
          />
          <ActivityIndicator
            size={25}
            color={theme.colors.palePink}
            style={{
              display: loaded ? 'none' : 'flex',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        </>
      )}
      <video
        src={gif.url}
        key={gif.url}
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        preload="none"
        onLoad={() => setLoaded(true)}
        style={{ position: 'absolute', width, height }}
      />
    </Pressable>
  )
}
