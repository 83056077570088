import React, {
  createContext,
  ReactNode,
  useReducer,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react'
import { useUniversalReducer } from './use-universal-reducer'

type CurrentMediaDevicesType = State & {
  dispatch: React.Dispatch<Action>
  currentMediaDevicesState: State
}

export type MediaDeviceType = {
  id: string
  name: string
}
export type SafeDisplay = {
  bounds: import('electron').Rectangle
  id: number
  internal: boolean
  scaleFactor: number
}
export type SafeSource = {
  display_id: string
  id: string
  name: string
}

type State = {
  preferredMic: MediaDeviceType | undefined
  currentMic: MediaDeviceType | undefined
  isMicMuted: boolean

  preferredSpeaker: MediaDeviceType | undefined
  currentSpeaker: MediaDeviceType | undefined

  systemAudioDevice: MediaDeviceType | undefined

  preferredCamera: MediaDeviceType | undefined
  currentCamera: MediaDeviceType | undefined

  preferredDisplay: SafeDisplay | undefined
  preferredSource: SafeSource | undefined

  isInternalRecommended?: boolean
  forceSpeaker?: boolean
}

export type CurrentMediaDevicesState = State

export type Action =
  | { type: 'load state'; state: State }
  | { type: 'preferred mic changed'; micId: string; name: string }
  | { type: 'update current mic'; micId: string; name: string }
  | { type: 'microphone state changed'; isMuted: boolean }
  | { type: 'preferred speaker changed'; speakerId: string; name: string }
  | { type: 'update current speaker'; speakerId: string; name: string }
  | { type: 'preferred camera changed'; deviceId: string; name: string }
  | { type: 'current camera changed'; deviceId: string; name: string }
  | { type: 'system audio device changed'; deviceId?: string; name?: string }
  | {
      type: 'preferred display changed'
      display: SafeDisplay | undefined
    }
  | {
      type: 'preferred source changed'
      source: SafeSource | undefined
    }
  | {
      type: 'internal recommended state changed'
      state: boolean
    }
  | {
      type: 'forceSpeaker changed'
      state: boolean
    }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'load state':
      return action.state

    case 'preferred mic changed':
      return {
        ...state,
        preferredMic: { id: action.micId, name: action.name },
        currentMic: { id: action.micId, name: action.name },
      }

    case 'update current mic':
      return { ...state, currentMic: { id: action.micId, name: action.name } }

    case 'microphone state changed':
      return { ...state, isMicMuted: action.isMuted }

    case 'preferred speaker changed':
      return {
        ...state,
        preferredSpeaker: { id: action.speakerId, name: action.name },
        currentSpeaker: { id: action.speakerId, name: action.name },
      }

    case 'update current speaker':
      return {
        ...state,
        currentSpeaker: { id: action.speakerId, name: action.name },
      }

    case 'system audio device changed':
      return {
        ...state,
        systemAudioDevice: action.deviceId
          ? { id: action.deviceId, name: action.name || '' }
          : undefined,
      }

    case 'preferred camera changed':
      return {
        ...state,
        preferredCamera: { id: action.deviceId, name: action.name },
        currentCamera: { id: action.deviceId, name: action.name },
      }
    case 'current camera changed':
      return {
        ...state,
        currentCamera: { id: action.deviceId, name: action.name },
      }

    case 'preferred display changed':
      return {
        ...state,
        preferredDisplay: action.display,
      }

    case 'preferred source changed':
      return {
        ...state,
        preferredSource: action.source,
      }

    case 'internal recommended state changed':
      return {
        ...state,
        isInternalRecommended: action.state,
      }

    case 'forceSpeaker changed':
      return {
        ...state,
        forceSpeaker: action.state,
      }

    // if unknown action, don't clear the state
    default:
      return state
  }
}

const initialState = {
  preferredMic: undefined,
  currentMic: undefined,
  isMicMuted: false,

  preferredSpeaker: undefined,
  currentSpeaker: undefined,

  preferredCamera: undefined,
  currentCamera: undefined,

  preferredSource: undefined,
  preferredDisplay: undefined,

  systemAudioDevice: undefined,

  isInternalRecommended: undefined,
  forceSpeaker: false,
}

const initialContext = {
  ...initialState,
  currentMediaDevicesState: initialState,
  dispatch: () => {},
}

export const useMediaDevicesState = (): [State, React.Dispatch<Action>] => {
  let [reducerTuple] = useUniversalReducer(useReducer(reducer, initialState), {
    key: 'media-devices-reducer',
  })

  return reducerTuple
}

export const CurrentMediaDevicesContext = createContext<
  CurrentMediaDevicesType
>(initialContext)
export const useCurrentMediaDevicesContext = () =>
  useContext<CurrentMediaDevicesType>(CurrentMediaDevicesContext)
export const CurrentMediaDevicesContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [currentMediaDevicesState, dispatch] = useMediaDevicesState()
  const [hydrated, setHydrated] = useState(false)

  // Load state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    const currentMediaDevicesJson = localStorage.getItem(
      'currentMediaDevicesState',
    )

    if (
      !currentMediaDevicesJson ||
      typeof currentMediaDevicesJson !== 'string' ||
      !currentMediaDevicesJson.startsWith('{')
    ) {
      setHydrated(true)
      return
    }

    // Hydrate
    dispatch({ type: 'load state', state: JSON.parse(currentMediaDevicesJson) })
    setHydrated(true)
  }, [dispatch])

  // Persist state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    // Don't replace our cache with empty state
    if (!hydrated) {
      return
    }

    localStorage.setItem(
      'currentMediaDevicesState',
      JSON.stringify(currentMediaDevicesState),
    )
  }, [hydrated, currentMediaDevicesState])

  const value = useMemo(
    () => ({
      ...currentMediaDevicesState,
      currentMediaDevicesState,
      dispatch,
    }),
    [currentMediaDevicesState, dispatch],
  )

  return (
    <CurrentMediaDevicesContext.Provider value={value}>
      {children}
    </CurrentMediaDevicesContext.Provider>
  )
}
