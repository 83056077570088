import { useFileUploadManager } from './useFileUploadManager'

export const WithUploadManager = ({
  children,
}: {
  children: React.ReactNode
}) => {
  useFileUploadManager()
  return <>{children}</>
}
