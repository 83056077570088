import { Picker } from '@there/components/emoji-picker/Picker'
import { NotificationBackgroundHook } from '@there/components/feed/NotificationBackgroundHook'
import { useOnWindowResize } from '@there/components/hooks/useOnWindowResize'
import { AddPeopleModal } from '@there/components/modal/AddPeopleModal'
import { EditRoomModal } from '@there/components/modal/EditRoomModal'
import { LeaveModal } from '@there/components/modal/LeaveModal'
import { PickSourceModal } from '@there/components/modal/PickSourceModal'
import { ViewPhotoModal } from '@there/components/photoViewer/ViewPhotoModal'
import { Settings } from '@there/components/settings/Settings'
import { DelayedRender } from '@there/components/shared/DelayedRender'
import { usePickerContext } from '@there/components/shared/PickerContext'
import { useUiContext } from '@there/components/shared/UiContext'
import { useWindowContext } from '@there/components/shared/WindowContext'
import { WithNewTopBar } from '@there/components/topbar/WithNewTopBar'
import { ContentView } from '@there/components/v2/ContentView'
import { Sidebar } from '@there/components/v2/Sidebar'
import { RtcAudio } from '@there/components/voice/RoomAudio'
import {
  electronVersion,
  ipc,
  isElectron,
} from '@there/desktop/utils/electron-api'
import { feedSize } from '@there/shared/desktop/windows-config'
import React, { memo, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { CustomBackground } from '../feed/CustomBackground'
import { useTheme } from '../feed/ThemeContext'
import { WebNotification } from '../main/WebNotification'
import { AlertModal } from '../modal/AlertModal'
import { ConfirmModal } from '../modal/ConfirmModal'
import { EditTopicModal } from '../modal/EditTopicModal'
import { LeaveSpaceModal } from '../modal/LeaveSpaceModal'
import { NewGroupModal } from '../modal/NewGroupModal'
import { NudgeLearningModal } from '../modal/NudgeLearningModal'
import { PreviewPhotoModal } from '../modal/PreviewPhotoModal'
import { RecommendedMicModal } from '../modal/RecommendedMicModal'
import { SetBackgroundModal } from '../modal/SetBackgroundModal'
import { SetFromTwitterModal } from '../modal/SetFromTwitterModal'
import { ToastModal } from '../modal/ToastModal'
import { SystemAudio } from '../voice/SystemAudio'
import { DownloadDesktopBanner } from './DownloadDesktopBanner'
import { WebCamera } from './WebCamera'
import { WithUploadManager } from './WithUploadManager'

export const v2Size = feedSize

export const RootView = memo(() => {
  useEffect(() => {
    if (electronVersion?.startsWith('1.')) {
      ipc?.invoke('there:resize-feed', feedSize, false)
      console.info('Resizing desktop app for v2 UI.')
    }
  }, [])

  return (
    <FeedWrapper>
      <>
        <WithUploadManager>
          <WithNewTopBar>
            <View style={styles.areasWrapper}>
              <Sidebar />
              <View style={styles.contentWrapper}>
                <ContentView />
              </View>
            </View>
          </WithNewTopBar>
        </WithUploadManager>

        <DelayedRender>
          {/* Modals */}
          <AddPeopleModal />
          <LeaveModal />
          <SetFromTwitterModal />
          <EditRoomModal />
          <RecommendedMicModal />
          <PickSourceModal />
          <NudgeLearningModal />
          <SetBackgroundModal />
          <LeaveSpaceModal />
          <NewGroupModal />
          <EditTopicModal />
          <ConfirmModal />
          <AlertModal />
          <ToastModal />
          <PreviewPhotoModal />
          <ViewPhotoModal />
        </DelayedRender>
      </>

      <NotificationBackgroundHook />
      {!isElectron ? <WebNotification /> : null}

      {/* Rooms backend */}
      <RtcAudio />
      <SystemAudio />
      <PickerContainer />

      <DelayedRender>
        <Settings />
      </DelayedRender>

      <CustomBackground />
    </FeedWrapper>
  )
})

const PickerContainer = () => {
  let pickerManager = usePickerContext()

  return (
    <DelayedRender>
      <Picker pickerManager={pickerManager} />
    </DelayedRender>
  )
}

export const FeedWrapper = ({ children }: { children: React.ReactNode }) => {
  const t = useTheme()
  let { isElectron } = useWindowContext()

  let [wrap, setWrap] = useState(false)

  useOnWindowResize((size) => {
    // setWrap
    // if (!isElectron && size.width > 1100 && size.height > 600) {
    //   setWrap(true)
    // } else {
    setWrap(false)
    // }
  })

  return (
    <View
      style={
        wrap
          ? {
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: t.colors.mainBg,
            }
          : {
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }
      }
    >
      <View
        style={{
          zIndex: 2,
          width: '100vw',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          //@ts-ignore
          pointerEvents: 'none',
        }}
      >
        <DownloadDesktopBanner />
        <WebCamera />
      </View>
      <View
        style={
          wrap
            ? {
                width: v2Size.width,
                minHeight: v2Size.height,
                backgroundColor: t.colors.background,
                borderWidth: 1,
                borderColor: t.colors.separatorLine,
                position: 'relative',
                marginVertical: 0,
                marginHorizontal: 'auto',

                shadowOffset: {
                  width: 0,
                  height: 0,
                },
                shadowColor: t.colors.quinaryLight,
                shadowRadius: 3,
                elevation: 2,

                height: v2Size.height,
                overflow: 'hidden',
                borderRadius: t.borderRadius.larger,

                display: 'flex',
                flexDirection: 'column',
              }
            : {
                width: '100vw',
                height: '100vh',
                backgroundColor: t.colors.background,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
              }
        }
      >
        {children}
      </View>
    </View>
  )
}

export const SideArea = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme()
  let { hasCustomBackground } = useUiContext()

  return (
    <View
      style={[
        {
          width: 230,
          backgroundColor: `rgba(255,255,255, 0.04)`,

          borderLeftWidth: 1,
          borderLeftColor: theme.colors.separatorLine,

          //@ts-ignore
          backdropFilter: `blur(5px)`,
        },
        hasCustomBackground && {
          backgroundColor: `rgba(255,255,255, 0.04)`,
        },
      ]}
    >
      {children}
    </View>
  )
}

export const styles = StyleSheet.create({
  areasWrapper: {
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  contentWrapper: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
})
