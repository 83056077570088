import React from 'react'

export const Portal = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div
      id="actionMenuPortal"
      className="ActionWrapper"
      style={{
        // Do not set width or height
        // It was breaking observer wrapper filling height
        // width: '100vw',
        // height: '100vh',
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  )
}
