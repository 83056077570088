import React, { useRef, useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Slider } from '../shared/Slider'
import { useVolumeContext } from '../shared/VolumeContext'
import { useTheme } from '@there/components/feed/ThemeContext'
import { useLatest } from '@there/components/shared/use-latest'

const handleWidth = 18

export const VolumeManager = () => {
  let theme = useTheme()
  const { volume, muted, dispatch } = useVolumeContext()

  return (
    <Slider
      onValueChange={(value) => {
        dispatch({ type: 'volume changed', volume: +value.toFixed(0) })
      }}
      value={muted ? 0 : volume}
      icon={<VolumeIcon color={theme.colors.popoverBg} />}
    />
  )
}

export function VolumeIcon(
  props: React.SVGProps<SVGSVGElement> & { color?: string },
) {
  return (
    <svg width={16} height={16} viewBox="0 0 20 20" {...props}>
      <path
        d="M13.596 13.457a.625.625 0 11-.61-1.091 2.707 2.707 0 00.018-4.722.625.625 0 01.619-1.086A3.957 3.957 0 0115.625 10c0 1.45-.786 2.762-2.029 3.457zm.395 3.117a.625.625 0 01-.542-1.126A6.041 6.041 0 0016.875 10a6.041 6.041 0 00-3.37-5.42.625.625 0 01.554-1.121A7.29 7.29 0 0118.125 10a7.291 7.291 0 01-4.134 6.574z"
        fill={props.color ? props.color : '#000'}
        opacity="0.6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.833 13.333l-2.827-.565a1.667 1.667 0 01-1.34-1.634V8.866c0-.794.561-1.478 1.34-1.634l2.827-.565 2.744-2.744A.833.833 0 0110 4.512v10.976a.833.833 0 01-1.423.59l-2.744-2.745z"
        fill={props.color ? props.color : '#000'}
        opacity="1"
      />
    </svg>
  )
}
