import { useCallback } from 'react'
import { useModals } from '../feed/ModalsContext'

export const useAlert = (props: {
  alertIcon?: React.ReactNode
  alertText: string
  alertDescription?: string
  submitLabel?: string
  dismissLabel?: string
}) => {
  let {
    alertText,
    submitLabel,
    alertDescription,
    alertIcon,
    dismissLabel,
  } = props
  let [modalsState, modalsDispatch] = useModals()

  let openModal = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      modalsDispatch({
        type: 'modal opened',
        modalName: 'alert',
        modalData: {
          alertIcon,
          alertText,
          alertDescription,
          dismissLabel,
          textAlign: 'normal',

          submitLabel,
          onSubmit: () => resolve(),
          onDismiss: () => reject(),
        },
      })
    })
  }, [
    alertDescription,
    alertIcon,
    alertText,
    dismissLabel,
    modalsDispatch,
    submitLabel,
  ])

  return { openModal }
}
