import { darken, lighten } from 'polished'
import { TouchableOpacity } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { Pressable } from '../shared/Pressable'
import { useHover } from '../shared/use-hover'

export const CrossButton = ({
  onPress = () => {},
}: {
  onPress?: () => void
}) => {
  let t = useTheme()
  return (
    <Pressable
      onPress={onPress}
      style={({ hovered, pressed }) => [
        {
          //@ts-ignore
          cursor: 'pointer',

          width: 14,
          height: 14,
          borderRadius: 12,
          backgroundColor: t.colors.circleButtonBackground,

          shadowColor: t.colors.quaternaryLight,
          shadowRadius: 1,
          elevation: 2,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        hovered && {
          backgroundColor: lighten(0.05, t.colors.circleButtonBackground),
        },
        pressed && {
          backgroundColor: darken(0.03, t.colors.circleButtonBackground),
        },
      ]}
    >
      <CloseIcon color={t.colors.secondaryText} width={6} height={6} />
    </Pressable>
  )
}
const CloseIcon = (props: {
  color?: string
  width?: number
  height?: number
}) => {
  return (
    <svg
      width={props.width ? props.width : 6}
      height={props.height ? props.height : 6}
      fill="none"
      viewBox="0 0 8 8"
    >
      <path
        d="M7 1L4 4M1 7l3-3m0 0L1 1m3 3l3 3"
        stroke={props.color ? props.color : '#AEAEAE'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
