import { View, Text } from 'react-native'
import {
  ChangeSpaceModalData,
  LeaveSpaceModalData,
  useModals,
} from '../feed/ModalsContext'
import { StandardButton } from '../main/StandardButton'
import { Space } from '../shared/Space'
import { ModalDescription } from './ModalDescription'
import { ModalTitle } from './ModalTitle'
import { ModalWrapper } from './ModalWrapper'

export const LeaveSpaceModal = () => {
  let [modalsState, modalsDispatch] = useModals()
  const onClose = () => {
    modalsDispatch({ type: 'modal closed', modalName: 'leaveSpace' })
  }
  let modalData = modalsState.modalsData['leaveSpace'] as LeaveSpaceModalData

  return (
    <ModalWrapper
      isOpen={modalsState.modals.includes('leaveSpace')}
      onClose={onClose}
    >
      <View
        style={{
          width: 215,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalTitle align="center">Leave Space?</ModalTitle>
        <Space vertical={5} />
        <ModalDescription align="center">
          Are you sure to leave{' '}
          <Text style={{ fontWeight: 'bold' }}>
            {modalData?.spaceName || 'this'}
          </Text>{' '}
          space?
        </ModalDescription>
        <Space vertical={10} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <StandardButton
            text="Stay"
            backgroundColor="buttonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={onClose}
          />
          <Space horizontal={10} />
          <StandardButton
            text="Leave"
            backgroundColor="primaryButtonBackground"
            textColor="secondaryText"
            size="normal"
            onPress={() => {
              if (modalData) {
                modalData.onSubmit()
                onClose()
              }
            }}
          />
        </View>
      </View>
    </ModalWrapper>
  )
}
