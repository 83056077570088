/**
 * TODO:
 *  channelClosing?: boolean | undefined
 * channelClosingAt?: number | undefined
 */

import { EventEmitter } from '@there/components/shared/event-emitter'
import { IceServer } from '@there/tower/types'
import errorCode from 'err-code'
import ms from 'ms'
import { nanoid } from 'nanoid/non-secure'

const debug = require('debug')('desktop:rtc')

declare global {
  interface RTCPeerConnection extends EventTarget {
    // Patch, until TS fixes it: https://github.com/microsoft/TypeScript-DOM-lib-generator/pull/966
    setLocalDescription(description?: RTCSessionDescriptionInit): Promise<void>
  }
}

type SignalData = RTCSessionDescriptionInit | RTCIceCandidateInit
export type RtcSignalData = { signal: SignalData; peerId: string }
type RemoteSignalData = RtcSignalData
type SdpTransform = (
  sdp: RTCSessionDescriptionInit,
) => RTCSessionDescriptionInit
interface Input<TrackNames extends string> {
  polite: boolean
  /** Advised to have impolite peer be the offerer */
  initialOfferer: boolean
  mediaRequired: boolean
  iceServers?: IceServer[]
  sdpTransform?: SdpTransform
  tracks: TracksConfig<TrackNames>
  logger?: (message: string, ...args: any[]) => void

  preferredAudioCodec?: AudioCodecNames
  preferredVideoCodec?: VideoCodecNames
  iceTransportPolicy?: RTCIceTransportPolicy
}

/** mid to track name  */
type MidMap = Record<string, string>
type Jsonable = Record<string, string | number | boolean | unknown>
type InternalDataMessage =
  | {
      __type: 'signal'
      payload: { signal: SignalData; peerId: string }
    }
  | { __type: 'close' }
  | { __type: 'midSync'; tracksMid: MidMap }

type ErrorCodes =
  | 'ERR_WEBRTC_SUPPORT'
  | 'ERR_CREATE_OFFER'
  | 'ERR_CREATE_ANSWER'
  | 'ERR_SET_LOCAL_DESCRIPTION'
  | 'ERR_SET_REMOTE_DESCRIPTION'
  | 'ERR_ADD_ICE_CANDIDATE'
  | 'ERR_ICE_CONNECTION_FAILURE'
  | 'ERR_ICE_RESTART_FAILURE'
  | 'ERR_SIGNALING'
  | 'ERR_DATA_CHANNEL'
  | 'ERR_CONNECTION_FAILURE'

export type RtcConnectionStateType = 'connected' | 'connecting' | 'closed'
// counted after the "connected" event
const NO_MEDIA_TIMEOUT = ms('7s') // 6s was low
// counted after the "connected" event
const NO_DATA_CHANNEL_TIMEOUT = ms('7s') // 3s, 6s was low
const OFFER_TIMEOUT_MIN = ms('5s') // 4s was low
const ICERESTART_TIMEOUT = ms('5s')
// const ICERESTART_TIMEOUT = ms('8s')
const CHANNEL_CLOSING_TIMEOUT = ms('5s')
const NO_ANSWER_TIMEOUT = ms('10s')
const CONNECTION_TIMEOUT = ms('16s')
const MAX_BUFFERED_AMOUNT = 64 * 1024

type AudioCodecNames = 'audio/red' | 'audio/opus'
type VideoCodecNames = 'video/VP9' | 'video/H264' | 'video/AV1' | 'video/VP8'

export type RemoteTracksMap = Map<string, MediaStreamTrack>

interface TrackConfig {
  kind: 'audio' | 'video'
  track: MediaStreamTrack | null
  /** Tracks that have identical stream keys will be attached to a common stream */
  streamKey: string
  warmUp?: boolean
}
type TracksConfig<TrackName extends string> = Map<TrackName, TrackConfig>
export type RtcTracksConfig<TrackName extends string> = TracksConfig<TrackName>

export class RtcPeer<TrackName extends string> extends EventEmitter {
  input: Input<TrackName>

  peer: RTCPeerConnection | undefined
  dataChannel?: RTCDataChannel
  sdpTransform: SdpTransform
  /** Generated by the offerer and used to filter out non-offer signals related to an old peer */
  currentPairId: number | undefined
  id: string

  // flags
  polite: boolean
  initialOfferer: boolean
  mediaRequired: boolean
  makingOffer?: boolean
  completelyDestroyed: boolean
  destroyed: boolean
  destroying: boolean
  connectionReady: boolean
  channelReady: boolean
  flushingIceQueue: boolean
  restartingIce: boolean
  needsIceRestart: boolean
  state: RtcConnectionStateType
  settingRemoteAnswer: boolean
  ignoredOffer: boolean
  negotiationCount: number

  //. Internal
  remoteTracks: Set<MediaStreamTrack>
  localTracks: TracksConfig<TrackName>
  senders: Map<TrackName, RTCRtpSender>
  tracksMid: MidMap
  remoteTracksMid: MidMap
  internalStreams: Map<string, MediaStream>
  timerId: number | undefined
  iceCandidatesQueue: Array<RTCIceCandidateInit>
  preferredAudioCodec?: AudioCodecNames
  preferredVideoCodec?: VideoCodecNames

  addListener(event: 'track', cb: (event: RTCTrackEvent) => void): void
  addListener(
    event: 'tracks',
    cb: (remoteTracksMap: RemoteTracksMap) => void,
  ): void
  addListener(event: 'signal', cb: (signalData: RtcSignalData) => void): void
  addListener(
    event: 'connectionStateChange',
    cb: (connectionState: RtcConnectionStateType) => void,
  ): void
  addListener(event: 'error', cb: (error: Error) => void): void
  addListener(event: 'data', cb: (data: Record<string, any>) => void): void
  addListener(event: 'dataOpen', cb: () => void): void
  /** Emitted when we have a new peer internally created */
  addListener(event: 'peerInitialize', cb: () => void): void
  addListener(event: 'peerDestroy', cb: (error?: Error) => void): void
  addListener(event: string, cb: (...args: any) => void) {
    super.addListener(event, cb)
  }

  get impolite() {
    return !this.polite
  }

  static defaultIceServers = [
    {
      urls: ['stun:stun.l.google.com:19302'],
    },
  ]

  constructor(input: Input<TrackName>) {
    super()

    // Setup input
    this.input = input
    this.sdpTransform = (sdp) => sdp
    this.polite = input.polite
    this.state = 'connecting'
    this.preferredAudioCodec = input.preferredAudioCodec
    this.preferredVideoCodec = input.preferredVideoCodec

    // Initialize flags
    this.initialOfferer = input.initialOfferer
    this.mediaRequired = input.mediaRequired
    this.completelyDestroyed = false
    this.destroyed = false
    this.destroying = false
    this.connectionReady = false
    this.channelReady = false
    this.restartingIce = false
    this.needsIceRestart = false
    this.currentPairId = undefined
    this.settingRemoteAnswer = false
    this.flushingIceQueue = false
    this.ignoredOffer = false
    this.negotiationCount = 0

    this.id = nanoid(6)
    this.timerId = undefined
    this.remoteTracks = new Set()
    this.localTracks = input.tracks
    this.senders = new Map()
    this.tracksMid = {}
    this.remoteTracksMid = {}
    this.internalStreams = new Map()
    this.iceCandidatesQueue = []

    // Bind fuckers
    this.handleNegotiationNeeded = this.handleNegotiationNeeded.bind(this)
    this.handleConnectionStateChange = this.handleConnectionStateChange.bind(
      this,
    )
    this.handleIceCandidate = this.handleIceCandidate.bind(this)
    this.handleTrack = this.handleTrack.bind(this)
    this.handleIceConnectionStateChange = this.handleIceConnectionStateChange.bind(
      this,
    )
    this.handleIceGatheringStateChange = this.handleIceGatheringStateChange.bind(
      this,
    )
    this.handleNegotiationNeeded = this.handleNegotiationNeeded.bind(this)
    this.handleSignalingStateChange = this.handleSignalingStateChange.bind(this)
    this.handleDataChannel = this.handleDataChannel.bind(this)
    this.setupDataChannel = this.setupDataChannel.bind(this)

    // Initialize peer connection
    this.startPeerConnection()
  }

  private startPeerConnection() {
    if (this.completelyDestroyed) return

    this.debug(
      `starting new RTCPeerConnection (polite=${this.polite}, initialOfferer=${this.initialOfferer})`,
    )

    // To be safe - experimental - added after suspicious of remote streams not clearing up
    this.closePeer()

    // To be safe that no other peer will be created as an artifact from the past
    this.stopTimers()

    this.destroyed = false
    this.setConnectionState('connecting')

    try {
      this.peer = new RTCPeerConnection({
        iceServers: this.input.iceServers || RtcPeer.defaultIceServers,
        // @ts-ignore
        sdpSemantics: 'unified-plan',
        iceTransportPolicy: this.input.iceTransportPolicy,
      })

      this.emit('peerInitialize')

      // debug
      if (this.input.iceTransportPolicy) {
        this.debug('using iceTransportPolicy=', this.input.iceTransportPolicy)
      }
    } catch (error) {
      this.destroy(errorCode(error, 'ERR_PC_CONSTRUCTOR'))
    }

    if (!this.peer) {
      this.destroy(errorCode(new Error('Peer undefined'), 'ERR_PC_CONSTRUCTOR'))
      return
    }

    // Attach event handlers
    this.peer?.addEventListener(
      'connectionstatechange',
      this.handleConnectionStateChange,
    )
    this.peer?.addEventListener('icecandidate', this.handleIceCandidate)
    this.peer?.addEventListener('track', this.handleTrack)
    this.peer?.addEventListener(
      'iceconnectionstatechange',
      this.handleIceConnectionStateChange,
    )
    this.peer?.addEventListener(
      'icegatheringstatechange',
      this.handleIceGatheringStateChange,
    )
    this.peer?.addEventListener('datachannel', this.handleDataChannel)
    this.peer?.addEventListener(
      'negotiationneeded',
      this.handleNegotiationNeeded,
    )
    this.peer?.addEventListener(
      'signalingstatechange',
      this.handleSignalingStateChange,
    )

    // add transceivers on impolite side
    if (this.initialOfferer) {
      // Data channel (non-negotiated as negotiated was flaky)
      this.dataChannel = this.peer.createDataChannel('main', {
        id: 313,
        negotiated: true,
      })
      this.setupDataChannel(this.dataChannel)
    }

    // Flush pending signals batch
    let hadRemoteOffer = this.flushPendingSignals()

    // Only send offer if didn't have offer
    if (!hadRemoteOffer) {
      if (this.initialOfferer) {
        // Add before offer
        // DEBUG
        setTimeout(() => {
          this.addInitialTracks()

          this.debug('sending initial offer (initialOfferer)')
          this.sendOffer()
        }, 300)
      } else {
        // Temporarily disable Perfect Negotiation
        // Temporarily disable Perfect Negotiation
        // Temporarily disable Perfect Negotiation
        // This makes it fail in Chrome 100: https://bugs.chromium.org/p/chromium/issues/detail?id=1315611
        // @experimental Some timeout
        // the delay doesn't work
        // this.sendOffer()
      }
    }
  }

  private handleConnectionStateChange() {
    if (this.destroyed) return
    if (!this.peer) return

    let connectionState = this.peer.connectionState
    if (connectionState === 'failed') {
      this.debug('connectionState=', 'failed')

      // DEBUG FOR ICE RESTART
      this.failed(
        RtcPeer.errorCode(
          new Error('Connection failed.'),
          'ERR_CONNECTION_FAILURE',
        ),
      )
    }

    if (connectionState === 'closed') {
      this.debug('connectionState= closed')
      // Simple peer doesn't close
      // this.destroy()
      // this.stopIceRestartTimeout()
    }

    if (connectionState === 'connected') {
      // handle connect
      this.connectionReady = true
      this.setConnectionState('connected')
    }
  }

  private async sendOffer() {
    if (this.makingOffer) {
      this.debug('Called sendOffer when we were making offer')
      return
    }

    // Moved to connection state change as it might have killed valid connections
    // if (!this.connectionTimeoutId && this.state !== 'connected') {
    //   this.startConnectionTimeout()
    // }

    this.makingOffer = true
    try {
      let offer = await this.peer?.createOffer()

      if (!offer) return

      let changedOffer = this.sdpTransform(offer)

      // Taken from: https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/
      if (this.peer?.signalingState !== 'stable') {
        this.debug('signalingState not stable: drop offer')
        return
      }

      await this.peer
        ?.setLocalDescription(changedOffer)
        .catch(this.catchError('ERR_SET_LOCAL_DESCRIPTION'))

      let offerToSend = this.peer?.localDescription || changedOffer

      // Send initial offer!
      this.sendSignal({ signal: offerToSend })
    } catch (error) {
      this.failed(RtcPeer.errorCode(error, 'ERR_CREATE_OFFER'))
    } finally {
      this.makingOffer = false
    }
  }

  private handleIceCandidate(event: RTCPeerConnectionIceEvent) {
    if (this.destroyed) return

    if (event.candidate) {
      this.sendSignal({ signal: event.candidate })
    }
  }

  /**
   * Called on both statechange and gatheringstatechange
   **/
  private handleIceStateChange() {
    if (this.destroyed) return
    if (!this.peer) return

    const iceConnectionState = this.peer.iceConnectionState
    const iceGatheringState = this.peer.iceGatheringState

    this.debug(
      'iceStateChange (connection: %s) (gathering: %s)',
      iceConnectionState,
      iceGatheringState,
    )

    switch (iceConnectionState) {
      case 'connected':
      case 'completed': {
        this.connectionReady = true
        this.restartingIce = false
        this.needsIceRestart = false
        this.stopIceRestartTimeout()
        this.setConnectionState('connected')

        break
      }

      case 'disconnected':
        // give it a few seconds if not back, do ice restart
        this.countdownToIceRestart()
        break

      case 'failed': {
        if (this.restartingIce && iceGatheringState === 'complete') {
          this.debug('Ice restart failed.')

          this.failed(
            RtcPeer.errorCode(
              new Error('Ice restart failed.'),
              'ERR_ICE_RESTART_FAILURE',
            ),
            // already waited.
            { backOff: false },
          )
        }

        break
      }
      case 'closed': {
        this.failed(
          RtcPeer.errorCode(
            new Error('Ice connection closed.'),
            'ERR_ICE_CONNECTION_FAILURE',
          ),
        )

        break
      }
      // No default
    }
  }

  iceRestartCountdown = 0
  private countdownToIceRestart() {
    this.debug('starting 1s countdown to ice restart')
    if (this.iceRestartCountdown) clearTimeout(this.iceRestartCountdown)
    this.iceRestartCountdown = setTimeout(
      () => {
        //
        // Check if we're connected, skip
        if (
          this.peer?.signalingState === 'stable' &&
          this.peer.iceConnectionState === 'connected'
        ) {
          debug('ice restart not needed, connected without issue :thumbsup:')
          return
        }

        //
        // Otherwise...

        this.needsIceRestart = true
        // Immediately restart for now (until we await connection)
        this.restartIce()
      },
      // Time to wait if connection came back without doing anything
      1000,
    )
  }

  private handleIceConnectionStateChange() {
    this.handleIceStateChange()
  }

  private handleIceGatheringStateChange() {
    this.handleIceStateChange()
  }

  private handleTrack(event: RTCTrackEvent) {
    this.debug('[handleTrack]', event.track)
    if (this.destroyed) {
      return
    }

    //..
    //.

    //.
    if (event.track) {
      this.remoteTracks.add(event.track)
    }

    const unmute = () => {
      this.stopNoMediaTimeout()
      event.track.removeEventListener('unmute', unmute)
    }
    event.track.addEventListener('unmute', unmute)

    this.emit('track', event)
  }

  /** Called on each change and reconnect */
  private async syncTracksMid() {
    if (!this.channelReady) {
      // Wait until channel ready then send
      await new Promise<void>((resolve) => {
        const ready = () => {
          if (this.channelReady) {
            this?.removeListener('dataOpen', ready)
            resolve()
          }
        }
        this?.addListener('dataOpen', ready)
      })
    }

    // Sync track mid map with remote peer
    this.sendJson({ __type: 'midSync', tracksMid: this.tracksMid })
    // FIXME: will it go through on reconnect?
  }

  private async setTrackMid(
    trackName: TrackName,
    sender: RTCRtpSender,
    /** used for warm up bc that still doesn't have track */
    inputTransceiver?: RTCRtpTransceiver,
    { replaceTrack }: { replaceTrack?: boolean } = {},
  ) {
    if (this.destroyed || !this.peer) return

    // on replaceTrack = no need to wait
    // on addTrack = wait for stable
    // NEVER DO THIS
    // if (this.peer?.signalingState !== 'stable') {
    // await until stable again (so we have mid)
    if (!replaceTrack) {
      await new Promise<void>((resolve) => {
        const stable = () => {
          if (this.peer?.signalingState === 'stable') {
            this.peer?.removeEventListener('signalingstatechange', stable)
            resolve()
          }
        }
        this.peer?.addEventListener('signalingstatechange', stable)
      })
    }

    let transceiver =
      inputTransceiver ||
      this.peer.getTransceivers().find((t) => {
        // if (!t.sender.track || !sender.track) return false
        // sender comparison didn't work in browser
        // if (t.sender.track.id === sender.track.id) {
        if (t.sender === sender) {
          return true
        }
        return false
      })

    if (!transceiver) return

    let mid = transceiver.mid

    if (typeof mid === 'string') {
      // continue
    } else {
      // polling.
      await new Promise<void>((resolve, reject) => {
        let start = performance.now()
        let pollingId = setInterval(() => {
          // timeout
          if (performance.now() - start > 8000) reject()
          // poll for 8s
          if (typeof transceiver?.mid === 'string') {
            clearInterval(pollingId)
            resolve()
          }
        }, 100)
      })
    }

    mid = transceiver.mid

    if (typeof mid === 'string') {
      this.tracksMid[mid] = trackName
    }

    this.syncTracksMid()
  }

  addTrack(
    trackName: TrackName,
    track: MediaStreamTrack,
  ): RTCRtpSender | undefined {
    if (this.destroyed || !this.peer) return

    // FIXME: This is so add tracks before getting initial offer
    // from the other side to not trigger cross offer making the track muted
    // if (
    //   !this.initialOfferer //&&
    //   // this.peer.connectionState !== 'connected' &&
    //   // this.peer.signalingState !== 'have-remote-offer'
    // ) {
    //   debug(
    //     'add track was called before stable and before initial offer was made',
    //   )
    //   const reDo = () => {
    //     debug('re-adding track after connection connected')
    //     this.addTrack(trackName, track)
    //     this.removeListener('connectionStateChange', reDo)
    //   }
    //   this.addListener('connectionStateChange', reDo)
    //   return
    // }

    let config = this.localTracks.get(trackName)

    if (!config) {
      console.error(
        'addTrack called with a track name that has not been initialized to the peer constructor.',
      )
      return
    }

    if (this.senders.get(trackName)) {
      // Already added
      this.debug(
        '[warn] addTrack was called with a track that is already added',
      )
      return
    }

    if (!track || !(track instanceof MediaStreamTrack)) {
      console.error(
        'addTrack was called without a valid track',
        trackName,
        track,
      )
      return
    }

    let { streamKey, kind } = config

    // Fetch stream
    let stream = this.getStream(streamKey, track)

    console.info(
      'addTrack: track',
      track,
      track.muted,
      track.readyState,
      stream,
    )

    // Add on peer
    let sender = this.peer.addTrack(track, stream)

    setTimeout(() => {
      // find mid for correction event
      this.setTrackMid(trackName, sender)
    }, 0)

    // Set codec
    if (kind === 'video' && this.preferredVideoCodec) {
      this.setCodecPreferenceOnSender(sender, 'video', this.preferredVideoCodec)
    }

    if (kind === 'audio' && this.preferredAudioCodec) {
      this.setCodecPreferenceOnSender(sender, 'audio', this.preferredAudioCodec)
    }

    // Save sender
    this.senders.set(trackName, sender)

    // Update localTrack for future reconnects
    this.updateLocalTracks(trackName, track)

    return sender
  }

  private warmUpTransceiver(trackName: TrackName): RTCRtpSender | undefined {
    if (this.destroyed || !this.peer) return

    let config = this.localTracks.get(trackName)

    if (!config) {
      console.error(
        'warmUp called with a track name that has not been initialized to the peer constructor.',
      )
      return
    }

    if (this.senders.get(trackName)) {
      // Already added
      this.debug('[warn] warmUp was called with a track that is already added')
      return
    }

    let transceiver = this.peer.addTransceiver(config.kind, {
      direction: 'sendrecv',
    })

    let { sender } = transceiver

    this.debug('Warmed up transceiver, kind =', config.kind)

    // Save sender
    this.senders.set(trackName, sender)

    // Save for later
    setTimeout(() => {
      this.setTrackMid(trackName, sender, transceiver)
    }, 0)

    return sender
  }

  updateLocalTracks(trackName: TrackName, track: MediaStreamTrack | null) {
    let config = this.localTracks.get(trackName)

    if (!config) {
      this.failed(
        new Error('Track name was not initialized in the peer constructor.'),
      )
      return
    }

    this.localTracks.set(trackName, {
      ...config,
      track,
    })
  }

  private setCodecPreferenceOnSender(
    sender: RTCRtpSender,
    type: 'audio' | 'video',
    codec: VideoCodecNames | AudioCodecNames,
  ) {
    if (!this.peer) return

    try {
      let transceiver = this.peer
        .getTransceivers()
        .find((t) => t.sender && t.sender === sender)
      const { codecs } = RTCRtpSender.getCapabilities(type) || {
        codecs: null,
      }
      this.debug('supported codecs:', codecs)
      if (codecs) {
        const selectedCodecIndex = codecs.findIndex((c) => c.mimeType === codec)
        if (typeof selectedCodecIndex === 'number') {
          const selectedCodec = codecs[selectedCodecIndex]
          if (selectedCodec) {
            codecs.splice(selectedCodecIndex, 1)
            codecs.unshift(selectedCodec)
            this.debug(
              `Set ${type} codec preference to ${selectedCodec.mimeType}`,
            )
            transceiver?.setCodecPreferences(codecs)
          }
        }
      }
    } catch (error) {
      console.warn(`Could not set codec to ${codec}`, error)
    }
  }

  /** A very new method that handles adding, replacing, muting tracks */
  setTrack(trackName: TrackName, track: MediaStreamTrack) {
    let sender = this.senders.get(trackName)

    if (sender) {
      if (sender.track?.id === track.id) {
        // Skip
        // this.debug('[setTrack] skipped')
        return
      }

      this.replaceTrack(trackName, track)
    } else {
      sender = this.addTrack(trackName, track)
    }

    return sender
  }

  /** Call it before you want to transmit the track */
  warmUpTrack(trackName: TrackName) {
    this.warmUpTransceiver(trackName)
  }

  private getStream(streamKey: string, track: MediaStreamTrack) {
    let stream = this.internalStreams.get(streamKey)

    if (stream) {
      stream.addTrack(track)
    } else {
      stream = new MediaStream([track])
    }

    this.internalStreams.set(streamKey, stream)

    return stream
  }

  private cleanUpStreams() {
    // if (this.completelyDestroyed) return

    for (let [, stream] of this.internalStreams) {
      // Stop tracks
      for (let track of stream.getTracks()) track.stop()
    }
  }

  getSenders() {
    return this.peer?.getSenders()
  }

  replaceTrack(trackName: TrackName, newTrack: MediaStreamTrack | null) {
    if (this.destroyed || !this.peer) return

    let sender = this.senders.get(trackName)

    if (!sender) {
      console.error(`[replaceTrack] Track has not been sent before. Aborting.`)
      return
    }

    sender.replaceTrack(newTrack)

    // Update localTrack for future reconnects
    this.updateLocalTracks(trackName, newTrack)

    // Update
    this.setTrackMid(trackName, sender, undefined, { replaceTrack: true })

    this.debug('[replaceTrack] succeeded')
    return sender
  }

  // Let's
  // 1. store signals received when we had no peer (from the "offer")
  // 2. stop any current timeout on creating a new peer
  // 3. create a new peer and flush the list
  // 4. PROFIT
  pendingSignalsPeerId: string | undefined
  pendingSignals: RemoteSignalData[] | undefined
  queuePendingSignal(signal: RemoteSignalData) {
    if (signal.peerId === this.pendingSignalsPeerId) {
      // add to existing batch
      this.pendingSignals?.push(signal)
    } else if (
      'type' in signal.signal &&
      signal.signal.type === 'offer' &&
      signal.peerId
    ) {
      // Start new pending batch
      this.pendingSignals = [signal]
      this.pendingSignalsPeerId = signal.peerId
      if (this.makingOffer) return
      console.info('starting a new peer when got queued offer')
      // initiate a new peer
      this.startPeerConnection()
    }
  }
  /**
   *
   * @returns boolean - Had offer or not
   */
  flushPendingSignals() {
    // return false
    if (!this.peer) {
      this.debug('Called flush pending signals when no peer was initiated!')
      return false
    }
    if (!this.pendingSignals || this.pendingSignals.length === 0) {
      return false
    }
    this.debug('Flushing pending signals, count=', this.pendingSignals.length)

    for (let signal of this.pendingSignals) {
      this.setRemoteSignal(signal)
    }

    this.pendingSignals = []
    this.pendingSignalsPeerId = undefined

    return true
  }

  /**
   * Perfect negotiation
   */
  async setRemoteSignal(signalData: RemoteSignalData) {
    if (this.destroyed || this.destroying || !this.peer) {
      this.debug(
        `Got remote signal when destroyed or no peer (state=${this.state}) not queuing...`,
      )
      this.debug(`initialOfferer: ${this.initialOfferer}`)
      // has issue (probably double peer)
      // checking again
      this.queuePendingSignal(signalData)
      return
    }

    let peer = this.peer

    let type = 'type' in signalData.signal ? signalData.signal.type : undefined

    try {
      // sdp
      if ('sdp' in signalData.signal) {
        const isOffer = type === 'offer'
        const isAnswer = type === 'answer'

        let isStable =
          this.peer.signalingState === 'stable' ||
          (this.peer.signalingState === 'have-local-offer' &&
            this.settingRemoteAnswer)

        const ignoreOffer =
          isOffer && (this.makingOffer || !isStable) && this.impolite

        if (ignoreOffer) {
          this.debug('ignored offer')
          this.ignoredOffer = true
          return
        } else {
          this.ignoredOffer = false
        }

        this.settingRemoteAnswer = isAnswer

        if (isAnswer) {
          this.stopOfferTimeout()
        }

        this.debug(
          `remote signal, type=`,
          signalData.signal.type,
          signalData.signal,
        )

        try {
          await peer.setRemoteDescription(signalData.signal)
          this.settingRemoteAnswer = false
        } catch (error) {
          this.debug(`Failed signal:`, signalData.signal)
          this.debug(`initialOfferer: ${this.initialOfferer}`)
          this.debug(`signalingState: ${peer.signalingState}`)
          this.debug(`iceConnectionState: ${peer.iceConnectionState}`)
          this.debug(`connectionState: ${peer.connectionState}`)
          this.debug(`polite: ${this.polite}`)
          this.settingRemoteAnswer = false
          this.catchError('ERR_SET_REMOTE_DESCRIPTION')(error)
          return
        } finally {
        }

        // --------------------------
        if (isOffer) {
          // Add tracks before answer
          await this.addInitialTracks()

          // Flush ICE
          await this.flushIceCandidatesQueue()

          // @experimental
          // If not initial offerer's offer is being accepted, add a data channel here.
          if (
            // Used to be: trying to fix camera v2
            // this.initialOfferer &&
            !this.initialOfferer &&
            !this.dataChannel &&
            // Because offer comes up when you get video or screen
            !this.channelReady &&
            !this.connectionReady
          ) {
            console.info('Created data-channel from the answerer peer')
            this.debug('Created data-channel from the answerer peer')
            // this.peer.create
            this.dataChannel = peer.createDataChannel('main', {
              id: 313,
              negotiated: true,
            })
            this.setupDataChannel(this.dataChannel)
          }

          let answer = await peer.createAnswer()
          let changedAnswer = this.sdpTransform(answer)

          try {
            await peer.setLocalDescription(changedAnswer)
          } catch (error) {
            this.catchError('ERR_SET_LOCAL_DESCRIPTION')
            return
          }

          return this.sendSignal({
            signal: this.peer.localDescription || changedAnswer,
          })
        } else if (isAnswer) {
          this.debug('-- set remote answer, negotiated --')
        }
        //----------
      }

      //ice
      if ('candidate' in signalData.signal) {
        let readyForIce = this.peer.remoteDescription?.type

        if (!readyForIce || this.flushingIceQueue) {
          // Push to queue
          this.addToIceCandidatesQueue(signalData.signal)
        } else {
          // ensure queue is flushed
          await this.flushIceCandidatesQueue()

          // set
          try {
            await this.peer?.addIceCandidate(signalData.signal)
            this.debug('ice applied', signalData.signal.sdpMLineIndex)
          } catch (error) {
            if (this.ignoredOffer) {
              return
            }

            this.failed(RtcPeer.errorCode(error, 'ERR_ADD_ICE_CANDIDATE'))
          }
        }
      }
    } catch (error) {
      this.failed(RtcPeer.errorCode(error, 'ERR_SET_REMOTE_DESCRIPTION'))
    }
  }

  addToIceCandidatesQueue(candidate: RTCIceCandidateInit) {
    this.iceCandidatesQueue.push(candidate)
  }

  async flushIceCandidatesQueue() {
    this.flushingIceQueue = true
    for (let candidate of this.iceCandidatesQueue) {
      if (this.destroyed) break // because async

      // Not "await"ing because WebRTC queues internally & this way we'll reduce race condition surface area
      this.peer
        ?.addIceCandidate(candidate)
        .then(() => {
          this.debug('ice applied', candidate.sdpMLineIndex)
        })
        .catch((error) => {
          this.debug(
            '[ignored error] Error flushing candidate:',
            error,
            candidate,
          )
        })
    }

    this.iceCandidatesQueue = []
    this.flushingIceQueue = false
  }

  /** Called  after remote offer was set */
  private async addInitialTracks() {
    if (!this.peer || this.destroyed) return

    this.debug('adding initial tracks', this.localTracks)
    for (let [trackName, config] of this.localTracks) {
      if (config.track) {
        // Add track
        this.addTrack(trackName, config.track)
      } else if (config.warmUp !== false) {
        this.warmUpTransceiver(trackName)
      }
    }
  }

  setupDataChannel(dataChannel: RTCDataChannel | undefined) {
    if (this.destroyed) return

    if (!dataChannel) {
      // In some situations `pc.createDataChannel()` returns `undefined` (in wrtc),
      // which is invalid behavior. Handle it gracefully.
      // See: https://github.com/feross/simple-peer/issues/163
      return this.failed(
        RtcPeer.errorCode(
          new Error('Data channel event is missing `channel` property'),
          'ERR_DATA_CHANNEL',
        ),
      )
    }

    // set data channel
    this.dataChannel = dataChannel

    dataChannel.binaryType = 'arraybuffer'

    this.debug('setting up data')

    if (typeof dataChannel.bufferedAmountLowThreshold === 'number') {
      dataChannel.bufferedAmountLowThreshold = MAX_BUFFERED_AMOUNT
    }

    // eslint-disable-next-line unicorn/prefer-add-event-listener
    dataChannel.onmessage = (event) => {
      this.handleChannelMessage(event)
    }

    dataChannel.onbufferedamountlow = () => {
      this.debug('buffered amount low (@mo: ping)')
    }

    // eslint-disable-next-line unicorn/prefer-add-event-listener
    dataChannel.onopen = () => {
      this.onChannelStatusChange()
    }
    dataChannel.onclose = () => {
      this.onChannelStatusChange()
    }
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    dataChannel.onerror = (
      event: Event & { error?: Error }, // workaround for TS 4.6.0
    ) => {
      if (this.destroyed || this.destroying) return
      let error = event.error || new Error('ERR_DATA_CHANNEL generic')
      try {
        error =
          event.error instanceof Error
            ? event.error
            : new Error(
                // @ts-ignore
                `Datachannel error: ${event.message} ${event.filename}:${event.lineno}:${event.colno}`,
              )
      } catch {}

      console.warn(
        'datachannel onerror',
        this.peer?.signalingState,
        this.peer?.connectionState,
      )

      this.failed(RtcPeer.errorCode(error, 'ERR_DATA_CHANNEL'))
    }
  }

  private handleDataChannel(event: RTCDataChannelEvent) {
    this.debug('got datachannel event')
    this.setupDataChannel(event.channel)
  }

  private handleInternalMessages(data: InternalDataMessage) {
    if (!('__type' in data)) return

    switch (data.__type) {
      case 'signal':
        this.setRemoteSignal(data.payload)
        return

      case 'midSync':
        this.remoteTracksMid = data.tracksMid
        this.updateRemoteTracks()
        break

      case 'close':
        this.destroy()
        return
    }
  }

  /**
   * Either we got a new track, or new mid map
   * client must replace their tracks with these new tracks
   */
  private updateRemoteTracks() {
    if (!this.peer) return

    let transceivers = this.peer.getTransceivers()

    this.debug('updating remote tracks', this.remoteTracksMid, transceivers)

    let remoteTracksMap = new Map()

    for (let transceiver of this.peer.getTransceivers()) {
      if (!transceiver.mid) continue
      let trackName = this.remoteTracksMid[transceiver.mid]
      let track = transceiver.receiver.track
      remoteTracksMap.set(trackName, track)
    }

    this.emit('tracks', remoteTracksMap)
  }

  // Channel
  private handleChannelMessage(event: MessageEvent<any>) {
    if (this.destroyed) return
    let data = event.data
    if (data instanceof ArrayBuffer) data = Buffer.from(data)

    let isJson = typeof data === 'string' && data[0] === '{'
    if (isJson) {
      let parsedData = JSON.parse(data)
      if ('__type' in parsedData) {
        this.handleInternalMessages(parsedData)
      } else {
        this.emit('data', parsedData)
      }
    } else {
      console.warn('Peer received non Json data')
    }
  }

  private onChannelStatusChange() {
    if (!this.dataChannel || this.destroyed || this.destroying) return
    let readyState = this.dataChannel?.readyState

    this.debug('channel status change', this.dataChannel?.readyState)

    if (readyState === 'open') {
      this.debug('on channel open')
      this.emit('dataOpen')
      this.channelReady = true
      this.connectionReady = true
      this.setConnectionState('connected')
      this.syncTracksMid()
      this.stopNoChannelTimeout()
    } else if (readyState === 'closing' || readyState === 'closed') {
      this.debug(`on channel ${readyState}`)
      this.channelReady = false

      this.failed(
        RtcPeer.errorCode(new Error('Channel closed'), 'ERR_DATA_CHANNEL'),
      )
    }
  }
  // End of channel

  private async handleNegotiationNeeded() {
    if (this.destroying || this.destroyed || !this.peer) return

    const currentNegotiationNo = ++this.negotiationCount

    if (this.peer.signalingState !== 'stable') {
      this.debug(
        '[warm] ignored negotiation needed that was called in signalingState=',
        this.peer.signalingState,
      )
      return
    }

    if (this.makingOffer) {
      return
    }

    try {
      this.makingOffer = true

      let createdOffer = await this.peer.createOffer()

      let offer = this.sdpTransform(createdOffer)

      if (this.peer.signalingState !== 'stable') {
        this.debug('[warn] stopping negotiation midway')
        return
      }

      if (currentNegotiationNo !== this.negotiationCount) {
        this.debug('[warn] closed old ONN (negotiation needed)')
        return
      }

      await this.peer.setLocalDescription(offer)

      // must check after set local dec
      if (this.peer.localDescription?.type === 'offer') {
        this.debug('negotiationneeded worked')
      } else {
        this.debug('[error] negotiationneeded raced with message')
        throw new Error('negotiationneeded raced')
      }

      this.sendSignal({
        signal: this.peer.localDescription || offer,
      })
    } catch (error) {
      this.debug('negotiation needed failed:', error)
      this.failed(error)
    } finally {
      this.makingOffer = false
    }
  }

  private handleSignalingStateChange() {
    if (!this.peer) return

    this.debug('signalingStateChange %s', this.peer.signalingState)

    switch (this.peer.signalingState) {
      case 'closed':
        this.stopOfferTimeout()
        break

      case 'stable':
        this.stopOfferTimeout()
        break
    }
  }

  sendJson(payload: Jsonable) {
    if (this.dataChannel?.readyState === 'open') {
      let raw = JSON.stringify(payload)
      this.dataChannel?.send(raw)
    }
  }

  private sendSignal({ signal }: { signal: SignalData }) {
    let payload = {
      signal,
      peerId: this.id,
    }

    if (
      'type' in signal &&
      signal.type === 'offer' &&
      // only do so on initialNegotiation
      this.state === 'connecting' &&
      !this.connectionReady &&
      !this.channelReady
    ) {
      this.startOfferTimeout()
    }

    let sendUsingPeer =
      this.connectionReady &&
      this.channelReady &&
      this.dataChannel?.readyState === 'open' &&
      // new, to not send using peer when restarting ice
      this.peer?.iceConnectionState === 'connected' &&
      !this.restartingIce

    if (sendUsingPeer) {
      this.sendJson({
        __type: 'signal',
        payload,
      } as InternalDataMessage)
    } else {
      this.emit('signal', payload)
    }
    this.debug(
      `sent signal (type: ${
        'type' in payload.signal ? payload.signal.type : 'ice'
      }, transport: ${
        sendUsingPeer ? 'peer' : 'ws'
      }, at: ${new Date().toISOString()},)`,
    )
  }

  async restartIce() {
    if (this.destroyed) return
    if (!this.peer) return
    if (this.restartingIce) {
      this.debug(`Skipped ice restart, already doing it.`)
      return
    }

    this.debug(`Restarting ICE`)

    this.restartingIce = true
    this.startIceRestartTimeout()

    if ('restartIce' in this.peer) {
      // @ts-ignore
      this.peer.restartIce()
    } else {
      this.debug('doing the ice restart hack')
      // HACK: support safari mobile
      let offer

      try {
        // Use the old way to restart ice
        // @ts-ignore: Because TS doesn't know about old browsers
        offer = await this.peer.createOffer({ iceRestart: true })
      } catch (error) {
        this.failed(RtcPeer.errorCode(error, 'ERR_CREATE_OFFER'))
        return
      }

      if (!offer) return

      try {
        // @ts-ignore: Because TS doesn't know about old browsers
        await this.peer.setLocalDescription(offer)
      } catch (error) {
        this.failed(RtcPeer.errorCode(error, 'ERR_SET_LOCAL_DESCRIPTION'))
        return
      }

      // Send initial offer!
      this.sendSignal({ signal: offer })
    }
  }

  private setConnectionState(state: RtcConnectionStateType) {
    if (state === 'connecting') {
      this.startConnectionTimeout()
    }

    if (this.state === state) {
      return
    }

    // Note: DO NOT MOVE
    // Even if we're connecting, and new connecting is emitted, restart connection timeout, so it must come before the if below
    // if (state === 'connecting' && !this.destroyed) {
    //   this.startConnectionTimeout()
    // }

    if (state === 'connected') {
      // reset attempt for new timers
      this.recreateAttempt = 1
      this.stopOfferTimeout()
      this.stopIceRestartTimeout()
      this.stopConnectionTimeout()

      // Moved back from signalingStateChange=stable by mo
      this.startNoMediaTimeout()
      this.startNoChannelTimeout()
    }

    if (state === 'closed') {
      this.stopOfferTimeout()
      this.stopConnectionTimeout()
      this.stopNoMediaTimeout()
      this.stopNoChannelTimeout()
    }

    this.state = state
    this.debug(`connectionStateChange=`, state)
    this.emit('connectionStateChange', state)
  }

  iceRestartTimeoutId: number | undefined
  /** Timeout for sent offer from the moment you send */
  private startIceRestartTimeout() {
    this.stopIceRestartTimeout()
    this.iceRestartTimeoutId = setTimeout(
      () => {
        this.failed(
          RtcPeer.errorCode(
            new Error('Ice restart failed.'),
            'ERR_ICE_RESTART_FAILURE',
          ),
          { backOff: false },
        )
      },
      ICERESTART_TIMEOUT +
        // expriment just so both don't happen at the same time
        (this.polite ? 1000 : 0),
    )
  }
  private stopIceRestartTimeout() {
    if (this.iceRestartTimeoutId) {
      clearTimeout(this.iceRestartTimeoutId)
      this.iceRestartTimeoutId = undefined
    }
  }

  noMediaTimeoutAt: number | undefined = undefined
  /** Timeout for receiving a media AFTER connected event */
  private startNoMediaTimeout() {
    if (
      this.remoteTracks.size > 0 &&
      //and some not muted
      Array.from(this.remoteTracks).some((track) => !track.muted)
    ) {
      // if we've got tracks, ignore
      this.debug('already got media (no media timeout)')
      return
    }

    // In silent rooms we don't need media
    if (!this.mediaRequired) return

    this.debug('no media timeout started counting', new Date().toISOString())
    this.stopNoMediaTimeout()
    this.noMediaTimeoutAt = setTimeout(() => {
      // added after disconnect happening after screen sharing
      if (
        this.remoteTracks.size > 0 &&
        //and some not muted
        Array.from(this.remoteTracks).some((track) => !track.muted)
      ) {
        // if we've got tracks, ignore
        this.debug('already got media (stop media timeout)')
        return
      }

      this.debug(
        'remote media timeout, remote tracks at time:',
        this.remoteTracks,
      )
      this.failed(new Error(`No media received within ${NO_MEDIA_TIMEOUT}ms.`))
    }, NO_MEDIA_TIMEOUT)
  }
  private stopNoMediaTimeout() {
    if (this.noMediaTimeoutAt) {
      clearTimeout(this.noMediaTimeoutAt)
      this.noMediaTimeoutAt = undefined
    }
  }

  noChannelTimeoutAt: number | undefined = undefined
  /** Timeout for receiving a media AFTER connected event */
  private startNoChannelTimeout() {
    this.debug('no channel timeout started counting', new Date().toISOString())
    this.stopNoChannelTimeout()
    this.noChannelTimeoutAt = setTimeout(() => {
      if (this.dataChannel?.readyState === 'open') {
        // if we've got it already, ignore
        return
      }

      this.failed(
        new Error(`No channel opened within ${NO_DATA_CHANNEL_TIMEOUT}ms.`),
      )
    }, NO_DATA_CHANNEL_TIMEOUT)
  }
  private stopNoChannelTimeout() {
    if (this.noChannelTimeoutAt) {
      clearTimeout(this.noChannelTimeoutAt)
      this.noChannelTimeoutAt = undefined
    }
  }

  /** Performance.now() time */
  offerTimeoutId: number | undefined
  /** Timeout for sent offer from the moment you send */
  private startOfferTimeout() {
    this.stopOfferTimeout()

    // Used to be this, debugging code: 442
    // if (!this.initialOfferer) {
    //   return
    // }
    // only timeout offers on the impolite peer
    if (this.polite) {
      return
    }

    let delay = Math.max(this.exponentialFactor, OFFER_TIMEOUT_MIN)
    this.offerTimeoutId = setTimeout(() => {
      this.failed(
        RtcPeer.errorCode(
          new Error(`Offer timeout (got no remote answer after ${delay}ms)`),
          'ERR_SIGNALING',
        ),
        { backOff: false },
      )
    }, delay)
  }
  private stopOfferTimeout() {
    if (this.offerTimeoutId) {
      clearTimeout(this.offerTimeoutId)
      this.offerTimeoutId = undefined
    }
  }

  connectionTimeoutId: number | undefined
  /** Timeout for sent offer from the moment you send */
  private startConnectionTimeout() {
    this.stopConnectionTimeout()
    if (this.destroyed) return
    this.debug('started connection timeout')

    let delay =
      CONNECTION_TIMEOUT +
      // on the receiving end, add some time so we don't timeout at the same time (@mo: ???)
      // (!this.initialOfferer ? ms('2s') : 0)
      (this.initialOfferer ? ms('2s') : 0) // was 2s
    // changed this by reversing the condition so initial offerer times out after the other guy

    let currentId = this.id
    this.connectionTimeoutId = setTimeout(() => {
      if (currentId !== this.id) return

      this.failed(
        RtcPeer.errorCode(
          new Error(`Connection timeout (Could not connect ${delay}ms)`),
          'ERR_CONNECTION_FAILURE',
        ),
        { backOff: false },
      )
    }, delay)
  }
  private stopConnectionTimeout() {
    if (this.connectionTimeoutId) {
      clearTimeout(this.connectionTimeoutId)
      this.connectionTimeoutId = undefined
    }
  }

  private stopTimers() {
    this.stopRecreating()
    this.stopIceRestartTimeout()
    this.stopOfferTimeout()
    this.stopNoMediaTimeout()
    this.stopConnectionTimeout()
    this.stopNoChannelTimeout()
  }

  static errorCode = (error: Error, code: ErrorCodes) => {
    return errorCode(error, code)
  }

  private catchError(code: ErrorCodes) {
    return (error: Error) => this.failed(RtcPeer.errorCode(error, code))
  }

  closePeer(error?: Error) {
    this.debug('destroying peer (error: %s)', error && (error.message || error))

    // stop immediately
    this.stopTimers()

    this.destroyed = true
    this.connectionReady = false
    this.channelReady = false
    this.flushingIceQueue = false
    this.makingOffer = false
    this.restartingIce = false
    this.needsIceRestart = false
    this.currentPairId = undefined

    if (this.peer) {
      try {
        this.peer.close()
        this.debug('closed peer')
      } catch (_) {}

      // From simple peer: allow events concurrent with destruction to be handled
      this.peer.removeEventListener(
        'connectionstatechange',
        this.handleConnectionStateChange,
      )
      this.peer.removeEventListener('icecandidate', this.handleIceCandidate)
      this.peer.removeEventListener('track', this.handleTrack)
      this.peer.removeEventListener(
        'iceconnectionstatechange',
        this.handleIceConnectionStateChange,
      )
      this.peer.removeEventListener(
        'icegatheringstatechange',
        this.handleIceGatheringStateChange,
      )
      this.peer.removeEventListener('datachannel', this.handleDataChannel)
      this.peer.removeEventListener(
        'negotiationneeded',
        this.handleNegotiationNeeded,
      )
      this.peer.removeEventListener(
        'signalingstatechange',
        this.handleSignalingStateChange,
      )
    }

    // moved bottom to prevent error of channel closed
    if (this.dataChannel) {
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      this.dataChannel.onmessage = null
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      this.dataChannel.onopen = null
      this.dataChannel.onclose = null
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      this.dataChannel.onerror = null

      // used to allow events concurrent with destruction to be handled
      try {
        this.dataChannel.close()
      } catch (_) {}
    }

    this.peer = undefined
    this.dataChannel = undefined

    // Remote tracks
    for (let track of this.remoteTracks) {
      track.stop()
    }

    this.ignoredOffer = false
    this.settingRemoteAnswer = false
    this.remoteTracks = new Set()
    this.internalStreams = new Map()
    this.senders = new Map()
    this.tracksMid = {}
    this.remoteTracksMid = {}
    this.iceCandidatesQueue = []
  }

  recreateTimerId?: number
  recreateAttempt?: number

  get exponentialFactor() {
    // increased to prevent quick new offers
    let minimum = 1200
    // let minimum = 400
    let maximum = 30_000
    let attempt = this.recreateAttempt || 1
    let random = Math.floor(Math.random() * (1500 - 300) + 300)
    /** Pick a number between 100 - 200 */
    // was 500
    let reasonableMultiplier = 400

    let delay = Math.min(
      Math.max(Math.pow(2, attempt) * reasonableMultiplier + random, minimum),
      maximum,
    )

    this.debug('delay=', delay)

    return delay
  }

  stopRecreating() {
    if (this.recreateTimerId) {
      clearTimeout(this.recreateTimerId)
      this.recreateTimerId = undefined
    }
  }

  recreatePeer() {
    if (this.completelyDestroyed) return

    this.recreateAttempt = Number(this.recreateAttempt) + 1
    this.debug(`recreating (attempt: ${this.recreateAttempt})`)
    this.closePeer()
    this.startPeerConnection()
  }

  // eslint-disable-next-line unicorn/no-object-as-default-parameter
  failed(error?: Error, options: { backOff: boolean } = { backOff: true }) {
    this.setConnectionState('closed')

    if (!this.recreateAttempt) {
      this.recreateAttempt = 1
    }

    if (error) {
      console.warn('rtc error:', error)
      this.emit('error', error)
    }

    if (this.recreateAttempt > 12) {
      this.debug('MAX ATTEMPT EXCEEDED')
      this.destroy(error, { errorEmitted: true })
      return
    }

    // Log
    this.debug(`[peer failed] Attempt: ${this.recreateAttempt || 0}`, error)

    this.stopRecreating()
    this.closePeer()

    if (options.backOff) {
      this.recreateTimerId = setTimeout(() => {
        if (this.completelyDestroyed) return

        this.recreateAttempt = Number(this.recreateAttempt) + 1
        this.startPeerConnection()
      }, this.exponentialFactor + 200)
    } else {
      this.recreateAttempt = Number(this.recreateAttempt) + 1
      this.startPeerConnection()
    }
  }

  destroy(error?: Error, options?: { errorEmitted?: boolean }) {
    this.destroying = true
    /** To prevent recreating anymore */
    this.completelyDestroyed = true

    this.setConnectionState('closed')
    this.stopRecreating()
    this.closePeer()
    // this.cleanUpStreams()

    if (error && !options?.errorEmitted) {
      console.error('RTCPeer destroyed:', error)
      this.emit('error', error)
    }
    this.emit('peerDestroy', error)
  }

  debug(arg: string, ...args: any[]) {
    debug(`${this.id} | ${arg}`, ...args)
    this.input.logger?.(`${this.id} | ${arg}`, ...args)
  }
}

function generatePairId() {
  return Math.floor(Date.now() / 1000 + Math.round(Math.random() * 1000))
}

// Peer DataChannel closing interval
// HACK: Chrome will sometimes get stuck in readyState "closing", let's check for this condition
// https://bugs.chromium.org/p/chromium/issues/detail?id=882743
// if (
//   participant.dataChannel &&
//   participant.dataChannel.readyState === 'closing'
// ) {
//   let channel = participant.dataChannel
//   let userId = participant.userId
//   if (channel && channel.readyState === 'closing') {
//     if (
//       participant.channelClosing &&
//       participant.channelClosingAt &&
//       participant.channelClosingAt + CHANNEL_CLOSING_TIMEOUT <
//         performanceNow
//     ) {
//       // timeout passed
//       dispatch({
//         type: 'peer disconnected',
//         userId,
//       })
//     } else {
//       dispatch({
//         type: 'peer channel closing changed',
//         userId,
//         closing: true,
//       })
//     }
//   } else {
//     dispatch({
//       type: 'peer channel closing changed',
//       userId,
//       closing: false,
//     })
//   }
// }
