import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

export function BellIcon(props: SvgProps) {
  return (
    <Svg width={16} height={16} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        d="M13.5 6a4.5 4.5 0 00-9 0c0 5.25-2.25 6.75-2.25 6.75h13.5S13.5 11.25 13.5 6zM10.297 15.75a1.5 1.5 0 01-2.595 0"
        stroke={props.color ? props.color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
