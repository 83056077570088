import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import ms from 'ms'

import { Draft } from 'immer'
import { useImmerReducer } from 'use-immer'
import { SubscriptionResult } from '../sun/utils/types'
import {
  GlobalTypingData,
  useGotGlobalTyping,
} from '../main/useGotGlobalTyping'

type GlobalTypingAction = {
  type: 'global typing state changed'
  userId: string
  typing: boolean
}

export type State = {
  userIdsTyping: Record<string, boolean>
}

const initialState: State = {
  userIdsTyping: {},
}

function reducer(
  draft: Draft<State>,
  action: GlobalTypingAction,
): void | State {
  switch (action.type) {
    case 'global typing state changed':
      draft.userIdsTyping[action.userId] = action.typing
      break
    default:
      return draft
  }
}

export interface GlobalTypingContextValue {
  state: State
  dispatch: React.Dispatch<GlobalTypingAction>
}

let batchNotificationTime = ms('2min')

export type GlobalTypingContextManager = GlobalTypingContextValue
export type GlobalTypingManager = GlobalTypingContextValue

let voidFunction = () => {}

const initialContext: GlobalTypingContextManager = {
  state: initialState,
  dispatch: voidFunction,
}

export const GlobalTypingContext = createContext<GlobalTypingContextManager>(
  initialContext,
)
export const useGlobalTypingContext = () => {
  return useContext(GlobalTypingContext)
}

/**
 * MANAGEMENT HOOK
 */

export const useGlobalTypingManager = (): GlobalTypingContextManager => {
  let [state, dispatch] = useImmerReducer<State, GlobalTypingAction>(
    reducer,
    initialState,
  )

  let onGotTyping = useCallback(
    (typingData: SubscriptionResult<GlobalTypingData>) => {
      if (!typingData) return
      let userTypingData = typingData.data
      if (!userTypingData) {
        console.warn('[GlobalTyping] typing data in undefined')
        return
      }
      if (!userTypingData.userId) {
        console.warn('[GlobalTyping] userId is undefined!')
        return
      }

      dispatch({
        type: 'global typing state changed',
        userId: userTypingData.userId,
        typing: userTypingData.typing,
      })
    },
    [dispatch],
  )
  useGotGlobalTyping({ onData: onGotTyping })

  const value: GlobalTypingContextManager = useMemo(() => {
    return {
      state,
      dispatch,
    }
  }, [dispatch, state])
  return value
}
