import { memo } from 'react'
import { useTheme } from '@there/components/feed/ThemeContext'
import { MicrophonePicker } from '@there/components/feed/MicrophonePicker'
import Svg, { SvgProps, Path, Circle } from 'react-native-svg'
import { GoodButton } from '@there/components/shared/GoodButton'
import { useIsCurrentlyMuted } from '@there/components/shared/use-media-devices'
import { DeafenIconMuted } from '@there/components/feed/HeaderIcons'

export const PickerButton = memo(() => {
  let theme = useTheme()
  let currentlyMuted = useIsCurrentlyMuted()

  return (
    <>
      <MicrophonePicker
        includeCameras={true}
        toggleComponent={({active}) =>
          <GoodButton
            variant="transparent"
            onPressHandled={true}
            active={active}
            icon={
              currentlyMuted ? (
                <DeafenIconMuted
                  color={theme.colors.tertiaryLight}
                  width={18}
                  height={18}
                />
              ) : (
                <ThreeDots color={theme.colors.tertiaryLight} />
              )
            }
          />
        }
      />
    </>
  )
})

function ThreeDots(props: SvgProps) {
  return (
    <Svg
      width={11}
      height={17}
      fill="none"
      transform="rotate(-90 0 0)"
      {...props}
    >
      <Circle
        cx={5.5}
        cy={13.5}
        r={1.5}
        transform="rotate(-180 5.5 13.5)"
        fill={props.color ? props.color : '#fff'}
      />
      <Circle
        cx={5.5}
        cy={8.5}
        r={1.5}
        transform="rotate(-180 5.5 8.5)"
        fill={props.color ? props.color : '#fff'}
      />
      <Circle
        cx={5.5}
        cy={3.5}
        r={1.5}
        transform="rotate(-180 5.5 3.5)"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}
