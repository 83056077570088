import { useEffect, useRef } from 'react'
import { View } from 'react-native'
import { GoodButton } from '@there/components/shared/GoodButton'
import { People } from '../emoji-picker/CategoryIcons'
import { usePickerContext } from '../shared/PickerContext'
import { useTheme } from '../feed/ThemeContext'
import { Emoji } from '../emoji-picker/use-picker'
import { useHover } from '../shared/use-hover'
import { GifMedia } from '../emoji-picker/use-tenor'

type Props = {
  onEmojiSelect: (emojiId: Emoji) => void
  onGifSelect: (gif: GifMedia) => void
}

export const ChatPickerButton = (props: Props) => {
  let theme = useTheme()
  let {
    open,
    selectedEmoji,
    clearEmoji,
    clearGif,
    mount: mountEmojiPicker,
    unMount: unMountEmojiPicker,
    setPickerToggleHovered,
    selectedGif,
  } = usePickerContext()
  let chatSelectedEmoji = selectedEmoji?.chat
  let chatSelectedGif = selectedGif?.chat
  let wrapperRef = useRef<View | null>(null)

  // to mount emoji picker after animation ended
  useEffect(() => {
    mountEmojiPicker()
    return () => unMountEmojiPicker()
  }, [mountEmojiPicker, unMountEmojiPicker])

  // chat emoji selected
  useEffect(() => {
    if (!chatSelectedEmoji) return
    props.onEmojiSelect(chatSelectedEmoji)
    clearEmoji('chat')
  }, [chatSelectedEmoji, clearEmoji, props])
  let onPress = () => {
    open({
      key: 'chat',
      ref: wrapperRef,
      isFullScreen: false,
    })
  }

  // chat gif selected
  useEffect(() => {
    if (!chatSelectedGif) return
    props.onGifSelect(chatSelectedGif)
    clearGif('chat')
  }, [chatSelectedGif, clearGif, props])

  let [hovered, hoverListener] = useHover()

  useEffect(() => {
    if (hovered) {
      open({
        key: 'chat',
        ref: wrapperRef,
        isFullScreen: false,
      })
    }
    setPickerToggleHovered(hovered)
  }, [hovered, open, setPickerToggleHovered])

  return (
    <View
      ref={wrapperRef}
      style={[
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        },
      ]}
      {...hoverListener}
    >
      <GoodButton
        onPress={onPress}
        icon={<People color={theme.colors.tertiaryText} />}
        variant="transparent"
      />
    </View>
  )
}
