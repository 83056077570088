import { memo } from 'react'
import { useLogOutListener } from './use-logout'
import { useClearCacheListener } from '@there/components/feed/BackgroundHooks/use-clear-cache'

export const BackgroundHooks = memo(() => {
  useLogOutListener()
  useClearCacheListener()

  return null
})
