import { View } from 'react-native'

import { NotificationDropdown } from '../dropdown/NotificationDropdown'
import { SettingsDropdown } from '../settings/SettingsDropdown'
import { memo } from 'react'

import { RefreshButton } from '../v2/RefreshButton'
import { NewGroupButton } from '../v2/NewGroupButton'
import { AccountManagerButton } from '../v2/AccountManagerButton'

export const SelfManager = memo(() => {
  return (
    <View
      style={{
        height: 41,
        borderTopWidth: 1,
        borderTopColor: 'rgba(255, 255, 255, 0.06)',

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        paddingLeft: 16,
        paddingRight: 8,
      }}
    >
      <AccountManagerButton />
      <NotificationDropdown v2={true} />
      <SettingsDropdown v2={true} />
      <NewGroupButton />
      <RefreshButton />
    </View>
  )
})

SelfManager.displayName = 'SelfManager'
