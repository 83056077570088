import { a, useTransition } from '@react-spring/native'
import { connectionStateAtom } from '@there/components/atoms'
import { SendMessageIcon } from '@there/components/chat/SendMessageIcon'
import { useKeyContext } from '@there/components/feed/KeyContext'
import { AutoHeightTextInput } from '@there/components/feedBox/AutoHeightTextInput'
import { useSendMessageAction } from '@there/components/main/useSendMessageAction'
import {
  ChatsStateDispatch,
  DraftMessage,
} from '@there/components/v2/useChatsState'
import { ChatPeerId } from '@there/components/v2/useRenderingPhase'
import { MessageActionType } from '@there/sun/modules/sendMessageActionUtils'
import { NewMessageInfo } from '@there/sun/utils/node-types'
import cuid from 'cuid'
import { useAtom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import ms from 'ms'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { StyleSheet, TextInput } from 'react-native'
import { activeNewChatPeerAtom } from '../atoms/chatAtom'
import { Emoji } from '../emoji-picker/use-picker'
import { GifMedia } from '../emoji-picker/use-tenor'
import { useTheme } from '../feed/ThemeContext'
import { Pressable } from '../shared/Pressable'
import { useUiContext } from '../shared/UiContext'
import { logEvent } from '../shared/use-analytics'
import { useMainWindowContext } from '../shared/use-main-window'
import { MessageTypeInput } from './ChatCompose'
import { ChatPickerButton } from './ChatPickerButton'
import { ChatUploadButton } from './ChatUploadButton'

const lineHeight = 16
const maxLineLimit = 7
const textInputPaddingVertical = 7
export const textInputMinHeight = 34

type Props = {
  sendChatMessage: ({}: MessageTypeInput) => void
  editingMessage: NewMessageInfo | undefined
  replyingMessageId: string | null
  setReplyMsgId: (msgId: string | null) => void
  setEditingMessageId: (msgId: string | null) => void
  editMessage: ({
    messageId,
    text,
  }: {
    messageId: string
    text: string
  }) => void
  peerUserId: string | null
  onArrowUp: () => void
  chatsStateDispatch: ChatsStateDispatch
  draft: DraftMessage | undefined
  peer: ChatPeerId
  isModalOpened: boolean
  updateRecentUsedEmojis: (emojis: string[]) => void
  isEmojiPickerOpen: boolean
  closeEmojiPicker: () => void
  addedMessage?: string | null
}

export const NewChatInput = memo(
  ({
    sendChatMessage,
    editingMessage,
    editMessage,
    peerUserId,
    peer,
    draft,
    replyingMessageId,
    setEditingMessageId,
    setReplyMsgId,
    onArrowUp,
    chatsStateDispatch,
    isModalOpened,
    updateRecentUsedEmojis,
    isEmojiPickerOpen,
    closeEmojiPicker,
    addedMessage,
  }: Props) => {
    let theme = useTheme()
    let { hasCustomBackground } = useUiContext()
    let textInputRef = useRef<null | TextInput>(null)
    let setActiveChatPeer = useUpdateAtom(activeNewChatPeerAtom)
    let { dispatch } = useMainWindowContext()

    let [message, setMessageLocal] = useState('')
    let [editingMessageValue, setEditingMessageValue] = useState('')
    let [recentlyUsedEmojis, setRecentlyUsedEmojis] = useState<string[]>([])

    const setMessage = useCallback(
      (message: string) => {
        setMessageLocal(message)
        // TODO: update with interval
        // Update on unmount
        chatsStateDispatch({ type: 'draft updated', peer, draft: { message } })
      },
      [chatsStateDispatch, peer],
    )

    let peerTopicId = peer?.type === 'Topic' ? peer.id : null
    let hasLoadedDraftPeer = useRef(peer)
    useEffect(() => {
      if (hasLoadedDraftPeer.current === peer) return
      setMessageLocal(draft?.message || '')
      hasLoadedDraftPeer.current = peer
    }, [draft, peer])

    // remote because it has bug and cloudnt solve it
    // const messageIsEmpty = message === ''
    // useEffect(() => {
    //   if (messageIsEmpty && addedMessage && addedMessage !== null) {
    //     setMessageLocal(addedMessage)
    //   }
    // }, [addedMessage, messageIsEmpty])

    useEffect(() => {
      if (!editingMessage) return
      if (!editingMessage.text) return
      setEditingMessageValue(editingMessage.text)
      // so it's focused after changing state
      setTimeout(() => {
        textInputRef.current?.focus()
      }, 10)
    }, [editingMessage])

    useEffect(() => {
      if (!replyingMessageId) return
      textInputRef.current?.focus()
    }, [replyingMessageId])

    // initial focus
    useEffect(() => {
      if (!peer) return
      textInputRef.current?.focus()
    }, [peer])

    const onSubmit = useCallback(() => {
      closeEmojiPicker()
      if (editingMessage && editingMessageValue.length > 0) {
        editMessage({ messageId: editingMessage.id, text: editingMessageValue })
        setEditingMessageValue('')
        return
      }
      if (message.length > 2560) {
        alert('Your message is too long!')
        return
      }
      if (message.length === 0) return
      if (message.replace(/\s/g, '').length === 0) return

      sendChatMessage({
        peerTopicId: peerTopicId,
        peerUserId: peerUserId,
        text: message,
      })
      setMessage('')
      logEvent('Chat Message Sent')
      updateRecentUsedEmojis(recentlyUsedEmojis)
      setRecentlyUsedEmojis([])
    }, [
      closeEmojiPicker,
      editMessage,
      editingMessage,
      editingMessageValue,
      message,
      peerTopicId,
      peerUserId,
      recentlyUsedEmojis,
      sendChatMessage,
      setMessage,
      updateRecentUsedEmojis,
    ])

    let [connectionState] = useAtom(connectionStateAtom)
    let [, sendMessageAction] = useSendMessageAction()
    // ref : currentActionType = 'cancel'
    let lastTypedAt = useRef(0)
    let lastAction = useRef<MessageActionType>(MessageActionType.Cancel)

    // Send typing status
    useEffect(() => {
      // Stop if network status is disconnected
      if (connectionState !== 'connected') return
      if (!peerUserId) return

      let time = performance.now()

      function sendAction(action: MessageActionType) {
        if (action === MessageActionType.Typing) {
          lastTypedAt.current = time
        }
        lastAction.current = action
        sendMessageAction({
          action: action,
          peerUserId: peerUserId,
          peerTopicId: null,
        })
      }

      if (message === '' && lastAction.current === MessageActionType.Typing) {
        // Cancel
        sendAction(MessageActionType.Cancel)
      } else if (message && lastTypedAt.current + ms('4s') < time) {
        // Typing
        // if it's typing  && last typing at is more than 5s send
        sendAction(MessageActionType.Typing)
      }
    }, [connectionState, message, peerUserId, sendMessageAction])

    useEffect(() => {
      // on Unmount send cancel
      return () => {
        if (!peerUserId) return
        sendMessageAction({
          action: MessageActionType.Cancel,
          peerUserId: peerUserId || null,
          peerTopicId: null,
        })
      }
    }, [peerUserId, sendMessageAction])

    let buttonTransition = useTransition(
      message.length > 0 || (editingMessage && editingMessageValue.length > 1),
      {
        from: {
          opacity: 0,
          scale: 0.5,
        },
        enter: {
          opacity: 1,
          scale: 1,
        },
        leave: {
          opacity: 0,
          scale: 0.5,
        },
        config: {
          tension: 500,
          friction: 15,
          mass: 0.1,
        },
      },
    )

    let textMessage = editingMessage ? editingMessageValue : message

    // Focus chat input on peer change and app focus
    useEffect(() => {
      if (peer) {
        textInputRef.current?.focus()
      }
    }, [peer, textInputRef])

    // On unhandled keydown
    let {
      registerUnhandledHandler,
      unregisterUnhandledHandler,
    } = useKeyContext()

    useEffect(() => {
      registerUnhandledHandler((event) => {
        // Do not eat hotkeys num num num
        if (!event.metaKey && !event.ctrlKey && !event.altKey) {
          textInputRef.current?.focus()
        }
      })
      return () => {
        unregisterUnhandledHandler()
      }
    }, [registerUnhandledHandler, unregisterUnhandledHandler])

    // unFocus chat input on any modal opened
    useEffect(() => {
      if (!textInputRef.current) return
      if (!isModalOpened) return

      textInputRef.current.blur()
    }, [isModalOpened])

    const onEmojiSelect = useCallback(
      (emoji: Emoji) => {
        let emojiUnicode = emoji.image
          .split('-')
          .map((u) => String.fromCodePoint(parseInt(u, 16)))
          .join('')
        editingMessage
          ? setEditingMessageValue(editingMessageValue + emojiUnicode)
          : setMessage(message + emojiUnicode)
        setRecentlyUsedEmojis((array) => [emoji.names[0], ...array])

        textInputRef.current?.focus()
      },
      [editingMessage, editingMessageValue, message, setMessage],
    )

    const onGifSelect = useCallback(
      (gif: GifMedia) => {
        sendChatMessage({
          peerTopicId: peerTopicId,
          peerUserId: peerUserId || null,
          text: draft?.message || '',
          document: {
            id: cuid(),
            compressed: false,
            fileExt: 'mp4',
            fileName: gif.name,
            type: 'Gif',
            objectId: null,
            size: gif.size,
            mediaW: gif.dims[0],
            mediaH: gif.dims[1],
            remoteUrl: gif.url,
            remotePreview: gif.preview,
          },
        })
        closeEmojiPicker()
      },
      [
        closeEmojiPicker,
        draft?.message,
        peerTopicId,
        peerUserId,
        sendChatMessage,
      ],
    )

    return (
      <a.View
        style={[
          {
            minWidth: 150,

            flexGrow: 1,
            position: 'relative',

            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'flex-end',
            alignItems: 'center',
            overflow: 'hidden',
            zIndex: 3,
          },
          hasCustomBackground && styles.blur,
        ]}
      >
        <AutoHeightTextInput
          ref={textInputRef}
          lineHeight={lineHeight}
          paddingVertical={16}
          minHeight={34}
          onKeyPress={(event) => {
            //@ts-ignore
            switch (event.code) {
              case 'Enter':
                //@ts-ignore
                if (event.shiftKey) return
                event.preventDefault()
                onSubmit()
                break
              case 'Escape':
                event.preventDefault()
                if (editingMessage || replyingMessageId) {
                  setReplyMsgId(null)
                  setEditingMessageId(null)
                  textInputRef.current?.blur()
                } else {
                  dispatch({ type: 'change mode', mode: 'room' })
                  setActiveChatPeer({})
                }
                break
              case 'ArrowUp':
                // If we've got something typed, don't block arrow up
                if (message || editingMessageValue) return
                event.preventDefault()
                onArrowUp()
                break
            }
          }}
          style={{
            //@ts-ignore
            outline: 'none',

            // If you change these update paddingVertical above
            paddingTop: 9,
            paddingVertical: 7,
            // If you change these update paddingVertical above

            paddingLeft: 14,

            // flexGrow: 1,
            // flex: 1,

            fontSize: theme.fontSizes.normal,
            color: theme.colors.text,

            textAlignVertical: 'center',
          }}
          placeholder="Send a message"
          placeholderTextColor={theme.colors.tertiaryText}
          autoFocus={true}
          value={editingMessage ? editingMessageValue : message}
          multiline={true}
          onChange={(event) => {
            editingMessage
              ? setEditingMessageValue(event.nativeEvent.text)
              : setMessage(event.nativeEvent.text)
          }}
          onSubmitEditing={onSubmit}
        />

        {buttonTransition(({ scale, opacity }, item) => {
          return item ? (
            <a.View
              style={{
                opacity,
                transform: [{ scale }],
              }}
            >
              <Pressable
                onPress={onSubmit}
                style={({ hovered, pressed }) => [
                  {
                    height: 30,
                    paddingRight: 8,
                    paddingLeft: 4,

                    display: 'flex',
                    justifyContent: 'center',
                  },
                  pressed && {
                    opacity: 0.8,
                    transform: [{ scale: 0.98 }],
                  },
                ]}
              >
                <SendMessageIcon />
              </Pressable>
            </a.View>
          ) : null
        })}
        <ChatPickerButton
          onEmojiSelect={onEmojiSelect}
          onGifSelect={onGifSelect}
        />
        <ChatUploadButton
          peerTopicId={peerTopicId}
          peerUserId={peerUserId}
          sendChatMessage={sendChatMessage}
          textMessage={textMessage}
        />
      </a.View>
    )
  },
)

const styles = StyleSheet.create({
  blur: {
    //@ts-ignore
    backdropFilter: `blur(5px)`,
  },
})
