import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { headerHeight } from './Header'
import { feedSize } from '@there/shared/desktop/windows-config'
import { ActionButton } from '@there/components/shared/ActionButton'
import useIsMounted from '@there/components/hooks/useIsMounted'
import { FeedWrapper, styles } from '@there/components/v2/RootView'
import { WithNewTopBar } from '@there/components/topbar/WithNewTopBar'
import { Sidebar } from '@there/components/v2/Sidebar'

export const FeedSkeleton = () => {
  let [showRefresh, setRefresh] = useState(false)
  let isMounted = useIsMounted()

  useEffect(() => {
    let t = setTimeout(() => {
      if (!isMounted.current) return
      setRefresh(true)
    }, 5000)

    return () => clearTimeout(t)
  }, [isMounted])

  return (
    <>
      <FeedWrapper>
        <WithNewTopBar skeleton={true}>
          <View style={styles.areasWrapper}>
            <Sidebar />
            <View style={styles.contentWrapper}>
              <View
                style={{
                  width: '100%',
                  minHeight: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {showRefresh && (
                  <>
                    <ActionButton
                      onClick={() => location.reload()}
                      variant="primary"
                    >
                      Reload
                    </ActionButton>
                  </>
                )}
              </View>
            </View>
          </View>
        </WithNewTopBar>
      </FeedWrapper>
    </>
  )

  //

  // return (
  //   <>
  //     <View>
  //       <MainViewWrapper>
  //         <AreasWrapper>
  //           <Wrapper>
  //             <Header noApollo={true} />

  //             <View
  //               style={{
  //                 width: feedSize.width,
  //                 minHeight: feedSize.height - headerHeight,
  //                 alignItems: 'center',
  //                 justifyContent: 'center',
  //               }}
  //             >
  //               {showRefresh && (
  //                 <>
  //                   <ActionButton
  //                     onClick={() => location.reload()}
  //                     variant="primary"
  //                   >
  //                     Reload
  //                   </ActionButton>
  //                 </>
  //               )}
  //             </View>
  //           </Wrapper>

  //           <SideArea>
  //             <SideHeader />
  //           </SideArea>
  //         </AreasWrapper>
  //       </MainViewWrapper>
  //     </View>
  //   </>
  // )
}
