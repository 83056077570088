import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useImmerReducer } from 'use-immer'
import { Draft } from 'immer'
import { useUniversalReducer } from './use-universal-reducer'
import { useLatest } from '@there/components/shared/use-latest'

type VolumeContextType = State & {
  dispatch: React.Dispatch<Action>
  volumeState: State
}

type State = {
  volume: number
  muted: boolean
  usersVolume: {
    [userId: string]: number | undefined
  }
  usersSystemVolume: {
    [userId: string]: number | undefined
  }
}

export type VolumeState = State

export type Action =
  | { type: 'volume changed'; volume: number }
  | { type: 'muted' }
  | { type: 'un muted' }
  | { type: 'load state'; state: State }
  | { type: 'user volume changed'; userId: string; volume: number }
  | { type: 'user system volume changed'; userId: string; volume: number }

function reducer(draft: Draft<State>, action: Action): State | void {
  switch (action.type) {
    case 'load state':
      return action.state
    case 'volume changed':
      draft.volume = action.volume
      break

    case 'un muted':
      draft.muted = false
      draft.volume = draft.volume === 0 ? 50 : draft.volume
      break

    case 'muted':
      draft.muted = true
      break

    case 'user volume changed':
      let usersVolume = draft.usersVolume || {}
      usersVolume[action.userId] = action.volume
      draft.usersVolume = usersVolume
      break

    case 'user system volume changed':
      let usersSystemVolume = draft.usersSystemVolume || {}
      usersSystemVolume[action.userId] = action.volume
      draft.usersSystemVolume = usersSystemVolume
      break

    // if unknown action, don't clear the state
    default:
      break
  }
}

const initialState = {
  volume: 50,
  muted: false,
  usersVolume: {},
  usersSystemVolume: {},
}

const initialContext = {
  ...initialState,
  volumeState: initialState,
  dispatch: () => {},
}

export const VolumeContext = createContext<VolumeContextType>(initialContext)
export const useVolumeContext = () =>
  useContext<VolumeContextType>(VolumeContext)
export const VolumeContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  // const [volumeState, dispatch] = useReducer(reducer, initialState)
  const [volumeState, dispatch] = useUniversalReducer<State, Action>(
    useImmerReducer<State, Action>(reducer, initialState),
    { key: 'volume' },
  )[0]

  let mutedRef = useLatest(volumeState.muted)
  /**
   * If user muted while in screen sharing, un mute it upon changing volume
   */
  useEffect(() => {
    if (mutedRef.current && volumeState.volume > 0) {
      dispatch({ type: 'un muted' })
    }
  }, [
    volumeState.volume,
    // don't run on muted change
    mutedRef,
    dispatch,
  ])

  const [hydrated, setHydrated] = useState(false)

  // Load state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    const volumeStateJson = localStorage.getItem('volumeState')

    if (
      !volumeStateJson ||
      typeof volumeStateJson !== 'string' ||
      !volumeStateJson.startsWith('{')
    ) {
      setHydrated(true)
      return
    }

    // Hydrate
    dispatch({ type: 'load state', state: JSON.parse(volumeStateJson) })
    setHydrated(true)
  }, [dispatch])

  // Persist state
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) {
      return
    }

    // Don't replace our cache with empty state
    if (!hydrated) {
      return
    }

    localStorage.setItem('volumeState', JSON.stringify(volumeState))
  }, [hydrated, volumeState])

  return (
    <VolumeContext.Provider
      value={{
        ...volumeState,
        volumeState,
        dispatch,
      }}
    >
      {children}
    </VolumeContext.Provider>
  )
}
