import { Svg, Path, SvgProps } from 'react-native-svg'

export function SmallLobbyIcon(props: SvgProps & { color: string }) {
  return (
    <Svg width={12} height={13} viewBox="0 0 12 13" fill="none" {...props}>
      <path
        d="M1 10V5.717a2 2 0 01.56-1.39l2.811-2.912a2 2 0 012.804-.074l3.19 2.975A2 2 0 0111 5.779V10a2 2 0 01-2 2H3a2 2 0 01-2-2z"
        stroke={props.color ? props.color : '#fff'}
        strokeOpacity={1}
        strokeWidth={1.5}
      />
    </Svg>
  )
}
export function RoomLobbyIcon(props: SvgProps) {
  return (
    <Svg width={17} height={17} viewBox="0 0 17 17" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8 6l5.3-3.3c.3-.1.5-.1.8 0L14.2 6c.4.2.7.7.7 1.2v6.3c0 .7-.7 1.4-1.4 1.4h-10c-.7 0-1.4-.7-1.4-1.4V7.2c0-.5.3-1 .7-1.2zm4.3 3.2c-.4 0-.7.3-.7.7V12c0 .4.3.8.7.8h2.8c.4 0 .7-.4.7-.8v-2c0-.5-.3-.8-.7-.8H7.1z"
        fill={props.color ? props.color : '#fff'}
      />
    </Svg>
  )
}
