import { View, Text } from 'react-native'
import { useTheme } from '../feed/ThemeContext'

export const InputLabel = ({
  children,
  subLabel,
}: {
  children: string
  subLabel?: string
}) => {
  let theme = useTheme()
  return (
    <View
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Text
        style={{
          fontSize: theme.fontSizes.normal,
          color: theme.colors.tertiaryText,
        }}
      >
        {children}
      </Text>
      {subLabel ? (
        <Text
          style={{
            marginLeft: 5,
            fontSize: theme.fontSizes.normal,
            color: theme.colors.quaternaryText,
          }}
        >
          {subLabel}
        </Text>
      ) : null}
    </View>
  )
}
