import { memo, useEffect, useRef } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useTheme } from '../feed/ThemeContext'
import { useAppContext } from '../shared/AppContext'
import { Message } from './Message'
import { AnimatePresence, motion } from 'framer-motion'
import { Pressable } from '@there/components/shared/Pressable'
import { ChatPeerId } from '@there/components/v2/useRenderingPhase'
import { useMessageActions } from '@there/components/v2/useMessageActions'
import { NewMessageInfo } from '@there/sun/utils/node-types'
import { LoadingState } from '@there/components/chat/useChatLoading'
import { DownloadManagerMap } from './useDownloadManager'
import { useSpaceChatsContext } from '../shared/SpaceChatsContext'

type Props = {
  peer: ChatPeerId
  messages: NewMessageInfo[]
  // @deprecated
  parentWidth: number
  lastSeenMessageSentAt: number | undefined
  lastSeenMessageIdOnMount: { current: string | undefined }
  lastMessageCalculated: () => void
  messageLoadingState: LoadingState
  unreadBadgeYRef: { current: number | null }
  // If in dm
  noAvatars: boolean
  withUsers: boolean
  inProgressDownload: DownloadManagerMap
  lastReadOutboxDate?: number
}

export const MessageListContent = memo(
  ({
    peer,
    messages,
    parentWidth,
    noAvatars,
    withUsers,
    lastSeenMessageSentAt,
    lastSeenMessageIdOnMount,
    lastMessageCalculated,
    messageLoadingState,
    unreadBadgeYRef,
    inProgressDownload,
    lastReadOutboxDate,
  }: Props) => {
    let theme = useTheme()
    let { currentUserId } = useAppContext()
    let { setReplyMsgId } = useMessageActions()

    let lastSeenMessageSentAtRef = useRef(lastSeenMessageSentAt)

    useEffect(() => {
      // Only set once to avoid UI jump
      if (lastSeenMessageSentAtRef.current) return
      lastSeenMessageSentAtRef.current = lastSeenMessageSentAt
    }, [lastSeenMessageSentAt])

    let unreadDivider = (
      <View style={styles.unreadDividerWrapper}>
        <Text
          style={{
            paddingHorizontal: 8,
            paddingVertical: 3,
            borderRadius: 20,
            color: theme.colors.tertiaryLight,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',

            fontSize: theme.fontSizes.normal,
          }}
        >
          Unread messages
        </Text>
      </View>
    )

    return (
      <AnimatePresence
        initial={false}
        presenceAffectsLayout={false}
        // key={peer.id}
      >
        {messages.map((message, index) => {
          let isUs = message.senderId === currentUserId

          let isLast = messages.length - 1 === index

          let previousMessage = messages[index - 1]
          let nextMessage = messages[index + 1]
          let firstMessageInBatch =
            previousMessage?.senderId !== message.senderId
          let lastMessageInBatch = nextMessage?.senderId !== message.senderId
          let showUnreadSeparator =
            lastSeenMessageIdOnMount.current &&
            message?.id === lastSeenMessageIdOnMount.current &&
            !isUs &&
            nextMessage?.senderId !== currentUserId &&
            // Don't show on last
            !isLast

          let withAvatar = lastMessageInBatch && !isUs && !noAvatars
          let withSenderName = firstMessageInBatch && !isUs && withUsers
          let downloadStatus = message?.document?.id
            ? inProgressDownload.has(message?.document?.id)
              ? inProgressDownload.get(message?.document?.id)
              : undefined
            : undefined

          let hasRead = lastReadOutboxDate
            ? message.sentAt < lastReadOutboxDate
            : false

          return (
            <Pressable
              key={message.id}
              onLayout={(event) => {
                if (showUnreadSeparator) {
                  //  used for scrolling to it
                  unreadBadgeYRef.current = event.nativeEvent.layout.y
                }
                // else if (isLast && !unreadBadgeYRef.current) {
                //   unreadBadgeYRef.current = null
                // }
              }}
              style={{
                // @ts-ignore
                cursor: 'default',
              }}
              onLongPress={() => setReplyMsgId(message.id)}
            >
              <motion.div
                key={message.id}
                initial={{ opacity: 0, height: 'auto', y: 20 }}
                animate={{ opacity: 1, height: 'auto', y: 0 }}
                exit={{ opacity: 0, height: 0, y: 0 }}
                style={{
                  overflow: 'hidden',
                  width: '100%',
                  position: 'relative',
                }}
                transition={{ ease: 'easeOut', duration: 0.12 }}
              >
                <Message
                  key={message.id}
                  message={message}
                  parentWidth={parentWidth}
                  isUs={isUs}
                  lastMessageInBatch={lastMessageInBatch}
                  onCalculate={
                    index === messages.length - 1
                      ? lastMessageCalculated
                      : undefined
                  }
                  sent={
                    !messageLoadingState.pendingMessageIds.includes(message.id)
                  }
                  hasRead={hasRead}
                  noAvatars={noAvatars}
                  withAvatar={withAvatar && !noAvatars}
                  withSenderName={withSenderName}
                  downloadStatusData={downloadStatus}
                />
                {showUnreadSeparator && unreadDivider}
              </motion.div>
            </Pressable>
          )
        })}
      </AnimatePresence>
    )
  },
)

const styles = StyleSheet.create({
  unreadDividerWrapper: {
    width: '100%',
    marginVertical: 5,
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})
